import BaseService from '../BaseService';

class OrganizationService extends BaseService {
  /**
   * Get settings for an organization
   * @param  string orgId
   */
  getSettings(orgId) {
    return this.http.get(`organizations/${orgId}`);
  }

  /**
   * Save orgSettings object
   * @param  string orgId
   * @param  {Object} orgSettings
   */
  saveSettings(orgId, orgSettings) {
    return this.http.put(`organizations/${orgId}`, {
      data: orgSettings,
    });
  }

  /**
   * Update the logo
   * @param {Object} image
   */
  updateOrganizationLogoImage(image) {
    return this.http.post(`organizations/${image.id}/logoimage`, {
      data: image,
    });
  }

  /**
   * Update the banner
   * @param {Object} image
   */
  updateOrganizationBannerImage(image) {
    return this.http.post(`organizations/${image.id}/bannerimage`, {
      data: image,
    });
  }

  /**
   * Save orgSettings object
   * @param  string orgId
   * @param  {Object} orgDefaults
   */
  saveOrganizationDefaults(orgId, orgDefaults) {
    return this.http.post(`organizations/${orgId}`, {
      data: orgDefaults,
    });
  }

  updateFeatures(orgId, features) {
    return this.http.put(`organizations/${orgId}/features`, features);
  }

  getOrgFeatures(orgId) {
    return this.http.get(`organizations/${orgId}/features`);
  }
}

export default new OrganizationService();
