import { commonStyles } from '../styles';

export default function styles(theme) {
  return {
    ...commonStyles(theme),
    moreMenuButton: {
      padding: 8,
      '& :hover': {
        backgroundColor: '#EEEEEE',
      },
    },
  };
}
