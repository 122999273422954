/* eslint-disable import/prefer-default-export */

import { FEATURES_TYPE } from 'globals/constants';

export const hasPremiumFeature = ({ featureType, features }) => {
  if (
    typeof featureType !== 'number' ||
    !Object.values(FEATURES_TYPE).includes(featureType)
  ) {
    return false;
  }

  return (
    Array.isArray(features) &&
    features.some(
      (feature) => feature?.featureId === featureType && feature?.isEnabled,
    )
  );
};
