import { connect } from 'react-redux';
import { setSuccess } from '../../../store/alerts/actions';
import ActionsMenu from './ActionsMenu';

export function mapStateToProps(state) {
  return {
    primary: state.pages.pages.find((p) => p.primary),
  };
}

export default connect(mapStateToProps, { setSuccess })(ActionsMenu);
