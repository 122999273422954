export default function styles(theme) {
  return {
    closeButton: {
      color: theme.palette.secondary.main,
      backgroundColor: '#FFF',
      border: 'solid 1px #DDDDDD',
      '&:hover': {
        backgroundColor: '#F6F6F6',
      },
    },
    deleteButton: {
      backgroundColor: '#C82947',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#A8233C',
      },
      '&:disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
  };
}
