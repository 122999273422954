import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getIn } from 'formik';
import { TextField, MenuItem, ListSubheader } from '@material-ui/core';
import * as globals from '../../../../globals/constants';

export default function FrequencyDropdown({
  field,
  form,
  frequencies,
  helperTextClass,
  defaultValue,
  ...props
}) {
  const { t } = useTranslation();
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && Boolean(fieldError);
  const frequencyList = globals.PAYMENT_FREQUENCY_LIST;

  useEffect(() => {
    form.setFieldValue(field.name, defaultValue, true);
    form.setFieldTouched(field.name, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, form.values.fundId, field.name]);

  const renderFrequenciesOptions = (frequenciesOptions) =>
    frequenciesOptions
      .filter((freq) => Boolean(frequencyList[freq.name]))
      .sort((freq1, freq2) => {
        return (
          frequencyList[freq1.name].displayOrder -
          frequencyList[freq2.name].displayOrder
        );
      })
      .map((freq) => {
        return (
          <MenuItem key={freq.name} value={freq.name}>
            {t(globals.PAYMENT_FREQUENCY_TRANSLATION_KEYS_MAP[freq.name])}
          </MenuItem>
        );
      });

  const oneTimeOptions = frequencies?.filter(
    (frequency) => frequency.group === globals.ONE_TIME_OPTION,
  );
  const recurringOptions = frequencies?.filter(
    (frequency) => frequency.group === globals.RECURRING_OPTION,
  );

  const getSelectedValue = () => {
    // Validate incoming field.value is part of fund frequencies
    if (
      !frequencies?.length ||
      !frequencies.some((freq) => freq.name === field?.value)
    ) {
      return '';
    }
    return field.value;
  };

  return (
    <TextField
      name={name}
      id={name}
      data-testid={name}
      label={t('gift.frequency.title')}
      FormHelperTextProps={{ classes: { root: helperTextClass } }}
      select
      variant="outlined"
      disabled={isSubmitting}
      error={showError}
      helperText={showError ? fieldError : ''}
      value={getSelectedValue()}
      onChange={(event) => {
        form.setFieldValue(field.name, event.target.value, true);
        form.setFieldTouched(field.name, true, false);
      }}
      {...props}
    >
      <MenuItem key="blank" value="" />
      {oneTimeOptions?.length && (
        <ListSubheader>{t('pay.oneTime')}</ListSubheader>
      )}
      {oneTimeOptions?.length && renderFrequenciesOptions(oneTimeOptions)}
      {recurringOptions?.length && (
        <ListSubheader>{t('pay.recurring')}</ListSubheader>
      )}
      {recurringOptions?.length && renderFrequenciesOptions(recurringOptions)}
    </TextField>
  );
}

FrequencyDropdown.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  frequencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  helperTextClass: PropTypes.string,
  defaultValue: PropTypes.string,
};

FrequencyDropdown.defaultProps = {
  helperTextClass: '',
  defaultValue: '',
};
