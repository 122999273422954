// set the env vars from the process for build time and override them from the window for runtime vars
window.env = {
  REACT_APP_MOCK_BACKEND_API: process.env.REACT_APP_MOCK_BACKEND_API,
  REACT_APP_BACKEND_API: process.env.REACT_APP_BACKEND_API,
  REACT_APP_UNSPLASH_APP_ID: process.env.REACT_APP_UNSPLASH_APP_ID,
  REACT_APP_IDS_DOMAIN: process.env.REACT_APP_IDS_DOMAIN,
  REACT_APP_IDS_CLIENT_ID: process.env.REACT_APP_IDS_CLIENT_ID,
  REACT_APP_IDS_TENANT: process.env.REACT_APP_IDS_TENANT,
  REACT_APP_MOBILE_URL: process.env.REACT_APP_MOBILE_URL,
  REACT_APP_IP_URL: process.env.REACT_APP_IP_URL,
  REACT_APP_MP_URL: process.env.REACT_APP_MP_URL,
  REACT_APP_TELEMETRY_INSTRUMENTATION_KEY:
    process.env.REACT_APP_TELEMETRY_INSTRUMENTATION_KEY,
  REACT_APP_DON_URL: process.env.REACT_APP_DON_URL,
  REACT_APP_IDS_AUDIENCE: process.env.REACT_APP_IDS_AUDIENCE,
  REACT_APP_STREAM_URL: process.env.REACT_APP_STREAM_URL,
  REACT_APP_RECURRENCE_API_URL: process.env.REACT_APP_RECURRENCE_API_URL,
  REACT_APP_IDS_SCOPES: process.env.REACT_APP_IDS_SCOPES,
  REACT_APP_CXP_URL: process.env.REACT_APP_CXP_URL,
  REACT_APP_FAITHFORWARD_ORIGIN: process.env.REACT_APP_FAITHFORWARD_ORIGIN,
  REACT_APP_FAITHFORWARD_ENV: process.env.REACT_APP_FAITHFORWARD_ENV,
  REACT_APP_VANCO_VIDEO_SIGNUP_GUIDE_LINK:
    process.env.REACT_APP_VANCO_VIDEO_SIGNUP_GUIDE_LINK,
  REACT_APP_FAITH_FORWARD_BACKEND: process.env.REACT_APP_FAITH_FORWARD_BACKEND,
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  REACT_APP_PEOPLE_ORIGIN: process.env.REACT_APP_PEOPLE_ORIGIN,
  REACT_APP_PEOPLE_ACCESSTOKEN: process.env.REACT_APP_PEOPLE_ACCESSTOKEN,
  REACT_APP_PEOPLE_STORAGE: process.env.REACT_APP_PEOPLE_STORAGE,
  REACT_APP_PAYWALL_FORM_URL: process.env.REACT_APP_PAYWALL_FORM_URL,
  REACT_APP_AZURE_AUTH_DOMAIN: process.env.REACT_APP_AZURE_AUTH_DOMAIN,
  REACT_APP_AZURE_AUTH_CLIENT_ID: process.env.REACT_APP_AZURE_AUTH_CLIENT_ID,
  REACT_APP_AZURE_AUTH_TENANT: process.env.REACT_APP_AZURE_AUTH_TENANT,
  REACT_APP_AZURE_AUTH_PROFILE: process.env.REACT_APP_AZURE_AUTH_PROFILE,
  REACT_APP_AZURE_AUTH_SIGNIN: process.env.REACT_APP_AZURE_AUTH_SIGNIN,
  REACT_APP_AZURE_AUTH_SIGNUP: process.env.REACT_APP_AZURE_AUTH_SIGNUP,
  ...window.env,
};
