import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withTile from '../../hoc/withTile';
import { saveTile } from '../../../store/tiles/actions';
import { setError, setSuccess } from '../../../store/alerts/actions';
import TileBuilder from './TileBuilder';

export function mapStateToProps(state) {
  return {
    loadingSave: state.tiles.loadingSave,
  };
}

export default connect(mapStateToProps, {
  saveTile,
  setSnackbar: setError,
  setSuccess,
})(withTile(withRouter(TileBuilder), 'titles.editTile'));
