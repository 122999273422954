import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import styles from './styles';

function InfoBadge({ classes, level, children }) {
  const isError = level === 'error';
  return (
    <div className={isError ? classes.errorContainer : classes.infoContainer}>
      {isError ? (
        <WarningIcon className={classes.errorIcon} data-testid="error-icon" />
      ) : (
        <ErrorOutlineIcon
          className={classes.infoIcon}
          data-testid="info-icon"
        />
      )}
      <Typography className={isError ? classes.errorText : ''}>
        {children}
      </Typography>
    </div>
  );
}

InfoBadge.propTypes = {
  classes: PropTypes.object.isRequired,
  level: PropTypes.oneOf(['info', 'error']),
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  ).isRequired,
};
InfoBadge.defaultProps = {
  level: 'info',
};
export default withStyles(styles)(InfoBadge);
