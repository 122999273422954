/* eslint-disable no-unused-vars */
export default (theme) => ({
  submit: {
    marginTop: '27px',
    borderColor: 'black',
    borderStyle: 'solid',
  },
  form: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  spaceDivider: {
    margin: '27px 0 27px 0',
  },
});
