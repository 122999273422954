import { compose } from 'redux';
import { withStyles, ButtonBase } from '@material-ui/core';
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import premiumFeatureVideo from './premiumFeatureVideo.png';
import premiumFeatureOutreach from './premiumFeatureOutreach.png';
import { Page, PageDescription, PageTitle } from '../../common';
import {
  VIDEO_ROUTES,
  PAYWALL_ROUTES,
  PAYWALL_PAGETYPE,
} from '../../../globals/constants';
import { useVideoDisplayFlags } from '../../../utils/hooks';
import styles from './styles';

const paywallPage = window.env.REACT_APP_PAYWALL_FORM_URL;

function Paywall({ classes }) {
  const { shouldDisplaySmartLibrary, shouldDisplaySmartClips } =
    useVideoDisplayFlags();

  const history = useHistory();

  const { t } = useTranslation();

  const location = useLocation();
  const currentPath = location.pathname;

  const pageType = PAYWALL_ROUTES[currentPath];

  useEffect(() => {
    if (
      pageType === PAYWALL_PAGETYPE.smartLibrary &&
      shouldDisplaySmartLibrary
    ) {
      history.push(VIDEO_ROUTES.smartLibrary);
    }
    if (pageType === PAYWALL_PAGETYPE.smartClips && shouldDisplaySmartClips) {
      history.push(VIDEO_ROUTES.smartClips);
    }
  }, [shouldDisplaySmartLibrary, shouldDisplaySmartClips, history, pageType]);

  const openVancoPaymentPage = () => {
    window.open(paywallPage, '_tab');
  };

  const premiumFeature = () => {
    return pageType === PAYWALL_PAGETYPE.smartLibrary ||
      pageType === PAYWALL_PAGETYPE.smartClips
      ? premiumFeatureVideo
      : premiumFeatureOutreach;
  };

  const mailto = `mailto: ${t('paywall.description2.emailUs.email')}`;
  const number = `tel: ${t('paywall.description2.callUs.number')}`;

  return (
    <Page variant="centered">
      <PageTitle className={classes.title}>
        {t(`paywall.${pageType}.title`)}
      </PageTitle>
      <PageTitle className={classes.subtitle}>
        {t(`paywall.${pageType}.description1`)}
      </PageTitle>
      <PageDescription className={classes.pageDescription}>
        {t(`paywall.${pageType}.description2`)}{' '}
        {t('paywall.description2.callUs')}{' '}
        <a href={number} className={classes.vancoContact}>
          {t('paywall.description2.callUs.number')}
        </a>{' '}
        {t('paywall.description2.emailUs')}{' '}
        <a href={mailto} className={classes.vancoContact}>
          {' '}
          {t('paywall.description2.emailUs.email')}
        </a>
        .
      </PageDescription>
      <div className={classes.imageContainer}>
        <img src={premiumFeature()} alt="tile" className={classes.image} />
      </div>
      <PageDescription className={classes.titlePremiumF}>
        {t('paywall.description3')}
      </PageDescription>
      <div className={classes.buttonContainer}>
        <ButtonBase
          size="large"
          onClick={openVancoPaymentPage}
          className={classes.button}
        >
          {t(`paywall.${pageType}.button.description`)}
        </ButtonBase>
      </div>
    </Page>
  );
}

Paywall.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(Paywall);
