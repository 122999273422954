import KioskService from '../../api/KioskService';
import {
  DISABLE_KIOSK,
  DISABLE_KIOSK_ERROR,
  DISABLE_KIOSK_SUCCESS,
  ENABLE_KIOSK,
  ENABLE_KIOSK_ERROR,
  ENABLE_KIOSK_SUCCESS,
  GET_KIOSK,
  GET_KIOSK_ERROR,
  GET_KIOSK_SUCCESS,
  SEND_KIOSK_REQUEST,
  SEND_KIOSK_REQUEST_ERROR,
  SEND_KIOSK_REQUEST_SUCCESS,
  UPDATE_KIOSK,
  UPDATE_KIOSK_SUCCESS,
  UPDATE_KIOSK_ERROR,
} from './types';
import { setError } from '../alerts/actions';

export function setKioskLoading() {
  return async (dispatch) => {
    dispatch({ type: GET_KIOSK });
  };
}

export function setKiosk(kiosk) {
  return async (dispatch) => {
    dispatch({ type: GET_KIOSK_SUCCESS, payload: kiosk });
  };
}

export function setKioskError() {
  return async (dispatch) => {
    dispatch({ type: GET_KIOSK_ERROR });
  };
}

export function requestKiosk() {
  return async (dispatch, getState) => {
    const { organizations } = getState();
    const orgUri = organizations.currentOrganization.id;
    dispatch({ type: SEND_KIOSK_REQUEST });
    try {
      await KioskService.requestKiosk(orgUri);
      dispatch({ type: SEND_KIOSK_REQUEST_SUCCESS });
    } catch (e) {
      dispatch(setError(e.message));
      dispatch({ type: SEND_KIOSK_REQUEST_ERROR });
    }
  };
}

export function getKiosk() {
  return async (dispatch, getState) => {
    dispatch(setKioskLoading());
    try {
      const { id: orgUri } = getState().organizations.currentOrganization;
      const response = await KioskService.getKiosk(orgUri);
      const kiosk = response?.data?.data;
      if (kiosk) {
        dispatch(setKiosk(kiosk));
      } else {
        dispatch(setKioskError());
      }
    } catch (e) {
      dispatch(setKioskError());
    }
  };
}

export function disableKiosk(disableDate) {
  return async (dispatch, getState) => {
    try {
      const { id: orgUri } = getState().organizations.currentOrganization;
      const formattedDisableDate = disableDate
        ? disableDate.format('MM/DD/YYYY')
        : undefined;
      dispatch({ type: DISABLE_KIOSK });
      await KioskService.disableKiosk(orgUri, formattedDisableDate);
      dispatch({ type: DISABLE_KIOSK_SUCCESS, payload: disableDate || null });
    } catch (e) {
      dispatch(setError(e.message));
      dispatch({ type: DISABLE_KIOSK_ERROR });
    }
  };
}

export function enableKiosk() {
  return async (dispatch, getState) => {
    const { organizations } = getState();
    const orgUri = organizations.currentOrganization.id;
    dispatch({ type: ENABLE_KIOSK });
    try {
      const response = await KioskService.enableKiosk(orgUri);
      dispatch({ type: ENABLE_KIOSK_SUCCESS, payload: response?.data?.data });
    } catch (e) {
      dispatch(setError(e.message));
      dispatch({ type: ENABLE_KIOSK_ERROR });
    }
  };
}

/**
 * @param kiosk
 * @param {string} kiosk.welcomeMessage The new welcome message.
 * @param {string} kiosk.buttonLabel the new custom button label
 * @returns {bool} returns true if update was successful
 */
export function updateKiosk(kiosk) {
  return async (dispatch, getState) => {
    const { organizations } = getState();
    const orgUri = organizations.currentOrganization.id;
    dispatch({ type: UPDATE_KIOSK });
    try {
      const response = await KioskService.updateKiosk(orgUri, kiosk);
      dispatch({ type: UPDATE_KIOSK_SUCCESS, payload: response?.data?.data });
      return true;
    } catch (e) {
      dispatch(setError(e.message));
      dispatch({ type: UPDATE_KIOSK_ERROR });
      return false;
    }
  };
}

export default { requestKiosk };
