import { useState, useEffect } from 'react';
import { objectOf, string, bool, arrayOf, object, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Select, MenuItem, Button } from '@material-ui/core';

import { SwapHoriz } from '@material-ui/icons';
import logo from '../../../../assets/logos/mobile.svg';

import styles from './styles';
import SelectOrganizationModal from '../../../common/SelectOrganizationModal';
import useSelectOrganizationModal from '../../../../utils/hooks/useSelectOrganizationModal';

export function AccountSelector({
  classes,
  associatedOrganizations,
  isMobile,
  organizationId,
  setOrganization,
  organizationName,
}) {
  const isSingleOrg = associatedOrganizations.length < 2;
  const showModal = associatedOrganizations.length > 5;
  const [orgId, setOrgId] = useState('');
  const {
    isOpen: modalIsOpen,
    openModal,
    closeModal,
    onSelectOrganization,
  } = useSelectOrganizationModal();

  useEffect(() => {
    setOrgId(organizationId);
  }, [organizationId]);

  const onSelectChange = (e) => {
    setOrgId(e.target.value);
    setOrganization(e.target.value, true);
  };

  return (
    <>
      {!isMobile && (
        <>
          <Link to="/">
            <img src={logo} alt="Vanco Logo" className={classes.desktopLogo} />
          </Link>
          {isSingleOrg && (
            <Typography className={classes.orgName}>
              {organizationName}
            </Typography>
          )}
          {!isSingleOrg && showModal && (
            <>
              <Button
                className={classes.desktopProfile}
                onClick={openModal}
                id="switch-org-btn"
              >
                <Typography className={classes.orgName}>
                  {organizationName}
                </Typography>
                <SwapHoriz className={classes.swapIcon} />
              </Button>
              <SelectOrganizationModal
                isOpen={modalIsOpen}
                onClose={closeModal}
                organizations={associatedOrganizations}
                selectedOrganizationId={organizationId}
                onSelectOrganization={onSelectOrganization}
              />
            </>
          )}
          {!isSingleOrg && !showModal && (
            <Select
              className={classes.select}
              value={orgId}
              onChange={onSelectChange}
              disableUnderline
              data-testid="account-select-list"
              renderValue={(value) =>
                associatedOrganizations.length
                  ? associatedOrganizations.find(
                      (o) => o.organizationId === value,
                    )?.organizationName
                  : ''
              }
            >
              {associatedOrganizations.map((o) => (
                <MenuItem key={o.organizationId} value={o.organizationId}>
                  <div className={classes.overflow}>
                    {`${o.clientId} | ${o.organizationName}`}
                  </div>
                </MenuItem>
              ))}
            </Select>
          )}
        </>
      )}
      <div className={classes.grow}>
        {isMobile && (
          <Link to="/">
            <img
              src={logo}
              alt="Vanco Mobile Logo"
              className={classes.mobileLogo}
            />
          </Link>
        )}
      </div>
    </>
  );
}

AccountSelector.propTypes = {
  classes: objectOf(string).isRequired,
  isMobile: bool,
  organizationId: string,
  associatedOrganizations: arrayOf(object),
  setOrganization: func.isRequired,
  organizationName: string,
};

AccountSelector.defaultProps = {
  isMobile: false,
  organizationId: '',
  associatedOrganizations: [],
  organizationName: '',
};

export default withStyles(styles)(AccountSelector);
