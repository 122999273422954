import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { useACSTBlocking, useUser } from 'utils/hooks';
import useStyles from './styles';
import { postAiEmailFinishMyThought } from '../../../store/AIAssist/actions';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';
import useGetOrgSettings from '../../../utils/hooks/useGetOrgSettings';
import useGetOrgFeatures from '../../../utils/hooks/useGetOrgFeatures';
import { PAYWALL_IFRAME_ROUTES } from '../../../globals/constants';
import {
  OPEN_NEWTRANSACTION,
  OPEN_AI_ASSIST,
} from '../../../store/modals/types';
import {
  openModal,
  openAIAssistModal,
  dismissAIOverwriteWarning,
  closeAIAssistModal,
} from '../../../store/modals/actions';
import AiAssistModal from '../Modals/AiAssistModal';

export const GetTitle = (pathname) => {
  const { t } = useTranslation();

  switch (pathname) {
    case '/transactions':
      return t('titles.transactions');
    case '/donors':
      return t('titles.donors');
    case '/emails':
      return t('titles.comms.communications');
    case '/templates':
      return t('titles.comms.templates');
    case '/donors-settings':
      return t('titles.donors.tags');
    case '/content/cms':
      return t('titles.content.contentManager');
    case '/content/desk':
      return t('titles.content.writingDesk');
    case '/content/analytics':
      return t('titles.content.analytics');
    case '/content/settings':
      return t('titles.content.settings');
    default:
      return 'My Page';
  }
};

// People App Modal Actions
const peopleAppModalActions = {
  openTransactionsModal: 'open',
  openEmailAiAssistant: 'openEmailAiAssistant',
  openFinishThought: 'openFinishThought',
};

function MultiverseIframeContent({ url }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const pathName = window.location.pathname;
  const title = GetTitle(pathName);
  usePageTitle(title);
  const { organizationId } = useUser();
  const { hideFeaturesForACST } = useACSTBlocking();
  const { modalsOpen, showEmailAIAssistantWarning } = useSelector(
    (state) => state.modals,
  );
  const modalIsOpen = modalsOpen?.includes(OPEN_NEWTRANSACTION);
  const AIAssistModalIsOpen = modalsOpen?.includes(OPEN_AI_ASSIST);
  const { settings, loadingOrgSettings } = useGetOrgSettings(organizationId);
  const { shouldDisplayOutreachEmail } = useGetOrgFeatures(organizationId);

  const iframeUrl = () => {
    if (pathName === '/emails') {
      return `${url}?&reply=${settings?.email}`;
    }
    return url;
  };

  const redirectToPaywall = () => {
    history.push(
      pathName === '/emails'
        ? PAYWALL_IFRAME_ROUTES.outreachEmails
        : PAYWALL_IFRAME_ROUTES.outreachTemplates,
    );
  };

  const validateOutreachEmailDisplay = () => {
    if (
      (pathName === '/emails' || pathName === '/templates') &&
      !shouldDisplayOutreachEmail
    ) {
      redirectToPaywall();
    }

    return true;
  };

  const redirectToError = useCallback(() => {
    history.push('/*');
  }, [history]);

  // Prevent ACST/PF orgs to use Analytics page
  useEffect(() => {
    if (hideFeaturesForACST) {
      redirectToError();
    }
  }, [hideFeaturesForACST, redirectToError]);

  useEffect(() => {
    const postMessageListener = async (event) => {
      // Always verify the origin for security purposes
      const isFaithForwardOrigin =
        event.origin === window.env.REACT_APP_FAITHFORWARD_ORIGIN;
      const isPeopleAppOrigin =
        event.origin === window.env.REACT_APP_PEOPLE_ORIGIN;

      if (!isFaithForwardOrigin && !isPeopleAppOrigin) {
        return;
      }
      if (
        isFaithForwardOrigin &&
        event.data.type &&
        event.data.type === 'routeChange'
      ) {
        const getBasePath = (str) => {
          // Using dummy domain since 'route' does not have a domain
          const urlObj = new URL(str, 'http://example.com');
          return urlObj.pathname;
        };

        const bPath1 = getBasePath(event.data.route);
        const bPath2 = getBasePath(url);

        //  fix for issue MV-173 faith changed the way they send content data
        //  mv-297 - url path validation is now dynamic
        if (bPath1 === bPath2) {
          // Both route and url request the same base path, prevent infinite loop
          return;
        }

        history.replace({
          pathname: event.data.route.replace('/app/', '/content/'),
        });
      }

      const processData = async () => {
        // FIXME: function addMultiverseData doesn't exists
        // await dispatch(addMultiverseData(data));
      };

      if (isPeopleAppOrigin && event.data.modalAction) {
        const { modalAction } = event.data;

        // open transaction modal
        if (
          modalAction === peopleAppModalActions.openTransactionsModal &&
          !modalIsOpen
        ) {
          // when memberId is supplied it is expected to be an existing user
          if (event.data.memberId) {
            processData(event.data);
          }

          dispatch(openModal());
        }

        // open email AI assistant modal
        if (
          modalAction === peopleAppModalActions.openEmailAiAssistant &&
          !AIAssistModalIsOpen
        ) {
          dispatch(openAIAssistModal(event.data.showWarning));
        }

        // Post Finish my Thought text
        if (
          modalAction === peopleAppModalActions.openFinishThought &&
          event.data.inputText
        ) {
          const { inputText } = event.data;
          const responseText = await dispatch(
            postAiEmailFinishMyThought({
              text: inputText,
              organizationId,
            }),
          );

          if (responseText) {
            const iframe = document.querySelector('iframe[src*=".myvanco"]');
            if (iframe) {
              // post message to iframe
              iframe.contentWindow.postMessage(
                {
                  action: 'emailAiAssistantResponse',
                  inputText: `${inputText} ${responseText}`,
                },
                '*',
              );
            }
          }
        }
      }
    };

    // Add event listener
    window.addEventListener('message', postMessageListener, false);

    return () => {
      // Remove event listener
      window.removeEventListener('message', postMessageListener, false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !loadingOrgSettings &&
    validateOutreachEmailDisplay() && (
      <>
        <iframe
          title="content"
          src={iframeUrl()}
          className={classes.iframeStyle}
        />

        <AiAssistModal
          open={AIAssistModalIsOpen}
          onWarningDismiss={() => dispatch(dismissAIOverwriteWarning())}
          onClose={() => dispatch(closeAIAssistModal())}
          showWarning={showEmailAIAssistantWarning}
          organizationId={organizationId}
        />
      </>
    )
  );
}

MultiverseIframeContent.propTypes = {
  url: PropTypes.string.isRequired,
};

export default MultiverseIframeContent;
