export default () => ({
  logoHeading: {
    marginTop: '50px',
    marginBottom: '16px',
    maxWidth: 700,
  },
  text: {
    maxWidth: 700,
  },
  organizationInitials: {
    fontSize: 48,
    alignSelf: 'center',
    color: 'white',
  },
  uploadIcon: {
    marginRight: '8px',
  },
});
