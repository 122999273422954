import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import usePermissionsRouter from 'utils/hooks/usePermissionsRouter';

export function HomeRedirect({ isInternal }) {
  const { fallbackRoute } = usePermissionsRouter();

  useEffect(() => {
    if (isInternal) {
      window.location.replace(window.env.REACT_APP_IP_URL);
    }
  }, [isInternal]);

  if (!fallbackRoute) {
    return null;
  }

  return (
    <Redirect
      to={{
        pathname: fallbackRoute,
      }}
    />
  );
}

HomeRedirect.propTypes = {
  isInternal: PropTypes.bool.isRequired,
};

export default HomeRedirect;
