import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { ReactComponent as TilesIcon } from '../../../assets/tilesIcon.svg';
import Page from '../Page';
import PagePaper from '../PagePaper';
import PageTitle from '../PageTitle';
import PageDescription from '../PageDescription';
import PrimaryButton from '../PrimaryButton';

import styles from './styles';

export function NoActiveChannel({ classes }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <section className={classes.main}>
      <section className={classes.contentWrap}>
        <Page className={classes.contentContainer}>
          <PagePaper className={classes.container}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <PageTitle className={classes.title}>
                  <TilesIcon
                    className={classes.activeIcon}
                    data-testid="tiles-icon"
                  />
                  {t('tile.setUp.heading1')}
                </PageTitle>
                <PageDescription
                  className={classes.body}
                  data-testid="noActiveChannelDescription"
                >
                  {t('tile.setUp.description1')}
                  <br />
                  {t('tile.setUp.description2')}
                </PageDescription>

                <PrimaryButton
                  className={classes.button}
                  size="large"
                  onClick={() => history.push('/getStarted')}
                >
                  {t('button.setUpVO')}
                </PrimaryButton>
                <PrimaryButton
                  className={classes.button}
                  size="large"
                  onClick={() => history.push('/mobile/get-started')}
                >
                  {t('button.setUpVM')}
                </PrimaryButton>
              </Grid>
            </Grid>
          </PagePaper>
        </Page>
      </section>
    </section>
  );
}

NoActiveChannel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoActiveChannel);
