import { TILE_TYPE_GIFT, TILE_TYPE_PAYMENT } from '../../globals/constants';

export default function backendToFrontend(data) {
  return {
    ...data,
    payments: data.tiles
      .filter((t) => t.type === TILE_TYPE_PAYMENT)
      .sort((a, b) => a.title.localeCompare(b.title)),
    tiles: data.tiles
      .filter((t) => t.type === TILE_TYPE_GIFT)
      .sort((a, b) => a.title.localeCompare(b.title)),
  };
}
