// to conform to the stream-app requirements - we have to trim the starting s- (one is lower case, the other is uppercase)
export const formatStreamInfo = streamerInfo => {
  // finds first occurrence of s- , case insensitive
  const regexMatch = /^(.*?)s-/i;

  return streamerInfo && regexMatch.test(streamerInfo)
    ? streamerInfo.replace(regexMatch, '')
    : streamerInfo;
};

export default {
  formatStreamInfo,
};
