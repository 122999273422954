import { validations } from '../../../utils';
import i18n from '../../../utils/i18n';
import { CHANNEL_TYPE } from '../../../globals/constants';

export const maxLengths = {
  pageName: 55,
};

export const createSchema = ({ page, pages = [] }) => {
  const bannedNames = pages
    .filter((p) => p.channelId === CHANNEL_TYPE.ONLINE && p.id !== page?.id)
    .map((p) => p.pageName);

  return validations.pageNameValidation({ i18n, bannedNames });
};
