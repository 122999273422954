import { string } from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as CardInfoIcon } from '../../../../assets/cardInfoIcon.svg';
import { useCardContext } from '../context';

function CardDisclosure({ title, message }) {
  const { classes } = useCardContext();

  return (
    <div className={classes.cardDisclosure}>
      <Typography className={classes.cardDisclosureTitle}>
        <CardInfoIcon />
        {title}
      </Typography>
      <Typography className={classes.cardDisclosureMessage}>
        {message}
      </Typography>
    </div>
  );
}

CardDisclosure.propTypes = {
  title: string.isRequired,
  message: string.isRequired,
};

export default CardDisclosure;
