import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import styles from './styles';
import websiteImage from './website.png';
import {
  PagePaper,
  PageTitle,
  PageDescription,
  PrimaryButton,
  LinkButton,
} from '../../../common';

export function Welcome({ goToNext, classes }) {
  const { t } = useTranslation();

  return (
    <PagePaper className={classes.container}>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <PageTitle className={classes.title}>
            {t('wizard.pageCreate.title')}
          </PageTitle>
          <img
            src={websiteImage}
            alt="website"
            className={classes.mobileImage}
          />
          <PageDescription className={classes.description}>
            {t('wizard.pageCreate.description')}
          </PageDescription>
          <PrimaryButton size="large" onClick={goToNext}>
            {t('wizard.pageCreate.leftButton')}
          </PrimaryButton>
          <LinkButton size="large" color="primary" to="/tiles">
            {t('wizard.pageCreate.rightButton')}
          </LinkButton>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <img src={websiteImage} alt="website" className={classes.image} />
        </Grid>
      </Grid>
    </PagePaper>
  );
}

Welcome.propTypes = {
  classes: PropTypes.object.isRequired,
  goToNext: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(Welcome));
