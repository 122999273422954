import commonStyles from '../styles';

export default function styles(theme) {
  return {
    subtitle: {
      marginBottom: 5,
    },
    subtitleTop: {
      marginTop: 5,
    },
    disabledInfo: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(5),
      backgroundColor: '#FAFBFB',
      borderRadius: 8,
      border: '1px solid #DCDEE0',
      display: 'flex',
    },
    phoneGutter: {
      marginTop: theme.spacing(3),
    },
    linkButton: {
      paddingTop: 3,
      paddingLeft: 0,
      paddingRight: 0,
    },
    link: {
      display: 'inline-flex',
    },
    container: {
      maxWidth: 612,
    },
    extraBottomSpace: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(8),
      },
    },

    ...commonStyles(theme),
  };
}
