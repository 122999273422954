import {
  OPEN_NEWTRANSACTION,
  CLOSE_NEWTRANSACTION,
  OPEN_AI_ASSIST,
  CLOSE_AI_ASSIST,
  DISMISS_AI_OVERWRITE_WARNING,
} from './types';

export function openModal() {
  return {
    type: OPEN_NEWTRANSACTION,
  };
}

export function closeModal() {
  return {
    type: CLOSE_NEWTRANSACTION,
  };
}

export function openAIAssistModal(showEmailAIAssistantWarning) {
  return {
    type: OPEN_AI_ASSIST,
    showEmailAIAssistantWarning,
  };
}

export function closeAIAssistModal() {
  return {
    type: CLOSE_AI_ASSIST,
  };
}

export function dismissAIOverwriteWarning() {
  return {
    type: DISMISS_AI_OVERWRITE_WARNING,
  };
}
