import hexeaPromise from './hexea';
import { SET_HEXEA_OBJECT } from './types';

const setHexea = async ({ servicePcct, dispatch }) => {
  const hexeaResult = await hexeaPromise();
  const hexea = hexeaResult(servicePcct);
  dispatch({ type: SET_HEXEA_OBJECT, hexea });
};

export default setHexea;
