import { useRef, useEffect } from 'react';

const useRevertToDefault = ({
  quill,
  defaultText,
  revertToDefault,
  setRevertToDefault,
}) => {
  const defaultTextRef = useRef(defaultText);

  useEffect(() => {
    if (revertToDefault) {
      quill.current.setContents([{ insert: defaultTextRef.current }]);
      setRevertToDefault(false);
    }
  }, [quill, revertToDefault, setRevertToDefault]);

  return {};
};

export default useRevertToDefault;
