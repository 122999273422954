export default function styles(theme) {
  const label = {
    alignItems: 'center',
    border: `solid 1px rgba(0, 0, 0, 0.23)`,
    borderRadius: 4,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    justifyContent: 'center',
    padding: '5px 10px',
  };

  return {
    label,
    labelDisabled: {
      ...label,
      cursor: 'default',
      opacity: 0.4,
    },
    text: {
      display: 'inline-flex',
      marginTop: 4,
      marginLeft: 5,
    },
    input: {
      height: 0.1,
      opacity: 0,
      overflow: 'hidden',
      width: 0.1,
      zIndex: -1,
    },
  };
}
