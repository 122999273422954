export function selectUserFullName(state) {
  const {
    given_name: givenName,
    family_name: familyName,
  } = state.user.session.idTokenPayload;

  return givenName && familyName ? `${givenName} ${familyName}` : '';
}

export function selectFirstName(state) {
  const { given_name: givenName } = state.user.session.idTokenPayload;
  return givenName;
}

export default {
  selectUserFullName,
  selectFirstName,
};
