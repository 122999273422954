import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from '../Table';

function ImportFileDetailTable({ data }) {
  const { t } = useTranslation();

  return (
    !!data && (
      <Table
        cols={[
          {
            key: 'firstName',
            displayName: t('ministry.platform.import.table.header.name'),
            width: '20%',
            sortable: true,
          },
          {
            key: 'lastName',
            displayName: t('ministry.platform.import.table.header.last.name'),
            width: '20%',
            sortable: true,
          },
          {
            key: 'email',
            displayName: t('ministry.platform.import.table.header.email'),
            width: '30%',
            sortable: true,
          },
          {
            key: 'error',
            subKey: 'description',
            displayName: t('ministry.platform.import.table.header.error'),
            width: '30%',
            sortable: true,
          },
        ]}
        data={data}
      />
    )
  );
}
ImportFileDetailTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ImportFileDetailTable;
