import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useMediaQuery } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { PrimaryButton } from '../../../common';
import styles from './styles';

export function Confirm({ classes, open, onClose, confirm, undo, path }) {
  const matches = useMediaQuery('(min-width:1280px)');
  const { t } = useTranslation();

  const onConfirm = () => {
    confirm(path);
  };

  const onUndo = () => {
    undo(path);
  };

  return (
    <Dialog fullScreen={!matches} open={open} onClose={onClose}>
      <DialogTitle>
        <IconButton
          onClick={onClose}
          size="small"
          classes={{ root: classes.closeButton }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Typography variant="h4">{t('confirm.save')}</Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <PrimaryButton onClick={onConfirm} size="large">
          {t('app.yes')}
        </PrimaryButton>
        <Button onClick={onUndo} size="large" color="primary">
          {t('app.noUndo')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Confirm.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  undo: PropTypes.func.isRequired,
  path: PropTypes.string,
};

Confirm.defaultProps = {
  path: '',
};

export default withStyles(styles)(Confirm);
