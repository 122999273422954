import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import NotFound from 'components/common/NotFound';
import withScrollToTop from '../../hoc/withScrollToTop';
import { useACSTBlocking, useVideoDisplayFlags } from '../../../utils/hooks';
import useUserSession from '../../../utils/hooks/useUser/useUserSession';
import { userIsAllowed } from '../../../utils/permissions';
import { VIDEO_ROUTES, VIDEO_WIZARD_ROUTES } from '../../../globals/constants';

function PrivateRouteWithVancoVideo({
  component: Component,
  requiredGrants,
  optionalGrants,
  fallbackPath,
  ...rest
}) {
  const WrappedComponent = useMemo(
    () => withScrollToTop(Component),
    [Component],
  );
  const { isLoggedIn, loadingAuth, grantedActions } = useUserSession();
  const location = useLocation();

  const { ffVancoVideo, ffSmartClips, ffSmartLibrary } = useVideoDisplayFlags();

  const { hideFeaturesForACST } = useACSTBlocking();

  const redirectStreamingToNotFound = !ffVancoVideo || hideFeaturesForACST;

  const routesGatingMap = {
    [VIDEO_ROUTES.liveStreaming]: () => redirectStreamingToNotFound,
    [VIDEO_ROUTES.videoSettings]: () => redirectStreamingToNotFound,
    [VIDEO_ROUTES.smartClips]: () => !ffSmartClips,
    [VIDEO_ROUTES.smartLibrary]: () => !ffSmartLibrary,
    [VIDEO_WIZARD_ROUTES.review]: () => redirectStreamingToNotFound,
    [VIDEO_WIZARD_ROUTES.pageInfo]: () => redirectStreamingToNotFound,
    [VIDEO_WIZARD_ROUTES.pageInfoDetail]: () => redirectStreamingToNotFound,
    [VIDEO_WIZARD_ROUTES.pageChatDetail]: () => redirectStreamingToNotFound,
    [VIDEO_WIZARD_ROUTES.pageGivingDetail]: () => redirectStreamingToNotFound,

    default: () => false,
  };

  const renderRoute = (props) => {
    if (loadingAuth) {
      return null;
    }

    const vancoVideoRoute = Object.values(VIDEO_ROUTES).find((route) =>
      location.pathname.includes(route),
    );

    const shouldRedirectToNotFound = (
      routesGatingMap[vancoVideoRoute] || routesGatingMap.default
    )();

    if (
      (isLoggedIn &&
        !userIsAllowed(grantedActions, requiredGrants, optionalGrants)) ||
      !ffVancoVideo ||
      shouldRedirectToNotFound ||
      hideFeaturesForACST
    ) {
      return <NotFound />;
    }

    if (isLoggedIn && fallbackPath) {
      return (
        <Redirect
          data-testid="redirect"
          to={{
            pathname: fallbackPath,
            state: { from: location },
          }}
        />
      );
    }

    if (isLoggedIn) {
      return <WrappedComponent {...rest} {...props} />;
    }

    return (
      <Redirect
        data-testid="redirect"
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  };

  return <Route {...rest} render={renderRoute} />;
}

PrivateRouteWithVancoVideo.defaultProps = {
  requiredGrants: [],
  optionalGrants: [],
  fallbackPath: '',
};

PrivateRouteWithVancoVideo.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  requiredGrants: PropTypes.arrayOf(PropTypes.string),
  optionalGrants: PropTypes.arrayOf(PropTypes.string),
  fallbackPath: PropTypes.string,
};

export default PrivateRouteWithVancoVideo;
