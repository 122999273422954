import { connect } from 'react-redux';
import GivingDashboard from './GivingDashboard';

const mapStateToProps = (state) => {
  return {
    orgId: state.organizations.currentOrganization.id,
    email: state.user.session.idTokenPayload.email,
  };
};

export default connect(mapStateToProps)(GivingDashboard);
