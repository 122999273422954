export default () => ({
  firstCheckbox: {
    marginTop: 8,
  },
  checkbox: {
    padding: '6px 12px',
  },
  formControl: {
    '& .MuiFormControl-marginNormal': {
      marginTop: 8,
      marginBottom: 11,
    },
  },
});
