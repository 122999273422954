import { connect } from 'react-redux';

import GetStartedVancoStream from './GetStartedVancoStream';
import { resetNewPage } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    loadingCreate: state.pages.loadingCreate,
    createPageError: state.pages.error,
    loadingPages: state.pages.loadingPages,
    hasLoadedPages: state.pages.hasLoadedPages,
    wasInternal: state.user.user.wasInternal,
    organizationStreamingDisabled:
      state.user.user.organizationStreamingDisabled,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, { resetNewPage })(
  GetStartedVancoStream,
);
