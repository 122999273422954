import { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { PropTypes } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles';
import {
  generateSignedUrl,
  setupPostMessageListener,
} from '../../../../../utils/sigma';

function FrameContent({ classes, url, clientPK, email }) {
  const [hash, setHash] = useState('');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!clientPK) return; // Optionally fetch clientPK if needed
    generateSignedUrl(url, email, clientPK).then(setHash);
  }, [url, email, clientPK]);

  useEffect(() => {
    const removeListener = setupPostMessageListener(setLoaded);
    return removeListener;
  }, []);

  return (
    <div className={classes.containerDiv}>
      {!loaded && (
        <div className={classes.spinnerContainer}>
          <CircularProgress
            color="primary"
            size={50}
            className={classes.spinner}
          />
        </div>
      )}

      {hash && (
        <div className={loaded ? classes.visible : classes.hide}>
          <iframe title="content" src={hash} className={classes.iframeStyle} />
        </div>
      )}
    </div>
  );
}

FrameContent.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  clientPK: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default withStyles(styles)(FrameContent);
