import { NAVBAR_HEIGHT } from '../../../globals/dimensions';

const main = {
  display: 'flex',
  flex: 1,
  height: '100%',
  paddingTop: NAVBAR_HEIGHT,
  overflow: 'hidden',
};

const content = {
  display: 'flex',
  alignItems: 'flex-start',
  height: '100%',
};

export default function styles(theme) {
  return {
    main: {
      ...main,
    },
    mainCentered: {
      ...main,
      justifyContent: 'center',
      overflow: 'auto',
    },
    contentWrap: {
      ...content,
      overflow: 'auto',
      flex: 1,
    },
    streamSiteSection: {
      display: 'flex',
      flexDirection: 'column',
    },
    contentWrapCentered: {
      ...content,
    },
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },
    },
    containerSmall: {
      display: 'inline',
    },
    pagePaper: {
      padding: '24px 24px 20px 24px',
      width: 600,
      margin: '32px 0',
    },
    pagePaperVO: {
      width: 900,
    },
  };
}
