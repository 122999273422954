import { objectOf, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import { Modal } from '../Modal';

import styles from './styles';

export function DeleteModalSpinner({ classes }) {
  return (
    <>
      <Modal.Title className={classes.title}>
        <Skeleton
          variant="text"
          height={31}
          width={150}
          data-testid="delete-modal-spinner"
        />
      </Modal.Title>
      <Modal.Content className={classes.content}>
        <Skeleton variant="text" height={24} />
        <Skeleton variant="text" height={24} width="50%" />
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <div className={classes.buttonPlaceholder} />
      </Modal.Actions>
    </>
  );
}

DeleteModalSpinner.propTypes = {
  classes: objectOf(string).isRequired,
};

export default withStyles(styles)(DeleteModalSpinner);
