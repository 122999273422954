export default function styles(theme) {
  return {
    container: {
      display: 'none',
      paddingBottom: 34,
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
      color: theme.palette.primary.light,
    },
    navTitle: {
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },
    progressBar: {
      height: 6,
      borderRadius: 4,
      backgroundColor: '#EBEBEB',
      marginBottom: 3,
    },
  };
}
