export default function styles(theme) {
  return {
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    buttonContainer: {
      marginTop: 20,
      textAlign: 'right',
    },
    buttonContainerDeprecated: {
      marginTop: 8,
    },
    savePublishButton: {
      marginLeft: theme.spacing(3),
    },
    goToSiteButton: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: '8px',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '8px',
      },
    },

    linkSiteText: {
      marginRight: '10px',
    },

    leftMargin: {
      marginLeft: '8px',
    },
    icon: {
      color: '#5E6266',
      marginRight: 18,
      width: 24,
      height: 24,
      '& path': {
        fill: '#5E6266',
      },
    },
    error: {
      color: '#C82947',
      '& path': {
        fill: '#C82947',
      },
    },
    pagePaper: {
      padding: '24px 40px 20px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 0 20px',
        width: 'unset',
      },
    },
    tabContent: {
      width: 552,
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
  };
}
