export default function styles() {
  return {
    container: {
      border: '1px solid #EBEBEB',
      borderRadius: 12,
      marginTop: 32,
      lineBreak: 'auto',
    },
    editLink: {
      color: '#30119B',
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: 1.8,
      display: 'flex',
      float: 'right',
    },
    sectionTitle: {
      padding: '20px 30px 0px 30px',
    },
    sectionSubtitle: {
      padding: '15px 0px 0px 30px',
      '&:last-of-type': {
        marginBottom: 24,
      },
      color: '#333333',
    },
    firstSubtitle: {
      paddingTop: 28,
    },
    subtitle: {
      width: 216,
    },
    titleFont: {
      color: '#9E9E9E',
      fontWeight: 'bold',
    },
    fontColor: {
      color: '#333333',
    },
    fontColorMargin: {
      color: '#333333',
      marginRight: 6,
    },
    colorDescription: {
      color: '#333333',
      marginLeft: 16,
    },
    colorBackground: {
      width: 24,
      height: 24,
      borderRadius: 2,
    },
    nonIconBackground: {
      width: 64,
      height: 64,
      borderRadius: 5.12,
      display: 'flex',
      justifyContent: 'center',
    },
    iconBackground: {
      width: 64,
      height: 64,
      borderRadius: 5.12,
      justifyContent: 'center',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    iconInitial: {
      fontSize: 24,
      alignSelf: 'center',
      color: 'white',
    },
    displayInline: {
      display: 'inline-flex',
    },
    button: {
      marginTop: 48,
    },
  };
}
