export default function styles() {
  return {
    bottomSpace: {
      marginBottom: 16,
    },
    content: {
      marginTop: -8,
    },
  };
}
