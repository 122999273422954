export const parseQueryParamsToObject = (queryParams = '') => {
  if (!queryParams) {
    return {};
  }

  const urlQueryParams = new URLSearchParams(queryParams);
  return Object.fromEntries(urlQueryParams);
};

export default {
  parseQueryParamsToObject,
};
