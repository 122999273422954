export default function styles(theme) {
  const listItemHover = {
    borderBottom: '1px solid #E6E6E6',
    borderTop: '1px solid #E6E6E6',
    position: 'relative',
    backgroundColor: '#F6F6F6',
    marginTop: '-1px',
    height: 56,
    padding: '0 24px',
    margin: 0,
  };

  const cardMediaImage = {
    borderRadius: 8,
    height: 132,
    width: 132,
    marginRight: 16,
  };

  return {
    root: {
      borderRadius: 12,
      boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.2)',
      maxWidth: 660,
    },
    title: {
      borderBottom: '1px solid #E6E6E6',
      lineHeight: '28px',
      paddingBottom: 16,
      margin: '24px 24px 0 24px',
    },
    cardList: {
      listStyle: 'none',
      padding: '0 0 8px 0',
      margin: 0,
    },
    cardListItem: {
      alignItems: 'center',
      borderBottom: 'solid 1px #E6E6E6',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 24px',
      height: 55,
      '&:hover': listItemHover,
      '&:last-of-type': {
        border: 0,
        '&:hover': {
          ...listItemHover,
          padding: '1px 24px 0 24px',
        },
      },
    },
    cardListItemDisabled: {
      alignItems: 'center',
      borderBottom: 'solid 1px #E6E6E6',
      display: 'flex',
      margin: '0 24px',
      height: 55,
      '&:last-of-type': {
        border: 0,
      },
    },
    cardListItemText: {
      fontSize: '1.6rem',
    },
    fowardIcon: {
      fontSize: '1.2rem',
    },
    cardMedia: {
      padding: '16px 24px 0 24px',
      display: 'flex',
    },
    cardMediaImageDefault: {
      ...cardMediaImage,
    },
    cardMediaImageWide: {
      ...cardMediaImage,
      width: 198,
    },
    cardMediaImagePlaceholder: {
      display: 'flex',
      background: theme.palette.primary.main,
      justifyContent: 'center',
    },
    cardMediaImageInitials: {
      color: 'white',
      fontSize: 60,
      alignSelf: 'center',
    },
    cardTextTitle: {
      display: 'block',
      fontSize: '1.8rem',
      fontWeight: 700,
      color: '#222222',
      marginBottom: 12,
    },
    cardTextDefault: {
      alignItems: 'center',
      display: 'flex',
      fontSize: '1.6rem',
      color: '#222222',
      '& svg': {
        marginRight: 10,
      },
    },
    cardTextSmall: {
      fontSize: '1.4rem',
      color: '#666666',
    },
    cardDescription: {
      padding: '16px 24px 0 24px',
      fontSize: '1.6rem',
      fontFamily: theme.typography.fontFamily,
    },
    readMore: {
      cursor: 'pointer',
      marginLeft: 5,
      fontWeight: 700,
      textDecoration: 'underline',
    },
    cardDisclosure: {
      margin: '16px 24px 24px 24px',
      padding: 16,
      backgroundColor: '#F5F5F6',
      borderRadius: 8,
    },
    cardDisclosureTitle: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 700,
      marginBottom: 4,
      '& svg': {
        marginRight: 8,
      },
    },
    cardDisclosureMessage: {
      fontSize: '1.4rem',
      marginLeft: 28,
    },
  };
}
