export default function styles() {
  return {
    container: {
      marginBottom: 40,
    },
    radioGroup: {
      marginTop: 20,
      '& label': {
        alignItems: 'flex-start',
        verticalAlign: 'top',
      },
    },
    radioButton: {
      paddingTop: 0,
    },
    formTitle: {
      lineHeight: '1.43',
    },
    formSubtitle: {
      fontWeight: 'normal',
    },
    actions: {
      justifyContent: 'space-between',
    },
    submitButton: {
      marginLeft: 8,
    },
  };
}
