import PropTypes from 'prop-types';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { compose } from 'redux';
import i18next from 'i18next';
import BasketItem from './BasketItem/BasketItem';
import PaymentMethodSummary from '../PaymentMethodSummary/PaymentMethodSummary';
import IOSSwitch from '../IOSSwitch/IOSSwitch';
import { addFeesToAmount, getCurrencyFormat } from '../../../utils/basket';
import {
  removeFromBasket,
  updateProcessingFee,
} from '../../../store/transaction/actions';
import getSumOfTextNumbers from '../../../utils/text/getSumOfTextNumbers';

import styles from './styles';

export function MyBasket(props) {
  const {
    removeFromBasketConnect,
    updatePaymentItemQuantityConnect,
    updateProcessingFeeConnect,
    enableProcessingFees,
    applyProcessingFee,
    processingFee,
    classes,
    items,
    paymentMethod,
    showSecurePaymentsMessage,
  } = props;

  const { t } = useTranslation();
  const displayFee = enableProcessingFees && Boolean(processingFee);

  const hasItems = items?.length > 0;

  const amountWithNoFee = hasItems
    ? parseFloat(getSumOfTextNumbers(items.map((i) => i.amount)), 10)
    : 0;

  const totalAmount = applyProcessingFee
    ? addFeesToAmount(amountWithNoFee, processingFee)
    : amountWithNoFee;

  const onItemDelete = (item) => {
    removeFromBasketConnect(item);
  };

  const onApplyProcessingFeesChange = (event) => {
    updateProcessingFeeConnect(event.target.checked);
  };

  const createKey = (campaign) => {
    return campaign
      ? `${campaign.fundId}-${campaign?.frequency}-${campaign?.startDate}`
      : campaign.fundId;
  };

  const updateQty = (quantity, campaign) => {
    const { campaignDetails } = campaign;
    updatePaymentItemQuantityConnect(campaignDetails.id, quantity);
  };

  return (
    <div className={classes.basketContainer}>
      <div className={classes.content}>
        <div className={classes.basketTopBar}>
          <Grid
            container
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
            <div className={classes.title}>
              <Typography variant="h5">{t('app.summary')}</Typography>
            </div>
          </Grid>
        </div>

        {hasItems ? (
          <>
            {items &&
              items.map((item) => (
                <BasketItem
                  className={classes.basketItem}
                  key={createKey(item)}
                  item={item}
                  onDelete={onItemDelete}
                  showProcessingFees={applyProcessingFee}
                  processingFee={processingFee}
                  onChangeQuantity={(qty) => updateQty(qty, item)}
                />
              ))}

            <Grid container className={classes.totalAmount}>
              <Grid item xs={6}>
                <Typography variant="h5" color="primary">
                  {t('basket.total')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  {getCurrencyFormat(totalAmount)}
                </Typography>
              </Grid>
            </Grid>

            {displayFee && (
              <Grid
                container
                className={classes.processingFeesSwitch}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" data-testid="cover-fees-legend">
                  {t('basket.cover.fees', { processingFee })}
                </Typography>
                <IOSSwitch
                  color="primary"
                  checked={applyProcessingFee}
                  onChange={onApplyProcessingFeesChange}
                />
              </Grid>
            )}

            {paymentMethod && (
              <div>
                <hr
                  className={classes.itemBreak}
                  style={{ marginTop: '40px', marginBottom: '30px' }}
                />
                <PaymentMethodSummary paymentMethod={paymentMethod} />
              </div>
            )}

            {showSecurePaymentsMessage && (
              <Grid
                container
                className={classes.paymentsCaptionContainer}
                justifyContent="center"
              >
                <LockOutlined />
                <Typography variant="caption">
                  {i18next.t('basket.paymentsProcessedSecurely')}
                </Typography>
              </Grid>
            )}
          </>
        ) : (
          <Box display="flex" justifyContent="center">
            <Typography variant="caption" className={classes.emptyMessage}>
              {t('newTransaction.noGiftsAdded')}
            </Typography>
          </Box>
        )}
      </div>

      {paymentMethod && (
        <div className={classes.captionContainer}>
          <Typography variant="caption">
            <Trans i18nKey="basket.recaptchaCaption">
              This site is protected by reCAPTCHA and the Google{' '}
              <a
                href="https://policies.google.com/privacy"
                className={classes.captionLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                href="https://policies.google.com/terms"
                className={classes.captionLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{' '}
              apply
            </Trans>
          </Typography>
        </div>
      )}
    </div>
  );
}

MyBasket.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  showSecurePaymentsMessage: PropTypes.bool,
  paymentMethod: PropTypes.object,
  removeFromBasketConnect: PropTypes.func.isRequired,
  enableProcessingFees: PropTypes.bool,
  processingFee: PropTypes.number,
  applyProcessingFee: PropTypes.bool.isRequired,
  updatePaymentItemQuantityConnect: PropTypes.func,
  updateProcessingFeeConnect: PropTypes.func.isRequired,
};

MyBasket.defaultProps = {
  paymentMethod: null,
  enableProcessingFees: false,
  processingFee: null,
  showSecurePaymentsMessage: false,
  updatePaymentItemQuantityConnect: () => {},
};

const mapStateToProps = (state) => ({
  items: state.transaction.basket,
  enableProcessingFees:
    state.transaction.paymentMethods?.creditCard?.enableFeeContribution ||
    state.transaction.paymentMethods?.ach?.enableFeeContribution,
  applyProcessingFee: state.transaction.selectedFees.applyProcessingFee,
  processingFee:
    state.transaction.selectedPaymentType === 'creditCard'
      ? state.transaction?.paymentMethods?.creditCard?.feeValue
      : state.transaction?.paymentMethods?.ach?.feeValue,
});

const mapDispatchToProps = (dispatch) => ({
  removeFromBasketConnect: (item) => dispatch(removeFromBasket(item)),
  updateProcessingFeeConnect: (checked) =>
    dispatch(updateProcessingFee(checked)),
});

export default compose(
  withRouter,
  withStyles(styles, { name: 'MyBasket' }),
  connect(mapStateToProps, mapDispatchToProps),
)(MyBasket);
