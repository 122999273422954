import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';

export function NotFound() {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = () => history.push('/');

  return (
    <div data-testid="not-found" className={classes.wrapper}>
      <Typography variant="body2" className={classes.notFound}>
        {t('app.notFound')}
      </Typography>
      <Button color="primary" onClick={handleClick}>
        {t('app.backToHome')}
      </Button>
    </div>
  );
}

export default NotFound;
