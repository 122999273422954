import { string, object, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useGetPages, useGetTiles } from '../../../utils/hooks';
import { getPagesByChannelAndStatus } from '../../../utils/page';
import { filterTilesByStatus } from '../../../utils/tiles';
import {
  CHANNEL_TYPE,
  PAGE_ACTIVE,
  TILE_ACTIVE,
} from '../../../globals/constants';
import CustomFormik from '../CustomFormik';
import PageDivider from '../PageDivider';
import PageTitle from '../PageTitle';
import Form from './Form';
import { createSchema } from './validation';

export function StreamPageGiving({
  savePage,
  goToNext,
  goToPrev,
  pageTitleKey,
  page,
  updateStream,
  setError,
  loadingSave,
  isFinished,
  newPage,
  resetNewPage,
}) {
  const { t } = useTranslation();
  const schema = createSchema();

  const donationsBaseLink = `${window.env.REACT_APP_DON_URL}/`;
  const { data: tiles = [], status: getTilesStatus } = useGetTiles();
  const { data: pages = [], status: getPagesStatus } = useGetPages();
  const onlineSites = getPagesByChannelAndStatus(
    pages,
    CHANNEL_TYPE.ONLINE,
    PAGE_ACTIVE,
  );
  const activeTiles = filterTilesByStatus(tiles, TILE_ACTIVE);
  const tilesLoading = getTilesStatus === 'loading';
  const pagesLoading = getPagesStatus === 'loading';

  useEffect(() => {
    if (goToNext && newPage.id) {
      goToNext();
      resetNewPage();
    }
  }, [newPage, goToNext, resetNewPage]);

  const buildGivingReference = (id, isTile) => {
    if (isTile) return `${donationsBaseLink}${page.locationURI}`;

    return `${donationsBaseLink}${
      onlineSites.find(s => s.id === id).locationURI
    }`;
  };

  const onSubmit = async values => {
    const referenceId = values.tileId ? values.tileId : values.siteId;
    const formPage = {
      ...page,
      streamGivingOption: {
        ...page.streamGivingOption,
        campaignId: values.tileId,
        locationId: values.siteId,
      },
      streamData: {
        ...page.streamData,
        givingReference: buildGivingReference(referenceId, !!values.tileId),
      },
    };
    await savePage(formPage);
  };

  const handleUpdateGiving = async (key, value) => {
    if (updateStream) {
      let override;
      if (value) {
        const isTile = key === 'tileId';
        override = {
          streamData: { givingReference: buildGivingReference(value, isTile) },
          streamGivingOption: {
            campaignId: isTile ? value : undefined,
            locationId: isTile ? undefined : value,
          },
        };
      } else {
        override = {
          streamData: { givingReference: undefined },
          streamGivingOption: { campaignId: undefined, locationId: undefined },
        };
      }
      updateStream(override);
    }
  };

  return (
    <>
      <PageTitle variant="small">{t(pageTitleKey)}</PageTitle>
      {!updateStream && <PageDivider variant="title" />}
      <CustomFormik
        onChange={handleUpdateGiving}
        onErrorsChange={setError}
        initialValues={{
          tileId: page.streamGivingOption?.campaignId || undefined,
          // siteId: page.streamGivingOption?.locationId || undefined,
          // Adding the GiveReference comparison due to an error where locationId was registered in our DB,
          // but givingReference was null in CosmosDB. (replace with the previous line when not needed anymore)
          siteId:
            page.streamGivingOption?.locationId &&
            page.streamData.givingReference
              ? page.streamGivingOption?.locationId
              : undefined,
        }}
        onSubmit={onSubmit}
        enableReinitialize
        render={formikProps => (
          <Form
            goToPrev={goToPrev}
            loading={loadingSave}
            isFinished={isFinished}
            isWizard={!updateStream}
            pages={onlineSites}
            tiles={activeTiles}
            pagesLoading={pagesLoading}
            tilesLoading={tilesLoading}
            {...formikProps}
          />
        )}
        validationSchema={schema}
      />
    </>
  );
}

StreamPageGiving.propTypes = {
  savePage: func.isRequired,
  goToNext: func,
  goToPrev: func,
  pageTitleKey: string,
  page: object.isRequired,
  updateStream: func,
  setError: func,
  loadingSave: bool.isRequired,
  isFinished: bool,
  newPage: object.isRequired,
  resetNewPage: func.isRequired,
};

StreamPageGiving.defaultProps = {
  goToNext: null,
  goToPrev: null,
  pageTitleKey: '',
  updateStream: null,
  setError: null,
  isFinished: false,
};

export default StreamPageGiving;
