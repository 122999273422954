import { ButtonBase, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

function LargeIconButton({ classes, isActive, icon, children, ...props }) {
  return (
    <ButtonBase
      className={`${classes.button} ${isActive ? classes.active : ''}`}
      {...props}
    >
      {icon}
      {children}
    </ButtonBase>
  );
}

LargeIconButton.defaultProps = {
  isActive: false,
};

LargeIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.element.isRequired,
  isActive: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default withStyles(styles)(LargeIconButton);
