export default () => ({
  title: {
    marginBottom: 20,
  },
  card: {
    marginTop: 24,
  },
  cardText: {
    marginTop: 12,
  },
  emptyMessage: {
    margin: '16px 24px',
  },
  divider: {
    marginBottom: 16,
  },
  positionIcon: {
    marginLeft: 3,
  },
  languageIcon: {
    marginLeft: -2,
    marginRight: 8,
    color: '#5E6266',
  },
  groupIcon: {
    width: 24,
  },
  locationText: {
    marginLeft: 1,
  },
  webText: {
    marginLeft: -1,
  },
  noIconText: {
    marginLeft: 30,
  },
  address: {
    marginLeft: 30,
  },
  emptyDiv: {
    marginBottom: 20,
  },
  detailsButton: {
    color: '#30119B',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: -6,
    marginLeft: 22,
  },
});
