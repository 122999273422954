// GET Org Settings
export const GET_ORG_SETTINGS_START = 'GET_ORG_SETTINGS_START';
export const GET_ORG_SETTINGS_FINISH = 'GET_ORG_SETTINGS_FINISH';
export const GET_ORG_SETTINGS_ERROR = 'GET_ORG_SETTINGS_ERROR';

// SAVE Org Settings
export const SAVE_ORG_SETTINGS_START = 'SAVE_ORG_SETTINGS_START';
export const SAVE_ORG_SETTINGS_FINISH = 'SAVE_ORG_SETTINGS_FINISH';
export const SAVE_ORG_SETTINGS_ERROR = 'SAVE_ORG_SETTINGS_ERROR';

// GET Org Features
export const GET_ORG_FEATURES_START = 'GET_ORG_FEATURES_START';
export const GET_ORG_FEATURES_FINISH = 'GET_ORG_FEATURES_FINISH';
export const GET_ORG_FEATURES_ERROR = 'GET_ORG_FEATURES_ERROR';

// GET Assigned Org Permissions
export const GET_ORG_GRANTS_START = 'GET_ORG_GRANTS_START';
export const GET_ORG_GRANTS_FINISH = 'GET_ORG_GRANTS_FINISH';
export const GET_ORG_GRANTS_ERROR = 'GET_ORG_GRANTS_ERROR';

// GET ALL Org Permissions
export const GET_ALL_ORG_GRANTS_START = 'GET_ALL_ORG_GRANTS_START';
export const GET_ALL_ORG_GRANTS_FINISH = 'GET_ALL_ORG_GRANTS_FINISH';
export const GET_ALL_ORG_GRANTS_ERROR = 'GET_ALL_ORG_GRANTS_ERROR';

// SAVE ALL Org Permissions
export const SAVE_ORG_PERMISSIONS_START = 'SAVE_ORG_PERMISSIONS_START';
export const SAVE_ORG_PERMISSIONS_FINISH = 'SAVE_ORG_PERMISSIONS_FINISH';
export const SAVE_ORG_PERMISSIONS_ERROR = 'SAVE_ORG_PERMISSIONS_ERROR';
