import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getBibleVersion,
  setBibleVersion,
} from '../../../api/FaithForwardService';
import { setError, setSuccess } from '../../../store/alerts/actions';

function useBibleVersion() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { session } = useSelector((state) => state.user, shallowEqual);
  const { currentOrganization } = useSelector(
    (state) => state.organizations,
    shallowEqual,
  );
  const { data, isLoading } = useQuery('bibleVersion', () =>
    getBibleVersion(currentOrganization.id, session.accessToken),
  );
  const versionMutation = useMutation(
    (version) =>
      setBibleVersion(currentOrganization.id, version, session.accessToken),
    {
      onSuccess: () => {
        dispatch(setSuccess(t('site.changes.saved')));
      },
      onError: () => {
        dispatch(setError(t('errors.somethingWrong')));
      },
    },
  );
  return {
    setBibleVersion: versionMutation.mutate,
    bibleVersion: data?.bibleVersion,
    loadingVersion: isLoading,
  };
}

export default useBibleVersion;
