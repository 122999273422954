import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { PageTitle, Page } from '../../common';
import ImportFilesTable from '../../import/ImportFilesTable';
import useImportFiles from '../../import/hooks/useImportFiles';
import { IMPORT_STATUS } from '../../import/utils/const';
import ActionsMenu from '../../import/ActionsMenu';
import styles from './styles';

function ImportedFilesView({ classes, accessToken, idsPayerTenant }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [isImportButtonDisabled, setisImportButtonDisabled] = useState(true);
  const [importFiles, error, loading] = useImportFiles(
    idsPayerTenant,
    accessToken,
  );

  useEffect(() => {
    if (!loading && (!importFiles || importFiles.length < 1)) {
      history.push('/new-import');
    }
    if (importFiles?.length > 0) {
      setisImportButtonDisabled(
        importFiles.some((i) => i.status === IMPORT_STATUS.IN_PROGRESS),
      );
    }
  }, [importFiles, loading, history]);

  const handleItemSelected = (item) => {
    const { status, id } = item;
    if (
      status === IMPORT_STATUS.FAILED ||
      status === IMPORT_STATUS.COMPLETE_WITH_ERRORS
    ) {
      history.push(`/import/${id}`);
    }
  };

  return (
    <Page>
      <div className={classes.titleContainer}>
        <PageTitle>{t('ministry.platform.import.page.title')}</PageTitle>
        {!loading && (
          <ActionsMenu isImportButtonDisabled={isImportButtonDisabled} />
        )}
      </div>

      {!loading && (
        <div className={classes.container}>
          {idsPayerTenant && !error && (
            <ImportFilesTable
              idsPayerTenant={idsPayerTenant}
              accessToken={accessToken}
              onItemSelected={handleItemSelected}
            />
          )}
        </div>
      )}
    </Page>
  );
}

ImportedFilesView.propTypes = {
  classes: PropTypes.object.isRequired,
  accessToken: PropTypes.string.isRequired,
  idsPayerTenant: PropTypes.string.isRequired,
};

export default withStyles(styles)(ImportedFilesView);
