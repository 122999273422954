import { string, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from '../../../common';
import { constants } from '../../../../globals';

function TileBuilderTabs({ value, onChange }) {
  const { t } = useTranslation();
  return (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      value={value}
      onChange={onChange}
      variant="scrollable"
    >
      <Tab value={constants.TILE_ALL} label={t('tile.all')} />
      <Tab value={constants.TILE_VISIBLE} label={t('tile.visible')} />
      <Tab value={constants.TILE_HIDDEN} label={t('tile.hidden')} />
      <Tab value={constants.TILE_PENDING} label={t('tile.draft')} />
    </Tabs>
  );
}

TileBuilderTabs.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
};

export default TileBuilderTabs;
