import { FEATURES_TYPE, PAYWALL_MAP } from 'globals/constants';
import { useSelector } from 'react-redux';
import useUser from '../useUser';
import useFeatures from '../useFeatures/useFeatures';
import { hasPremiumFeature } from './helpers';

const usePaywall = () => {
  const { features } =
    useSelector((state) => state.organizations?.currentOrganization) ?? {};
  const { wasInternal: isInternalUser } = useUser();
  const { Insights: ffInsights } = useFeatures();

  /* redirects to the corresponding paywall */
  const redirectInsights = Boolean(
    ffInsights &&
      !isInternalUser &&
      !hasPremiumFeature({ features, featureType: FEATURES_TYPE.Insights }),
  );

  return {
    [PAYWALL_MAP.INSIGHTS]: redirectInsights,
  };
};

export default usePaywall;
