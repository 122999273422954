export default function styles(theme) {
  return {
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    leftMargin: {
      marginLeft: '8px',
    },
    actionsTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      color: theme.palette.text.subdued,
      marginLeft: 8,
    },

    buttonContainer: {
      marginTop: 20,
      textAlign: 'right',
    },
    icon: {
      color: '#5E6266',
      marginRight: 18,
      '& path': {
        fill: '#5E6266',
      },
    },
  };
}
