import { bool, func, objectOf, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Modal from '../../Modal';
import MultipleEmailSelector from '../../MultipleEmailSelector';

import styles from './styles';

function InviteByEmailModal({
  classes,
  open,
  onClose,
  onSubmit,
  isSubmitting,
}) {
  const { t } = useTranslation();

  const sumit = (values, formikFunctions) => {
    if (onSubmit) {
      const validEmails = values.emails
        .filter(e => e.isValid)
        .map(e => e.email);
      onSubmit({ emails: validEmails }, formikFunctions);
    }
  };
  const close = resetForm => () => {
    onClose();
    resetForm();
  };

  return (
    <Formik
      initialValues={{
        emails: [],
      }}
      onSubmit={sumit}
    >
      {({ resetForm, values }) => {
        const validEmails = (values.emails || []).filter(e => e.isValid);
        return (
          <Modal open={open} onClose={close(resetForm)}>
            <Form>
              <Modal.Title>
                {t('vanco.mobile.home.users.invite.by.email')}
              </Modal.Title>
              <Modal.Content className={classes.modalContent}>
                <Typography className={classes.description}>
                  {t('vanco.mobile.home.users.email.modal.description')}
                </Typography>
                <FormControl fullWidth>
                  <MultipleEmailSelector name="emails" />
                </FormControl>
              </Modal.Content>
              <Modal.Actions className={classes.modalActions}>
                <div>
                  {validEmails.length > 0 && (
                    <Typography className={classes.description}>
                      {t(
                        `vanco.mobile.home.users.invites.to.send${
                          validEmails.length > 1 ? '.multiple' : ''
                        }`,
                        {
                          invites: validEmails.length,
                        },
                      )}
                    </Typography>
                  )}
                </div>
                <div>
                  <Button
                    className={classes.cancelButton}
                    type="button"
                    variant="outlined"
                    onClick={close(resetForm)}
                  >
                    <Typography className={classes.cancelButtonText}>
                      {t('button.cancel')}
                    </Typography>
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || validEmails.length < 1}
                  >
                    <Typography className={classes.sendButtonText}>
                      {t('vanco.mobile.home.users.email.modal.send')}
                    </Typography>
                  </Button>
                </div>
              </Modal.Actions>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}

InviteByEmailModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func,
  onSubmit: func,
  isSubmitting: bool,
};

InviteByEmailModal.defaultProps = {
  open: false,
  onClose: null,
  onSubmit: null,
  isSubmitting: false,
};

export default withStyles(styles)(InviteByEmailModal);
