import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useGetPage } from '../useGetPage/useGetPage';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';
import VancoMobileUsersService from '../../../api/VancoMobileUsersService';
import { handleException } from '../../../store/alerts/actions';

async function getMobileInviteCode() {
  const response = await VancoMobileUsersService.getInviteCode();
  return response.data.data;
}

export function useGetMobileInviteCode(config = {}) {
  const dispatch = useDispatch();
  const { page, isLoading } = useGetPage(CHANNEL_TYPE.MOBILE, PAGE_ACTIVE);
  const { data = {}, status, isLoading: usersIsLoading, ...rest } = useQuery({
    queryKey: ['mobile-invite-code', page.id],
    queryFn: getMobileInviteCode,
    enabled: !!page.id,
    onError: error => {
      dispatch(handleException(error));
    },
    ...config,
  });

  return {
    ...rest,
    invitationCode: data?.toString(),
    isLoading: isLoading || usersIsLoading,
    status,
  };
}

export default useGetMobileInviteCode;
