export default function styles(theme) {
  const common = {
    minHeight: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  };
  return {
    tabsDeprecated: {
      marginTop: 35,
      ...common,
    },
    tabs: {
      marginTop: 12,
      ...common,
    },
  };
}
