import {
  GET_TILES_START,
  GET_TILES_FINISH,
  GET_TILES_ERROR,
  SAVE_TILE_START,
  SAVE_TILE_FINISH,
  SAVE_TILE_ERROR,
  UPDATE_TILE,
  CREATE_TILE_START,
  CREATE_TILE_FINISH,
  CREATE_TILE_ERROR,
  RESET_NEW_TILE,
  DELETE_TILE_START,
  DELETE_TILE_FINISH,
  DELETE_TILE_ERROR,
  SAVE_TILES_START,
  SAVE_TILES_FINISH,
  SAVE_TILES_ERROR,
  UPDATE_IMAGE_TILE_ERROR,
  UPDATE_IMAGE_TILE_FINISH,
  UPDATE_IMAGE_TILE_START,
  CLEAR_TEMP_IMAGE,
  CLEAR_TILES,
} from './types';
import { USER_LOGOUT } from '../user/types';
import { backendToFrontend } from './transform';

const INITIAL_STATE = {
  loadingTiles: false,
  loadingTile: false,
  loadingSave: false,
  loadingCreate: false,
  loadingUpdateImage: false,
  tempImage: null,
  error: null,
  tiles: [],
  newTile: {},
};

export default (state = INITIAL_STATE, action = {}) => {
  const createOrReplaceTile = (tile, convertToFrontend = true) => {
    const newTile = convertToFrontend ? backendToFrontend(tile) : tile;
    return [
      newTile,
      ...state.tiles.filter(t => {
        return t.id !== tile.id;
      }),
    ];
  };
  const replaceTiles = (tiles, convertToFrontend = true) => {
    const newTiles = [...state.tiles];
    tiles.forEach(backendTile => {
      let found = false;
      state.tiles.forEach((frontendTile, index) => {
        if (frontendTile.id === backendTile.id) {
          const newTile = convertToFrontend
            ? backendToFrontend(backendTile)
            : backendTile;
          const updatedTile = {
            ...frontendTile,
            ...newTile,
          };

          found = true;
          newTiles[index] = updatedTile;
        }
      });
      if (!found) {
        newTiles.push(backendToFrontend(backendTile));
      }
    });
    return newTiles;
  };

  switch (action.type) {
    // GET TILES
    case GET_TILES_START:
      return {
        ...state,
        error: null,
        loadingTiles: true,
      };

    case GET_TILES_FINISH:
      return {
        ...state,
        error: null,
        loadingTiles: false,
        tiles: action.data,
      };

    case GET_TILES_ERROR:
      return {
        ...state,
        error: action.error,
        loadingTiles: false,
      };

    // SAVE

    case SAVE_TILE_START:
      return {
        ...state,
        error: null,
        loadingSave: true,
      };

    case SAVE_TILE_FINISH: {
      const tile = action.data;
      const tiles = createOrReplaceTile(tile);

      return {
        ...state,
        error: null,
        loadingSave: false,
        newTile: tile,
        tempImage: null,
        tiles,
      };
    }

    case SAVE_TILE_ERROR:
      return {
        ...state,
        error: action.error,
        loadingSave: false,
      };

    // SAVE tiles

    case SAVE_TILES_START:
      return {
        ...state,
        error: null,
        loadingSave: true,
      };

    case SAVE_TILES_FINISH: {
      const tiles = replaceTiles(action.data, false);
      return {
        ...state,
        error: null,
        loadingSave: false,
        tiles,
      };
    }

    case SAVE_TILES_ERROR:
      return {
        ...state,
        error: action.error,
        loadingSave: false,
      };

    // DELETE

    case DELETE_TILE_START:
      return {
        ...state,
        error: null,
        loadingDelete: true,
      };

    case DELETE_TILE_FINISH: {
      const tile = action.data;
      const tiles = [
        ...state.tiles.filter(t => {
          return t.id !== tile.id;
        }),
      ];

      return {
        ...state,
        error: null,
        loadingDelete: false,
        tiles,
      };
    }

    case DELETE_TILE_ERROR:
      return {
        ...state,
        error: action.error,
        loadingDelete: false,
      };

    // UPDATE
    case UPDATE_TILE: {
      const tile = action.data;
      const tiles = createOrReplaceTile(tile, false);

      return {
        ...state,
        tiles,
      };
    }

    // CREATE

    case CREATE_TILE_START:
      return {
        ...state,
        error: null,
        loadingCreate: true,
      };

    case CREATE_TILE_FINISH: {
      const tile = action.data;
      const tiles = createOrReplaceTile(tile);

      return {
        ...state,
        error: null,
        loadingCreate: false,
        tiles,
        newTile: tile,
      };
    }

    case CREATE_TILE_ERROR:
      return {
        ...state,
        error: action.error,
        loadingCreate: false,
      };

    // UPDATE IMAGE

    case UPDATE_IMAGE_TILE_START:
      return {
        ...state,
        error: null,
        loadingUpdateImage: true,
        tempImage: null,
      };

    case UPDATE_IMAGE_TILE_FINISH: {
      const { tempImage } = action;

      return {
        ...state,
        error: null,
        loadingUpdateImage: false,
        tempImage,
      };
    }

    case UPDATE_IMAGE_TILE_ERROR:
      return {
        ...state,
        error: action.error,
        loadingUpdateImage: false,
      };

    // RESET

    case RESET_NEW_TILE:
      return {
        ...state,
        newTile: {},
      };

    case CLEAR_TEMP_IMAGE:
      return {
        ...state,
        tempImage: null,
      };

    case USER_LOGOUT: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }

    case CLEAR_TILES:
      return INITIAL_STATE;

    default:
      return state;
  }
};
