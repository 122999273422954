import { string, object, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import PageTitle from '../PageTitle';
import CustomFormik from '../CustomFormik';
import Form from './Form';
import { getPagePermissions } from '../../../utils/page';
import { maxLengths } from '../../../utils/validations';
import { createSchema } from './validation';

export function VancoMobileAppPermissionsWizard({
  savePage,
  goToNext,
  goToPrev,
  pageTitleKey,
  page,
  updatePage,
  setError,
  loadingSave,
  isFinished,
  setPageCommunityFeaturesChoosen,
}) {
  const { t } = useTranslation();
  const schema = createSchema();

  const onSubmit = async (values) => {
    const formPage = {
      ...page,
      mobileAppPermissions: {
        ...values,
        clientPK: page.mobileAppPermissions
          ? page.mobileAppPermissions.clientPK
          : null,
      },
      hasCommunityFeatures: values.hasCommunityFeatures,
      updateMobileAppPermissions: true,
    };

    await savePage(formPage);
    if (!values.hasCommunityFeatures) {
      setPageCommunityFeaturesChoosen(true);
    }
    goToNext();
  };

  // TODO: Remove with ChannelManagement FF cleanup
  return (
    <>
      <PageTitle>{t(pageTitleKey)}</PageTitle>
      <CustomFormik
        onChange={updatePage}
        onErrorsChange={setError}
        initialValues={getPagePermissions(page)}
        onSubmit={onSubmit}
        enableReinitialize
        render={(formikProps) => (
          <Form
            {...formikProps}
            maxLengths={maxLengths}
            goToPrev={goToPrev}
            loading={loadingSave}
            isFinished={isFinished}
          />
        )}
        validationSchema={schema}
      />
    </>
  );
}

VancoMobileAppPermissionsWizard.propTypes = {
  savePage: func.isRequired,
  goToNext: func.isRequired,
  goToPrev: func.isRequired,
  pageTitleKey: string,
  page: object.isRequired,
  updatePage: func,
  setError: func,
  loadingSave: bool.isRequired,
  isFinished: bool,
  setPageCommunityFeaturesChoosen: func.isRequired,
};

VancoMobileAppPermissionsWizard.defaultProps = {
  pageTitleKey: '',
  updatePage: null,
  setError: null,
  isFinished: false,
};

export default VancoMobileAppPermissionsWizard;
