export default function styles() {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
      gap: '20px',
      marginBottom: '20px',
    },
    formItemGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
  };
}
