import { connect } from 'react-redux';
import AppProgressBar from './AppProgressBar';

export function mapStateToProps(state) {
  return {
    isLoading: !!state.loading.appIsLoading,
  };
}

export default connect(
  mapStateToProps,
  {},
)(AppProgressBar);
