import { connect } from 'react-redux';

import VancoMobileEditProcessingFees from './VancoMobileEditProcessingFees';
import { savePage } from '../../../store/pages/actions';
import { setSuccess } from '../../../store/alerts/actions';

export const mapStateToProps = state => ({
  loadingSave: state.pages.loadingSave,
});

export default connect(
  mapStateToProps,
  {
    savePage,
    setSuccess,
  },
)(VancoMobileEditProcessingFees);
