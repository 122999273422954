import { node, arrayOf, string, bool } from 'prop-types';
import { useUser } from 'utils/hooks';
import { userIsAllowed } from '../../../utils/permissions';

export function AuthorizationWrapper({
  requiredGrants,
  optionalGrants,
  children,
  disabled,
}) {
  const { grantedActions } = useUser();

  if (
    disabled ||
    userIsAllowed(grantedActions, requiredGrants, optionalGrants)
  ) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return null;
}

AuthorizationWrapper.defaultProps = {
  disabled: false,
};

AuthorizationWrapper.propTypes = {
  children: node.isRequired,
  requiredGrants: arrayOf(string),
  optionalGrants: arrayOf(string),
  disabled: bool,
};

AuthorizationWrapper.defaultProps = {
  requiredGrants: [],
  optionalGrants: [],
};

export default AuthorizationWrapper;
