import { useState, Fragment } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { func, objectOf, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { useIsFetching, useQueryClient } from 'react-query';
import Modal from '../../Modal';
import PrimaryButton from '../../PrimaryButton';
import styles from './styles';
import { TILE_ACTIVE } from '../../../../globals/constants';
import { useGetTiles } from '../../../../utils/hooks';
import { ReactComponent as TilesIcon } from '../../../../assets/tilesIcon.svg';
import { useUpdateTileStatus } from '../../../../utils/hooks/tiles';

function AddTilesModal({ classes, closeModal, pageId }) {
  const { t } = useTranslation();
  const isFetching = useIsFetching();
  const { data: tiles = [] } = useGetTiles();
  const { mutate: updateTileStatus } = useUpdateTileStatus();
  const queryClient = useQueryClient();
  const hiddenTiles = tiles.filter(
    (tile) =>
      tile.status === TILE_ACTIVE &&
      !tile.locations.some((l) => l.id === pageId),
  );

  const [checkedIds, setCheckedIds] = useState([]);

  const handleToggle = (tileId) => () => {
    const currentIndex = checkedIds.indexOf(tileId);
    const newChecked = [...checkedIds];

    if (currentIndex === -1) {
      newChecked.push(tileId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedIds(newChecked);
  };

  const showZeroState = hiddenTiles.length === 0;

  const addTiles = async () => {
    closeModal();
    await updateTileStatus({
      pageId,
      tileIds: checkedIds,
      tileStatus: TILE_ACTIVE,
    });
    queryClient.removeQueries(['tile']);
  };

  const modalClasses = {
    titleContainer: classes.titleContainer,
    content: classes.content,
    closeIcon: classes.closeIcon,
    actions: classes.actions,
    dialogPaper: classes.dialogPaper,
  };

  const renderZeroState = () => (
    <>
      <Grid container justifyContent="center">
        <TilesIcon className={classes.tileIcon} />
      </Grid>
      <Grid container justifyContent="center">
        <Typography variant="body2">
          {t('archivedModal.zeroStateHeading')}
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
        className={classes.bottomText}
      >
        <Typography variant="body1">
          {t('archivedModal.zeroStateDescription1')}
        </Typography>
        <Typography variant="body1">
          <span>{`${t('archivedModal.zeroStateDescription2')} `}</span>
          <Link to="/tiles/pending" className={classes.underlined}>
            {t('archivedModal.zeroStateDescription3')}
          </Link>
          <span>{` ${t('word.or')} `}</span>
          <Link
            to="/tiles/all?showArchived=true"
            className={classes.underlined}
          >
            {t('archivedModal.zeroStateDescription4')}
          </Link>
          <span>.</span>
        </Typography>
      </Grid>
    </>
  );

  const renderList = () => (
    <List className={classes.list}>
      {hiddenTiles.map((tile, index) => {
        const labelId = `checkbox-list-label-${tile.id}`;

        return (
          <Fragment key={tile.id}>
            <ListItem
              role={undefined}
              button
              className={classes.listItem}
              onClick={handleToggle(tile.id)}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Checkbox
                  edge="start"
                  color="primary"
                  checked={checkedIds.indexOf(tile.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primaryTypographyProps={{ variant: 'body1' }}
                primary={tile.title}
              />
            </ListItem>
            {index < hiddenTiles.length - 1 && (
              <Divider variant="inset" component="li" />
            )}
          </Fragment>
        );
      })}
    </List>
  );

  const renderContent = showZeroState ? renderZeroState : renderList;

  return (
    <Modal classes={modalClasses} onClose={closeModal} open>
      <Modal.Title className={classes.title}>
        {t('tilesOrder.addTilesDialog.title')}
      </Modal.Title>
      <Modal.Content>
        {isFetching ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className={classes.progressContainer}
          >
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          renderContent()
        )}
      </Modal.Content>
      {!showZeroState && (
        <Modal.Actions className={classes.actions}>
          <Button onClick={closeModal} size="medium" color="primary">
            {t('button.cancel')}
          </Button>
          <PrimaryButton
            size="medium"
            disabled={checkedIds.length === 0}
            onClick={addTiles}
            data-testid="confirm-add-tiles"
          >
            {t('tilesOrder.addTilesDialog.addTiles')}
          </PrimaryButton>
        </Modal.Actions>
      )}
    </Modal>
  );
}

AddTilesModal.propTypes = {
  classes: objectOf(string).isRequired,
  closeModal: func.isRequired,
  pageId: string.isRequired,
};

AddTilesModal.defaultProps = {};

export default withStyles(styles)(AddTilesModal);
