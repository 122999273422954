import { node, oneOf, string, oneOfType, number } from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { useCardContext } from '../context';

function CardText({ variant, className, spinnerWidth, children, ...rest }) {
  const { classes, isLoading } = useCardContext();
  const textClasses = clsx({
    [classes.cardTextTitle]: variant === 'title',
    [classes.cardTextDefault]: variant === 'default',
    [classes.cardTextSmall]: variant === 'small',
    [className]: !!className,
  });

  if (isLoading) {
    return (
      <Skeleton className={textClasses} variant="text" width={spinnerWidth} />
    );
  }

  return (
    <Typography component="div" className={textClasses} {...rest}>
      {children}
    </Typography>
  );
}

CardText.propTypes = {
  children: node,
  variant: oneOf(['title', 'default', 'small']),
  className: string,
  spinnerWidth: oneOfType([string, number]),
};

CardText.defaultProps = {
  children: null,
  variant: 'default',
  className: null,
  spinnerWidth: 150,
};

export default CardText;
