import { connect } from 'react-redux';

import GetStarted from './GetStarted';
import { resetNewPage } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    loadingCreate: state.pages.loadingCreate,
    createPageError: state.pages.error,
    loadingPages: state.pages.loadingPages,
    hasLoadedPages: state.pages.hasLoadedPages,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, { resetNewPage })(GetStarted);
