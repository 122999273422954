import { connect } from 'react-redux';

import TileReview from './TileReview';
import { saveTile, resetNewTile } from '../../../store/tiles/actions';

export function mapStateToProps(state) {
  return {
    loadingSave: state.tiles.loadingSave,
    newTile: state.tiles.newTile,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, { saveTile, resetNewTile })(TileReview);
