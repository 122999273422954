export default function styles() {
  return {
    infoContainer: {
      padding: 20,
      borderRadius: 4,
      backgroundColor: '#F6F6F6',
      margin: '20px 0',
    },
    infoNotice: {
      marginBottom: 10,
    },
  };
}
