export default function styles() {
  return {
    container: {
      margin: '24px 24px 0 0',
      height: 48,
      width: 48,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    circle: {
      cursor: 'pointer',
      borderRadius: '50%',
      height: 40,
      width: 40,
      '&:hover': {
        height: 48,
        width: 48,
      },
    },
    selectedCircle: {
      alignItems: 'center',
      borderRadius: '50%',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      height: 48,
      width: 48,
    },
  };
}
