import { arrayOf, func, object, objectOf, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import {
  Table,
  TableBody,
  TableHeadCell,
  TableCell,
  TableHead,
  TableRow,
} from '../../../common';
import Tile from '../Tile';

import styles from './styles';

export function ArchivedList({
  classes,
  tiles,
  onItemClick,
  onDeleteTile,
  onUnarchiveTile,
  pages,
}) {
  const { t } = useTranslation();

  return (
    <Table disabled={tiles.length < 1}>
        <TableHead>
          <TableRow isHeader>
            <TableHeadCell>
              <Typography variant="body2">{t('tile.tableHeader1')}</Typography>
            </TableHeadCell>
            <TableHeadCell className={classes.smallColumn}>
              <Typography variant="body2">{t('tile.archived')}</Typography>
            </TableHeadCell>
            <TableHeadCell className={classes.moreMenu} menu />
          </TableRow>
        </TableHead>
        <TableBody>
          {tiles.length > 0 ? (
            tiles.map(tile => (
              <Tile
                key={tile.id}
                tile={tile}
                onClick={onItemClick}
                onDeleteTile={onDeleteTile}
                onUnarchiveTile={onUnarchiveTile}
                pages={pages}
              />
            ))
          ) : (
            <TableRow>
              <TableCell>
                <Typography
                  component="span"
                  className={classes.noArchivedTiles}
                >
                  {t('tileManagement.emptyArchiveTileListHeading')}
                </Typography>
              </TableCell>
              <TableCell />
              <TableCell menu />
            </TableRow>
          )}
        </TableBody>
      </Table>
  );
}

ArchivedList.propTypes = {
  classes: objectOf(string).isRequired,
  tiles: arrayOf(object),
  onItemClick: func,
  onDeleteTile: func,
  onUnarchiveTile: func,
  pages: arrayOf(object).isRequired,
};

ArchivedList.defaultProps = {
  tiles: [],
  onItemClick: null,
  onDeleteTile: null,
  onUnarchiveTile: null,
};

export default withStyles(styles)(ArchivedList);
