import { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';

import styles from './styles';
import { Page, PageTitle, OutlinedSelect } from '../../common';
import { useDashboard } from '../../../utils/hooks';

const DASHBOARD_HEIGHTS = {
  dashboard1: 905,
  dashboard2: 905,
};

function Dashboard({ classes }) {
  const [dashboardType, setDashboardType] = useState('dashboard1');
  const { url, error } = useDashboard({ type: dashboardType });
  const { t } = useTranslation();

  const handleDashboardSelect = event => {
    setDashboardType(event.target.value);
  };

  if (error || !url) return null;

  return (
    <Page>
      <section className={classes.rightOptions}>
        <OutlinedSelect
          id="dashboardType"
          name="dashboardType"
          value={dashboardType}
          label={t('dashboard.select')}
          onChange={handleDashboardSelect}
          className={classes.dropdown}
        >
          <MenuItem value="dashboard1">{t('dashboard.insights')}</MenuItem>
          <MenuItem value="dashboard2">{t('dashboard.performance')}</MenuItem>
        </OutlinedSelect>
      </section>
      <PageTitle className={classes.title}>{t('app.dashboard')}</PageTitle>
      <iframe
        data-testid="iframe"
        src={url}
        title={url}
        style={{
          height: DASHBOARD_HEIGHTS[dashboardType],
        }}
        height={DASHBOARD_HEIGHTS[dashboardType]}
        className={classes.iframe}
      />
    </Page>
  );
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
