export default () => ({
  tabs: {
    marginTop: 20,
    minHeight: 'auto',
  },
  tab: {
    fontSize: '1.6em',
    fontWeight: 'bold',
    height: 48,
    maxWidth: 156,
    minWidth: 136,
  },
});
