import PropTypes, { oneOf } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';

import clsx from 'clsx';
import styles from './styles';
import Badge from '../Badge';

function PageTitle({
  children,
  classes,
  className,
  onGoBack,
  isLoading,
  readOnly,
  variant,
  ...typographyProps
}) {
  const { t } = useTranslation();
  const pageTitleClasses = clsx({
    [classes.text]: variant === 'default',
    [classes.textSmall]: variant === 'small',
    [classes.textMedium]: variant === 'medium',
    [classes.textModDefault]: variant === 'mod-default',
    [className]: !!className,
  });

  return (
    <div className={readOnly ? classes.mainDiv : ''}>
      <Typography
        variant={variant === 'default' ? 'h2' : 'h5'}
        className={`${pageTitleClasses} ${className}`}
        data-testid="text-typography"
        {...typographyProps}
      >
        {onGoBack && (
          <IconButton
            className={classes.titleIcon}
            onClick={onGoBack}
            data-testid="back-arrow"
          >
            <ArrowBack />
          </IconButton>
        )}
        {isLoading ? (
          <Skeleton variant="text" height={32} width={200} />
        ) : (
          children
        )}
        {readOnly && (
          <Typography className={classes.readOnly}>
            <Badge variant="default">{t('app.readOnly')}</Badge>
          </Typography>
        )}
      </Typography>
    </div>
  );
}

PageTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  onGoBack: PropTypes.func,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  variant: oneOf(['default', 'small', 'medium', 'mod-default']),
};

PageTitle.defaultProps = {
  className: '',
  onGoBack: undefined,
  isLoading: false,
  children: null,
  readOnly: false,
  variant: 'default',
};

export default withStyles(styles)(PageTitle);
