import BaseService from '../BaseService';

class AccountGrantsService extends BaseService {
  /**
   * Get a list of All Org Permissions that can be assigned to an Organization
   * @endpoint GET /api/accountgrants/organization
   * @description List of All Org Permissions that can be assigned to an Organization
   * @returns {Promise<Array>} A promise that resolves to an array of Org Permissions
   * @note This method intentionally skips adding the organizationId to the request
   */
  getOrgGrants() {
    // Explicitly skip adding organizationId for this specific API call
    return this.http.get('accountgrants/organization/', {
      skipAddOrganizationId: true,
    });
  }

  /**
   * Save organization permissions
   * @endpoint PUT /api/accountgrants/organization
   * @description Set Organization Account Grants
   * @param {Object} grantData - Object containing organization URI and account grants
   * @param {string} grantData.organizationUri - The URI of the organization
   * @param {Array} grantData.accountGrants - Array of grant objects
   * @param {Object} grantData.accountGrants[] - Each object represents a grant
   * @param {number} grantData.accountGrants[].sectionId - The ID of the section
   * @param {number} grantData.accountGrants[].subSectionId - The ID of the subsection
   * @param {number} grantData.accountGrants[].permissionId - The ID of the permission level
   * @returns {Promise<Object>} A promise that resolves to the response from the server
   */
  saveOrganizationPermissions(organizationUri, accountGrants) {
    const grantData = { organizationUri, accountGrants };
    return this.http.put(`accountgrants/organization/`, grantData);
  }

  getAssginedGrants() {
    return this.http.get(`accountgrants/organization/assigned`);
  }

  updateOrgGrants(grantsBody) {
    return this.http.put(`accountgrants/organization`, grantsBody);
  }

  // User endpoints
  listUserGrants() {
    return this.http.get(`accountgrants/user`);
  }

  getUserGrants(idsuid) {
    return this.http.get(`accountgrants/user/${idsuid}`);
  }

  updateUserGrants(grantsBody) {
    return this.http.put(`accountgrants/user`, grantsBody);
  }
}

export default new AccountGrantsService();
