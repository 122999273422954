import { func, objectOf, shape, string } from 'prop-types';
import { withStyles } from '@material-ui/core';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

function TileItem({ classes, tile, hideTile }) {
  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <DragIndicatorIcon className={classes.drag} />
        <Typography className={classes.title}>{tile.title}</Typography>
      </div>
      <CloseIcon
        className={classes.close}
        data-testid="hide-tile-button"
        onClick={() => hideTile(tile)}
      />
    </div>
  );
}

TileItem.propTypes = {
  classes: objectOf(string).isRequired,
  tile: shape({}).isRequired,
  hideTile: func.isRequired,
};

export default withStyles(styles)(TileItem);
