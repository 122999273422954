import { connect } from 'react-redux';
import TilesOrder from './TilesOrder';
import { savePage } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, { savePage })(TilesOrder);
