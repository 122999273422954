export default function styles(theme) {
  const divider = {
    marginLeft: '-24px',
    marginRight: '-24px',
    width: '110%',
    marginTop: theme.spacing(3),
  };
  return {
    subtitle: {
      marginTop: 15,
    },
    divider: {
      ...divider,
      marginTop: theme.spacing(3),
    },
    lastDivider: {
      ...divider,
      marginTop: theme.spacing(2),
    },
    radioButton: {
      marginTop: theme.spacing(2),
    },
    disclosure: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      border: '1px solid #BCC0C4',
    },
    descriptionText: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: theme.palette.text.subdued,
    },
    selectTextField: {
      margin: theme.spacing(1),
    },
    spinnerContainer: {
      marginTop: theme.spacing(2),
    },
  };
}
