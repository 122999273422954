import { string, node, oneOf, objectOf } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import styles from './styles';

function Badge({ classes, variant, children }) {
  const badgeClasses = clsx({
    [classes.default]: variant === 'default',
    [classes.primary]: variant === 'primary',
    [classes.active]: variant === 'active',
    [classes.pending]: variant === 'pending',
    [classes.archived]: variant === 'archived',
  });
  return <span className={badgeClasses}>{children}</span>;
}

Badge.propTypes = {
  classes: objectOf(string).isRequired,
  variant: oneOf(['default', 'primary', 'active', 'pending', 'archived']),
  children: node,
};

Badge.defaultProps = {
  variant: 'default',
  children: null,
};

export default withStyles(styles)(Badge);
