import {
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import i18next from 'i18next';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import Search from '../Search/Search';
import styles from './styles';

export function SelectOrganizationModal({
  classes,
  isOpen,
  onClose,
  organizations,
  selectedOrganizationId,
  onSelectOrganization,
}) {
  const { t } = useTranslation();

  const [orgsFiltered, setOrgsFiltered] = useState(organizations ?? []);

  const [isSearchFilterEmtpy, setIsSearchFilterEmtpy] = useState(true);
  const [searchedText, setSearchedText] = useState('');

  const [resetKey, setResetKey] = useState(0);

  const handleCleanFilter = () => {
    setSearchedText('');
    setOrgsFiltered(organizations ?? []);
    setIsSearchFilterEmtpy((prevState) => !prevState);
  };

  const handleCleanAndReset = () => {
    handleCleanFilter();
    setResetKey(resetKey === 0 ? 1 : 0);
  };

  const handleSearchFilter = (event, newValue) => {
    const textFiltered = newValue;
    if (textFiltered) {
      // must have access to all entries - use original source to filter down
      const orgsFound = organizations.filter(
        (d) =>
          d.clientId.toLowerCase().includes(textFiltered.toLowerCase()) ||
          d.organizationName
            ?.toLowerCase()
            ?.includes(textFiltered.toLowerCase()),
      );

      if (orgsFound.length > 0) {
        setOrgsFiltered(orgsFound);
      } else if (orgsFound.length === 0) {
        setOrgsFiltered([]);
      }

      setSearchedText(textFiltered);
    } else if (!textFiltered) {
      handleCleanFilter();
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Title className={classes.title}>
        {t('account.switch.organizations')}
      </Modal.Title>

      <span className={classes.searchContainerSingle}>
        <Search
          key={resetKey}
          searchOptions={orgsFiltered.map(
            (x) => `${x.clientId} | ${x.organizationName}`,
          )}
          onChange={handleSearchFilter}
          classes={classes}
          labels={{
            search: i18next.t('account.switch.search'),
            clearResults: i18next.t('account.switch.clearResults'),
          }}
          isInputEmpty={isSearchFilterEmtpy}
          onClean={handleCleanAndReset}
          changeIsInputEmpty={setIsSearchFilterEmtpy}
        />
      </span>

      {orgsFiltered.length > 0 ? (
        <List className={classes.accountList}>
          {orgsFiltered
            .sort((a, b) => (a.clientId > b.clientId ? 1 : -1))
            .map((o) => (
              <ListItem
                key={o.organizationId}
                className={classes.listItem}
                button
                selected={o.organizationId === selectedOrganizationId}
                onClick={onSelectOrganization(o.organizationId)}
              >
                <ListItemText
                  primary={
                    <div className={classes.overflow}>
                      {`${o.clientId} | ${o.organizationName}`}
                    </div>
                  }
                />
              </ListItem>
            ))}
        </List>
      ) : (
        <div className={classes.accountList}>
          <div className={classes.messageContainer}>
            <Typography className={classes.noEntryText}>
              {i18next.t('account.switch.noResultsFound')}
              <span className={classes.emphesisWord}>
                {`"${searchedText}"`}
              </span>
            </Typography>

            <Typography className={classes.tryAgainText}>
              {i18next.t('account.switch.noResultsTryAgain')}{' '}
            </Typography>
          </div>
        </div>
      )}
    </Modal>
  );
}

SelectOrganizationModal.propTypes = {
  classes: object.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  organizations: arrayOf(
    shape({
      organizationId: string.isRequired,
      organizationName: string.isRequired,
    }),
  ),
  selectedOrganizationId: string,
  onSelectOrganization: func.isRequired,
};

SelectOrganizationModal.defaultProps = {
  organizations: [],
  selectedOrganizationId: '',
};

export default withStyles(styles)(SelectOrganizationModal);
