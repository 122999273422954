export default theme => ({
  date: {
    width: 395,
    [theme.breakpoints.down('sm')]: {
      width: 335,
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  assistance: {
    width: 205,
  },
  attendeeLimit: {
    color: '#828282',
  },
  singleRow: {
    display: 'block',
  },
  eventName: {
    marginRight: 6,
  },
});
