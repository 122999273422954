import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { internalLogout } from '../../../store/internal/actions';
import { toggleProfileNav, closeNavs } from '../../../store/navigation/actions';
import { selectUserFullName } from '../../../store/user/selectors';
import { logout, setOrganization } from '../../../store/user/actions';
import ProfileNav from './ProfileNav';

export function mapStateToProps(state) {
  const {
    user: { associatedOrganizations, organizationName },
  } = state.user;
  const organizationId = state.organizations.currentOrganization.id;

  return {
    open: state.navigation.profileNavOpen,
    fullName: selectUserFullName(state),
    wasInternal: state.user.user.wasInternal,
    isInternal: state.user.user.isInternal,
    associatedOrganizations,
    organizationName,
    organizationId,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    navActions: bindActionCreators(
      { toggleProfileNav, closeNavs, internalLogout, logout, setOrganization },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileNav);
