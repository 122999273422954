import validator from 'validator';
import * as Yup from 'yup';
import i18n from '../../../utils/i18n';
import { PAYMENT_METHOD } from '../../../globals/constants';

export const initialTransactionValues = {
  creditCard: {
    zipCode: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  ach: {
    accountNumber: '',
    routingNumber: '',
    accountType: PAYMENT_METHOD.ACCOUNT_CHECKING,
    firstName: '',
    lastName: '',
    email: '',
    nacha: false,
  },
  billingInfo: {
    addressLine1: '',
    addressLine2: '',
    state: '',
    city: '',
    zipCode: '',
    phoneNumber: '',
  },
};

const getAchBillingInfoSchema = () => {
  return Yup.object().shape({
    addressLine1: Yup.string(),
    addressLine2: Yup.string().notRequired(),
    city: Yup.string(),
    zipCode: Yup.string().test(
      'zipCode-length',
      i18n.t('formValidation.zipcodeLength'),
      (value = '') => {
        return !value || value.length === 5;
      },
    ),
    state: Yup.string(),
    phoneNumber: Yup.string()
      .notRequired()
      .test(
        'phoneNumber-test',
        i18n.t('formValidation.phoneNumber'),
        (value) => !value || validator.isMobilePhone(value),
      ),
  });
};

const getCardOptionalBillingInfoSchema = () => {
  return Yup.object().shape({
    state: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    addressLine1: Yup.string().notRequired(),
    addressLine2: Yup.string().notRequired(),
    zipCode: Yup.string()
      .notRequired()
      .test(
        'zipCode-length',
        i18n.t('formValidation.zipcodeLength'),
        (value = '') => {
          return !value || value.length === 5;
        },
      ),
    phoneNumber: Yup.string()
      .notRequired()
      .test(
        'phoneNumber-test',
        i18n.t('formValidation.phoneNumber'),
        (value) => !value || validator.isMobilePhone(value),
      ),
  });
};

export const buildBillingInfoSchema = (type) => {
  /*
   * validate all data including email, but not saving the payment
   * method since they're not logged in
   */
  return type === PAYMENT_METHOD.CREDIT_CARD
    ? getCardOptionalBillingInfoSchema()
    : getAchBillingInfoSchema();
};

/**
 * Creates a Yup schema to be used with Formik for validation. Unfortunately the schema must be defined in the function since
 * i18n won't be initialized if it's defined as a constant outside it. We could create a work around for that, but it probably isn't necessary.
 * @param {string} type - the payment type to create a schema for. Should be of a type defined in paymentMethodMap
 * @returns {*}
 */
export const createSchema = (type) => {
  Yup.setLocale({
    mixed: {
      required: `${i18n.t('formValidation.required')}`,
    },
  });

  const achSchema = Yup.object().shape({
    accountNumber: Yup.string()
      .max(17, i18n.t('formValidation.accountLength'))
      .test(
        'account-alphanumeric-test',
        i18n.t('formValidation.accountLength'),
        (value = '') => validator.isAlphanumeric(value),
      )
      .required(),
    routingNumber: Yup.string()
      .test(
        'routing-length-test',
        i18n.t('formValidation.routingLength'),
        (value = '') => validator.isInt(value) && value.length === 9,
      )
      .test(
        'routing-start-test',
        i18n.t('formValidation.routingStartValue'),
        (value = '') => '0123'.includes(value[0]),
      )
      .required(),
    firstName: Yup.string()
      .required()
      .test(
        'firstName-test',
        i18n.t('formValidation.nameLength'),
        // Yup requires a function expression rather than an arrow function to use the special this values such as parent
        function test(value = '') {
          const lastName = this.parent.lastName || '';
          return value.length + lastName.length <= 29;
        },
      ),
    lastName: Yup.string()
      .required()
      .test(
        'lastName-test',
        i18n.t('formValidation.nameLength'),
        function test(value = '') {
          const firstName = this.parent.firstName || '';
          return value.length + firstName.length <= 29;
        },
      ),
    email: Yup.string().required().email(i18n.t('formValidation.email')),
    nacha: Yup.bool().oneOf([true], i18n.t('formValidation.nacha')),
  });

  const cardSchema = Yup.object().shape({
    zipCode: Yup.string()
      .required()
      .test(
        'zipCode-length',
        i18n.t('formValidation.zipcodeLength'),
        (value = '') => {
          return value.length === 5;
        },
      ),
    firstName: Yup.string()
      .required()
      .test(
        'firstName-test',
        i18n.t('formValidation.nameLength'),
        // Yup requires a function expression rather than an arrow function to use the special this values such as parent
        function test(value = '') {
          const lastName = this.parent.lastName || '';
          return value.length + lastName.length <= 29;
        },
      ),
    lastName: Yup.string()
      .required()
      .test(
        'lastName-test',
        i18n.t('formValidation.nameLength'),
        function test(value = '') {
          const firstName = this.parent.firstName || '';
          return value.length + firstName.length <= 29;
        },
      ),
    email: Yup.string().required().email(i18n.t('formValidation.email')),
  });

  const paymentMethodMap = {
    [PAYMENT_METHOD.CREDIT_CARD]: cardSchema,
    [PAYMENT_METHOD.ACH]: achSchema,
  };

  return Yup.object().shape({
    [type]: paymentMethodMap[type],
    billingInfo: buildBillingInfoSchema(type),
  });
};
