export default () => ({
  description: {
    lineHeight: '1.43',
    marginTop: 22,
    marginBottom: 24,
    maxWidth: 640,
  },
  spinner: {
    marginTop: 40,
  },
});
