export default function styles(theme) {
  return {
    page: {
      padding: '20px 24px',
    },
    title: {
      marginTop: 16,
    },
    buttonContainerDeprecated: {
      marginTop: 8,
    },

    leftMargin: {
      marginLeft: '8px',
    },
    icon: {
      color: '#5E6266',
      marginRight: 18,
      '& path': {
        fill: '#5E6266',
      },
    },
    tabContent: {
      width: 552,
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    tempTabContent: {
      display: 'flex',
      '& :nth-child(2)': {
        marginLeft: theme.spacing(1),
      },
    },
  };
}
