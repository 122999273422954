export default function styles() {
  return {
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '29px',
    },
    paperContainer: {
      width: '100%',
      padding: '56px',
    },
    configurationSection: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 300,
    },
    credentialWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 18,
    },
    credentialKey: {
      fontWeight: 'bold',
      minWidth: 100,
    },
    kioskIcon: {
      marginRight: 20,
    },
    linkButton: {
      paddingTop: 3,
    },
    credentialDivider: {
      height: 2,
      marginTop: 34,
      marginBottom: 34,
    },
    sectionTitle: {
      marginBottom: 24,
    },
    settingsSubsection: {
      marginBottom: 36,
    },
    paragraph: {
      maxWidth: 600,
    },
    paragraphHeader: {
      fontWeight: 'bold',
    },
    radioButton: {
      maxWidth: 300,
    },
    messageTextField: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 750,
    },
    labelDropdown: {
      maxWidth: 300,
    },
    labelTextField: {
      maxWidth: 300,
    },
    menuItemDivider: {
      margin: '12px 0 10px 50px',
      color: '#EBEBEB',
    },
    doneIcon: {
      marginLeft: -2,
      paddingRight: 8,
      width: 32,
      height: 32,
    },
    buttonLabelHeader: {
      padding: '14px 0 12px 16px',
      color: '#999999',
      fontSize: '1.3rem',
    },
    unselected: {
      paddingLeft: 30,
    },
    actionButtonGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: 16,
    },
  };
}
