import PropTypes, { objectOf } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import {
  Divider,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Done } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { CUSTOM_LABEL, CUSTOM_TEXT } from '../../../globals/constants';
import styles from './styles';
import PageDescription from '../PageDescription';
import OutlinedSelect from '../OutlinedSelect';
import FormHelperTextBottom from '../FormHelperTextBottom';
import OutlinedTextField from '../OutlinedTextField';
import { getCountText } from '../../../utils';
import DisclosureMessage from '../DisclosureMessage';
import DropdownFields from './DropdownFields';
import WarningMessageModal from './WarningMessageModal';

function MemoTextField({
  classes,
  values,
  setFieldValue,
  errors,
  touched,
  memoLineOptions,
  handleBlur,
  setFieldTouched,
  maxLengths,
  isGift,
  initialValues,
  features,
}) {
  const {
    showTextField,
    textField,
    responseRequired,
    customTextField,
    isTextFieldResponseType,
    memoFieldResponses,
  } = values;
  const { t } = useTranslation();
  const customTextFieldRef = useRef(null);
  const orderedMemoFieldResponses = memoFieldResponses.sort(
    (a, b) => a.ordinal - b.ordinal,
  );
  const [memoFieldWarningOpen, setMemoFieldWarningOpen] = useState(false);
  const [dropDownHasChange, setDropdownHasChange] = useState(false);
  const [enablecustomTextField, setEnableCustomTextField] = useState(false);
  const messageNoun = isGift
    ? t('tile.gift').toLowerCase()
    : t('tile.payment').toLowerCase();

  const handleChange = (e) => {
    setFieldValue({
      target: { name: e.target.name, value: e.target.checked },
    });
  };

  const handleShowTextFieldChange = async (e) => {
    const { checked } = e.target;
    await setFieldValue({
      target: { name: e.target.name, value: checked },
    });
  };

  const onTextFieldDropdownChange = (e) => {
    setFieldValue(e);
    setDropdownHasChange(true);
    setEnableCustomTextField(e.target.value === CUSTOM_LABEL);
  };

  useEffect(() => {
    if (enablecustomTextField && dropDownHasChange) {
      customTextFieldRef.current.focus();
    }
  }, [enablecustomTextField, dropDownHasChange]);

  const handleChangeTextfieldType = async (e) => {
    const { name, value } = e.target;
    const boolValue = value === 'true';
    if (boolValue && touched.memoFieldResponses) {
      setMemoFieldWarningOpen(true);
    } else {
      await setFieldValue({
        target: { name, value: boolValue },
      });
    }
  };

  const handleDropdownFieldsChange = async (value) => {
    await setFieldValue({
      target: { name: 'memoFieldResponses', value },
    });
    setFieldTouched('memoFieldResponses');
  };

  const closeMemoFieldWarning = () => setMemoFieldWarningOpen(false);

  const memoFieldsChangesLostConfirmed = async () => {
    closeMemoFieldWarning();
    await setFieldValue({
      target: { name: 'isTextFieldResponseType', value: true },
    });
    await setFieldValue({
      target: {
        name: 'memoFieldResponses',
        value: initialValues.memoFieldResponses,
      },
    });
  };

  return (
    <>
      <Typography variant="body2">
        {t(
          features.TileMemoDropdown
            ? 'tile.memoLine.subtitle'
            : 'tile.memoLine.textfield',
        )}
      </Typography>
      <PageDescription>
        {t(
          features.TileMemoDropdown
            ? 'tile.memoLine.description'
            : 'tile.memoLine.description.deprecated',
          {
            requiredNoun: messageNoun,
          },
        )}
      </PageDescription>
      <Grid container className={classes.content}>
        <Grid item xs={12} md={9}>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Checkbox
                name="showTextField"
                color="primary"
                checked={showTextField}
                value={showTextField}
                onChange={handleShowTextFieldChange}
                onBlur={handleBlur}
              />
            }
            label={t(
              features.TileMemoDropdown
                ? 'tile.memoLine.showTextField'
                : 'tile.memoLine.showTextField.deprecated',
              {
                requiredNoun: messageNoun,
              },
            )}
          />
        </Grid>
        {showTextField && (
          <Grid item xs={12} md={9} className={classes.textFieldMargin}>
            {features.NewWizard && (
              <Typography>{t('tile.memoLine.label')}</Typography>
            )}
            <OutlinedSelect
              id="textField"
              name="textField"
              data-testid="textField"
              error={!!errors.textField && touched.textField}
              value={textField}
              onChange={onTextFieldDropdownChange}
              onBlur={handleBlur}
              label={
                // eslint-disable-next-line no-nested-ternary
                features.NewWizard
                  ? textField
                    ? ''
                    : t('tile.memoLine.select')
                  : t('tile.memoLine.label')
              }
              renderValue={(value) => {
                return value === CUSTOM_LABEL ? CUSTOM_TEXT : value;
              }}
            >
              <Typography variant="body2" className={classes.buttonLabelHeader}>
                {t('tile.setUp.customButtonheading')}
              </Typography>
              <MenuItem
                className={classes.inlineItems}
                value={CUSTOM_LABEL}
                key={CUSTOM_LABEL}
              >
                {textField === CUSTOM_LABEL ? (
                  <Done color="primary" className={classes.doneIcon} />
                ) : (
                  <span className={classes.unselected} />
                )}
                {t('tile.memoLine.customLabel')}
              </MenuItem>
              <Divider className={classes.buttonLabelDivider} />
              <Typography variant="body2" className={classes.buttonLabelHeader}>
                {t('tile.setUp.suggestionsButtonheading')}
              </Typography>
              {memoLineOptions.map((option) => (
                <MenuItem
                  className={classes.inlineItems}
                  value={option}
                  key={option}
                >
                  {textField === option ? (
                    <Done color="primary" className={classes.doneIcon} />
                  ) : (
                    <span className={classes.unselected} />
                  )}
                  {option}
                </MenuItem>
              ))}
            </OutlinedSelect>
            {errors.textField && (
              <FormHelperTextBottom left={errors.textField} />
            )}
            {textField === CUSTOM_LABEL && (
              <Grid item xs={12}>
                <OutlinedTextField
                  id="customTextField"
                  name="customTextField"
                  value={customTextField}
                  onChange={setFieldValue}
                  label={
                    errors.customTextField
                      ? ''
                      : t('tile.memoLine.customFieldLabel')
                  }
                  inputProps={{
                    maxLength: maxLengths.customTextField,
                    'data-testid': 'custom-text-field',
                    ref: customTextFieldRef,
                  }}
                  error={errors.customTextField && touched.customTextField}
                  autoComplete="off"
                  onBlur={handleBlur}
                  placeholder={t('tile.setUp.customButtonPlaceholder')}
                />
                <FormHelperTextBottom
                  left={touched.customTextField && errors.customTextField}
                  right={
                    errors.customTextField && touched.customTextField
                      ? ''
                      : getCountText(
                          customTextField.length,
                          maxLengths.customTextField,
                        )
                  }
                />
              </Grid>
            )}
            {features.TileMemoDropdown && (
              <Grid item xs={12}>
                <RadioGroup
                  component={RadioGroup}
                  className={classes.radioGroup}
                  onChange={handleChangeTextfieldType}
                >
                  <FormControlLabel
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Radio
                        className={classes.radioButton}
                        color="primary"
                        name="isTextFieldResponseType"
                        checked={isTextFieldResponseType}
                        value="true"
                      />
                    }
                    label={
                      /* eslint-disable-next-line react/jsx-wrap-multilines */
                      <Typography className={classes.formSubtitle}>
                        {t('tile.memoLine.textfield')}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Radio
                        name="isTextFieldResponseType"
                        className={classes.radioButton}
                        color="primary"
                        checked={!isTextFieldResponseType}
                        value="false"
                      />
                    }
                    label={
                      /* eslint-disable-next-line react/jsx-wrap-multilines */
                      <Typography className={classes.formSubtitle}>
                        {t('tile.memoLine.dropdownfield')}
                      </Typography>
                    }
                  />
                  <Typography className={classes.responseDetailText}>
                    {t('tile.memoLine.dropdownfield.description', {
                      requiredNoun: messageNoun,
                    })}
                  </Typography>
                </RadioGroup>

                {!isTextFieldResponseType && (
                  <DropdownFields
                    textFields={orderedMemoFieldResponses}
                    onChange={handleDropdownFieldsChange}
                    maxLengths={maxLengths}
                    errors={errors}
                  />
                )}

                <WarningMessageModal
                  open={memoFieldWarningOpen}
                  onClose={closeMemoFieldWarning}
                  onConfirm={memoFieldsChangesLostConfirmed}
                />
              </Grid>
            )}
            <FormControlLabel
              className={
                features.TileMemoDropdown ? classes.responseDetailMainText : ''
              }
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Checkbox
                  name="responseRequired"
                  color="primary"
                  checked={responseRequired}
                  value={responseRequired}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.responseRequired && touched.responseRequired}
                />
              }
              label={
                <Typography>{t('tile.memoLine.response.label')}</Typography>
              }
            />
            <Typography className={classes.responseDetailText}>
              {t('tile.memoLine.response.description', {
                requiredNoun: messageNoun,
              })}
            </Typography>
          </Grid>
        )}
        {showTextField && (
          <Grid item xs={12} md={9}>
            <DisclosureMessage
              className={classes.disclosure}
              title=" "
              message={t('tile.memoLine.disclosureMessage')}
              variant="single"
              // onLearnMore={() => {}}   Link/Message is not defined yet
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

MemoTextField.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  memoLineOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxLengths: PropTypes.object.isRequired,
  isGift: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  features: objectOf(PropTypes.bool),
};

MemoTextField.defaultProps = {
  isGift: false,
  features: {},
};

export default withStyles(styles)(MemoTextField);
