import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import i18next from 'i18next';
import { FastField } from 'formik';
import { TextField } from 'formik-material-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CxpPaymentMethodFormField from '../CxpPaymentMethodFormField';
import * as FormUtils from '../FormUtils';
import { PAYMENT_METHOD } from '../../../../globals/constants';
import styles from './styles';
import {
  PAYMENT_METHOD_INFO,
  PAYMENT_METHOD_FIELDS,
} from './helpers/constants';
import UserInformationField from '../UserInformationField';

const initialState = {
  validationMap: {
    cardNumber: false,
    cardExpiration: false,
  },
  helperTextMap: {
    cardNumber: i18next.t('formValidation.required'),
    cardExpiration: i18next.t('formValidation.required'),
  },
  isDirtyMap: {
    cardNumber: false,
    cardExpiration: false,
  },
  isBlurredMap: {
    cardNumber: true,
    cardExpiration: true,
  },
};

export function CreditCardPaymentMethod({
  classes,
  hexeaPaymentMethod,
  showErrors,
  onInputChanged,
  onCardInfoValidity,
  cxpFieldKey,
}) {
  const { t } = useTranslation();
  const [localState, setLocalState] = useState(initialState);
  const { validationMap, isDirtyMap, helperTextMap, isBlurredMap } = localState;

  useEffect(
    () => {
      /*
       * Validates Card number and Exp.
       * are truthy only. The rest of the validations are from the hexea object
       */
      const isCardInfoValid = FormUtils.validateAll(localState.validationMap);
      onCardInfoValidity(PAYMENT_METHOD_INFO.CARD_INFO, isCardInfoValid);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localState.validationMap],
  );

  const handleCxpFieldChange = (event, field) => {
    let isValid = true;
    let errorMessage = '';

    // set is dirty map for this field
    if (!localState.isDirtyMap[field]) {
      setLocalState((prevState) => ({
        ...prevState,
        isDirtyMap: { ...prevState.isDirtyMap, [field]: true },
      }));
    }

    // check event.error and set validationMap and helperTextMap if error exists
    if (event) {
      // if field === cardNumber, set cardBrand in state for cvv validation
      if (field === PAYMENT_METHOD_FIELDS.CARD_NUMBER) {
        onInputChanged(
          PAYMENT_METHOD_INFO.CARD_INFO,
          PAYMENT_METHOD_INFO.CARD_BRAND,
          event.brand,
        );
      }

      if (event.empty) {
        isValid = false;
        errorMessage = t('formValidation.required');
      }

      if (event.error) {
        isValid = false;
        errorMessage = event.error.message;
      }
    }

    setLocalState((prevState) => ({
      ...prevState,
      validationMap: { ...prevState.validationMap, [field]: isValid },
      helperTextMap: {
        ...prevState.helperTextMap,
        [field]: errorMessage,
      },
    }));
  };

  const handleCxpFieldBlur = (field) =>
    setLocalState((prevState) => ({
      ...prevState,
      isBlurredMap: {
        ...prevState.isBlurredMap,
        [field]: true,
      },
    }));

  const handleCxpFieldFocus = (field) =>
    setLocalState((prevState) => ({
      ...prevState,
      isBlurredMap: {
        ...prevState.isBlurredMap,
        [field]: false,
      },
    }));

  return (
    <Typography component="div" className={classes.container}>
      {hexeaPaymentMethod && (
        <span className={classes.formItemGroup}>
          <CxpPaymentMethodFormField
            key={`${cxpFieldKey}-cardNumber`}
            field="cardNumber"
            id="card-number"
            className={classes.inlineFormItem}
            label={t('transactions.card.number')}
            paymentMethod={hexeaPaymentMethod}
            onChange={(e) =>
              handleCxpFieldChange(e, PAYMENT_METHOD_FIELDS.CARD_NUMBER)
            }
            onBlur={() => handleCxpFieldBlur(PAYMENT_METHOD_FIELDS.CARD_NUMBER)}
            onFocus={() =>
              handleCxpFieldFocus(PAYMENT_METHOD_FIELDS.CARD_NUMBER)
            }
            error={
              !validationMap.cardNumber &&
              ((isDirtyMap.cardNumber && isBlurredMap.cardNumber) || showErrors)
            }
            helperText={
              !validationMap.cardNumber &&
              ((isDirtyMap.cardNumber && isBlurredMap.cardNumber) || showErrors)
                ? helperTextMap.cardNumber
                : ''
            }
            data-testid="cardNumber-field"
          />
        </span>
      )}
      <span className={classes.formItemGroup}>
        {hexeaPaymentMethod && (
          <CxpPaymentMethodFormField
            key={`${cxpFieldKey}-cardExpiration`}
            field="cardExpiration"
            id="card-exp"
            className={classes.inlineFormItem}
            label={t('transactions.exp.date')}
            paymentMethod={hexeaPaymentMethod}
            onChange={(e) =>
              handleCxpFieldChange(e, PAYMENT_METHOD_FIELDS.CARD_EXPIRATION)
            }
            onBlur={() =>
              handleCxpFieldBlur(PAYMENT_METHOD_FIELDS.CARD_EXPIRATION)
            }
            onFocus={() =>
              handleCxpFieldFocus(PAYMENT_METHOD_FIELDS.CARD_EXPIRATION)
            }
            error={
              !validationMap.cardExpiration &&
              ((isDirtyMap.cardExpiration && isBlurredMap.cardExpiration) ||
                showErrors)
            }
            helperText={
              !validationMap.cardExpiration &&
              ((isDirtyMap.cardExpiration && isBlurredMap.cardExpiration) ||
                showErrors)
                ? helperTextMap.cardExpiration
                : ''
            }
            data-testid="cardExpiration-field"
          />
        )}
        <FastField
          name={`${PAYMENT_METHOD.CREDIT_CARD}.zipCode`}
          component={TextField}
          autoComplete="section-payment zip-code"
          id="card-zip-code"
          inputProps={{ inputMode: 'numeric' }}
          className={classes.inlineFormItem}
          label={t('headerManagement.zip')}
          variant="outlined"
          data-testid="zipCode-field"
          disabled={false}
        />
      </span>
      <UserInformationField />
    </Typography>
  );
}

CreditCardPaymentMethod.propTypes = {
  classes: PropTypes.object.isRequired,
  hexeaPaymentMethod: PropTypes.object,
  onInputChanged: PropTypes.func.isRequired,
  onCardInfoValidity: PropTypes.func.isRequired,
  showErrors: PropTypes.bool,
  cxpFieldKey: PropTypes.number,
};

CreditCardPaymentMethod.defaultProps = {
  showErrors: false,
  hexeaPaymentMethod: null,
  cxpFieldKey: 0,
};

export default withStyles(styles)(CreditCardPaymentMethod);
