import axios from 'axios';

async function getTenantInfo(tenant, authToken) {
  const response = await axios.get(
    `https://${window.env.REACT_APP_IDS_DOMAIN}/api/tenants/${tenant}`,
    { headers: { Authorization: `Bearer ${authToken}` } },
  );
  return response;
}
export default { getTenantInfo };
