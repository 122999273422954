import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  backendToFrontend,
  frontendToBackend,
} from '../../../store/funds/transform';
import { setSuccess, handleException } from '../../../store/alerts/actions';
import FundService from '../../../api/FundService';

const create = async ({ organizationId, fund }) => {
  const res = await FundService.createFund(
    organizationId,
    frontendToBackend(fund),
  );
  return backendToFrontend(res.data.data);
};

export function useCreateFunds() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    mutate: createFund,
    isLoading: loadingFundCreate,
    status: fundCreateStatus,
    error: createFundError,
    data: newFund,
  } = useMutation(create, {
    onSuccess: (_, { organizationId }) => {
      queryClient.invalidateQueries(['funds', organizationId]);
      dispatch(setSuccess(t('fund.addFund.success')));
    },
    onError: (error) => {
      dispatch(handleException(error));
    },
  });
  return {
    createFund,
    newFund,
    loadingFundCreate,
    fundCreateStatus,
    createFundError,
  };
}

export default useCreateFunds;
