import BaseService from '../BaseService';

class FundService extends BaseService {
  // eslint-disable-next-line class-methods-use-this
  getFundsUrl = organizationId => {
    return `organizations/${organizationId}/funds`;
  };

  createFund(organizationId, fund) {
    const url = this.getFundsUrl(organizationId);
    return this.http.post(url, { data: fund });
  }

  getFunds(organizationId) {
    return this.http.get(this.getFundsUrl(organizationId));
  }
}

export default new FundService();
