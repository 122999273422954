/* eslint-disable class-methods-use-this */
import BaseService from '../BaseService';

class UserService extends BaseService {
  login() {
    return this.http.post(`users/login`);
  }

  getUserPermissions() {
    return this.http.get('users/permissions');
  }

  getLogoutUrl = () => {
    return 'users/logout';
  };

  logout() {
    return this.http.post(this.getLogoutUrl());
  }

  getUsersUrl = (orgId) => {
    return `organizations/${orgId}/listusers`;
  };

  getUsers(orgId) {
    return this.http.get(this.getUsersUrl(orgId));
  }

  getAccountUsersUrl = (user) => {
    if (user.userId) {
      return 'admin/classicAccountUsers';
    }

    return 'admin/accountUsers';
  };

  getPatchUserUrl = (idsUid) => {
    return `admin/accountUser/${idsUid}`;
  };

  getRemoveUserUrl = (orgId, idsUid) => {
    return `organizations/${orgId}/users/${idsUid}`;
  };

  createUser = (user) => {
    return this.http.post(this.getAccountUsersUrl(user), { data: user });
  };

  removeUser = (idsUid, orgId) => {
    return this.http.delete(this.getRemoveUserUrl(orgId, idsUid));
  };

  patchUser = (idsUid, values) => {
    const patchValues = [];
    Object.keys(values).forEach((key) => {
      patchValues.push(this.patchHelper(key, values[key]));
    });
    return this.http.patch(this.getPatchUserUrl(idsUid), patchValues);
  };

  getUserOrganizationUrl = () => {
    return 'users/organization';
  };

  setOrganization = (orgId) => {
    return this.http.put(this.getUserOrganizationUrl(), {
      data: { organization: { id: orgId } },
    });
  };

  getTransferOwnershipUrl = (idsUid, orgId) => {
    return `organization/${orgId}/users/${idsUid}/transfer`;
  };

  transferOwnership = (idsUid, orgId) => {
    return this.http.put(this.getTransferOwnershipUrl(idsUid, orgId));
  };

  getOrganizationId = (orgId) => {
    return this.http.get(`admin/organizations/${orgId}`);
  };
}

export default new UserService();
