import PropTypes from 'prop-types';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { SECTION_PERMISSIONS } from 'globals/grants';

import styles from './styles';

const permissionsList = [
  { label: 'Giving', value: false },
  { label: 'Donors', value: false },
  { label: 'Outreach', value: false },
  { label: 'Smart Video', value: false },
  { label: 'Reporting', value: false },
  { label: 'Settings', value: true },
];

export function UserPermissionOptions({
  classes,
  onPermissionChange,
  permissions,
}) {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Typography variant="h5" className={classes.formTitle}>
        {t('users.editPermissions.subtitle1')}
      </Typography>
      <Typography variant="body2" className={classes.formSubtitle}>
        {t('users.editPermissions.subtitle2')}
      </Typography>
      {permissions.map((section, index) => {
        const { value, label, key, disabled } = section;

        // ADDED: Check to exclude rendering the Settings permission
        if (key === SECTION_PERMISSIONS.SETTINGS.ROOT) {
          return null;
        }

        return (
          <Grid item xs={12} key={label}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Checkbox
                  id={label}
                  name="permission"
                  color="primary"
                  checked={value}
                  value={value}
                  disabled={disabled}
                  onChange={() => onPermissionChange(section, index)}
                />
              }
              label={label}
              value={value}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

UserPermissionOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.object),
  onPermissionChange: PropTypes.func,
};

UserPermissionOptions.defaultProps = {
  onPermissionChange: () => {},
  permissions: permissionsList,
};

export default withStyles(styles)(UserPermissionOptions);
