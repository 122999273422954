import { makeStyles } from '@material-ui/core';

export default ({ contained }) =>
  makeStyles((theme) => {
    return {
      root: {
        padding: contained ? 0 : 42,
        boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.2)',
        [theme.breakpoints.down('sm')]: {
          border: 0,
          boxShadow: 'none',
          padding: 0,
        },
      },
    };
  })();
