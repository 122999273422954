import { string, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tabs, { Tab } from '../../Tabs';
import {
  MOBILE_USER_ROLE,
  MOBILE_USER_TAB_ALL,
} from '../../../../globals/constants';

function MobileUsersTabs({ value, onChange }) {
  const { t } = useTranslation();
  return (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      value={value}
      onChange={onChange}
      variant="scrollable"
    >
      <Tab
        value={MOBILE_USER_TAB_ALL}
        label={t('vanco.mobile.appUsers.allTab')}
      />
      <Tab
        value={MOBILE_USER_ROLE.MEMBER}
        label={t('vanco.mobile.appUsers.membersTab')}
      />
      <Tab
        value={MOBILE_USER_ROLE.MODERATOR}
        label={t('vanco.mobile.appUsers.moderatorsTab')}
      />
      <Tab
        value={MOBILE_USER_ROLE.STAFF}
        label={t('vanco.mobile.appUsers.staffTab')}
      />
    </Tabs>
  );
}

MobileUsersTabs.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
};

export default MobileUsersTabs;
