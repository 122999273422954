import {
  SEND_KIOSK_REQUEST,
  SEND_KIOSK_REQUEST_ERROR,
  SEND_KIOSK_REQUEST_SUCCESS,
  GET_KIOSK,
  GET_KIOSK_SUCCESS,
  GET_KIOSK_ERROR,
  DISABLE_KIOSK,
  DISABLE_KIOSK_ERROR,
  DISABLE_KIOSK_SUCCESS,
  ENABLE_KIOSK,
  ENABLE_KIOSK_ERROR,
  ENABLE_KIOSK_SUCCESS,
  UPDATE_KIOSK,
  UPDATE_KIOSK_ERROR,
  UPDATE_KIOSK_SUCCESS,
} from './types';

const INITIAL_STATE = {
  wasKioskRequested: false,
  loadingKioskRequest: false,
  loadingKiosk: false,
  enablingKiosk: false,
  disablingKiosk: false,
  updatingKiosk: false,
  hasKiosk: false,
  kiosk: null,
  kioskDisableDate: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SEND_KIOSK_REQUEST: {
      return {
        ...state,
        loadingKioskRequest: true,
      };
    }
    case SEND_KIOSK_REQUEST_SUCCESS: {
      return {
        ...state,
        loadingKioskRequest: false,
        wasKioskRequested: true,
      };
    }
    case SEND_KIOSK_REQUEST_ERROR: {
      return {
        ...state,
        loadingKioskRequest: false,
        wasKioskRequested: false,
      };
    }
    case GET_KIOSK: {
      return {
        ...state,
        loadingKiosk: false,
      };
    }
    case GET_KIOSK_SUCCESS: {
      return {
        ...state,
        loadingKiosk: false,
        hasKiosk: true,
        kiosk: action.payload,
      };
    }
    case GET_KIOSK_ERROR: {
      return {
        ...state,
        loadingKiosk: false,
        hasKiosk: false,
        kiosk: null,
      };
    }
    case DISABLE_KIOSK: {
      return {
        ...state,
        disablingKiosk: true,
      };
    }
    case DISABLE_KIOSK_ERROR: {
      return {
        ...state,
        disablingKiosk: false,
      };
    }
    case DISABLE_KIOSK_SUCCESS: {
      return {
        ...state,
        kiosk: null,
        hasKiosk: false,
        disablingKiosk: false,
        kioskDisableDate: action.payload,
      };
    }
    case ENABLE_KIOSK: {
      return {
        ...state,
        enablingKiosk: true,
      };
    }
    case ENABLE_KIOSK_ERROR: {
      return {
        ...state,
        enablingKiosk: false,
      };
    }
    case ENABLE_KIOSK_SUCCESS: {
      return {
        ...state,
        kiosk: action.payload,
        hasKiosk: true,
        enablingKiosk: false,
      };
    }
    case UPDATE_KIOSK: {
      return {
        ...state,
        updatingKiosk: true,
      };
    }
    case UPDATE_KIOSK_ERROR: {
      return {
        ...state,
        updatingKiosk: false,
      };
    }
    case UPDATE_KIOSK_SUCCESS: {
      return {
        ...state,
        updatingKiosk: false,
      };
    }
    default:
      return state;
  }
};
