import { bool, func, objectOf, string, object, number } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Field } from 'formik';
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
  Checkbox,
  MenuItem,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useEffect, useCallback, useState, useRef } from 'react';
import { useFeatures } from 'utils/hooks';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import styles from './styles';
import OutlinedTextEditor from '../../OutlinedTextEditor';
import OutlinedTextField from '../../OutlinedTextField';
import DisclosureMessage from '../../DisclosureMessage';
import DatePicker from '../../DatePicker';
import FormHelperTextBottom from '../../FormHelperTextBottom';
import TimePicker from '../../TimePicker';
import {
  PAGE_FACEBOOK_VIDEO,
  PAGE_PLATFORM_FACEBOOK,
  PAGE_PLATFORM_YOUTUBE,
  PAGE_YOUTUBE_CHANNEL,
  PAGE_YOUTUBE_VIDEO,
} from '../../../../globals/constants';
import TimeZonePicker from '../../TimeZonePicker';
import PageDivider from '../../PageDivider';
import OutlinedSelect from '../../OutlinedSelect';
import ExternalLink from '../../ExternalLink';
import { getCountText } from '../../../../utils';

function PageInfoForm(props) {
  const {
    classes,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    values,
    goToPrev,
    loading,
    isFinished,
    handleReset,
    dirty,
    errors,
    touched,
    handleBlur,
    isWizard,
    submitCount,
    submitCountFromParent,
    maxLengths,
  } = props;
  const {
    title,
    description,
    videoUrl,
    videoType,
    startDate,
    startTime,
    endDate,
    endTime,
    timeZone,
    repeatWeekly,
    platform,
    embedCode,
  } = values;

  const { t } = useTranslation();
  const { ChannelManagement: ChannelManagementFF } = useFeatures();
  const setFieldValueRef = useRef(setFieldValue);
  const currentDate = moment().startOf('day');
  const [youtubeVideoType, setYoutubeVideoType] = useState(
    videoType.includes('YouTube_') ? videoType : '',
  );
  const dateFormatString = 'MM/DD/YYYY';
  const dateTimeFormatString = `${dateFormatString} h:mm A`;
  const isPermalink = videoType === PAGE_YOUTUBE_CHANNEL;
  const platforms = [PAGE_PLATFORM_YOUTUBE, PAGE_PLATFORM_FACEBOOK];
  const showFullSchedule =
    videoType === PAGE_YOUTUBE_VIDEO || videoType === PAGE_FACEBOOK_VIDEO;

  /* Don't show an error while entering startDate in the wizard */
  const endDateHasError = !!(
    errors.endDate &&
    ((isWizard && !!endDate && (touched.endDate || touched.startDate)) ||
      submitCount > 0 ||
      !isWizard)
  );
  const handleRadioSelectionToggle = async (e) => {
    await setFieldValue({ target: { name: 'videoUrl', value: '' } });

    await setFieldValue({
      target: { name: 'videoType', value: e.target.value },
    });
    setYoutubeVideoType(e.target.value);
  };

  useEffect(() => {
    setFieldValueRef.current = setFieldValue;
  }, [setFieldValue]);

  useEffect(() => {
    // Set videoType based on selected platform
    if (platform !== '') {
      const defaultVideoType =
        // eslint-disable-next-line no-nested-ternary
        platform === PAGE_PLATFORM_FACEBOOK
          ? PAGE_FACEBOOK_VIDEO
          : youtubeVideoType !== ''
            ? youtubeVideoType
            : PAGE_YOUTUBE_CHANNEL;
      // Prevent setting the form dirty on load
      if (videoType !== defaultVideoType) {
        setFieldValueRef.current({
          target: { name: 'videoType', value: defaultVideoType },
        });
      }
    }
  }, [platform, videoType, youtubeVideoType]);

  useEffect(() => {
    if (submitCountFromParent > 0) {
      if (errors.embedCode) setFieldTouched('embedCode', true, true);
      if (errors.videoUrl) setFieldTouched('videoUrl', true, true);
    }
  }, [
    errors.embedCode,
    errors.videoUrl,
    setFieldTouched,
    submitCountFromParent,
  ]);

  const onEditorChange = (value) => {
    setFieldValueRef.current({ target: { name: 'description', value } });
  };

  const handleTimeChange = useCallback(async (e) => {
    const { value, name } = e.target;
    await setFieldValueRef.current({ target: { name, value } });
  }, []);

  const concatDateTime = (date, time) => {
    return `${date.format(dateFormatString)} ${time}`;
  };

  const handleDateChange = useCallback(async (name, value, shouldValidate) => {
    await setFieldValueRef.current({ target: { name, value, shouldValidate } });
  }, []);

  const hasValidStartTime = useCallback(() => {
    return !!startTime && startTime !== -1;
  }, [startTime]);

  const hasValidEndTime = useCallback(() => {
    return !!endTime && endTime !== -1;
  }, [endTime]);

  const canAutoIncrement = useCallback(() => {
    if (!hasValidStartTime() && !hasValidEndTime()) {
      return false;
    }
    if (hasValidStartTime() && hasValidEndTime()) {
      return false;
    }
    if (hasValidStartTime() && !startDate) {
      return false;
    }
    if (hasValidEndTime() && !endDate) {
      return false;
    }

    return true;
  }, [endDate, startDate, hasValidStartTime, hasValidEndTime]);

  useEffect(() => {
    const res = canAutoIncrement();

    if (!res) {
      return;
    }

    const autoIncrement = async () => {
      if (hasValidStartTime()) {
        const curStartTime = moment(
          concatDateTime(startDate, startTime),
          dateTimeFormatString,
        );
        const updatedEndDate = curStartTime.add(1, 'hour');
        const timeUpdate = updatedEndDate
          .format('hh:mm a')
          .replace(/^0+/, '') // remove trailing zeroes
          .toUpperCase();
        await handleDateChange(
          'endDate',
          moment(
            updatedEndDate.format(dateFormatString),
            dateFormatString,
            true,
          ),
          true,
        );
        await handleTimeChange({
          target: { name: 'endTime', value: timeUpdate },
        });
      } else if (hasValidEndTime()) {
        const curEndTime = moment(
          concatDateTime(endDate, endTime),
          dateTimeFormatString,
        );
        const updatedStartDate = curEndTime.subtract(1, 'hour');
        const timeUpdate = updatedStartDate
          .format('hh:mm a')
          .replace(/^0+/, '') // remove trailing zeroes
          .toUpperCase();
        await handleDateChange(
          'startDate',
          moment(
            updatedStartDate.format(dateFormatString),
            dateFormatString,
            true,
          ),
          true,
        );
        await handleTimeChange({
          target: { name: 'startTime', value: timeUpdate },
        });
      }
    };
    autoIncrement();
  }, [
    startDate,
    startTime,
    endDate,
    endTime,
    canAutoIncrement,
    handleTimeChange,
    handleDateChange,
    hasValidEndTime,
    hasValidStartTime,
    dateTimeFormatString,
  ]);

  const isSameDate = useCallback(() => {
    return (
      startDate &&
      endDate &&
      startDate.format(dateFormatString) === endDate.format(dateFormatString)
    );
  }, [startDate, endDate]);

  useEffect(() => {
    if (isPermalink && !isSameDate()) {
      handleDateChange('endDate', startDate, true);
    }
  }, [isPermalink, startDate, endDate, handleDateChange, isSameDate]);

  const getStartTimeElement = () => {
    return (
      <Grid
        item
        md={6}
        xs={12}
        className={showFullSchedule ? '' : classes.groupPadding}
      >
        <Typography className={classes.dateTitle} variant="caption">
          {t('stream.page.videoDetails.schedule.startTime')}
        </Typography>
        <Grid item xs={12} className={classes.timeContainer}>
          <TimePicker
            values={{ time: {} }}
            inputPlaceholderText={t(
              'stream.page.videoDetails.schedule.startTimePlaceholder',
            )}
            className={classes.timepickerStyle}
            onChange={handleTimeChange}
            value={startTime}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            name="startTime"
            variant="outlined"
            defaultTime="10:00 AM"
          />
          <TimeZonePicker
            values={{ timeZone: {} }}
            className={classes.timeZoneStyle}
            onChange={setFieldValue}
            value={timeZone}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            name="timeZone"
            variant="outlined"
          />
        </Grid>
        {errors.startTime && touched.startTime && (
          <FormHelperTextBottom left={errors.startTime} />
        )}
      </Grid>
    );
  };

  const renderStartTime = () => {
    return showFullSchedule ? (
      getStartTimeElement()
    ) : (
      <Grid item md={6} xs={12} />
    );
  };

  const renderEndDate = () => {
    return showFullSchedule ? (
      <Grid item md={6} xs={12} className={classes.groupPadding}>
        <Typography className={classes.dateTitle} variant="caption">
          {t('stream.page.videoDetails.schedule.endDate')}
        </Typography>

        <FormControl fullWidth className={classes.dateInput}>
          <Field
            item
            id="donation-end-date"
            name="endDate"
            value={endDate}
            inputClass={classes.inputField}
            helperTextClass={classes.inputHelperText}
            earliestAllowedDate={currentDate}
            handleDateChange={handleDateChange}
            hasError={endDateHasError}
            component={DatePicker}
          />
        </FormControl>
      </Grid>
    ) : (
      getStartTimeElement()
    );
  };

  const handleRepetitionToggle = async (e) => {
    const { checked, name } = e.target;
    await setFieldValueRef.current({ target: { name, value: checked } });
  };

  return (
    <Form>
      <Grid container data-testid="form-container">
        {ChannelManagementFF && (
          <Grid item xs={12}>
            <Typography variant="h5">
              {t('stream.page.branding.pageName.title')}
            </Typography>
            <Typography variant="body1" className={classes.textMargin}>
              {t('stream.page.branding.pageName.description')}
            </Typography>
            <FormControl fullWidth className={classes.textMargin}>
              <OutlinedTextField
                id="title"
                name="title"
                error={errors.title && touched.title}
                value={title}
                label={t('stream.page.branding.streamPageName')}
                placeholder={t(
                  'stream.page.branding.streamPageName.placeholder',
                )}
                inputProps={{
                  maxLength: maxLengths.title,
                  'data-testid': 'pageName-input',
                }}
                onBlur={handleBlur}
                onChange={setFieldValue}
                autoComplete="off"
                autoFocus={!title.length}
              />
              <FormHelperTextBottom
                left={touched.title && errors.title}
                right={getCountText(title.length, maxLengths.title)}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item container md={12}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {t('stream.page.videoDetails.videoUrl.title')}
            </Typography>
            <Typography className={classes.subtitleTop}>
              {t('stream.page.videoDetails.videoUrl.description')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            className={`${classes.sectionTitle} ${classes.selectVertGutter}`}
          >
            <Typography>
              {t('stream.page.videoDetails.videoUrl.platform')}
            </Typography>

            <FormControl fullWidth>
              <OutlinedSelect
                id="platform"
                name="platform"
                value={platform}
                label={
                  platform
                    ? ''
                    : t(
                        'stream.page.videoDetails.videoUrl.platform.placeholder',
                      )
                }
                placeholderOnly={platform === ''}
                onChange={setFieldValue}
                onBlur={handleBlur}
                error={errors.platform && touched.platform}
                inputProps={{
                  'data-testid': 'select-platform',
                }}
                labelClass={errors.platform ? classes.labelOverride : ''}
                className={errors.platform ? classes.errorOverride : ''}
              >
                {platforms.map((s) => {
                  return (
                    <MenuItem key={s} value={s}>
                      <span>{s}</span>
                    </MenuItem>
                  );
                })}
              </OutlinedSelect>
              {errors.platform && touched.platform && (
                <FormHelperTextBottom left={errors.platform} />
              )}
            </FormControl>
          </Grid>
          {platform === PAGE_PLATFORM_YOUTUBE && (
            <Grid item className={classes.sectionVertGutter}>
              <Typography>
                {t('stream.page.videoDetails.videoUrl.link.type')}
              </Typography>
              <RadioGroup
                component={RadioGroup}
                onChange={handleRadioSelectionToggle}
              >
                <FormControlLabel
                  classes={{
                    root: classes.radioGroupItem,
                    label: classes.radioGroupLabel,
                  }}
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Radio
                      name="videoType"
                      className={classes.radioButton}
                      color="primary"
                      checked={videoType === PAGE_YOUTUBE_CHANNEL}
                      value={PAGE_YOUTUBE_CHANNEL}
                    />
                  }
                  label={
                    /* eslint-disable-next-line react/jsx-wrap-multilines */
                    <>
                      <Typography className={classes.subtitle} variant="body2">
                        {t('stream.page.videoDetails.videoUrl.option2.title')}
                      </Typography>
                      <Typography>
                        {t(
                          'stream.page.videoDetails.videoUrl.option2.description',
                        )}
                      </Typography>

                      <Grid
                        item
                        xs={12}
                        className={
                          videoType === PAGE_YOUTUBE_CHANNEL
                            ? classes.show
                            : classes.hidden
                        }
                      >
                        <FormControl fullWidth>
                          <OutlinedTextField
                            showCount
                            id="videoUrlChannel"
                            name="videoUrl"
                            data-testid="videoUrl"
                            value={videoUrl}
                            placeholder={t(
                              'stream.page.videoDetails.videoUrl.option2.placeholder',
                            )}
                            inputProps={{
                              className:
                                errors.videoUrl && touched.videoUrl
                                  ? classes.errorOverride
                                  : null,
                            }}
                            onChange={setFieldValue}
                            onBlur={handleBlur}
                            error={errors.videoUrl && touched.videoUrl}
                          />
                        </FormControl>
                        {errors.videoUrl && touched.videoUrl && (
                          <FormHelperTextBottom left={errors.videoUrl} />
                        )}
                        <Typography>
                          {t('stream.page.videoDetails.videoUrl.option2.help')}
                          <ExternalLink to="https://support.google.com/youtube/answer/6180214?hl=en">
                            <Button
                              variant="text"
                              color="primary"
                              className={classes.learMoreButton}
                            >
                              {t('app.learnMore')}
                            </Button>
                          </ExternalLink>
                        </Typography>
                      </Grid>
                    </>
                  }
                />
                <FormControlLabel
                  classes={{
                    root: classes.radioGroupItem,
                    label: classes.radioGroupLabel,
                  }}
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Radio
                      className={classes.radioButton}
                      color="primary"
                      name="videoType"
                      checked={videoType === PAGE_YOUTUBE_VIDEO}
                      value={PAGE_YOUTUBE_VIDEO}
                    />
                  }
                  label={
                    /* eslint-disable-next-line react/jsx-wrap-multilines */
                    <>
                      <Typography className={classes.subtitle} variant="body2">
                        {t('stream.page.videoDetails.videoUrl.option1.title')}
                      </Typography>
                      <Typography>
                        {t(
                          'stream.page.videoDetails.videoUrl.option1.description',
                        )}
                      </Typography>

                      <Grid
                        item
                        xs={12}
                        className={
                          videoType === PAGE_YOUTUBE_VIDEO
                            ? classes.show
                            : classes.hidden
                        }
                      >
                        <FormControl fullWidth>
                          <OutlinedTextField
                            showCount
                            id="videoUrl"
                            name="videoUrl"
                            data-testid="videoUrl"
                            value={videoUrl}
                            placeholder={t(
                              'stream.page.videoDetails.videoUrl.option1.placeholder',
                            )}
                            inputProps={{
                              className:
                                errors.videoUrl && touched.videoUrl
                                  ? classes.errorOverride
                                  : null,
                            }}
                            onChange={setFieldValue}
                            onBlur={handleBlur}
                            error={errors.videoUrl && touched.videoUrl}
                          />
                        </FormControl>
                        {errors.videoUrl && touched.videoUrl && (
                          <FormHelperTextBottom left={errors.videoUrl} />
                        )}
                      </Grid>
                    </>
                  }
                />
              </RadioGroup>
            </Grid>
          )}

          {videoType === PAGE_FACEBOOK_VIDEO && (
            <Grid item xs={12} className={classes.sectionVertGutter}>
              <Typography>
                {t('stream.page.videoDetails.videoUrl.embedCode')}
              </Typography>

              <FormControl fullWidth>
                <OutlinedTextField
                  id="embedCode"
                  name="embedCode"
                  value={embedCode}
                  placeholder={t(
                    'stream.page.videoDetails.videoUrl.embedCode.placeholder',
                  )}
                  multiline
                  maxRows="1"
                  inputProps={{
                    className: classes.embedCodeTextField,
                    'data-testid': 'short-description',
                  }}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  error={errors.embedCode && touched.embedCode}
                />
              </FormControl>
              {errors.embedCode && touched.embedCode && (
                <FormHelperTextBottom left={errors.embedCode} />
              )}

              <Typography>
                {t('stream.page.videoDetails.videoUrl.embedCode.help')}
                <ExternalLink to="https://developers.facebook.com/docs/plugins/embedded-video-player/">
                  <Button
                    variant="text"
                    color="primary"
                    className={classes.learMoreButton}
                  >
                    {t('app.learnMore')}
                  </Button>
                </ExternalLink>
              </Typography>
            </Grid>
          )}

          {videoType !== '' && (
            <>
              <PageDivider variant="streamSite" />
              <Grid item xs={12} className={classes.sectionVertGutter}>
                <section className={classes.heading}>
                  <Typography variant="h5" className={classes.sectionTitle}>
                    {t('stream.page.videoDetails.schedule.title')}
                  </Typography>
                  <Typography>
                    {t('stream.page.videoDetails.schedule.description')}
                  </Typography>
                </section>
              </Grid>
            </>
          )}
        </Grid>

        {videoType !== '' && (
          <Grid item container md={12}>
            <Grid container item sm={12} className={classes.sectionVertGutter}>
              <Grid item md={6} xs={12} className={classes.groupPadding}>
                <Typography className={classes.dateTitle} variant="caption">
                  {t('stream.page.videoDetails.schedule.startDate')}
                </Typography>

                <FormControl fullWidth className={classes.dateInput}>
                  <Field
                    item
                    id="stream-start-date"
                    name="startDate"
                    value={startDate}
                    inputClass={classes.inputField}
                    helperTextClass={classes.inputHelperText}
                    earliestAllowedDate={currentDate}
                    handleDateChange={handleDateChange}
                    component={DatePicker}
                  />
                </FormControl>
              </Grid>
              {renderStartTime()}
            </Grid>
            <Grid container item sm={12} className={classes.sectionVertGutter}>
              {renderEndDate()}
              <Grid item md={6} xs={12}>
                <Typography className={classes.dateTitle} variant="caption">
                  {t('stream.page.videoDetails.schedule.endTime')}
                </Typography>

                <Grid item xs={12} className={classes.timeContainer}>
                  <TimePicker
                    values={{ time: {} }}
                    inputPlaceholderText={t(
                      'stream.page.videoDetails.schedule.endTimePlaceholder',
                    )}
                    className={classes.timepickerStyle}
                    onChange={handleTimeChange}
                    value={endTime}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    name="endTime"
                    variant="outlined"
                  />
                  <TimeZonePicker
                    disabled
                    values={{ timeZone: {} }}
                    className={classes.timeZoneStyle}
                    onChange={setFieldValue}
                    value={timeZone}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    name="timeZone"
                    variant="outlined"
                  />
                </Grid>
                {errors.endTime && touched.endTime && (
                  <FormHelperTextBottom left={errors.endTime} />
                )}
              </Grid>
            </Grid>
            {videoType === PAGE_YOUTUBE_CHANNEL && (
              <Grid
                container
                spacing={2}
                item
                xs={12}
                className={classes.sectionVertGutter}
              >
                <FormControlLabel
                  className={classes.repetitionFix}
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Checkbox
                      className={classes.checkboxColor}
                      checked={repeatWeekly}
                      onChange={handleRepetitionToggle}
                      name="repeatWeekly"
                      color="primary"
                    />
                  }
                  label={t(
                    'stream.page.videoDetails.schedule.repetitionDescription',
                  )}
                />
              </Grid>
            )}
          </Grid>
        )}

        <PageDivider variant="streamSite" />

        <Grid item xs={12}>
          <section className={classes.heading}>
            <Typography variant="h5" className={classes.sectionTitle}>
              {t('tile.description.heading1')}
            </Typography>
            <Typography>{t('tile.description.helper1')}</Typography>
          </section>
        </Grid>
        <Grid item xs={12} className={classes.sectionVertGutter}>
          <OutlinedTextEditor
            placeholder={t('tile.description.placeholder')}
            onChange={onEditorChange}
            value={description}
          />
        </Grid>

        <Grid
          item
          xs={12}
          className={[
            classes.sectionVertGutter,
            classes.streamDisclosureSection,
          ].join(' ')}
        >
          <DisclosureMessage
            className={classes.streamDisclosure}
            classes={{
              disclosureMessage: classes.streamDisclosureMessage,
              disclosureTitle: classes.streamDisclosureTitle,
            }}
            message={t('stream.page.videoDetails.learMore.description')}
            variant="single"
          />
        </Grid>

        {isWizard && <PageDivider variant="streamSite" />}

        <Grid item xs={12}>
          {!loading && goToPrev && (
            <WizardButtons
              isFinished={isFinished}
              dirty={dirty}
              goToNext={handleSubmit}
              goToPrev={goToPrev}
              undo={handleReset}
              hidePrevious
              newWizard
            />
          )}
          {loading && goToPrev && (
            <div className={classes.spinnerContainer}>
              <CircularProgress
                color="primary"
                className={classes.spinner}
                data-testid="spinner-test"
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}

PageInfoForm.propTypes = {
  classes: objectOf(string).isRequired,
  handleSubmit: func.isRequired,
  goToPrev: func,
  loading: bool.isRequired,
  isFinished: bool.isRequired,
  handleReset: func.isRequired,
  dirty: bool.isRequired,
  setFieldValue: func.isRequired,
  setFieldTouched: func.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  handleBlur: func.isRequired,
  isWizard: bool.isRequired,
  submitCount: number.isRequired,
  submitCountFromParent: number,
  maxLengths: object.isRequired,
};

PageInfoForm.defaultProps = {
  goToPrev: null,
  submitCountFromParent: 0,
};

export default withStyles(styles)(PageInfoForm);
