export default () => ({
  colorsContainer: {
    marginBottom: '42px',
    maxWidth: 510,
  },
  colorsHeader: {
    marginBottom: '10px',
  },
  spinnerContainer: {
    marginTop: 40,
  },
});
