export default function styles(theme) {
  return {
    container: {
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        margin: 0,
      },
    },
    main: {
      display: 'flex',
      flex: 1,
      height: '100%',
      overflow: 'hidden',
    },
    contentWrap: {
      display: 'flex',
      alignItems: 'flex-start',
      flex: 1,
      overflow: 'auto',
      height: '100%',
    },
    contentContainer: {
      display: 'flex',
      textAlign: 'center',
      flex: 1,
      flexDirection: 'column',
      padding: 42,
      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },
    },
    title: {
      fontSize: '2rem',
      lineHeight: 'normal',
      marginBottom: '2rem',
      display: 'flex',
      justifyContent: 'center',
      allignItems: 'center',
    },
    body: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    button: {
      marginTop: 32,
      marginLeft: 8,
      padding: '8px 16px',
      fontWeight: 600,
    },
    activeIcon: {
      '& path': {
        fill: theme.palette.primary.main,
      },
      marginRight: '15.5px',
    },
  };
}
