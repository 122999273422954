export default function styles(theme) {
  return {
    search: {
      maxWidth: 660,
      marginBottom: 46,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    empty: {
      width: '100%',
      marginTop: 23,
      marginBottom: 77,
      textAlign: 'center',
    },
    images: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      position: 'relative',
    },
    loadButton: {
      fontSize: '1.4rem',
      color: theme.palette.primary.main,
    },
    loadMore: {
      width: '100%',
      marginTop: 23,
      marginBottom: 23,
      textAlign: 'center',
    },
    error: {
      width: '100%',
      marginTop: 23,
      marginBottom: 77,
      textAlign: 'center',
      color: 'red',
    },
    imagesBy: {
      display: 'flex',
      marginBottom: 5,
      fontSize: '1.2rem',
    },
    unsplashLink: {
      marginLeft: 2,
      color: theme.palette.primary.main,
    },
  };
}
