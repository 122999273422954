import { Typography, withStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PageTitle, Page } from '../../common';
import styles from './styles';
import ImportManager from '../../import/ImportManager';
import { SAMPLE_CSV_HEADER } from '../../import/utils/const';

function ImportMembers({ classes, accessToken, idsPayerTenant }) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const goBack = () => {
    history.goBack();
  };
  const onUploadSuccess = () => {
    goBack();
  };
  function buildCSVBlob() {
    const blob = new Blob([SAMPLE_CSV_HEADER], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    return url;
  }
  return (
    <Page>
      <PageTitle onGoBack={location.state?.showBack ? goBack : null}>
        {t('ministry.platform.import.page.title')}
      </PageTitle>
      <Typography>
        {t('ministry.platform.import.page.description')}
        <a
          className={classes.link}
          href={buildCSVBlob()}
          target="_blank"
          rel="noreferrer"
          download="data-import-template.csv"
        >
          {t('ministry.platform.import.page.description.link')}
        </a>
      </Typography>
      <div className={classes.container}>
        {idsPayerTenant && (
          <ImportManager
            idsPayerTenant={idsPayerTenant}
            accessToken={accessToken}
            onUploadSuccess={onUploadSuccess}
          />
        )}
      </div>
    </Page>
  );
}

ImportMembers.propTypes = {
  classes: PropTypes.object.isRequired,
  accessToken: PropTypes.string.isRequired,
  idsPayerTenant: PropTypes.string.isRequired,
};

export default withStyles(styles)(ImportMembers);
