import { connect } from 'react-redux';

import StreamPageInfo from './StreamPageInfo';
import { createPage, savePage } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    loadingPages: state.pages.loadingPages,
    loadingCreate: state.pages.loadingCreate,
    loadingSave: state.pages.loadingSave,
    newPage: state.pages.newPage,
    organizationName: state.user.user.organizationName,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, { createPage, savePage })(
  StreamPageInfo,
);
