import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { defaultTile } from '../../../globals/defaults';
import PageTitle from '../PageTitle';
import Form from './Form';
import CustomFormik from '../CustomFormik';
import { createSchema, maxLengths } from './validation';
import {
  CUSTOM_TEXT,
  BUTTON_TEXT_OPTIONS_GIFT,
  BUTTON_TEXT_OPTIONS_PAYMENT,
  TILE_TYPE_PAYMENT,
} from '../../../globals/constants';

export function TileDescription(props) {
  const {
    pageTitleKey,
    newTile,
    goToNext,
    goToPrev,
    loadingTile,
    loadingSave,
    saveTile,
    updateTile,
    setError,
    tile = defaultTile,
    isFinished,
  } = props;
  const buttonTextOptions =
    tile?.type === TILE_TYPE_PAYMENT
      ? BUTTON_TEXT_OPTIONS_PAYMENT
      : BUTTON_TEXT_OPTIONS_GIFT;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const schema = createSchema();

  useEffect(() => {
    if (newTile.id && goToNext) {
      goToNext();
    }
  }, [newTile, goToNext]);

  const renderForm = () => {
    if (loadingTile || !tile) {
      return null;
    }

    const onSubmit = async values => {
      const formTile = {
        ...tile,
        ...values,
      };
      await saveTile(formTile);
      queryClient.setQueryData(['tile', formTile.id], formTile);
    };

    return (
      <CustomFormik
        onChange={updateTile}
        onErrorsChange={setError}
        initialValues={{
          description: tile.description,
          shortDescription: tile.shortDescription,
          customButtonText: tile.customButtonText,
          buttonText:
            tile.buttonText === '' ||
            buttonTextOptions.includes(tile.buttonText)
              ? tile.buttonText
              : CUSTOM_TEXT,
        }}
        onSubmit={onSubmit}
        render={formikProps => (
          <Form
            maxLengths={maxLengths}
            goToPrev={goToPrev}
            loading={loadingSave}
            updateTile={updateTile}
            isFinished={isFinished}
            buttonTextOptions={buttonTextOptions}
            {...formikProps}
          />
        )}
        validationSchema={schema}
      />
    );
  };

  return (
    <>
      <PageTitle>{t(pageTitleKey || 'tile.header.title')}</PageTitle>
      {renderForm()}
    </>
  );
}

TileDescription.propTypes = {
  pageTitleKey: PropTypes.string,
  goToNext: PropTypes.func,
  goToPrev: PropTypes.func,
  saveTile: PropTypes.func.isRequired,
  loadingTile: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  tile: PropTypes.object,
  newTile: PropTypes.object.isRequired,
  updateTile: PropTypes.func,
  setError: PropTypes.func,
  isFinished: PropTypes.bool,
};

TileDescription.defaultProps = {
  pageTitleKey: null,
  tile: defaultTile,
  goToNext: () => {},
  goToPrev: null,
  updateTile: null,
  setError: null,
  isFinished: false,
};

export default TileDescription;
