import { push } from 'connected-react-router';
import UserService from '../../api/UserService';
import AuthService from '../../api/AuthService';
import Cookies from '../../utils/Cookies';
import { createSession, isTokenExpired } from '../../utils/session';
import {
  GET_USERS_BEGIN,
  GET_USERS_FAILED,
  GET_USERS_SUCCESS,
  USER_LOGIN_BEGIN,
  USER_LOGIN_FAILED,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  AUTH_BEGIN,
  AUTH_SUCCESS,
  AUTH_FAILED,
  CREATE_USER_START,
  CREATE_USER_FINISH,
  CREATE_USER_ERROR,
  REMOVE_USER_START,
  REMOVE_USER_FINISH,
  REMOVE_USER_ERROR,
  CLEAR_USER_ERROR,
  SET_CREATE_USER_MODAL_OPEN,
  SET_REMOVE_USER_MODAL_OPEN,
  SET_EDIT_USER_MODAL_OPEN,
  SET_EDIT_USER_PERMISSIONS_MODAL_OPEN,
  SET_USER,
  OWNERSHIP_TRANSFERRED_START,
  OWNERSHIP_TRANSFERRED_FINISH,
  OWNERSHIP_TRANSFERRED_ERROR,
  SET_EMAIL_SETTINGS_MODAL_OPEN,
  EMAIL_SETTINGS_UPDATE_START,
  EMAIL_SETTINGS_UPDATE_FINISH,
  EMAIL_SETTINGS_ERROR,
  DISABLE_PROMPT_TO_COMPLETE_ORGANIZATION_SETTINGS,
  REFRESHED_TOKEN,
} from './types';
import {
  setError,
  setSuccess,
  handleException,
  handleResponseError,
} from '../alerts/actions';
import i18n from '../../utils/i18n';
import { backendToFrontend, userOrgFrontendToBackend } from './transform';
import { setAppLoading } from '../loading/actions';
import { getPages, setCurrentPage, setStreamerId } from '../pages/actions';
import { getTiles, clearTiles } from '../tiles/actions';
import {
  open as openLoadingDialog,
  close as closeLoadingDialog,
} from '../loadingDialog/actions';
import { getFeatures, loadFeatures } from '../features/actions';
import { LOGIN_ORG_START } from '../internal/types';
import { CLEAN_UP_TRANSACTIONS } from '../transaction/types';
import {
  ORGANIZATION_CONTINUE_WIZARD_ROUTE,
  SUCCESSFUL_HTTP_RESPONSES,
} from '../../globals/constants';
import { objectKeysToCamelCase } from '../../common/apiUtils';
import { renewAccessToken } from '../../api/AuthService/renewTokenService';
import { isValidObject } from '../../utils/validations';

function setSession(session) {
  const newSession = createSession(session);
  Cookies.setSessionCookie(newSession);
  return newSession;
}

function initAppcuesSession(session, user) {
  window.Appcues.identify(`MV-${session.idTokenPayload.email}`, {
    firstName: session.idTokenPayload.given_name,
    lastName: session.idTokenPayload.family_name,
    companyName: user.organizationName,
    role: user.role,
    isFirstLogin: user.lastLogin === null,
    hasVOSite: user.organizationHasVOSite,
    hasVMSite: user.organizationHasVMSite,
  });
}

function storeStreamerId(dispatch, streamerId) {
  dispatch(setStreamerId(streamerId));
}

/* actions */
function loadAuth(showError = false, redirectPath = '/') {
  return async (dispatch) => {
    dispatch({
      type: AUTH_BEGIN,
    });

    // load feature flags for this session
    dispatch(loadFeatures());

    const session = Cookies.getSessionCookie();
    const user = Cookies.getUserCookie();

    if (session) {
      if (user) {
        initAppcuesSession(session, user);
      }
      dispatch({
        type: AUTH_SUCCESS,
        session,
        user,
        redirectPath,
      });
      storeStreamerId(dispatch, user?.streamerId);
    } else {
      dispatch({
        type: AUTH_FAILED,
      });
      if (showError) dispatch(setError(i18n.t('errors.authFailed')));
    }
    return !!session;
  };
}

// call this when you want to logout a user it will call postLogout() after the redirect
export function logout() {
  return async (dispatch, getState) => {
    const { session } = getState().user;
    const { idToken } = session;
    try {
      dispatch(setAppLoading(true));
      const res = await UserService.logout();
      if (res.status === 200) {
        Cookies.deleteCookies();
        AuthService.logout(idToken);
        dispatch({
          type: USER_LOGOUT,
        });
        dispatch(setAppLoading(false));
      } else {
        dispatch(setError(res.message));
        dispatch(setAppLoading(false));
      }
    } catch (e) {
      dispatch(setError(e.message));
      dispatch(setAppLoading(false));
    }
  };
}

// called after AuthService.logout() is called via redirect
export function postLogout() {
  Cookies.deleteCookies();
  return {
    type: USER_LOGOUT,
  };
}

export function loginFailed(error) {
  return async (dispatch) => {
    // we must logout the user out of IDS if the login fails
    AuthService.logout();
    dispatch({
      type: USER_LOGIN_FAILED,
      error,
    });
  };
}

async function userLogin(dispatch) {
  const res = await UserService.login();
  if (res.status === 200) {
    const { headers } = res;
    const csrfToken = headers['x-csrf-token'];
    const { data } = res.data;
    const frontendUser = backendToFrontend(data);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      user: frontendUser,
    });
    Cookies.setUserCookie(frontendUser);
    storeStreamerId(dispatch, frontendUser.streamerId);
    Cookies.setCSRFToken(csrfToken);
    initAppcuesSession(Cookies.getSessionCookie(), frontendUser);
    return frontendUser;
  }

  dispatch(handleResponseError(res));
  dispatch(loginFailed(res.status));
  return null;
}

export function auth(showError = false, redirectPath = '/') {
  return async (dispatch) => {
    const authSucceeded = await dispatch(loadAuth(showError, redirectPath));

    const user = Cookies.getUserCookie();
    // If logged in (with IDS) but user is null because have not logged in with MyVanco API
    // Logging out of an organization as an internal user can cause this by deleting user cookie.
    if (authSucceeded && !user) {
      dispatch(setAppLoading(true));
      dispatch({ type: USER_LOGIN_BEGIN });
      try {
        await userLogin(dispatch);
      } catch (e) {
        dispatch(loginFailed(e));
        dispatch(handleException(e));
      } finally {
        dispatch(setAppLoading(false));
      }
    }
  };
}

export function login(session) {
  return async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      dispatch({ type: USER_LOGIN_BEGIN });
      setSession(session);
      const { redirectPath } = JSON.parse(session.state);
      const isInternalLogin = redirectPath
        ?.toLowerCase()
        .startsWith('/internallogin');
      if (isInternalLogin) {
        // This will ensure that the 'internalLoadingLogin' prop in the router will
        // prevent getPages from being called until the internal login process finishes
        dispatch({ type: LOGIN_ORG_START });
      }
      const redirectPathFinal = isInternalLogin ? redirectPath : undefined;
      await dispatch(loadAuth(true, redirectPathFinal));

      const frontendUser = await userLogin(dispatch);
      if (frontendUser) {
        if (
          frontendUser.isInternal &&
          !frontendUser.organizationId &&
          !isInternalLogin
        ) {
          // Before redirecting back to MyVanco Internal, delete the cookies. Need to make sure that if, after being
          // redirected to MyVanco Internal, they log out of IDS and then come back to MyVanco to try and
          // log in as some other user that we don't find old cookies related to their internal user and
          // keeep redirecting them back to MyVanco Internal.
          Cookies.deleteCookies();
          window.location.replace(window.env.REACT_APP_IP_URL);
          return;
        }
        if (!isInternalLogin) {
          // If it's internal login, wait until org has been set before fetching features
          await dispatch(getFeatures());
        }
      }
    } catch (e) {
      dispatch(loginFailed(e));
      dispatch(handleException(e));
    } finally {
      dispatch(setAppLoading(false));
    }
  };
}

export const renewAccessTokenFromRefresh = async (refreshToken, clientId) => {
  if (typeof refreshToken !== 'string' || !refreshToken.length) {
    throw new Error('Invalid refreshToken');
  }

  if (typeof clientId !== 'string' || !clientId.length) {
    throw new Error('Invalid clientId');
  }

  const { data = null, status = null } = await renewAccessToken(
    refreshToken,
    clientId,
  );
  if (!isValidObject(data) || status !== SUCCESSFUL_HTTP_RESPONSES.OK) {
    return null;
  }

  return objectKeysToCamelCase(data);
};

export function getOrRenewAccessToken() {
  return async (dispatch, getState) => {
    const { session } = getState().user;

    /*
     * We need to match which client is the
     * single source of truth when creating a session in IDS.
     * We cannot refresh an accessToken with MyVanco (MV)
     * if it was created from MyVancoInternal (MVI) and vice versa.
     * idTokenPayload.aud help us to identify who is the main clientId
     */
    const {
      accessToken,
      refreshToken,
      idTokenPayload: { aud: clientId },
    } = session;

    const hasTokenExpired = isTokenExpired(session);

    if (!hasTokenExpired) {
      return accessToken;
    }

    try {
      const renewedSession = await renewAccessTokenFromRefresh(
        refreshToken,
        clientId,
      );

      if (!renewedSession) {
        return null;
      }

      const currentSessionCookie = Cookies.getSessionCookie();
      /*
       * These prevents the SessionCookie and
       * the idsTokenPayload to be overwritten with the
       * renewedSession values only
       */
      const { tExpires } = setSession({
        ...currentSessionCookie,
        ...renewedSession,
      });

      /*
       * We need the tExpires field updated in Redux as well, right after
       * createSession establishes the new expiration time.
       */
      dispatch({
        type: REFRESHED_TOKEN,
        payload: { ...renewedSession, tExpires },
      });

      return renewedSession.accessToken;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      /*
       * renewAccessToken will throw
       * an exception if the refreshToken expires.
       */
      console.error({ error });
      await dispatch(logout());
      return null;
    }
  };
}

export function refreshPermissions() {
  return async (dispatch, getState) => {
    dispatch(setAppLoading(true));
    const currentFrontEndUser = getState().user.user;
    try {
      const res = await UserService.getUserPermissions();
      if (res.status === 200) {
        const newGrantedActions = backendToFrontend(
          res.data.data,
        ).grantedActions;

        const removedGrants = currentFrontEndUser.grantedActions.filter(
          (a) => !newGrantedActions.includes(a),
        );
        if (removedGrants.length > 0) {
          const newFrontendUser = {
            ...currentFrontEndUser,
            grantedActions: newGrantedActions,
          };

          Cookies.setUserCookie(newFrontendUser);
          storeStreamerId(dispatch, newFrontendUser.streamerId);
          dispatch({ type: SET_USER, user: newFrontendUser });
          return new Error('Your permissions have changed');
        }
        return null;
      }
      dispatch(handleResponseError(res));
      return null;
    } catch (e) {
      handleException(e);
    } finally {
      dispatch(setAppLoading(false));
    }
    return null;
  };
}

export function getUsers(orgId) {
  return async (dispatch) => {
    dispatch(setAppLoading(true));
    dispatch({ type: GET_USERS_BEGIN });

    try {
      const res = await UserService.getUsers(orgId);

      if (res.status === 200) {
        dispatch({
          type: GET_USERS_SUCCESS,
          data: res.data.data,
        });
      } else {
        dispatch({
          type: GET_USERS_FAILED,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: GET_USERS_FAILED,
        error: e.message,
      });
      dispatch(handleException(e));
    }

    dispatch(setAppLoading(false));
  };
}

export function createUser(user, organizationId) {
  return async (dispatch) => {
    dispatch({ type: CREATE_USER_START });

    try {
      const { data } = await UserService.createUser(
        userOrgFrontendToBackend(user, organizationId),
      );

      dispatch({
        type: CREATE_USER_FINISH,
        data: data.data,
      });
      dispatch(setSuccess(i18n.t('users.addUser.success')));
    } catch (e) {
      dispatch({
        type: CREATE_USER_ERROR,
        error: e,
      });
    }
  };
}

export function removeUser(user, organizationId) {
  return async (dispatch) => {
    dispatch({ type: REMOVE_USER_START });

    try {
      const { data } = await UserService.removeUser(
        user.idsuid,
        organizationId,
      );
      dispatch({
        type: REMOVE_USER_FINISH,
        data: data.data,
      });
      dispatch(getUsers(organizationId));
      dispatch(setSuccess(i18n.t('users.removeUser.success')));
    } catch (e) {
      dispatch({
        type: REMOVE_USER_ERROR,
        error: e,
      });
      dispatch(handleException(e));
    }
  };
}

export function clearUserError() {
  return async (dispatch) => {
    dispatch({ type: CLEAR_USER_ERROR });
  };
}

export function setCreateUserModalOpen(isOpen) {
  return async (dispatch) => {
    dispatch({ type: SET_CREATE_USER_MODAL_OPEN, isOpen });
  };
}

export function setRemoveUserModalOpen(isOpen, userToRemove) {
  return async (dispatch) => {
    dispatch({ type: SET_REMOVE_USER_MODAL_OPEN, isOpen, userToRemove });
  };
}

export function setEditUserModalOpen(isOpen, userToEdit) {
  return async (dispatch) => {
    dispatch({ type: SET_EDIT_USER_MODAL_OPEN, isOpen, userToEdit });
  };
}

export function setEditUserPermissionsModalOpen(isOpen, userToEdit) {
  return async (dispatch) => {
    dispatch({
      type: SET_EDIT_USER_PERMISSIONS_MODAL_OPEN,
      isOpen,
      userToEdit,
    });
  };
}

export function setEmailSettingsModalOpen(isOpen, userToEdit) {
  return async (dispatch) => {
    dispatch({ type: SET_EMAIL_SETTINGS_MODAL_OPEN, isOpen, userToEdit });
  };
}

export function setOrganization(orgId, redirectToHome = false) {
  return async (dispatch, getState) => {
    /*
     * We need the previous wasInternal value if the user was internal
     * from the very beginning to keep refreshing the accessToken. Otherwise,
     * the refreshToken service will throw an exception since the clientId
     * won't match.
     */
    const {
      user: { wasInternal },
    } = getState().user;

    dispatch(openLoadingDialog(i18n.t('loading.switchingAccounts')));
    try {
      const {
        data: { data },
      } = await UserService.setOrganization(orgId);
      await dispatch(getFeatures());

      const serviceData = backendToFrontend(data);
      const frontendUser = { ...serviceData, wasInternal };
      dispatch({
        type: SET_USER,
        user: frontendUser,
      });
      Cookies.setUserCookie(frontendUser);
      storeStreamerId(dispatch, frontendUser.streamerId);
      const currentSession = Cookies.getSessionCookie();
      initAppcuesSession(currentSession, frontendUser);

      if (data.promptToCompleteOrganizationSettings) {
        dispatch(push(ORGANIZATION_CONTINUE_WIZARD_ROUTE));
      } else if (redirectToHome) {
        dispatch(push('/'));
      }
      await dispatch(getPages());
      const { pages = [] } = getState().pages;
      await dispatch(setCurrentPage(pages[0] || {}));
      await dispatch(pages.length > 0 ? getTiles() : clearTiles());
      dispatch(closeLoadingDialog());
      dispatch({
        type: CLEAN_UP_TRANSACTIONS,
      });
    } catch (e) {
      dispatch(handleException(e));
      dispatch(closeLoadingDialog());
    }
  };
}

export function transferOwnership(user, organizationId) {
  return async (dispatch) => {
    dispatch({ type: OWNERSHIP_TRANSFERRED_START });
    try {
      const {
        data: { data },
      } = await UserService.transferOwnership(user.idsuid, organizationId);

      const { wasInternal, organizationName } = Cookies.getUserCookie();
      const frontendUser = {
        ...backendToFrontend(data),
        wasInternal,
        organizationName,
      };
      Cookies.setUserCookie(frontendUser);
      dispatch({
        type: SET_USER,
        user: frontendUser,
      });
      await dispatch(getUsers(organizationId));
      dispatch({
        type: OWNERSHIP_TRANSFERRED_FINISH,
      });
      dispatch(setSuccess(i18n.t('users.ownership.transferred.success')));
    } catch (e) {
      dispatch({
        type: OWNERSHIP_TRANSFERRED_ERROR,
        error: e,
      });
      dispatch(handleException(e));
    }
  };
}

export function patchUser(idsuid, values) {
  return async (dispatch) => {
    dispatch({ type: EMAIL_SETTINGS_UPDATE_START });
    try {
      await UserService.patchUser(idsuid, values);
      dispatch({
        type: EMAIL_SETTINGS_UPDATE_FINISH,
        data: { idsuid, ...values },
      });
      dispatch(setSuccess(i18n.t('users.editEmailNotifications.success')));
    } catch (e) {
      dispatch({
        type: EMAIL_SETTINGS_ERROR,
        error: e,
      });
      dispatch(handleException(e));
    }
  };
}

export function disablePromptToCompleteOrganizationSettings() {
  return async (dispatch, getState) => {
    dispatch({ type: DISABLE_PROMPT_TO_COMPLETE_ORGANIZATION_SETTINGS });

    // Save in Cookies (in case the user refreshes the screen before a logout)
    const { user } = getState().user;
    const updatedUser = {
      ...user,
      promptToCompleteOrganizationSettings: false,
    };
    Cookies.setUserCookie(updatedUser);
  };
}

export function getOrganizationId(orgId) {
  return async (dispatch) => {
    try {
      const res = await UserService.getOrganizationId(orgId);

      if ([201, 200].includes(res.status)) {
        const orgData = res.data.data;
        return orgData;
      }

      dispatch(handleResponseError(res));
    } catch (e) {
      dispatch(handleException(e));
    }

    return null;
  };
}
