export default () => ({
  emptyList: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '72px 0',
  },
  title: {
    fontSize: '2.4rem',
    marginBottom: 16,
  },
});
