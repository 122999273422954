export default function styles() {
  return {
    show: {
      display: 'block',
    },
    hidden: {
      display: 'none',
    },
  };
}
