import { useState } from 'react';
import { func, bool, objectOf, string, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid, withStyles } from '@material-ui/core';
import { Form } from 'formik';

import PrimaryButton from '../PrimaryButton';
import PagePaper from '../PagePaper';
import PageTitle from '../PageTitle';
import BrandingForm from '../VancoMobileBrandingForm';
import CustomFormik from '../CustomFormik';
import Page from '../Page';
import PreventLeavePageModal from '../PreventLeavePageModal';
import usePreventModal from '../../../utils/hooks/usePreventModal';
import useGetPageSettings from '../../../utils/hooks/useGetPageSettings';

import {
  CHANNEL_TYPE,
  DEFAULT_COLOR,
  PAGE_ACTIVE,
} from '../../../globals/constants';

import styles from './styles';

function VancoMobileAppBranding({
  classes,
  savePage,
  setSuccess,
  loadingSave,
}) {
  const { t } = useTranslation();
  const [dirty, setDirty] = useState(false);
  const { goBack, onCancel, cancelLeave } = usePreventModal('/mobile/home');
  const { isLoading, page, updatePage } = useGetPageSettings(
    CHANNEL_TYPE.MOBILE,
    PAGE_ACTIVE,
  );

  const update = async (values) => {
    await savePage({
      ...page,
      ...values,
    });
  };

  const onSubmit = async (values) => {
    await update(values);
    setSuccess(t('mobileSetup.branding.success'));
    setDirty(false);
    goBack();
  };

  const handleUpdateForm = (key, value) => {
    setDirty(true);
    updatePage({
      ...page,
      [key]: value,
    });
  };

  if (isLoading) {
    return null;
  }

  const brandingFormProps = { loadingSave, page };

  // TODO: Remove with ChannelManagement FF cleanup
  return (
    <Page variant="centered">
      <PageTitle className={classes.title} onGoBack={goBack}>
        {t('mobilePageWizard.branding.key')}
      </PageTitle>
      <PagePaper className={classes.paper}>
        <CustomFormik
          onChange={handleUpdateForm}
          enableReinitialize
          initialValues={{
            primaryColor: page.primaryColor || DEFAULT_COLOR,
            logoImage: page.logoImage,
            bannerImage: page.bannerImage,
          }}
          onSubmit={onSubmit}
          render={(formikProps) => {
            const { errors } = formikProps;
            return (
              <Form>
                <BrandingForm
                  formikProps={formikProps}
                  {...brandingFormProps}
                />
                <Grid item className={classes.buttonsContainer}>
                  {!loadingSave ? (
                    <>
                      <Button color="primary" onClick={onCancel} size="large">
                        {t('button.cancel')}
                      </Button>
                      <PrimaryButton
                        type="submit"
                        size="large"
                        disabled={
                          !dirty || Object.keys(errors || {}).length > 0
                        }
                      >
                        {t('button.save')}
                      </PrimaryButton>
                    </>
                  ) : (
                    <CircularProgress
                      color="primary"
                      data-testid="mobile-setup-spinner"
                    />
                  )}
                </Grid>
              </Form>
            );
          }}
        />
      </PagePaper>
      <PreventLeavePageModal shouldOpenOnLeave={dirty && !cancelLeave} />
    </Page>
  );
}

VancoMobileAppBranding.propTypes = {
  page: shape({}),
  getPage: func.isRequired,
  classes: objectOf(string).isRequired,
  savePage: func.isRequired,
  setSuccess: func.isRequired,
  loadingPage: bool.isRequired,
  hasLoadedPages: bool.isRequired,
  loadingSave: bool.isRequired,
};

VancoMobileAppBranding.defaultProps = {
  page: {},
};

export default withStyles(styles)(VancoMobileAppBranding);
