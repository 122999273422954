import { connect } from 'react-redux';

import VancoMobileBasicInfoWizard from './VancoMobileBasicInfoWizard';
import { savePage } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    loadingPage: state.pages.loadingPage,
    loadingSave: state.pages.loadingSave,
    newPage: state.pages.newPage,
  };
}

export default connect(mapStateToProps, { savePage })(
  VancoMobileBasicInfoWizard,
);
