import { oneOfType, string, number, bool, func } from 'prop-types';
import MaterialTab from '@material-ui/core/Tab';
import { Tooltip } from '@material-ui/core';
import useStyles from './styles';

export default function Tab({
  value,
  label,
  disabledHintText,
  disabled,
  onChange,
  ...rest
}) {
  const classes = useStyles();

  const handleTabClick = (...props) => {
    if (disabled) {
      return;
    }
    onChange(...props);
  };

  return (
    <Tooltip disableHoverListener={!disabled} title={disabledHintText} arrow>
      <MaterialTab
        className={disabled ? classes.softDisabled : ''}
        value={value}
        label={label}
        onChange={handleTabClick}
        disableRipple={disabled}
        aria-disabled={disabled}
        {...rest}
      />
    </Tooltip>
  );
}

Tab.defaultProps = {
  disabledHintText: '',
  disabled: false,
  onChange: () => {},
};

Tab.propTypes = {
  value: oneOfType([string, number]).isRequired,
  label: string.isRequired,
  disabledHintText: string,
  disabled: bool,
  onChange: func,
};
