import * as Yup from 'yup';
import i18n from '../../../../utils/i18n';
import * as validations from '../../../../utils/validations';

export const maxLengths = {
  accountCode: 75,
  name: 60,
};

export const minLengths = {
  accountCode: 2,
  name: 2,
};

export const createSchema = () => {
  return Yup.object().shape({
    accountCode: Yup.string()
      .matches(
        validations.alphanumericPlusSomeSpecialChars,
        i18n.t('validation.alphanumericPlusSomeSpecialChars', {
          requiredNoun: i18n.t('fund.addFund.fundCode').toLocaleLowerCase(),
        }),
      )
      .required(
        i18n.t('validation.required', {
          requiredNoun: i18n.t('fund.addFund.fundCode').toLocaleLowerCase(),
        }),
      )
      .min(
        minLengths.accountCode,
        i18n.t('validation.short', { min: minLengths.accountCode }),
      )
      .max(maxLengths.accountCode, i18n.t('validation.long')),
    bankAccount: Yup.string().required(
      i18n.t('validation.required', {
        requiredNoun: i18n
          .t('fund.addFund.selectBankAccount')
          .toLocaleLowerCase(),
        requiredVerb: '',
      }),
    ),
    name: Yup.string()
      .matches(
        validations.alphanumericPlusSomeSpecialChars,
        i18n.t('validation.alphanumericPlusSomeSpecialChars', {
          requiredNoun: i18n.t('fund.addFund.fundName').toLocaleLowerCase(),
        }),
      )
      .required(
        i18n.t('validation.required', {
          requiredNoun: i18n.t('fund.addFund.fundName').toLocaleLowerCase(),
        }),
      )
      .min(
        minLengths.name,
        i18n.t('validation.short', { min: minLengths.name }),
      )
      .max(maxLengths.name, i18n.t('validation.long')),
  });
};
