import { bool, func, objectOf, string, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Typography, FormControl, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { Form } from 'formik';
import WizardButtons from '../../../../navigation/WizardLayout/WizardButtons';
import styles from './styles';
import OutlinedTextField from '../../../OutlinedTextField';
import ExternalLink from '../../../ExternalLink';
import EditorButtons from '../../EditorButtons';
import PreventLeavePageModal from '../../../PreventLeavePageModal';
import { getPhone } from '../../../../../utils/page';

function ContactForm(props) {
  const {
    classes,
    setFieldValue,
    handleSubmit,
    values,
    goToPrev,
    loading,
    isFinished,
    handleReset,
    dirty,
    errors,
    touched,
    handleBlur,
    maxLengths,
    onGoBack,
    isSubmitting,
  } = props;

  const {
    dbaName,
    phone,
    email,
    website,
    address,
    address2,
    city,
    state,
    zip,
  } = values;

  const hasErrors = Object.keys(errors).length > 0;
  const isEditor = !!onGoBack;
  const hasAddress = !!address || !!address2 || !!city || !!state || !!zip;

  const { t } = useTranslation();
  const setFieldValueRef = useRef(setFieldValue);

  useEffect(() => {
    setFieldValueRef.current = setFieldValue;
  }, [setFieldValue]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue({ target: { name, value } });
  };

  return (
    <Form>
      <Grid
        container
        className={`${classes.container} ${
          isEditor ? '' : classes.extraBottomSpace // prevent support widget to cover submit button
        }`}
        data-testid="form-container"
      >
        <Grid item container md={12}>
          <Grid item xs={12}>
            <Typography className={classes.subtitle} variant="body2">
              {t('organization.contact.name.title')}
            </Typography>
            <Typography className={classes.subtitleTop}>
              {t('organization.contact.name.description1')}
              <ExternalLink
                to="https://www.cognitoforms.com/VancoPayments/merchantaccountupdateform"
                className={classes.link}
              >
                <Button
                  variant="text"
                  color="primary"
                  className={classes.linkButton}
                >
                  {t('organization.contact.name.description2')}
                </Button>
              </ExternalLink>
              {t('organization.contact.name.description3')}
              <ExternalLink
                to="https://www.vancopayments.com/contact-us"
                className={classes.link}
              >
                <Button
                  variant="text"
                  color="primary"
                  className={classes.linkButton}
                >
                  {t('organization.contact.name.description4')}
                </Button>
              </ExternalLink>
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.disabledInfo}>
          <Grid item xs={12} sm={4}>
            <Typography variant="body2">
              {t('organization.contact.name.title')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{dbaName}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <section className={classes.heading}>
            <Typography className={classes.subtitle} variant="body2">
              {t('organization.contact.information')}
            </Typography>
            <Typography>
              {t('organization.contact.information.description1')}
              <ExternalLink
                to="https://myvanco.vancopayments.com/"
                className={classes.link}
              >
                <Button
                  variant="text"
                  color="primary"
                  className={classes.linkButton}
                >
                  {t('organization.contact.information.description2')}
                </Button>
              </ExternalLink>
              {t('organization.contact.information.description3')}
              <ExternalLink
                to="https://vancopayments.zendesk.com/hc/en-us"
                className={classes.link}
              >
                <Button
                  variant="text"
                  color="primary"
                  className={classes.linkButton}
                >
                  {`${t('organization.contact.information.description4')}.`}
                </Button>
              </ExternalLink>
            </Typography>
          </section>
        </Grid>

        <Grid container className={classes.disabledInfo}>
          <Grid item xs={12} sm={4}>
            <Typography variant="body2">
              {t('organization.contact.address.title')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            {hasAddress ? (
              <>
                <Typography data-testid="addres-line1">{`${address} ${address2}`}</Typography>
                <Typography data-testid="addres-line2">
                  {`${city}${!!state || !!zip ? ',' : ''} ${state} ${zip}`}
                </Typography>
              </>
            ) : (
              <Typography data-testid="addres-not-provided">
                {t('organization.review.notProvided')}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4} className={classes.phoneGutter}>
            <Typography variant="body2">
              {t('organization.review.phone')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.phoneGutter}>
            <Typography data-testid={phone ? 'phone' : 'phone-not-provided'}>
              {phone
                ? getPhone({ phone })
                : t('organization.review.notProvided')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <section className={classes.heading}>
            <Typography className={classes.subtitle} variant="body2">
              {t('organization.contact.additional.title')}
            </Typography>
            <Typography>
              {t('organization.contact.additional.description')}
            </Typography>
          </section>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <OutlinedTextField
              showCount
              id="email"
              name="email"
              data-testid="email"
              value={email}
              label={t('organization.contact.email')}
              inputProps={{
                maxLength: maxLengths.email,
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email && touched.email}
              errorMessage={touched.email && errors.email}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <OutlinedTextField
              showCount
              id="website"
              name="website"
              data-testid="website"
              value={website}
              label={t('organization.contact.website')}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.website && touched.website}
              errorMessage={touched.website && errors.website}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          {!loading && goToPrev && (
            <WizardButtons
              isFinished={isFinished}
              dirty={dirty}
              goToNext={handleSubmit}
              goToPrev={goToPrev}
              undo={handleReset}
              hidePrevious
            />
          )}
          {!loading && isEditor && (
            <EditorButtons
              onCancel={onGoBack}
              onHandleSubmit={handleSubmit}
              dirty={dirty && !hasErrors}
            />
          )}
          {loading && (
            <div
              className={
                isEditor
                  ? classes.spinnerContainer
                  : classes.spinnerContainerWizard
              }
            >
              <CircularProgress
                color="primary"
                className={classes.spinner}
                data-testid="spinner-test"
              />
            </div>
          )}
          {isEditor && (
            <PreventLeavePageModal
              shouldOpenOnLeave={dirty && !loading && !isSubmitting}
              onLeave={onGoBack}
            />
          )}
        </Grid>
      </Grid>
    </Form>
  );
}

ContactForm.propTypes = {
  classes: objectOf(string).isRequired,
  handleSubmit: func.isRequired,
  goToPrev: func,
  loading: bool,
  isFinished: bool.isRequired,
  handleReset: func.isRequired,
  dirty: bool.isRequired,
  setFieldValue: func.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  handleBlur: func.isRequired,
  maxLengths: object.isRequired,
  onGoBack: func,
  isSubmitting: bool.isRequired,
};

ContactForm.defaultProps = {
  goToPrev: null,
  loading: false,
  onGoBack: null,
};

export default withStyles(styles)(ContactForm);
