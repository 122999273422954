import Delta from 'quill-delta';

import { urlRegex } from '../../../utils/validations';

const urlStartsWithValidScheme = url => {
  const urlLower = (url ?? '').toLowerCase();
  return urlLower.startsWith('http://') || urlLower.startsWith('https://');
};

export const ensureValidScheme = url => {
  return urlStartsWithValidScheme(url) ? url : `http://${url}`;
};

export const isValidURL = url => {
  const urlLower = (url ?? '').toLowerCase();
  // eslint-disable-next-line no-script-url
  if (urlLower.startsWith('javascript:') || !url) {
    return false;
  }
  return url && urlRegex.test(url);
};

export const quillFormat = (quill, format, value) => {
  if (!value) {
    // Toggle off the format if selected text already has that format
    quill.format(format, false);
    return;
  }
  const { index, length } = quill.getSelection();
  if (length < 1) {
    // If no text is selected, enable the given format for when user starts typing
    const formats = quill.getFormat(index, 1);
    if (formats.link) {
      // Don't enable the format if cursor is inside of a link
      return;
    }
    quill.format(format, true);
  } else {
    // This part is for when a range of text is selected
    const content = quill.getContents(index, length);
    let currentIndex = index;
    content.ops.forEach(c => {
      if (!c?.attributes?.link) {
        quill.formatText(currentIndex, c.insert.length, { [format]: true });
      }
      currentIndex += c.insert.length;
    });
  }
};

export const quillClean = (quill, val) => {
  if (!val) {
    return;
  }
  const { index, length } = quill.getSelection();

  if (length > 0) {
    const content = quill.getContents(index, length);
    let currentIndex = index;
    content.ops.forEach(c => {
      if (!c?.attributes?.link) {
        quill.removeFormat(currentIndex, c.insert.length);
      }
      currentIndex += c.insert.length;
    });
  }
};

export const stripClipboardFormatMatcher = (node, delta) => {
  // Ignore the attributes part of each op
  const ops = delta.map(({ insert }) => ({ insert }));
  return new Delta(ops);
};
