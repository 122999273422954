import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CircularProgress, withStyles } from '@material-ui/core';
import { Form } from 'formik';

import PageTitle from '../../PageTitle';
import CustomFormik from '../../CustomFormik';
import FeesForm from './Form';
import styles from './styles';
import { createSchema } from './validation';
import useGetOrgSettings from '../../../../utils/hooks/useGetOrgSettings';
import PreventLeavePageModal from '../../PreventLeavePageModal';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import EditorButtons from '../EditorButtons';
import { defaultOrg } from '../../../../globals/defaults';

export function Fees({
  classes,
  organizationId,
  saveOrganizationSettings,
  pageTitleKey,
  goToNext,
  goToPrev,
  setError,
  isFinished,
  onGoBack,
}) {
  const schema = createSchema();
  const { t } = useTranslation();
  const { settings, loadingOrgSettings, savingOrgSettings } =
    useGetOrgSettings(organizationId);
  const isEditor = !!onGoBack;
  const [isSaving, setIsSaving] = useState(savingOrgSettings);
  const loading = loadingOrgSettings || isSaving || savingOrgSettings;

  const checkPercentage =
    typeof settings.checkPercentage === 'number'
      ? settings.checkPercentage.toFixed(2)
      : settings.checkPercentage || defaultOrg.checkPercentage.toFixed(2);
  const creditPercentage =
    typeof settings.creditPercentage === 'number'
      ? settings.creditPercentage.toFixed(2)
      : settings.creditPercentage || defaultOrg.creditPercentage.toFixed(2);

  const onSubmit = async (values) => {
    const formValues = {
      ...settings,
      hasPaymentMethods: true,
      ...values,
      // By disabling the allowOffsetFund checkbox, the fund information must be retained until form is saved.
      fund:
        values.enableProcessingFees && values.allowOffsetFund
          ? values.fund
          : undefined,
    };

    if (
      values.checkPercentage === '0.00' &&
      values.creditPercentage === '0.00'
    ) {
      formValues.enableProcessingFees = false;
    }

    const succeeded = await saveOrganizationSettings(
      organizationId,
      formValues,
      !!onGoBack,
    );

    if (succeeded) {
      setIsSaving(true);
      if (onGoBack) {
        onGoBack();
      } else {
        goToNext();
      }
    }
  };

  // Using useMemo here to make sure formValues only changes when
  // settings.enableProcessingFees changes (and *not* if the percentages change).
  // The enableReinitialize flag means that any change to the form's values
  // will cause the Formik form to reset and then form information about which
  // fields have been touched will be lost and validation errors won't show up in UI.
  const formValues = useMemo(
    () => ({
      enableProcessingFees:
        settings.hasPaymentMethods || isEditor
          ? settings.enableProcessingFees
          : true,
      applyFeeByDefault: settings.applyFeeByDefault,
      checkPercentage,
      creditPercentage,
      allowOffsetFund: !!settings.fund?.fundRefId,
      fund: settings.fund,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings.enableProcessingFees],
  );

  return (
    <>
      <PageTitle
        className={isEditor ? classes.editorGutter : classes.pageTitle}
      >
        {t(pageTitleKey)}
      </PageTitle>
      <CustomFormik
        onErrorsChange={setError}
        enableReinitialize
        initialValues={formValues}
        validationSchema={schema}
        onSubmit={onSubmit}
        render={(formikProps) => {
          const { dirty, handleSubmit, handleReset, errors } = formikProps;
          const hasErrors = Object.keys(errors).length > 0;
          return (
            <Form>
              <FeesForm
                goToPrev={goToPrev}
                isFinished={isFinished}
                isEditor={isEditor}
                {...formikProps}
              />
              {!loading && goToPrev && (
                <WizardButtons
                  isFinished={isFinished}
                  dirty={dirty}
                  goToNext={handleSubmit}
                  goToPrev={goToPrev}
                  undo={handleReset}
                />
              )}
              {!loading && isEditor && (
                <EditorButtons
                  onCancel={onGoBack}
                  onHandleSubmit={handleSubmit}
                  dirty={dirty && !hasErrors}
                />
              )}
              {loading && (
                <div
                  className={
                    isEditor
                      ? classes.spinnerContainer
                      : classes.spinnerContainerWizard
                  }
                >
                  <CircularProgress
                    color="primary"
                    data-testid="color-options-save-spinner"
                  />
                </div>
              )}
              {isEditor && (
                <PreventLeavePageModal
                  shouldOpenOnLeave={dirty && !loading && !isSaving}
                  onLeave={onGoBack}
                />
              )}
            </Form>
          );
        }}
      />
    </>
  );
}

Fees.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  organizationId: PropTypes.string.isRequired,
  saveOrganizationSettings: PropTypes.func.isRequired,
  pageTitleKey: PropTypes.string,
  goToNext: PropTypes.func,
  goToPrev: PropTypes.func,
  setError: PropTypes.func,
  isFinished: PropTypes.bool,
  onGoBack: PropTypes.func,
};

Fees.defaultProps = {
  pageTitleKey: '',
  goToNext: null,
  goToPrev: null,
  setError: null,
  isFinished: false,
  onGoBack: null,
};

export default withStyles(styles)(Fees);
