import { node } from 'prop-types';
import { useCardContext } from '../context';

function CardMediaContent({ children }) {
  const { classes } = useCardContext();

  return <div className={classes.cardMediaContent}>{children}</div>;
}

CardMediaContent.propTypes = {
  children: node,
};

CardMediaContent.defaultProps = {
  children: null,
};

export default CardMediaContent;
