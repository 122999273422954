import { OPEN_LOADING_DIALOG, CLOSE_LOADING_DIALOG } from './types';

const INITIAL_STATE = {
  open: false,
  message: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case OPEN_LOADING_DIALOG:
      return {
        open: true,
        message: action.message,
      };
    case CLOSE_LOADING_DIALOG:
      return {
        open: false,
        message: null,
      };
    default:
      return state;
  }
};
