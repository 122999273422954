import { object, func, string, objectOf, number, bool } from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import { useSelector } from 'react-redux';
import { validations } from '../../../utils';
import OutlinedTextField from '../OutlinedTextField';
import FormHelperTextBottom from '../FormHelperTextBottom';

import styles from './styles';
import OffsetFund from './OffsetFund';

function FeeDonationInputs({
  classes,
  values,
  touched,
  errors,
  setFieldValue,
  handleBlur,
  submitCount,
  isEditor,
}) {
  const { t } = useTranslation();
  const { features } = useSelector((state) => ({
    features: state.features?.features,
  }));
  const handleRadio = (e) => {
    const { name, value } = e.target;
    const checked = value === 'true';
    setFieldValue({ target: { name, value: checked } });
    if (name === 'enableProcessingFees')
      setFieldValue({ target: { name: 'applyFeeByDefault', value: checked } });
  };

  const decimalBlur = (e) => {
    const { name, value } = e.target;
    const withDecimal = parseFloat(value, 10).toFixed(2);
    const newValue = withDecimal === 'NaN' ? value : withDecimal;
    setFieldValue({ target: { name, value: newValue } });
    handleBlur(e);
  };

  const handleChangeAndStrip = (e) => {
    const { name, value } = e.target;
    const strippedValue = value.replace(validations.nonDecimal, '');
    setFieldValue({ target: { name, value: strippedValue } });
  };

  function renderBottomMessage(isTouched, error, value, max = '4') {
    if (isTouched && !!error) return <FormHelperTextBottom left={error} />;
    if (isTouched && ['0', '.'].includes(value[0]))
      return (
        <FormHelperTextBottom
          warning
          left={t('validation.lowPercentWarning', {
            value: value.toString(),
          })}
        />
      );
    return (
      <FormHelperTextBottom
        warning
        left={t('additionalOptions.percentage', { max })}
      />
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} lg={12}>
          <FormControl className={classes.radioGroup}>
            <RadioGroup
              name="enableProcessingFees"
              value={String(values.enableProcessingFees)}
              onChange={handleRadio}
            >
              <FormControlLabel
                control={
                  /* eslint-disable-next-line react/jsx-wrap-multilines */
                  <Radio
                    color="primary"
                    value="false"
                    inputProps={{
                      'data-testid': 'disableProcessingFees',
                    }}
                  />
                }
                label={t('additionalOptions.disable')}
              />
              <FormControlLabel
                control={
                  /* eslint-disable-next-line react/jsx-wrap-multilines */
                  <Radio
                    color="primary"
                    value="true"
                    inputProps={{
                      'data-testid': 'enableProcessingFees',
                    }}
                  />
                }
                label={t('additionalOptions.activate')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {values.enableProcessingFees && (
        <Grid container>
          <Grid item xs={12} sm={12} lg={12}>
            <Typography className={classes.vertGutterXL} variant="body2">
              {t('organization.fees.preselected')}
            </Typography>
            <FormControl className={classes.radioGroup}>
              <RadioGroup
                name="applyFeeByDefault"
                value={String(values.applyFeeByDefault)}
                onChange={handleRadio}
              >
                <FormControlLabel
                  control={
                    /* eslint-disable-next-line react/jsx-wrap-multilines */
                    <Radio
                      color="primary"
                      value="false"
                      inputProps={{
                        'data-testid': 'disableApplyFeeByDefault',
                      }}
                    />
                  }
                  label={t('organization.fees.preselected.no')}
                />
                <FormControlLabel
                  control={
                    /* eslint-disable-next-line react/jsx-wrap-multilines */
                    <Radio
                      color="primary"
                      value="true"
                      inputProps={{
                        'data-testid': 'applyFeeByDefault',
                      }}
                    />
                  }
                  label={t('organization.fees.preselected.yes')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Typography className={classes.header} variant="body2">
            {t('additionalOptions.credit')}
          </Typography>
          <Typography>{t('additionalOptions.credit.description')}</Typography>
          <Grid container>
            <Grid item xs={12} sm={12} lg={12}>
              <div className={classes.inputWrap}>
                <FormControl className={classes.input}>
                  <OutlinedTextField
                    name="creditPercentage"
                    placeholder={t('additionalOptions.input')}
                    value={values.creditPercentage}
                    onBlur={decimalBlur}
                    onChange={handleChangeAndStrip}
                    error={
                      touched.creditPercentage && !!errors.creditPercentage
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 4,
                      'data-testid': 'credit-percentage',
                      autoComplete: 'off',
                    }}
                    disabled={!values.enableProcessingFees}
                  />
                </FormControl>
                <Typography>{t('additionalOptions.inputHelper')}</Typography>
              </div>
              {renderBottomMessage(
                touched.creditPercentage,
                errors.creditPercentage,
                values.creditPercentage,
              )}
            </Grid>
          </Grid>
          <Typography className={classes.header} variant="body2">
            {t('additionalOptions.echeck')}
          </Typography>

          <Typography>{t('additionalOptions.echeck.description')}</Typography>
          <Grid container>
            <Grid item xs={12} sm={12} lg={12}>
              <div className={classes.inputWrap}>
                <FormControl className={classes.input}>
                  <OutlinedTextField
                    name="checkPercentage"
                    placeholder={t('additionalOptions.input')}
                    value={values.checkPercentage}
                    onBlur={decimalBlur}
                    onChange={handleChangeAndStrip}
                    error={touched.checkPercentage && !!errors.checkPercentage}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 4,
                      'data-testid': 'check-percentage',
                      autoComplete: 'off',
                    }}
                    disabled={!values.enableProcessingFees}
                  />
                </FormControl>
                <Typography>{t('additionalOptions.inputHelper')}</Typography>
              </div>
              {renderBottomMessage(
                touched.checkPercentage,
                errors.checkPercentage,
                values.checkPercentage,
                '2',
              )}
            </Grid>
          </Grid>
          {features.Offsets && (
            <Grid container>
              <Grid item xs={12} sm={12} lg={12} className={classes.vertGutter}>
                <OffsetFund
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  submitCount={submitCount}
                  isEditor={isEditor}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

FeeDonationInputs.propTypes = {
  classes: objectOf(string).isRequired,
  values: object.isRequired,
  touched: object.isRequired,
  errors: object.isRequired,
  setFieldValue: func.isRequired,
  handleBlur: func.isRequired,
  submitCount: number,
  isEditor: bool,
};

FeeDonationInputs.defaultProps = {
  submitCount: 0,
  isEditor: false,
};

export default withStyles(styles)(FeeDonationInputs);
