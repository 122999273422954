import { useSelector } from 'react-redux';

export default () => {
  const {
    isLoggedIn,
    loadingAuth,
    user: { grantedActions },
    session: { idTokenPayload },
  } = useSelector((state) => state.user);
  return {
    isLoggedIn,
    loadingAuth,
    grantedActions,
    idTokenPayload,
  };
};
