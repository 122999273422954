import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { NotFound } from '../../common';
import { userIsAllowed } from '../../../utils/permissions';
import withScrollToTop from '../../hoc/withScrollToTop';
import { PAGE_ACTIVE, PAGE_ARCHIVED } from '../../../globals/constants';
import useSetCurrentPageByChannel from '../../../utils/hooks/useSetCurrentPageByChannel';
import { useGetPageId } from '../../../utils/hooks';

export function PrivateRouteWithPage({
  isLoggedIn,
  loadingAuth,
  component: Component,
  grantedActions,
  requiredGrants,
  optionalGrants,
  channelType,
  pages,
  loadingPages,
  hasLoadedPages,
  fallbackPath,
  pageStatus,
  location,
  path,
  ...rest
}) {
  const pageId = useGetPageId([path]);
  useSetCurrentPageByChannel({ channelType, pageStatus, pageId });

  const channelTypeExist = pages.some(
    p => p.channelId === channelType && pageStatus.includes(p.status),
  );

  const WrappedComponent = useMemo(() => withScrollToTop(Component), [
    Component,
  ]);

  const renderRoute = props => {
    if (loadingAuth || (isLoggedIn && (loadingPages || !hasLoadedPages))) {
      return null;
    }

    if (
      isLoggedIn &&
      !userIsAllowed(grantedActions, requiredGrants, optionalGrants)
    ) {
      return <NotFound />;
    }

    if (isLoggedIn && !channelTypeExist) {
      return (
        <Redirect
          data-testid="redirect"
          to={{
            pathname: fallbackPath,
            state: { from: location },
          }}
        />
      );
    }

    if (isLoggedIn) {
      return <WrappedComponent {...rest} {...props} />;
    }

    return (
      <Redirect
        data-testid="redirect"
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  };

  return <Route {...rest} render={renderRoute} />;
}

PrivateRouteWithPage.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  location: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loadingAuth: PropTypes.bool.isRequired,
  grantedActions: PropTypes.arrayOf(PropTypes.string).isRequired,
  requiredGrants: PropTypes.arrayOf(PropTypes.string),
  optionalGrants: PropTypes.arrayOf(PropTypes.string),
  channelType: PropTypes.number.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadingPages: PropTypes.bool.isRequired,
  hasLoadedPages: PropTypes.bool.isRequired,
  fallbackPath: PropTypes.string.isRequired,
  pageStatus: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.string.isRequired,
};

PrivateRouteWithPage.defaultProps = {
  requiredGrants: [],
  optionalGrants: [],
  pageStatus: [PAGE_ACTIVE, PAGE_ARCHIVED],
};

export default withRouter(PrivateRouteWithPage);
