export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.white,
      overflow: 'hidden',
    },
  };
}
