export default () => ({
  title: {
    marginBottom: 20,
  },
  cardList: {
    marginTop: 24,
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    padding: '16px 20px',
    marginBottom: 6,
  },
  popoverText: {
    fontSize: '1.4rem',
  },
  groupAccess: {
    cursor: 'pointer',
  },
  itemNoMembers: {
    padding: '16px 24px 8px 24px',
  },
});
