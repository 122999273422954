import { func, objectOf, string, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { Modal, PrimaryButton } from '../../../../common/index';

function DisableStreamForm(props) {
  const { classes, setFieldValue, handleSubmit, handleClose, values } = props;
  const { enableVancoMobile } = values;

  const { t } = useTranslation();

  const handleRadioSelectionToggle = async (e) => {
    const isEnabled = e.target.value === 'true';
    await setFieldValue({
      target: { name: 'enableVancoMobile', value: isEnabled },
    });
  };

  return (
    <>
      <Modal.Content
        className={classes.content}
        style={{ overflowY: 'visible' }}
      >
        <Typography>
          {t('vanco.mobile.manageAccess.modal.description')}
        </Typography>
        <RadioGroup
          name="enableStreamChange"
          value={enableVancoMobile}
          onChange={handleRadioSelectionToggle}
        >
          <div className={classes.borderTop}>
            <FormControlLabel
              checked={enableVancoMobile}
              value="true"
              className={classes.marginFirstControl}
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Radio color={enableVancoMobile ? 'primary' : 'default'} />
              }
              label={t('vanco.mobile.manageAccess.modal.enable')}
            />
          </div>

          <div className={classes.border}>
            <FormControlLabel
              classes={{
                root: classes.radioGroupItem,
                label: classes.radioGroupLabel,
              }}
              checked={!enableVancoMobile}
              value="false"
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Radio
                  color={enableVancoMobile ? 'default' : 'primary'}
                  className={classes.marginRadio}
                />
              }
              labelPlacement="end"
              label={
                <Typography>
                  {t('vanco.mobile.manageAccess.modal.disable')}
                </Typography>
              }
            />
          </div>
        </RadioGroup>
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <Button
          onClick={handleClose}
          size="medium"
          variant="outlined"
          className={classes.closeButton}
        >
          {t('button.cancel')}
        </Button>
        <PrimaryButton
          type="submit"
          size="medium"
          data-testid="disable-date-submit"
          onClick={handleSubmit}
        >
          {t('disableStreamModal.button')}
        </PrimaryButton>
      </Modal.Actions>
    </>
  );
}

DisableStreamForm.propTypes = {
  classes: objectOf(string).isRequired,
  handleSubmit: func.isRequired,
  handleClose: func.isRequired,
  setFieldValue: func.isRequired,
  values: object.isRequired,
};

export default withStyles(styles)(DisableStreamForm);
