import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { useQueryClient } from 'react-query';

import { defaultTile } from '../../../globals/defaults';
import PageTitle from '../PageTitle';
import PageDescription from '../PageDescription';
import Form from './Form';
import CustomFormik from '../CustomFormik';
import ExternalLink from '../ExternalLink';
import { UNSPLASH_UTM_PARAMS } from '../../../globals/constants';
import styles from './styles';

export function TileImageSelect({
  classes,
  goToNext,
  goToPrev,
  updateTile,
  loadingSave,
  saveTile,
  tile = defaultTile,
  newTile,
  loadingUpdateImage,
  updateTileImage,
  isFinished,
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (newTile.id && goToNext) {
      goToNext();
    }
  }, [newTile, goToNext]);

  const handleSave = async values => {
    const formTile = {
      ...tile,
      ...values,
    };
    await saveTile(formTile);
    queryClient.setQueryData(['tile', formTile.id], formTile);
  };

  return (
    <>
      <PageTitle>{t('imageSelection.title')}</PageTitle>
      <PageDescription>
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        {t('imageSelection.description')}{' '}
        <ExternalLink
          to={`https://unsplash.com${UNSPLASH_UTM_PARAMS}`}
          className={classes.unsplashLink}
        >
          Unsplash
        </ExternalLink>
        .
      </PageDescription>
      <CustomFormik
        onChange={updateTile}
        initialValues={{
          image: tile.image,
          inputFileValue: '',
        }}
        onSubmit={handleSave}
        render={formikProps => (
          <Form
            loadingSave={loadingSave}
            goToNext={goToNext}
            goToPrev={goToPrev}
            isFinished={isFinished}
            tile={tile}
            updateTileImage={updateTileImage}
            loadingUpdateImage={loadingUpdateImage}
            {...formikProps}
          />
        )}
      />
    </>
  );
}

TileImageSelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  goToNext: PropTypes.func,
  goToPrev: PropTypes.func,
  updateTile: PropTypes.func,
  saveTile: PropTypes.func,
  tile: PropTypes.object,
  loadingSave: PropTypes.bool.isRequired,
  newTile: PropTypes.object.isRequired,
  loadingUpdateImage: PropTypes.bool.isRequired,
  updateTileImage: PropTypes.func.isRequired,
  isFinished: PropTypes.bool,
};

TileImageSelect.defaultProps = {
  tile: defaultTile,
  goToNext: null,
  goToPrev: null,
  updateTile: null,
  saveTile: () => {},
  isFinished: false,
};

export default withStyles(styles)(TileImageSelect);
