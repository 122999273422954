import moment from 'moment';
import { func } from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { getCurrentTimeZone } from 'utils/date';
import { useTranslation } from 'react-i18next';
import { createSchema, pageNameLength } from './validation';
import { CustomFormik, PagePaper } from '../../common';
import { useGetPages, useUser } from '../../../utils/hooks';
import { parseDescription } from '../../../utils/quill';
import useStyles from './styles';
import StreamPageInfoForm from '../../common/StreamPageInfo/Form';
import StreamPageGivingForm from '../../common/StreamPageGiving/Form';
import StreamPageChatForm from '../../common/StreamPageChat/Form';
import EditorButtons from '../../common/Organization/EditorButtons';
import { getPagesByChannelAndStatus } from '../../../utils/page';
import {
  CHANNEL_TYPE,
  PAGE_ACTIVE,
  PAGE_PLATFORM_YOUTUBE,
} from '../../../globals/constants';
import { getPage, savePage, createPage } from '../../../store/pages/actions';
import useLivestreamData from '../../../utils/hooks/useLivestreamData';
import { defaultPage } from '../../../globals/defaults';

export function LiveStreaming({ onCancel }) {
  const { organizationName = '' } = useUser();

  const { data: pages = [], status: getPagesStatus } = useGetPages();
  const { isLoading, streamerId, page } = useLivestreamData();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const displayContent = getPagesStatus !== 'loading';
  const schema = createSchema();

  useEffect(() => {
    if (streamerId) dispatch(getPage({ id: streamerId }));
  }, [dispatch, streamerId]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!displayContent) return <></>;

  const onlineSites = getPagesByChannelAndStatus(
    pages,
    CHANNEL_TYPE.ONLINE,
    PAGE_ACTIVE,
  );
  const donationsBaseLink = `${window.env.REACT_APP_DON_URL}/`;
  const buildGivingReference = (id, isTile) => {
    if (isTile) return `${donationsBaseLink}${page.locationURI}`;

    return `${donationsBaseLink}${
      onlineSites.find((s) => s.id === id).locationURI
    }`;
  };

  const handleSubmit = async (values) => {
    const referenceId = values.tileId ? values.tileId : values.siteId;

    if (page) {
      // Update Stream
      const formPage = {
        ...page,
        streamData: {
          ...page.streamData,
          ...values,
          title: values.title,
          givingReference: buildGivingReference(referenceId, !!values.tileId),
        },
        streamGivingOption: {
          ...page.streamGivingOption,
          campaignId: values.tileId,
          locationId: values.siteId,
        },
      };
      await dispatch(savePage(formPage, true, t('site.stream.saved')));
    } else {
      // Create an Active Stream Page
      const streamValues = {
        ...defaultPage,
        channelId: CHANNEL_TYPE.STREAM,
        streamData: {
          ...values,
          givingReference: buildGivingReference(referenceId, !!values.tileId),
        },
        streamGivingOption: {
          campaignId: values.tileId,
          locationId: values.siteId,
        },
      };
      await dispatch(
        createPage(streamValues, true, t('stream.created.success')),
      );
    }
  };

  const resolveUserTimeZone = (timeZone) => {
    if (timeZone) return timeZone;
    return getCurrentTimeZone();
  };

  const getInitialValues = () => {
    const defaultDate = moment().add(1, 'day');

    const defaultInitialValues = {
      title: page?.streamData?.title ?? organizationName,
      platform: page?.streamData?.platform ?? PAGE_PLATFORM_YOUTUBE,
      videoType: page?.streamData?.videoType ?? '',
      videoUrl: page?.streamData?.videoUrl ?? '',
      embedCode: page?.streamData?.embedCode ?? '',
      startDate: page?.streamData?.startDate ?? defaultDate,
      startTime: page?.streamData?.startTime ?? -1,
      endDate: page?.streamData?.endDate ?? defaultDate,
      endTime: page?.streamData?.endTime ?? -1,
      description: page?.streamData?.description ?? parseDescription(' '),
      timeZone: resolveUserTimeZone(page?.streamData?.timeZone),
      repeatWeekly: page?.streamData?.repeatWeekly ?? false,
      tileId: page?.streamGivingOption?.campaignId ?? undefined,
      siteId:
        page?.streamGivingOption?.locationId &&
        page?.streamData?.givingReference
          ? page?.streamGivingOption?.locationId
          : undefined,
      chatEnabled: page?.streamData?.chatEnabled ?? true,
      // eslint-disable-next-line no-nested-ternary
      chatTurnOnMinutes: page?.streamData?.chatTurnOnMinutes
        ? page?.streamData?.chatTurnOnMinutes === -1 // It allows us to know if the chat has already been configured
          ? 0
          : page?.streamData?.chatTurnOnMinutes
        : 0,
      chatTurnOffMinutes: page?.streamData?.chatTurnOffMinutes ?? 0,
    };

    return defaultInitialValues;
  };

  return (
    <PagePaper contained>
      <div className={classes.padding}>
        <CustomFormik
          enableReinitialize={false}
          initialValues={getInitialValues()}
          onSubmit={handleSubmit}
          validationSchema={schema}
          render={(formikProps) => (
            <>
              <StreamPageInfoForm
                loading={isLoading || formikProps.isSubmitting}
                isFinished={false}
                page={page}
                isWizard={false}
                pages={onlineSites}
                pagesLoading={!displayContent}
                hideDividers
                tiles={[]}
                tilesLoading={false}
                maxLengths={{ title: pageNameLength }}
                {...formikProps}
              />
              <StreamPageGivingForm
                loading={isLoading || formikProps.isSubmitting}
                isFinished={false}
                page={page}
                isWizard={false}
                pages={onlineSites}
                pagesLoading={!displayContent}
                hideDividers
                tiles={[]}
                tilesLoading={false}
                {...formikProps}
              />
              <StreamPageChatForm
                loading={isLoading || formikProps.isSubmitting}
                isFinished={false}
                page={page}
                isWizard={false}
                pages={pages}
                hideDividers
                {...formikProps}
              />
              {!isLoading && (
                <EditorButtons
                  onCancel={onCancel}
                  onHandleSubmit={formikProps.handleSubmit}
                  dirty={formikProps.dirty && !formikProps.hasErrors}
                />
              )}
              {isLoading && (
                <div className={classes.spinnerContainer}>
                  <CircularProgress
                    color="primary"
                    data-testid="save-spinner"
                  />
                </div>
              )}
            </>
          )}
        />
      </div>
    </PagePaper>
  );
}

LiveStreaming.propTypes = {
  onCancel: func.isRequired,
};

export default LiveStreaming;
