import { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { IMAGE_ASPECTS } from '../../../globals/constants';

function Crop({ src, crop, onCrop, aspect = IMAGE_ASPECTS.RECTANGLE }) {
  const imgRef = useRef(null);

  const onImageLoad = (event) => {
    const { width, height } = event.currentTarget;
    onCrop(
      null,
      centerCrop(makeAspectCrop(crop, aspect, width, height), width, height),
    );
  };

  return (
    <ReactCrop crop={crop} onChange={onCrop} keepSelection aspect={aspect}>
      <img src={src} alt="crop" ref={imgRef} onLoad={onImageLoad} />
    </ReactCrop>
  );
}

Crop.propTypes = {
  src: PropTypes.string.isRequired,
  crop: PropTypes.object.isRequired,
  onCrop: PropTypes.func.isRequired,
  aspect: PropTypes.number.isRequired,
};

export default Crop;
