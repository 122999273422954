export default function styles() {
  return {
    tableRow: {
      cursor: 'pointer',
      '&:hover': {
        background: '#FFF',
        transform: 'scale(1,1)',
        '-webkit-transform': 'scale(1,1)',
        '-moz-transform': 'scale(1,1)',
        'box-shadow': '0px 1px 3px 1px rgba(0,0,0,0.2)',
        '-webkit-box-shadow': '0px 1px 3px 1px rgba(0,0,0,0.2)',
        '-moz-box-shadow': '0px 1px 3px 1px rgba(0,0,0,0.2)',
        zIndex: 1,
        // IE hack
        '& .MuiTableCell-root:first-child': {
          position: 'relative',
          borderLeft: '1px solid #E6E6E6',
          left: -1,
        },
        '& .MuiTableCell-root:last-child': {
          position: 'relative',
          borderRight: '1px solid #E6E6E6',
          right: -1,
        },
      },
    },
  };
}
