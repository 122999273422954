import { useQuery } from 'react-query';
import recurrenceAPI from '../api/recurrenceAPI';

function useImportFile(accessToken, id) {
  const { data, isLoading, error } = useQuery('importFile', () =>
    recurrenceAPI.getImportFile(accessToken, id),
  );

  return [data?.data?.data, error, isLoading];
}

export default useImportFile;
