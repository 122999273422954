import { ACST_PREFIXES, PF_PREFIXES } from '../../globals/constants';
import {
  USER_LOGOUT,
  USER_LOGIN_SUCCESS,
  SET_USER,
  AUTH_SUCCESS,
} from '../user/types';

import {
  SAVE_ORG_SETTINGS_START,
  SAVE_ORG_SETTINGS_FINISH,
  SAVE_ORG_SETTINGS_ERROR,
  GET_ORG_SETTINGS_START,
  GET_ORG_SETTINGS_FINISH,
  GET_ORG_SETTINGS_ERROR,
  GET_ORG_FEATURES_START,
  GET_ORG_FEATURES_FINISH,
  GET_ORG_FEATURES_ERROR,
  GET_ORG_GRANTS_FINISH,
  GET_ORG_GRANTS_START,
  GET_ORG_GRANTS_ERROR,
  GET_ALL_ORG_GRANTS_FINISH,
  GET_ALL_ORG_GRANTS_START,
  GET_ALL_ORG_GRANTS_ERROR,
} from './types';

const INITIAL_STATE = {
  error: null,
  loadingOrgSettings: false,
  savingOrgSettings: false,
  loadingFeaturesSettings: false,
  loadingOrganizationGrants: false,
  loadingALLOrganizationGrants: false,
  currentOrganization: {
    id: null,
    settings: {},
    features: [],
    grants: [],
    allAvailableGrants: [],
  },
};

const GetMobileSetting = (user) => {
  const associatedOrg = user?.associatedOrganizations.find(
    (o) => o.organizationId === user.organizationId,
  );

  return associatedOrg?.mobileEnabled;
};

const getClientID = (user) => {
  if (user) {
    return user.associatedOrganizations
      ?.find(
        (o) =>
          o.organizationId?.toLowerCase() ===
          user.organizationId?.toLowerCase(),
      )
      ?.clientId.toUpperCase();
  }
  return '';
};

const hasACSTPrefix = (user) => {
  if (user) {
    const orgClientId = getClientID(user);
    if (orgClientId)
      return ACST_PREFIXES.some((prefix) => orgClientId.startsWith(prefix));
  }

  return false;
};

const hasPFPrefix = (user) => {
  if (user) {
    const orgClientId = getClientID(user);
    if (orgClientId)
      return PF_PREFIXES.some((prefix) => orgClientId.startsWith(prefix));
  }

  return false;
};

export default (state = INITIAL_STATE, action = {}) => {
  const settings = action.data;

  switch (action.type) {
    case AUTH_SUCCESS:
    case USER_LOGIN_SUCCESS:
    case SET_USER: {
      return {
        currentOrganization: {
          ...state.currentOrganization,
          id: action.user?.organizationId,
          idsPayerTenant: action.user?.organizationTenant,
          mobileEnabled: GetMobileSetting(action.user),
          // Verify if is an ACST/PF Organizations
          isACSTOrganization: hasACSTPrefix(action.user),
          isPFOrganization: hasPFPrefix(action.user),
        },
      };
    }
    case USER_LOGOUT: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }

    // GET ORG_SETTINGS
    case GET_ORG_SETTINGS_START:
      return {
        ...state,
        loadingOrgSettings: true,
        error: null,
      };

    case GET_ORG_SETTINGS_FINISH: {
      return {
        ...state,
        loadingOrgSettings: false,
        error: null,
        currentOrganization: {
          ...state.currentOrganization,
          settings,
        },
      };
    }

    case GET_ORG_SETTINGS_ERROR:
      return {
        ...state,
        loadingOrgSettings: false,
        error: action.error,
      };

    case GET_ORG_FEATURES_START:
      return {
        ...state,
        loadingFeaturesSettings: true,
        error: null,
      };

    case GET_ORG_FEATURES_FINISH: {
      return {
        ...state,
        loadingFeaturesSettings: false,
        error: null,
        currentOrganization: {
          ...state.currentOrganization,
          features: action.data,
        },
      };
    }

    case GET_ORG_FEATURES_ERROR:
      return {
        ...state,
        loadingFeaturesSettings: false,
        error: action.error,
      };

    // SAVE
    case SAVE_ORG_SETTINGS_START:
      return {
        ...state,
        error: null,
        savingOrgSettings: true,
      };

    // Called when we save some info on page-setttings section
    case SAVE_ORG_SETTINGS_FINISH: {
      return {
        ...state,
        error: null,
        currentOrganization: {
          ...state.currentOrganization,
          settings,
        },
        savingOrgSettings: false,
      };
    }

    case SAVE_ORG_SETTINGS_ERROR:
      return {
        ...state,
        error: action.error,
        savingOrgSettings: false,
      };

    case GET_ORG_GRANTS_START:
      return {
        ...state,
        loadingOrganizationGrants: true,
        organizationGrantsLoaded: false,
      };

    case GET_ORG_GRANTS_FINISH:
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          grants: action.grants,
        },
        loadingOrganizationGrants: false,
        organizationGrantsLoaded: true,
      };

    case GET_ORG_GRANTS_ERROR:
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          error: action.error,
        },
        loadingOrganizationGrants: false,
        organizationGrantsLoaded: true,
      };

    case GET_ALL_ORG_GRANTS_START:
      return {
        ...state,
        loadingALLOrganizationGrants: true,
        allOrganizationGrantsLoaded: false,
      };

    case GET_ALL_ORG_GRANTS_FINISH:
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          allAvailableGrants: action.grants,
        },
        loadingALLOrganizationGrants: false,
        allOrganizationGrantsLoaded: true,
      };

    case GET_ALL_ORG_GRANTS_ERROR:
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          error: action.error,
        },
        loadingALLOrganizationGrants: false,
        allOrganizationGrantsLoaded: true,
      };

    default: {
      return state;
    }
  }
};
