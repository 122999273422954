const iconSize = 27;

const sectionText = {
  paddingTop: 3,
  marginLeft: 22,
  fontWeight: 'normal',
  lineHeight: '1.235',
  letterSpacing: '0.79px',
};

export default function styles(theme) {
  return {
    section: {
      width: '100%',
      paddingTop: 12,
      paddingBottom: 12,
    },
    sectionDeprecated: {
      display: 'flex',
      paddingTop: 15,
      paddingBottom: 15,
    },
    unfinishedText: {
      marginLeft: 12,
      fontWeight: '500',
      fontSize: 16,
      color: theme.palette.text.disabled,
    },
    unfinishedTextDeprecated: {
      ...sectionText,
      color: theme.palette.secondary.medium,
    },
    sectionText: {
      ...sectionText,
      color: theme.palette.icons,
    },
    sectionTextBold: {
      ...sectionText,
      fontWeight: 'bold',
      color: theme.palette.icons,
      marginLeft: 12,
      fontSize: 16,
      paddingTop: 0,
      letterSpacing: 'normal',
    },
    sectionTextBoldDeprecated: {
      ...sectionText,
      fontWeight: 'bold',
      color: theme.palette.icons,
    },
    icon: {
      fontSize: iconSize,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 14,
    },
    checkIcon: {
      fontSize: 20,
      color: theme.palette.primary.main,
      marginRight: 16,
    },
    notFinishedPadding: {
      width: iconSize,
      height: iconSize,
    },
    notFinishedCircle: {
      width: iconSize,
      height: iconSize,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 14,
    },
    bold: {
      fontWeight: 'bold',
    },
    lockIcon: {
      width: 20,
      height: 20,
      color: '#B5B9BD',
      marginRight: 15,
    },
  };
}
