import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';

import styles from './styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export function SnackbarContentWrapper(props) {
  const { className, message, onClose, classes, variant, ...other } = props;

  // We cache these so that when they're cleared, they don't
  // disappear during the close animation
  const [cachedVariant, setCachedVariant] = useState(variant);
  const [cachedMessage, setCachedMessage] = useState(message);

  useEffect(() => {
    if (variant) {
      setCachedVariant(variant);
    }
  }, [variant]);

  useEffect(() => {
    if (message) {
      setCachedMessage(message);
    }
  }, [message]);

  const Icon = variantIcon[cachedVariant];

  const renderMessage = () => (
    <Typography
      id="client-snackbar"
      data-testid="message"
      className={classes.message}
    >
      <Icon className={`${classes.icon} ${classes.iconVariant}`} />
      {cachedMessage}
    </Typography>
  );

  return (
    <SnackbarContent
      className={classes[cachedVariant]}
      aria-describedby="client-snackbar"
      message={renderMessage()}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
          data-testid="icon-close"
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
};

SnackbarContentWrapper.defaultProps = {
  variant: 'error',
  className: '',
  message: '',
};

export default withStyles(styles)(SnackbarContentWrapper);
