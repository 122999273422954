import { connect } from 'react-redux';

import StreamPageReviewWizard from './StreamPageReviewWizard';
import { savePage } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    loadingPage: state.pages.loadingPage,
    loadingSave: state.pages.loadingSave,
    pages: state.pages.pages,
    organizationName: state.user.user.organizationName,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, { savePage })(StreamPageReviewWizard);
