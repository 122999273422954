import { useQuery, useMutation, useQueryClient } from 'react-query';
import AccountGrantsService from 'api/AccountGrantsService';
import { SECTION_PERMISSIONS } from 'globals/grants';
import uniqBy from 'lodash/uniqBy';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSuccess, handleException } from 'store/alerts/actions';

const transformGrants = (backend) => {
  const permissions = backend.accountGrants
    .filter((g) => g.enabled)
    .map((g) => ({
      ...g,
      key: `${g.section}_${g.permission}`.toUpperCase(),
      label: g.section.replace(/_/g, ' '),
    }));

  Object.keys(SECTION_PERMISSIONS).forEach((sectionKey) => {
    if (
      backend.accountGrants.some((g) => {
        return g.section.toUpperCase() === sectionKey && g.enabled;
      })
    ) {
      permissions.push({
        section: sectionKey,
        key: sectionKey,
      });
    }
  });
  return permissions;
};

const filterSections = (grants) =>
  (grants ? uniqBy(grants, (g) => g.section?.toUpperCase()) : []).map((g) => ({
    ...g,
    key: g.section.toUpperCase(),
  }));

export default function useUserGrants(idsuid) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  async function getUserGrants() {
    const response = await AccountGrantsService.getUserGrants(idsuid);
    return transformGrants(response.data?.data);
  }

  async function updateUserGrants(accountGrants) {
    const body = { idsuid, accountGrants };
    const response = await AccountGrantsService.updateUserGrants(body);
    return response.data?.data;
  }
  const query = useQuery(['user-grants', idsuid], getUserGrants, {
    refetchOnWindowFocus: false,
  });
  const mutation = useMutation({
    mutationKey: ['user-grants', idsuid],
    mutationFn: updateUserGrants,
    onSuccess: () => {
      dispatch(setSuccess(t('site.changes.saved')));
      queryClient.invalidateQueries(['user-grants', idsuid]);
    },
    onError: (error) => {
      dispatch(handleException(error));
    },
  });

  return {
    isLoadingGrants: query.isFetching,
    userGrants: query.data,
    getError: query.error,
    sectionGrants: filterSections(query.data),
    updateGrants: mutation.mutateAsync,
    isUpdatingGrants: mutation.isLoading,
    updateError: mutation.error,
  };
}
