import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import styles from './styles';

export function ExampleModal({ classes, image, text, open, onClose }) {
  const { t } = useTranslation();
  const matches = useMediaQuery('(min-width:1280px)');

  return (
    <Dialog fullScreen={!matches} open={open} onClose={onClose}>
      <DialogContent classes={{ root: classes.content }}>
        {image && image.length && (
          <img alt="Example" src={image} className={classes.image} />
        )}
        {text && text.length && <Typography>{text}</Typography>}
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={onClose} size="large" color="primary">
          {t('button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ExampleModal.propTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string,
};

ExampleModal.defaultProps = {
  image: '',
  text: '',
};

export default withStyles(styles)(ExampleModal);
