import { shape, objectOf, string, arrayOf } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@material-ui/core';

import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { ReactComponent as LanguageIcon } from '../../../../assets/vancoOnlineIconBlue.svg';

import styles from './styles';
import { CHANNEL_TYPE } from '../../../../globals/constants';
import addWordSeparators from '../../../../utils/text/addWordSeparators';
import { getSiteNames } from '../../../../utils/tiles';

export function TileChannels({ classes, tile, pages }) {
  const decorateWithTooltip = (channelName, siteNames, child) => {
    const body = (
      <div className={classes.tooltipContent}>
        <Typography className={classes.channelLabel}>
          {channelName.toUpperCase()}
        </Typography>
        <ul className={classes.list}>
          <li>
            <Typography display="inline" className={classes.siteNamesText}>
              {siteNames}
            </Typography>
          </li>
        </ul>
      </div>
    );
    return (
      <Tooltip classes={{ tooltip: classes.tooltip }} title={body}>
        {child}
      </Tooltip>
    );
  };

  const { t } = useTranslation();

  const tileLocs = tile.locations;
  const onlineNames = getSiteNames(tileLocs, pages, CHANNEL_TYPE.ONLINE);
  const mobileNames = getSiteNames(tileLocs, pages, CHANNEL_TYPE.MOBILE);
  return (
    <>
      {onlineNames.length > 0 &&
        decorateWithTooltip(
          t('app.vancoOnline'),
          addWordSeparators(onlineNames),
          <div
            aria-label={t('app.vancoOnline')}
            className={classes.iconContainer}
          >
            <LanguageIcon />
          </div>,
        )}
      {mobileNames.length > 0 &&
        decorateWithTooltip(
          t('app.vancoMobile'),
          addWordSeparators(mobileNames),
          <div
            aria-label={t('app.vancoMobile')}
            className={classes.iconContainer}
          >
            <SmartphoneIcon color="primary" fontSize="inherit" />
          </div>,
        )}
    </>
  );
}

TileChannels.propTypes = {
  classes: objectOf(string).isRequired,
  tile: shape({}).isRequired,
  pages: arrayOf(shape({})).isRequired,
};

export default withStyles(styles)(TileChannels);
