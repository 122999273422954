import PropTypes, { objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import styles from './styles';

function PageBuilderTabs({ classes, value, onChange, isStreamPage, features }) {
  const { t } = useTranslation();

  return (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      value={value}
      onChange={onChange}
      className={
        isStreamPage || features.ChannelManagement
          ? classes.tabs
          : classes.tabsDeprecated
      }
      variant="scrollable"
    >
      <Tab label={t('stream.multipleStreams.tab.upcoming.title')} />
      <Tab label={t('stream.multipleStreams.tab.past.title')} />
      <Tab label={t('stream.multipleStreams.tab.all.title')} />
    </Tabs>
  );
}

PageBuilderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isStreamPage: PropTypes.bool.isRequired,
  features: objectOf(PropTypes.bool).isRequired,
};

export default withStyles(styles)(PageBuilderTabs);
