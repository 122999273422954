/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { parseObjectToQueryParams } from '../../common/apiUtils';

/**
 * @param {string} refreshToken The refreshToken from AuthService.
 * @param {string} clientId The IDS clientId. It should be from MyVanco or MyVanco Internal, it depends on the user type, internal or not.
 */
export const renewAccessToken = async (refreshToken, clientId) => {
  if (typeof refreshToken !== 'string' || !refreshToken.length) {
    throw new Error('Invalid refreshToken');
  }

  if (typeof clientId !== 'string' || !clientId.length) {
    throw new Error('Invalid clientId');
  }

  const baseUrl = `https://${window.env.REACT_APP_IDS_DOMAIN}/oauth/token`;

  const data = parseObjectToQueryParams({
    grant_type: 'refresh_token',
    client_id: clientId,
    refresh_token: refreshToken,
    audience: window.env.REACT_APP_IDS_AUDIENCE,
    scope: 'profile email phone address offline_access',
  });

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  const response = await axios.post(baseUrl, data, config);
  return response;
};
