import * as Yup from 'yup';
import i18n from '../../../utils/i18n';
import { memoLineValidations } from '../../../utils/validations';

// eslint-disable-next-line import/prefer-default-export
export const createSchema = (features) => {
  return Yup.object().shape({
    paymentFrequencies: Yup.array()
      .defined()
      .required(i18n.t('gift.frequencyRequired'))
      .min(1, i18n.t('gift.frequencyRequired')),
    fund: Yup.object()
      .nullable()
      .required(
        i18n.t('validation.required', {
          requiredNoun: i18n.t('fund.selectFund').toLocaleLowerCase(),
          requiredVerb: '',
        }),
      ),
    ...memoLineValidations(i18n, features),
  });
};
