import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import { TILE_ACTIVE, TILE_ARCHIVED } from '../../../../globals/constants';
import PrimaryButton from '../../PrimaryButton';
import Form from '../../Form';
import Tile from '../../Tile';
import styles from './styles';
import FormHelperTextBottom from '../../FormHelperTextBottom';
import ChannelVisibility from '../../ChannelVisibility';
import UnarchiveSection from '../UnarchiveSection';

function TileOverviewForm({
  handleSubmit,
  values,
  errors,
  setFieldValue,
  touched,
  goToPrev,
  goToNext,
  loading,
  tile,
  classes,
  unarchiveTile,
  resetForm,
}) {
  const { status, locations, visibility } = values;

  const { t } = useTranslation();

  const handleRadio = (e) => {
    const { name, value } = e.target;
    setFieldValue({ target: { name, value } });
  };
  const unarchiveAndReset = async () => {
    await unarchiveTile();
    resetForm({
      values: {
        ...values,
        status: TILE_ACTIVE,
        locations: [],
        visibility: false,
      },
    });
  };

  return (
    <>
      <Form>
        <Grid container spacing={5}>
          {!goToPrev && (
            <Grid item sm={5} xs={12}>
              <Typography variant="h4" className={classes.title}>
                {t('tile.visibility')}
              </Typography>
              {status === TILE_ARCHIVED ? (
                <UnarchiveSection
                  tile={tile}
                  unarchiveTile={unarchiveAndReset}
                />
              ) : (
                <ChannelVisibility
                  errors={errors}
                  locations={locations}
                  visibility={visibility}
                  setFieldValue={setFieldValue}
                  shouldSetDefaultLocations={false}
                  tileType={tile.type}
                />
              )}
            </Grid>
          )}
          <Grid item sm={7} xs={12}>
            <Typography variant="h4" className={classes.title}>
              {t('tile.preview')}
            </Typography>

            <section className={classes.content}>
              <Tile tile={tile} />
            </section>
            {goToPrev && (
              <>
                <section className={classes.question}>
                  <Typography variant="body2">
                    {t('wizard.tileReview.question1')}
                  </Typography>
                  <Typography>{t('wizard.tileReview.description1')}</Typography>
                </section>
                <Grid container>
                  <Grid item md={8} sm={12} xs={12}>
                    <FormControl className={classes.radioGroup}>
                      <RadioGroup
                        name="status"
                        value={status}
                        onChange={handleRadio}
                      >
                        <FormControlLabel
                          control={
                            /* eslint-disable-next-line react/jsx-wrap-multilines */
                            <Radio
                              color="primary"
                              value={TILE_ACTIVE}
                              inputProps={{
                                'data-testid': 'publish',
                              }}
                            />
                          }
                          label={
                            /* eslint-disable-next-line react/jsx-wrap-multilines */
                            <section className={classes.labelSection}>
                              <Typography variant="body2">
                                {t('wizard.tileReview.publish')}
                              </Typography>
                              <Typography>
                                {t('wizard.tileReview.publishDescription')}
                              </Typography>
                            </section>
                          }
                        />
                        <FormControlLabel
                          control={
                            /* eslint-disable-next-line react/jsx-wrap-multilines */
                            <Radio
                              color="primary"
                              value={TILE_ARCHIVED}
                              inputProps={{
                                'data-testid': 'archive',
                              }}
                            />
                          }
                          label={
                            /* eslint-disable-next-line react/jsx-wrap-multilines */
                            <section className={classes.labelSection}>
                              <Typography variant="body2">
                                {t('wizard.tileReview.archive')}
                              </Typography>
                              <Typography>
                                {t('wizard.tileReview.archiveDescription')}
                              </Typography>
                            </section>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormHelperTextBottom
                      left={touched.status && errors.status}
                    />
                  </Grid>
                </Grid>
                <br />
              </>
            )}
          </Grid>
        </Grid>
      </Form>

      {goToNext && !loading && (
        <>
          <PrimaryButton size="large" onClick={handleSubmit}>
            {t('button.done')}
          </PrimaryButton>
          <Button size="large" color="primary" onClick={goToPrev}>
            {t('button.previousStep')}
          </Button>
        </>
      )}
      {loading && goToPrev && <CircularProgress color="primary" />}
    </>
  );
}

TileOverviewForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  goToPrev: PropTypes.func,
  goToNext: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  tile: PropTypes.object.isRequired,
  unarchiveTile: PropTypes.func,
  resetForm: PropTypes.func.isRequired,
};

TileOverviewForm.defaultProps = {
  goToPrev: null,
  goToNext: null,
  unarchiveTile: null,
};

export default withStyles(styles)(TileOverviewForm);
