import { connect } from 'react-redux';
import KioskDisableModal from './KioskDisableModal';
import { disableKiosk, enableKiosk } from '../../../store/kiosk/actions';
import { setSuccess } from '../../../store/alerts/actions';

export function mapStateToProps(state) {
  return {
    hasKiosk: state.kiosk.hasKiosk,
  };
}

export default connect(mapStateToProps, {
  disableKiosk,
  enableKiosk,
  setSuccess,
})(KioskDisableModal);
