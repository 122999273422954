import { bool, node, string, func } from 'prop-types';
import MaterialTableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import { useTableContext } from '../context';

export function TableCell({ menu, className, onClick, children, ...rest }) {
  const { classes, disabled } = useTableContext();

  const cellClasses = clsx({
    [classes.tableCell]: true,
    [classes.menu]: menu,
    [className]: !!className,
  });

  return (
    <MaterialTableCell
      onClick={disabled ? null : onClick}
      className={cellClasses}
      {...rest}
    >
      {children}
    </MaterialTableCell>
  );
}

TableCell.propTypes = {
  menu: bool,
  children: node,
  className: string,
  onClick: func,
};

TableCell.defaultProps = {
  menu: false,
  children: null,
  className: '',
  onClick: null,
};

export default TableCell;
