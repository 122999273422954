import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from '../PrimaryButton';

import editImage from './edit.svg';
import styles from './styles';

function Tile({ classes, tile, onEdit, moving }) {
  const { t } = useTranslation();
  const { image, title, shortDescription, buttonText, customButtonText } = tile;
  const src = image ? image.src : '';

  const clickEdit = () => {
    if (onEdit) onEdit(tile);
  };

  let cta = buttonText;
  if (buttonText === 'custom') cta = customButtonText;

  return (
    <Paper
      onClick={clickEdit}
      className={`${classes.paper} ${moving ? classes.moving : ''}`}
    >
      <div
        data-testid="image"
        className={classes.image}
        style={{ backgroundImage: `url(${src})` }}
      />
      <section className={classes.content}>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {shortDescription}
        </Typography>
        <PrimaryButton size="medium">{cta}</PrimaryButton>
      </section>
      {onEdit && (
        <div className={classes.editOverlay}>
            <img className={classes.editImage} src={editImage} alt="Edit" />
            <Typography variant="h3" className={classes.title}>
              {t('tile.edit')}
            </Typography>
          </div>
      )}
    </Paper>
  );
}

Tile.propTypes = {
  classes: PropTypes.object.isRequired,
  tile: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  moving: PropTypes.bool,
};

Tile.defaultProps = {
  onEdit: null,
  moving: false,
};

export default withStyles(styles)(Tile);
