import { useSelector } from 'react-redux';
import { getPagesByChannelAndStatus } from '../../page';
import { CHANNEL_TYPE } from '../../../globals/constants';

export default () => {
  const filteredPage = useSelector(
    (state) =>
      getPagesByChannelAndStatus(state.pages.pages, CHANNEL_TYPE.STREAM)[0],
  );
  const isLoading = useSelector((state) => state.pages.loadingSave);
  return { isLoading, streamerId: filteredPage?.id, page: filteredPage };
};
