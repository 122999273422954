import { Button, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { func, objectOf, string } from 'prop-types';

import Modal from '../../Modal';
import PrimaryButton from '../../PrimaryButton';
import styles from './styles';

function ConfirmRemoveTileModal({
  classes,
  removedChannelName,
  tileTitle,
  closeModal,
  onConfirm,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      classes={{ ...classes, closeIcon: undefined }}
      open={!!tileTitle}
      onClose={closeModal}
    >
      <Modal.Title className={classes.title}>
        {t('tilesOrder.removeTileDialogTitle')}
      </Modal.Title>
      <Modal.Content>
        <Typography>
          <span>{t('tilesOrder.removeTileDialogContent1')}</span>
          <span style={{ fontWeight: 'bold' }}>{tileTitle}</span>
          <span>{t('tilesOrder.removeTileDialogContent2')}</span>
          <span style={{ fontWeight: 'bold' }}>{`${removedChannelName}`}</span>
          <span>{`? ${t('tilesOrder.removeTileDialogContent3')}`}</span>
        </Typography>
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <Button onClick={closeModal} size="medium" color="primary">
          {t('tilesOrder.removeTileDialogCancel')}
        </Button>
        <PrimaryButton
          size="medium"
          onClick={onConfirm}
          data-testid="confirm-hide-tile"
        >
          {t('tilesOrder.removeTileDialogConfirm')}
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
}

ConfirmRemoveTileModal.propTypes = {
  classes: objectOf(string).isRequired,
  removedChannelName: string.isRequired,
  tileTitle: string,
  closeModal: func.isRequired,
  onConfirm: func.isRequired,
};

ConfirmRemoveTileModal.defaultProps = {
  tileTitle: null,
};

export default withStyles(styles)(ConfirmRemoveTileModal);
