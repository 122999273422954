import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import styles from './styles';
import FormHelperTextBottom from '../../FormHelperTextBottom';
import OutlinedTextField from '../../OutlinedTextField';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import Form from '../../Form';
import AddressInputs from '../../AddressInputs';

function HeaderManagementForm(props) {
  const {
    handleSubmit,
    values,
    errors,
    maxLengths,
    setFieldValue,
    touched,
    handleBlur,
    classes,
    goToPrev,
    loading,
    isFinished,
    handleReset,
    dirty,
  } = props;
  const {
    pageName,
    email,
    phone,
    homePage,
    provideAddress,
    providePhone,
    provideEmail,
    displayPageName,
  } = values;
  const { t } = useTranslation();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue({ target: { name, value } });
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFieldValue({ target: { name, value: checked } });
  };

  // eslint-disable-next-line react/prop-types
  const renderCheckbox = ({ value, name, label }) => {
    return (
      <Grid item md={12} sm={12} xs={12}>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Checkbox
              name={name}
              id={name}
              color="primary"
              onChange={handleCheckboxChange}
              checked={!!value}
            />
          }
          label={label}
        />
      </Grid>
    );
  };

  const renderPageNameSection = () => {
    return (
      <>
        <Grid item md={9} sm={12} xs={12}>
          <Typography className={classes.subtitle} variant="body2">
            {t('headerManagement.giveName')}
          </Typography>
          <Typography>
            {t('headerManagement.giveName.description.deprecated')}
          </Typography>
          <FormControl fullWidth>
            <OutlinedTextField
              showCount
              id="pageName"
              name="pageName"
              data-testid="pageName"
              error={errors.pageName && touched.pageName}
              value={pageName}
              label={t('headerManagement.siteName')}
              placeholder={t('headerManagement.siteName.placeholder')}
              inputProps={{
                maxLength: maxLengths.pageName,
              }}
              onBlur={handleBlur}
              onChange={handleChange}
              autoComplete="off"
              autoFocus={!pageName.length}
              errorMessage={touched.pageName && errors.pageName}
            />
          </FormControl>
        </Grid>
        <Hidden smDown>
          <Grid item md={3}>
            <FormHelperText />
          </Grid>
        </Hidden>
        <FormControlLabel
          control={
            <Checkbox
              name="displayPageName"
              color="primary"
              onChange={handleCheckboxChange}
              checked={displayPageName}
            />
          }
          label={t('headerManagement.display.siteName')}
        />
      </>
    );
  };

  const renderAddressSection = () => {
    const checkbox = {
      name: 'provideAddress',
      value: provideAddress,
      label: 'Provide a mailing address',
    };
    if (!provideAddress) return renderCheckbox(checkbox);

    return (
      <>
        {renderCheckbox(checkbox)}
        <Grid item md={9} sm={12} xs={12}>
          <AddressInputs
            values={values}
            maxLengths={maxLengths}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
          />
        </Grid>
        <Hidden smDown>
          <Grid item md={3}>
            <FormHelperText />
          </Grid>
        </Hidden>
      </>
    );
  };

  const renderEmailSection = () => {
    const checkbox = {
      name: 'provideEmail',
      value: provideEmail,
      label: 'Provide an email address',
    };
    if (!provideEmail) return renderCheckbox(checkbox);

    return (
      <>
        {renderCheckbox(checkbox)}
        <Grid item md={9} sm={12} xs={12}>
          <FormControl fullWidth>
            <OutlinedTextField
              showCount
              id="email"
              name="email"
              data-testid="email"
              value={email}
              label={t('headerManagement.emailAddress')}
              inputProps={{
                maxLength: maxLengths.email,
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email && touched.email}
              errorMessage={touched.email && errors.email}
            />
          </FormControl>
        </Grid>
        <Hidden smDown>
          <Grid item md={3}>
            <FormHelperText />
          </Grid>
        </Hidden>
      </>
    );
  };

  const renderHomePageSection = () => {
    return (
      <>
        <Grid item md={9} sm={12} xs={12}>
          <Typography variant="body2" className={classes.subtitle}>
            {t('headerManagement.homeInfo')}
          </Typography>
          <Typography>{t('headerManagement.homeInfo.helper')}</Typography>
          <FormControl fullWidth>
            <OutlinedTextField
              showCount
              id="homePage"
              name="homePage"
              data-testid="homePage"
              value={homePage}
              label={t('headerManagement.homePage')}
              inputProps={{
                maxLength: maxLengths.homePage,
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.homePage && touched.homePage}
              errorMessage={touched.homePage && errors.homePage}
            />
          </FormControl>
        </Grid>
        <Hidden smDown>
          <Grid item md={3}>
            <FormHelperText />
          </Grid>
        </Hidden>
      </>
    );
  };

  const renderPhoneSection = () => {
    const checkbox = {
      name: 'providePhone',
      value: providePhone,
      label: 'Provide a phone number',
    };
    if (!providePhone) return renderCheckbox(checkbox);

    return (
      <>
        {renderCheckbox(checkbox)}
        <Grid item md={9} sm={12} xs={12}>
          <FormControl fullWidth>
            <OutlinedTextField
              showCount
              id="phone"
              name="phone"
              data-testid="phone"
              value={phone}
              label={t('headerManagement.phone')}
              inputProps={{
                maxLength: maxLengths.phone,
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.phone && touched.phone}
              errorMessage={touched.phone && errors.phone}
            />
          </FormControl>
        </Grid>
        <Hidden smDown>
          <Grid item md={3}>
            <FormHelperText />
          </Grid>
        </Hidden>
      </>
    );
  };

  const renderProvideError = () => {
    return (
      <Grid item md={12} sm={12} xs={12}>
        <FormHelperTextBottom left={errors.provideContact} />
      </Grid>
    );
  };

  return (
    <Form>
      <Grid container>
        {renderPageNameSection()}
        <Grid item md={12} sm={12} xs={12}>
          <Typography variant="body2" className={classes.subtitle}>
            {t('headerManagement.provideInfo')}
          </Typography>
          <Typography>{t('headerManagement.provideInfo.helper')}</Typography>
        </Grid>
        {renderPhoneSection()}
        {renderEmailSection()}
        {renderAddressSection()}
        {renderProvideError()}
        {renderHomePageSection()}
      </Grid>
      <br />
      {!loading && goToPrev && (
        <WizardButtons
          isFinished={isFinished}
          dirty={dirty}
          goToNext={handleSubmit}
          goToPrev={goToPrev}
          undo={handleReset}
          hidePrevious
        />
      )}
      {loading && goToPrev && <CircularProgress color="primary" />}
    </Form>
  );
}

HeaderManagementForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  maxLengths: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  goToPrev: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
};

HeaderManagementForm.defaultProps = {
  goToPrev: null,
};

export default withStyles(styles)(HeaderManagementForm);
