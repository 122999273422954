import PropTypes, { objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import styles from './styles';

function PageBuilderTabs({ classes, value, onChange, isStreamPage, features }) {
  const { t } = useTranslation();

  const getTileTabTitle = () => {
    if (isStreamPage) {
      return 'stream.pageWizard.basicInfo.title';
    }
    return features.VOExpressView
      ? 'pageLayout.tab.title'
      : 'tileManagement.tileOrder';
  };

  return (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      value={value}
      onChange={onChange}
      className={
        isStreamPage || features.ChannelManagement
          ? classes.tabs
          : classes.tabsDeprecated
      }
      variant="scrollable"
    >
      <Tab label={t(getTileTabTitle())} />
      {(!isStreamPage || (isStreamPage && !features.ChannelManagement)) && (
        <Tab
          label={t(
            // eslint-disable-next-line no-nested-ternary
            isStreamPage
              ? 'stream.pageWizard.branding.title' // Remove this on ChannelManagement FF cleanup
              : features.ChannelManagement
                ? 'siteSettings.title'
                : 'headerManagement.basicInfo',
          )}
        />
      )}
      {/* Tabs component doesn't accept a Fragment as a child (repeating conditional) */}
      {(isStreamPage || !features.ChannelManagement) && (
        <Tab
          label={t(
            isStreamPage
              ? 'stream.pageWizard.giving.title'
              : 'donations.colors',
          )}
        />
      )}
      {(isStreamPage || !features.ChannelManagement) && (
        <Tab
          label={t(
            isStreamPage
              ? 'stream.pageWizard.chat.title'
              : 'donations.processingFees',
          )}
        />
      )}
      {!isStreamPage && !features.ChannelManagement && (
        <Tab label={t('wizard.nav.socialSharing')} />
      )}
    </Tabs>
  );
}

PageBuilderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isStreamPage: PropTypes.bool.isRequired,
  features: objectOf(PropTypes.bool).isRequired,
};

export default withStyles(styles)(PageBuilderTabs);
