/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { defaultPage } from '../../../globals/defaults';
import { selectPage } from '../../../store/pages/selectors';
import {
  savePage,
  getPage as _getPage,
  resetNewPage,
} from '../../../store/pages/actions';

import {
  pagePaths,
  pagePathsDeprecated,
} from '../../navigation/WizardLayout/page.config';
import { mobilePagePaths } from '../../navigation/WizardLayout/mobile.page.config';
import { streamPagePaths } from '../../navigation/WizardLayout/stream.page.config';
import { useGetPageId } from '../../../utils/hooks';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

function PageWrapper(Component, pageTitleKey, keyParam = {}) {
  function Wrapped({
    currentPage,
    getPage,
    loadingPage,
    loadingPages,
    loadingCurrentPage,
    features,
    pages,
    ...rest
  }) {
    const location = useLocation();
    const isMobilePage = location.pathname.includes('mobile');
    const isStreamPage = location.pathname.includes('stream');

    const paths = isMobilePage
      ? mobilePagePaths
      : isStreamPage
        ? streamPagePaths
        : features.ChannelManagement
          ? pagePaths
          : pagePathsDeprecated;
    const pageId = useGetPageId(paths);
    const { t } = useTranslation();

    const hasKeyParam = Boolean(keyParam) && Object.keys(keyParam).length > 0;
    const pageTitle = hasKeyParam
      ? t(pageTitleKey, { [keyParam.key]: keyParam.value })
      : t(pageTitleKey);

    usePageTitle(pageTitle, true);
    const page = !loadingPages
      ? selectPage({ pages: { pages } }, pageId || currentPage?.id)
      : defaultPage;

    let content = null;

    useEffect(() => {
      if (currentPage && currentPage.id && !pageId) {
        getPage(currentPage);
      }

      if (pageId) {
        getPage({ id: pageId });
      }
    }, [getPage, currentPage, pageId, features]);
    // WARNING: Note that the following conditional rendering causes components
    // to briefly unmount and lose their state when things that cause loading
    // (such as clicking a save button) occur.
    if (loadingCurrentPage || loadingPage || !page?.id) {
      content = null;
    } else {
      content = <Component page={page} features={features} {...rest} />;
    }

    return content;
  }

  Wrapped.propTypes = {
    currentPage: PropTypes.object.isRequired,
    getPage: PropTypes.func.isRequired,
    loadingPage: PropTypes.bool.isRequired,
    loadingPages: PropTypes.bool.isRequired,
    loadingCurrentPage: PropTypes.bool.isRequired,
    features: PropTypes.objectOf(PropTypes.bool),
    pages: PropTypes.arrayOf(PropTypes.object),
  };

  Wrapped.defaultProps = {
    features: {},
    pages: [],
  };

  return Wrapped;
}

export function mapStateToProps(state) {
  return {
    loadingPage: state.pages.loadingPage,
    loadingPages: state.pages.loadingPages,
    loadingSave: state.pages.loadingSave,
    currentPage: state.pages.currentPage,
    loadingCurrentPage: state.pages.loadingCurrentPage,
    newPage: state.pages.newPage,
    pages: state.pages.pages,
    features: state.features?.features,
  };
}

const withPage = compose(
  connect(mapStateToProps, { savePage, getPage: _getPage, resetNewPage }),
  PageWrapper,
);

export default withPage;
