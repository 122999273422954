import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Divider, useMediaQuery } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Section from './Section';
import { types } from '../../../globals';
import CustomDrawer from '../../common/CustomDrawer';
import {
  SIDEBAR_WIDTH_WIDE,
  WIZARD_SIDEBAR_WIDTH_WIDE,
} from '../../../globals/dimensions';
import { getWizardNavTitle, isCurrentSection } from '../../../utils/wizard';
import Confirm from './Confirm';

import styles from './styles';
import { useFeatures } from '../../../utils/hooks';

export function WizardNav({
  classes,
  history,
  currentSection,
  navConfig,
  page,
  tile,
  open,
  submit,
  setDirty,
  dirty,
  clearTempImage,
  isStreamPage,
  isOrganizationPage,
  newWizard,
  isStreamSite,
}) {
  const [promptPath, setPromptPath] = useState(null);
  const matches = useMediaQuery('(min-width:1280px)');

  const { VancoVideo: ffVancoVideo } = useFeatures();

  function changeStep(path) {
    if (dirty && tile?.id) {
      history.push({ search: `?redirect=${path}` });
      setPromptPath(path);
    } else {
      history.push(path);
    }
  }

  const closePrompt = () => {
    history.push({ search: null });
    setPromptPath(null);
  };

  const onConfirm = () => {
    if (submit) {
      setDirty(false);
      submit();
    }
  };

  const onUndo = (path) => {
    setDirty(false);
    clearTempImage();
    history.push(path);
  };

  useEffect(() => {
    setPromptPath(null);
  }, [history.location.pathname, setPromptPath]);

  return (
    <CustomDrawer
      inline={matches}
      size={newWizard ? WIZARD_SIDEBAR_WIDTH_WIDE : SIDEBAR_WIDTH_WIDE}
      open={matches || open}
      onClose={() => {}}
      className={newWizard ? classes.container : classes.containerDeprecated}
    >
      <Typography
        variant={newWizard ? 'h5' : 'h2'}
        className={newWizard ? classes.title : classes.titleDeprecated}
      >
        {getWizardNavTitle(
          { tile },
          isStreamPage,
          isOrganizationPage,
          isStreamSite,
          ffVancoVideo,
        )}
      </Typography>
      {newWizard && (
        <Divider
          className={[
            classes.divider,
            isStreamSite ? classes.modDivider : '',
          ].join(' ')}
        />
      )}
      {navConfig.map((section) => {
        const isFinished = !!section.isFinished;
        const isNextStep = !!section.next;
        const isCurrentStep = isCurrentSection(currentSection, section);
        const path = section.path
          .replace(':tileId', tile && tile.id)
          .replace(':pageId', page && page.id);
        if (isFinished || isNextStep) {
          return (
            /* eslint-disable-next-line */
            <div
              className={classes.sectionLink}
              onClick={() => changeStep(path)}
              key={section.navTitle}
              data-testid={section.path}
            >
              {newWizard ? (
                <div
                  className={
                    isCurrentStep
                      ? classes.sectionLinkInternal
                      : classes.sectionLinkInternalEmpty
                  }
                >
                  <Section
                    page={section}
                    currentSection={currentSection}
                    isFinished={isFinished}
                    isNextStep={isNextStep}
                    newWizard={newWizard}
                  />
                </div>
              ) : (
                <Section
                  page={section}
                  currentSection={currentSection}
                  isFinished={isFinished}
                  isNextStep={isNextStep}
                  newWizard={newWizard}
                />
              )}
            </div>
          );
        }
        return (
          <section key={section.navTitle}>
            {newWizard ? (
              <div
                className={
                  isCurrentStep
                    ? classes.sectionLinkInternal
                    : classes.sectionLinkInternalEmpty
                }
              >
                <Section
                  page={section}
                  currentSection={currentSection}
                  isFinished={isFinished}
                  isNextStep={isNextStep}
                  newWizard={newWizard}
                />
              </div>
            ) : (
              <Section
                page={section}
                currentSection={currentSection}
                isFinished={isFinished}
                isNextStep={isNextStep}
                newWizard={newWizard}
              />
            )}
          </section>
        );
      })}
      <Confirm
        open={!!promptPath}
        onClose={closePrompt}
        confirm={onConfirm}
        undo={onUndo}
        path={promptPath}
      />
    </CustomDrawer>
  );
}

WizardNav.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  navConfig: types.wizardConfigType.isRequired,
  currentSection: PropTypes.object.isRequired,
  page: PropTypes.object,
  tile: PropTypes.object,
  open: PropTypes.bool.isRequired,
  submit: PropTypes.func,
  setDirty: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  clearTempImage: PropTypes.func.isRequired,
  isStreamPage: PropTypes.bool.isRequired,
  isOrganizationPage: PropTypes.bool.isRequired,
  newWizard: PropTypes.bool,
  isStreamSite: PropTypes.bool,
};

WizardNav.defaultProps = {
  page: null,
  tile: null,
  submit: null,
  newWizard: false,
  isStreamSite: false,
};

export default withStyles(styles)(WizardNav);
