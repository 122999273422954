import * as Yup from 'yup';
import i18n from '../../../utils/i18n';

export const maxLengths = {
  subject: 200,
  body: 5000,
};

export const createSchema = () => {
  return Yup.object().shape({
    subject: Yup.string().test(
      'nonEmpty',
      i18n.t('validation.required', {
        requiredNoun: i18n.t('communication.subject').toLocaleLowerCase(),
        requiredVerb: i18n.t('communication.placeholder').toLocaleLowerCase(),
      }),
      (value) => {
        return value?.trim().length > 0;
      },
    ),
    body: Yup.array().test(
      'nonEmpty',
      i18n.t('validation.required', {
        requiredNoun: i18n.t('communication.body').toLocaleLowerCase(),
        requiredVerb: i18n.t('app.addAn').toLocaleLowerCase(),
      }),
      (value) => {
        return (
          value.length > 0 &&
          value[0].insert !== '\n' &&
          value[0].insert.replace('\n', '').trim().length > 0
        );
      },
    ),
  });
};
