import BaseService from '../BaseService';

class DashboardService extends BaseService {
  // eslint-disable-next-line class-methods-use-this
  getDashboardUrl = type => {
    return `users/dashboard/looker?type=${type}`;
  };

  getDashboardData(type) {
    return this.http.get(this.getDashboardUrl(type));
  }
}

export default new DashboardService();
