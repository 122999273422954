import { object, func, bool, objectOf, string } from 'prop-types';
import { Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import FeeDonationInputs from '../../FeeDonationInputs';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';

import styles from './styles';

// TODO: Remove with ChannelManagement FF cleanup
export function VancoMobileProcessingFeesWizard({
  classes,
  values,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  handleSubmit,
  goToPrev,
  loading,
  isFinished,
  handleReset,
  dirty,
}) {
  const { t } = useTranslation();

  return (
    <Form>
      <Grid container>
        <Grid item md={9} sm={12} xs={12}>
          <Typography variant="h5" className={classes.description}>
            {t('vanco.mobile.setup.fees.question')}
          </Typography>
          <FeeDonationInputs
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          {!loading && goToPrev && (
            <WizardButtons
              isFinished={isFinished}
              dirty={dirty}
              goToNext={handleSubmit}
              goToPrev={goToPrev}
              undo={handleReset}
            />
          )}
          {loading && goToPrev && (
            <CircularProgress color="primary" className={classes.spinner} />
          )}
        </Grid>
      </Grid>
    </Form>
  );
}

VancoMobileProcessingFeesWizard.propTypes = {
  classes: objectOf(string).isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  setFieldValue: func.isRequired,
  handleBlur: func.isRequired,
  handleSubmit: func.isRequired,
  goToPrev: func.isRequired,
  loading: bool.isRequired,
  isFinished: bool.isRequired,
  handleReset: func.isRequired,
  dirty: bool.isRequired,
};

export default withStyles(styles)(VancoMobileProcessingFeesWizard);
