const common = {
  color: '#444444',
  padding: '2px 8px',
  borderRadius: 4,
  display: 'inline-flex',
  alignItems: 'center',
  '& .MuiTypography-root': {
    fontSize: '1.4rem',
  },
};

const commonStatus = {
  borderRadius: 1000,
  padding: '2px 8px',
};

export default () => ({
  primary: {
    backgroundColor: 'rgba(48, 17, 155, 0.06)',
    '& svg': {
      marginRight: 6,
      color: '#30119B',
    },
    ...common,
  },
  default: {
    backgroundColor: '#F6F6F7',
    fontWeight: 500,
    '& svg': {
      width: 16,
      height: 20,
      marginRight: 6,
      color: '#222222',
    },
    ...common,
  },
  active: {
    background: '#C9F0E0',
    ...commonStatus,
  },
  pending: {
    background: '#FBEA97',
    ...commonStatus,
  },
  archived: {
    background: '#DDDDDD',
    ...commonStatus,
  },
});
