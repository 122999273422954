export default theme => ({
  modalTitle: {
    padding: '16px 24px',
    '& h6': {
      paddingLeft: 0,
      textAlign: 'left',
    },
  },
  modalContent: {
    padding: '14px 24px 32px 24px',
  },
  title: {
    fontWeight: 700,
    color: '#222222',
  },
  description: {
    color: '#666666',
  },
  invitationCodeContaienr: {
    display: 'flex',
    padding: '16px 24px 16px 16px',
    border: '1px solid #CCCCCC',
    borderRadius: 6,
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  copyControl: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    display: 'flex',
    fontWeight: 700,
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    '& svg': {
      marginRight: 10,
    },
  },
});
