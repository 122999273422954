import { createRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { getIn } from 'formik';
import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates/lib';
import 'react-dates/lib/css/_datepicker.css';
import Clear from '@material-ui/icons/Clear';
import moment from 'moment';
import { CalendarToday } from '@material-ui/icons';
import FormHelperTextBottom from '../FormHelperTextBottom';
import { getLatestAllowedEndDate } from '../../../utils/calendar';

function DatePicker(props) {
  const {
    id,
    label,
    inputClass,
    field,
    form,
    handleDateChange,
    earliestAllowedDate,
    latestAllowedDate,
    initialVisibleMonth,
    hasError,
    position,
    allowedDates,
  } = props;

  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  const [curState, setCurState] = useState({
    dateString: field?.value ? field.value.format('MM/DD/YYYY') : '',
    datePickerCanClose: true,
    displayDatePicker: false,
  });

  useEffect(() => {
    setCurState({
      ...curState,
      dateString: field?.value ? field.value.format('MM/DD/YYYY') : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  const { dateString, displayDatePicker } = curState;

  const datePickerRef = createRef();

  const clearPicker = () => {
    setCurState({
      ...curState,
      dateString: '',
    });
    handleDateChange(field.name, undefined, true);
    form.setFieldTouched(field.name, true, false);
    datePickerRef.current.querySelector('input').focus();
  };

  const handleTextFieldDateChange = (event) => {
    const { value: dateValue } = event.target;
    const date = moment(dateValue, 'MM/DD/YYYY', true);
    const minAllowedDate = moment(earliestAllowedDate, 'MM/DD/YYYY', true);

    if (date >= minAllowedDate) {
      // only allow strictly parsed dates for QA automation, or autocomplete
      if (date.isValid()) {
        handleDateChange(field.name, date, true);
        form.setFieldTouched(field.name, true, false);
        setCurState({
          ...curState,
          dateString: date.format('MM/DD/YYYY'),
          datePickerCanClose: true,
          displayDatePicker: false,
        });
      }
    }
  };

  const handleDateChanged = (momentDate) => {
    // Remove time
    const date = moment(momentDate.format('MM/DD/YYYY'), 'MM/DD/YYYY', true);
    const currentValue = field.value
      ? moment(field.value.format('MM/DD/YYYY'), 'MM/DD/YYYY', true)
      : null;
    if (
      (date && !field.value) ||
      (field.value && currentValue.diff(date, 'days') !== 0)
    ) {
      handleDateChange(field.name, date, true);
      form.setFieldTouched(field.name, true, false);
    }
    setCurState({
      ...curState,
      dateString: date.format('MM/DD/YYYY'),
      datePickerCanClose: true,
      displayDatePicker: false,
    });
  };

  const handleIconClick = () => {
    // find input and focus it - will trigger the calendar to appear
    setCurState({ ...curState, displayDatePicker: true });
  };

  const handleDateFocusChange = (focused) => () => {
    const { datePickerCanClose } = curState;
    if (datePickerCanClose) {
      setCurState({ ...curState, displayDatePicker: focused });
    }
  };

  return (
    <>
      <TextField
        id={id}
        label={label}
        placeholder="Select Date"
        variant="outlined"
        disabled={isSubmitting}
        error={(hasError || showError) && !displayDatePicker}
        className={inputClass}
        value={dateString}
        onChange={handleTextFieldDateChange}
        inputProps={{ inputMode: 'none' }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              onClick={handleIconClick}
              data-testid="open-button"
            >
              <IconButton>
                <CalendarToday style={{ color: '#5E6266' }} />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: field.value ? (
            <InputAdornment position="end">
              <IconButton onClick={clearPicker} data-testid="clear-button">
                <Clear />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
        onFocus={handleDateFocusChange(true)}
        onBlur={handleDateFocusChange(false)}
        autoComplete="off"
        ref={datePickerRef}
      />
      {(hasError || showError) && (
        <FormHelperTextBottom data-testid={`${name}`} left={errors[name]} />
      )}
      {displayDatePicker && !isSubmitting && (
        <div
          style={{
            position,
            zIndex: 9999,
          }}
          onMouseEnter={() =>
            setCurState({ ...curState, datePickerCanClose: false })
          }
          onMouseLeave={() =>
            setCurState({ ...curState, datePickerCanClose: true })
          }
        >
          <DayPickerSingleDateController
            keepOpenOnDateSelect
            date={field.value}
            onDateChange={handleDateChanged}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            isOutsideRange={(day) => {
              const beforeAndAfter =
                day.isBefore(earliestAllowedDate) ||
                day.isAfter(latestAllowedDate);
              if (allowedDates && allowedDates.length > 0) {
                return beforeAndAfter || !allowedDates.includes(day.date());
              }
              return beforeAndAfter;
            }}
            focused
            initialVisibleMonth={initialVisibleMonth}
          />
        </div>
      )}
    </>
  );
}

DatePicker.propTypes = {
  value: PropTypes.objectOf(moment),
  label: PropTypes.string,
  inputClass: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  earliestAllowedDate: PropTypes.objectOf(moment),
  latestAllowedDate: PropTypes.objectOf(moment),
  id: PropTypes.string,
  initialVisibleMonth: PropTypes.func,
  handleDateChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  position: PropTypes.string,
  allowedDates: PropTypes.arrayOf(PropTypes.number),
};

DatePicker.defaultProps = {
  value: null,
  label: '',
  inputClass: '',
  id: '',
  initialVisibleMonth: null,
  earliestAllowedDate: moment().startOf('day'),
  latestAllowedDate: getLatestAllowedEndDate(moment()),
  hasError: false,
  position: 'absolute',
  allowedDates: null,
};

export default DatePicker;
