import { formInputText } from '../../../globals/typography';

export default function styles(theme) {
  return {
    name: {
      fontWeight: 'bold',
      fontSize: '2rem',
      padding: '47px 32px 15px 32px',
      borderBottom: '1px solid #EBEBEB',
    },
    closeDesktop: {
      display: 'flex',
      marginTop: 40,
      marginBottom: 0,
      marginLeft: 20,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    closeText: {
      position: 'relative',
      display: 'inline-block',
      color: theme.palette.secondary.light,
      marginLeft: 3,
      bottom: 1,
      ...formInputText,
    },
    closeIcon: {
      width: 20,
      height: 20,
      color: theme.palette.secondary.light,
    },
    accountList: {
      padding: 0,
      height: '100vh',
      marginTop: -10,
      overflowY: 'auto',
    },
    listItem: {
      borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    },
    accountSelector: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    accountName: {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: 180,
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
    },
    menu: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    upperContent: {
      justifyContent: 'normal',
    },
    drawerFooter: {
      color: '#717171',
      fontSize: 10,
      lineHeight: '22px',
      padding: '14px 47px',
      '& a': {
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 'normal',
        margin: '0 5px',
      },
      '& a:first-child': {
        marginLeft: 0,
      },
    },
    copyright: {
      display: 'block',
      fontSize: 14,
    },
    overflow: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  };
}
