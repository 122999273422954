import { connect } from 'react-redux';
import PrivateRouteWithPage from './PrivateRouteWithPage';

export function mapStateToProps(state) {
  return {
    isLoggedIn: state.user.isLoggedIn,
    loadingAuth: state.user.loadingAuth,
    grantedActions: state.user.user.grantedActions,
    pages: state.pages.pages,
    loadingPages: state.pages.loadingPages,
    hasLoadedPages: state.pages.hasLoadedPages,
  };
}

export default connect(
  mapStateToProps,
  {},
)(PrivateRouteWithPage);
