import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { PAGE_ACTIVE } from '../../../../globals/constants';

export function PageRedirect({
  history,
  pages,
  resetNewPage,
  loadingPages,
  hasLoadedPages,
}) {
  useEffect(() => {
    const hasAnActivePage = pages.some((p) => p.status === PAGE_ACTIVE);
    resetNewPage();

    if (!hasLoadedPages || loadingPages) {
      return;
    }
    if (hasAnActivePage) {
      history.replace('/tiles');
    } else {
      history.replace('/getStarted');
    }
  }, [history, pages, resetNewPage, loadingPages, hasLoadedPages]);

  return null;
}

PageRedirect.propTypes = {
  resetNewPage: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadingPages: PropTypes.bool.isRequired,
  hasLoadedPages: PropTypes.bool.isRequired,
};

export default PageRedirect;
