import { objectOf, string } from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import UnarchiveIcon from '@material-ui/icons/Replay';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import EmailIcon from '@material-ui/icons/MailOutline';
import BlockIcon from '@material-ui/icons/BlockOutlined';
import { Divider, Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Badge from '../Badge';
import { ReactComponent as AddModeratorIcon } from '../../../assets/blockIcon.svg';
import { ReactComponent as RemoveModeratorIcon } from '../../../assets/unblockIcon.svg';
import { ReactComponent as ModeratorIcon } from '../../../assets/moderatorIcon.svg';
import formatPhoneNumber from '../../../utils/text/formatPhoneNumber';
import {
  useGetMobilePageUser,
  useCustomState,
  useUpdateMobileUser,
  useGetPage,
} from '../../../utils/hooks';
import Page from '../Page';
import PageTitle from '../PageTitle';
import PagePaper from '../PagePaper';
import { getOrganizationInitials } from '../../../utils/page';
import {
  MOBILE_USER_STATUS,
  MOBILE_USER_ROLE,
  CHANNEL_TYPE,
  PAGE_ACTIVE,
} from '../../../globals/constants';
import DisclosureMessage from '../DisclosureMessage';
import UpdateMobileUserModal from './UpdateMobileUserModal';

import styles from './styles';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

const initialState = {
  updateUserModalOpen: false,
  updateOption: '',
};
export function VancoMobileUsers({ classes }) {
  const { t } = useTranslation();
  usePageTitle(t('vanco.mobile.home.users.viewUsers'));
  const { id } = useParams();
  const { page } = useGetPage(CHANNEL_TYPE.MOBILE, PAGE_ACTIVE);
  const { user = {}, status } = useGetMobilePageUser(id);
  const { mutate: updateUser, isLoading: isUpdating } = useUpdateMobileUser();
  const isLoaded = status === 'success';
  const history = useHistory();
  const { location } = history;
  const onGoBack = () =>
    history.push(location?.state?.prevPath || '/mobile/users');

  const [state, setState, resetState] = useCustomState(initialState);

  const renderProfilePicture = () =>
    user.avatar ? (
      <img className={classes.profileImage} src={user.avatar} alt="profile" />
    ) : (
      <div className={`${classes.profileImage} ${classes.imagePlaceholder}`}>
        <Typography className={classes.initials}>
          {getOrganizationInitials(user.name)}
        </Typography>
      </div>
    );

  const handleButtonClick = (value) => {
    setState({
      updateOption: value,
      updateUserModalOpen: true,
    });
  };

  const closeModal = () => {
    setState({
      updateUserModalOpen: false,
    });
  };

  const onConfirmUpdate = async () => {
    const mobileUser = { ...user };
    // eslint-disable-next-line default-case
    switch (state.updateOption) {
      case 'suspendUser':
        mobileUser.status = MOBILE_USER_STATUS.SUSPENDED;
        mobileUser.role = MOBILE_USER_ROLE.MEMBER;
        break;
      case 'unsuspendUser':
        mobileUser.status = MOBILE_USER_STATUS.ACTIVE;
        break;
      case 'removeModerator':
        mobileUser.role = MOBILE_USER_ROLE.MEMBER;
        break;
      case 'makeModerator':
        mobileUser.role = MOBILE_USER_ROLE.MODERATOR;
        break;
    }
    mobileUser.updateAction = state.updateOption;
    await updateUser({ user: mobileUser });
    resetState();
  };

  return (
    <Page variant="cardContainer">
      <PageTitle
        onGoBack={onGoBack}
        isLoading={!isLoaded}
        readOnly={!page.hasCommunityFeatures}
      >
        {user.name}
      </PageTitle>
      <PagePaper className={classes.pagePaper}>
        <div className={classes.title}>
          <Typography className={classes.detailsLabel} variant="h4">
            {t('word.details')}
          </Typography>
          {user.role === MOBILE_USER_ROLE.MODERATOR && (
            <Typography className={classes.badge}>
              <Badge variant="default">
                <ModeratorIcon />
                {user.role}
              </Badge>
            </Typography>
          )}
        </div>
        <Divider />
        <div style={{ display: 'flex', marginTop: 16 }}>
          {isLoaded ? (
            renderProfilePicture()
          ) : (
            <Skeleton variant="rect" className={classes.profileImage} />
          )}
          <Grid container alignContent="flex-start" style={{ gap: 12 }}>
            {isLoaded && (
              <>
                <Grid container item>
                  <Typography variant="h5">
                    {/* the trim is to prevent a name just with empty spaces */}
                    {(user.name || '').trim() || user.email}
                  </Typography>
                </Grid>
                {user.email && (
                  <Grid container item>
                    <EmailIcon className={classes.icon} />
                    <Typography>{user.email}</Typography>
                  </Grid>
                )}
                {user.phoneNumber && (
                  <Grid container item>
                    <PhoneIcon className={classes.icon} />
                    <Typography>
                      {formatPhoneNumber(user.phoneNumber)}
                    </Typography>
                  </Grid>
                )}
              </>
            )}
            {!isLoaded &&
              [180, 280, 150].map((width, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid container item key={index}>
                  <Skeleton
                    variant="text"
                    height={28}
                    width={width}
                    length={50}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
        <Divider className={classes.divider} />
        {user?.status === MOBILE_USER_STATUS.SUSPENDED ? (
          <DisclosureMessage
            className={classes.disclosure}
            title={t('vanco.mobile.appUsers.suspended.disclosureTitle')}
            message={t('vanco.mobile.appUsers.suspended.disclosureMessage')}
          >
            <Button
              size="medium"
              className={`${classes.unsuspendButton}`}
              variant="outlined"
              onClick={() => handleButtonClick('unsuspendUser')}
            >
              <UnarchiveIcon className={classes.unsuspendButtonIcon} />
              {t('vanco.mobile.appUsers.unsuspend')}
            </Button>
          </DisclosureMessage>
        ) : (
          <div className={classes.actionButtons}>
            {!isLoaded ? (
              <div className={classes.inline}>
                {[100, 150].map((width) => (
                  <Skeleton
                    variant="text"
                    height={28}
                    width={width}
                    className={classes.button}
                  />
                ))}
              </div>
            ) : (
              <>
                <Button
                  size="medium"
                  className={classes.button}
                  onClick={() => handleButtonClick('suspendUser')}
                >
                  <BlockIcon className={classes.blockIcon} />
                  {t('vanco.mobile.appUsers.suspend')}
                </Button>
                <Button
                  size="medium"
                  className={classes.button}
                  onClick={
                    () =>
                      handleButtonClick(
                        user?.role === MOBILE_USER_ROLE.MODERATOR
                          ? 'removeModerator'
                          : 'makeModerator',
                      )
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  {user?.role === MOBILE_USER_ROLE.MODERATOR ? (
                    <>
                      <RemoveModeratorIcon className={classes.moderatorIcon} />
                      {t('vanco.mobile.appUsers.removeModerator')}
                    </>
                  ) : (
                    <>
                      <AddModeratorIcon className={classes.moderatorIcon} />
                      {t('vanco.mobile.appUsers.makeModerator')}
                    </>
                  )}
                </Button>
              </>
            )}
          </div>
        )}
      </PagePaper>
      <UpdateMobileUserModal
        onClose={closeModal}
        open={state.updateUserModalOpen || isUpdating}
        onConfirm={onConfirmUpdate}
        disabled={isUpdating}
        type={state.updateOption || 'suspendUser'}
        userName={user?.name || ''}
      />
    </Page>
  );
}

VancoMobileUsers.propTypes = {
  classes: objectOf(string).isRequired,
};

export default withStyles(styles)(VancoMobileUsers);
