const centered = {
  width: '100%',
  borderRadius: 6,
  minHeight: 40,
};

export default function styles(theme) {
  return {
    container: {
      display: 'inline',
      position: 'relative',
    },
    paper: {
      minWidth: 216,
    },
    button: {
      color: '#666666',
      padding: 8,
      '& :hover': {
        backgroundColor: '#EEEEEE',
      },
    },
    buttonSelected: {
      color: '#666666',
      padding: 8,
      background:
        'linear-gradient(0deg, rgba(34, 34, 34, 0.1), rgba(34, 34, 34, 0.1)), #EEEEEE',
    },
    menu: {
      padding: '10px 0',
    },
    item: {
      minHeight: 36,
      padding: '4px 16px',
      '& svg': {
        width: 20,
        height: 20,
        marginRight: 16,
      },
    },
    default: {
      color: theme.palette.secondary.main,
      '& svg': {
        fill: theme.palette.icon.medium,
        color: theme.palette.icon.medium,
      },
      ...centered,
    },
    danger: {
      fill: theme.palette.error.main,
      color: theme.palette.error.main,
      ...centered,
    },
    divider: {
      margin: '7px 0',
      backgroundColor: '#DADCE0',
    },
    iconDivider: {
      margin: '7px 0 7px 62px',
      backgroundColor: '#DADCE0',
    },
  };
}
