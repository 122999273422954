import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { withStyles } from '@material-ui/core/styles';
import useLogRocket from 'utils/hooks/useLogRocket';
import InsightsPaywall from 'components/pages/Giving/Dashboard/InsightsPaywall';
import usePaidFeatures from 'utils/hooks/usePaidFeatures';
import MainLayout from '../MainLayout';
import WizardLayout from '../WizardLayout';
import PrivateRoute from '../PrivateRoute';
import PrivateRouteWithPage from '../PrivateRouteWithPage';
import PrivateRouteWithPages from '../PrivateRouteWithPages';
import PrivateRouteWithStreamer from '../PrivateRouteWithStreamer';
import AppSnackbar from '../AppSnackbar';
import GetStarted from '../../pages/GetStarted';
import Donations from '../../pages/Donations';
import PageRedirect from '../../pages/PageWizard/Redirect';
import PageWelcome from '../../pages/PageWizard/Welcome';
import PageReview from '../../pages/PageWizard/Review';
// import PageComplete from '../../pages/PageComplete';
import HomeRedirect from '../../pages/HomeRedirect';
import AppProgressBar from '../AppProgressBar';
import Navbar from '../Navbar';
import ProfileNav from '../ProfileNav';
import {
  TileTitle,
  TileDescription,
  TilePayment,
  TileImageSelect,
  TileGift,
  TileOverview,
  TileReview,
  ColorOptions,
  FeeDonationOptions,
  SocialSharing,
  // TileComplete,
  // TileCreate,
  TileMemo,
  HeaderManagement,
  IdleHandler,
  NotFound,
  LoadingDialog,
  VancoMobileBasicInfoWizard,
  VancoMobileProcessingFeesWizard,
  VancoMobileAppBrandingWizard,
  VancoMobileAppPermissionsWizard,
  VancoMobileReviewWizard,
  StreamPageInfo,
  StreamPageBranding,
  StreamPageGiving,
  StreamPageChat,
  StreamPageReviewWizard,
  OrganizationContact,
  OrganizationBranding,
  OrganizationFees,
  OrganizationReview,
  SiteSettings,
  StreamSite,
  MultiverseIframeContent,
  NotAllowed,
} from '../../common';
import {
  VancoMobileUserPage,
  VancoMobileUsersPage,
  VancoMobileGroupsPage,
  VancoMobileOrderTilesPage,
  VancoMobileEditProcessingFeesPage,
  VancoMobileOrganizationDetailsPage,
  VancoMobileEditPermissionsPage,
  VancoMobileSettingsPage,
  GetStartedVancoMobilePage,
  VancoMobileAppBrandingPage,
  VancoMobileNotFoundPage,
  VancoMobileGroupPage,
  VancoMobileEventsPage,
  VancoMobileEventPage,
  VancoMobileEditDonorSettingsPage,
} from './MobileRoutes';
import { VancoStreamGetStarted, VancoStreamNotFoundPage } from './StreamRoutes';
import {
  CHANNEL_TYPE,
  PAGE_ACTIVE,
  PAGE_PENDING,
  PAGE_ARCHIVED,
  VIDEO_ROUTES,
  REPORTING_ROUTES,
  PAYWALL_MAP,
} from '../../../globals/constants';
import { USER_VIEW } from '../../../globals/grants';
import {
  TileBuilder,
  TilesList,
  PageBuilder,
  SitesList,
  Login,
  Profile,
  Logout,
  Users,
  InternalLogin,
  StreamList,
  OrganizationSettings,
  MultipleStreamList,
  CommunicationSettings,
  CommunicationEmailTemplate,
  NewTransaction,
  VideoOnDemand,
  Clips,
  FeatureManagement,
} from '../../pages';
import styles from './styles';
import history from '../../../history';
import OrganizationEditor from '../../common/Organization/Editor';
import StreamSiteLayout from '../StreamSiteLayout';
import ImportMembers from '../../pages/ImportMembers';
import ImportedFilesView from '../../pages/ImportedFilesView';
import ImportedFileDetail from '../../pages/ImportedFileDetail';
import RequestKiosk from '../../pages/Kiosk/Request';
import SetupKiosk from '../../pages/Kiosk/Setup';
import VideoSettings from '../../pages/VideoSettings';
import GivingDashboard from '../../pages/Giving/Dashboard';
import Reporting from '../../pages/Reports';
import SigmaReport from '../../pages/Reports/SigmaReport/SigmaReport';
import PrivateRouteWithVancoVideo from '../PrivateRouteWithVancoVideo';
import PaywallDashboard from '../../pages/PaywallDashboard';
import Paywall from '../../pages/Paywall';
import PermissionsRouter from './PermissionsRouter';

export function Router({
  classes,
  auth,
  isLoggedIn,
  getPages,
  loadingPages,
  hasLoadedPages,
  isInternal,
  wasInternal,
  loadingLogin,
  internalLoadingLogin,
  features,
  promptToCompleteOrganizationSettings,
  mobileEnabled,
  hasKiosk,
  getKiosk,
  orgUri,
}) {
  const showPaidFeatures = usePaidFeatures();

  const canAccessFeaturemangement =
    wasInternal && features.AdminUserPermissions;

  // setup authentication cookies
  useEffect(() => {
    auth();
  }, [auth]);

  useLogRocket();

  // mv-297 capture Id for FF/desk navigation
  const [id, setId] = useState(null);

  // redirect if org settings incomplete
  useEffect(() => {
    if (promptToCompleteOrganizationSettings) {
      history.replace('/organization/wizard/continue');
    }
  }, [promptToCompleteOrganizationSettings]);

  // fetch the current page / location if needed
  useEffect(() => {
    const shouldFetchPages =
      isLoggedIn &&
      !isInternal &&
      !loadingLogin &&
      !internalLoadingLogin &&
      !loadingPages &&
      !hasLoadedPages;
    if (shouldFetchPages) {
      getPages();
    }
  }, [
    getPages,
    isLoggedIn,
    isInternal,
    loadingLogin,
    internalLoadingLogin,
    loadingPages,
    hasLoadedPages,
  ]);

  useEffect(() => {
    if (features.KioskSetup && isLoggedIn && !hasKiosk && orgUri) {
      getKiosk();
    }
  }, [getKiosk, hasKiosk, isLoggedIn, features, orgUri]);

  let prevLocation;
  history.listen((location) => {
    // extract Id for FF/desk navigation
    const idMatch = location.pathname.match(/id=([^&]*)/);
    const currId = idMatch ? idMatch[1] : null;
    setId(currId);

    if (location !== prevLocation) {
      prevLocation = location;
      window.Appcues.page();
    }
  });

  const renderMobileRoutes = () => (
    <PrivateRoute
      path="/mobile"
      component={() => (
        <Switch>
          <PrivateRouteWithPage
            exact
            path="/mobile/home"
            key="/mobile/home"
            channelType={CHANNEL_TYPE.MOBILE}
            fallbackPath="/mobile/get-started"
            component={VancoMobileSettingsPage}
          />
          <PrivateRoute
            exact
            key="/mobile/get-started"
            path="/mobile/get-started"
            component={GetStartedVancoMobilePage}
          />
          <PrivateRoute
            exact
            key="/mobile/edit-processing-fees"
            path="/mobile/edit-processing-fees"
            component={VancoMobileEditProcessingFeesPage}
          />
          <PrivateRoute
            exact
            path="/mobile/details"
            key="/mobile/details"
            component={VancoMobileOrganizationDetailsPage}
          />
          <PrivateRoute
            exact
            path="/mobile/branding"
            key="/mobile/branding"
            component={VancoMobileAppBrandingPage}
          />
          <PrivateRoute
            exact
            key="/mobile/edit-permissions"
            path="/mobile/edit-permissions"
            component={VancoMobileEditPermissionsPage}
          />
          <PrivateRoute
            exact
            path="/mobile/tiles-order"
            key="/mobile/tiles-order"
            component={VancoMobileOrderTilesPage}
          />
          <PrivateRoute
            exact
            path="/mobile/users/:userRole?"
            key="/mobile/users"
            component={VancoMobileUsersPage}
          />
          <PrivateRoute
            exact
            path="/mobile/user/:id"
            key="/mobile/user"
            component={VancoMobileUserPage}
          />
          <PrivateRoute
            exact
            path="/mobile/groups"
            key="/mobile/groups"
            component={VancoMobileGroupsPage}
          />
          <PrivateRoute
            exact
            path="/mobile/event/:id"
            key="/mobile/event"
            component={VancoMobileEventPage}
          />
          <PrivateRoute
            exact
            path="/mobile/events/:filter?"
            key="/mobile/events"
            component={VancoMobileEventsPage}
          />
          <PrivateRoute
            exact
            path="/mobile/groups/:id"
            key="/mobile/group"
            component={VancoMobileGroupPage}
          />
          <PrivateRoute
            exact
            key="/mobile/donor-settings"
            path="/mobile/donor-settings"
            component={VancoMobileEditDonorSettingsPage}
          />
          <Route key="mobile-not-found" component={VancoMobileNotFoundPage} />
        </Switch>
      )}
    />
  );
  const renderWizardRoutesWithPage = () => {
    return [
      <PrivateRoute
        key="/wizard/page/review"
        path="/wizard/page/:pageId/review"
        exact
        component={PageReview}
      />,
      <PrivateRoute
        key="/wizard/page-info"
        path="/wizard/page-info"
        exact
        component={features.ChannelManagement ? SiteSettings : HeaderManagement}
      />,
      <PrivateRoute
        key="/wizard/pageId/page-info"
        path="/wizard/:pageId/page-info"
        exact
        component={features.ChannelManagement ? SiteSettings : HeaderManagement}
      />,
      <PrivateRoute
        key="/wizard/color-options"
        path="/wizard/:pageId/color-options"
        exact
        component={ColorOptions}
      />,
      <PrivateRoute
        key="/wizard/donation-options"
        path="/wizard/:pageId/donation-options"
        exact
        component={FeeDonationOptions}
      />,
      <PrivateRoute
        key="/wizard/social-sharing"
        path="/wizard/:pageId/social-sharing"
        exact
        component={SocialSharing}
      />,
      // <PrivateRoute
      //   key="/wizard/page-complete"
      //   path="/wizard/page-complete"
      //   exact
      //   component={PageComplete}
      // />,
      // <PrivateRouteWithPages
      //   key="/wizard/tile"
      //   path="/wizard/tile"
      //   exact
      //   component={TileCreate}
      // />,
      <PrivateRouteWithPages
        key="/wizard/tile/:tileId/title"
        path="/wizard/tile/:tileId/title"
        exact
        component={TileTitle}
      />,
      <PrivateRouteWithPages
        key="/wizard/tile/:tileId/description"
        path="/wizard/tile/:tileId/description"
        exact
        component={TileDescription}
      />,
      <PrivateRouteWithPages
        key="/wizard/tile/:tileId/payment"
        path="/wizard/tile/:tileId/payment"
        exact
        component={TilePayment}
      />,
      <PrivateRouteWithPages
        key="/wizard/tile/:tileId/image"
        path="/wizard/tile/:tileId/image"
        exact
        component={TileImageSelect}
      />,
      <PrivateRouteWithPages
        key="/wizard/tile/:tileId/gift"
        path="/wizard/tile/:tileId/gift"
        exact
        component={TileGift}
      />,
      <PrivateRouteWithPages
        key="/wizard/tile/:tileId/memo"
        path="/wizard/tile/:tileId/memo"
        exact
        component={TileMemo}
      />,
      <PrivateRouteWithPages
        key="/wizard/tile/:tileId/preview"
        path="/wizard/tile/:tileId/preview"
        exact
        component={TileOverview}
      />,
      <PrivateRouteWithPages
        key="/wizard/tile/:tileId/review"
        path="/wizard/tile/:tileId/review"
        exact
        component={TileReview}
      />,
      // <PrivateRouteWithPages
      //   key="/wizard/tile/complete"
      //   path="/wizard/tile/complete"
      //   exact
      //   component={TileComplete}
      // />,
      <PrivateRoute
        key="/wizard/mobile/app-info"
        path="/wizard/mobile/:pageId/app-info"
        exact
        component={VancoMobileBasicInfoWizard}
      />,
      <PrivateRoute
        key="/wizard/mobile/branding"
        path="/wizard/mobile/:pageId/branding"
        exact
        component={VancoMobileAppBrandingWizard}
      />,
      <PrivateRoute
        key="/wizard/mobile/processing-fees"
        path="/wizard/mobile/:pageId/processing-fees"
        exact
        component={VancoMobileProcessingFeesWizard}
      />,
      <PrivateRoute
        key="/wizard/mobile/permissions"
        path="/wizard/mobile/:pageId/permissions"
        exact
        component={VancoMobileAppPermissionsWizard}
      />,
      <PrivateRoute
        key="/wizard/mobile/review"
        path="/wizard/mobile/:pageId/review"
        exact
        component={VancoMobileReviewWizard}
      />,
      <Route key="notFound" component={NotFound} />,
    ];
  };

  const renderKioskRoutes = () => {
    if (!features.KioskSetup) return null;
    return [
      <PrivateRoute
        key="/kiosk"
        path="/kiosk"
        exact
        component={() =>
          !wasInternal && !hasKiosk ? <RequestKiosk /> : <SetupKiosk />
        }
      />,
    ];
  };

  const renderImportRoutes = () => {
    if (!wasInternal || !features.ImportExternalPayerRecurrence) return null;
    return [
      <PrivateRoute
        key="/new-import"
        path="/new-import"
        exact
        component={() => <ImportMembers />}
      />,
      <PrivateRoute
        key="/import"
        path="/import"
        exact
        component={() => <ImportedFilesView />}
      />,
      <PrivateRoute
        key="/import/:importId"
        path="/import/:importId"
        exact
        component={() => <ImportedFileDetail />}
      />,
    ];
  };

  const renderStreamRoutes = () => (
    <PrivateRoute
      path="/stream"
      component={() => (
        <Switch>
          <PrivateRoute
            exact
            key="/stream/get-started"
            path="/stream/get-started"
            component={VancoStreamGetStarted}
          />
          <Route key="stream-not-found" component={VancoStreamNotFoundPage} />
        </Switch>
      )}
    />
  );

  const renderStreamSiteRoutes = () => (
    <PrivateRoute
      path="/stream"
      component={() => (
        <Switch>
          <PrivateRoute
            exact
            key="/stream/get-started"
            path="/stream/get-started"
            component={VancoStreamGetStarted}
          />
          <PrivateRoute
            path="/stream/settings"
            key="/stream/settings"
            component={(props) => (
              <StreamSiteLayout {...props}>
                <StreamSite />
              </StreamSiteLayout>
            )}
          />
          <PrivateRoute
            path="/stream/:streamerId/streams"
            key="/stream/:streamerId/streams"
            component={(props) => <MultipleStreamList {...props} />}
          />
          <Route key="stream-not-found" component={VancoStreamNotFoundPage} />
        </Switch>
      )}
    />
  );

  const renderMainRoutes = () => {
    return (
      <Switch>
        <Route path="/login" key="/login" exact component={Login} />
        {mobileEnabled ? (
          renderMobileRoutes()
        ) : (
          <PrivateRoute path="/mobile" component={HomeRedirect} />
        )}
        {renderKioskRoutes()}
        {renderImportRoutes()}
        {features.MultipleStreams
          ? renderStreamSiteRoutes()
          : renderStreamRoutes()}
        {showPaidFeatures && (
          <PrivateRoute
            key="/paidfeatures"
            path="/paidfeatures"
            component={PaywallDashboard}
          />
        )}
        {canAccessFeaturemangement && (
          <PrivateRoute
            key="/feature-management"
            path="/feature-management"
            component={FeatureManagement}
          />
        )}
        <PrivateRouteWithPages
          path="/new-transaction/"
          key="/new-transaction"
          component={NewTransaction}
        />
        <PrivateRouteWithPages
          path="/tiles/:status?"
          key="/tiles"
          component={TilesList}
        />
        <PrivateRoute
          path="/donations"
          key="/donations"
          component={Donations}
        />
        <PrivateRouteWithPages
          path="/tile/:tileId"
          key="/tile/:tileId"
          component={TileBuilder}
        />
        <PrivateRouteWithPage
          path="/:pageId/page-settings"
          key="/page-settings"
          channelType={CHANNEL_TYPE.ONLINE}
          fallbackPath="/sites"
          component={PageBuilder}
        />
        <PrivateRouteWithPage
          path="/streams/:pageId/stream-settings"
          key="/stream-settings"
          channelType={CHANNEL_TYPE.STREAM}
          fallbackPath="/streams"
          component={PageBuilder}
        />
        <PrivateRouteWithPage
          path="/sites"
          key="/sites"
          channelType={CHANNEL_TYPE.ONLINE}
          pageStatus={[PAGE_PENDING, PAGE_ACTIVE, PAGE_ARCHIVED]}
          fallbackPath="/getStarted"
          component={SitesList}
        />
        {features.MultipleStreams ? (
          <PrivateRouteWithStreamer
            path="/streams"
            key="/streams"
            fallbackPath="/stream/get-started"
            component={MultipleStreamList}
          />
        ) : (
          <PrivateRouteWithPage
            path="/streams"
            key="/streams"
            channelType={CHANNEL_TYPE.STREAM}
            pageStatus={[PAGE_ACTIVE, PAGE_ARCHIVED]}
            fallbackPath="/stream/get-started"
            component={StreamList}
          />
        )}
        <PrivateRouteWithVancoVideo
          key={VIDEO_ROUTES.smartLibrary}
          path={VIDEO_ROUTES.smartLibrary}
          component={VideoOnDemand}
        />
        <PrivateRouteWithVancoVideo
          key={VIDEO_ROUTES.smartLibraryPaywall}
          path={VIDEO_ROUTES.smartLibraryPaywall}
          component={Paywall}
        />
        <PrivateRouteWithVancoVideo
          path={VIDEO_ROUTES.smartClips}
          key={VIDEO_ROUTES.smartClips}
          component={Clips}
        />
        <PrivateRoute
          path={VIDEO_ROUTES.smartClipsPaywall}
          key={VIDEO_ROUTES.smartClipsPaywall}
          component={Paywall}
        />
        <PrivateRouteWithVancoVideo
          path={`${VIDEO_ROUTES.videoSettings}/:activeTab?`}
          key={VIDEO_ROUTES.videoSettings}
          component={VideoSettings}
        />
        {
          <PrivateRoute
            path={['/content/desk', '/content/desk:id']}
            key="/content/desk"
            exact
            component={() => (
              // ?iframe=true, instructs target site to apply custom styling
              <MultiverseIframeContent
                url={`${
                  window.env.REACT_APP_FAITHFORWARD_ORIGIN
                }/app/desk?iframe=true${
                  id ? `&id=${id}` : ''
                }&mobile=${mobileEnabled}`}
              />
            )}
          />
        }
        <PrivateRoute
          exact
          key="/content/cms"
          path="/content/cms"
          component={() => (
            <MultiverseIframeContent
              url={`${window.env.REACT_APP_FAITHFORWARD_ORIGIN}/app/cms?iframe=true`}
            />
          )}
        />
        <PrivateRoute
          exact
          key="/content/cms/published"
          path="/content/cms/published"
          component={() => (
            <MultiverseIframeContent
              url={`${window.env.REACT_APP_FAITHFORWARD_ORIGIN}/app/cms/published?iframe=true`}
            />
          )}
        />
        <PrivateRoute
          key="/content/analytics"
          path="/content/analytics"
          exact
          component={() => (
            <MultiverseIframeContent
              url={`${window.env.REACT_APP_FAITHFORWARD_ORIGIN}/app/analytics?iframe=true`}
            />
          )}
        />
        <PrivateRoute
          key="/content/settings"
          path="/content/settings"
          exact
          component={() => (
            <MultiverseIframeContent
              url={`${window.env.REACT_APP_FAITHFORWARD_ORIGIN}/app/settings?iframe=true`}
            />
          )}
        />
        <PrivateRoute
          path="/profile"
          key="/profile"
          exact
          component={Profile}
        />
        <PrivateRoute
          path="/users"
          key="/users"
          exact
          requiredGrants={[USER_VIEW]}
          component={Users}
        />
        <PrivateRoute
          key="/getStarted"
          path="/getStarted"
          exact
          component={GetStarted}
        />
        <PrivateRoute
          key="/organization"
          path="/organization"
          exact
          component={OrganizationSettings}
        />
        <PrivateRoute
          key="/organization/contact"
          path="/organization/contact"
          exact
          component={() => <OrganizationEditor variant="contact" />}
        />
        <PrivateRoute
          key="/organization/branding"
          path="/organization/branding"
          exact
          component={() => <OrganizationEditor variant="branding" />}
        />
        <PrivateRoute
          key="/organization/fees"
          path="/organization/fees"
          exact
          component={() => <OrganizationEditor variant="fees" />}
        />
        <PrivateRoute
          key="/communications"
          path="/communications"
          exact
          component={CommunicationSettings}
        />
        <PrivateRoute
          key="/communications/receipt/:template"
          path="/communications/receipt/:template"
          exact
          component={CommunicationEmailTemplate}
        />
        <PrivateRoute
          exact
          paywallKey={PAYWALL_MAP.INSIGHTS}
          path={[REPORTING_ROUTES.givingDashboard]}
          key={REPORTING_ROUTES.givingDashboard}
          component={GivingDashboard}
        />
        <PrivateRoute
          exact
          path={[REPORTING_ROUTES.insightsPaywall]}
          key={REPORTING_ROUTES.insightsPaywall}
          component={InsightsPaywall}
        />
        <PrivateRoute
          exact
          path={['/reporting']}
          key="/reporting"
          component={Reporting}
        />
        ,
        <PrivateRoute
          exact
          path={['/reporting/detail']}
          key="/reporting/detail"
          component={SigmaReport}
        />
        <PrivateRoute
          key="/donors"
          path="/donors"
          exact
          component={() => (
            <MultiverseIframeContent
              url={`${window.env.REACT_APP_PEOPLE_ORIGIN}/members`}
            />
          )}
        />
        <PrivateRoute
          key="/donors-settings"
          path="/donors-settings"
          exact
          component={() => (
            <MultiverseIframeContent
              url={`${window.env.REACT_APP_PEOPLE_ORIGIN}/general-settings`}
            />
          )}
        />
        <PrivateRoute
          key="/emails"
          path="/emails"
          exact
          component={() => (
            <MultiverseIframeContent
              url={`${window.env.REACT_APP_PEOPLE_ORIGIN}/comunications-center`}
            />
          )}
        />
        <PrivateRoute
          key="/emails-paywall"
          path="/emails-paywall"
          component={Paywall}
        />
        <PrivateRoute
          key="/sms"
          path="/sms"
          exact
          component={() => (
            <MultiverseIframeContent
              url={`${window.env.REACT_APP_PEOPLE_ORIGIN}/comunications-center-sms`}
            />
          )}
        />
        <PrivateRoute
          key="/templates"
          path="/templates"
          exact
          component={() => (
            <MultiverseIframeContent
              url={`${window.env.REACT_APP_PEOPLE_ORIGIN}/comunications-center/templates`}
            />
          )}
        />
        <PrivateRoute
          key="/templates-paywall"
          path="/templates-paywall"
          component={Paywall}
        />
        <PrivateRoute path="/logout" key="/logout" exact component={Logout} />
        <Route
          key="notAllowed"
          path="/not-allowed"
          exact
          component={NotAllowed}
        />
        <Route key="notFound" component={NotFound} />
      </Switch>
    );
  };

  const renderWizardRoutesWithoutPage = () => {
    return [
      <PrivateRoute
        key="/wizard/page/redirect"
        path="/wizard/page/redirect"
        exact
        component={PageRedirect}
      />,
      <PrivateRoute
        key="/wizard/page/welcome"
        path="/wizard/page/welcome"
        exact
        component={PageWelcome}
      />,
    ];
  };

  const renderWizardRoutesWithPageNewGUI = () => {
    return [
      <PrivateRouteWithVancoVideo
        key="/wizard/stream/page-info"
        path="/wizard/stream/page-info"
        exact
        component={StreamPageInfo}
      />,
      <PrivateRouteWithVancoVideo
        key="/wizard/stream/pageId/page-info"
        path="/wizard/stream/:pageId/page-info"
        exact
        component={StreamPageInfo}
      />,
      // Remove on ChannelManagement FF cleanup
      <PrivateRoute
        key="/wizard/stream/branding"
        path="/wizard/stream/:pageId/branding"
        exact
        component={StreamPageBranding}
      />,
      <PrivateRouteWithVancoVideo
        key="/wizard/stream/giving"
        path="/wizard/stream/:pageId/giving"
        exact
        component={StreamPageGiving}
      />,
      <PrivateRouteWithVancoVideo
        key="/wizard/stream/chat"
        path="/wizard/stream/:pageId/chat"
        exact
        component={StreamPageChat}
      />,
      <PrivateRouteWithVancoVideo
        key="/wizard/stream/review"
        path="/wizard/stream/:pageId/review"
        exact
        component={StreamPageReviewWizard}
      />,
      <Route key="notFound" component={NotFound} />,
    ];
  };

  const renderOrganizationWizardRoutes = () => {
    return [
      <PrivateRoute
        key="/organization/wizard/continue"
        path="/organization/wizard/continue"
        exact
        component={OrganizationContact}
      />,
      <PrivateRoute
        key="/organization/wizard/contact"
        path="/organization/wizard/contact"
        exact
        component={OrganizationContact}
      />,
      <PrivateRoute
        key="/organization/wizard/branding"
        path="/organization/wizard/branding"
        exact
        component={OrganizationBranding}
      />,
      <PrivateRoute
        key="/organization/wizard/fees"
        path="/organization/wizard/fees"
        exact
        component={OrganizationFees}
      />,
      <PrivateRoute
        key="/organization/wizard/review"
        path="/organization/wizard/review"
        exact
        component={OrganizationReview}
      />,
      <Route key="notFound" component={NotFound} />,
    ];
  };

  return (
    <ConnectedRouter history={history}>
      <PermissionsRouter />
      <section className={classes.container} data-testid="container">
        <Navbar isLoggedIn={isLoggedIn} />
        <AppProgressBar />
        <ProfileNav />
        <Switch>
          <PrivateRoute path="/" exact component={HomeRedirect} />
          <PrivateRoute
            path="/organization/wizard"
            component={(props) => (
              <WizardLayout
                data-testid="routes"
                {...props}
                isLoggedIn={isLoggedIn}
              >
                {renderOrganizationWizardRoutes()}
              </WizardLayout>
            )}
          />
          {renderWizardRoutesWithoutPage()}
          <PrivateRoute
            path="/wizard/stream"
            component={(props) => (
              <WizardLayout
                data-testid="routes"
                {...props}
                isLoggedIn={isLoggedIn}
                newWizard // temporary prop
              >
                {renderWizardRoutesWithPageNewGUI()}
              </WizardLayout>
            )}
          />
          <PrivateRoute
            path="/wizard"
            component={(props) => (
              <WizardLayout
                data-testid="routes"
                {...props}
                isLoggedIn={isLoggedIn}
              >
                {renderWizardRoutesWithPage()}
              </WizardLayout>
            )}
          />
          <PrivateRoute
            path="/internallogin/:orgId"
            component={InternalLogin}
          />

          <Route
            path="/"
            render={() => <MainLayout>{renderMainRoutes()}</MainLayout>}
          />
          <PrivateRoute
            path="/mobile"
            component={(props) => (
              <WizardLayout
                data-testid="routes"
                {...props}
                isLoggedIn={isLoggedIn}
              >
                {renderWizardRoutesWithPage()}
              </WizardLayout>
            )}
          />
          <Route key="notFound" component={NotFound} />
        </Switch>
        <LoadingDialog />
        <AppSnackbar />
        <IdleHandler />
      </section>
    </ConnectedRouter>
  );
}

Router.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isInternal: PropTypes.bool.isRequired,
  wasInternal: PropTypes.bool.isRequired,
  auth: PropTypes.func.isRequired,
  loadingAuth: PropTypes.bool.isRequired,
  getPages: PropTypes.func.isRequired,
  loadingPages: PropTypes.bool.isRequired,
  loadingLogin: PropTypes.bool.isRequired,
  internalLoadingLogin: PropTypes.bool.isRequired,
  hasLoadedPages: PropTypes.bool.isRequired,
  features: PropTypes.objectOf(PropTypes.bool).isRequired,
  promptToCompleteOrganizationSettings: PropTypes.bool,
  mobileEnabled: PropTypes.bool,
  hasKiosk: PropTypes.bool.isRequired,
  getKiosk: PropTypes.func.isRequired,
  orgUri: PropTypes.string,
};

Router.defaultProps = {
  promptToCompleteOrganizationSettings: false,
  mobileEnabled: false,
  orgUri: null,
};

export default withStyles(styles)(Router);
