import { makeStyles } from '@material-ui/core';

export default makeStyles(() => {
  return {
    iframeStyle: {
      border: 'none',
      width: '100%',
      // Firefox
      scrollbarWidth: 'none',
      // IE
      msOverflowStyle: 'none',
      // Chrome / Safari
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      padding: 19,
      height: '100%',
    },
  };
});
