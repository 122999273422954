import { connect } from 'react-redux';

import VancoMobileAppBranding from './VancoMobileAppBranding';
import { savePage, getPage } from '../../../store/pages/actions';
import { setSuccess } from '../../../store/alerts/actions';
import { selectPage } from '../../../store/pages/selectors';

export const mapStateToProps = state => ({
  page: selectPage(state, state.pages.currentPage.id),
  loadingSave: state.pages.loadingSave,
  loadingPage: state.pages.loadingPage,
  hasLoadedPages: state.pages.hasLoadedPages,
});

export default connect(mapStateToProps, {
  savePage,
  setSuccess,
  getPage,
})(VancoMobileAppBranding);
