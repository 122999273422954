import { useSelector } from 'react-redux';
import selectSessionUser from './transactionSelectors';

/**
 *
 * @returns the user session information, it does not include address
 */
const useSessionUser = () => {
  const sessionUser = useSelector(selectSessionUser);
  return sessionUser;
};

export default useSessionUser;
