export default function styles() {
  return {
    modal: {
      maxWidth: 600,
    },
    title: {
      padding: 24,
      marginBottom: 0,
      '& h6': {
        textAlign: 'left',
        paddingLeft: 0,
      },
    },
    padding: {
      padding: '24px',
    },
    noBorder: {
      paddingTop: 4,
      borderTop: 'none',
    },
    button: {
      borderRadius: 4,
    },
    checkMargin: {
      marginTop: 4,
    },
    optionMargin: {
      marginTop: 12,
    },
  };
}
