// Libs
import {
  FAITH_FORWARD_EVENT_TYPE,
  VANCO_VIDEO_TYPE,
} from '../../../globals/constants';
import { useFetchPages } from '../../../utils/hooks/pages';

// Components
import BaseVideoComponent from '../BaseVideoComponent';
import PagePaper from '../PagePaper';

// Styles
import useStyles from './styles';

function VideoChannelSettings() {
  const classes = useStyles();
  const { isLoading, fetchPages } = useFetchPages();

  const events = {
    [FAITH_FORWARD_EVENT_TYPE.setChannelUrl]: fetchPages,
  };

  return (
    <PagePaper className={classes.container} contained>
      <BaseVideoComponent
        variant={VANCO_VIDEO_TYPE.SETTINGS}
        customEventsMap={events}
        isLoading={isLoading}
      />
    </PagePaper>
  );
}

VideoChannelSettings.propTypes = {};

export default VideoChannelSettings;
