export default function styles() {
  return {
    title: {
      padding: 24,
      marginBottom: 0,
      '& h6': {
        textAlign: 'left',
        paddingLeft: 0,
      },
    },
    content: {
      padding: '15px 24px 30px',
    },
    actions: {
      borderTop: 0,
      padding: '0 24px 24px',
    },
    closeButton: {
      padding: '13px 12px',
      width: 85,
    },
  };
}
