import {
  VancoMobileUsers,
  VancoMobileGroups,
  VancoMobileEditProcessingFees,
  VancoMobileOrganizationDetails,
  VancoMobileAppBranding,
  VancoMobileEditPermissions,
  VancoMobileOrderTiles,
  VancoMobileGroup,
  VancoMobileEvent,
  VancoMobileEditDonorSettings,
  NotFound,
} from '../../common';
import VancoMobileEvents from '../../common/VancoMobileEvents';
import VancoMobileSettings from '../../pages/VancoMobileSettings';
import GetStartedVancoMobile from '../../pages/GetStartedVancoMobile';
import VancoMobileUser from '../../common/VancoMobileUser';

// this is not the best soultion bu we need to find the way to remove the naviation
// dependencies from the common folder in order to use MainLayout inside common
// also we need to extract MainLayout from /navigation to make it a /common component
export function VancoMobileUsersPage() {
  return <VancoMobileUsers />;
}

export function VancoMobileUserPage() {
  return <VancoMobileUser />;
}

export function VancoMobileGroupsPage() {
  return <VancoMobileGroups />;
}

export function VancoMobileGroupPage() {
  return <VancoMobileGroup />;
}

// TODO: Remove with ChannelManagement FF cleanup
export function GetStartedVancoMobilePage() {
  return <GetStartedVancoMobile />;
}

// TODO: Remove with ChannelManagement FF cleanup
export function VancoMobileEditProcessingFeesPage() {
  return <VancoMobileEditProcessingFees />;
}

// TODO: Remove with ChannelManagement FF cleanup
export function VancoMobileOrganizationDetailsPage() {
  return <VancoMobileOrganizationDetails />;
}

// TODO: Remove with ChannelManagement FF cleanup
export function VancoMobileAppBrandingPage() {
  return <VancoMobileAppBranding />;
}

export function VancoMobileEditPermissionsPage() {
  return <VancoMobileEditPermissions />;
}

export function VancoMobileOrderTilesPage() {
  return <VancoMobileOrderTiles />;
}

export function VancoMobileSettingsPage() {
  return <VancoMobileSettings />;
}

export function VancoMobileEventsPage() {
  return <VancoMobileEvents />;
}

export function VancoMobileEventPage() {
  return <VancoMobileEvent />;
}

export function VancoMobileEditDonorSettingsPage() {
  return <VancoMobileEditDonorSettings />;
}

export function VancoMobileNotFoundPage() {
  return <NotFound />;
}
