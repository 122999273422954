import { Fragment } from 'react';
// eslint-disable-next-line import/prefer-default-export
export const stringToHTML = text => {
  if (!(text || '').trim()) {
    return null;
  }

  const paragraphs = text.split('\n');

  const result = paragraphs.reduce((html, p, index) => {
    const key = index;
    html.push(<Fragment key={key}>{p}</Fragment>);

    if (index < paragraphs.length - 1 || p === '') {
      html.push(<br key={`${key}-breakline`} />);
    }

    return html;
  }, []);

  return result;
};
