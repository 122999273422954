import { useState, useEffect } from 'react';
import PropTypes, { objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';

import { Page, PagePaper, PageTitle } from '../../common';
import { defaultPage } from '../../../globals/defaults';
import Tabs from './Tabs';
import StreamTitle from './Title';

import styles from './styles';

// temporary Mockup
const getDefaultDiv = (classes, t) => {
  return (
    <div className={classes.tempTabContent}>
      <AddIcon color="primary" />
      <Typography color="primary">
        {t('stream.multipleStreams.tab.upcoming.description')}
      </Typography>
    </div>
  );
};

// temporary Mockup
const getTabsContent = (classes, t) => {
  return [
    () => getDefaultDiv(classes, t),
    () => (
      <div className={classes.tempTabContent}>
        <Typography>
          {t('stream.multipleStreams.tab.past.description')}
        </Typography>
      </div>
    ),
    () => getDefaultDiv(classes, t),
  ];
};

const getTabPaths = () => {
  return ['Upcoming', 'Past', 'All'];
};

export function StreamList({ classes, location, page, features }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [pageToUpdate, setPageToUpdate] = useState(page);
  const [setError] = useState(false);
  const [submitCount] = useState(0);
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const isStreamPage = currentPath.includes('/stream');
  const isNewGUI = isStreamPage;
  const tabPaths = getTabPaths(isStreamPage, features);
  const tabIndex = tabPaths.findIndex((path) => currentPath.includes(path));
  const TabContent = getTabsContent(classes, t)[activeTab];

  useEffect(() => {
    setActiveTab(tabIndex !== -1 ? tabIndex : 0);
  }, [tabIndex]);

  useEffect(() => {
    if (page.id !== pageToUpdate.id) {
      setPageToUpdate(page);
    }
  }, [page, pageToUpdate]);

  // Temporary - until pages exists
  const handleTabChange = (e, val) => {
    setCurrentPath(`/streams/${tabPaths[val]}`);
  };

  return (
    <Page className={classes.page} variant="normal">
      <StreamTitle />
      <div className={classes.title}>
        <PageTitle variant="medium">Streams</PageTitle>
      </div>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        isStreamPage={isStreamPage}
        features={features}
      />
      <PagePaper>
        <div className={isNewGUI ? classes.tabContent : ''}>
          <TabContent
            page={pageToUpdate}
            updatePage={() => {}}
            updateStreamData={() => {}}
            updateStream={() => {}}
            resetNewPage={() => {}}
            setError={setError}
            onUnarchivePage={() => {}}
            submitCountFromParent={submitCount}
          />
        </div>
      </PagePaper>
    </Page>
  );
}

StreamList.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  page: PropTypes.object,
  features: objectOf(PropTypes.bool).isRequired,
};

StreamList.defaultProps = {
  page: defaultPage,
};

export default withStyles(styles)(StreamList);
