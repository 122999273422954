export default function styles(theme) {
  return {
    disclosure: {
      marginBottom: 32,
    },
    unarchiveButton: {
      backgroundColor: '#FFF',
      marginLeft: 'auto',
      [theme.breakpoints.down('xs')]: {
        margin: '8px 0 0 0',
      },
    },
    unarchiveButtonIcon: {
      marginRight: '10px',
      width: 20,
      height: 20,
    },
  };
}
