import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import useOrganizationPermissions from 'utils/hooks/useOrganizationPermissions';
import { isValidObject } from '../../../../utils/validations';
import { SECTIONS_TYPE } from '../../../../globals/constants';

import { Modal, PrimaryButton } from '../../../common';

import UserPermissionOptions from '../UserPermissionOptions';
import { usePermissions, useUserGrants } from '../../../../utils/hooks';
import {
  PERMISSIONS_CHECKBOXES,
  SECTION_PERMISSIONS,
} from '../../../../globals/grants';
import styles from './styles';

export function EditPermissionModal({ open, onClose, classes }) {
  const { t } = useTranslation();
  const { userToEditPermissions } = useSelector(
    (state) => state.user,
    shallowEqual,
  );
  const { isLoadingGrants, sectionGrants, updateGrants, isUpdatingGrants } =
    useUserGrants(userToEditPermissions?.idsuid);
  const { organizationGrants } = useOrganizationPermissions();
  const permissions = usePermissions();

  const ensureSettingsPermission = (grants) => {
    if (!Array.isArray(grants)) {
      return [];
    }
    const settingsGrant = grants.find(
      (g) => g.key === SECTION_PERMISSIONS.SETTINGS.ROOT,
    );
    if (!isValidObject(settingsGrant)) {
      return [
        ...grants,
        {
          sectionId: SECTIONS_TYPE.Settings,
          key: SECTION_PERMISSIONS.SETTINGS.ROOT,
          label: 'Settings',
          value: true,
        },
      ];
    }
    return grants.map((grant) =>
      grant.key === SECTION_PERMISSIONS.SETTINGS.ROOT
        ? { ...grant, value: true }
        : grant,
    );
  };

  const checkboxes = ensureSettingsPermission(
    PERMISSIONS_CHECKBOXES.filter((p) => permissions[p.key]).map((p) => ({
      ...p,
      value: sectionGrants.some((sg) => sg.key === p.key),
      disabled: !organizationGrants?.[p.key],
    })),
  );

  const onSubmitPermissions = async ({ permissions: accountGrants }) => {
    await updateGrants(accountGrants.filter((ag) => ag.value));
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title data-testid="edit-permissions-modal-title">
        {t('users.editPermissions.title')}
      </Modal.Title>
      {isLoadingGrants || !checkboxes?.length ? (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      ) : (
        <Formik
          initialValues={{
            permissions: checkboxes,
          }}
          onSubmit={onSubmitPermissions}
        >
          {({ values, setFieldValue, handleSubmit, dirty }) => {
            const onBoxChecked = (section, index) => {
              const newValue = cloneDeep(values.permissions);
              newValue[index] = { ...section, value: !section.value };
              setFieldValue('permissions', newValue);
            };

            return (
              <Form>
                <Modal.Content className={classes.modal}>
                  <UserPermissionOptions
                    permissions={values.permissions}
                    onPermissionChange={onBoxChecked}
                  />
                </Modal.Content>
                <Modal.Actions>
                  {isUpdatingGrants ? (
                    <CircularProgress />
                  ) : (
                    <div className={classes.buttonRow}>
                      <Button
                        size="large"
                        color="primary"
                        variant="outlined"
                        disabled={isUpdatingGrants}
                        onClick={onClose}
                      >
                        {t('button.cancel')}
                      </Button>
                      <PrimaryButton
                        size="large"
                        disabled={!dirty}
                        onClick={handleSubmit}
                        data-testid="update-user-button"
                      >
                        {t('button.update')}
                      </PrimaryButton>
                    </div>
                  )}
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
}

EditPermissionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditPermissionModal);
