import { useEffect, useState } from 'react';
import { bool, func, objectOf, string } from 'prop-types';
import { Prompt, useHistory } from 'react-router-dom';
import { withStyles, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import PrimaryButton from '../PrimaryButton';

import styles from './styles';

export function PreventLeavePageModal({
  classes,
  shouldOpenOnLeave,
  onLeave,
  changeAccountIsOpen,
}) {
  const [isOpen, setOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [navigateConfirmed, setNavigateConfirmed] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const closeModal = () => setOpen(false);

  useEffect(() => {
    if (navigateConfirmed && nextLocation) {
      history.push(nextLocation);
      if (onLeave) {
        onLeave(nextLocation);
      }
      setNextLocation(null);
      setNavigateConfirmed(false);
    }
  }, [history, onLeave, navigateConfirmed, nextLocation]);

  const handleOnLeave = location => {
    if (!navigateConfirmed && !changeAccountIsOpen) {
      setOpen(true);
      setNextLocation(location);
      return false;
    }

    return true;
  };

  const onLeavePage = () => {
    setOpen(false);
    setNavigateConfirmed(true);
  };

  return (
    <>
      <Prompt when={shouldOpenOnLeave} message={handleOnLeave} />
      <Modal
        classes={{ ...classes, closeIcon: undefined }}
        open={isOpen}
        onClose={closeModal}
      >
        <Modal.Title className={classes.title}>
          {t('leavePageModal.title')}
        </Modal.Title>
        <Modal.Content>
          <Typography>{t('leavePageModal.description')}</Typography>
        </Modal.Content>
        <Modal.Actions className={classes.actions}>
          <Button onClick={closeModal} size="large" color="primary">
            {t('leavePageModal.stayOnPage')}
          </Button>
          <PrimaryButton size="large" onClick={onLeavePage}>
            {t('leavePageModal.leavePage')}
          </PrimaryButton>
        </Modal.Actions>
      </Modal>
    </>
  );
}

PreventLeavePageModal.propTypes = {
  classes: objectOf(string).isRequired,
  shouldOpenOnLeave: bool,
  onLeave: func,
  changeAccountIsOpen: bool.isRequired,
};

PreventLeavePageModal.defaultProps = {
  shouldOpenOnLeave: false,
  onLeave: undefined,
};

export default withStyles(styles)(PreventLeavePageModal);
