import { node } from 'prop-types';

import PageDescription from '../../PageDescription';
import { useSetupLayoutContext } from '../context';

export function Content({ children }) {
  const { classes } = useSetupLayoutContext();

  return (
    <PageDescription className={classes.content}>{children}</PageDescription>
  );
}

Content.propTypes = {
  children: node.isRequired,
};

export default Content;
