export default function styles(theme) {
  return {
    pagePaper: {
      padding: 20,
    },
    title: {
      marginTop: 'auto',
      marginBottom: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    buttonContainer: {
      marginTop: 8,
    },
    goToSiteButton: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: '8px',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '8px',
      },
    },

    linkSiteText: {
      marginRight: '10px',
    },

    leftMargin: {
      marginLeft: '8px',
    },
  };
}
