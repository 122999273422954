export default function styles(theme) {
  return {
    button: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      fontSize: '14px',
      height: '90px',
      width: '90px',
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: '6px',
      '& svg': {
        stroke: theme.palette.primary.light,
        fill: theme.palette.primary.light,
        height: '30px',
        width: '30px',
      },
      '&.Mui-disabled': {
        opacity: 0.4,
      },
    },
    active: {
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      fontWeight: 600,
      '& svg': {
        stroke: theme.palette.primary.main,
        fill: theme.palette.primary.main,
      },
    },
  };
}
