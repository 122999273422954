export default function styles(theme) {
  return {
    tabs: {
      marginTop: 35,
      minHeight: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
      },
    },
  };
}
