export default function styles() {
  return {
    empty: {
      textAlign: 'center',
    },
    emptyHeading: {
      fontSize: '2rem',
    },
    emptyBody: {
      fontSize: '1.6rem',
    },
  };
}
