import { connect } from 'react-redux';
import ImportedFilesView from './ImportedFilesView';
import { resetNewPage } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    accessToken: state.user.session.accessToken,
    organizationUri: state.organizations.currentOrganization.id,
    idsPayerTenant: state.organizations.currentOrganization.idsPayerTenant,
  };
}

export default connect(mapStateToProps, { resetNewPage })(ImportedFilesView);
