import { useState } from 'react';
import { useMutation } from 'react-query';
import recurrenceAPI from '../api/recurrenceAPI';

function useNewImport() {
  const [uploadProgress, setUploadProgress] = useState(0);
  const onUploadProgress = (e) => {
    setUploadProgress(e.loaded);
  };
  const mutation = useMutation(({ file, tenant, authToken }) =>
    recurrenceAPI.createImport(file, tenant, authToken, onUploadProgress),
  );
  return {
    createNewImport: mutation.mutate,
    isLoading: mutation.isLoading,
    progress: uploadProgress,
    errors: mutation.error?.response?.data?.errors,
    ...mutation,
  };
}

export default useNewImport;
