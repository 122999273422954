import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
} from '@material-ui/core';

import { useFeatures } from 'utils/hooks';
import styles from './styles';

import { PrimaryButton, Modal, AuthorizationWrapper } from '../../../common';
import { ROLES } from '../../../../globals/constants';
import { USER_TRANSFEROWNERSHIP } from '../../../../globals/grants';

export function EditUserRoleForm({
  classes,
  user,
  onClose,
  onSave,
  onTransferOwnership,
  isLoading,
}) {
  const { t } = useTranslation();
  const { AdminUserPermissions } = useFeatures();

  return (
    <>
      <Modal.Content>
        <div className={classes.container}>
          <Typography>
            <b>{user.userNameString}</b>
          </Typography>
          <RadioGroup
            name="role"
            value={ROLES.ADMINISTRATOR}
            className={classes.radioGroup}
          >
            <FormControlLabel
              value={ROLES.ADMINISTRATOR}
              control={
                <Radio className={classes.radioButton} color="primary" />
              }
              label={
                <>
                  <Typography variant="h5" className={classes.formTitle}>
                    {ROLES.ADMINISTRATOR}
                  </Typography>
                  {!AdminUserPermissions && (
                    <Typography
                      variant="body2"
                      className={classes.formSubtitle}
                    >
                      {t('users.roles.administrator.description')}
                    </Typography>
                  )}
                </>
              }
            />
          </RadioGroup>
        </div>
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <AuthorizationWrapper requiredGrants={[USER_TRANSFEROWNERSHIP]}>
          <Button
            size="large"
            color="primary"
            variant="outlined"
            onClick={onTransferOwnership}
            data-testid="edit-form-transfer-ownership"
          >
            {t('users.editRole.transferOwnership')}
          </Button>
        </AuthorizationWrapper>
        {isLoading ? (
          <CircularProgress data-testid="edit-user-role-form-spinner" />
        ) : (
          <div>
            <Button
              size="large"
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              {t('button.cancel')}
            </Button>
            <PrimaryButton
              size="large"
              onClick={onSave}
              className={classes.submitButton}
              data-testid="update-user-button"
            >
              {t('button.update')}
            </PrimaryButton>
          </div>
        )}
      </Modal.Actions>
    </>
  );
}

EditUserRoleForm.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onTransferOwnership: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(EditUserRoleForm);
