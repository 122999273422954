import { useQuery } from 'react-query';

import { backendToFrontend } from '../../../store/banks/transform';
import BankService from '../../../api/BankService';

async function getBanks({ queryKey }) {
  const [, organizationId] = queryKey;
  const response = await BankService.getBanks(organizationId);
  return response.data.data.map(backendToFrontend);
}

export default function useBankAccounts(organizationId) {
  return useQuery(['bankAccounts', organizationId], getBanks);
}
