import { string, func, object, bool, objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';
import { CircularProgress, withStyles } from '@material-ui/core';

import WizardButtons from '../../navigation/WizardLayout/WizardButtons';
import PageTitle from '../PageTitle';
import CustomFormik from '../CustomFormik';
import { DEFAULT_COLOR } from '../../../globals/constants';
import BrandingForm from '../VancoMobileBrandingForm';
import styles from './styles';

export function VancoMobileAppBrandingWizard({
  classes,
  pageTitleKey,
  goToNext,
  goToPrev,
  page,
  savePage,
  loadingSave,
  updatePage,
  isFinished,
}) {
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    const formPage = {
      ...page,
      ...values,
    };

    await savePage(formPage);
    goToNext();
  };

  const colorSelectorProps = {
    loadingSave,
    goToPrev,
    isFinished,
    page,
  };

  // TODO: Remove with ChannelManagement FF cleanup
  return (
    <>
      <PageTitle className={classes.pageTitle}>{t(pageTitleKey)}</PageTitle>
      <CustomFormik
        onChange={updatePage}
        enableReinitialize
        initialValues={{
          primaryColor: page.primaryColor || DEFAULT_COLOR,
          logoImage: page.logoImage,
          bannerImage: page.bannerImage,
        }}
        onSubmit={onSubmit}
        render={(formikProps) => {
          const { dirty, handleSubmit, handleReset } = formikProps;
          return (
            <Form>
              <BrandingForm formikProps={formikProps} {...colorSelectorProps} />
              {!loadingSave && goToPrev && (
                <WizardButtons
                  isFinished={isFinished}
                  dirty={dirty}
                  goToNext={handleSubmit}
                  goToPrev={goToPrev}
                  undo={handleReset}
                />
              )}
              {loadingSave && goToPrev && (
                <div className={classes.spinnerContainer}>
                  <CircularProgress
                    color="primary"
                    data-testid="color-options-save-spinner"
                  />
                </div>
              )}
            </Form>
          );
        }}
      />
    </>
  );
}

VancoMobileAppBrandingWizard.propTypes = {
  classes: objectOf(string).isRequired,
  pageTitleKey: string,
  goToNext: func,
  goToPrev: func,
  page: object.isRequired,
  savePage: func.isRequired,
  loadingSave: bool.isRequired,
  updatePage: func,
  isFinished: bool,
};

VancoMobileAppBrandingWizard.defaultProps = {
  pageTitleKey: 'mobilePageWizard.branding.title',
  goToNext: null,
  goToPrev: null,
  updatePage: null,
  isFinished: false,
};

export default withStyles(styles)(VancoMobileAppBrandingWizard);
