import {
  GET_TRANSACTION_DATA_START,
  GET_TRANSACTION_DATA_FINISH,
  GET_TRANSACTION_DATA_ERROR,
  SET_PAYMENT_TYPE,
  UPDATE_BASKET,
  UPDATE_TRANSACTION_FEES,
  PROCESS_TRANSACTIONS_FINISH,
  SET_IS_PROCESSING_TRANSACTIONS,
} from './types';
import {
  handleException,
  handleResponseError,
  setError,
  setSuccess,
} from '../alerts/actions';
import TransactionService from '../../api/TransactionService';
import { setAppLoading } from '../loading/actions';
import { PAYMENT_FREQUENCY, PAYMENT_METHOD } from '../../globals/constants';
import {
  createTransactionsFactory,
  getDefaultPaymentMethod,
  hexeaRequestFactory,
  userToEnsureFactory,
} from '../../components/common/PaymentMethod/helpers/paymentMethodHelpers';
import { isValidObject } from '../../utils/validations';
import backendToFrontend from './transform';
import { getCxpErrorMessage, getApiErrorMessage } from '../../utils/errors';
import setHexea from './hexeaUtils';

export function getTransactionData() {
  return async (dispatch, getState) => {
    const { hexea, pcct: statePcct } = getState().transaction;

    dispatch(setAppLoading(true));
    dispatch({ type: GET_TRANSACTION_DATA_START });

    try {
      const response = await TransactionService.getTransactionData();

      if ([201, 200].includes(response.status)) {
        const { data } = response.data;

        const mappedData = backendToFrontend(data);
        dispatch({
          type: GET_TRANSACTION_DATA_FINISH,
          data: mappedData,
        });

        const { pcct: servicePcct = null, paymentMethods = null } = data;

        const selectedPaymentType = getDefaultPaymentMethod(paymentMethods);

        dispatch({
          type: SET_PAYMENT_TYPE,
          selectedPaymentType,
        });

        if (!servicePcct) return;

        if (hexea && statePcct === servicePcct) {
          return;
        }

        await setHexea({ servicePcct, dispatch });
      } else {
        dispatch({
          type: GET_TRANSACTION_DATA_ERROR,
          error: response.error,
        });
        dispatch(handleResponseError(response));
      }
    } catch (e) {
      dispatch({
        type: GET_TRANSACTION_DATA_ERROR,
        error: e?.message,
      });
      dispatch(handleException(e));
    } finally {
      dispatch(setAppLoading(false));
    }
  };
}

const getTransactionError = ({ error, fallbackMessage }) => {
  const serviceError = getCxpErrorMessage(error) || getApiErrorMessage(error);

  if (!serviceError) return error?.message ?? fallbackMessage;

  return serviceError;
};

export function processTransactions({
  hexeaPaymentMethod,
  formValues,
  formActions,
  translation,
}) {
  return async (dispatch, getState) => {
    dispatch(setAppLoading(true));
    dispatch({ type: SET_IS_PROCESSING_TRANSACTIONS, value: true });

    try {
      const { basket, selectedPaymentType, hexea, selectedFees } =
        getState().transaction;

      const shouldCreateUser =
        basket?.some((x) => x.frequency !== PAYMENT_FREQUENCY.ONE_TIME_NOW) ??
        false;

      const userToEnsure = userToEnsureFactory({
        selectedPaymentType,
        formValues: { ...formValues },
      });

      const {
        data: { data: ensuredUser = {}, identifier },
        error: ensureUserError,
        status,
      } = await TransactionService.ensureUser(userToEnsure, shouldCreateUser);

      if (status !== 200) {
        dispatch(setError(ensureUserError.message));
        return;
      }

      const payerUser = { ...ensuredUser, uuid: identifier };

      const hexeaRequest = hexeaRequestFactory({
        payerUser: { ...payerUser },
        formValues: { ...formValues },
        selectedPaymentType,
      });

      const tokenizeResponse = await hexea?.tokenize(
        hexeaPaymentMethod,
        hexeaRequest,
      );

      const { error: hexeaError, paymentMethod: tokenPaymentDetails } =
        tokenizeResponse;

      if (isValidObject(hexeaError)) {
        dispatch(
          setError(hexeaError.detailed_error_message || hexeaError.message),
        );
        return;
      }

      const createTransactionsRequest = createTransactionsFactory({
        tokenPaymentDetails,
        basket,
        selectedFees,
        payerUser: { ...payerUser },
        formValues: { ...formValues },
        selectedPaymentType,
      });

      const {
        data: { data: createdTransactions },
        error: createTransactionsError,
        status: createTransactionsStatus,
      } = await TransactionService.createTransactions(
        createTransactionsRequest,
      );

      if (![200, 201].includes(createTransactionsStatus)) {
        dispatch(setError(createTransactionsError.message));
        return;
      }

      formActions?.resetForm();
      if (selectedPaymentType === PAYMENT_METHOD.CREDIT_CARD) {
        /*
         * Makes a re-render in order to avoid CXP card fields freezing
         * when a transactions was processed successfully
         */
        formActions?.resetCCForm(formValues.currentCxpKey + 1);
      }
      dispatch(setSuccess(translation('newTransaction.processSuccess')));
      dispatch({ type: PROCESS_TRANSACTIONS_FINISH, createdTransactions });
    } catch (e) {
      const errorMessage = getTransactionError({
        error: e,
        fallbackMessage: translation('errors.newTransaction.processFailure'),
      });
      dispatch(handleException(e, false));
      dispatch(setError(errorMessage));
    } finally {
      dispatch(setAppLoading(false));
      dispatch({ type: SET_IS_PROCESSING_TRANSACTIONS, value: false });
    }
  };
}

export function setPaymentType(selectedPaymentType) {
  return async (dispatch) => {
    dispatch({
      type: SET_PAYMENT_TYPE,
      selectedPaymentType,
    });
  };
}

export function addToBasket(newTransaction) {
  return async (dispatch, getState) => {
    const { basket, tiles } = getState().transaction;
    const title = tiles.find((e) => e.id === newTransaction.fundId)?.title;
    const newBasket = [...basket, { ...newTransaction, title }];

    dispatch({ type: UPDATE_BASKET, data: newBasket });
  };
}
export function removeFromBasket(transaction) {
  return async (dispatch, getState) => {
    const { basket } = getState().transaction;
    const newBasket = [
      ...basket.filter((e) => e.fundId !== transaction.fundId),
    ];

    dispatch({ type: UPDATE_BASKET, data: newBasket });
  };
}

export function updateProcessingFee(applyFee) {
  return async (dispatch, getState) => {
    const { selectedPaymentType } = getState().transaction;
    const { feeValue } =
      getState().transaction.paymentMethods[selectedPaymentType];
    dispatch({
      type: UPDATE_TRANSACTION_FEES,
      data: {
        applyProcessingFee: applyFee,
        feeValue: applyFee ? feeValue : 0,
      },
    });
  };
}
