import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  withStyles,
  Typography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import AuthorizationWrapper from 'components/common/AuthorizationWrapper/AuthorizationWrapper';
import { useFeatures } from 'utils/hooks';
import styles from './styles';
import { ExternalLink } from '../../common';

import {
  TITLES_SUBROUTES,
  SITES_SUBROUTES,
  MOBILE_SUBROUTES,
  STREAM_SUBROUTES,
} from '../../../globals/constants';

const useClassColor = (label, classes) => {
  const { t } = useTranslation();

  const objClassColors = {
    [t('app.menu.giving')]: classes.orangeBackground,
    [t('app.menu.donors')]: classes.blueBackground,
    [t('app.menu.outreach')]: classes.greenBackground,
    [t('app.menu.smartVideo')]: classes.pinkBackground,
    [t('app.menu.data')]: classes.purpleBackground,
    [t('app.menu.import')]: classes.internalPurpleBackground,
    [t('app.menu.settings')]: classes.greyBackground,
  };

  return objClassColors[label] || '';
};

const isSubActive = (location, item) => {
  const itemPath = item.path;
  const currentPath = location.pathname;

  const objSubActive = {
    '/tiles':
      Object.values(TITLES_SUBROUTES).some((route) =>
        currentPath.includes(route),
      ) &&
      !Object.values(MOBILE_SUBROUTES).some((route) =>
        currentPath.includes(route),
      ),
    '/sites': Object.values(SITES_SUBROUTES).some((route) =>
      currentPath.includes(route),
    ),
    '/mobile/home': Object.values(MOBILE_SUBROUTES).some((route) =>
      currentPath.includes(route),
    ),
    '/donors': currentPath === item.path,
    '/streams':
      Object.values(STREAM_SUBROUTES).some((route) =>
        currentPath.includes(route),
      ) || currentPath === item.path,
  };

  const isSubActiveResult = objSubActive[itemPath];

  return isSubActiveResult !== undefined
    ? isSubActiveResult
    : location.pathname.includes(item.path);
};

function Submenu({
  classes,
  location,
  icon,
  label,
  active,
  expanded,
  onToggle,
  items,
}) {
  const classesColor = useClassColor(label, classes);
  const { AdminUserPermissions } = useFeatures();
  const AccordionClasses = clsx({
    [classes.menuLink]: true,
    [classes.active]: active,
    [classesColor]: active,
  });

  return (
    <Accordion expanded={expanded} onChange={onToggle} elevation={0} square>
      <AccordionSummary
        className={AccordionClasses}
        classes={{
          root: classes.summary,
          content: classes.summaryContent,
          expanded: classes.expanded,
        }}
      >
        <span>{icon}</span>
        <Typography
          className={`${classes.label} 
          ${active ? classes.active : classes.labelInactive}`}
        >
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <List>
          {items.map((item) => {
            const subActive = isSubActive(location, item);
            const WrapperLink = item.isExternalLink ? ExternalLink : Link;
            return (
              <AuthorizationWrapper
                {...item}
                key={item.path}
                disabled={!AdminUserPermissions}
              >
                <ListItem
                  key={item.path}
                  component={WrapperLink}
                  to={item.path}
                >
                  <Typography
                    className={`${classes.listItemText} ${
                      subActive ? classes.active : ''
                    }`}
                    data-testid={item['data-testid']}
                  >
                    {item.label}
                  </Typography>
                  {item.rightIcon ? item.rightIcon : null}
                </ListItem>
              </AuthorizationWrapper>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

Submenu.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
      isExternalLink: PropTypes.bool,
      rightIcon: PropTypes.element,
    }),
  ).isRequired,
};

export default withStyles(styles)(withRouter(Submenu));
