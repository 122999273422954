import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import FeeDonationInputs from '../../FeeDonationInputs';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import Form from '../../Form';
import styles from './styles';

export function OffsetForm({
  classes,
  values,
  errors,
  touched,
  handleBlur,
  handleSubmit,
  setFieldValue,
  goToPrev,
  loading,
  isFinished,
  handleReset,
  dirty,
}) {
  const { t } = useTranslation();

  return (
    <Form>
      <Typography className={classes.header} variant="body2">
        {t('additionalOptions.processingFees.title')}
      </Typography>
      <Typography>
        {t('additionalOptions.processingFees.description')}
      </Typography>
      <Grid item md={9} sm={12} xs={12}>
        <FeeDonationInputs
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
        />
      </Grid>

      {!loading && goToPrev && (
        <WizardButtons
          isFinished={isFinished}
          dirty={dirty}
          goToNext={handleSubmit}
          goToPrev={goToPrev}
          undo={handleReset}
        />
      )}
      {loading && goToPrev && <CircularProgress color="primary" />}
    </Form>
  );
}

OffsetForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  goToPrev: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
};

OffsetForm.defaultProps = {
  goToPrev: null,
};

export default withStyles(styles)(OffsetForm);
