const getEditorStyles = (theme, fontSize) => ({
  '&.ql-snow': {
    border: 0,
  },
  '& .ql-editor': {
    border: '1px solid #ccc',
    borderRadius: 6,
    // The segoe ui emoji font must be specified here in order to support Internet Explorer
    fontFamily: ['"Muli"', 'sans-serif', 'segoe ui emoji'].join(', '),
    fontSize: `${fontSize}rem`,
    minHeight: 256,
    paddingTop: 50,
    '&:focus, &:focus:hover': {
      border: 'solid 2px #30119B',
      padding: '49px 14px 12px',
    },
    '&:hover': {
      borderColor: theme.palette.secondary.main,
    },
    '&:before': {
      fontStyle: 'normal',
    },
    '&.ql-blank': {
      '&:before': {
        fontWeight: 200,
        opacity: 0.6,
      },
    },
  },
});

export default (theme) => ({
  divider: {
    height: '100%',
    width: '1px',
  },
  editorContainer: {
    position: 'relative',
    marginTop: 8,
  },
  editor: {
    ...getEditorStyles(theme, 1.6),
  },
  largeEditor: {
    ...getEditorStyles(theme, 2.0),
  },
  toolbar: {
    '&.ql-snow': {
      border: 0,
      borderRadius: '6px 6px 0px 0px',
      backgroundColor: theme.palette.white,
      position: 'absolute',
      left: 2,
      top: 2,
      width: 'calc(100% - 4px)',
      zIndex: 1,
      '& .button-group': {
        display: 'inline-block',
        '& button': {
          borderRadius: 4,
          color: theme.palette.primary.light,
          width: 32,
          height: 32,
          marginRight: 4,
          '&:hover': {
            color: theme.palette.secondary.main,
            backgroundColor: '#F3F3F3',
          },
          '&.ql-active': {
            color: theme.palette.secondary.main,
            backgroundColor: '#E1E1E1',
          },
        },
      },
      '& .button-group-separator': {
        boxSizing: 'border-box',
        backgroundColor: '#ddd',
        display: 'inline-block',
        height: 24,
        width: 1,
        margin: 4,
      },
      '& .ql-cname': {
        color: '#000000 !important',
        fontWeight: '700 !important',
        fontSize: '16px !important',
        width: '155px !important',
        backgroundColor: '#ECECEC !important',
        borderRadius: '8px !important',
      },
      '& .disabled': {
        color: '#9E9E9E !important',
        fontWeight: 'normal !important',
        cursor: 'not-allowed !important',
      },
    },
    '& .tooltipText:hover:before': {
      display: 'block',
      width: '100%',
    },
    '& .adjustLeft': {
      marginLeft: '27px',
    },
  },
  emojiPopover: {
    zIndex: '2',
  },
});
