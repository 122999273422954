import { withStyles } from '@material-ui/core/styles';
import { arrayOf, objectOf, string, node, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Hidden } from '@material-ui/core';

import {
  Table,
  TableHead,
  TableHeadCell,
  TableRow,
  TableBody,
} from '../../../common';
import styles from './styles';

export function List({ classes, disabled, children }) {
  const { t } = useTranslation();

  return (
    <Table disabled={disabled}>
        <TableHead>
          <TableRow isHeader>
            <TableHeadCell>
              <Typography variant="body2">{t('tile.tableHeader1')}</Typography>
            </TableHeadCell>
            <TableHeadCell className={classes.smallColumn}>
              <Typography variant="body2">{t('tile.tableHeader2')}</Typography>
            </TableHeadCell>
            <Hidden xsDown>
              <TableHeadCell className={classes.smallColumn}>
                <Typography variant="body2">
                  {t('tile.tableHeader3')}
                </Typography>
              </TableHeadCell>
            </Hidden>
            <Hidden smDown>
              <TableHeadCell className={classes.smallColumn}>
                <Typography variant="body2">
                  {t('tile.tableHeader4')}
                </Typography>
              </TableHeadCell>
            </Hidden>
            <TableHeadCell className={classes.moreMenu} menu />
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
  );
}

List.propTypes = {
  classes: objectOf(string).isRequired,
  disabled: bool.isRequired,
  children: arrayOf(node).isRequired,
};

export default withStyles(styles)(List);
