import i18n from '../i18n';
import { defaultPage } from '../../globals/defaults';
import { constants } from '../../globals';

export const getCheckPercentage = page => {
  return typeof page.checkPercentage === 'number'
    ? page.checkPercentage.toFixed(2)
    : page.checkPercentage || defaultPage.checkPercentage;
};

export const getCreditPercentage = page => {
  return typeof page.creditPercentage === 'number'
    ? page.creditPercentage.toFixed(2)
    : page.creditPercentage || defaultPage.creditPercentage;
};

export const getPagePermissions = page => {
  return {
    hasCommunityFeatures: page.hasCommunityFeatures,
    requiresJoinRequest:
      (page.mobileAppPermissions &&
        page.mobileAppPermissions.requiresJoinRequest) ||
      false,
    usersCanInvite:
      (page.mobileAppPermissions && page.mobileAppPermissions.usersCanInvite) ||
      false,
    usersCanCreateEvents:
      (page.mobileAppPermissions &&
        page.mobileAppPermissions.usersCanCreateEvents) ||
      false,
    usersCanCreateSubgroups:
      (page.mobileAppPermissions &&
        page.mobileAppPermissions.usersCanCreateSubgroups) ||
      false,
    defaultGroupName:
      (page.mobileAppPermissions &&
        page.mobileAppPermissions.defaultGroupName) ||
      i18n.t('mobileSetup.appPermissions.general'),
  };
};

export const getOrganizationInitials = name => {
  return (name ?? '')
    .split(' ')
    .map(word => (word.length > 0 ? word[0] : ''))
    .slice(0, 2)
    .join('')
    .toUpperCase();
};

export const getPhone = ({ phone }) => {
  if (!phone || !phone.length) return '';
  const areaCode = phone.substring(0, 3);
  const firstHalf = phone.substring(3, 6);
  const endingHalf = phone.substring(6);
  return `(${areaCode}) ${firstHalf}-${endingHalf}`;
};

export const isActive = page => page.status === constants.PAGE_ACTIVE;
export const isPending = page => page.status === constants.PAGE_PENDING;
export const isArchived = page => page.status === constants.PAGE_ARCHIVED;

export const getNonArchivedOnlinePages = pages =>
  pages.filter(
    p =>
      p.status !== constants.PAGE_ARCHIVED &&
      p.channelId === constants.CHANNEL_TYPE.ONLINE,
  );

export const getArchivedOnlinePages = pages =>
  pages.filter(
    p =>
      p.status === constants.PAGE_ARCHIVED &&
      p.channelId === constants.CHANNEL_TYPE.ONLINE,
  );

export const getPagesByChannelAndStatus = (
  pages,
  channelType,
  status = undefined,
) => {
  return pages
    .filter(
      p => p.channelId === channelType && (status ? p.status === status : true),
    )
    .sort((a, b) => (a.id > b.id ? 1 : -1));
};

export default {
  getCheckPercentage,
  getCreditPercentage,
  getPagePermissions,
  getOrganizationInitials,
  getPhone,
  isActive,
  isPending,
  isArchived,
  getNonArchivedOnlinePages,
  getArchivedOnlinePages,
  getPagesByChannelAndStatus,
};
