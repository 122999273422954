import { string, object, func, bool, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import moment from 'moment';
import {
  CHANNEL_TYPE,
  PAGE_PLATFORM_YOUTUBE,
} from '../../../globals/constants';
import { parseDescription } from '../../../utils/tiles';
import CustomFormik from '../CustomFormik';
import PageTitle from '../PageTitle';
import { createSchema, titleLength } from './validation';
import { defaultPage } from '../../../globals/defaults';
import useFeatures from '../../../utils/hooks/useFeatures/useFeatures';

import { getCurrentTimeZone } from '../../../utils/date';
import PageDivider from '../PageDivider';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';
import Form from './Form';

export function StreamPageInfo({
  createPage,
  savePage,
  goToNext,
  goToPrev,
  pageTitleKey,
  page,
  updateStreamData,
  setError,
  loadingSave,
  isFinished,
  loadingPages,
  loadingCreate,
  newPage,
  resetNewPage,
  submitCountFromParent,
  organizationName,
}) {
  const { t } = useTranslation();
  const features = useFeatures();
  const schema = createSchema(features);
  usePageTitle(t('titles.createSite'));

  useEffect(() => {
    if (goToNext && newPage.id) {
      goToNext();
      resetNewPage();
    }
  }, [newPage, goToNext, resetNewPage]);

  const createNewPage = async (values) => {
    await createPage({
      ...defaultPage,
      channelId: CHANNEL_TYPE.STREAM,
      streamData: {
        ...values,
      },
    });
  };

  const saveLocation = (values) => {
    const formPage = {
      ...page,
      streamData: {
        ...page.streamData,
        ...values,
      },
    };
    savePage(formPage);
  };

  const onSubmit = (values) => {
    if (page?.id) {
      saveLocation(values);
    } else {
      createNewPage(values);
    }
  };

  const resolveUserTimeZone = (timeZone) => {
    if (timeZone) return timeZone;
    return getCurrentTimeZone();
  };

  const getInitialValues = () => {
    const defaultDate = moment().add(1, 'day');

    const defaultInitialValues = {
      title: page.streamData?.title || organizationName,
      videoUrl: page.streamData?.videoUrl || '',
      platform: page.streamData?.platform || '',
      videoType: page.streamData?.videoType || '',
      embedCode: page.streamData?.embedCode || '',
      startDate: page.streamData?.startDate || undefined,
      startTime: page.streamData?.startTime ?? -1,
      endDate: page.streamData?.endDate || undefined,
      endTime: page.streamData?.endTime ?? -1,
      description: page.streamData?.description || parseDescription(' '),
      timeZone: resolveUserTimeZone(page.streamData?.timeZone),
      repeatWeekly: page.streamData?.repeatWeekly || false,
      chatTurnOnMinutes: page.streamData?.chatTurnOnMinutes || -1, // It allows us to know if the chat has already been configured
      chatEnabled: page.streamData?.chatEnabled ?? true,
    };

    if (features?.VancoVideo) {
      return {
        ...defaultInitialValues,
        startDate: defaultInitialValues.startDate ?? defaultDate,
        endDate: defaultInitialValues.endDate ?? defaultDate,
        platform: PAGE_PLATFORM_YOUTUBE,
      };
    }

    return defaultInitialValues;
  };

  return (
    <>
      <PageTitle variant="small">{t(pageTitleKey)}</PageTitle>
      {!updateStreamData && <PageDivider variant="title" />}
      <CustomFormik
        onChange={updateStreamData}
        onErrorsChange={setError}
        initialValues={getInitialValues()}
        onSubmit={onSubmit}
        render={(formikProps) => (
          <Form
            goToPrev={goToPrev}
            loading={loadingSave || loadingPages || loadingCreate}
            isFinished={isFinished}
            isWizard={!updateStreamData}
            submitCountFromParent={submitCountFromParent}
            maxLengths={{ title: titleLength }}
            features={features}
            {...formikProps}
          />
        )}
        validationSchema={schema}
      />
    </>
  );
}

StreamPageInfo.propTypes = {
  createPage: func.isRequired,
  savePage: func.isRequired,
  goToNext: func,
  goToPrev: func,
  pageTitleKey: string,
  page: object.isRequired,
  updateStreamData: func,
  setError: func,
  loadingSave: bool.isRequired,
  isFinished: bool,
  loadingPages: bool.isRequired,
  loadingCreate: bool.isRequired,
  newPage: object.isRequired,
  resetNewPage: func.isRequired,
  submitCountFromParent: number,
  organizationName: string,
};

StreamPageInfo.defaultProps = {
  goToNext: null,
  goToPrev: null,
  pageTitleKey: '',
  updateStreamData: null,
  setError: null,
  isFinished: false,
  submitCountFromParent: 0,
  organizationName: '',
};

export default StreamPageInfo;
