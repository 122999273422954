import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  useDynamicEmailTexts,
  useSaveDynamicEmailTexts,
} from '../../../utils/hooks/dynamicEmailTexts';

import { Page, PageTitle, CustomFormik } from '../../common';

import { COMMUNICATIONS_COMPLETED_TEMPLATE } from '../../../globals/constants';
import { createSchema, maxLengths } from './validation';
import { parseDescription } from '../../../utils/tiles';
import Form from './Form';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

function CommunicationEmailTemplate({ organizationName, userFirstname }) {
  const { t } = useTranslation();
  usePageTitle(t('communications.title'));
  const schema = createSchema();
  const { template } = useParams();

  const history = useHistory();
  const isCompletedTemplate = template === COMMUNICATIONS_COMPLETED_TEMPLATE;

  const { data: dynamicTexts } = useDynamicEmailTexts(template);
  const { mutate: saveDynamicEmailTexts } = useSaveDynamicEmailTexts(template);

  const onGoBack = () => {
    history.push('/communications/');
  };

  const handleSubmit = (values) => {
    saveDynamicEmailTexts(
      { template, values },
      {
        onSuccess: () => {
          onGoBack();
        },
      },
    );
  };

  return (
    <Page>
      <PageTitle onGoBack={onGoBack}>
        {isCompletedTemplate
          ? t('communications.select.completed')
          : t('communications.select.scheduled')}
      </PageTitle>
      <CustomFormik
        enableReinitialize
        initialValues={{
          subject: dynamicTexts ? dynamicTexts.actualSubject : '',
          body: parseDescription(dynamicTexts ? dynamicTexts.actualBody : ''),
          contactinfo: dynamicTexts ? dynamicTexts.contactInfo : '',
        }}
        onSubmit={handleSubmit}
        render={(formikProps) => (
          <Form
            template={template}
            organizationName={organizationName}
            userFirstname={userFirstname}
            maxLengths={maxLengths}
            onGoBack={onGoBack}
            {...formikProps}
          />
        )}
        validationSchema={schema}
      />
    </Page>
  );
}

CommunicationEmailTemplate.propTypes = {
  organizationName: string.isRequired,
  userFirstname: string.isRequired,
};

export default CommunicationEmailTemplate;
