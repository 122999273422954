import { string, func, bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';

import { CustomFormik, Modal } from '../../../common';
import NewUserForm from '../NewUserForm';

import { createSchema } from '../validation';

export function NewUserModal({
  open,
  onClose,
  onSave,
  isLoading,
  backendError,
  initialValues,
}) {
  const { t } = useTranslation();
  const schema = createSchema();

  return (
    <Modal open={open} onClose={onClose}>
        <CustomFormik
          onChange={() => {}}
          onErrorsChange={() => {}}
          initialValues={initialValues}
          onSubmit={onSave}
          render={formikProps => (
            <Form>
              <Modal.Title data-testid="create-user-modal-title">
                {t('users.add')}
              </Modal.Title>
              <NewUserForm
                onClose={onClose}
                isLoading={isLoading}
                backendError={backendError}
                {...formikProps}
              />
            </Form>
          )}
          validationSchema={schema}
        />
      </Modal>
  );
}

NewUserModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onSave: func.isRequired,
  isLoading: bool.isRequired,
  backendError: string,
  initialValues: object,
};

NewUserModal.defaultProps = {
  backendError: null,
  initialValues: {},
};

export default NewUserModal;
