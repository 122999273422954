import { connect } from 'react-redux';

import StreamSite from './StreamSite';
import {
  createStreamerSettings,
  updateStreamerSettings,
} from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    organizationName: state.user.user.organizationName,
    streamerId: state.pages.streamer.streamerId || state.user.user.streamerId,
    loadingCreate: state.pages.loadingCreate,
    loadingSave: state.pages.loadingSave,
  };
}

export default connect(mapStateToProps, {
  createStreamerSettings,
  updateStreamerSettings,
})(StreamSite);
