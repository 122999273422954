import {
  GET_USERS_SUCCESS,
  USER_LOGOUT,
  USER_LOGIN_BEGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  AUTH_SUCCESS,
  AUTH_FAILED,
  CREATE_USER_START,
  CREATE_USER_FINISH,
  CREATE_USER_ERROR,
  CLEAR_USER_ERROR,
  REMOVE_USER_START,
  REMOVE_USER_FINISH,
  REMOVE_USER_ERROR,
  SET_CREATE_USER_MODAL_OPEN,
  SET_REMOVE_USER_MODAL_OPEN,
  SET_EDIT_USER_MODAL_OPEN,
  SET_EDIT_USER_PERMISSIONS_MODAL_OPEN,
  SET_USER,
  OWNERSHIP_TRANSFERRED_START,
  OWNERSHIP_TRANSFERRED_FINISH,
  OWNERSHIP_TRANSFERRED_ERROR,
  SET_EMAIL_SETTINGS_MODAL_OPEN,
  EMAIL_SETTINGS_UPDATE_START,
  EMAIL_SETTINGS_UPDATE_FINISH,
  EMAIL_SETTINGS_ERROR,
  DISABLE_PROMPT_TO_COMPLETE_ORGANIZATION_SETTINGS,
  REFRESHED_TOKEN,
} from './types';

const INITIAL_STATE = {
  error: null,
  loadingLogin: false,
  loadingAuth: true,
  isLoggedIn: false,
  session: {
    accessToken: '',
    refreshToken: '',
    idTokenPayload: {
      name: '',
      given_name: '',
      family_name: '',
    },
    isAzureSession: false,
  },
  user: {
    isInternal: false,
    wasInternal: false,
    grantedActions: [],
    associatedOrganizations: [],
    organizationId: null,
    organizationTenant: null,
    organizationName: null,
    organizationStreamingDisableDate: undefined,
    organizationStreamingDisabled: false,
    promptToCompleteOrganizationSettings: false,
    streamerId: null,
    smartLibraryDisableDate: undefined,
    smartLibraryDisabled: false,
    smartClipsDisableDate: undefined,
    smartClipsDisabled: false,
  },
  orgUsers: [],
  loadingCreate: false,
  loadingRemove: false,
  loadingEdit: false,
  showCreateUserModal: false,
  showRemoveUserModal: false,
  showEditUserModal: false,
  showEditUserPermissionsModal: false,
  showEmailSettingsModal: false,
  loadingEmailSettings: false,
  userToRemove: {},
  userToEditRole: {},
  userToEditEmail: {},
  userToEditPermissions: {},
  redirectPath: '/',
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case REFRESHED_TOKEN: {
      const {
        payload: { accessToken, refreshToken, idToken, expiresIn, tExpires },
      } = action;
      return {
        ...state,
        session: {
          ...state.session,
          accessToken,
          refreshToken,
          tExpires,
          idToken,
          expiresIn,
        },
      };
    }
    case AUTH_SUCCESS: {
      const { session, user, redirectPath } = action;
      return {
        ...state,
        error: null,
        isLoggedIn: true,
        loadingAuth: false,
        session: session || INITIAL_STATE.session,
        user: user || INITIAL_STATE.user,
        redirectPath,
      };
    }
    case AUTH_FAILED:
      return {
        ...state,
        isLoggedIn: false,
        loadingAuth: false,
        error: action.error,
      };
    case USER_LOGOUT: {
      return {
        ...INITIAL_STATE,
        loadingAuth: false,
      };
    }
    case USER_LOGIN_BEGIN:
      return {
        ...state,
        loadingLogin: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        loadingLogin: false,
        redirectPath: action.redirectPath,
      };
    case USER_LOGIN_FAILED:
      return {
        ...state,
        loadingLogin: false,
        isLoggedIn: false,
        session: INITIAL_STATE.session,
        user: INITIAL_STATE.user,
        error: action.error,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        orgUsers: action.data,
      };
    case CREATE_USER_START:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_USER_FINISH:
      return {
        ...state,
        orgUsers: [...state.orgUsers, action.data],
        error: null,
        loadingCreate: false,
        showCreateUserModal: false,
      };
    case CREATE_USER_ERROR:
      return {
        ...state,
        loadingCreate: false,
        error: action.error,
      };
    case CLEAR_USER_ERROR:
      return {
        ...state,
        error: null,
      };
    case REMOVE_USER_START:
      return {
        ...state,
        loadingRemove: true,
      };
    case REMOVE_USER_FINISH:
      return {
        ...state,
        loadingRemove: false,
        showRemoveUserModal: false,
      };
    case REMOVE_USER_ERROR:
      return {
        ...state,
        loadingRemove: false,
      };
    case SET_CREATE_USER_MODAL_OPEN:
      return {
        ...state,
        showCreateUserModal: action.isOpen,
      };
    case SET_REMOVE_USER_MODAL_OPEN:
      return {
        ...state,
        showRemoveUserModal: action.isOpen,
        userToRemove: action.userToRemove,
      };
    case SET_EDIT_USER_MODAL_OPEN:
      return {
        ...state,
        showEditUserModal: action.isOpen,
        userToEditRole: action.userToEdit,
      };
    case SET_EDIT_USER_PERMISSIONS_MODAL_OPEN:
      return {
        ...state,
        showEditUserPermissionsModal: action.isOpen,
        userToEditPermissions: action.userToEdit,
      };
    case SET_EMAIL_SETTINGS_MODAL_OPEN:
      return {
        ...state,
        showEmailSettingsModal: action.isOpen,
        userToEditEmail: action.userToEdit,
      };
    case EMAIL_SETTINGS_UPDATE_START:
      return {
        ...state,
        loadingEmailSettings: true,
      };
    case EMAIL_SETTINGS_UPDATE_FINISH:
      return {
        ...state,
        orgUsers: [
          ...state.orgUsers.map((x) =>
            x.idsuid === action.data.idsuid ? { ...x, ...action.data } : x,
          ),
        ],
        loadingEmailSettings: false,
        showEmailSettingsModal: false,
      };
    case EMAIL_SETTINGS_ERROR:
      return {
        ...state,
        loadingEmailSettings: false,
        error: action.error,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case OWNERSHIP_TRANSFERRED_START:
      return {
        ...state,
        loadingEdit: true,
      };
    case OWNERSHIP_TRANSFERRED_FINISH:
      return {
        ...state,
        loadingEdit: false,
        showEditUserModal: false,
        error: null,
      };
    case OWNERSHIP_TRANSFERRED_ERROR:
      return {
        ...state,
        loadingEdit: false,
        error: action.error,
      };
    case DISABLE_PROMPT_TO_COMPLETE_ORGANIZATION_SETTINGS: {
      const updatedUser = {
        ...state.user,
        promptToCompleteOrganizationSettings: false,
      };
      return {
        ...state,
        user: updatedUser,
      };
    }
    default:
      return state;
  }
};
