import { useEffect } from 'react';
import LogRocket from 'logrocket';
import useUserSession from '../useUser/useUserSession';

export default () => {
  const { isLoggedIn, idTokenPayload: userInformation } = useUserSession();

  // Identify User
  useEffect(() => {
    if (!isLoggedIn || !userInformation) {
      return;
    }
    const { name, email } = userInformation;
    const env = window.env.REACT_APP_ENVIRONMENT;
    LogRocket.identify(email, {
      name,
      email,
      env,
    });
  }, [userInformation, isLoggedIn]);
};
