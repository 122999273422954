export default {
  base: {
    fontSize: '16px',
    lineHeight: '19px',
    fontFamily: ['Muli', 'sans-serif'].join(', '),
    '::placeholder': {
      color: '#a0a0a0',
    },
  },
  invalid: {
    color: '#e65f4b',
    ':focus': {
      color: '#3d3d3d',
    },
  },
};
