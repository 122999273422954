import AiAssistService from 'api/AiAssistService';
import { handleException, handleResponseError } from '../alerts/actions';

export function postAiEmailAssist(data) {
  return async (dispatch) => {
    const body = {
      subject: data.purposeEntry,
      description: data.mainPointsEntry,
      vancoChurchId: data.organizationId,
      tone: data.toneEntry,
      length: data.responseLength,
    };
    try {
      const res = await AiAssistService.postAiEmailAssist(body);

      if ([201, 200].includes(res.status)) {
        return res.data.data;
      }

      dispatch(handleResponseError(res));
    } catch (e) {
      dispatch(handleException(e));
    }
    return null;
  };
}

export function postAiEmailFinishMyThought(data) {
  return async (dispatch) => {
    const body = {
      text: data.text,
      vancoChurchId: data.organizationId,
    };
    try {
      const res = await AiAssistService.finishMyThought(body);

      if ([201, 200].includes(res.status)) {
        return res.data.data;
      }

      dispatch(handleResponseError(res));
    } catch (e) {
      dispatch(handleException(e));
    }
    return null;
  };
}

export default postAiEmailAssist;
