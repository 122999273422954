import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import Form from '../../Form';

import styles from './styles';
import OutlinedTextField from '../../OutlinedTextField';
import PrimaryButton from '../../PrimaryButton';

export function SiteSettingsForm({
  classes,
  values,
  handleSubmit,
  setFieldValue,
  isWizard,
  loading,
  touched,
  errors,
  maxLengths,
  handleBlur,
}) {
  const { t } = useTranslation();
  const { pageName, allowSocialSharing, displayPageName } = values;

  const handleRadioChange = async (e) => {
    const { name, value } = e.target;
    await setFieldValue({ target: { name, value: value === 'true' } });
  };
  const handleCheckboxChange = async (e) => {
    const { name, checked } = e.target;
    await setFieldValue({ target: { name, value: checked } });
  };

  return (
    <Form className={isWizard ? classes.form : classes.formEditor}>
      <Grid container className={classes.container}>
        <Typography
          className={isWizard ? classes.subTitle : classes.subTitleEditor}
          variant="body2"
        >
          {t('headerManagement.siteName')}
        </Typography>
        <Typography>{t('headerManagement.giveName.description')}</Typography>
        <FormControl fullWidth>
          <OutlinedTextField
            showCount
            id="pageName"
            name="pageName"
            data-testid="pageName"
            error={errors.pageName && touched.pageName}
            value={pageName}
            label={t('headerManagement.siteName')}
            placeholder={t('headerManagement.siteName.placeholder')}
            inputProps={{
              maxLength: maxLengths.pageName,
            }}
            onBlur={handleBlur}
            onChange={setFieldValue}
            autoComplete="off"
            autoFocus={!pageName.length}
            errorMessage={touched.pageName && errors.pageName}
          />
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              name="displayPageName"
              color="primary"
              onChange={handleCheckboxChange}
              checked={displayPageName}
            />
          }
          label={t('headerManagement.display.siteName')}
        />
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.container}>
        <Typography variant="body2">{t('wizard.nav.socialSharing')}</Typography>
        <Typography>{t('wizard.socialSharing.description')}</Typography>
        <RadioGroup
          component={RadioGroup}
          onChange={handleRadioChange}
          className={classes.defaultGutter}
        >
          <FormControlLabel
            control={
              <Radio
                checked={allowSocialSharing}
                name="allowSocialSharing"
                color="primary"
                value
              />
            }
            label={<Typography>{t('wizard.socialSharing.enable')}</Typography>}
          />
          <FormControlLabel
            control={
              <Radio
                checked={!allowSocialSharing}
                name="allowSocialSharing"
                color="primary"
                value={false}
              />
            }
            label={<Typography>{t('wizard.socialSharing.disable')}</Typography>}
          />
        </RadioGroup>
      </Grid>
      {isWizard && !loading && (
        <PrimaryButton
          size="large"
          onClick={handleSubmit}
          className={classes.submitButton}
        >
          {t('button.done')}
        </PrimaryButton>
      )}
      {isWizard && loading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress
            color="primary"
            data-testid="social-sharing-save-spinner"
          />
        </div>
      )}
    </Form>
  );
}

SiteSettingsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isWizard: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  maxLengths: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default withStyles(styles)(SiteSettingsForm);
