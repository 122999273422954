import { withStyles } from '@material-ui/core';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Review from '../../common/Organization/Review';
import PageTitle from '../../common/PageTitle';
import { Page } from '../../common';
import styles from './styles';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

function OrganizationSettings({ classes }) {
  const { t } = useTranslation();
  usePageTitle(t('app.menu.settings.organization'));

  return (
    <Page variant="centered" className={classes.container}>
      <PageTitle className={classes.title}>
        {t('app.menu.settings.organization')}
      </PageTitle>
      <Review isEditor />
    </Page>
  );
}

OrganizationSettings.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(OrganizationSettings);
