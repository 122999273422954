import { forwardRef } from 'react';
import { node, func, oneOf } from 'prop-types';
import { MenuItem } from '@material-ui/core';

import { usePopoverMenuContext } from '../context';

const Item = forwardRef(({ children, onSelect, variant }, ref) => {
  const { classes, closeMenu } = usePopoverMenuContext();

  const onItemClick = e => {
    e.stopPropagation();
    if (onSelect) {
      onSelect(e);
    }
    closeMenu(e);
  };

  function getVariantClass() {
    switch (variant) {
      case 'danger':
        return classes.danger;
      case 'default':
      default:
        return classes.default;
    }
  }

  return (
    <MenuItem
      innerRef={ref}
      className={[classes.item, getVariantClass()].join(' ')}
      onClick={onItemClick}
    >
      {children}
    </MenuItem>
  );
});

Item.propTypes = {
  children: node.isRequired,
  onSelect: func,
  variant: oneOf(['default', 'danger']),
};

Item.defaultProps = {
  onSelect: null,
  variant: 'default',
};

export default Item;
