import { connect } from 'react-redux';
import { updateAddressRequired } from '../../../store/pages/actions';

import VancoMobileEditDonorSettings from './VancoMobileEditDonorSettings';

export function mapStateToProps(state) {
  return {
    loadingSave: state.pages.loadingSave,
  };
}

export default connect(mapStateToProps, {
  updateAddressRequired,
})(VancoMobileEditDonorSettings);
