import { string, object, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import PageTitle from '../PageTitle';
import CustomFormik from '../CustomFormik';
import ProcessingFeesForm from './ProcessingFeesForm';
import { getCheckPercentage, getCreditPercentage } from '../../../utils/page';
import { createSchema } from './validations';

// TODO: Remove with ChannelManagement FF cleanup
export function VancoMobileProcessingFeesWizard({
  savePage,
  goToNext,
  goToPrev,
  pageTitleKey,
  page,
  updatePage,
  setError,
  loadingSave,
  isFinished,
}) {
  const { t } = useTranslation();
  const schema = createSchema();

  const onSubmit = async (values) => {
    const formPage = {
      ...page,
      ...values,
      hasPaymentMethods: true,
    };

    await savePage(formPage);
    goToNext();
  };

  return (
    <>
      <PageTitle>{t(pageTitleKey)}</PageTitle>
      <CustomFormik
        onChange={updatePage}
        onErrorsChange={setError}
        initialValues={{
          enableProcessingFees: page.enableProcessingFees || false,
          checkPercentage: getCheckPercentage(page),
          creditPercentage: getCreditPercentage(page),
        }}
        onSubmit={onSubmit}
        enableReinitialize
        render={(formikProps) => (
          <ProcessingFeesForm
            {...formikProps}
            goToPrev={goToPrev}
            loading={loadingSave}
            isFinished={isFinished}
          />
        )}
        validationSchema={schema}
      />
    </>
  );
}

VancoMobileProcessingFeesWizard.propTypes = {
  savePage: func.isRequired,
  goToNext: func.isRequired,
  goToPrev: func.isRequired,
  pageTitleKey: string,
  page: object.isRequired,
  updatePage: func,
  setError: func,
  loadingSave: bool.isRequired,
  isFinished: bool,
};

VancoMobileProcessingFeesWizard.defaultProps = {
  pageTitleKey: '',
  updatePage: null,
  setError: null,
  isFinished: false,
};

export default VancoMobileProcessingFeesWizard;
