import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import tileImage from './tile.png';
import PageTitle from '../PageTitle';
import PageDescription from '../PageDescription';
import PrimaryButton from '../PrimaryButton';
import LinkButton from '../LinkButton';

export function TileComplete({ classes, goToNext, firstName }) {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <PageTitle className={classes.title}>
            {t('wizard.tileComplete.title', { firstName })}
          </PageTitle>
          <img src={tileImage} alt="tile" className={classes.mobileImage} />
          <PageDescription className={classes.description}>
            {t('wizard.tileComplete.description')}
          </PageDescription>
          <PrimaryButton size="large" onClick={goToNext}>
            {t('wizard.tileComplete.leftButton')}
          </PrimaryButton>
          <LinkButton
            to="/page-settings/page-info"
            size="large"
            color="primary"
          >
            {t('wizard.tileComplete.rightButton')}
          </LinkButton>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <img src={tileImage} alt="tile" className={classes.image} />
        </Grid>
      </Grid>
    </div>
  );
}

TileComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  goToNext: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
};

export default withStyles(styles)(TileComplete);
