import { connect } from 'react-redux';
import LoadingDialog from './LoadingDialog';

export function mapStateToProps(state) {
  return {
    message: state.loadingDialog.message,
    open: state.loadingDialog.open,
  };
}

export default connect(mapStateToProps, null)(LoadingDialog);
