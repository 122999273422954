import { useCallback, useMemo, useRef, useState } from 'react';
import { objectOf, string, node, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { IconButton, Menu } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import PopoverMenuContext from './context';
import Item from './Item/Item';
import Divider from './Divider/Divider';

import styles from './styles';

export function PopoverMenu({ classes, children, buttonProps, menuProps }) {
  const menuRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const onMenuClick = e => {
    e.stopPropagation();
    setMenuOpen(true);
  };

  const closeMenu = useCallback(e => {
    e.stopPropagation();
    setMenuOpen(false);
  }, [setMenuOpen]);

  function getPosition() {
    if (!menuRef.current) {
      return { top: 0, left: 0 };
    }

    const rect = menuRef.current.getBoundingClientRect();
    return { top: rect.top + 30, left: rect.left - 195 };
  }

  const contextProviderProps = useMemo(() => ({classes, closeMenu}), [classes, closeMenu]);

  return (
    <PopoverMenuContext.Provider value={contextProviderProps}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={onMenuClick}
        className={isMenuOpen ? classes.buttonSelected : classes.button}
        ref={menuRef}
        {...buttonProps}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        keepMounted
        open={isMenuOpen}
        onClose={closeMenu}
        anchorReference="anchorPosition"
        anchorPosition={getPosition()}
        PaperProps={{
          className: classes.paper,
          ...menuProps,
        }}
        MenuListProps={{
          className: classes.menu,
        }}
      >
        {children}
      </Menu>
    </PopoverMenuContext.Provider>
  );
}

PopoverMenu.Item = Item;
PopoverMenu.Divider = Divider;

PopoverMenu.propTypes = {
  classes: objectOf(string).isRequired,
  children: node.isRequired,
  buttonProps: object,
  menuProps: object,
};

PopoverMenu.defaultProps = {
  buttonProps: {},
  menuProps: {},
};

export default withStyles(styles)(PopoverMenu);
