export default function styles(theme) {
  return {
    container: {
      paddingTop: 91,
      paddingBottom: 91,
      paddingLeft: 51,
      paddingRight: 51,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    title: {
      fontSize: 36,
      lineHeight: 'normal',
    },
    description: {
      paddingTop: 41,
      paddingBottom: 41,
    },
    image: {
      display: 'block',
      width: '100%',
      maxWidth: 858,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    mobileImage: {
      display: 'none',
      width: '100%',
      maxWidth: 858,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
  };
}
