/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, react/jsx-wrap-multilines */
import { useState } from 'react';
import { node } from 'prop-types';
import TruncateMarkup from 'react-truncate-markup';
import Skeleton from '@material-ui/lab/Skeleton';

import { useCardContext } from '../context';

function CardDescription({ children }) {
  const { classes, isLoading } = useCardContext();
  const [displayText, setDisplayText] = useState(false);
  const toggleText = () => setDisplayText(prevDisplayText => !prevDisplayText);

  if (isLoading) {
    return (
      <div className={classes.cardDescription}>
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
      </div>
    );
  }

  if (!children) {
    return null;
  }

  return (
    <div className={classes.cardDescription}>
      {!displayText ? (
        <TruncateMarkup
          lines={4}
          ellipsis={
            <span onClick={toggleText} className={classes.readMore}>
              read more
            </span>
          }
        >
          <div>{children}</div>
        </TruncateMarkup>
      ) : (
        <>
          {children}
          <span onClick={toggleText} className={classes.readMore}>
            read less
          </span>
        </>
      )}
    </div>
  );
}

CardDescription.propTypes = {
  children: node,
};

CardDescription.defaultProps = {
  children: null,
};

export default CardDescription;
