import { OPEN_LOADING_DIALOG, CLOSE_LOADING_DIALOG } from './types';

export function open(message) {
  return {
    type: OPEN_LOADING_DIALOG,
    message,
  };
}

export function close() {
  return {
    type: CLOSE_LOADING_DIALOG,
  };
}
