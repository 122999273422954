import { func, objectOf, string } from 'prop-types';
import { ButtonBase, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

function AddTile({ classes, onClick }) {
  const { t } = useTranslation();

  return (
    <ButtonBase className={classes.container} onClick={onClick}>
      <AddIcon />
      <Typography className={classes.add} color="inherit">
        {t('tilesOrder.addTiles')}
      </Typography>
    </ButtonBase>
  );
}

AddTile.propTypes = {
  classes: objectOf(string).isRequired,
  onClick: func.isRequired,
};

export default withStyles(styles)(AddTile);
