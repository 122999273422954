const getSameGrants = (actions, grantedActions) =>
  actions.filter(Set.prototype.has, new Set(grantedActions));

export const userIsAllowed = (
  grantedActions,
  requiredActions = [],
  optionalActions = [],
) => {
  if (requiredActions?.length < 1 && optionalActions?.length < 1) {
    return true;
  }

  // validGrants is the intersecction between requiredActions and grantedActions
  const validGrants =
    Boolean(requiredActions?.length) &&
    getSameGrants(requiredActions, grantedActions);

  if (
    requiredActions?.length > 0 &&
    requiredActions?.length === validGrants.length
  ) {
    return true;
  }

  const validOptionalGrants = getSameGrants(optionalActions, grantedActions);
  // we need at least 1 optional grant to allow the user
  if (validOptionalGrants.length > 0) {
    return true;
  }

  return false;
};

export default { userIsAllowed };
