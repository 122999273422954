import PropTypes from 'prop-types';

/* A wizard page that represents a section or subsection and its navigational properties */
export const wizardPageType = PropTypes.shape({
  /* An index that represents the linkable page (or non-page) */
  index: PropTypes.number,
  // The navigation title in the right nav bar of the wizard
  // This can be omitted to not treat page as a step, ie intro pages
  navTitle: PropTypes.string,
  /* An overidable next path that it should go to when Save & Continue is clicked */
  nextPath: PropTypes.string,
  /* An overidable previous path that it should go to when Previous Step is clicked */
  prevPath: PropTypes.string,
});

/* The wizard config that represents the pages (sections or subsections) */
export const wizardConfigType = PropTypes.arrayOf(wizardPageType);

export default {
  wizardPageType,
  wizardConfigType,
};
