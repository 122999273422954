import { format } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';
import { getTimeZones } from '@vvo/tzdb';
import { getOffSets } from './timeZoneHelper';

let tzCache = [];

export const getFormatedDate = (date) => {
  return format(date, 'EEEE, MMM d ∙ h:mm aaa');
};

export const getFormatedHour = (date) => {
  return format(date, 'h:mm aaa');
};

export const getFormatedFullDate = (date) => {
  return format(date, 'EEEE, MMMM d yyyy');
};

export const getFormatedSingleDate = (date) => {
  return format(date, 'MMMM d, yyyy');
};

export const getFormatedDaysRange = (startDate, endDate) => {
  if (startDate.getYear() === endDate.getYear()) {
    return `${format(startDate, 'MMMM d')} - ${format(
      endDate,
      'MMMM d, yyyy',
    )}`;
  }
  return `${format(startDate, 'MMMM d, yyyy')} - ${format(
    endDate,
    'MMMM d, yyyy',
  )}`;
};

export const getDayName = (date) => {
  return format(date, 'EEEE');
};

export const getTimeZoneAbbreviation = (date) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return formatToTimeZone(date, 'z', { timeZone });
};

export const isSameDay = (startDate, endDate) => {
  return (
    startDate.getDay() === endDate.getDay() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear()
  );
};

export const getTimeZonesList = () => {
  const tzList = getTimeZones({ includeUtc: true });
  const naTimezones = tzList.filter((x) => x.continentCode === 'NA');

  if (tzCache.length === 0) {
    tzCache = naTimezones.map((x) => {
      const offsets = getOffSets(x.name);

      const res = {
        abbreviation: offsets.abbrev,
        displayName: `${x.alternativeName} - ${x.mainCities[0]}`,
        ianaName: x.name,
        offset: offsets.offSet,
      };

      return res;
    });
  }

  return tzCache;
};

export const getCurrentTimeZone = () => {
  const timeZones = getTimeZonesList();
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneInfo = timeZones.find((x) => x.ianaName === currentTimeZone);
  if (timeZoneInfo) return timeZoneInfo;
  return timeZones.find((x) => x.ianaName === 'America/Chicago'); // fallback if user is in an unknown timezone
};
