export default function styles(theme) {
  return {
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    header: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    paper: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        minHeight: 600,
        maxWidth: 940,
      },
    },
    description: {
      color: '#5E5E5E',
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(2),
      },
    },
    dropdown: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
      },
    },
  };
}
