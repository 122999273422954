import { useState } from 'react';
import { objectOf, string } from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as GroupPrivate } from '../../../assets/groupPrivate.svg';
import { ReactComponent as GroupPublic } from '../../../assets/groupPublic.svg';
import Page from '../Page';
import PageTitle from '../PageTitle';
import Card, {
  CardTitle,
  CardList,
  CardListItem,
  CardMedia,
  CardMediaImage,
  CardMediaContent,
  CardText,
  CardDescription,
  CardDisclosure,
} from '../Card';
import { useGetMobilePageGroup, useGetPage } from '../../../utils/hooks';
import { stringToHTML } from '../../../utils/text/transformText';
import {
  GROUP_ACCESS,
  CHANNEL_TYPE,
  PAGE_ACTIVE,
} from '../../../globals/constants';

import styles from './styles';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

export function VancoMobileGroup({ classes }) {
  const { t } = useTranslation();
  usePageTitle(t('vanco.mobile.home.groups.view'));
  const { id } = useParams();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const displayPopover = Boolean(anchorEl);
  const { page } = useGetPage(CHANNEL_TYPE.MOBILE, PAGE_ACTIVE);
  const { group, isLoading } = useGetMobilePageGroup(id);
  const onGoBack = () => history.push('/mobile/groups');

  const goToUsePage = (userId) => () =>
    history.push(`/mobile/user/${userId}`, {
      prevPath: `/mobile/groups/${id}`,
    });

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Page variant="cardContainer">
      <PageTitle
        onGoBack={onGoBack}
        isLoading={isLoading}
        className={classes.title}
        readOnly={!page.hasCommunityFeatures}
      >
        {group?.name}
      </PageTitle>
      {/* Details */}
      <Card isLoading={isLoading}>
        <CardTitle>{t('vanco.mobile.groups.details')}</CardTitle>
        <CardMedia>
          <CardMediaImage
            src={group?.avatar}
            alt={
              group?.avatar
                ? group?.name
                : (group?.name || '').replace(/\s/g, '')
            }
          />
          <CardMediaContent>
            <CardText variant="title">{group?.name}</CardText>
            <CardText
              spinnerWidth={100}
              aria-owns={displayPopover ? 'group-access-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              className={classes.groupAccess}
            >
              {group?.access === GROUP_ACCESS.OPEN ? (
                <>
                  <GroupPublic />
                  {t('vanco.mobile.groups.open')}
                </>
              ) : (
                <>
                  <GroupPrivate />
                  {t('vanco.mobile.groups.private')}
                </>
              )}
            </CardText>
          </CardMediaContent>
        </CardMedia>
        <CardDescription>{stringToHTML(group?.description)}</CardDescription>
        <CardDisclosure
          title={t('vanco.mobile.groups.disclosureTitle')}
          message={t('vanco.mobile.groups.disclosureMessage')}
        />
      </Card>
      {/* Creator */}
      <Card isLoading={isLoading} className={classes.cardList}>
        <CardTitle>{t('vanco.mobile.groups.creator')}</CardTitle>
        <CardList>
          <CardListItem disabled>{group?.createdBy?.name}</CardListItem>
        </CardList>
      </Card>
      {/* Members */}
      <Card isLoading={isLoading} className={classes.cardList}>
        <CardTitle>{t('vanco.mobile.groups.members')}</CardTitle>
        <CardList>
          {(group?.members || []).length > 0 ? (
            group.members.map((m) => (
              <CardListItem onClick={goToUsePage(m.id)} key={m.id}>
                {(m.name || '').trim() || m.email}
              </CardListItem>
            ))
          ) : (
            <CardListItem disabled className={classes.itemNoMembers}>
              {t('vanco.mobile.groups.membersEmpty')}
            </CardListItem>
          )}
        </CardList>
      </Card>
      <Popover
        id="group-access-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverPaper,
        }}
        open={displayPopover && !isLoading}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography component="span" className={classes.popoverText}>
          {group?.access === GROUP_ACCESS.OPEN
            ? t('vanco.mobile.groups.openMessage')
            : t('vanco.mobile.groups.privateMessage')}
        </Typography>
      </Popover>
    </Page>
  );
}

VancoMobileGroup.propTypes = {
  classes: objectOf(string).isRequired,
};

export default withStyles(styles)(VancoMobileGroup);
