import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import UnarchiveIcon from '@material-ui/icons/Replay';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { useTranslation } from 'react-i18next';

import styles from './styles';

function UnarchiveSection({ classes, unarchiveTile, variant }) {
  const isWide = variant === 'wide';
  const { t } = useTranslation();

  return (
    <div className={`${classes.container} ${variant}`}>
      <div className={classes.headingContainer}>
        <InfoIcon className={classes.infoIcon} />
        <Typography variant="body2" className={classes.titleText}>
          {`${t('tile.unarchiveSection.title')}${isWide ? '.' : ''}`}
        </Typography>
      </div>
      <Typography className={`${classes.descriptionText} ${variant}`}>
        {isWide
          ? t('tile.unarchiveSection.shortDescription')
          : t('tile.unarchiveSection.longDescription')}
      </Typography>
      <Button
        size="medium"
        className={`${classes.button} ${variant}`}
        variant="outlined"
        onClick={unarchiveTile}
      >
        <UnarchiveIcon className={classes.buttonIcon} />
        {t('tile.unarchiveSection.unarchive')}
      </Button>
    </div>
  );
}

UnarchiveSection.propTypes = {
  classes: PropTypes.object.isRequired,
  unarchiveTile: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

UnarchiveSection.defaultProps = {
  variant: 'normal',
};

export default withStyles(styles)(UnarchiveSection);
