import { useMemo } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { objectOf, string, node, bool } from 'prop-types';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableHeadCell from './TableHeadCell';
import TableRow from './TableRow';
import TableCell from './TableCell';
import TableContext from './context';

import styles from './styles';

export function Table({ classes, disabled, children, ...rest }) {
  const contextProviderProps = useMemo(() => ({classes, disabled}), [classes, disabled]);
  return (
    <MaterialTable {...rest} className={classes.table}>
      <TableContext.Provider value={contextProviderProps}>
        {children}
      </TableContext.Provider>
    </MaterialTable>
  );
}

Table.TableBody = TableBody;
Table.TableHead = TableHead;
Table.TableHeadCell = TableHeadCell;
Table.TableRow = TableRow;
Table.TableCell = TableCell;

Table.propTypes = {
  classes: objectOf(string).isRequired,
  children: node.isRequired,
  disabled: bool,
};

Table.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(Table);
