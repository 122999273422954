import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from 'utils/hooks';
import usePermissionsRouter from 'utils/hooks/usePermissionsRouter';

function PermissionsRouter() {
  const { redirectRoute } = usePermissionsRouter();
  const { isLoggedIn } = useUser();
  const history = useHistory();
  useEffect(() => {
    const ignoreRedirection = !redirectRoute || !isLoggedIn;
    if (ignoreRedirection) {
      return;
    }
    history.push(redirectRoute);
  }, [redirectRoute, history, isLoggedIn]);
  return null;
}

export default PermissionsRouter;
