import { connect } from 'react-redux';

import StreamSiteLayout from './StreamSiteLayout';
import { createPage, savePage } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    pages: state.pages.pages,
    isLoggedIn: state.user.isLoggedIn,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, { createPage, savePage })(
  StreamSiteLayout,
);
