import { createTheme } from '@material-ui/core/styles';
import palette from '../palette';
import zIndex from '../zIndex';
import typography from '../typography';

const shadows = new Array(24).fill('0 5px 10px 0 rgba(0, 0, 0, 0.2)');
const defaultTheme = createTheme({});

export default createTheme({
  palette,
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    useNextVariants: true,
    fontFamily: ['"Muli"', 'sans-serif'].join(', '),
    button: typography.button,
  },
  zIndex,
  shadows: ['none', ...shadows],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1870,
    },
  },
  overrides: {
    MuiTypography: typography,
    MuiButton: {
      root: {
        ...typography.button,
        borderRadius: 10,
        [defaultTheme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      sizeLarge: {
        ...typography.button,
        padding: '13px 30px 13px 30px',
      },
      contained: {
        boxShadow: 'none',
        padding: '9px 16px',
      },
      outlined: {
        padding: '9px 16px',
      },
      label: {
        textTransform: 'none',
      },
    },
    MuiIconButton: {
      root: {
        color: palette.icons,
      },
    },
    MuiTab: {
      root: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 25,
        paddingRight: 25,
        minHeight: 0,
      },
    },
    MuiFormHelperText: {
      root: {
        maxWidth: 350,
        marginBottom: 14,
        marginTop: 14,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 13,
        lineHeight: 'normal',
        minHeight: 0,
      },
    },
    MuiPaper: {},
    MuiTableCell: {
      body: {},
      head: {},
    },

    // Fixes round corners on IE 11
    MuiPrivateNotchedOutline: {
      root: {
        top: 0,
      },
      legend: {
        float: 'left',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#F6F6F6',
        },
      },
    },
  },
});
