import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { handleException, setSuccess } from '../../../store/alerts/actions';
import VancoMobileUsersService from '../../../api/VancoMobileUsersService';

async function updateUser({ user }) {
  const response = await VancoMobileUsersService.updateUser(user);
  return response.data.data;
}

export function useUpdateMobileUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const queryName = 'mobile-users';
  let updateAction;

  return useMutation(updateUser, {
    onMutate: ({ user }) => {
      updateAction = user.updateAction;
      queryClient.cancelQueries([queryName, user.id]);
      const prevUser = queryClient.getQueryData([queryName, user.id]);
      queryClient.setQueryData([queryName, user.id], user);
      return () => queryClient.setQueryData([queryName, user.id], prevUser);
    },
    onSuccess: user => {
      dispatch(
        setSuccess(
          t(`vanco.mobile.appUsers.${updateAction}.saved`, {
            userName: user.name,
          }),
        ),
      );

      queryClient.invalidateQueries(queryName);
    },
    onError: (error, _, rollback) => {
      dispatch(handleException(error));
      rollback();
    },
  });
}

export default useUpdateMobileUser;
