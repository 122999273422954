import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BUTTON_TEXT_OPTIONS_KIOSK } from '../../../../globals/constants';

const DEFAULT_INITIAL_VALUES = {
  welcomeMessage: '',
  buttonLabel: '',
  showWelcomeMessage: false,
  isCustomButtonLabel: true,
};

export const KioskFormKeys = {
  WELCOME_MESSAGE: 'welcomeMessage',
  BUTTON_LABEL: 'buttonLabel',
  SHOW_MESSAGE: 'showWelcomeMessage',
  CUSTOM_LABEL: 'isCustomButtonLabel',
};

export const CUSTOM_LABEL_OPTION = 'Custom button label';

const createSchema = () => {
  return Yup.object().shape({
    welcomeMessage: Yup.string().nullable(true).max(30).optional(),
    buttonLabel: Yup.string().nullable(true).max(15).optional(),
    showWelcomeMessage: Yup.bool(),
    isCustomButtonLabel: Yup.bool(),
  });
};

export const useKioskForm = (initialValues) => {
  const values = initialValues
    ? {
        welcomeMessage: initialValues.welcomeMessage,
        showWelcomeMessage: Boolean(initialValues.welcomeMessage),
        buttonLabel: initialValues.buttonLabel,
        isCustomButtonLabel: !BUTTON_TEXT_OPTIONS_KIOSK.includes(
          initialValues.buttonLabel,
        ),
      }
    : DEFAULT_INITIAL_VALUES;
  const formik = useFormik({
    initialValues: values,
    validationSchema: createSchema(),
    enableReinitialize: true,
  });
  return formik;
};
