import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import KioskService from '../../../api/KioskService';
import { setKioskError, setKiosk } from '../../../store/kiosk/actions';

export const GET_KIOSK_QUERY = 'getKiosk';

function useGetKiosk() {
  const orgUri = useSelector((state) => {
    return state.organizations.currentOrganization.id;
  });
  const dispatch = useDispatch();
  const { data, isLoading, error } = useQuery({
    retry: false,
    queryKey: GET_KIOSK_QUERY,
    staleTime: Infinity,
    queryFn: () => KioskService.getKiosk(orgUri),
    enabled: Boolean(orgUri),
    onSuccess: (response) => {
      const kiosk = response?.data?.data;
      if (!kiosk) {
        dispatch(setKioskError());
        return;
      }
      dispatch(setKiosk());
    },
    onError: () => {
      dispatch(setKioskError());
    },
  });

  return {
    kiosk: data?.data?.data,
    hasKiosk: !!data?.data?.data,
    error,
    loadingKiosk: isLoading,
  };
}

export default useGetKiosk;
