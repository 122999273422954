import moment from 'moment';
import {
  zonedTimeToUtc,
  utcToZonedTime,
  format,
  getTimezoneOffset,
} from 'date-fns-tz';
import { parseISO } from 'date-fns';

const typeToPos = {
  year: 0,
  month: 1,
  day: 2,
  hour: 3,
  minute: 4,
  second: 5,
};

// YYYY-MM-DD H:mm:ss
const matchDateTimeRegex = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/;

function partsOffset(dtf, date) {
  const formatted = dtf.formatToParts(date);
  const filled = [];
  for (let i = 0; i < formatted.length; i += 1) {
    const pos = typeToPos[formatted[i].type];
    if (pos >= 0) {
      filled[pos] = parseInt(formatted[i].value, 10);
    }
  }
  return filled;
}

function hackyOffset(dtf, date) {
  const formatted = dtf.format(date).replace(/\u200E/g, '');
  const parsed = /(\d+)\/(\d+)\/(\d+),? (\d+):(\d+):(\d+)/.exec(formatted);
  // var [, fMonth, fDay, fYear, fHour, fMinute, fSecond] = parsed
  // return [fYear, fMonth, fDay, fHour, fMinute, fSecond]
  return [parsed[3], parsed[1], parsed[2], parsed[4], parsed[5], parsed[6]];
}

// Get a cached Intl.DateTimeFormat instance for the IANA `timeZone`. This can be used
// to get deterministic local date/time output according to the `en-US` locale which
// can be used to extract local time parts as necessary.
const dtfCache = {};

function getDateTimeFormat(timeZone) {
  if (!dtfCache[timeZone]) {
    // New browsers use `hourCycle`, IE and Chrome <73 does not support it and uses `hour12`
    const testDateFormatted = new Intl.DateTimeFormat('en-US', {
      hour12: false,
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(new Date('2014-06-25T04:00:00.123Z'));

    const hourCycleSupported =
      testDateFormatted === '06/25/2014, 00:00:00' ||
      testDateFormatted === '06/25/2014 00:00:00';
    dtfCache[timeZone] = hourCycleSupported
      ? new Intl.DateTimeFormat('en-US', {
          hour12: false,
          timeZone,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
      : new Intl.DateTimeFormat('en-US', {
          hourCycle: 'h23',
          timeZone,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
  }
  return dtfCache[timeZone];
}

function tzTokenizeDate(date, timeZone) {
  const dtf = getDateTimeFormat(timeZone);

  return dtf.formatToParts ? partsOffset(dtf, date) : hackyOffset(dtf, date);
}

export const calcOffset = (date, timezoneString) => {
  const tokens = tzTokenizeDate(date, timezoneString);

  const asUTC = Date.UTC(
    tokens[0],
    tokens[1] - 1,
    tokens[2],
    tokens[3] % 24,
    tokens[4],
    tokens[5],
  );
  let asTS = date.getTime();
  const over = asTS % 1000;
  asTS -= over >= 0 ? over : 1000 + over;
  return asUTC - asTS;
};

function tzParseTimezone(timezoneString, date) {
  const parsedDate = new Date(date || Date.now());
  return calcOffset(parsedDate, timezoneString);
}

export const ConvertZonedTimeToUtc = (date, timezoneString) => {
  return zonedTimeToUtc(date, timezoneString);
};

export const ConvertUtcToZonedTime = (date, timezoneString) => {
  return utcToZonedTime(date, timezoneString);
};

// // expected format yyyy-MM-dd HH:mm:ss zzz
export const dateToParts = (dateString) => {
  // 2022-02-04 05:01:15 HAST GMT-10
  const matches = dateString.match(
    /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})(.*)(gmt-?\d{1,2})/i,
  );

  try {
    return {
      year: matches[1],
      month: matches[2],
      day: matches[3],
      date: `${matches[1]}-${matches[2]}-${matches[3]}`,
      hours: matches[4],
      minutes: matches[5],
      seconds: matches[6],
      time: `${matches[4]}-${matches[5]}-${matches[6]}`,
      timezone: matches[7].trim(),
      offset: matches[8],
    };
  } catch (err) {
    return {};
  }
};

export const processDate = (dateStr, timeZone) => {
  const date = parseISO(dateStr);
  const offset = tzParseTimezone(timeZone, date) / (60 * 60 * 1000);
  const formattedDate = `${format(date, 'yyyy-MM-dd HH:mm:ss zzz', {
    timeZone,
  })} GMT${offset}`;
  return formattedDate;
};

export const getOffSets = (timezone) => {
  const currentYear = new Date().getFullYear();
  const janDate = new Date(currentYear, 0, 1);
  const julDate = new Date(currentYear, 6, 1);
  const janOffsetMilli = getTimezoneOffset(timezone, janDate);
  const julOffsetMilli = getTimezoneOffset(timezone, julDate);

  const observesDst = janOffsetMilli !== julOffsetMilli;

  const janConversion = processDate(janDate.toISOString(), timezone);
  const julyConversion = processDate(julDate.toISOString(), timezone);

  const janParts = dateToParts(janConversion);
  const julyParts = dateToParts(julyConversion);

  const abbrev = observesDst
    ? `${janParts.timezone}/${julyParts.timezone}`
    : `${janParts.timezone}`;

  let offSet = observesDst
    ? `${janParts.offset}/${julyParts.offset}`
    : `${janParts.offset}`;

  if (!offSet.match(/gmt/gi)) {
    offSet = `GMT-${janParts.offset}`;
  }

  return { observesDst: true, abbrev, offSet };
};

// MM/DD/YYYY
export const formatDate = (date) => {
  const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
  const matches = date.match(matchDateTimeRegex);
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
    new Date(matches[1]),
  );
  return formattedDate;
};

export const formatTime = (dateTime, ianaName) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const matches = dateTime.match(matchDateTimeRegex);

  const zonedTime = utcToZonedTime(
    new Date(matches[1]).toISOString(),
    ianaName,
  );

  const formattedTime = zonedTime.toLocaleString('en-US', options);

  return formattedTime;
};

// date is a moment obj
// time is string
export const GetDateForBackend = (date, time, ianaName) => {
  const formattedDate = date.format('YYYY-MM-DD');
  const concatDateTime = `${formattedDate} ${time}`;
  const curDate = new Date(concatDateTime);

  const convertedUtcDate2 = zonedTimeToUtc(curDate, ianaName);

  return convertedUtcDate2;
};

export const GetDateForFrontEnd = (date, ianaName) => {
  // const zonedTime = utcToZonedTime(new Date(date).toISOString(), ianaName);

  const proccessedDate = processDate(new Date(date).toISOString(), ianaName);

  return proccessedDate;
};

export const GetDateForFrontEndTest = (date, ianaName) => {
  const dat = new Date(date);
  const iso = dat.toISOString();

  const proccessedDate = processDate(iso, ianaName);

  return proccessedDate;
};

export const getDisableDate = (date, fallback = undefined) =>
  date ? moment(date).startOf('day') : fallback;

export const isSameOrBefore = (date) => {
  if (!date) return false;

  const localDate = moment(date).startOf('day');
  const today = moment().startOf('day');

  const isSame = localDate.isSameOrBefore(today, 'day');
  return isSame;
};

export default processDate;
