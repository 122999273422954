import { Button, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import { ReactComponent as RefreshIcon } from '../assets/refresh.svg';

function ReloadFIP({ classes }) {
  const { t } = useTranslation();
  const reloadPage = () => {
    window.location.reload();
  };
  return (
    <div className={classes.container}>
      <span className={classes.iconWrapper}>
        <RefreshIcon />
      </span>
      <Typography className={classes.pickerText}>
        {t('ministry.platform.import.refresh.description')}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        endIcon={<RefreshIcon className={classes.buttonIcon} />}
        onClick={reloadPage}
      >
        {t('ministry.platform.import.refresh.button')}
      </Button>
    </div>
  );
}

ReloadFIP.propTypes = {
  classes: PropTypes.object.isRequired,
};

// ReloadFIP.defaultProps = {

// }
export default withStyles(styles)(ReloadFIP);
