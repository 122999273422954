import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';
import { PageTitle } from '../../../common';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import { PAGE_ACTIVE } from '../../../../globals/constants';
import { getPhone } from '../../../../utils/page';

export function Review({
  history,
  page,
  goToPrev,
  goToNext,
  classes,
  savePage,
  newPage,
  resetNewPage,
  loadingSave,
}) {
  const { t } = useTranslation();
  const getAddress = () => {
    const { address, address2, city, state, zip } = page;
    if (!address || !address.length) return '';
    if (address2) {
      return `${address}
    ${address2}
    ${city}, ${state} ${zip}`;
    }

    return `${address}
    ${city}, ${state} ${zip}`;
  };

  useEffect(() => {
    if (goToNext && newPage.id) {
      goToNext();
      resetNewPage();
    }
  }, [newPage, goToNext, resetNewPage]);

  const handleSubmit = () => {
    savePage({
      ...page,
      status: PAGE_ACTIVE,
    });
  };

  function getNotProvided() {
    return (
      <Typography className={classes.notProvided}>
        {t('wizard.pageReview.notProvided')}
      </Typography>
    );
  }

  return (
    <div className={classes.container}>
      <PageTitle className={classes.title}>
        {t('wizard.pageReview.title')}
      </PageTitle>
      <section className={classes.noteSection}>
        <Typography variant="body2">{t('wizard.pageReview.note1')}</Typography>
        <Typography>{t('wizard.pageReview.note2')}</Typography>
      </section>

      {/* Basic Info */}
      <Grid container className={classes.grid}>
        <Grid item md={11} sm={11} xs={11}>
          <Typography className={classes.gridTitle}>
            {t('wizard.pageReview.basicInfo')}
          </Typography>
        </Grid>
        <Grid item md={1} sm={1} xs={1}>
          <Button
            color="primary"
            onClick={() => history.push(`/wizard/${page.id}/page-info`)}
          >
            {t('wizard.pageReview.edit')}
          </Button>
        </Grid>
        <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
          <Typography variant="body2" className={classes.body2}>
            {t('wizard.pageReview.siteName')}
          </Typography>
        </Grid>
        <Grid item md={8} sm={12} xs={12} className={classes.gridRow}>
          <Typography className={classes.body}>{page.pageName}</Typography>
        </Grid>
        <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
          <Typography variant="body2" className={classes.body2}>
            {t('wizard.pageReview.phone')}
          </Typography>
        </Grid>
        <Grid item md={8} sm={12} xs={12} className={classes.gridRow}>
          {page.phone && (
            <Typography className={classes.body}>{getPhone(page)}</Typography>
          )}
          {(!page.phone || page.phone.length < 1) && getNotProvided()}
        </Grid>
        <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
          <Typography variant="body2" className={classes.body2}>
            {t('wizard.pageReview.email')}
          </Typography>
        </Grid>
        <Grid item md={8} sm={12} xs={12} className={classes.gridRow}>
          {!!page.email.length && (
            <Typography className={classes.body}>{page.email}</Typography>
          )}
          {!page.email.length && getNotProvided()}
        </Grid>
        <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
          <Typography variant="body2" className={classes.body2}>
            {t('wizard.pageReview.address')}
          </Typography>
        </Grid>
        <Grid item md={8} sm={12} xs={12} className={classes.gridRow}>
          {!!getAddress().length && (
            <Typography className={classes.address}>{getAddress()}</Typography>
          )}
          {!getAddress().length && getNotProvided()}
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Typography variant="body2" className={classes.body2}>
            {t('wizard.pageReview.websiteUrl')}
          </Typography>
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          {!!page.homePage.length && (
            <Typography className={classes.body}>{page.homePage}</Typography>
          )}
          {!page.homePage.length && getNotProvided()}
        </Grid>
      </Grid>

      {/* Color */}
      <Grid container className={classes.grid}>
        <Grid item md={11} sm={11} xs={11}>
          <Typography className={classes.gridTitle}>
            {t('wizard.pageReview.color')}
          </Typography>
        </Grid>
        <Grid item md={1} sm={1} xs={1}>
          <Button
            color="primary"
            onClick={() => history.push(`/wizard/${page.id}/color-options`)}
          >
            {t('wizard.pageReview.edit')}
          </Button>
        </Grid>
        <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
          <Typography variant="body2" className={classes.body2}>
            {t('wizard.pageReview.color')}
          </Typography>
        </Grid>
        {page.primaryColor ? (
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            className={`${classes.gridRow} ${classes.primaryColorContainer}`}
          >
            <div
              className={classes.primaryColor}
              style={{ backgroundColor: page.primaryColor }}
              data-testid="review-primary-color"
            />
            <Typography className={classes.body}>
              {page.primaryColor}
            </Typography>
          </Grid>
        ) : (
          getNotProvided()
        )}
      </Grid>

      {/* Processing Fees */}
      <Grid container className={classes.grid}>
        <Grid item md={11} sm={11} xs={11}>
          <Typography className={classes.gridTitle}>
            {t('wizard.pageReview.processingFees')}
          </Typography>
        </Grid>
        <Grid item md={1} sm={1} xs={1}>
          <Button
            color="primary"
            onClick={() => history.push(`/wizard/${page.id}/donation-options`)}
          >
            {t('wizard.pageReview.edit')}
          </Button>
        </Grid>
        <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
          <Typography variant="body2" className={classes.body2}>
            {t('wizard.pageReview.acceptSupport')}
          </Typography>
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <Typography className={classes.body}>
            {page.enableProcessingFees ? t('app.yes') : t('app.no')}
          </Typography>
        </Grid>
        {page.enableProcessingFees && (
          <>
            <Grid item md={4} sm={12} xs={12} />
            <Grid item md={8} sm={12} xs={12}>
              <Typography>{`${page.creditPercentage}% for card transactions`}</Typography>
            </Grid>
            <Grid item md={4} sm={12} xs={12} />
            <Grid item md={8} sm={12} xs={12}>
              <Typography>{`${page.checkPercentage}% for bank transactions`}</Typography>
            </Grid>
          </>
        )}
      </Grid>
      {/* Social Sharing */}
      <Grid container className={classes.grid}>
        <Grid item md={11} sm={11} xs={11}>
          <Typography className={classes.gridTitle}>
            {t('wizard.nav.socialSharing')}
          </Typography>
        </Grid>
        <Grid item md={1} sm={1} xs={1}>
          <Button
            color="primary"
            onClick={
              () => history.push(`/wizard/${page.id}/social-sharing`)
              // eslint-disable-next-line react/jsx-curly-newline
            }
          >
            {t('wizard.pageReview.edit')}
          </Button>
        </Grid>
        <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
          <Typography variant="body2" className={classes.body2}>
            {t('wizard.nav.socialSharing')}
          </Typography>
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <Typography className={classes.body}>
            {page.allowSocialSharing
              ? t('wizard.socialSharing.enable')
              : t('wizard.socialSharing.disable')}
          </Typography>
        </Grid>
      </Grid>

      {!loadingSave && goToPrev && (
        <WizardButtons
          isFinished={false}
          dirty={false}
          goToNext={handleSubmit}
          goToPrev={goToPrev}
          undo={() => {}}
        />
      )}
      {loadingSave && <CircularProgress color="primary" />}
    </div>
  );
}

Review.propTypes = {
  classes: PropTypes.object.isRequired,
  goToNext: PropTypes.func.isRequired,
  goToPrev: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  savePage: PropTypes.func.isRequired,
  newPage: PropTypes.object.isRequired,
  resetNewPage: PropTypes.func.isRequired,
  loadingSave: PropTypes.bool.isRequired,
};

export default withStyles(styles)(withRouter(Review));
