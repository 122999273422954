import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import { Page } from '../../common';
import useAzureLogin from '../../../utils/hooks/useAzureLogin';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

// TODO: Remove !important once IDS iframe patches 1px width issue
const useStyles = makeStyles(() => ({
  container: {
    '&>iframe': {
      width: '100% !important',
    },
  },
}));

export default function Login() {
  const iframeRef = useRef(null);
  const iframeId = 'ids-login';
  const styles = useStyles();
  const { t } = useTranslation();
  usePageTitle(t('titles.login'));
  const isUserLoggedIn = useSelector(({ user }) => user.isLoggedIn);

  // Handles the logic to determine the type of login (IDS or AADB2C).
  useAzureLogin(iframeRef);

  if (isUserLoggedIn) return null;

  return (
    <Page>
      <div
        id={iframeId}
        data-testid={iframeId}
        ref={iframeRef}
        className={styles.container}
      />
    </Page>
  );
}
