import * as Yup from 'yup';

import { validations } from '../../../utils';
import i18n from '../../../utils/i18n';
import { CHANNEL_TYPE } from '../../../globals/constants';

export const maxLengths = {
  pageName: 55,
  address: 30,
  address2: 30,
  city: 25,
  state: 2,
  zip: 5,
  email: 45,
  phone: 10,
};
export const minLengths = {
  state: 2,
};

export const createSchema = ({ page, pages = [] }) => {
  const bannedNames = pages
    .filter((p) => p.channelId === CHANNEL_TYPE.ONLINE && p.id !== page?.id)
    .map((p) => p.pageName);
  const schema = Yup.object().shape({
    provideAddress: Yup.boolean().nullable(),
    provideEmail: Yup.boolean().nullable(),
    providePhone: Yup.boolean().nullable(),
    provideContact: Yup.boolean().test(
      'oneOfRequired',
      i18n.t('validation.oneOfEmailPhoneAddress'),
      /* eslint-disable-next-line func-names */
      function () {
        const { providePhone, provideAddress, provideEmail } = this.parent;
        return provideAddress || provideEmail || providePhone;
      },
    ),
    phone: Yup.string()
      .nullable()
      .when('providePhone', {
        is: true,
        then: (s) =>
          s.matches(validations.phone, i18n.t('validation.phone')).required(
            i18n.t('validation.required', {
              requiredNoun: i18n
                .t('headerManagement.phone')
                .toLocaleLowerCase(),
            }),
          ),
      }),
    email: Yup.string()
      .nullable()
      .when('provideEmail', {
        is: true,
        then: (s) =>
          s.email(i18n.t('validation.email')).required(
            i18n.t('validation.required', {
              requiredNoun: i18n
                .t('headerManagement.emailAddress')
                .toLocaleLowerCase(),
            }),
          ),
      }),
    homePage: Yup.string().matches(
      validations.urlRegex,
      i18n.t('validation.url', {
        requiredNoun: i18n.t('headerManagement.homePage'),
      }),
    ),
  });

  return schema
    .concat(validations.addressValidation(i18n))
    .concat(validations.pageNameValidation({ i18n, bannedNames }));
};
