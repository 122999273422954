import { shape, objectOf, string, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import RemoveRedEyeOutlined from '@material-ui/icons/RemoveRedEyeOutlined';
import LaunchIcon from '@material-ui/icons/Launch';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import CodeIcon from '@material-ui/icons/Code';
import { VisibilityOff } from '@material-ui/icons';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { ReactComponent as EditIcon } from '../../../../assets/edit-20px.svg';
import { isArchived, isActive, isPending } from '../../../../utils/page';
import { PopoverMenu, TableRow, TableCell, Badge } from '../../../common';
import pageNavConfig from '../../../navigation/WizardLayout/stream.page.config';
import styles from './styles';
import {
  PAGE_ARCHIVED,
  PAGE_ACTIVE,
  VIDEO_ROUTES,
  VIDEO_SETTINGS_TABS,
} from '../../../../globals/constants';
import { copyText, validations } from '../../../../utils';
import { formatStreamInfo } from '../../../../utils/stream';
import { useUser } from '../../../../utils/hooks';

export function Page({
  classes,
  onEmbedPage,
  onGenerateQRCode,
  page,
  setSuccess,
  onSavePage,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const verbiage = '.off';

  const { organizationId: userOrganizationId = '' } = useUser();

  const pageIsArchived = page.status.toLowerCase() === 'archived';
  const pageStatus = pageIsArchived
    ? t(`stream.status.unpublished${verbiage}`)
    : t(`stream.status.published${verbiage}`);
  const dataTestId = { 'data-testid': `more-menu-${page.id}` };

  const streamLink = `${
    window.env.REACT_APP_STREAM_URL
  }/watch/${formatStreamInfo(page.streamData?.streamerId)}/${formatStreamInfo(
    page.streamData?.streamId,
  )}`;

  const formatTime = (time) => {
    return time || '';
  };

  const formatDate = (date) => {
    if (!date) return ' ';
    return date.format('MMM D');
  };

  const onEditPage = () => {
    let editPath;
    if (isPending(page))
      editPath = pageNavConfig(page)
        .find((step) => step.next)
        .path.replace(':pageId', page.id);
    else
      editPath = `${VIDEO_ROUTES.videoSettings}/${VIDEO_SETTINGS_TABS.liveStreaming}`;

    history.push(editPath);
  };

  const handleSavePageStatus = (status) => {
    if (![PAGE_ACTIVE, PAGE_ARCHIVED].includes(status)) return;

    const pageData = {
      ...page,
      organizationId: userOrganizationId ?? '',
      status,
    };

    onSavePage(pageData);
  };

  const onCopy = () => {
    copyText(streamLink);
    setSuccess(t('streamSettings.linkCopied'));
  };

  const onEmbed = () => {
    onEmbedPage(page);
  };

  const onViewStream = () => {
    if (validations.urlRegex.test(streamLink))
      window.open(streamLink, '_blank');
  };

  const onViewQRCode = () => {
    onGenerateQRCode(page);
  };

  const isPageActive = isActive(page);

  return (
    <TableRow onClick={onEditPage} data-testid={page.id}>
      <TableCell className={!page.streamData?.title ? classes.untitled : ''}>
        <Typography component="span">
          {page.streamData?.title || 'Untitled'}
        </Typography>
      </TableCell>
      <TableCell className={!page.streamData?.title ? classes.untitled : ''}>
        <Typography component="span">
          {formatDate(page?.streamData?.startDate) || ''}
        </Typography>
      </TableCell>
      <TableCell className={!page.streamData?.title ? classes.untitled : ''}>
        <Typography component="span">
          {`${formatTime(page?.streamData?.startTime)} - ${formatTime(
            page?.streamData?.endTime,
          )} ${page?.streamData?.timeZone?.abbreviation}` || ''}
        </Typography>
      </TableCell>
      <TableCell className={classes.statusColumn}>
        <Badge variant={pageIsArchived ? 'archived' : 'active'}>
          {pageStatus}
        </Badge>
      </TableCell>
      <TableCell className={classes.moreMenu} menu>
        <PopoverMenu buttonProps={dataTestId}>
          <PopoverMenu.Item onSelect={onEditPage}>
            <EditIcon />
            {t('stream.edit')}
          </PopoverMenu.Item>
          {isPageActive && (
            <PopoverMenu.Item onSelect={onViewStream}>
              <LaunchIcon />
              {t('stream.viewStream')}
            </PopoverMenu.Item>
          )}
          <PopoverMenu.Divider />

          {isArchived(page) && (
            <PopoverMenu.Item
              onSelect={() => handleSavePageStatus(PAGE_ACTIVE)}
            >
              <RemoveRedEyeOutlined />
              {t(`site.publish${verbiage}`)}
            </PopoverMenu.Item>
          )}
          {isPageActive && (
            <>
              <div>
                <PopoverMenu.Item onSelect={onCopy}>
                  <InsertLinkIcon />
                  <Typography>{t('stream.copyStreamLink')}</Typography>
                </PopoverMenu.Item>
                <PopoverMenu.Item onSelect={onEmbed}>
                  <CodeIcon />
                  <Typography>{t('stream.embedStream')}</Typography>
                </PopoverMenu.Item>
                <PopoverMenu.Item onSelect={onViewQRCode}>
                  <QrCodeIcon />
                  <Typography>{t('site.qrCode')}</Typography>
                </PopoverMenu.Item>
                <PopoverMenu.Divider />
              </div>
              <PopoverMenu.Item
                onSelect={() => handleSavePageStatus(PAGE_ARCHIVED)}
              >
                <VisibilityOff />
                {t(`site.unpublish${verbiage}`)}
              </PopoverMenu.Item>
            </>
          )}
        </PopoverMenu>
      </TableCell>
    </TableRow>
  );
}

Page.propTypes = {
  classes: objectOf(string).isRequired,
  onEmbedPage: func.isRequired,
  onGenerateQRCode: func,
  page: shape({}).isRequired,
  onSavePage: func.isRequired,
  setSuccess: func.isRequired,
};

Page.defaultProps = {
  onGenerateQRCode: null,
};

export default withStyles(styles)(Page);
