import BaseService from '../BaseService';
import { CHANNEL_TYPE } from '../../globals/constants';

class PageService extends BaseService {
  // eslint-disable-next-line class-methods-use-this
  getPathSection = (page) => {
    switch (page.channelId) {
      case CHANNEL_TYPE.ONLINE:
        return 'locations';
      case CHANNEL_TYPE.MOBILE: // TODO: remove with ChannelManagement FF cleanup mobile now PUT only
        return 'locations/mobile';
      case CHANNEL_TYPE.STREAM:
        return 'locations/streaming';
      default:
        throw new Error(`ChannelType not supported: ${page.channelId}`);
    }
  };

  /**
   * Get all pages / locations for a current organization
   */
  getPages() {
    return this.http.get('locations');
  }

  /**
   * Fetch a page / location object
   * @param  {Object} page
   */
  getPage(page) {
    return this.http.get(`locations/${page.id}`);
  }

  /**
   * Save page / location object
   * @param  {Object} page
   */
  savePage(page) {
    if (page.channelId === CHANNEL_TYPE.STREAM)
      return this.http.put(`locations/streaming/${page.id}`, {
        data: page,
      });

    return this.http.put(`locations/${page.id}`, {
      data: page,
    });
  }

  /**
   * Create a new page / location object
   * @param  {Object} page - Typically a blank default page / location object
   * @param  {Object} organization - Typically the user's current organization
   */
  createPage(page, organization) {
    const data = { ...page, organizationId: organization.id };
    return this.http.post(this.getPathSection(page), { data });
  }

  /**
   * Delete a page / location object
   * @param  {Object} page
   */
  deletePage(page) {
    return this.http.delete(`locations/${page.id}`);
  }

  /**
   * Update the logo for a page
   * @param {Object} image
   */
  updateLocationLogoImage(image) {
    return this.http.post(`locations/${image.id}/images`, { data: image });
  }

  /**
   * Update the banner for a page
   * @param {Object} image
   */
  updateLocationBannerImage(image) {
    return this.http.post(`locations/${image.id}/bannerimage`, { data: image });
  }

  /**
   * Page is deletable / location object
   * @param  {Object} page
   */
  isDeletable(page) {
    return this.http.get(`locations/${page.id}/deletable`);
  }

  /**
   * Get Streamer settings for the current organization
   */
  getStreamerSettings() {
    return this.http.get('locations/streamer');
  }

  /**
   * Create streamer settings for Vanco live page
   * @param  {Object} streamerSettings - streamer settings object
   * @param  {Object} organization - Typically the user's current organization
   */
  createStreamerSettings(streamerSettings, organization) {
    const data = { ...streamerSettings, organizationId: organization.id };
    return this.http.post('locations/streamer', { data });
  }

  /**
   * Update streamer settings for Vanco live page
   * @param  {Object} page
   */
  updateStreamerSettings(streamerSettings) {
    return this.http.put(`locations/streamer/${streamerSettings.streamerId}`, {
      data: streamerSettings,
    });
  }

  /**
   * Update address required field
   * @param  {Object} page
   */
  updateAddressRequired(locationId, required) {
    return this.http.put(`location/${locationId}/addressRequired/${required}`);
  }

  /**
   * Update location default view
   * @param {string} locationId
   * @param {Object} data
   */
  patchLocation(page, data) {
    return this.http.patch(`location/${page.id}`, data);
  }
}

export default new PageService();
