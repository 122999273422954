import { useEffect, useState } from 'react';
import PropTypes, { objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  CircularProgress,
  Divider,
  Typography,
  withStyles,
} from '@material-ui/core';
import Form from '../Form';
import PaymentForm from './Form';
import {
  useBankAccounts,
  useFunds,
  useCreateFunds,
} from '../../../utils/hooks';
import NewFundModal from '../TileFundConfiguration/NewFundModal';
import PageTitle from '../PageTitle';
import PageDescription from '../PageDescription';
import CustomFormik from '../CustomFormik';
import FundForm from '../TileFundConfiguration/FundForm';
import { defaultTile } from '../../../globals/defaults';
import { createSchema } from './validation';
import { maxLengths } from '../../../utils/validations';
import { mergeFundsWithBanks } from '../../../store/funds/helpers';
import { selectBackendToFrontendErrors } from '../../../store/funds/selectors';
import WizardButtons from '../../navigation/WizardLayout/WizardButtons';
import styles from './styles';
import MemoTextField from '../MemoTextField';
import {
  CUSTOM_LABEL,
  MEMO_LINE_PAYMENT_OPTIONS,
} from '../../../globals/constants';

export function TilePayment({
  classes,
  organizationId,
  pageTitleKey,
  tile = defaultTile,
  saveTile,
  loadingTile,
  loadingSave,
  goToNext,
  goToPrev,
  updateTile,
  setError,
  newTile,
  isFinished,
  features,
}) {
  const { data: banks, error: banksError } = useBankAccounts(organizationId);
  const {
    data: funds,
    error: fundsError,
    status: fundsStatus,
  } = useFunds(organizationId);
  const {
    createFund,
    newFund,
    loadingFundCreate,
    fundCreateStatus,
    createFundError,
  } = useCreateFunds();
  const [newFundOpen, setNewFundOpen] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const schema = createSchema(features);
  const loading = loadingTile || !tile;
  const fundsWithBanks = mergeFundsWithBanks(funds, banks);
  const [initialNewFund, setInitialNewFund] = useState({
    accountCode: '',
    name: '',
    bankAccount: '',
    fundRefId: '',
  });

  useEffect(() => {
    if (newTile.id && goToNext) {
      goToNext();
    }
  }, [newTile, goToNext]);

  useEffect(() => {
    if (fundCreateStatus === 'success') {
      setNewFundOpen(false);
    }
  }, [fundCreateStatus]);

  if (loading) {
    return null;
  }

  const onSubmit = async (values) => {
    const formTile = {
      ...tile,
      ...values,
    };
    await saveTile(formTile);
    queryClient.setQueryData(['tile', formTile.id], formTile);
  };

  const handleNewFund = () => {
    const firstBankAccount = banks && banks.length > 0 ? banks[0].id : '';
    setInitialNewFund({
      accountCode: '',
      name: '',
      bankAccount: firstBankAccount,
      fundRefId: '',
    });
    setNewFundOpen(true);
  };

  const closeNewFund = () => {
    setNewFundOpen(false);
  };

  const newFundSave = (setFieldValue) => (fund) => {
    createFund(
      { organizationId, fund },
      {
        onSuccess: (newCreatedFund) => {
          setFieldValue({ target: { name: 'fund', value: newCreatedFund } });
        },
      },
    );
  };

  function getInitialPaymentOptions() {
    return tile.paymentOptions
      ? { price: parseFloat(tile.paymentOptions.price, 10).toFixed(2) }
      : {};
  }

  return (
    <>
      <PageTitle
        variant={features.NewWizard ? 'small' : 'default'}
        className={features.NewWizard ? classes.titlePadding : ''}
      >
        {t(pageTitleKey)}
      </PageTitle>
      {features.NewWizard && <Divider className={classes.divider} />}
      <Typography className={classes.fundTitle} variant="body2">
        {t('gift.fund.title')}
      </Typography>
      <PageDescription>{t('fund.header.description')}</PageDescription>
      <CustomFormik
        onChange={updateTile}
        onErrorsChange={setError}
        initialValues={{
          fund: tile?.fund,
          paymentOptions: getInitialPaymentOptions(),
          textField:
            tile.textField === '' ||
            MEMO_LINE_PAYMENT_OPTIONS.includes(tile.textField)
              ? tile.textField
              : CUSTOM_LABEL,
          showTextField: tile.showTextField,
          responseRequired: tile.responseRequired,
          customTextField: tile.customTextField,
          isTextFieldResponseType: tile.isTextFieldResponseType,
          memoFieldResponses: tile.memoFieldResponses || [],
        }}
        onSubmit={onSubmit}
        render={(formikProps) => {
          const { dirty, handleSubmit, handleReset, setFieldValue } =
            formikProps;
          return (
            <Form className={classes.paymentForm}>
              <FundForm
                handleNewFund={handleNewFund}
                goToPrev={goToPrev}
                funds={fundsWithBanks || []}
                isFinished={isFinished}
                loading={fundsStatus === 'loading'}
                fundsError={!!fundsError}
                banksError={!!banksError}
                newFund={newFund}
                loadingSave={loadingSave}
                {...formikProps}
              />
              {features.NewWizard && <Divider className={classes.divider} />}
              <PaymentForm maxLengths={maxLengths} {...formikProps} />

              {features.NewWizard && <Divider className={classes.divider} />}
              <MemoTextField
                {...formikProps}
                memoLineOptions={MEMO_LINE_PAYMENT_OPTIONS}
                maxLengths={maxLengths}
              />

              {!loadingSave && goToPrev && (
                <WizardButtons
                  isFinished={isFinished}
                  dirty={dirty}
                  goToNext={handleSubmit}
                  goToPrev={goToPrev}
                  undo={handleReset}
                />
              )}
              {loadingSave && goToPrev && <CircularProgress color="primary" />}
              <NewFundModal
                open={newFundOpen}
                onClose={closeNewFund}
                onSave={newFundSave(setFieldValue)}
                loadingCreate={loadingFundCreate}
                banks={banks || []}
                initialNewFund={initialNewFund}
                backendErrors={selectBackendToFrontendErrors(
                  createFundError?.response?.data?.errors,
                )}
              />
            </Form>
          );
        }}
        validationSchema={schema}
        enableReinitialize={!!features.TileMemoDropdown} // Added FF validation in case it produces unexpected behavior
      />
    </>
  );
}

TilePayment.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  organizationId: PropTypes.string.isRequired,
  pageTitleKey: PropTypes.string,
  tile: PropTypes.object,
  loadingTile: PropTypes.bool.isRequired,
  saveTile: PropTypes.func.isRequired,
  goToNext: PropTypes.func,
  goToPrev: PropTypes.func,
  updateTile: PropTypes.func,
  setError: PropTypes.func,
  newTile: PropTypes.object.isRequired,
  isFinished: PropTypes.bool,
  loadingSave: PropTypes.bool.isRequired,
  features: objectOf(PropTypes.bool),
};

TilePayment.defaultProps = {
  pageTitleKey: 'tile.payment',
  tile: defaultTile,
  goToNext: () => {},
  goToPrev: null,
  updateTile: null,
  setError: null,
  isFinished: false,
  features: {},
};

export default withStyles(styles)(TilePayment);
