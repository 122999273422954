import { useState } from 'react';
import { bool, func, objectOf, oneOf, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import Modal from '../../Modal';
import PrimaryButton from '../../PrimaryButton';

import styles from './styles';

export function ConfirmCommunityModal({
  classes,
  open,
  onClose,
  onConfirm,
  disabled,
  variant,
}) {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState(false);
  const handleChange = e => {
    setConfirm(e.target.checked);
  };
  const toDisable = variant === 'disable';

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={toDisable ? classes.modal : ''}
      data-testid="confirm-community-modal"
    >
      <Modal.Title className={classes.title}>
        {t(`vanco.mobile.modal.${variant}Community.title`)}
      </Modal.Title>
      <Modal.Content className={classes.padding}>
        <Typography>
          {t(`vanco.mobile.modal.${variant}Community.description`)}
        </Typography>
        {toDisable ? (
          <FormControlLabel
            className={classes.checkMargin}
            control={
              /* eslint-disable-next-line react/jsx-wrap-multilines */
              <Checkbox
                name="confirm"
                color="primary"
                checked={confirm}
                onChange={handleChange}
                className={classes.checkColor}
                inputProps={{
                  'data-testid': 'confirmCheckbox',
                }}
              />
            }
            label={t(`vanco.mobile.modal.${variant}Community.option`)}
          />
        ) : (
          <Typography className={classes.optionMargin}>
            {t(`vanco.mobile.modal.${variant}Community.option`)}
          </Typography>
        )}
      </Modal.Content>
      <Modal.Actions className={toDisable ? classes.padding : classes.noBorder}>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          className={classes.button}
          disabled={disabled}
        >
          {t('app.goBack')}
        </Button>
        <PrimaryButton
          data-testid="confirm-button"
          onClick={onConfirm}
          className={classes.button}
          disabled={(!confirm && toDisable) || disabled}
        >
          {t(`vanco.mobile.modal.${variant}Community.confirm`)}
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
}

ConfirmCommunityModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  disabled: bool,
  variant: oneOf(['enable', 'disable']),
};

ConfirmCommunityModal.defaultProps = {
  open: false,
  disabled: false,
  variant: 'enable',
};

export default withStyles(styles)(ConfirmCommunityModal);
