export default function styles() {
  return {
    mainContainer: {
      flex: 'auto',
      width: '100%',
    },
    pagePaper: {
      padding: 20,
    },
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      marginBottom: 0,
    },
  };
}
