import { TILE_ACTIVE, TILE_TYPE_PAYMENT } from '../../../globals/constants';
import i18n from '../../../utils/i18n';

export const tileConfigPaths = {
  TITLE: '/wizard/tile/:tileId/title',
  DESCRIPTION: '/wizard/tile/:tileId/description',
  IMAGE: '/wizard/tile/:tileId/image',
  PAYMENT: '/wizard/tile/:tileId/payment',
  GIFT: '/wizard/tile/:tileId/gift',
  REVIEW: '/wizard/tile/:tileId/review',
  PAYMENTS: '/wizard/tile/:tileId/payments', // DEPRECATED, remove when clean up TyleTypes FF
};

export function createConfig(isPayment) {
  return [
    {
      navTitle: i18n.t('wizard.nav.title'),
      path: tileConfigPaths.TITLE,
      pageTitleKey: 'title.wizardHeader',
    },
    {
      navTitle: i18n.t('wizard.nav.details'),
      path: tileConfigPaths.DESCRIPTION,
      pageTitleKey: 'wizard.tile.customText',
    },
    {
      navTitle: i18n.t('wizard.nav.image'),
      path: tileConfigPaths.IMAGE,
    },
    isPayment
      ? {
          navTitle: i18n.t('wizard.nav.payment'),
          path: tileConfigPaths.PAYMENT,
          pageTitleKey: 'wizard.nav.payment',
        }
      : {
          navTitle: i18n.t('wizard.nav.gift'),
          path: tileConfigPaths.GIFT,
          pageTitleKey: 'wizard.tile.gift',
        },
    {
      navTitle: i18n.t('wizard.nav.review'),
      path: tileConfigPaths.REVIEW,
      pageTitleKey: 'wizard.tileReview.title',
    },
  ];
}

export function tileNavConfig(tile) {
  let nextFound = false;
  const isPayment = tile.type === TILE_TYPE_PAYMENT;
  const config = createConfig(isPayment);
  return config.map((step) => {
    const newStep = { ...step };
    switch (step.navTitle) {
      case i18n.t('wizard.nav.title'):
        if (tile.title && !!tile.title.length) {
          newStep.isFinished = true;
        }
        break;
      case i18n.t('wizard.nav.fund'):
        if (tile.fund && tile.fund.id && tile.fund.accountCode) {
          newStep.isFinished = true;
        }
        break;
      case i18n.t('wizard.nav.image'):
        if (tile.image && tile.image.id) {
          newStep.isFinished = true;
        }
        break;
      case i18n.t('wizard.nav.frequencies'):
        if (tile.paymentFrequencies && !!tile.paymentFrequencies.length) {
          newStep.isFinished = true;
        }
        break;
      case i18n.t('wizard.nav.details'):
        if (tile.headerText || tile.buttonText) {
          newStep.isFinished = true;
        }
        break;
      case i18n.t('wizard.nav.gift'):
        if (
          tile.paymentFrequencies?.length > 0 &&
          tile.fund &&
          tile.fund.accountCode
        ) {
          newStep.isFinished = true;
        }
        break;
      case i18n.t('wizard.nav.payment'):
        if (tile.paymentOptions?.price && tile.fund && tile.fund.accountCode) {
          newStep.isFinished = true;
        }
        break;
      default:
      // Do nothing
    }
    if (tile.status === TILE_ACTIVE) {
      newStep.isFinished = true;
    } else if (newStep.navTitle && !newStep.isFinished && !nextFound) {
      newStep.next = true;
      nextFound = true;
    }

    return newStep;
  });
}

export default tileNavConfig;
