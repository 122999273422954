import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import {
  CircularProgress,
  FormControl,
  useMediaQuery,
} from '@material-ui/core';
import Form from '../../Form';

import styles from './styles';
import OutlinedTextField from '../../OutlinedTextField';
import FormHelperTextBottom from '../../FormHelperTextBottom';
import { getCountText } from '../../../../utils';

import PageTitle from '../../PageTitle';
import PageDivider from '../../PageDivider';
import PagePaper from '../../PagePaper';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import PreventLeavePageModal from '../../PreventLeavePageModal';
import useFeatures from '../../../../utils/hooks/useFeatures/useFeatures';

export function OffsetForm({
  classes,
  values,
  handleBlur,
  setFieldValue,
  errors,
  touched,
  maxLengths,
  handleSubmit,
  dirty,
  cancelSetup,
  loading,
  isEditor,
}) {
  const { t } = useTranslation();
  const { title, pageTitleKey } = values;
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  const { VancoVideo: ffVancoVideo } = useFeatures();
  const videoTitle = t(`${ffVancoVideo ? 'video' : 'live'}Title`);

  return (
    <>
      <PagePaper className={`${isLargeScreen ? classes.pagePaper : ''}`}>
        <Form className={classes.form}>
          {pageTitleKey && (
            <>
              <PageTitle variant="small">{t(pageTitleKey)}</PageTitle>
              <Grid item className={classes.divider}>
                <PageDivider variant="wide" />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="h5">
              {t('stream.page.streamSite.pageName.title')}
            </Typography>
            <Typography variant="body1" className={classes.textMargin}>
              {t('stream.page.streamSite.pageName.description', {
                title: videoTitle,
              })}
            </Typography>
            <FormControl fullWidth className={classes.textMargin}>
              <OutlinedTextField
                id="title"
                name="title"
                error={errors.pageName && touched.pageName}
                value={title}
                label={t('stream.page.streamSite.streamPageName', {
                  title: videoTitle,
                })}
                placeholder={t(
                  'stream.page.streamSite.streamPageName.placeholder',
                )}
                inputProps={{
                  maxLength: maxLengths.pageName,
                  'data-testid': 'pageName-input',
                }}
                onBlur={handleBlur}
                onChange={setFieldValue}
                autoComplete="off"
                autoFocus={!title.length}
              />
              <FormHelperTextBottom
                left={touched.pageName && errors.pageName}
                right={getCountText(title.length, maxLengths.pageName)}
              />
            </FormControl>
          </Grid>
        </Form>
      </PagePaper>
      <Grid item>
        <PageDivider variant="streamSite" />
      </Grid>

      {!loading && (
        <WizardButtons
          isFinished
          dirty={dirty}
          goToNext={handleSubmit}
          goToPrev={cancelSetup}
          undo={() => {}}
          newWizard
          isStreamSite
          disableSave={isEditor && !dirty}
        />
      )}
      {loading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress color="primary" data-testid="save-spinner" />
        </div>
      )}
      {isEditor && (
        <PreventLeavePageModal shouldOpenOnLeave={dirty && !loading} />
      )}
    </>
  );
}

OffsetForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  dirty: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cancelSetup: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  maxLengths: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  isEditor: PropTypes.bool.isRequired,
};

OffsetForm.defaultProps = {};

export default withStyles(styles)(OffsetForm);
