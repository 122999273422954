import { string, object, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PageTitle from '../PageTitle';
import PrimaryButton from '../PrimaryButton';
import styles from './styles';
import { getOrganizationInitials, getPhone } from '../../../utils/page';
import { PAGE_ACTIVE } from '../../../globals/constants';

export function VancoMobileReviewWizard({
  classes,
  savePage,
  loadingPage,
  goToNext,
  goToPrev,
  pageTitleKey,
  page,
  setNewMobilePageModalOpen,
  setPageCommunityFeaturesChoosen,
}) {
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery('(min-width:600px)');

  if (loadingPage) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const organizationInitials = getOrganizationInitials(page.pageName);
  const enableCommunity = page.hasCommunityFeatures;
  const { creditPercentage, checkPercentage, logoImage } = page;
  const {
    defaultGroupName = 'General',
    requiresJoinRequest,
    usersCanCreateEvents,
    usersCanCreateSubgroups,
    usersCanInvite,
  } = page.mobileAppPermissions || {};

  const onDone = async () => {
    const formPage = {
      ...page,
      status: PAGE_ACTIVE,
    };
    await savePage(formPage);
    goToNext();
    setNewMobilePageModalOpen(true);
    setPageCommunityFeaturesChoosen(false);
  };

  // TODO: Remove with ChannelManagement FF cleanup
  return (
    <>
      <PageTitle>{t(pageTitleKey)}</PageTitle>

      <Grid item xs={12}>
        <Typography className={classes.firstSubtitle} variant="body2">
          {t('wizard.pageReview.note1')}
        </Typography>
        <Typography>{t('wizard.pageReview.note2')}</Typography>
      </Grid>

      {/* Organization details */}
      <Grid container className={classes.container}>
        <Grid container className={classes.sectionTitle}>
          <Grid item xs={8}>
            <Typography variant="body1" className={classes.titleFont}>
              {t('mobilePageWizard.basicInfo.key')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Link
              to={`/wizard/mobile/${page.id}/app-info`}
              className={classes.editLink}
            >
              {t('wizard.pageReview.edit')}
            </Link>
          </Grid>
        </Grid>
        <Grid container className={classes.sectionSubtitle}>
          <Grid className={classes.subtitle}>
            <Typography variant="body2" className={classes.fontColor}>
              {t('mobileSetup.organizationName')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.fontColor}>
              {page.pageName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.sectionSubtitle}>
          <Grid item className={classes.subtitle}>
            <Typography variant="body2" className={classes.fontColor}>
              {t('mobileSetup.organizationAddress')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.fontColor}>
              {page.address}
            </Typography>
            <Typography className={classes.fontColor}>
              {page.address2}
            </Typography>
          </Grid>
        </Grid>
        {(page.phone || page.email) && (
          <Grid container className={classes.sectionSubtitle}>
            <Grid item className={classes.subtitle}>
              <Typography variant="body2" className={classes.fontColor}>
                {t('mobileSetup.contactInformation')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              {page.phone && (
                <Typography className={classes.fontColor}>
                  {getPhone(page)}
                </Typography>
              )}
              {page.phone && (
                <Typography className={classes.fontColor}>
                  {page.email}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* App branding */}
      <Grid container className={classes.container}>
        <Grid container className={classes.sectionTitle}>
          <Grid item xs={8}>
            <Typography variant="body1" className={classes.titleFont}>
              {t('mobilePageWizard.branding.key')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Link
              to={`/wizard/mobile/${page.id}/branding`}
              className={classes.editLink}
            >
              {t('wizard.pageReview.edit')}
            </Link>
          </Grid>
        </Grid>
        <Grid container className={classes.sectionSubtitle}>
          <Grid className={classes.subtitle}>
            <Typography variant="body2" className={classes.fontColor}>
              {t('mobileSetup.branding.colorHeading')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} className={classes.displayInline}>
            <div
              className={classes.colorBackground}
              style={{ backgroundColor: page.primaryColor }}
            />
            <Typography className={classes.colorDescription}>
              {page.primaryColor}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.sectionSubtitle}>
          <Grid item className={classes.subtitle}>
            <Typography variant="body2" className={classes.fontColor}>
              {t('mobileSetup.review.organizationIcon')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            {!logoImage?.url && (
              <div
                className={classes.nonIconBackground}
                style={{ backgroundColor: page.primaryColor }}
              >
                <Typography variant="body2" className={classes.iconInitial}>
                  {organizationInitials}
                </Typography>
              </div>
            )}
            {logoImage?.url && (
              <div
                className={classes.iconBackground}
                style={{ backgroundImage: `url(${logoImage.url})` }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Processing fees */}
      <Grid container className={classes.container}>
        <Grid container className={classes.sectionTitle}>
          <Grid item xs={8}>
            <Typography variant="body1" className={classes.titleFont}>
              {t('mobilePageWizard.processingFees.key')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Link
              to={`/wizard/mobile/${page.id}/processing-fees`}
              className={classes.editLink}
            >
              {t('wizard.pageReview.edit')}
            </Link>
          </Grid>
        </Grid>
        <Grid container className={classes.sectionSubtitle}>
          <Grid className={classes.subtitle}>
            <Typography variant="body2" className={classes.fontColor}>
              {t('mobileSetup.review.feeSupport')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.fontColor}>
              {page.enableProcessingFees ? t('app.yes') : t('app.no')}
            </Typography>
            {page.enableProcessingFees && (
              <>
                <Typography className={classes.fontColor}>
                  {t('mobileSetup.review.cardTransactionPercentage', {
                    creditPercentage,
                  })}
                </Typography>
                <Typography className={classes.fontColor}>
                  {t('mobileSetup.review.bankTransactionPercentage', {
                    checkPercentage,
                  })}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Community Features */}
      <Grid container className={classes.container}>
        <Grid container className={classes.sectionTitle}>
          <Grid item xs={8}>
            <Typography variant="body1" className={classes.titleFont}>
              {t('mobilePageWizard.communityFeatures.key')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Link
              to={`/wizard/mobile/${page.id}/permissions`}
              className={classes.editLink}
            >
              {t('wizard.pageReview.edit')}
            </Link>
          </Grid>
        </Grid>
        <Grid container className={classes.sectionSubtitle}>
          <Grid className={classes.subtitle}>
            <Typography
              variant="body2"
              className={
                isLargeScreen ? classes.fontColorMargin : classes.fontColor
              }
            >
              {t('mobileSetup.appPermissions.enableCommunity')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.fontColor}>
              {enableCommunity
                ? t('mobileSetup.appPermissions.yesEnableCommunity')
                : t('mobileSetup.appPermissions.doNotEnableCommunity')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.sectionSubtitle}>
          <Grid className={classes.subtitle}>
            <Typography variant="body2" className={classes.fontColor}>
              {t('mobileSetup.appPermissions.approvalSubtitle')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.fontColor}>
              {requiresJoinRequest
                ? t('mobileSetup.review.requireApproval')
                : t('mobileSetup.review.notRequireApproval')}
            </Typography>
            <Typography className={classes.fontColor}>
              {requiresJoinRequest &&
                (usersCanInvite
                  ? t('mobileSetup.review.canInvite')
                  : t('mobileSetup.review.canNotInvite'))}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.sectionSubtitle}>
          <Grid className={classes.subtitle}>
            <Typography variant="body2" className={classes.fontColor}>
              {t('mobileSetup.appPermissions.events')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.fontColor}>
              {usersCanCreateEvents
                ? t('mobileSetup.review.canCreateEvents')
                : t('mobileSetup.review.canNotCreateEvents')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.sectionSubtitle}>
          <Grid className={classes.subtitle}>
            <Typography variant="body2" className={classes.fontColor}>
              {t('mobileSetup.appPermissions.groupsSubtitle')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.fontColor}>
              {t('mobileSetup.review.defaultGroupName', { defaultGroupName })}
            </Typography>
            <Typography className={classes.fontColor}>
              {usersCanCreateSubgroups
                ? t('mobileSetup.review.canCreateGroups')
                : t('mobileSetup.review.canNotCreateGroups')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {goToNext && !loadingPage && (
        <div className={classes.button}>
          <PrimaryButton size="large" onClick={onDone}>
            {t('button.done')}
          </PrimaryButton>
          <Button size="large" color="primary" onClick={goToPrev}>
            {t('button.previousStep')}
          </Button>
        </div>
      )}
      {loadingPage && goToPrev && <CircularProgress color="primary" />}
    </>
  );
}

VancoMobileReviewWizard.propTypes = {
  classes: object.isRequired,
  savePage: func.isRequired,
  loadingPage: bool.isRequired,
  goToNext: func.isRequired,
  goToPrev: func.isRequired,
  pageTitleKey: string,
  page: object.isRequired,
  setNewMobilePageModalOpen: func.isRequired,
  setPageCommunityFeaturesChoosen: func.isRequired,
};

VancoMobileReviewWizard.defaultProps = {
  pageTitleKey: '',
};

export default withStyles(styles)(VancoMobileReviewWizard);
