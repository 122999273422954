export default function styles() {
  return {
    container: {
      alignItems: 'center',
      background: '#FFFFFF',
      border: '1px solid #E6E6E6',
      borderRadius: 4,
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.07)',
      cursor: 'grab',
      display: 'flex',
      padding: '20px 22px',
      marginBottom: 16,
      width: 600,
      justifyContent: 'space-between',
    },
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    title: {
      color: '#222222',
      fontWeight: 600,
    },
    drag: {
      color: '#B0B0B0',
      marginRight: 22,
    },
    close: {
      color: '#666666',
      cursor: 'pointer',
    },
  };
}
