import { connect } from 'react-redux';

import Logout from './Logout';
import { postLogout } from '../../../store/user/actions';

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  { postLogout },
)(Logout);
