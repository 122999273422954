import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { PropTypes, string, objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { useHistory } from 'react-router-dom';
import withPaywall from 'components/hoc/withPaywall';
import { useACSTBlocking } from 'utils/hooks';
import PageTitle from '../../../common/PageTitle';
import { Page } from '../../../common';
import FrameContent from './FrameContent/FrameContent';
import styles from './styles';
import { getOrganizationId } from '../../../../store/user/actions';
import usePageTitle from '../../../../utils/hooks/usePageTitle/usePageTitle';

export function GivingDashboard({ classes, email, orgId }) {
  // Default URL
  let url =
    'https://app.sigmacomputing.com/embed/3c10aXAaNd58U0Nh0hmYxG/tag/Live';

  // When loading if windows size is mobile "small" we use mobile sigma call
  if (window.innerWidth < 510) {
    url =
      'https://app.sigmacomputing.com/embed/3c10aXAaNd58U0Nh0hmYxG/tag/Mobile%20Live';
  }

  const reportEmail = email.includes('vanco.com')
    ? 'reportuser@vanco.com'
    : email;

  const dispatch = useDispatch();
  const { hideFeaturesForACST } = useACSTBlocking();
  const [clientPK, setClientPK] = useState();
  const history = useHistory();

  const redirectToError = useCallback(() => {
    history.push('/*');
  }, [history]);

  const getOrgId = async () => {
    const orgData = await dispatch(getOrganizationId(orgId));
    setClientPK(orgData.clientPK);
  };

  if (!clientPK) {
    getOrgId();
  }

  useEffect(() => {}, [clientPK]);

  // Prevent ACST/PF orgs to use Analytics page
  useEffect(() => {
    if (hideFeaturesForACST) {
      redirectToError();
    }
  }, [hideFeaturesForACST, redirectToError]);

  const { t } = useTranslation();
  usePageTitle(t('app.menu.giving.dashboard'));

  return (
    <Page variant="centered" className={classes.container}>
      <PageTitle className={classes.title}>
        {t('app.menu.giving.dashboard')}
      </PageTitle>

      {clientPK && (
        <FrameContent
          url={url}
          id="mviframe"
          name="mviframe"
          email={reportEmail}
          clientPK={clientPK}
        />
      )}
    </Page>
  );
}
GivingDashboard.propTypes = {
  classes: objectOf(string).isRequired,
  orgId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default withPaywall(withStyles(styles)(GivingDashboard));
