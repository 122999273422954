import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { Button, Divider, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { useCallback, useState } from 'react';
import { getCountText } from '../../../../utils';
import {
  Form,
  FormHelperTextBottom,
  OutlinedTextEditor,
  OutlinedTextField,
  PagePaper,
  PreventLeavePageModal,
  PrimaryButton,
} from '../../../common';
import { renderQuillDescriptionHTML } from '../../../../utils/quill';
import {
  COMMUNICATIONS_COMPLETED_TEMPLATE,
  COMMUNICATIONS_CONTRIBUTOR_NAME_KEY,
  COMMUNICATIONS_EMAIL,
} from '../../../../globals/constants';

import styles from './styles';
import useRevertToDefault from './useRevertToDefault';

function CommunicationEmailTemplateForm({
  classes,
  handleSubmit,
  values,
  errors,
  setFieldValue,
  handleBlur,
  dirty,
  maxLengths,
  organizationName,
  userFirstname,
  template,
  isSubmitting,
  onGoBack,
}) {
  const { subject, body, contactinfo } = values;
  const { t } = useTranslation();
  const currentDate = moment().format('MMMM DD, YYYY');
  const futureDate = moment().add(2, 'days').format('MMMM DD, YYYY');
  const isCompletedTemplate = template === COMMUNICATIONS_COMPLETED_TEMPLATE;
  const parsedBody = JSON.parse(
    JSON.stringify(body).replaceAll(
      COMMUNICATIONS_CONTRIBUTOR_NAME_KEY,
      userFirstname,
    ),
  );
  const isLoaded = !!(subject && body && userFirstname);
  const hasErrors = !!(errors.subject || errors.body);
  const [currentState, setCurrentState] = useState({
    editMode: false,
    previewMode: false,
    bodyLength: 0,
  });

  const {
    onRevertToDefault,
    revertBodyToDefault,
    setRevertBodyToDefault,
    defaultSubjectText,
    defaultBodyText,
  } = useRevertToDefault({ t, template, setFieldValue });

  const onEdit = () => {
    setCurrentState({ ...currentState, editMode: true });
  };
  const onPreview = () => {
    if (!errors.subject && !errors.body)
      setCurrentState({ ...currentState, editMode: false, previewMode: true });
  };
  const onBackToEdit = () => {
    setCurrentState({ ...currentState, editMode: true, previewMode: false });
  };
  const setBodyLength = useCallback(
    (length) => setCurrentState((state) => ({ ...state, bodyLength: length })),
    [setCurrentState],
  );

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFieldValue({
      target: { name, value },
    });
  };

  const onBodyChange = (value) => {
    setFieldValue({ target: { name: COMMUNICATIONS_EMAIL.BODY_TYPE, value } });
  };

  const renderFieldFooter = ({
    error,
    length,
    type,
    onRevert,
    isBody = false,
    disabled = false,
  }) => {
    return (
      <div
        className={`${classes.flexContainer} ${
          isBody ? classes.flexMargin : ''
        }`}
      >
        <FormHelperTextBottom left={error} />
        <div className={classes.flex}>
          <Button
            disabled={disabled}
            size="small"
            color="primary"
            className={classes.revert}
            onClick={() => onRevert(type)}
          >
            {t('communication.revert')}
          </Button>
          <FormHelperTextBottom
            right={getCountText(
              length,
              isBody ? maxLengths.body : maxLengths.subject,
            )}
          />
        </div>
      </div>
    );
  };

  return (
    <Form>
      <PagePaper className={classes.paper}>
        {!currentState.editMode && !currentState.previewMode && (
          <Grid container>
            <Grid item sm={11} xs={12}>
              <Typography variant="subtitle2">
                {t('communication.transaction.confirmation.message')}
              </Typography>
            </Grid>
            <Grid item sm={1} xs={12}>
              <PrimaryButton className={classes.buttonEdit} onClick={onEdit}>
                {t('pageManagement.editPage')}
              </PrimaryButton>
            </Grid>
          </Grid>
        )}
        <Typography className={classes.twoTopSpace} variant="h2">
          {organizationName}
        </Typography>
        <Typography className={classes.date} variant="subtitle2">
          {currentDate}
        </Typography>
        {currentState.editMode ? (
          <>
            <Typography className={classes.subtitle} variant="subtitle2">
              {`${t('communication.subject')}:`}
            </Typography>
            <OutlinedTextField
              id="subject"
              name="subject"
              value={subject}
              onChange={(e) => {
                handleOnChange(e);
              }}
              inputProps={{
                'data-testid': 'subject-text',
                style: { fontSize: 32, fontWeight: 'bold' },
                maxLength: maxLengths.subject,
              }}
              autoComplete="off"
              onBlur={(e) => handleBlur(e)}
              placeholder={`${t('communication.placeholder')} ${t(
                'communication.subject',
              ).toLowerCase()}`}
            />
            {renderFieldFooter({
              error: errors.subject,
              length: subject.length,
              onRevert: onRevertToDefault,
              type: COMMUNICATIONS_EMAIL.SUBJECT_TYPE,
              disabled: subject === defaultSubjectText,
            })}
            <Typography className={classes.subtitle} variant="subtitle2">
              {`${t('communication.body')}:`}
            </Typography>
            <OutlinedTextEditor
              name="body"
              placeholder={t('tile.description.placeholder')}
              onChange={(e) => {
                onBodyChange(e);
              }}
              value={body}
              notifyLength={setBodyLength}
              variant="large"
              showContributorName
              defaultText={defaultBodyText}
              revertToDefault={revertBodyToDefault}
              setRevertToDefault={setRevertBodyToDefault}
            />
            {renderFieldFooter({
              error: errors.body,
              length: currentState.bodyLength,
              onRevert: onRevertToDefault,
              type: COMMUNICATIONS_EMAIL.BODY_TYPE,
              applyMargin: true,
              disabled: body?.[0]?.insert === defaultBodyText,
              isBody: true,
            })}
          </>
        ) : (
          <div>
            {isLoaded ? (
              <>
                <Typography className={classes.header} variant="h2">
                  {subject}
                </Typography>
                <Typography className={classes.bodyText} variant="subtitle2">
                  {renderQuillDescriptionHTML(parsedBody)}
                </Typography>
              </>
            ) : (
              <div>
                <Skeleton
                  variant="text"
                  className={classes.header}
                  height={48}
                />
                <Skeleton
                  variant="text"
                  className={classes.bodyText}
                  height={32}
                />
              </div>
            )}
          </div>
        )}
        <br />
        <Typography variant="caption" className={classes.gray}>
          {t('communication.transaction.to.ensure.necessary.info')}
        </Typography>
        <br />
        <div className={classes.items}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3">
                {isCompletedTemplate
                  ? t('communication.transaction.confirmation.items')
                  : t('communication.transaction.confirmation.schedule.items')}
              </Typography>
            </Grid>
            <Grid item className={classes.twoTopSpace} xs={11}>
              <Typography variant="h3">
                {t('communication.transaction.confirmation.general.donations')}
              </Typography>
            </Grid>
            <Grid className={classes.twoTopSpace} item xs={1}>
              <Typography variant="h3">
                {t('communication.transaction.confirmation.general.amount')}
              </Typography>
            </Grid>
          </Grid>
          {!isCompletedTemplate && (
            <Typography className={classes.threeTopSpace} variant="body1">
              {`${t(
                'communication.transaction.confirmation.schedule.every.month',
              )} ${futureDate}`}
            </Typography>
          )}

          <Divider className={classes.eightTopSpace} />
          <Typography className={classes.threeTopSpace} variant="h3">
            {t('communication.transaction.confirmation.your.info')}
          </Typography>
          <br />
          <Typography variant="h4">
            {t('communication.transaction.payment.method')}
          </Typography>
          <br />
          <Typography variant="subtitle2">
            {t('communication.transaction.mask.visa')}
          </Typography>
        </div>
        <Typography className={classes.threeTopSpace} variant="h3">
          {t('communication.transaction.questions')}
        </Typography>
        {!isCompletedTemplate && (
          <Typography className={classes.threeTopSpace} variant="h3">
            {t('communication.transaction.how.do.I.manage')}
          </Typography>
        )}
        <div className={classes.threeTopSpace}>
          {!isCompletedTemplate && (
            <Typography className={classes.fontRegular} variant="caption">
              {t('communication.transaction.to.manage.click.here')}
              <Typography component="span" className={classes.link}>
                {t('communication.transaction.to.manage.here')}
              </Typography>
            </Typography>
          )}
        </div>
        <div className={classes.threeTopSpace}>
          <Typography className={classes.fontRegular} variant="caption">
            {t('communication.transaction.if.have.questions')}
          </Typography>
          <Typography className={classes.vancoEmail} variant="caption">
            {contactinfo || t('communication.transaction.footer.email')}
          </Typography>
        </div>
        <div className={classes.footer}>
          {isCompletedTemplate && (
            <Typography variant="caption">
              {t(
                'communication.transaction.footer.you.are.receiving.this.email.completed',
              )}
            </Typography>
          )}
          {!isCompletedTemplate && (
            <Typography variant="caption">
              {t(
                'communication.transaction.footer.you.are.receiving.this.email.scheduled',
              )}
            </Typography>
          )}
          <Typography variant="caption">{organizationName}</Typography>
          <Typography variant="caption">
            {t('communication.transaction.footer.which.partner.with')}
          </Typography>
          <Typography className={classes.vanco} variant="caption">
            {t('communication.transaction.footer.vanco')}
          </Typography>
          <Typography variant="caption">
            {t('communication.transaction.footer.provide')}
          </Typography>
        </div>
        {(currentState.editMode || currentState.previewMode) && (
          <Grid container spacing={2} className={classes.actionButtons}>
            {currentState.editMode && (
              <Grid item>
                <PrimaryButton
                  onClick={onPreview}
                  size="large"
                  disabled={hasErrors || !dirty || isSubmitting}
                >
                  {t('tile.preview')}
                </PrimaryButton>
              </Grid>
            )}
            <Grid item>
              <PrimaryButton
                size="large"
                onClick={handleSubmit}
                disabled={hasErrors || !dirty || isSubmitting}
                type="submit"
              >
                {t('button.saveChanges')}
              </PrimaryButton>
            </Grid>
            {currentState.previewMode && (
              <Grid item>
                <PrimaryButton
                  onClick={onBackToEdit}
                  size="large"
                  disabled={isSubmitting}
                >
                  {t('communication.back')}
                </PrimaryButton>
              </Grid>
            )}
          </Grid>
        )}
      </PagePaper>
      <PreventLeavePageModal
        shouldOpenOnLeave={dirty && !isSubmitting}
        onLeave={onGoBack}
      />
    </Form>
  );
}

CommunicationEmailTemplateForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  maxLengths: PropTypes.object.isRequired,
  organizationName: PropTypes.string.isRequired,
  userFirstname: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onGoBack: PropTypes.func.isRequired,
};

export default withStyles(styles)(CommunicationEmailTemplateForm);
