import { createApi } from 'unsplash-js';

// max unsplash supports is 30 photos per page of requests
// see: https://unsplash.com/documentation#pagination
const PHOTOS_PER_PAGE = 30;

class UnsplashService {
  static client;

  constructor() {
    this.client = createApi({
      accessKey: window.env.REACT_APP_UNSPLASH_APP_ID,
    });
  }

  getSearchPhotos(query, page) {
    return this.client.search.getPhotos({query, page, perPage: PHOTOS_PER_PAGE});
  }

  trackDownloadImage(image) {
    return this.client.photos.trackDownload(image);
  }
}

export default new UnsplashService();
