export default function styles(theme) {
  const divider = {
    marginTop: 24,
  };
  return {
    textMargin: {
      marginTop: 8,
    },
    colorContainer: {
      maxWidth: 510,
      marginBottom: theme.spacing(3),
    },
    spinnerContainer: {
      marginTop: theme.spacing(2),
    },
    divider: {
      ...divider,
      marginBottom: 24,
    },
    lastDivider: {
      ...divider,
    },
    form: {
      marginBottom: 0,
    },
    sectionVertGutter: {
      marginBottom: theme.spacing(3),
    },
  };
}
