export default (theme) => ({
  date: {
    width: 200, //  395
    [theme.breakpoints.down('sm')]: {
      width: 200, //  335
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  table: {
    marginTop: '60px',
  },
  singleRow: {
    display: 'block',
  },
  eventName: {
    widht: '160px',
  },
  eventStatus: {
    widht: '160px',
  },
  eventActions: {
    widht: '70px',
    justifyContent: 'center',
    allignItems: 'center',
    textAlign: 'center',
  },
  headerTextWithButtons: {
    display: 'inline',
    marginRight: 6,
    fontWeight: 'bold',
  },
  badge: {
    fontSize: '12px',
    display: 'inline',
    color: 'blue',
    fontWeight: 'bold',
  },
  flipHorizontally: {
    transform: 'scaleX(-1)',
  },
  title: {
    fontSize: '2rem',
    lineHeight: 'normal',
    marginBottom: '2rem',
    display: 'flex',
    justifyContent: 'center',
    allignItems: 'center',
  },
});
