export const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const AUTH_BEGIN = 'AUTH_BEGIN';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN_BEGIN = 'USER_LOGIN_BEGIN';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_FINISH = 'CREATE_USER_FINISH';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR';
export const REMOVE_USER_START = 'REMOVE_USER_START';
export const REMOVE_USER_FINISH = 'REMOVE_USER_FINISH';
export const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR';
export const SET_CREATE_USER_MODAL_OPEN = 'SET_CREATE_USER_MODAL_OPEN';
export const SET_REMOVE_USER_MODAL_OPEN = 'SET_REMOVE_USER_MODAL_OPEN';
export const SET_EDIT_USER_MODAL_OPEN = 'SET_EDIT_USER_MODAL_OPEN';
export const SET_EDIT_USER_PERMISSIONS_MODAL_OPEN =
  'SET_EDIT_USER_PERMISSIONS_MODAL_OPEN';
export const SET_EMAIL_SETTINGS_MODAL_OPEN = 'SET_EMAIL_SETTINGS_MODAL_OPEN';
export const EMAIL_SETTINGS_UPDATE_START = 'EMAIL_SETTINGS_UPDATE_START';
export const EMAIL_SETTINGS_UPDATE_FINISH = 'EMAIL_SETTINGS_UPDATE_FINISH';
export const EMAIL_SETTINGS_ERROR = 'EMAIL_SETTINGS_ERROR';
export const SET_USER = 'SET_USER';
export const OWNERSHIP_TRANSFERRED_START = 'OWNERSHIP_TRANSFERRED_START';
export const OWNERSHIP_TRANSFERRED_FINISH = 'OWNERSHIP_TRANSFERRED_FINISH';
export const OWNERSHIP_TRANSFERRED_ERROR = 'OWNERSHIP_TRANSFERRED_ERROR';
export const DISABLE_PROMPT_TO_COMPLETE_ORGANIZATION_SETTINGS =
  'DISABLE_PROMPT_TO_COMPLETE_ORGANIZATION_SETTINGS';
export const REFRESHED_TOKEN = 'REFRESHED_TOKEN';
