export default function styles(theme) {
  return {
    wrapper: {
      flexWrap: 'wrap',
      marginTop: 24,
    },
    error: {
      color: '#A16512',
      fontSize: 16,
      marginTop: 0,
    },
    helperText: {
      margin: 0,
      marginTop: theme.spacing(2),
    },
  };
}
