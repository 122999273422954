import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PersonAddOutlined, ShareOutlined } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import ButtonMenu from '../../common/ButtonMenu';
import styles from './styles';
import { copyText } from '../../../utils';

function ActionsMenu({ classes, primary, setSuccess, isImportButtonDisabled }) {
  const history = useHistory();
  const { t } = useTranslation();
  const locationURI = primary?.locationURI;
  const urlToShare = `${window.env.REACT_APP_DON_URL}/${locationURI}/profile/transactions?actionneeded=yes`;

  const copyActionRequiredURL = () => {
    copyText(urlToShare);
    setSuccess(t('ministry.platform.import.link.copied.to.clipboard'));
  };

  const goToNewImport = () => {
    history.push('/new-import', { showBack: true });
  };

  return (
    <ButtonMenu buttonContent={t('site.actions')}>
      <ButtonMenu.Item
        onClick={goToNewImport}
        disabled={isImportButtonDisabled}
      >
        <PersonAddOutlined className={classes.actionIcons} />
        <Typography ml={4}>
          {t('ministry.platform.import.page.button')}
        </Typography>
      </ButtonMenu.Item>
      <ButtonMenu.Divider />
      <ButtonMenu.Item onClick={copyActionRequiredURL}>
        <ShareOutlined className={classes.actionIcons} />
        <Typography ml={4}>
          {t('ministry.platform.import.copy.action.donor.link')}
        </Typography>
      </ButtonMenu.Item>
    </ButtonMenu>
  );
}

ActionsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  primary: PropTypes.object,
  setSuccess: PropTypes.func.isRequired,
  isImportButtonDisabled: PropTypes.bool.isRequired,
};

ActionsMenu.defaultProps = {
  primary: null,
};

export default withStyles(styles)(ActionsMenu);
