export default function styles(theme) {
  return {
    infoContainer: {
      display: 'flex',
      flexDirection: 'row',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#cbc3e6',
      backgroundColor: '#e9e8f5',
      minWidth: 400,
      maxWidth: 500,
      padding: 16,
      paddingLeft: 32,
      paddingRight: 32,
    },
    infoText: {
      color: theme.palette.primary.main,
    },
    infoIcon: {
      marginRight: 8,
      '& path': {
        stroke: theme.palette.primary.main,
      },
    },
    errorContainer: {
      display: 'flex',
      flexDirection: 'row',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#F03738',
      backgroundColor: '#fdeaeb',
      minWidth: 400,
      maxWidth: 500,
      padding: 16,
      paddingLeft: 32,
      paddingRight: 32,
    },
    errorText: {
      color: '#F03738',
    },
    errorIcon: {
      marginRight: 8,
      '& path': {
        fill: '#F03738',
      },
    },
  };
}
