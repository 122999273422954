import { connect } from 'react-redux';

import { auth } from '../../../store/user/actions';
import { getPages } from '../../../store/pages/actions';
import { getKiosk } from '../../../store/kiosk/actions';
import Router from './Router';

export function mapStateToProps(state) {
  return {
    isLoggedIn: state.user.isLoggedIn,
    loadingLogin: state.user.loadingLogin,
    internalLoadingLogin: state.internal.loadingLogin,
    isInternal: state.user.user.isInternal,
    wasInternal: state.user.user.wasInternal,
    loadingAuth: state.user.loadingAuth,
    loadingPages: state.pages.loadingPages,
    hasLoadedPages: state.pages.hasLoadedPages,
    features: state.features?.features,
    promptToCompleteOrganizationSettings:
      state.user.user.promptToCompleteOrganizationSettings,
    mobileEnabled:
      state.user.user.wasInternal ||
      state.organizations.currentOrganization.mobileEnabled,
    hasKiosk: state.kiosk.hasKiosk,
    orgUri: state.organizations.currentOrganization?.id,
  };
}

export default connect(mapStateToProps, {
  auth,
  getPages,
  getKiosk,
})(Router);
