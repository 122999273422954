export default function styles(theme) {
  return {
    createtileModal: {
      maxWidth: 576,
    },
    header: {
      borderBottom: 0,
      padding: 21,
      marginBottom: 0,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 40px 40px',
    },
    icon: {
      alignItems: 'center',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      flex: '0 0 48px',
      justifyContent: 'center',
      height: 48,
      marginRight: 15,
    },
    title: {
      alignSelf: 'center',
      fontSize: '2.4rem',
      textAlign: 'center',
      maxWidth: 350,
      lineHeight: '3.2rem',
      marginBottom: 62,
    },
    paymentButtonContainer: {
      cursor: 'pointer',
      marginTop: 16,
    },
    tileTypeButton: {
      alignItems: 'center',
      borderColor: '#EAEAEA',
      cursor: 'pointer',
      padding: '24px 30px 24px 24px',
      width: '100%',
      '&:disabled': {
        opacity: 0.5,
      },
    },
    popover: {
      pointerEvents: 'none',
      position: 'absolute',
    },
    popoverContent: {
      borderRadius: 4,
      backgroundColor: '#FFFFFF',
      width: 210,
      padding: '12px 16px',
      '& p': {
        fontSize: '1.2rem',
        fontWeight: 600,
        textAlign: 'left',
      },
    },
    optionTextContainer: {
      textAlign: 'left',
      marginRight: 17,
    },
    optionTitle: {
      fontWeight: 700,
    },
    optionSubtitle: {
      fontWeight: 400,
      fontSize: '1.4rem',
    },
    nextIcon: {
      marginLeft: 'auto',
    },
  };
}
