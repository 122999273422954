import TileService from '../../api/TileService';
import {
  GET_TILES_START,
  GET_TILES_FINISH,
  GET_TILES_ERROR,
  SAVE_TILE_START,
  SAVE_TILE_FINISH,
  SAVE_TILE_ERROR,
  UPDATE_TILE,
  CREATE_TILE_START,
  CREATE_TILE_FINISH,
  CREATE_TILE_ERROR,
  RESET_NEW_TILE,
  DELETE_TILE_START,
  DELETE_TILE_FINISH,
  DELETE_TILE_ERROR,
  UPDATE_IMAGE_TILE_ERROR,
  UPDATE_IMAGE_TILE_FINISH,
  UPDATE_IMAGE_TILE_START,
  CLEAR_TEMP_IMAGE,
  CLEAR_TILES,
} from './types';
import {
  frontendToBackend,
  backendToFrontend,
  imageFrontendToBackend,
  imageBackendToFrontend,
} from './transform';
import {
  handleException,
  handleResponseError,
  setSuccess,
} from '../alerts/actions';
import { setAppLoading } from '../loading/actions';
import i18n from '../../utils/i18n';
import { getCroppedImageBase64 } from '../../utils/tiles';
import { defaultTile } from '../../globals/defaults';

export function getTiles() {
  return async (dispatch, getState) => {
    const { currentPage } = getState().pages;

    dispatch(setAppLoading(true));
    dispatch({ type: GET_TILES_START });

    try {
      const res = await TileService.getTiles(currentPage);

      if (res.status === 200) {
        dispatch({
          type: GET_TILES_FINISH,
          data: res.data.data.map(tile => backendToFrontend(tile)),
        });
      } else {
        dispatch({
          type: GET_TILES_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: GET_TILES_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }

    dispatch(setAppLoading(false));
  };
}

export function createTile(tile = defaultTile) {
  return async (dispatch, getState) => {
    const { currentPage } = getState().pages;

    dispatch({ type: CREATE_TILE_START });

    try {
      const res = await TileService.createTile(
        frontendToBackend(tile, currentPage),
      );

      if ([201, 200].includes(res.status)) {
        dispatch({
          type: CREATE_TILE_FINISH,
          data: res.data.data,
        });
      } else {
        dispatch({
          type: CREATE_TILE_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: CREATE_TILE_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }
  };
}

export function saveTile(tile, showSuccessMessage = false) {
  return async (dispatch, getState) => {
    const { currentPage } = getState().pages;

    dispatch({ type: SAVE_TILE_START });

    try {
      const res = await TileService.saveTile(
        frontendToBackend(tile, currentPage),
      );

      if ([201, 200].includes(res.status)) {
        dispatch({
          type: SAVE_TILE_FINISH,
          data: res.data.data,
        });
        if (showSuccessMessage) {
          dispatch(setSuccess(i18n.t('tile.saved')));
        }
      } else {
        dispatch({
          type: SAVE_TILE_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: SAVE_TILE_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }
  };
}

export function deleteTile(tile) {
  return async dispatch => {
    dispatch({ type: DELETE_TILE_START });

    try {
      const res = await TileService.deleteTile(tile);

      if ([204, 200].includes(res.status)) {
        dispatch({
          type: DELETE_TILE_FINISH,
          data: tile,
        });
      } else {
        dispatch({
          type: DELETE_TILE_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: DELETE_TILE_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }
  };
}

export function updateTileImage(tile) {
  return async dispatch => {
    dispatch({ type: UPDATE_IMAGE_TILE_START });
    try {
      const blob = await getCroppedImageBase64(tile.image);
      const image = {
        ...tile.image,
        blob,
      };
      const updatedTile = {
        ...tile,
        image,
      };

      const res = await TileService.updateTileImage(
        imageFrontendToBackend(updatedTile),
      );

      if ([201, 200].includes(res.status)) {
        const tempImage = imageBackendToFrontend(tile, res.data.data).image;
        dispatch({
          type: UPDATE_IMAGE_TILE_FINISH,
          tempImage,
          tile,
        });
        return tempImage;
      }
      dispatch({
        type: UPDATE_IMAGE_TILE_ERROR,
        error: res.error,
      });
      dispatch(handleResponseError(res));
    } catch (e) {
      dispatch({
        type: UPDATE_IMAGE_TILE_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }
    return null;
  };
}

// a local only update of a tile
export function updateTile(tile) {
  return {
    type: UPDATE_TILE,
    data: tile,
  };
}

export function resetNewTile() {
  return {
    type: RESET_NEW_TILE,
  };
}

export function clearTempImage() {
  return {
    type: CLEAR_TEMP_IMAGE,
  };
}

export function clearTiles() {
  return {
    type: CLEAR_TILES,
  };
}
