import { connect } from 'react-redux';
import PrivateRouteWithStreamer from './PrivateRouteWithStreamer';

export function mapStateToProps(state) {
  return {
    isLoggedIn: state.user.isLoggedIn,
    loadingAuth: state.user.loadingAuth,
    grantedActions: state.user.user.grantedActions,
    streamerId: state.pages.streamer.streamerId || state.user.user.streamerId,
  };
}

export default connect(mapStateToProps, {})(PrivateRouteWithStreamer);
