import { useTranslation } from 'react-i18next';
import { shape, objectOf, string } from 'prop-types';

import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { withStyles, Typography } from '@material-ui/core';

import { ReactComponent as DraftIcon } from '../../../../assets/draft.svg';
import { isPending, isVisible, isHidden } from '../../../../utils/tiles';

import styles from './styles';

export function TileStatus({ classes, tile }) {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      {isPending(tile) && (
        <>
          <DraftIcon className={classes.icon} />
          <Typography component="span">{t('tile.draft')}</Typography>
        </>
      )}
      {isVisible(tile) && (
        <>
          <VisibilityOutlinedIcon className={classes.icon} />
          <Typography component="span">{t('tile.visible')}</Typography>
        </>
      )}
      {isHidden(tile) && (
        <>
          <VisibilityOffOutlinedIcon
            className={[classes.icon, classes.light].join(' ')}
          />
          <Typography className={classes.light} component="span">
            {t('tile.hidden')}
          </Typography>
        </>
      )}
    </div>
  );
}

TileStatus.propTypes = {
  classes: objectOf(string).isRequired,
  tile: shape({}).isRequired,
};

export default withStyles(styles)(TileStatus);
