export default () => ({
  colorsContainer: {
    marginBottom: '42px',
    maxWidth: 510,
  },
  colorsHeader: {
    marginBottom: '16px',
    width: '100%',
    maxWidth: 700,
  },
  text: {
    maxWidth: 700,
  },
  spinnerContainer: {
    marginTop: 40,
  },
});
