import { useEffect, useState } from 'react';
import { useGetPage } from '../useGetPage/useGetPage';

export function useGetPageSettings(channelType, pageStatus) {
  const [pageToUpdate, setPageToUpdate] = useState({});
  const { page, isLoading } = useGetPage(channelType, pageStatus);

  useEffect(() => {
    if (page.id !== pageToUpdate.id) {
      setPageToUpdate(page);
    }
  }, [page, pageToUpdate]);

  return {
    isLoading,
    page,
    pageToUpdate,
    updatePage: setPageToUpdate,
  };
}

export default useGetPageSettings;
