import { connect } from 'react-redux';
import KioskRequest from './KioskSetup';
import { updateKiosk } from '../../../../store/kiosk/actions';
import { setError, setSuccess } from '../../../../store/alerts/actions';

export function mapStateToProps(state) {
  return {
    hasKiosk: state.kiosk.hasKiosk,
    kiosk: state.kiosk.kiosk,
    wasInternal: state.user.user.wasInternal,
  };
}

export default connect(mapStateToProps, { updateKiosk, setError, setSuccess })(
  KioskRequest,
);
