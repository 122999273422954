import OrganizationService from '../../api/OrganizationService';
import {
  SAVE_ORG_PERMISSIONS_START,
  SAVE_ORG_PERMISSIONS_FINISH,
  SAVE_ORG_PERMISSIONS_ERROR,
  SAVE_ORG_SETTINGS_START,
  SAVE_ORG_SETTINGS_FINISH,
  SAVE_ORG_SETTINGS_ERROR,
  GET_ORG_SETTINGS_START,
  GET_ORG_SETTINGS_FINISH,
  GET_ORG_SETTINGS_ERROR,
  GET_ORG_FEATURES_START,
  GET_ORG_FEATURES_FINISH,
  GET_ORG_FEATURES_ERROR,
  GET_ORG_GRANTS_START,
  GET_ORG_GRANTS_ERROR,
  GET_ORG_GRANTS_FINISH,
  GET_ALL_ORG_GRANTS_START,
  GET_ALL_ORG_GRANTS_ERROR,
  GET_ALL_ORG_GRANTS_FINISH,
} from './types';
import {
  handleException,
  handleResponseError,
  setSuccess,
} from '../alerts/actions';
import i18n from '../../utils/i18n';
import { frontendToBackend, backendToFrontend } from './transform';
import { getCroppedImageBase64 } from '../../utils/tiles';
import { setAppLoading } from '../loading/actions';
import AccountGrantsService from '../../api/AccountGrantsService';

export function getOrganizationSettingsAction(orgId) {
  return async (dispatch) => {
    dispatch({ type: GET_ORG_SETTINGS_START });
    dispatch(setAppLoading(true));

    try {
      const res = await OrganizationService.getSettings(orgId);
      if ([201, 200].includes(res.status)) {
        dispatch({
          type: GET_ORG_SETTINGS_FINISH,
          data: backendToFrontend(res.data.data),
        });
      } else {
        dispatch({
          type: GET_ORG_SETTINGS_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      const error =
        e?.response?.status === 404
          ? { message: i18n.t('errors.orgSettingsNotFound') }
          : e;
      dispatch({
        type: GET_ORG_SETTINGS_ERROR,
        error: error.message,
      });
      dispatch(handleException(error));
    }

    dispatch(setAppLoading(false));
  };
}

export function saveOrganizationSettings(
  orgId,
  orgSettings,
  showSuccessMessage = false,
) {
  return async (dispatch) => {
    dispatch({ type: SAVE_ORG_SETTINGS_START });
    try {
      const res = await OrganizationService.saveSettings(
        orgId,
        frontendToBackend(orgSettings),
      );
      if ([201, 200].includes(res.status)) {
        dispatch({
          type: SAVE_ORG_SETTINGS_FINISH,
          data: backendToFrontend(res.data.data),
        });
        if (showSuccessMessage) {
          dispatch(setSuccess(i18n.t('site.changes.saved')));
        }
        return true;
      }
      dispatch({
        type: SAVE_ORG_SETTINGS_ERROR,
        error: res.error,
      });
      dispatch(handleResponseError(res));
    } catch (e) {
      dispatch({
        type: SAVE_ORG_SETTINGS_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }
    return false;
  };
}

export function updateLogoImage({ pageId, image }) {
  return async (dispatch) => {
    try {
      const blob = await getCroppedImageBase64(image, true);
      const updatedImage = { id: pageId, blob };

      const res =
        await OrganizationService.updateOrganizationLogoImage(updatedImage);

      if ([201, 200].includes(res.status)) {
        return res.data.data;
      }
      dispatch(handleResponseError(res));
    } catch (e) {
      dispatch(handleException(e));
    }
    return null;
  };
}

export function updateBannerImage({ pageId, image }) {
  return async (dispatch) => {
    try {
      const blob = await getCroppedImageBase64(image);
      const updatedImage = { id: pageId, blob };

      const res =
        await OrganizationService.updateOrganizationBannerImage(updatedImage);

      if ([201, 200].includes(res.status)) {
        return res.data.data;
      }
      dispatch(handleResponseError(res));
    } catch (e) {
      dispatch(handleException(e));
    }
    return null;
  };
}

export function updateUserFeatures(orgId, features = []) {
  return async (dispatch) => {
    dispatch(setAppLoading(true));
    try {
      const res = await OrganizationService.updateFeatures(orgId, features);

      if ([201, 200].includes(res.status)) {
        dispatch(setSuccess(i18n.t('vanco.mobile.edit.orgDetails.success')));
        dispatch(setAppLoading(false));
        return res.data.data;
      }
      dispatch(handleResponseError(res));
    } catch (e) {
      dispatch(handleException(e));
    }
    dispatch(setAppLoading(false));
    return null;
  };
}

export function getUserFeatures(orgId) {
  return async (dispatch) => {
    dispatch(setAppLoading(true));
    dispatch({ type: GET_ORG_FEATURES_START });
    try {
      const res = await OrganizationService.getOrgFeatures(orgId);

      if ([201, 200].includes(res?.status)) {
        dispatch({
          type: GET_ORG_FEATURES_FINISH,
          data: res.data.data,
        });
      } else {
        dispatch({
          type: GET_ORG_FEATURES_ERROR,
          error: res?.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      const error =
        e?.response?.status === 404
          ? { message: i18n.t('errors.orgNotFound') }
          : e;
      dispatch({
        type: GET_ORG_FEATURES_ERROR,
        error: error?.message,
      });
      dispatch(handleException(error));
    }

    dispatch(setAppLoading(false));
  };
}

export function saveOrganizationDefaults(orgId) {
  return async (dispatch) => {
    dispatch({ type: SAVE_ORG_SETTINGS_START });
    dispatch(setAppLoading(true));
    try {
      const res = await OrganizationService.saveOrganizationDefaults(orgId, {
        tileName: i18n.t('organizationDefaults.tileName'),
        tileShortDescription: i18n.t(
          'organizationDefaults.tileShortDescription',
        ),
        tileDescription: i18n.t('organizationDefaults.tileDescription'),
        buttonLabel: i18n.t('organizationDefaults.buttonLabel'),
        unsplashImageId: i18n.t('organizationDefaults.unsplashImageId'),
      });
      if ([201, 200].includes(res.status)) {
        dispatch({
          type: SAVE_ORG_SETTINGS_FINISH,
          data: { success: true },
        });
      } else {
        dispatch({
          type: SAVE_ORG_SETTINGS_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: SAVE_ORG_SETTINGS_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }
    dispatch(setAppLoading(false));
  };
}

export const getOrganizationGrants = () => async (dispatch) => {
  dispatch({ type: GET_ORG_GRANTS_START });
  try {
    const response = await AccountGrantsService.getAssginedGrants();
    if ([201, 200].includes(response.status)) {
      const grants = response.data?.data?.accountGrants;
      dispatch({
        type: GET_ORG_GRANTS_FINISH,
        grants,
      });
    } else {
      dispatch({
        type: GET_ORG_GRANTS_ERROR,
        error: response.error,
      });
      dispatch(handleResponseError(response));
    }
  } catch (error) {
    dispatch({ type: GET_ORG_GRANTS_ERROR, error });
  }
};

export const getAllOrganizationGrants = () => async (dispatch) => {
  dispatch({ type: GET_ALL_ORG_GRANTS_START });
  try {
    const response = await AccountGrantsService.getOrgGrants();
    if ([201, 200].includes(response.status)) {
      const grants = response.data?.data?.accountGrants;
      dispatch({
        type: GET_ALL_ORG_GRANTS_FINISH,
        grants,
      });
    } else {
      dispatch({
        type: GET_ALL_ORG_GRANTS_ERROR,
        error: response.error,
      });
      dispatch(handleResponseError(response));
    }
  } catch (error) {
    dispatch({ type: GET_ALL_ORG_GRANTS_ERROR, error });
  }
};

export function saveOrganizationPermissions(organizationUri, permissionsData) {
  return async (dispatch) => {
    dispatch({ type: SAVE_ORG_PERMISSIONS_START });
    dispatch(setAppLoading(true));
    try {
      const res = await AccountGrantsService.saveOrganizationPermissions(
        organizationUri,
        permissionsData,
      );
      if ([201, 200].includes(res.status)) {
        dispatch({
          type: SAVE_ORG_PERMISSIONS_FINISH,
          data: { success: true },
        });
        dispatch(setSuccess(i18n.t('site.permissions.saved')));
        return { success: true }; // Return success status
      }

      dispatch({
        type: SAVE_ORG_PERMISSIONS_ERROR,
        error: res.error,
      });
      dispatch(handleResponseError(res));
      return { success: false };
    } catch (e) {
      dispatch({
        type: SAVE_ORG_PERMISSIONS_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
      return { success: false };
    } finally {
      dispatch(setAppLoading(false));
    }
  };
}
