export default function styles(theme) {
  return {
    title: {
      padding: '24px 41px 28px',
    },
    content: {
      padding: 20,
    },
    actions: {
      justifyContent: 'space-between',
      margin: '24px 41px',
    },
    imageBy: {
      display: 'flex',
      marginTop: 5,
      fontSize: '1.2rem',
    },
    link: {
      marginLeft: 2,
      color: theme.palette.primary.main,
    },
  };
}
