import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

import SnackbarContentWrapper from '../../common/SnackbarContentWrapper';

export function AppSnackbar({ variant, message, clearAlert }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!variant);
  }, [variant]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    clearAlert();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          variant={variant}
          message={
            message && typeof message !== 'string'
              ? t('errors.somethingWrong')
              : message
          }
        />
      </Snackbar>
    </div>
  );
}

AppSnackbar.propTypes = {
  variant: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  clearAlert: PropTypes.func.isRequired,
};

AppSnackbar.defaultProps = {
  variant: null,
  message: null,
};

export default AppSnackbar;
