export default function styles() {
  return {
    accountList: {
      padding: 0,
      height: '100vh',
      marginTop: -10,
      overflowY: 'auto',
    },
    listItem: {
      borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
      padding: '24px',
    },
    overflow: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    title: {
      '& h6': {
        textAlign: 'left',
        paddingLeft: 0,
      },
    },
    searchContainerSingle: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      borderBottom: '1px solid #EAEAEA',
      padding: '24px',
    },
    searchContainer: {
      height: '40px',
      width: '100%',
      position: 'relative',
      gridColumnStart: 1,
    },
    searchContent: {
      fontSize: '1.6rem',
      lineHeight: '20px',
      width: '100%',
    },
    searchOption: {
      fontSize: '1.6rem',
      lineHeight: '24px',
      fontWeight: 500,
    },
    searchIconButton: {
      height: '40px',
      width: '40px',
      top: '-1px',
      right: '-3px',
      position: 'absolute',
    },
    closeIconButton: {
      height: '40px',
      width: '40px',
      top: '-1px',
      right: '33px',
      position: 'absolute',
    },
    divider: {
      height: 26,
      margin: '4px',
      top: '3px',
      right: 30,
      position: 'absolute',
    },
    noEntryText: {
      fontSize: '20px',
      lineHeight: '25.1px',
    },
    tryAgainText: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    emphesisWord: {
      marginLeft: '4px',
      fontWeight: 'bold',
    },
    messageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '35vh',
    },
  };
}
