import { useState } from 'react';
import useQRCodeModal from '../useQRCodeModal';

export function useSmartLibraryActions() {
  const [embedModalOpen, setEmbedModalOpen] = useState(false);
  const openEmbedModal = () => setEmbedModalOpen(true);
  const closeEmbedModal = () => setEmbedModalOpen(false);
  const {
    qrCodeModalOpen,
    openQRCodeModal,
    onCloseQRCodeModal,
    onErrorQRCodeModal,
  } = useQRCodeModal();

  return {
    embedModalOpen,
    openEmbedModal,
    closeEmbedModal,
    qrCodeModalOpen,
    openQRCodeModal,
    onCloseQRCodeModal,
    onErrorQRCodeModal,
  };
}

export default useSmartLibraryActions;
