import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../store/pages/actions';

// Due that we currently only support 1 location per ChannelType,
// we are just finding the first coincidence that means that it was
// the first created of that ChannelType is the one that we edit on the page-settings page,
// is the location that is displayed and used,
// to have a reference of this we can check the action getCurrentPage() in /src/store/pages/actions.js
function getNewCurrentPage({
  pages,
  currentPage,
  channelType,
  pageStatus,
  pageId,
}) {
  let validPage;

  if (pageId) {
    validPage = pages.find(
      p => p.id === pageId && pageStatus.includes(p.status),
    );
  } else {
    validPage = pages.find(
      p => p.channelId === channelType && pageStatus.includes(p.status),
    );
  }

  if (validPage) {
    return validPage.id !== currentPage.id ? validPage : null;
  }
  return null;
}

/**
 * @param {String} channelType - ONLINE, MOBILE
 * @param {Array} pageStatus - Active, Pending
 * @returns {Object} - currentPage
 */
export function useSetCurrentPageByChannel({
  channelType,
  pageStatus,
  pageId,
}) {
  const { pages, currentPage } = useSelector(state => ({
    pages: state.pages.pages,
    currentPage: state.pages.currentPage,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    const newPage = getNewCurrentPage({
      pages,
      currentPage,
      channelType,
      pageStatus,
      pageId,
    });

    // the second validation is due that we can have specific sections
    // per location with different ChannelTypes so if we don't find a location with that ChannelType we are going to clear the currentPage
    if (
      newPage ||
      (currentPage.channelId && currentPage.channelId !== channelType)
    ) {
      dispatch(setCurrentPage(newPage || {}));
    }
  }, [pages, currentPage, channelType, pageStatus, pageId, dispatch]);

  return currentPage;
}

export default useSetCurrentPageByChannel;
