export default function styles() {
  return {
    title: {
      marginTop: -20,
      marginBottom: -20,
    },
    buttonContainer: {
      marginTop: 8,
    },
    archivedTileOverlay: {
      backgroundColor: 'rgba(255,255,255,.5)',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
    },
  };
}
