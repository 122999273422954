import { shallowEqual, useSelector } from 'react-redux';
import useFeatures from '../useFeatures/useFeatures';

// returns hideFeaturesForACST if the AdminUserPermissions feature flag is OFF and isACSTOrganization or isPFOrganization is true
// when the feature flag cleanup takes place, all references to this hook should be removed
const useACSTBlocking = () => {
  const currentOrganization = useSelector(
    (state) => state.organizations.currentOrganization,
    shallowEqual,
  );
  const { AdminUserPermissions } = useFeatures();
  const isACSTOrganization = currentOrganization?.isACSTOrganization;
  const isPFOrganization = currentOrganization?.isPFOrganization;

  return {
    isACSTOrganization: !AdminUserPermissions && isACSTOrganization,
    isPFOrganization: !AdminUserPermissions && isPFOrganization,
    hideFeaturesForACST:
      !AdminUserPermissions && (isACSTOrganization || isPFOrganization),
  };
};

export default useACSTBlocking;
