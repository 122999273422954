import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setError } from '../../../store/alerts/actions';

export function useQRCodeModal() {
  const dispatch = useDispatch();
  const [qrCodeModalOpen, setQRCodeModalOpen] = useState(false);
  const { t } = useTranslation();
  const onCloseQRCodeModal = () => setQRCodeModalOpen(false);
  const openQRCodeModal = () => setQRCodeModalOpen(true);
  const onErrorQRCodeModal = () => {
    dispatch(setError(t('errors.generate.qrCode')));
    setQRCodeModalOpen(false);
  };

  return {
    qrCodeModalOpen,
    openQRCodeModal,
    onCloseQRCodeModal,
    onErrorQRCodeModal,
  };
}

export default useQRCodeModal;
