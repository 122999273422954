import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationSettingsAction } from '../../../store/organizations/actions';

export function useGetOrgSettings(orgId) {
  const dispatch = useDispatch();

  const { settings, loadingOrgSettings, savingOrgSettings } = useSelector(
    (state) => ({
      settings: state.organizations.currentOrganization.settings || {},
      loadingOrgSettings: state.organizations.loadingOrgSettings,
      savingOrgSettings: state.organizations.savingOrgSettings,
    }),
  );

  useEffect(() => {
    if (orgId && !loadingOrgSettings) {
      dispatch(getOrganizationSettingsAction(orgId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, dispatch]);

  return {
    settings,
    loadingOrgSettings,
    savingOrgSettings,
  };
}

export default useGetOrgSettings;
