import { node, string } from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';

import { useModalContext } from '../context';

export function ModalContent({ children, className, ...restProps }) {
  const { classes } = useModalContext();

  return (
    <DialogContent {...restProps} className={`${classes.content} ${className}`}>
      {children}
    </DialogContent>
  );
}

ModalContent.propTypes = {
  children: node.isRequired,
  className: string,
};

ModalContent.defaultProps = {
  className: '',
};

export default ModalContent;
