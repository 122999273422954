import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';

import { createSchema, maxLengths } from './validation';
import Modal from '../../Modal';
import CustomFormik from '../../CustomFormik';
import NewFundForm from '../NewFundForm';

export function NewFundModal({
  open,
  onClose,
  onSave,
  loadingCreate,
  banks,
  backendErrors,
  initialNewFund,
}) {
  const { t } = useTranslation();
  const schema = createSchema();

  return (
    <Modal open={open} onClose={onClose}>
        <Modal.Title>{t('fund.addFund.title')}</Modal.Title>
        <CustomFormik
          onChange={() => {}}
          onErrorsChange={() => {}}
          initialValues={initialNewFund}
          enableReinitialize
          onSubmit={newFund => {
            onSave(newFund);
          }}
          render={formikProps => (
            <Form>
              <NewFundForm
                maxLengths={maxLengths}
                onClose={onClose}
                loadingCreate={loadingCreate}
                banks={banks}
                backendErrors={backendErrors}
                {...formikProps}
              />
            </Form>
          )}
          validationSchema={schema}
        />
      </Modal>
  );
}

NewFundModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loadingCreate: PropTypes.bool.isRequired,
  banks: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialNewFund: PropTypes.object.isRequired,
  backendErrors: PropTypes.object.isRequired,
};

NewFundModal.defaultProps = {};

export default NewFundModal;
