export default function styles(theme) {
  return {
    header: {
      marginTop: 40,
      marginBottom: 10,
      width: '100%',
    },
    feesContainer: {
      marginTop: 24,
    },
    radioGroup: {
      width: '100%',
    },
    inputWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      width: 90,
      marginRight: 10,
    },
    vertGutter: {
      marginTop: 8,
    },
    vertGutterXL: {
      marginTop: theme.spacing(3),
      marginBottom: 10,
    },
  };
}
