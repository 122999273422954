import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { Link, useHistory, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { useEffect } from 'react';

import { LinkButton } from '../../../common';
import vancoMobileLogo from '../../../../assets/logos/mobile.svg';
import { getExitPath } from '../config';
import styles from './styles';

export function Navbar({
  classes,
  isLoggedIn,
  menuOpen,
  setMenuOpen,
  location,
  pages,
  newWizard,
  features,
  setForcePreventModal,
  forcePreventModal,
}) {
  const { t } = useTranslation();
  const currentPath = location.pathname;
  const isDesktop = useMediaQuery('(min-width:1280px)');
  const history = useHistory();
  const isTile = location.pathname.includes('tile');
  const isMobilePage = location.pathname.includes('mobile');
  const isStreamPage = location.pathname.includes('stream');
  const isOrganizationPage = location.pathname.includes('organization');
  const isProfile = currentPath.includes('profile');
  const isVONewWizard =
    features.ChannelManagement &&
    !isTile &&
    !isMobilePage &&
    !isStreamPage &&
    !isOrganizationPage;
  const showMenu = (!isDesktop || isProfile) && !isVONewWizard;

  const exitPath = getExitPath({
    isTile,
    isMobilePage,
    isStreamPage,
    isOrganizationPage,
    pages,
  });

  useEffect(() => {
    if (forcePreventModal) {
      history.push(exitPath);
      setForcePreventModal(false);
    }
  }, [exitPath, forcePreventModal, history, setForcePreventModal]);

  const onExit = () => {
    setForcePreventModal(true);
  };

  const renderOrganizationExitButton = (showIcon) => (
    <Button
      onClick={onExit}
      className={showIcon ? classes.exitButton : ''}
      data-testid="exit-button"
    >
      {showIcon && (
        <ArrowBackIcon color="primary" className={classes.arrowIcon} />
      )}
      <Typography color="primary" className={classes.menuText}>
        {t('app.exit')}
      </Typography>
    </Button>
  );

  const renderVancoLogo = () => (
    <img
      src={vancoMobileLogo}
      alt="Vanco Mobile Logo"
      className={classes.mobileLogo}
    />
  );

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {isLoggedIn && showMenu && !menuOpen && (
          <Button
            onClick={() => {
              setMenuOpen(true);
            }}
            className={classes.mobileWizardButton}
          >
            <Typography color="primary" className={classes.menuText}>
              {t('app.menu')}
            </Typography>
          </Button>
        )}
        {isLoggedIn && showMenu && menuOpen && (
          <Button
            onClick={() => {
              setMenuOpen(false);
            }}
            className={classes.mobileWizardButton}
          >
            <Typography color="primary" className={classes.menuText}>
              {t('app.close')}
            </Typography>
          </Button>
        )}
        {isLoggedIn &&
          isDesktop &&
          isTile &&
          (newWizard ? (
            <LinkButton
              to={exitPath}
              className={classes.exitButtonWizard}
              variant="outlined"
            >
              {t('app.exit')}
            </LinkButton>
          ) : (
            <LinkButton to={exitPath} className={classes.exitButton}>
              <ChevronLeft color="primary" />
              <Typography color="primary" className={classes.menuText}>
                {t('app.saveExit')}
              </Typography>
            </LinkButton>
          ))}
        {isLoggedIn &&
          (isDesktop || isVONewWizard) &&
          !isTile &&
          !isMobilePage &&
          (newWizard ? (
            <LinkButton
              to={exitPath}
              className={classes.exitButtonWizard}
              variant="outlined"
            >
              {t('app.exit')}
            </LinkButton>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isOrganizationPage ? (
                renderOrganizationExitButton(true)
              ) : (
                <LinkButton to={exitPath} className={classes.exitButton}>
                  {isVONewWizard ? (
                    <ArrowBackIcon
                      color="primary"
                      className={classes.arrowIcon}
                    />
                  ) : (
                    <ExitToAppIcon
                      color="primary"
                      className={classes.exitIcon}
                    />
                  )}

                  <Typography color="primary" className={classes.menuText}>
                    {t('app.exit')}
                  </Typography>
                </LinkButton>
              )}
            </>
          ))}
        {isLoggedIn && isDesktop && isMobilePage && (
          <LinkButton to={exitPath} className={classes.exitButton}>
            <ChevronLeft color="primary" />
            <Typography color="primary" className={classes.menuText}>
              {t('app.exit')}
            </Typography>
          </LinkButton>
        )}
        <div className={classes.grow}>
          {isOrganizationPage ? (
            <Button
              onClick={onExit}
              disableRipple
              className={classes.logoButton}
            >
              {renderVancoLogo()}
            </Button>
          ) : (
            <Link to="/">{renderVancoLogo()}</Link>
          )}
        </div>
        {isLoggedIn && !isDesktop && isTile && (
          <LinkButton to={exitPath} className={classes.mobileWizardButton}>
            <Typography color="primary" className={classes.menuText}>
              {t('app.exit')}
            </Typography>
          </LinkButton>
        )}
        {isLoggedIn && !isDesktop && isOrganizationPage && (
          <div className={classes.mobileWizardButton}>
            {renderOrganizationExitButton(false)}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object),
  newWizard: PropTypes.bool,
  features: PropTypes.object,
  setForcePreventModal: PropTypes.func.isRequired,
  forcePreventModal: PropTypes.bool.isRequired,
};

Navbar.defaultProps = {
  pages: [],
  newWizard: false,
  features: {},
};

export default withStyles(styles)(withRouter(Navbar));
