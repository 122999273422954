import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export default function usePreventModal(backUrl) {
  const history = useHistory();
  const [cancel, setCancel] = useState(false);

  const goBack = useCallback(() => {
    history.push(backUrl);
  }, [history, backUrl]);

  useEffect(() => {
    if (cancel) goBack();
  }, [cancel, goBack]);

  function onCancel() {
    setCancel(true);
  }

  return {
    goBack,
    onCancel,
    cancelLeave: cancel,
  };
}
