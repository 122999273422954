import { bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import PrimaryButton from '../../PrimaryButton';
import styles from './styles';

export function EditorButtons({ classes, onHandleSubmit, onCancel, dirty }) {
  const { t } = useTranslation();

  return (
    <section className={classes.section}>
      <Button
        size="medium"
        color="primary"
        onClick={onCancel}
        className={classes.button}
      >
        {t('button.cancel')}
      </Button>
      <PrimaryButton
        size="medium"
        onClick={onHandleSubmit}
        disabled={!dirty}
        className={classes.saveButton}
      >
        {t('button.save')}
      </PrimaryButton>
    </section>
  );
}

EditorButtons.propTypes = {
  classes: object.isRequired,
  onHandleSubmit: func.isRequired,
  onCancel: func.isRequired,
  dirty: bool.isRequired,
};

export default withStyles(styles)(EditorButtons);
