import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetTile, useGetTileId } from '../../../utils/hooks';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

function TileWrapper(Component, pageTitleKey) {
  function Wrapped(props) {
    const { t } = useTranslation();
    usePageTitle(t(pageTitleKey));
    const tileId = useGetTileId();
    const features = useSelector((state) => state.features?.features || {});

    const { data: tile, status } = useGetTile(tileId, {
      refetchOnWindowFocus: false,
    });

    const loadingTile = status === 'loading';
    if (loadingTile || !tile || tile?.type === '') {
      return null;
    }
    return (
      <Component
        tile={tile}
        features={features}
        loadingTile={loadingTile}
        {...props}
      />
    );
  }
  return Wrapped;
}

export default TileWrapper;
