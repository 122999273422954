import { useEffect } from 'react';
import { string, func, object, bool, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Disable } from 'react-disable';

import { useHistory } from 'react-router-dom';
import UnarchivePage from '../UnarchivePage';
import PageTitle from '../PageTitle';
import CustomFormik from '../CustomFormik';
import Form from './Form';
import { isArchived } from '../../../utils/page';
import { defaultPage } from '../../../globals/defaults';
import { createSchema, maxLengths } from './validation';
import { PAGE_ACTIVE, VO_WIZARD } from '../../../globals/constants';

export function SiteSettings({
  pageTitleKey,
  goToPrev,
  page,
  pages,
  createPage,
  savePage,
  loadingSave,
  loadingCreate,
  newPage,
  resetNewPage,
  updatePage,
  isFinished,
  onUnarchivePage,
  setError,
}) {
  const { t } = useTranslation();
  const schema = createSchema({ page, pages });
  const history = useHistory();
  const isWizard = !!goToPrev;
  const disabled = isArchived(page);

  useEffect(() => {
    if (isWizard && newPage.id) {
      const historyData = {
        pathname: `/${newPage.id}/page-settings`,
        state: { from: VO_WIZARD },
      };
      history.push(historyData);
      resetNewPage();
    }
  }, [newPage, resetNewPage, history, isWizard]);

  const createNewPage = async (values) => {
    await createPage({
      ...defaultPage,
      ...values,
    });
  };

  const handleSubmit = (values) => {
    const formPage = {
      ...page,
      ...values,
      status: PAGE_ACTIVE,
    };

    if (page?.id) {
      savePage(formPage);
    } else {
      createNewPage(formPage);
    }
  };

  return (
    <>
      {disabled && <UnarchivePage onClick={onUnarchivePage} />}
      <Disable disabled={disabled}>
        <PageTitle variant={isWizard ? 'default' : 'medium'}>
          {t(pageTitleKey)}
        </PageTitle>
        <CustomFormik
          onChange={updatePage}
          onErrorsChange={setError}
          enableReinitialize
          initialValues={{
            allowSocialSharing:
              page.allowSocialSharing ?? defaultPage.allowSocialSharing,
            pageName: page?.pageName || defaultPage.pageName,
            displayPageName:
              page.displayPageName ?? defaultPage.displayPageName,
          }}
          onSubmit={handleSubmit}
          render={(formikProps) => (
            <Form
              maxLengths={maxLengths}
              isWizard={isWizard}
              loading={loadingSave || loadingCreate}
              isFinished={isFinished}
              {...formikProps}
            />
          )}
          validationSchema={schema}
        />
      </Disable>
    </>
  );
}

SiteSettings.propTypes = {
  pageTitleKey: string,
  goToNext: func,
  goToPrev: func,
  page: object,
  pages: arrayOf(object).isRequired,
  createPage: func.isRequired,
  savePage: func.isRequired,
  loadingSave: bool.isRequired,
  loadingCreate: bool.isRequired,
  newPage: object.isRequired,
  resetNewPage: func.isRequired,
  updatePage: func,
  setError: func,
  isFinished: bool,
  onUnarchivePage: func,
  isEditor: bool,
};

SiteSettings.defaultProps = {
  pageTitleKey: 'siteSettings.title',
  page: {},
  goToNext: null,
  goToPrev: null,
  updatePage: null,
  setError: null,
  isFinished: false,
  onUnarchivePage: null,
  isEditor: false,
};

export default SiteSettings;
