// GET tiles
export const GET_TILES_START = 'GET_TILES_START';
export const GET_TILES_FINISH = 'GET_TILES_FINISH';
export const GET_TILES_ERROR = 'GET_TILES_ERROR';

// SAVE tile
export const SAVE_TILE_START = 'SAVE_TILE_START';
export const SAVE_TILE_FINISH = 'SAVE_TILE_FINISH';
export const SAVE_TILE_ERROR = 'SAVE_TILE_ERROR';

// SAVE tiles
export const SAVE_TILES_START = 'SAVE_TILES_START';
export const SAVE_TILES_FINISH = 'SAVE_TILES_FINISH';
export const SAVE_TILES_ERROR = 'SAVE_TILES_ERROR';

// CLEAR TILES
export const CLEAR_TILES = 'CLEAR_TILES';

// CREATE tiles
export const CREATE_TILE_START = 'CREATE_TILE_START';
export const CREATE_TILE_FINISH = 'CREATE_TILE_FINISH';
export const CREATE_TILE_ERROR = 'CREATE_TILE_ERROR';

// DELETE tile
export const DELETE_TILE_START = 'DELETE_TILE_START';
export const DELETE_TILE_FINISH = 'DELETE_TILE_FINISH';
export const DELETE_TILE_ERROR = 'DELETE_TILE_ERROR';

// UPDATE image for tile
export const UPDATE_IMAGE_TILE_START = 'UPDATE_IMAGE_TILE_START';
export const UPDATE_IMAGE_TILE_FINISH = 'UPDATE_IMAGE_TILE_FINISH';
export const UPDATE_IMAGE_TILE_ERROR = 'UPDATE_IMAGE_TILE_ERROR';

// UPDATE tile
export const UPDATE_TILE = 'UPDATE_TILE';

// RESET methods
export const RESET_NEW_TILE = 'RESET_NEW_TILE';

// Clear temp image for tile
export const CLEAR_TEMP_IMAGE = 'CLEAR_TEMP_IMAGE';
