import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import CustomFormik from '../CustomFormik';

import { TILE_ACTIVE, TILE_PENDING } from '../../../globals/constants';
import { defaultTile } from '../../../globals/defaults';
import PageTitle from '../PageTitle';
import Form from './Form';
import { createSchema } from './validation';

export function TileReview({
  tile,
  goToPrev,
  goToNext,
  newTile,
  saveTile,
  loadingSave,
  updateTile,
  setError,
  isFinished,
  history,
  resetNewTile,
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const schema = createSchema();

  useEffect(() => {
    if (newTile.id && goToPrev) {
      history.push('/tiles/all');
      resetNewTile();
    }
  }, [newTile, history, resetNewTile, goToPrev]);

  const onSubmit = async (values) => {
    const formTile = {
      ...tile,
      ...values,
    };
    await saveTile(formTile);
    queryClient.setQueryData(['tile', formTile.id], formTile);
  };

  return (
    <>
      <PageTitle>{t('tile.review')}</PageTitle>
      <CustomFormik
        onChange={updateTile}
        onErrorsChange={setError}
        retrieveErrors
        initialValues={{
          status: tile.status === TILE_PENDING ? TILE_ACTIVE : tile.status,
          locations: tile.locations,
          visibility: true,
        }}
        onSubmit={onSubmit}
        render={(formikProps) => (
          <Form
            goToPrev={goToPrev}
            goToNext={goToNext}
            loading={loadingSave}
            isFinished={isFinished}
            tile={tile}
            {...formikProps}
          />
        )}
        validationSchema={schema}
      />
    </>
  );
}

TileReview.propTypes = {
  tile: PropTypes.object,
  goToPrev: PropTypes.func,
  goToNext: PropTypes.func,
  newTile: PropTypes.object.isRequired,
  saveTile: PropTypes.func.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  pageTitleKey: PropTypes.string,
  isFinished: PropTypes.bool,
  updateTile: PropTypes.func,
  setError: PropTypes.func,
  history: PropTypes.object.isRequired,
  resetNewTile: PropTypes.func.isRequired,
};

TileReview.defaultProps = {
  tile: defaultTile,
  goToPrev: null,
  goToNext: null,
  updateTile: null,
  setError: null,
  pageTitleKey: 'tile.reviewTile',
  isFinished: false,
};

export default TileReview;
