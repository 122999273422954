import { func, object, objectOf, string, bool } from 'prop-types';
import { Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';

import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import PageNameInput from '../../PageNameInput';
import AddressInputs from '../../AddressInputs';
import VancoMobileContactInputs from '../../VancoMobileContactInputs';

import { maxLengths } from '../../../../utils/validations';
import styles from './styles';

export function BasicInfoForm({
  classes,
  values,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  handleSubmit,
  goToPrev,
  loading,
  isFinished,
  handleReset,
  dirty,
}) {
  const { t } = useTranslation();

  const handleChange = e => {
    const { name, value } = e.target;
    setFieldValue({ target: { name, value } });
  };

  return (
    <Form>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>
            {t('mobileSetup.organizationName')}
          </Typography>
          <Typography className={classes.subtitle}>
            {t('mobileSetup.organizationName.description')}
          </Typography>
          <PageNameInput
            label="Organization name"
            value={values.pageName}
            error={errors.pageName}
            touched={touched.pageName}
            maxLength={maxLengths.pageName}
            handleBlur={handleBlur}
            handleChange={handleChange}
            autoFocus={(values.pageName || '').length < 1}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            className={[classes.title, classes.addressSubtitle].join(' ')}
          >
            {t('mobileSetup.organizationAddress')}
          </Typography>
          <Typography className={classes.subtitle}>
            {t('mobileSetup.organizationAddress.description')}
          </Typography>
          <AddressInputs
            values={values}
            maxLengths={maxLengths}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={[classes.title, classes.addressSubtitle].join(' ')}
            variant="body2"
          >
            {t('mobileSetup.contactInformation')}
          </Typography>
          <Typography className={classes.subtitle}>
            {t('mobileSetup.provideAtLeastOne')}
          </Typography>
          <VancoMobileContactInputs
            values={values}
            maxLengths={maxLengths}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          {!loading && goToPrev && (
            <WizardButtons
              isFinished={isFinished}
              dirty={dirty}
              goToNext={handleSubmit}
              goToPrev={goToPrev}
              undo={handleReset}
              hidePrevious
            />
          )}
          {loading && goToPrev && (
            <CircularProgress color="primary" className={classes.spinner} />
          )}
        </Grid>
      </Grid>
    </Form>
  );
}

BasicInfoForm.propTypes = {
  classes: objectOf(string).isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  setFieldValue: func.isRequired,
  handleBlur: func.isRequired,
  handleSubmit: func.isRequired,
  goToPrev: func.isRequired,
  loading: bool.isRequired,
  isFinished: bool.isRequired,
  handleReset: func.isRequired,
  dirty: bool.isRequired,
};

BasicInfoForm.defaultProps = {};

export default withStyles(styles)(BasicInfoForm);
