import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  setNewMobilePageModalOpen,
  patchLocation,
} from '../../../store/pages/actions';
import { createTile } from '../../../store/tiles/actions';
import VancoMobileSettings from './VancoMobileSettings';

export const mapStateToProps = (state) => ({
  newMobilePageModalOpen: state.pages.newMobilePageModalOpen,
  features: state.features?.features,
  wasInternal: state.user.user.wasInternal,
  organizationId: state.organizations.currentOrganization.id,
});

export const mapDispatchToProps = (dispatch) => ({
  tilesActions: bindActionCreators(
    {
      createTile,
      setNewMobilePageModalOpen,
      patchLocation,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VancoMobileSettings);
