const commonStyles = (theme) => ({
  spinnerContainer: {
    marginTop: 20,
    float: 'right',
    height: 40,
  },
  spinnerContainerWizard: {
    marginTop: 40,
    height: 46,
  },
  pageTitle: {
    marginBottom: '32px',
  },
  editorGutter: {
    marginBottom: theme.spacing(3),
  },
});

export default commonStyles;
