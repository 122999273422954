/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { ROLES } from 'globals/constants';
import { useFeatures } from 'utils/hooks';

import { PERMISSIONS_CHECKBOXES } from '../../../globals/grants';
import {
  Page,
  PageTitle,
  PageDescription,
  PagePaper,
  PrimaryButton,
} from '../../common';
import User from './User';
import NewUserModal from './NewUserModal';
import styles from './styles';
import RemoveUserModal from './RemoveUserModal';
import EditUserModal from './EditUserModal';
import EmailSettingsModal from './EmailSettingsModal';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';
import EditPermissionModal from './EditPermissionsModal';
import {
  hasSettingsPermission,
  DEFAULT_SETTINGS_PERMISSION,
} from './NewUserModal/helpers';

export function Users({
  classes,
  currentUser,
  isInternalSession,
  users,
  getUsers,
  orgId,
  createUser,
  loadingUserCreate,
  backendError,
  clearUserError,
  showCreateUserModal,
  setCreateUserModalOpen,
  showRemoveUserModal,
  setRemoveUserModalOpen,
  userToRemove,
  removeUser,
  loadingUserRemove,
  showEditUserModal,
  setEditUserModalOpen,
  showEmailSettingsModal,
  showEditUserPermissionsModal,
  setEmailSettingsModalOpen,
  setEditUserPermissionsModalOpen,
  loadingEmailSettings,
  userToEditEmail,
  userToEditRole,
  loadingUserEdit,
  transferOwnership,
  patchUser,
}) {
  const { t } = useTranslation();
  usePageTitle(t('users.title'));
  const { AdminUserPermissions } = useFeatures();

  useEffect(() => {
    getUsers(orgId);
  }, [getUsers, orgId]);

  const openNewUserModal = () => {
    setCreateUserModalOpen(true);
  };

  const closeCreateUserModal = () => {
    if (!loadingUserCreate) {
      setCreateUserModalOpen(false);
      clearUserError();
    }
  };

  const getUserPermissions = (accountGrants) => {
    if (!Array.isArray(accountGrants)) return [];

    const assignSettingsAsDefault = !hasSettingsPermission(accountGrants);

    /*
     * when nothing is checked, we want to assign the default settings permission
     */
    const assignedPermissions = assignSettingsAsDefault
      ? [...accountGrants, { ...DEFAULT_SETTINGS_PERMISSION }]
      : accountGrants;

    const userPermissions = assignedPermissions.filter((g) => g.value);
    return userPermissions;
  };

  const handleSendInvite = ({ accountGrants, ...restUser }) => {
    const newUser = AdminUserPermissions
      ? {
          ...restUser,
          accountGrants: getUserPermissions(accountGrants, restUser),
        }
      : restUser;

    createUser(newUser, orgId);
  };

  const closeRemoveUserModal = () => {
    setRemoveUserModalOpen(false);
  };

  const onUserRemove = (user) => {
    removeUser(user, orgId);
  };

  const closeEditUserModal = () => {
    if (!loadingUserEdit) {
      setEditUserModalOpen(false);
      clearUserError();
    }
  };

  const closeEmailSettingsModal = () => {
    if (!loadingEmailSettings) {
      setEmailSettingsModalOpen(false);
      clearUserError();
    }
  };

  const onUserEdit = () => {
    closeEditUserModal();
  };

  const onTransferOwnership = () => {
    transferOwnership(userToEditRole, orgId);
  };

  const onEmailSettingsEdit = async (user, values) => {
    await patchUser(user.idsuid, values);
  };

  const isYou = (user) => {
    const { idsuid, userName } = user;
    return (
      currentUser &&
      (idsuid === currentUser.uid ||
        (!currentUser.uid &&
          userName.toLowerCase() === currentUser.email.toLowerCase()))
    );
  };

  const canModifyEmailSettings = () => {
    const isAccountOwner = users.some(
      (u) =>
        isYou(u) &&
        (u.role === ROLES.ACCOUNT_OWNER ||
          u.role === ROLES.GLOBAL_ADMINISTRATOR),
    );
    return isAccountOwner || isInternalSession;
  };

  return (
    <Page>
      <PageTitle>{t('users.title')}</PageTitle>
      <PageDescription>{t('users.description')}</PageDescription>
      <PrimaryButton
        size="large"
        className={classes.addUser}
        onClick={openNewUserModal}
      >
        {t('users.add')}
      </PrimaryButton>
      <PagePaper>
        <Table className={classes.list}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2">{t('users.user')}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{t('users.role')}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <User
                key={user.userName}
                user={user}
                isYou={isYou(user)}
                canModifyEmailSettings={canModifyEmailSettings()}
                setRemoveUserModalOpen={setRemoveUserModalOpen}
                setEditUserModalOpen={setEditUserModalOpen}
                setEmailSettingsModalOpen={setEmailSettingsModalOpen}
                setEditUserPermissionsModalOpen={
                  setEditUserPermissionsModalOpen
                }
              />
            ))}
          </TableBody>
        </Table>
      </PagePaper>
      <NewUserModal
        open={showCreateUserModal}
        onClose={closeCreateUserModal}
        onSave={handleSendInvite}
        isLoading={loadingUserCreate}
        backendError={backendError}
        initialValues={{
          clientId: '',
          userName: '',
          role: ROLES.ADMINISTRATOR,
          hasAccount: null,
          accountGrants: PERMISSIONS_CHECKBOXES,
        }}
      />
      {showEditUserPermissionsModal && (
        <EditPermissionModal
          open={showEditUserPermissionsModal}
          onClose={() => setEditUserPermissionsModalOpen(false, null)}
        />
      )}
      {userToRemove && (
        <RemoveUserModal
          open={showRemoveUserModal}
          onClose={closeRemoveUserModal}
          onSave={onUserRemove}
          user={userToRemove}
          isLoading={loadingUserRemove}
        />
      )}
      {userToEditRole && (
        <EditUserModal
          open={showEditUserModal}
          onClose={closeEditUserModal}
          onUserEdit={onUserEdit}
          onTransferOwnership={onTransferOwnership}
          user={userToEditRole}
          isLoading={loadingUserEdit}
        />
      )}
      {userToEditEmail && (
        <EmailSettingsModal
          open={showEmailSettingsModal}
          onClose={closeEmailSettingsModal}
          onSave={onEmailSettingsEdit}
          user={userToEditEmail}
          isLoading={loadingEmailSettings}
        />
      )}
    </Page>
  );
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  isInternalSession: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  getUsers: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  createUser: PropTypes.func.isRequired,
  loadingUserCreate: PropTypes.bool.isRequired,
  loadingUserRemove: PropTypes.bool.isRequired,
  loadingUserEdit: PropTypes.bool.isRequired,
  backendError: PropTypes.string,
  clearUserError: PropTypes.func.isRequired,
  showCreateUserModal: PropTypes.bool.isRequired,
  setCreateUserModalOpen: PropTypes.func.isRequired,
  showRemoveUserModal: PropTypes.bool.isRequired,
  showEditUserPermissionsModal: PropTypes.bool.isRequired,
  setRemoveUserModalOpen: PropTypes.func.isRequired,
  userToRemove: PropTypes.object,
  removeUser: PropTypes.func.isRequired,
  showEditUserModal: PropTypes.bool.isRequired,
  setEditUserModalOpen: PropTypes.func.isRequired,
  showEmailSettingsModal: PropTypes.bool.isRequired,
  setEmailSettingsModalOpen: PropTypes.func.isRequired,
  loadingEmailSettings: PropTypes.bool.isRequired,
  userToEditEmail: PropTypes.object,
  userToEditRole: PropTypes.object,
  transferOwnership: PropTypes.func.isRequired,
  patchUser: PropTypes.func.isRequired,
  setEditUserPermissionsModalOpen: PropTypes.func.isRequired,
};

Users.defaultProps = {
  backendError: null,
  userToRemove: null,
  userToEditEmail: null,
  userToEditRole: null,
};

export default withStyles(styles)(Users);
