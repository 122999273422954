export default function styles(theme) {
  return {
    subtitle: {
      lineHeight: '1.43',
      maxWidth: 640,
    },
    text: {
      fontSize: 16,
      maxWidth: 640,
      color: theme.palette.secondary.light,
    },
    container: {
      marginTop: 10,
    },
    pageName: {
      marginBottom: 40,
    },
    contactInformationHeader: {
      marginTop: 30,
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
      '& button': {
        '&:first-of-type': {
          marginRight: 5,
        },
        [theme.breakpoints.down('xs')]: {
          '&:first-of-type': {
            marginRight: 0,
          },
        },
      },
    },
  };
}
