import BaseService from '../BaseService';

class AIAssistService extends BaseService {
  postAiEmailAssist = (data) => {
    return this.http.post('communication/assistant/completions', { ...data });
  };

  finishMyThought = (data) => {
    return this.http.post('communication/assistant/finishThought', { ...data });
  };
}

export default new AIAssistService();
