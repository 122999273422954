export default function styles() {
  return {
    cardIcon: {
      marginRight: '10px',
    },
    formItemGroup: {
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
  };
}
