export default function styles(theme) {
  return {
    buttons: {
      display: 'inline-block',
      marginTop: 40,
    },
    section: {
      marginTop: 8,
      float: 'right',
    },
    pagePaper: {
      padding: theme.spacing(3),
      paddingTop: 0,
    },
    button: {
      height: 40,
    },
  };
}
