import { commonStyles } from '../styles';

export default function styles(theme) {
  return {
    emptyList: {
      color: '#717171',
    },
    ...commonStyles(theme),
  };
}
