import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function ShowEndDate({ values, setFieldValue, disabled }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (disabled && values.showEndDate) {
      setFieldValue({ target: { name: 'showEndDate', value: !disabled } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFieldValue({ target: { name, value: checked } });
  };

  return (
    <div style={{ paddingBottom: '20px' }}>
      <Typography
        variant="body2"
        style={{ color: disabled ? '#8E9194' : '#222222' }}
      >
        {t('gift.showEndDate')}
      </Typography>
      <Typography style={{ color: disabled ? '#8E9194' : 'rgb(34, 34, 34)' }}>
        {t('gift.showEndDate.description')}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            id="showEndDate"
            name="showEndDate"
            data-testid="showEndDateTestId"
            color="primary"
            onChange={handleCheckboxChange}
            disabled={disabled || false}
            checked={values.showEndDate || false}
          />
        }
        label={t('gift.showEndDate.showOptional')}
        style={{ marginTop: '15px' }}
      />
    </div>
  );
}

ShowEndDate.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ShowEndDate;
