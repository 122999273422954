import { Form as FormikForm } from 'formik';
import { object, func, bool, objectOf, string } from 'prop-types';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import PrimaryButton from '../../PrimaryButton';

export function Form({
  classes,
  values,
  errors,
  setFieldValue,
  loading,
  dirty,
  onCancel,
}) {
  const { t } = useTranslation();
  const { addressRequired } = values;

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const boolValue = value === 'true';
    await setFieldValue({
      target: { name, value: boolValue },
    });
  };

  return (
    <FormikForm>
      <Grid item xs={12}>
        <Typography variant="h5">
          {t('vanco.mobile.donor.settings.subtitle')}
        </Typography>
        <Typography className={classes.description}>
          {t('vanco.mobile.donor.settings.description')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          component={RadioGroup}
          className={classes.radioGroup}
          onChange={handleChange}
        >
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Radio
                className={classes.radioButton}
                color="primary"
                name="addressRequired"
                checked={!addressRequired}
                value="false"
              />
            }
            label={
              <Typography className={classes.formSubtitle}>
                {t('vanco.mobile.donor.settings.no')}
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Radio
                name="addressRequired"
                className={classes.radioButton}
                color="primary"
                checked={addressRequired}
                value="true"
              />
            }
            label={
              <Typography className={classes.formSubtitle}>
                {t('vanco.mobile.donor.settings.yes')}
              </Typography>
            }
          />
        </RadioGroup>
      </Grid>
      <Grid item className={classes.buttonsContainer}>
        {!loading ? (
          <>
            <Button onClick={onCancel} color="primary" size="large">
              {t('button.cancel')}
            </Button>
            <PrimaryButton
              size="large"
              type="submit"
              disabled={!dirty || Object.keys(errors || {}).length > 0}
            >
              {t('button.save')}
            </PrimaryButton>
          </>
        ) : (
          <CircularProgress
            color="primary"
            data-testid="mobile-setup-spinner"
          />
        )}
      </Grid>
    </FormikForm>
  );
}

Form.propTypes = {
  classes: objectOf(string).isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  setFieldValue: func.isRequired,
  loading: bool.isRequired,
  dirty: bool.isRequired,
  onCancel: func,
};

Form.defaultProps = {
  onCancel: undefined,
};

export default withStyles(styles)(Form);
