import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { withStyles } from '@material-ui/core/styles';

import { Skeleton } from '@material-ui/lab';
import { constants } from '../../../globals';
import { CustomDrawer, PrimaryButton } from '..';
import tileNavConfig from '../../navigation/WizardLayout/tile.config';
import MenuItem from '../../navigation/MenuItem';
import { SIDEBAR_WIDTH_TILE } from '../../../globals/dimensions';
import styles from './styles';
import { useGetTile } from '../../../utils/hooks';

export function EditDrawer({
  classes,
  moveToActive,
  moveToArchived,
  selectedTile,
  onClose,
  deleteTile,
  isOpen,
}) {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const { data: tile, status } = useGetTile(selectedTile.id, {
    refetchOnWindowFocus: false,
  });
  const loaded = status === 'success';

  const editPath =
    // eslint-disable-next-line no-nested-ternary
    selectedTile.status === constants.TILE_PENDING
      ? loaded
        ? tileNavConfig(tile)
            .find((step) => step.next)
            .path.replace(':tileId', tile.id)
        : '' // This disables the Edit button while loading (tileNavConfig requires all campaign details to work properly)
      : `/tile/${selectedTile.id}`;

  const closeDrawer = () => {
    setShowConfirm(false);
    onClose();
  };

  return (
    <CustomDrawer
      className={classes.editDrawer}
      anchor="right"
      size={SIDEBAR_WIDTH_TILE}
      open={isOpen}
      onClose={closeDrawer}
      blur
      elevated
    >
      {!showConfirm && (
        <>
          <Button className={classes.closeButton} onClick={onClose}>
            <CloseIcon className={classes.closeIcon} />
            <Typography variant="body1" className={classes.closeText}>
              {t('app.close')}
            </Typography>
          </Button>
          <Typography className={classes.titleNoBorder} variant="h2">
            {selectedTile && selectedTile.title}
          </Typography>
          {loaded && (
            <>
              {tile.image && tile.image.src && (
                <div
                  data-testid="image"
                  className={classes.image}
                  style={{ backgroundImage: `url(${tile.image.src})` }}
                />
              )}
              {tile.shortDescription && (
                <Typography
                  variant="body1"
                  className={
                    tile.image.src
                      ? classes.description
                      : classes.onlyDescription
                  }
                >
                  {tile.shortDescription}
                </Typography>
              )}
            </>
          )}
          <div className={classes.divider} />
          {selectedTile.status === constants.TILE_PENDING && !loaded ? (
            <Skeleton variant="rect" height="52px" width={SIDEBAR_WIDTH_TILE} />
          ) : (
            <MenuItem
              label={t('tile.editDetails')}
              active={false}
              linkPath={editPath}
            />
          )}

          {selectedTile &&
            ![constants.TILE_ACTIVE, constants.TILE_PENDING].includes(
              selectedTile.status,
            ) && (
              <MenuItem
                label={t('tile.unarchive')}
                active={false}
                onClick={moveToActive}
              />
            )}
          {selectedTile &&
            ![constants.TILE_PENDING, constants.TILE_ARCHIVED].includes(
              selectedTile.status,
            ) && (
              <MenuItem
                label={t('tile.archive')}
                active={false}
                onClick={() => moveToArchived(selectedTile)}
              />
            )}
          {selectedTile && (
            <MenuItem
              label={t('tile.delete')}
              active={false}
              onClick={() => {
                return deleteTile(selectedTile);
              }}
            />
          )}
        </>
      )}
      {showConfirm && (
        <div className={classes.confirm}>
          <Typography variant="h4" className={classes.confirmTitle}>
            {t('tile.deleteTile')}
          </Typography>
          <Typography variant="body1" className={classes.confirmText}>
            {t('tile.areYouSure')}
          </Typography>
          <PrimaryButton
            fullWidth
            data-testid="delete-tile-button"
            onClick={() => {
              deleteTile();
              closeDrawer();
            }}
            className={classes.confirmButton}
          >
            {t('tile.deleteTile')}
          </PrimaryButton>
          <Button
            onClick={() => {
              setShowConfirm(false);
            }}
            color="primary"
          >
            <ChevronLeft />
            <span>{t('app.goBack')}</span>
          </Button>
        </div>
      )}
    </CustomDrawer>
  );
}

EditDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  moveToActive: PropTypes.func.isRequired,
  moveToArchived: PropTypes.func.isRequired,
  selectedTile: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  deleteTile: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

EditDrawer.defaultProps = {
  selectedTile: null,
  isOpen: false,
};

export default withStyles(styles)(EditDrawer);
