const common = () => ({
  padding: 16,
  backgroundColor: '#F5F5F6',
  borderRadius: 8,
});

const commonTitle = () => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
  '& svg': {
    marginRight: 8,
  },
});

export default function styles() {
  return {
    disclosure: {
      ...common(),
    },
    disclosureInline: {
      ...common(),
      display: 'flex',
    },
    disclosureSingle: {
      ...common(),
      display: 'flex',
      padding: 22,
    },
    disclosureTitle: {
      paddingBottom: 6,
      ...commonTitle(),
    },
    disclosureTitleInline: {
      ...commonTitle(),
    },
    disclosureMessage: {
      fontSize: '1.4rem',
      marginLeft: 28,
    },
    disclosureMessageInline: {
      marginLeft: 4,
      display: 'flex',
      alignItems: 'center',
    },
    disclosureMessageSingle: {
      marginLeft: 14,
    },
    icon: {
      width: 24,
      height: 24,
      alignSelf: 'start',
      '& path': {
        fill: '#5E6266',
      },
    },
    learMoreButton: {
      marginTop: -4,
    },
  };
}
