import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(4),
  },
  spinnerContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
}));
