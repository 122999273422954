import { bool, func, objectOf, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as CopyIcon } from '../../../../assets/copyIcon.svg';
import Modal from '../../Modal';

import styles from './styles';

function InviteByCodeModal({
  classes,
  invitationCode,
  open,
  onClose,
  onCopyCode,
}) {
  const { t } = useTranslation();

  const onCopy = () => {
    onCopyCode(invitationCode);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title className={classes.modalTitle}>
        {t('vanco.mobile.home.users.modal.title')}
      </Modal.Title>
      <Modal.Content className={classes.modalContent}>
        <Typography className={classes.title}>
          {t('vanco.mobile.home.users.modal.invite.code')}
        </Typography>
        <Typography className={classes.description}>
          {t('vanco.mobile.home.users.modal.description')}
        </Typography>
        <div className={classes.invitationCodeContaienr}>
          <Typography component="span">{invitationCode}</Typography>
          <Typography
            onClick={onCopy}
            component="span"
            className={classes.copyControl}
          >
            <CopyIcon />
            {t('vanco.mobile.home.users.modal.copy.code')}
          </Typography>
        </div>
      </Modal.Content>
    </Modal>
  );
}

InviteByCodeModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func,
  invitationCode: string,
  onCopyCode: func.isRequired,
};

InviteByCodeModal.defaultProps = {
  open: false,
  onClose: null,
  invitationCode: null,
};

export default withStyles(styles)(InviteByCodeModal);
