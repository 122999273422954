export default () => ({
  modalTitle: {
    padding: '16px 24px',
  },
  modalContent: {
    padding: '14px 24px 24px 24px',
  },
  modalActions: {
    padding: 24,
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 700,
    color: '#222222',
  },
  description: {
    color: '#222222',
  },
  cancelButton: {
    marginRight: 10,
  },
  cancelButtonText: {
    fontWeight: 700,
    borderRadius: 6,
  },
  sendButtonText: {
    color: 'inherit',
  },
});
