import { NAVBAR_HEIGHT } from '../../../globals/dimensions';

export default function styles(theme) {
  return {
    container: {
      flex: 1,
      padding: '91px 51px',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        margin: 0,
      },
    },
    main: {
      display: 'flex',
      flex: 1,
      height: '100%',
      paddingTop: NAVBAR_HEIGHT,
      overflow: 'hidden',
    },
    contentWrap: {
      display: 'flex',
      alignItems: 'flex-start',
      flex: 1,
      overflow: 'auto',
      height: '100%',
    },
    contentContainer: {
      display: 'flex',
      textAlign: 'center',
      flex: 1,
      flexDirection: 'column',
      padding: 42,
      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },
    },
    icon: {
      marginRight: '1rem',
      verticalAlign: 'bottom',
    },
    title: {
      fontSize: '2rem',
      lineHeight: 'normal',
      marginBottom: '2rem',
    },
    body: {
      width: '40rem',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    button: {
      marginTop: 36,
    },
    colorIcon: {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  };
}
