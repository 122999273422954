import PropTypes from 'prop-types';
import {
  Button,
  MenuItem,
  TextField,
  Typography,
  withStyles,
  Grid,
} from '@material-ui/core';
import range from 'lodash/range';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@material-ui/icons/Clear';
import { addFeesToAmount, getCurrencyFormat } from '../../../../utils/basket';
import * as globals from '../../../../globals/constants';

import styles from './styles';
import { formatBasketItemDate } from '../../../../utils/calendar';

/**
 * Placeholder component for items in the basket. Will need to be expanded with whatever details we decide to put in the
 * side basket view widget
 */
export function BasketItem(props) {
  const {
    item,
    onDelete,
    showProcessingFees,
    processingFee,
    classes,
    onChangeQuantity,
  } = props;
  const { title, frequency, quantity, itemAmount, memoLine } = item;

  const { t } = useTranslation();

  const onDeleteClick = () => {
    onDelete(item);
  };

  const formattedStartDate = formatBasketItemDate(item.startDate);
  const formattedEndDate = item.endDate && formatBasketItemDate(item.endDate);
  const { memoLine: memoLineDetails } = item?.campaignDetails
    ?.transactionItems[0] ?? { memoLine: null };
  // Since it's possible that removed frequencies still may
  // be returned by the backend, we need to at least make sure
  // the app doesn't throw an error in the shouldn't-happen-case
  // where one is returned
  const formattedFrequency =
    globals.PAYMENT_FREQUENCY_TRANSLATION_KEYS_MAP?.[frequency] ||
    'basket.unknownFrequencyTotal';

  let amount = parseFloat(item.amount, 10);
  if (showProcessingFees) {
    amount = addFeesToAmount(amount, processingFee);
  }

  const quantityChoices = range(1, globals.MAX_PAYMENT_ITEMS + 1); // From 1 to max quantity

  return (
    <div className={classes.basketItemRoot} data-testid="basket-item">
      <Grid
        container
        className={classes.itemHeader}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6} id="headerTitle" data-testid="headerTitle">
          <Typography variant="body2">{title}</Typography>
        </Grid>
        <Grid
          container
          item
          xs={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="body2" id="amount" data-testid="amount">
              {getCurrencyFormat(amount)}
            </Typography>
          </Grid>
          <Grid item className={classes.itemButtonContainer}>
            <Button
              id="basket-item-delete-button"
              data-testid="basket-item-delete-button"
              className={classes.itemHeaderLink}
              onClick={onDeleteClick}
            >
              <ClearIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {quantity && getCurrencyFormat && (
        <Typography component="div">
          <div className={classes.itemText}>
            <div>
              <span id="amountLabel" data-testid="quantityLabel">
                {t('tile.quantity')}:
              </span>
              <TextField
                className={classes.quantitySelect}
                select
                value={quantity}
                InputProps={{ disableUnderline: true }}
                onChange={(event) => {
                  onChangeQuantity(event.target.value);
                }}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    variant: 'menu',
                    PaperProps: { style: { maxHeight: 150, maxWidth: 60 } },
                  },
                }}
              >
                {quantityChoices.map((i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <span id="amount" data-testid="amount-ea">
              {getCurrencyFormat(itemAmount)} ea
            </span>
          </div>
        </Typography>
      )}

      <Grid
        container
        className={classes.itemHeader}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6} id="detailTitle" data-testid="detailTitle">
          <Typography component="div">
            {frequency !== globals.PAYMENT_FREQUENCY.ONE_TIME_NOW && (
              <>
                <div className={classes.itemText}>
                  <span id="startDate" data-testid="startDate">
                    {formattedStartDate}
                  </span>
                </div>
                {formattedEndDate && (
                  <div className={classes.itemText}>
                    <span data-testid="endDate">{formattedEndDate}</span>
                  </div>
                )}
              </>
            )}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography id="frequency" data-testid="frequency">
              <span data-testid="start-date-description">
                {t(formattedFrequency)}
              </span>
              {formattedEndDate && (
                <div className={classes.itemText}>
                  <span>{t('basket.endDate')}</span>
                </div>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {memoLine && (
        <Typography component="div">
          <div className={classes.itemText}>
            <span className={classes.leftMemo}>
              {memoLineDetails.textField}
            </span>
            <span id="memoTextField" className={classes.rightMemo}>
              {memoLine}
            </span>
          </div>
        </Typography>
      )}
    </div>
  );
}

BasketItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    frequency: PropTypes.string.isRequired,
    quantity: PropTypes.number,
    itemAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    memoLine: PropTypes.string,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    amount: PropTypes.string.isRequired,
    campaignDetails: PropTypes.object,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  showProcessingFees: PropTypes.bool,
  processingFee: PropTypes.number,
};

BasketItem.defaultProps = {
  showProcessingFees: false,
  processingFee: null,
};

export default withStyles(styles)(BasketItem);
