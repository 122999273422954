import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  FAITH_FORWARD_EVENT_TYPE,
  VANCO_VIDEO_TYPE,
  VIDEO_ROUTES,
} from '../../../globals/constants';
import { setSuccess } from '../../../store/alerts/actions';
import { copyText, validations } from '../../../utils';
import useSmartLibraryActions from '../../../utils/hooks/useSmartLibraryActions';
import { EmbedModal, QRCodeModal } from '../../common';
import BaseVideoComponent from '../../common/BaseVideoComponent';
import { useVideoDisplayFlags } from '../../../utils/hooks';

const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 'auto',
    overflow: 'hidden',
  },
}));

/**
 * Also know as SmartLibrary
 * @returns {JSX.Element}
 */
function VideoOnDemand() {
  const { shouldDisplaySmartLibrary, redirectToErrorFromLibrary } =
    useVideoDisplayFlags();
  const history = useHistory();

  const redirectToPaywall = () => {
    history.push(VIDEO_ROUTES.smartLibraryPaywall);
  };

  const redirectToError = () => history.push('/*');

  /*
   * Prevent users to use premium features if disabled
   */
  useEffect(() => {
    if (redirectToErrorFromLibrary) {
      redirectToError();
      return;
    }

    if (!shouldDisplaySmartLibrary) {
      redirectToPaywall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDisplaySmartLibrary, redirectToErrorFromLibrary]);

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { primaryLocationUri } = useSelector((state) => ({
    primaryLocationUri:
      state.pages.pages?.find((c) => c.primary === true)?.locationURI || '',
  }));

  const siteLink = `${window.env.REACT_APP_DON_URL}/${primaryLocationUri}/videos`;

  const {
    embedModalOpen,
    openEmbedModal,
    closeEmbedModal,
    qrCodeModalOpen,
    openQRCodeModal,
    onCloseQRCodeModal,
    onErrorQRCodeModal,
  } = useSmartLibraryActions();

  const onViewSite = () => {
    if (validations.urlRegex.test(siteLink)) window.open(siteLink, '_blank');
  };

  const onCopySite = () => {
    copyText(siteLink);
    dispatch(setSuccess(t('siteSettings.linkCopied')));
  };

  const customEventsMap = {
    [FAITH_FORWARD_EVENT_TYPE.openVideoFeed]: onViewSite,
    [FAITH_FORWARD_EVENT_TYPE.copyVideoFeedLink]: onCopySite,
    [FAITH_FORWARD_EVENT_TYPE.copyEmbedCode]: openEmbedModal,
    [FAITH_FORWARD_EVENT_TYPE.openQRCode]: openQRCodeModal,
    [FAITH_FORWARD_EVENT_TYPE.signupRequired]: redirectToPaywall,
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <BaseVideoComponent
          variant={VANCO_VIDEO_TYPE.VIDEO}
          customEventsMap={customEventsMap}
        />
      </div>
      <EmbedModal
        domain={window.env.REACT_APP_DON_URL}
        locationURI={`${primaryLocationUri}/videos`}
        onClose={closeEmbedModal}
        open={embedModalOpen}
        variant="library"
      />
      <QRCodeModal
        siteLink={siteLink}
        open={qrCodeModalOpen}
        onClose={onCloseQRCodeModal}
        onError={onErrorQRCodeModal}
        name={t('vancoVideo.qr.modal.title')}
      />
    </>
  );
}

export default VideoOnDemand;
