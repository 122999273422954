export default (theme) => ({
  desktopLogo: {
    height: 24,
  },
  mobileLogo: {
    height: 34,
    display: 'block',
  },
  grow: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  orgName: {
    fontWeight: 'bold',
    marginLeft: 8,
  },
  swapIcon: {
    marginLeft: 10,
    color: theme.palette.icon.default,
  },
  select: {
    fontWeight: 'bold',
    marginLeft: 20,
    '& .MuiSelect-select': {
      borderRadius: 10,
      padding: '10px 30px 10px 10px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  },
  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
