import PropTypes, { oneOf } from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import { Divider } from '@material-ui/core';
import styles from './styles';

function PageDivider({ classes, variant }) {
  const dividerClasses = clsx({
    [classes.divider]: variant === 'default',
    [classes.dividerEditor]: variant === 'editor',
    [classes.dividerTitle]: variant === 'title',
    [classes.dividerWide]: variant === 'wide',
    [classes.dividerWideEditor]: variant === 'wideEditor',
    [classes.dividerStreamSite]: variant === 'streamSite',
  });
  return <Divider className={dividerClasses} data-testid="page-divider" />;
}

PageDivider.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: oneOf([
    'default',
    'editor',
    'wide',
    'wideEditor',
    'title',
    'streamSite',
  ]),
};

PageDivider.defaultProps = {
  variant: 'default',
};

export default withStyles(styles)(PageDivider);
