export default function styles(theme) {
  return {
    container: {
      margin: '32px 24px 0 0',
      padding: '24px 12px 12px 12px',
      height: 'auto',
      overflow: 'hidden',
      backgroundColor: theme.palette.surface.subdued,
    },
    containerDeprecated: {
      padding: '53px 32px 15px 32px',
      boxShadow: 'none',
      height: '100%',
      overflow: 'auto',
    },
    title: {
      marginLeft: 24,
      fontSize: 18,
    },
    titleDeprecated: {
      paddingBottom: 15,
    },
    sectionLink: {
      cursor: 'pointer',
    },
    sectionLinkInternal: {
      backgroundColor: '#F0F1F2',
      margin: '0 12px',
      borderRadius: 6,
    },
    sectionLinkInternalEmpty: {
      margin: '0 12px',
      borderRadius: 6,
    },
    divider: {
      marginLeft: -24,
      width: 312,
      marginTop: 24,
      marginBottom: 8,
    },
    modDivider: {
      width: 300,
    },
  };
}
