import { connect } from 'react-redux';
import moment from 'moment';
import StreamPageHeader from './SteamPageHeader';

export function mapStateToProps(state) {
  return {
    organizationId: state.user.user.organizationId,
    organizationStreamingDisableDate: state.user.user
      .organizationStreamingDisableDate
      ? moment(state.user.user.organizationStreamingDisableDate)
      : undefined,
    organizationStreamingDisabled:
      state.user.user.organizationStreamingDisabled,
    smartLibraryDisableDate: state.user.user.smartLibraryDisableDate
      ? moment(state.user.user.smartLibraryDisableDate)
      : undefined,
    smartClipsDisableDate: state.user.user.smartClipsDisableDate
      ? moment(state.user.user.smartClipsDisableDate)
      : undefined,
  };
}

export default connect(mapStateToProps)(StreamPageHeader);
