import { useRef, useState } from 'react';
import { arrayOf, func, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Button from '@material-ui/core/Button';
import { useLocation } from 'react-router-dom';
import { Page, PagePaper } from '../../../common';
import ArchivedList from '../ArchivedList';
import styles from './styles';
import useScrollOnceToBottom from '../../../../utils/hooks/useScrollOnceToBottom';
import { parseQueryParamsToObject } from '../../../../utils/url';

export function ArchivedTiles({
  classes,
  tiles,
  onItemClick,
  onDeleteTile,
  onUnarchiveTile,
  pages,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const [viewArchivedTiles, setViewArchivedTiles] = useState(false);
  const buttonRef = useRef(null);

  const onArchiveTiles = () => {
    setViewArchivedTiles(!viewArchivedTiles);
  };

  const readyForScrollToBottom = parseQueryParamsToObject(
    location.search,
  ).showArchived;
  useScrollOnceToBottom(readyForScrollToBottom, buttonRef, () => {
    setViewArchivedTiles(true);
  });

  return (
    <>
      <Button
        className={classes.showArchivedButton}
        onClick={onArchiveTiles}
        size="large"
        ref={buttonRef}
      >
        {t('tile.archivedTiles')}
        {viewArchivedTiles ? (
          <KeyboardArrowUp className={classes.arrowIcon} />
        ) : (
          <KeyboardArrowDown className={classes.arrowIcon} />
        )}
      </Button>
      {viewArchivedTiles && (
        <Page className={classes.archivedPage}>
          <PagePaper className={classes.tableContainer}>
            <ArchivedList
              tiles={tiles}
              onItemClick={onItemClick}
              onDeleteTile={onDeleteTile}
              onUnarchiveTile={onUnarchiveTile}
              pages={pages}
            />
          </PagePaper>
        </Page>
      )}
      {!viewArchivedTiles && <div className={classes.emptySpace} />}
    </>
  );
}

ArchivedTiles.propTypes = {
  classes: object.isRequired,
  tiles: arrayOf(object).isRequired,
  onItemClick: func.isRequired,
  onDeleteTile: func.isRequired,
  onUnarchiveTile: func.isRequired,
  pages: arrayOf(object).isRequired,
};

export default withStyles(styles)(ArchivedTiles);
