import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { shape, func, objectOf, bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Button, Grid, Hidden, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { Disable } from 'react-disable';
import PageTitle from '../PageTitle';
import PageDescription from '../PageDescription';
import Tiles from './Tiles';
import AddTile from './AddTile';
import UnarchivePage from '../UnarchivePage';
import ConfirmRemoveTileModal from './ConfirmRemoveTileModal';
import {
  useGetTilesByLocation,
  useHideTile,
  useSaveTileOrder,
} from '../../../utils/hooks';
import {
  getChannelName,
  getTilesToOrder,
  orderTiles,
} from '../../../utils/tiles';
import { isArchived } from '../../../utils/page';
import PrimaryButton from '../PrimaryButton';
import PreventLeavePageModal from '../PreventLeavePageModal';
import usePreventModal from '../../../utils/hooks/usePreventModal';
import AddTilesModal from './AddTilesModal/AddTilesModal';
import {
  VO_WIZARD,
  CHANNEL_TYPE,
  LOCATION_PAGE_LAYOUTS,
  LOCATION_PATCH_FIELDS,
} from '../../../globals/constants';
import styles from './styles';
import { ReactComponent as DetailLayoutIcon } from '../../../assets/pageLayouts/detailLayout.svg';
import { ReactComponent as ExpressLayoutIcon } from '../../../assets/pageLayouts/expressLayout.svg';
import detailPreview from '../../../assets/pageLayouts/detail-preview.png';
import expressPreview from '../../../assets/pageLayouts/express-preview.png';
import LargeIconButton from '../LargeIconButton';
import { usePatchLocation } from '../../../utils/hooks/pages';
import { setSuccess } from '../../../store/alerts/actions';

function TilesOrder({ classes, page, onUnarchivePage, features }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { onCancel } = usePreventModal('/mobile/home');

  const { data: tiles = [], status: getTilesStatus } = useGetTilesByLocation(
    page.id,
  );

  const { mutateAsync: patchLocation } = usePatchLocation(page);

  const tilesToOrder = useMemo(
    () => getTilesToOrder(tiles, page),
    [tiles, page],
  );

  const [reorderedTiles, setReorderedTiles] = useState(null);
  const [selectedLayout, setSelectedLayout] = useState(
    page.defaultView ?? LOCATION_PAGE_LAYOUTS.DETAIL,
  );

  const [isAddTilesModalOpen, setIsAddTilesModalOpen] = useState(false);
  const [tileToHide, setTileToHide] = useState(null);
  const { mutate: hideTile } = useHideTile();
  const { mutateAsync: saveTileOrder } = useSaveTileOrder();
  const closeModal = () => setTileToHide(null);
  const onConfirm = async () => {
    setTileToHide(null);
    await hideTile({
      tile: tileToHide,
      values: {
        locationIds: tileToHide.locations.map(({ id }) => id),
      },
    });
  };

  const hideTileInCurrentLocation = (tile) => {
    const newLocations = tile.locations.filter((a) => a.id !== page.id);
    const updatedTile = { ...tile, locations: newLocations };
    setTileToHide(updatedTile);
  };

  const sequenceEqual = (arr1, arr2) =>
    arr1.length === arr2.length && arr1.every((item, i) => item === arr2[i]);

  const onReorder = (newList) => {
    const oldOrder = tilesToOrder.map((a) => a.id);
    const newOrder = newList.map((a) => a.key);
    // If the order is changed but then returns to the original order
    // treat it as though the user had never changed anything.
    const ordered = sequenceEqual(oldOrder, newOrder)
      ? null
      : orderTiles(tilesToOrder, newList);
    setReorderedTiles(ordered);
  };

  const onSaveSettings = async () => {
    const promises = [];
    if (reorderedTiles !== null) {
      promises.push(saveTileOrder({ page, tiles: reorderedTiles }));
      setReorderedTiles(null);
    }
    if (page.defaultView !== selectedLayout) {
      promises.push(
        patchLocation({
          page,
          field: LOCATION_PATCH_FIELDS.DEFAULT_VIEW,
          data: selectedLayout,
        }),
      );
    }

    const result = await Promise.allSettled(promises);

    if (!result.some((res) => res.status === 'rejected')) {
      dispatch(setSuccess(t('page.settings.saved.successfully')));
    }
  };

  const closeAddTilesModal = () => {
    setIsAddTilesModalOpen(false);
    if (history?.location?.state?.from === VO_WIZARD) {
      const historyData = {
        pathname: history.location.pathname,
        state: { from: '' },
      };
      history.push(historyData);
    }
  };

  const isDirty =
    reorderedTiles !== null || page.defaultView !== selectedLayout;
  const channelName = getChannelName(page.channelId);
  const disabled = isArchived(page);

  useEffect(() => {
    // Disabled validation is required in case a user go back using the browser history
    if (!disabled && history?.location?.state?.from === VO_WIZARD) {
      setIsAddTilesModalOpen(true);
    }
  }, [disabled, history]);

  const renderPageStyle = () => (
    <Grid item md={12}>
      <PageTitle variant={features.ChannelManagement ? 'medium' : 'default'}>
        {t('pageLayout.pageStyle')}
      </PageTitle>
      <PageDescription>{t('pageLayout.description')}</PageDescription>
      <Grid className={classes.largeButtons}>
        <LargeIconButton
          icon={<DetailLayoutIcon />}
          isActive={selectedLayout === LOCATION_PAGE_LAYOUTS.DETAIL}
          onClick={() => setSelectedLayout(LOCATION_PAGE_LAYOUTS.DETAIL)}
        >
          {t('pageLayout.detail')}
        </LargeIconButton>
        <LargeIconButton
          icon={<ExpressLayoutIcon />}
          isActive={selectedLayout === LOCATION_PAGE_LAYOUTS.EXPRESS}
          onClick={() => setSelectedLayout(LOCATION_PAGE_LAYOUTS.EXPRESS)}
        >
          {t('pageLayout.express')}
        </LargeIconButton>
      </Grid>
    </Grid>
  );

  const renderPreviewImage = () => {
    if (selectedLayout === LOCATION_PAGE_LAYOUTS.DETAIL) {
      return <img src={detailPreview} alt="detail-layout" />;
    }
    return <img src={expressPreview} alt="express-layout" />;
  };

  const renderPreviewSection = () => (
    <Grid item className={classes.previewSection} xl={6}>
      <PageTitle variant={features.ChannelManagement ? 'medium' : 'default'}>
        {selectedLayout === LOCATION_PAGE_LAYOUTS.DETAIL &&
          t('pageLayout.preview.detail')}
        {selectedLayout === LOCATION_PAGE_LAYOUTS.EXPRESS &&
          t('pageLayout.preview.express')}
      </PageTitle>
      <Box boxShadow={3}>{renderPreviewImage()}</Box>
    </Grid>
  );

  const renderTilesOrder = () => (
    <Grid item md={12}>
      <PageTitle variant={features.ChannelManagement ? 'medium' : 'default'}>
        {t('tilesOrder.title')}
      </PageTitle>
      <PageDescription>
        {t(
          features.ChannelManagement && page.channelId === CHANNEL_TYPE.MOBILE
            ? 'tilesOrder.descriptionMobile'
            : 'tilesOrder.description',
        )}
      </PageDescription>
      {getTilesStatus !== 'loading' && (
        <>
          <Tiles
            tiles={reorderedTiles ?? tilesToOrder}
            hideTile={hideTileInCurrentLocation}
            onReorder={onReorder}
          />
          <AddTile onClick={() => setIsAddTilesModalOpen(true)} />

          {!features.ChannelManagement && (
            <PrimaryButton
              style={{ marginTop: 24 }}
              disabled={!isDirty}
              onClick={onSaveSettings}
            >
              {t('button.saveChanges')}
            </PrimaryButton>
          )}
        </>
      )}
    </Grid>
  );

  return (
    <>
      <PreventLeavePageModal shouldOpenOnLeave={isDirty} />
      {disabled && <UnarchivePage onClick={onUnarchivePage} />}
      <Disable disabled={disabled}>
        <Grid container>
          <Grid item container spacing={4} lg={12} xl={6}>
            {features.VOExpressView && renderPageStyle()}
            {renderTilesOrder()}
          </Grid>
          {features.VOExpressView && (
            <Hidden lgDown>{renderPreviewSection()}</Hidden>
          )}
          {features.ChannelManagement && (
            <Grid item md={12} className={classes.buttonsContainer}>
              <Button onClick={onCancel} color="primary" size="large">
                {t('button.cancel')}
              </Button>
              <PrimaryButton
                size="large"
                type="submit"
                disabled={!isDirty}
                onClick={onSaveSettings}
              >
                {t('button.save')}
              </PrimaryButton>
            </Grid>
          )}
        </Grid>
        <ConfirmRemoveTileModal
          removedChannelName={channelName}
          tileTitle={tileToHide?.title}
          closeModal={closeModal}
          pageId={page.id}
          onConfirm={onConfirm}
        />
        {isAddTilesModalOpen && (
          <AddTilesModal closeModal={closeAddTilesModal} pageId={page.id} />
        )}
      </Disable>
    </>
  );
}

TilesOrder.propTypes = {
  classes: objectOf(string).isRequired,
  page: shape({}).isRequired,
  onUnarchivePage: func,
  features: objectOf(bool),
};

TilesOrder.defaultProps = {
  onUnarchivePage: null,
  features: {},
};

export default withStyles(styles)(TilesOrder);
