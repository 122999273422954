/* eslint-disable camelcase */
const selectSessionUser = (state) => {
  const { given_name, family_name, name, email, tenant, managed } =
    state?.user?.session?.idTokenPayload ?? {};

  return {
    givenName: given_name,
    familyName: family_name,
    fullName: name,
    email,
    tenant,
    managed,
    accessToken: state?.user?.session?.accessToken,
  };
};
export default selectSessionUser;
