import BaseService from '../BaseService';

class VancoMobileGroupsService extends BaseService {
  getGroups() {
    return this.http.get('groups');
  }

  getGroup(id) {
    return this.http.get(`groups/${id}`);
  }
}

export default new VancoMobileGroupsService();
