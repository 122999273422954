import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openModal } from '../../../../store/modals/actions';
import OPEN_NEWTRANSACTION from '../../../../store/modals/types';
import ReportsIframe from './ReportsIframe';

const mapStateToProps = (state) => {
  return {
    modalIsOpen: state.modals.modalsOpen?.includes(OPEN_NEWTRANSACTION),
    orgId: state.organizations.currentOrganization.id,
    email: state.user.session.idTokenPayload.email,
  };
};
const mapDispatchToProps = (dispatch) => {
  return { modalActions: bindActionCreators({ openModal }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsIframe);
