import { Button, Divider, Typography, withStyles } from '@material-ui/core';
import { objectOf, string } from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@material-ui/icons/Language';
import { useState } from 'react';
import { useGetMobilePageEvent, useGetPage } from '../../../utils/hooks';
import Card, {
  CardTitle,
  CardList,
  CardListItem,
  CardMedia,
  CardMediaImage,
  CardMediaContent,
  CardText,
  CardDescription,
  CardDisclosure,
} from '../Card';
import { ReactComponent as GroupPublic } from '../../../assets/groupPublic.svg';
import { ReactComponent as PositionIcon } from '../../../assets/positionIcon.svg';
import { ReactComponent as GroupIcon } from '../../../assets/groupIcon.svg';
import { ReactComponent as TimeIcon } from '../../../assets/timeIcon.svg';
import { ReactComponent as ZoomIcon } from '../../../assets/zoomIcon.svg';
import {
  getFormatedFullDate,
  getFormatedHour,
  getTimeZoneAbbreviation,
} from '../../../utils/date';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';
import Page from '../Page';
import PageTitle from '../PageTitle';
import { stringToHTML } from '../../../utils/text/transformText';
import styles from './styles';
import EventDetailsModal from './EventDetailsModal/EventDetailsModal';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

export function VancoMobileEvent({ classes }) {
  const { t } = useTranslation();
  usePageTitle(t('vanco.mobile.home.users.viewUsers'));
  const history = useHistory();
  const { location } = history;
  const { id } = useParams();
  const { page } = useGetPage(CHANNEL_TYPE.MOBILE, PAGE_ACTIVE);
  const { event, isLoading } = useGetMobilePageEvent(id);
  const [openDetails, setOpenDetails] = useState(false);
  const now = new Date();
  const isUpcoming =
    (event?.dateTimeStart && new Date(event.dateTimeStart) >= now) || false;

  const singleDay =
    event?.dateTimeStart.getDay() === event?.dateTimeEnd.getDay();

  const isInPersonEvent =
    event?.locationType === 'person' && (event?.venue || event?.address);
  const isZoomEvent =
    !isInPersonEvent &&
    event?.onlineLocationType === 'zoom' &&
    event?.zoomMeetingURL !== null &&
    event?.zoomMeetingURL !== '';
  const isWebEvent =
    !isInPersonEvent &&
    !isZoomEvent &&
    event?.webLocationLink !== null &&
    event?.webLocationLink !== '';

  const onGoBack = () =>
    history.push(location?.state?.prevPath || '/mobile/events');

  const assistanceTitle = () => {
    let title;
    if (isUpcoming) {
      title = t('vanco.mobile.events.going');
    } else {
      title = t('vanco.mobile.events.went');
    }
    if (event?.hasAttendeeLimit) {
      title = title.concat(
        ` (${event.attendingCount} / ${event.attendeeLimit}  ${t(
          'vanco.mobile.events.available',
        )})`,
      );
    } else {
      title = title.concat(` (${event?.attendingCount})`);
    }
    return title;
  };

  const assistanceName = (user) => {
    let nameLabel = (user.name || '').trim() || user.email;
    if (user.guests > 0) nameLabel = nameLabel.concat(` + ${user.guests}`);

    return nameLabel;
  };

  const goToUserPage = (userId) => () =>
    history.push(`/mobile/user/${userId}`, {
      prevPath: `/mobile/event/${id}`,
    });

  const onOpenDetails = () => setOpenDetails(true);
  const onCloseDetails = () => setOpenDetails(false);

  return (
    <Page variant="cardContainer">
      <PageTitle
        onGoBack={onGoBack}
        className={classes.title}
        isLoading={isLoading}
        readOnly={!page.hasCommunityFeatures}
      >
        {event?.name}
      </PageTitle>
      <Card isLoading={isLoading}>
        <>
          <CardTitle>{t('word.details')}</CardTitle>
          <CardMedia>
            {event?.avatar && (
              <CardMediaImage src={event?.avatar} variant="wide" />
            )}
            <CardMediaContent>
              <CardText variant="title">{event?.name}</CardText>
              {singleDay ? (
                <>
                  <CardText className={classes.cardText}>
                    <TimeIcon />
                    {event?.dateTimeStart &&
                      getFormatedFullDate(event.dateTimeStart)}
                  </CardText>
                  <CardText className={classes.noIconText}>
                    {event?.dateTimeStart &&
                      `${getFormatedHour(
                        event.dateTimeStart,
                      )} – ${getFormatedHour(
                        event.dateTimeEnd,
                      )} ${getTimeZoneAbbreviation(event.dateTimeEnd)}`}
                  </CardText>
                </>
              ) : (
                <>
                  <CardText className={classes.cardText}>
                    <TimeIcon />
                    {event?.dateTimeStart &&
                      `${getFormatedFullDate(
                        event.dateTimeStart,
                      )}, ${getFormatedHour(event.dateTimeStart)} –`}
                  </CardText>
                  <CardText className={classes.noIconText}>
                    {event?.dateTimeStart &&
                      `${getFormatedFullDate(
                        event.dateTimeEnd,
                      )}, ${getFormatedHour(
                        event.dateTimeEnd,
                      )} ${getTimeZoneAbbreviation(event.dateTimeEnd)}`}
                  </CardText>
                </>
              )}
              {(isInPersonEvent || isWebEvent || isZoomEvent) && (
                <>
                  <CardText className={classes.cardText}>
                    {isInPersonEvent && (
                      <>
                        <PositionIcon className={classes.positionIcon} />
                        <Typography className={classes.locationText}>
                          {event?.venue ? event?.venue : event?.address}
                        </Typography>
                      </>
                    )}
                    {isWebEvent && (
                      <>
                        <LanguageIcon className={classes.languageIcon} />
                        <Typography className={classes.webText}>
                          {t('vanco.mobile.events.web.link')}
                        </Typography>
                      </>
                    )}
                    {isZoomEvent && (
                      <>
                        <ZoomIcon />
                        <Typography>{t('vanco.mobile.events.zoom')}</Typography>
                      </>
                    )}
                  </CardText>
                  <CardText className={classes.cardText}>
                    <Button
                      onClick={onOpenDetails}
                      className={classes.detailsButton}
                    >
                      {t('vanco.mobile.events.see.details')}
                    </Button>
                  </CardText>
                  {isInPersonEvent && event?.venue && (
                    <CardText variant="small" className={classes.noIconText}>
                      {event?.address}
                    </CardText>
                  )}
                </>
              )}
              <CardText
                spinnerWidth={100}
                aria-haspopup="true"
                className={classes.cardText}
              >
                {event?.groupName ? (
                  <>
                    <GroupIcon className={classes.groupIcon} />
                    {`${t('vanco.mobile.events.group')} ∙ ${event.groupName}`}
                  </>
                ) : (
                  <>
                    <GroupPublic />
                    {t('vanco.mobile.events.public')}
                  </>
                )}
              </CardText>
            </CardMediaContent>
          </CardMedia>
          {event?.description && (
            <CardDescription>
              <Divider className={classes.divider} />
              {stringToHTML(event?.description)}
            </CardDescription>
          )}
          {isUpcoming ? (
            <CardDisclosure
              title={t('vanco.mobile.groups.disclosureTitle')}
              message={t('vanco.mobile.events.disclosureMessage')}
            />
          ) : (
            <div className={classes.emptyDiv} />
          )}
        </>
      </Card>
      <Card isLoading={isLoading} className={classes.card}>
        <CardTitle>{t('vanco.mobile.events.creator')}</CardTitle>
        <CardList>
          <CardListItem onClick={goToUserPage(event?.creator?.id)}>
            {event?.creator?.name}
          </CardListItem>
        </CardList>
      </Card>
      <Card isLoading={isLoading} className={classes.card}>
        <CardTitle>{!isLoading && assistanceTitle()}</CardTitle>
        <CardList>
          {event?.attendingUsers.length > 0 ? (
            event?.attendingUsers.map((u) => (
              <CardListItem onClick={goToUserPage(u.id)} key={u.id}>
                {assistanceName(u)}
              </CardListItem>
            ))
          ) : (
            <CardListItem disabled className={classes.emptyMessage}>
              {isUpcoming
                ? t('vanco.mobile.events.no.attendees.upcoming')
                : t('vanco.mobile.events.no.attendees.past')}
            </CardListItem>
          )}
        </CardList>
      </Card>
      <EventDetailsModal
        open={openDetails}
        onClose={onCloseDetails}
        variant={
          // eslint-disable-next-line no-nested-ternary
          isInPersonEvent ? 'inPerson' : isZoomEvent ? 'zoomMeeting' : 'webLink'
        }
        event={event || {}}
      />
    </Page>
  );
}

VancoMobileEvent.propTypes = {
  classes: objectOf(string).isRequired,
};

export default withStyles(styles)(VancoMobileEvent);
