import Api from '../Api';

class BaseService {
  static http;

  constructor() {
    this.http = Api;
  }

  // eslint-disable-next-line class-methods-use-this
  patchHelper = (field, value, operation) => {
    return { op: operation, path: `/${field}`, value };
  };
}

export default BaseService;
