import { func, string, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Form } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomFormik from '../../CustomFormik';
import DisableStreamForm from './Form';
import { createSchema } from './validation';
import { setSuccess } from '../../../../store/alerts/actions';
import { setStreamingDisableDate } from '../../../../store/internal/actions';
import styles from './styles';
import { useFeatures } from '../../../../utils/hooks';

export function DisableStream({
  organizationId,
  streamingDisableDate,
  smartLibraryDisableDate,
  smartClipsDisableDate,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    VancoVideo: ffVancoVideo,
    SmartLibrary: ffSmartLibrary,
    SmartClips: ffSmartClips,
  } = useFeatures();

  const videoTitle = t(`${ffVancoVideo ? 'video' : 'live'}Title`);

  const schema = createSchema({ ffSmartLibrary, ffSmartClips });

  const onSubmit = async (values) => {
    const { streamingDisableDate: streamingDate } = values;

    const notified = await dispatch(
      setStreamingDisableDate(organizationId, {
        streamingDisableDate: streamingDate,
        smartLibraryDisableDate: values.smartLibraryDisableDate,
        smartClipsDisableDate: values.smartClipsDisableDate,
        isDisableProcessed: false,
      }),
    );

    if (notified) {
      const successMessage = streamingDate
        ? `${t('disableStreamModal.disableSuccess', {
            title: videoTitle,
          })} ${streamingDate.format('MM/DD/YYYY')}`
        : t('site.changes.saved');
      dispatch(setSuccess(successMessage));
    }
  };

  const close = (resetForm, isSubmitting) => () => {
    if (!isSubmitting) {
      resetForm();
    }
  };

  return (
    <CustomFormik
      initialValues={{
        enableStreamSelected: !streamingDisableDate,
        streamingDisableDate: streamingDisableDate || undefined,
        enableLibrarySelected: !smartLibraryDisableDate,
        smartLibraryDisableDate: smartLibraryDisableDate || undefined,
        enableClipSelected: !smartClipsDisableDate,
        smartClipsDisableDate: smartClipsDisableDate || undefined,
      }}
      enableReinitialize
      onSubmit={onSubmit}
      render={(formikProps) => (
        <Form>
          <DisableStreamForm
            handleSubmit={onSubmit}
            handleClose={close(formikProps.resetForm, formikProps.isSubmitting)}
            {...formikProps}
          />
        </Form>
      )}
      validationSchema={schema}
    />
  );
}

DisableStream.propTypes = {
  setSuccess: func.isRequired,
  setStreamingDisableDate: func.isRequired,
  organizationId: string.isRequired,
  streamingDisableDate: object,
  smartLibraryDisableDate: object,
  smartClipsDisableDate: object,
};

DisableStream.defaultProps = {
  streamingDisableDate: undefined,
  smartLibraryDisableDate: undefined,
  smartClipsDisableDate: undefined,
};

export default compose(
  withStyles(styles),
  connect(null, {
    setSuccess,
    setStreamingDisableDate,
  }),
)(DisableStream);
