export default theme => ({
  email: {
    width: 330,
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
  },
  status: {
    width: 150,
  },
  userName: {
    marginRight: 6,
  },
});
