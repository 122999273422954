import { commonStyles } from '../styles';

export default function styles(theme) {
  return {
    noArchivedTiles: {
      color: '#828282',
    },
    ...commonStyles(theme),
  };
}
