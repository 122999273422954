import axios from 'axios';

const MINISTRY_PLATFORM_SSO = 'ministry-platform';

async function getImportFiles(tenant, authToken) {
  const importsUrl = `${window.env.REACT_APP_RECURRENCE_API_URL}/imports/${tenant}?pageSize=50`;
  return axios.get(importsUrl, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
}

function createImport(file, tenant, authToken, onUploadProgress) {
  const importsUrl = `${window.env.REACT_APP_RECURRENCE_API_URL}/recurrences`;
  const form = new FormData();

  form.append('File', file);
  form.append('PayerTenantId', tenant);
  form.append('LoginProvider', MINISTRY_PLATFORM_SSO); // hardcoding Ministry Platform sso-provider

  return axios.post(importsUrl, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${authToken}`,
    },
    onUploadProgress,
  });
}

async function getImportFile(authToken, id) {
  const importsUrl = `${window.env.REACT_APP_RECURRENCE_API_URL}/imports/${id}/recurrences`;
  return axios.get(importsUrl, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
}

export default { getImportFile, getImportFiles, createImport };
