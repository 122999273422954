import { forwardRef } from 'react';
import { Divider as MaterialDivider } from '@material-ui/core';
import { bool } from 'prop-types';

import { usePopoverMenuContext } from '../context';

const Divider = forwardRef(({ iconDivider }, ref) => {
  const { classes } = usePopoverMenuContext();
  return (
    <MaterialDivider
      innerRef={ref}
      className={iconDivider ? classes.iconDivider : classes.divider}
    />
  );
});

Divider.propTypes = {
  iconDivider: bool,
};

Divider.defaultProps = {
  iconDivider: false,
};

export default Divider;
