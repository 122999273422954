/* eslint-disable no-unreachable */
import BaseService from '../BaseService';

class TransactionService extends BaseService {
  /**
   * Class representing a TransactionService.
   * Extends the functionalities of the BaseService class by adding methods specific to transaction operations.
   */

  /**
   * Sends a GET request to retrieve transaction data from the server.
   * @returns {Promise} A promise that resolves with the transaction data or rejects with an error.
   */
  getTransactionData = () => {
    return this.http.get('transactions');
  };

  /**
   * Sends a PUT request to ensure a user exists by providing an email.
   * @param {string} email - The email of the user to ensure.
   * @returns {Promise} A promise that resolves with the response or rejects with an error.
   */
  ensureUser = (userToEnsure, createUser) => {
    //  throw new Error('error ensure user');

    return this.http.put('transactions/users', {
      data: { payerUser: userToEnsure, createUser },
    });
  };

  /**
   * Sends a POST request to create transactions by sending transaction data to the server.
   * @param {object} data - The transaction data to create.
   * @returns {Promise} A promise that resolves with the response or rejects with an error.
   */
  createTransactions(data) {
    // TODO: Enable to simulate error
    // throw new Error('error createTransactions');
    return this.http.post(`transactions`, { data });
  }
}

export default new TransactionService();
