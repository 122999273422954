import { string, bool, objectOf } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Dialog,
  DialogContent,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import styles from './styles';

export function LoadingDialog({ classes, open, message }) {
  const matches = useMediaQuery('(min-width:768px)');
  return (
    <Dialog fullScreen={!matches} open={open} data-testid="loading-dialog">
      <DialogContent className={classes.content}>
        <CircularProgress color="primary" size={30} />
        <Typography variant="h5" className={classes.message}>
          {message}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

LoadingDialog.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool,
  message: string,
};

LoadingDialog.defaultProps = {
  open: false,
  message: '',
};

export default withStyles(styles)(LoadingDialog);
