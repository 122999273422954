import { connect } from 'react-redux';
import ImportedFileDetail from './ImportedFileDetail';
import { resetNewPage } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    accessToken: state.user.session.accessToken,
    idsPayerTenant: state.organizations.currentOrganization.idsPayerTenant,
  };
}

export default connect(mapStateToProps, { resetNewPage })(ImportedFileDetail);
