export default function styles() {
  return {
    actionIcons: {
      color: '#5E6266',
      marginRight: 18,
      width: 24,
      height: 24,
      '& path': {
        fill: '#5E6266',
      },
    },
  };
}
