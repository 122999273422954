import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { ReactComponent as StreamingIcon } from '../../../assets/streamingIcon.svg';
import { SetupLayout } from '../../common';
import StreamPageHeader from '../../common/StreamPageHeader';
import {
  PAGE_PENDING,
  CHANNEL_TYPE,
  PAGE_ACTIVE,
} from '../../../globals/constants';
import styles from './styles';
import streamPageNavConfig from '../../navigation/WizardLayout/stream.page.config';
import {
  useACSTBlocking,
  useFeatures,
  useGetPage,
  useVideoDisplayFlags,
} from '../../../utils/hooks';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

function GetStartedVancoStream({
  classes,
  loadingCreate,
  resetNewPage,
  loadingPages,
  hasLoadedPages,
  wasInternal,
  organizationStreamingDisabled,
}) {
  const { t } = useTranslation();

  const features = useFeatures();

  const { MultipleStreams: ffMultipleStreams } = features;

  const { ffVancoVideo } = useVideoDisplayFlags();

  const videoTitle = t(`${ffVancoVideo ? 'video' : 'live'}Title`);

  const history = useHistory();
  usePageTitle(!organizationStreamingDisabled ? t('titles.getStarted') : '');

  const { page, isLoading } = useGetPage(CHANNEL_TYPE.STREAM, [
    PAGE_PENDING,
    PAGE_ACTIVE,
  ]);

  const { hideFeaturesForACST } = useACSTBlocking();
  const redirectToNotFound = !ffVancoVideo || hideFeaturesForACST;

  useEffect(() => {
    if (redirectToNotFound) {
      history.push('/not-found');
    }
  }, [history, redirectToNotFound]);

  if (loadingPages || !hasLoadedPages || isLoading) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const startWizard = async () => {
    resetNewPage();
    const url = ffMultipleStreams
      ? '/stream/settings'
      : '/wizard/stream/page-info';
    history.push(url);
  };

  const continueWizard = () => {
    resetNewPage();
    const config = streamPageNavConfig(page, features);
    if (page.status === PAGE_ACTIVE) {
      history.push(config[config.length - 1].path.replace(':pageId', page.id));
    } else {
      const nextPage = config.find((step) => step.next);
      history.push(nextPage.path.replace(':pageId', page.id));
    }
  };

  return (
    <Grid container className={classes.container}>
      {wasInternal && (
        <Grid item xs={12} className={classes.actions}>
          <StreamPageHeader />
        </Grid>
      )}
      {!organizationStreamingDisabled && (
        <Grid item xs={12}>
          <SetupLayout>
            <SetupLayout.Title>
              <StreamingIcon data-testid="vanco-streaming-icon" />
              {t('app.vancoStreaming', {
                title: videoTitle,
              })}
            </SetupLayout.Title>
            <Typography>{t('stream.getStarted.body1')}</Typography>
            <Typography>{t('stream.getStarted.body2')}</Typography>
            {!loadingCreate && !page.status && (
              <SetupLayout.Button
                className={classes.button}
                size="large"
                onClick={startWizard}
              >
                {t('button.getStarted')}
              </SetupLayout.Button>
            )}
            {!loadingCreate && page.status && (
              <SetupLayout.Button
                className={classes.button}
                size="large"
                onClick={continueWizard}
              >
                {t('button.continueSetup')}
              </SetupLayout.Button>
            )}
            {loadingCreate && <CircularProgress color="primary" />}
          </SetupLayout>
        </Grid>
      )}
    </Grid>
  );
}

GetStartedVancoStream.propTypes = {
  classes: PropTypes.object.isRequired,
  loadingCreate: PropTypes.bool.isRequired,
  resetNewPage: PropTypes.func.isRequired,
  loadingPages: PropTypes.bool.isRequired,
  hasLoadedPages: PropTypes.bool.isRequired,
  wasInternal: PropTypes.bool.isRequired,
  organizationStreamingDisabled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(GetStartedVancoStream);
