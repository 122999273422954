export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: 8,
      },
    },
    icon: {
      color: theme.palette.icon.seconday,
      fontSize: 20,
    },
    light: {
      color: theme.palette.icon.light,
    },
  };
}
