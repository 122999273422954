export default function styles(theme) {
  return {
    grid: {
      marginBottom: 40,
    },
    formTitle: {
      lineHeight: '1.43',
    },
    formSubtitle: {
      fontWeight: 'normal',
    },
    textInput: {
      marginTop: 30,
    },
    radioGroup: {
      marginTop: 20,
      '& label': {
        alignItems: 'flex-start',
        verticalAlign: 'top',
      },
    },
    radioButton: {
      paddingTop: '0',
    },
    inlineError: {
      display: 'flex',
      padding: 25,
      backgroundColor: theme.palette.error.light,
      fontWeight: 'bold',
    },
    errorIcon: {
      color: theme.palette.error.main,
      marginRight: 23,
    },
    errorText: {
      fontWeight: 'bold',
    },
    loaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: 16,
      paddingBottom: 16,
    },
  };
}
