import { string, object, func, bool } from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomFormik from '../CustomFormik';
import PageDivider from '../PageDivider';
import PageTitle from '../PageTitle';
import Form from './Form';

export function StreamPageChat({
  savePage,
  goToNext,
  goToPrev,
  pageTitleKey,
  page,
  updateStreamData,
  setError,
  loadingSave,
  isFinished,
  newPage,
  resetNewPage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (goToNext && newPage.id) {
      goToNext();
      resetNewPage();
    }
  }, [newPage, goToNext, resetNewPage]);

  const onSubmit = async values => {
    const formPage = {
      ...page,
      streamData: {
        ...page.streamData,
        chatTurnOnMinutes: values.chatTurnOnMinutes,
        chatTurnOffMinutes: values.chatTurnOffMinutes,
        chatEnabled: values.chatEnabled,
      },
      streamGivingOption: {
        ...page.streamGivingOption,
      },
    };
    await savePage(formPage);
  };

  return (
    <>
      <PageTitle variant="small">{t(pageTitleKey)}</PageTitle>
      {!updateStreamData && <PageDivider variant="title" />}
      <CustomFormik
        onChange={updateStreamData}
        onErrorsChange={setError}
        initialValues={{
          chatEnabled: page.streamData ? page.streamData.chatEnabled : true,
          // eslint-disable-next-line no-nested-ternary
          chatTurnOnMinutes: page.streamData?.chatTurnOnMinutes
            ? page.streamData?.chatTurnOnMinutes === -1
              ? 0
              : page.streamData?.chatTurnOnMinutes
            : 0,
          chatTurnOffMinutes: page.streamData?.chatTurnOffMinutes || 0,
        }}
        onSubmit={onSubmit}
        enableReinitialize
        render={formikProps => (
          <Form
            goToPrev={goToPrev}
            loading={loadingSave}
            isFinished={isFinished}
            isWizard={!updateStreamData}
            {...formikProps}
          />
        )}
      />
    </>
  );
}

StreamPageChat.propTypes = {
  savePage: func.isRequired,
  goToNext: func,
  goToPrev: func,
  pageTitleKey: string,
  page: object.isRequired,
  updateStreamData: func,
  setError: func,
  loadingSave: bool.isRequired,
  isFinished: bool,
  newPage: object.isRequired,
  resetNewPage: func.isRequired,
};

StreamPageChat.defaultProps = {
  goToNext: null,
  goToPrev: null,
  pageTitleKey: '',
  updateStreamData: null,
  setError: null,
  isFinished: false,
};

export default StreamPageChat;
