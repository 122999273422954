import { NotFound } from '../../common';
import GetStartedVancoStream from '../../pages/GetStartedVancoStream';

export function VancoStreamGetStarted() {
  return <GetStartedVancoStream />;
}

export function VancoStreamNotFoundPage() {
  return <NotFound />;
}
