import i18n from '../../../utils/i18n';
import { tilePaymentValidations } from '../../../utils/validations';

export const createSchema = (features) => {
  return tilePaymentValidations(i18n, features);
};

export default {
  createSchema,
};
