export default function styles() {
  return {
    text: {
      fontWeight: 'normal',
      marginTop: 20,
      marginBottom: 20,
    },
    colorsContainer: {
      maxWidth: 510,
    },
    spinnerContainer: {
      marginTop: 40,
    },
  };
}
