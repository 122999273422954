import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Typography, Button, Grid } from '@material-ui/core';
import { PageTitle } from '..';
import ButtonMenu from '../ButtonMenu';
import DisableStreamModal from './DisableStreamModal';

import styles from './styles';
import { useFeatures } from '../../../utils/hooks';

export function StreamPageHeader({
  classes,
  organizationId,
  organizationStreamingDisableDate,
  organizationStreamingDisabled,
  smartLibraryDisableDate,
  smartClipsDisableDate,
}) {
  const [enableModalOpen, setEnableModalOpen] = useState(false);

  const { t } = useTranslation();

  const {
    VancoVideo: ffVancoVideo,
    SmartLibrary: ffSmartLibrary,
    SmartClips: ffSmartClips,
  } = useFeatures();

  const pageTitle = ffVancoVideo
    ? t('app.menu.streaming.liveStreaming')
    : t('streamPageHeader.streaming');

  const videoTitle = t(`${ffVancoVideo ? 'video' : 'live'}Title`);
  const videoTitleHeader = t(
    `${ffSmartLibrary || ffSmartClips ? 'smartVideo' : 'vancoVideo'}Title`,
  );

  const openEnableModal = () => {
    setEnableModalOpen(true);
  };
  const closeEnableModal = () => {
    setEnableModalOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid container item className={classes.titleContainer}>
          <PageTitle>{pageTitle}</PageTitle>
          <ButtonMenu buttonContent={t('streamPageHeader.button')}>
            <ButtonMenu.Item onClick={openEnableModal}>
              <Typography>
                {t('streamPageHeader.title', { title: videoTitleHeader })}
              </Typography>
            </ButtonMenu.Item>
          </ButtonMenu>
        </Grid>

        {organizationStreamingDisabled && (
          <Grid item xs={12}>
            <Typography variant="body2">
              {t('streamPageHeader.notEnabled', {
                title: videoTitle,
              })}
              <Button
                color="primary"
                className={classes.linkButton}
                onClick={openEnableModal}
              >
                {t('streamPageHeader.notEnabledLink')}
              </Button>
            </Typography>
          </Grid>
        )}
      </Grid>
      <DisableStreamModal
        onClose={closeEnableModal}
        open={enableModalOpen}
        organizationId={organizationId}
        streamingDisableDate={organizationStreamingDisableDate}
        smartLibraryDisableDate={smartLibraryDisableDate}
        smartClipsDisableDate={smartClipsDisableDate}
      />
    </>
  );
}

StreamPageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  organizationStreamingDisableDate: PropTypes.object,
  organizationStreamingDisabled: PropTypes.bool.isRequired,
  smartLibraryDisableDate: PropTypes.object,
  smartClipsDisableDate: PropTypes.object,
};

StreamPageHeader.defaultProps = {
  organizationStreamingDisableDate: undefined,
  smartLibraryDisableDate: undefined,
  smartClipsDisableDate: undefined,
};

export default withStyles(styles)(StreamPageHeader);
