import PropTypes from 'prop-types';
import { withStyles, MenuItem, Typography } from '@material-ui/core';
import { FastField } from 'formik';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import { PAYMENT_METHOD } from '../../../../globals/constants';
import styles from './styles';
import UserInformationField from '../UserInformationField';

export function ACHPaymentMethod(props) {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <Typography component="div" className={classes.container}>
      <FastField
        name={`${PAYMENT_METHOD.ACH}.accountNumber`}
        component={TextField}
        id="account-number"
        inputProps={{ inputMode: 'numeric' }}
        className={classes.formItem}
        label={t('transactions.account.number')}
        variant="outlined"
      />
      <FastField
        name={`${PAYMENT_METHOD.ACH}.routingNumber`}
        component={TextField}
        id="routing-number"
        inputProps={{ inputMode: 'numeric' }}
        className={classes.formItem}
        label={t('transactions.routing.number')}
        variant="outlined"
      />
      <FastField
        name={`${PAYMENT_METHOD.ACH}.accountType`}
        component={TextField}
        id="account-type"
        label={t('transactions.accountType')}
        select
        variant="outlined"
        data-testid="account-type-select"
        className={classes.formItem}
      >
        <MenuItem
          key={PAYMENT_METHOD.ACCOUNT_CHECKING}
          value={PAYMENT_METHOD.ACCOUNT_CHECKING}
        >
          {t('transactions.checking')}
        </MenuItem>
        <MenuItem
          key={PAYMENT_METHOD.ACCOUNT_SAVINGS}
          value={PAYMENT_METHOD.ACCOUNT_SAVINGS}
        >
          {t('transactions.savings')}
        </MenuItem>
      </FastField>
      <UserInformationField />
    </Typography>
  );
}

ACHPaymentMethod.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ACHPaymentMethod);
