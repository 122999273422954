import TelemetryService from 'api/TelemetryService';
import { ALERT_TYPES } from '../../globals/constants';
import { SET_ALERT, CLEAR_ALERT } from './types';

export function setSuccess(message) {
  return {
    type: SET_ALERT,
    variant: ALERT_TYPES.SUCCESS,
    message,
  };
}

export function setError(message) {
  return {
    type: SET_ALERT,
    variant: ALERT_TYPES.ERROR,
    message,
  };
}

export function setInfo(message) {
  return {
    type: SET_ALERT,
    variant: ALERT_TYPES.INFORMATION,
    message,
  };
}

export function setWarning(message) {
  return {
    type: SET_ALERT,
    variant: ALERT_TYPES.WARNING,
    message,
  };
}

export function clearAlert() {
  return {
    type: CLEAR_ALERT,
  };
}

export function handleException(error, showAlert = true) {
  /* eslint-disable-next-line no-console */
  console.error(error);
  TelemetryService.handleTelemetryException(error);
  if (!showAlert) {
    // no-op
    return { type: 'NO_OP' };
  }
  return setError(error.message);
}

export function handleResponseError(res) {
  /* eslint-disable-next-line no-console */
  console.info(res);
  return setError(res?.error);
}
