import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles, Typography, Divider } from '@material-ui/core';
import { Field } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  getInitialMonth,
  getLatestAllowedDate,
  getEarliestAllowedDate,
  getLatestAllowedEndDate,
} from '../../../../utils/calendar/index';
import DatePicker from '../../DatePicker';
import * as globals from '../../../../globals/constants';
import FrequencyDropdown from './FrequencyDropdown';
import CurrencyTextField from '../../CurrencyTextField';
import TextFieldWithCharCount from '../../TextFieldWithCharCount';
import MemoFieldsDropdown from './MemoFieldsDropdown';
import CustomDropdown from './CustomDropdown';
import usePaymentFrequencies from '../../../../utils/hooks/usePaymentFrequencies';

const styleOverrides = () => ({
  inputField: {
    width: '100%',
    background: '#FFFFFF',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  currencyInput: {
    width: '100%',
    '& div': {
      padding: '12px 16px',
      '& input': {
        fontSize: '60px',
        textAlign: 'center',
      },
    },
  },
  inputFieldNoBackground: {
    background: 'unset',
  },
  inputHelperText: {
    margin: '3px 12px 0',
    maxWidth: '100%',
  },
  dividerStyleFix: {
    margin: '20px 0 12px',
  },
  formClassName: {
    marginBottom: '8px',
  },
  dateTitle: {
    color: '#212224',
    fontSize: '16px',
    lineHeight: '24px',
  },
  charCount: {
    display: 'findLastIndex',
  },
  setEndDateVisible: {
    color: '#0096EF',
  },
});

export function OpenAmount({
  classes,
  formikProps: { values, setFieldValue, setFieldTouched },
  startDateLabel,
  transactionData,
  features,
}) {
  const { t } = useTranslation();
  const [resetDateValues, setResetDateValues] = useState(false);
  const resetDateValuesCallback = () => setResetDateValues(false);
  // recurringOption is the field name/value of the active option - can be once or recurring
  const { frequency, startDate, endDate, fundId } = values;
  const { tileFrequencies, defaultFrequency, showEndDate } =
    usePaymentFrequencies({
      transactionData,
      selectedFundId: fundId,
    });

  const allowedDates =
    frequency === globals.PAYMENT_FREQUENCY.TWICE_A_MONTH ? [1, 15] : null;

  useEffect(() => {
    if (frequency === globals.PAYMENT_FREQUENCY.ONE_TIME_NOW && startDate) {
      setFieldValue(`startDate`, null, true);
      setFieldTouched(`startDate`, false, false);
    }

    if (
      (frequency === globals.PAYMENT_FREQUENCY.ANNUALLYONE_TIME_NOW ||
        frequency === globals.PAYMENT_FREQUENCY.ONE_TIME_FUTURE) &&
      endDate
    ) {
      setFieldValue(`endDate`, null, true);
      setFieldTouched(`endDate`, false, false);
    }
  }, [frequency, startDate, setFieldValue, setFieldTouched, endDate]);

  const setInitialVisibleMonth = () => {
    return getInitialMonth(startDate, allowedDates);
  };

  const getLabelText = () => {
    let formattedText = values.memoLine.textField;
    formattedText += values.memoLine.responseRequired
      ? ` (${t('tile.responseRequired')})`
      : ` (${t('tile.responseOptional')})`;
    return formattedText;
  };

  const showMemoFieldsDropDown =
    features.TileMemoDropdown &&
    !values.isTextFieldResponseType &&
    values.memoFields?.length > 0;

  const orderedMemoFields = values.memoFields?.sort((a, b) =>
    a.ordinal > b.ordinal ? 1 : -1,
  );

  return (
    <>
      <Field
        id="donation-amount"
        name="amount"
        autoComplete="off"
        className={classes.currencyInput}
        helperTextClass={classes.inputHelperText}
        component={CurrencyTextField}
      />
      <Field
        id="donation-tile"
        name="fundId"
        component={CustomDropdown}
        data={transactionData.tiles}
        className={classes.inputField}
        helperTextClass={classes.inputHelperText}
        features={features}
        textFieldLabel={t('gift.select.to')}
      />

      <Field
        id="donation-frequency"
        name="frequency"
        component={FrequencyDropdown}
        frequencies={tileFrequencies}
        className={classes.inputField}
        helperTextClass={classes.inputHelperText}
        defaultValue={defaultFrequency}
      />
      {Boolean(values.frequency) &&
        values.frequency !== globals.PAYMENT_FREQUENCY.ONE_TIME_NOW && (
          <Field
            id="donation-start-date"
            name="startDate"
            label={startDateLabel || t('tile.startDate')}
            inputClass={classes.inputField}
            helperTextClass={classes.inputHelperText}
            earliestAllowedDate={getEarliestAllowedDate(moment(), frequency)}
            latestAllowedDate={getLatestAllowedDate(moment())}
            component={DatePicker}
            position="relative"
            initialVisibleMonth={setInitialVisibleMonth}
            allowedDates={allowedDates}
            resetValue={resetDateValues}
            resetCallBack={resetDateValuesCallback}
            handleDateChange={setFieldValue}
          />
        )}

      {Boolean(values.frequency) &&
        values.frequency !== globals.PAYMENT_FREQUENCY.ONE_TIME_NOW &&
        values.frequency !== globals.PAYMENT_FREQUENCY.ONE_TIME_FUTURE &&
        showEndDate && (
          <Field
            id="donation-end-date"
            name="endDate"
            label={t('tile.endDate')}
            inputClass={classes.inputField}
            helperTextClass={classes.inputHelperText}
            earliestAllowedDate={getEarliestAllowedDate(moment(), frequency)}
            latestAllowedDate={getLatestAllowedEndDate(moment())}
            component={DatePicker}
            position="relative"
            initialVisibleMonth={setInitialVisibleMonth}
            allowedDates={allowedDates}
            handleDateChange={setFieldValue}
          />
        )}

      {false && values.memoLine.showTextField && !showMemoFieldsDropDown && (
        <>
          <Divider variant="middle" className={classes.dividerStyleFix} />
          <Typography className={classes.dateTitle} variant="caption">
            {getLabelText()}
          </Typography>
          <Field
            id="donation-memoline"
            name="memoLine"
            variant="outlined"
            autoComplete="off"
            maxLength={50}
            maxRows={2}
            multiline
            className={classes.inputField}
            helperTextClass={classes.inputHelperText}
            component={TextFieldWithCharCount}
          />
        </>
      )}
      {false && values.memoLine.showTextField && showMemoFieldsDropDown && (
        <>
          <Divider variant="middle" className={classes.dividerStyleFix} />
          <Field
            id="memo-fields"
            textFieldLabel={`${t('accountPayment.select')} ${getLabelText()}`}
            name="memoLine"
            component={MemoFieldsDropdown}
            memoFields={orderedMemoFields}
            className={classes.inputField}
            helperTextClass={classes.inputHelperText}
          />
        </>
      )}
    </>
  );
}

OpenAmount.propTypes = {
  classes: PropTypes.object.isRequired,
  formikProps: PropTypes.object.isRequired,
  startDateLabel: PropTypes.string,
  features: PropTypes.object.isRequired,
  transactionData: PropTypes.object.isRequired,
};
OpenAmount.defaultProps = {
  startDateLabel: '',
};

const mapStateToProps = (state) => ({
  features: state.features.features,
  transactionData: {
    tiles: [...state.transaction.tiles, ...state.transaction.payments],
  },
});

export default compose(
  withStyles(styleOverrides),
  connect(mapStateToProps),
)(OpenAmount);
