import moment from 'moment';
import 'moment-timezone';
import {
  LOCATION_PAGE_LAYOUTS,
  PAGE_YOUTUBE_VIDEO,
} from '../../globals/constants';
import { defaultPage } from '../../globals/defaults';
import { getTimeZonesList } from '../../utils/date';
import processDate from '../../utils/date/timeZoneHelper';
import UseFacebookEmbedCode from '../../utils/hooks/useFacebookEmbedCode';
import { parseDescription } from '../../utils/tiles';
/* Frontend page to a backend representation of a page */
export const frontendToBackend = (page) => {
  const email = page.provideEmail ? page.email : '';
  const phone = page.providePhone ? page.phone : '';
  const line1 = page.provideAddress ? page.address : '';
  const line2 = page.provideAddress ? page.address2 : '';
  const zip = page.provideAddress ? page.zip : '';
  const state = page.provideAddress ? page.state : '';
  const city = page.provideAddress ? page.city : '';
  const enableFees = page.enableProcessingFees;
  const logoImage = page.logoImage ? { id: page.logoImage.id } : null;
  const bannerImage = page.bannerImage ? { id: page.bannerImage.id } : null;

  const pageObject = {
    id: page.id,
    name: page.pageName,
    address: {
      line1,
      line2,
      city,
      zip,
      state,
    },
    contact: {
      phone,
      email,
    },
    donationOptions: {
      enableFeeContribution: enableFees,
      paymentMethods: null,
    },
    status: page.status,
    url: page.url,
    homePageUrl: page.homePage,
    organizationId: page?.organization?.id,
    primaryColor: page.primaryColor,
    logoImage,
    bannerImage,
    locationURI: page.locationURI,
    channelId: page.channelId,
    mobileAppPermissions: null,
    displayName: page.displayPageName,
    addressRequired: page.addressRequired,
  };

  if (page.hasPaymentMethods) {
    pageObject.donationOptions.paymentMethods = {
      creditCard: {
        paymentmethod: 'CreditCard',
        active: enableFees,
        feeValue: enableFees ? page.creditPercentage : '',
      },
      ach: {
        paymentmethod: 'ACH',
        active: enableFees,
        feeValue: enableFees ? page.checkPercentage : '',
      },
    };
  }

  if (page.updateMobileAppPermissions && page.mobileAppPermissions) {
    pageObject.mobileAppPermissions = {
      clientPK: page.mobileAppPermissions.clientPK,
      requiresJoinRequest: page.mobileAppPermissions.requiresJoinRequest,
      usersCanInvite: page.mobileAppPermissions.usersCanInvite,
      usersCanCreateEvents: page.mobileAppPermissions.usersCanCreateEvents,
      usersCanCreateSubgroups:
        page.mobileAppPermissions.usersCanCreateSubgroups,
      defaultGroupName: page.mobileAppPermissions.defaultGroupName,
    };
  }
  pageObject.hasCommunityFeatures = page.hasCommunityFeatures;
  pageObject.allowSocialSharing = page.allowSocialSharing;

  if (page.streamData) {
    const {
      isEmbedCode,
      extractUrlFromEmbed,
      isValidFullURL,
      extractUrlFromFullUrl,
    } = UseFacebookEmbedCode();
    let { embedCode } = page.streamData;
    if (isEmbedCode(embedCode)) {
      embedCode = extractUrlFromEmbed(embedCode);
    } else if (isValidFullURL(embedCode)) {
      embedCode = extractUrlFromFullUrl(embedCode);
    }

    const standardizedStartTime = moment(page.streamData?.startTime, [
      'h:mm A',
    ])?.format('HH:mm');
    const concatStart = `${page.streamData.startDate?.format(
      'YYYY/MM/DD',
    )} ${standardizedStartTime}`;
    const startDate = moment(concatStart).isValid()
      ? new Date(concatStart)?.toISOString()
      : '';

    const standardizedEndTime = moment(page.streamData?.endTime, [
      'h:mm A',
    ])?.format('HH:mm');
    const concatEnd = `${page.streamData.endDate?.format(
      'YYYY/MM/DD',
    )} ${standardizedEndTime}`;
    const endDate = moment(concatEnd).isValid()
      ? new Date(concatEnd)?.toISOString()
      : '';

    const data = {
      streamerId: page.streamData.streamerID,
      streamId: page.streamData.streamID,
      applicationID: 'MyVanco',
      videoUrl: page.streamData.videoUrl,
      videoType: page.streamData.videoType,
      startDateTime: startDate,
      timeZone: page.streamData.timeZone?.ianaName,
      endDateTime: endDate,
      repeatWeekly: page.streamData.repeatWeekly,
      description: page.streamData.description
        ? JSON.stringify(page.streamData.description)
        : '',
      chatTurnOnMinutes: page.streamData.chatTurnOnMinutes,
      chatTurnOffMinutes: page.streamData.chatTurnOffMinutes,
      givingReference: page.streamData.givingReference,
      chatEnabled: page.streamData.chatEnabled,
      title: page.streamData.title,
      platform: page.streamData.platform,
      embedCode,
    };

    pageObject.streamData = data;
  }

  if (page.streamGivingOption) {
    pageObject.streamGivingOption = {
      campaignId: page.streamGivingOption.campaignId,
      locationId: page.streamGivingOption.locationId,
    };
  }

  pageObject.organizationId = page.organizationId;

  return pageObject;
};

/* Backend page to a frontend representation of a page */
export const backendToFrontend = (page) => {
  const hasPaymentMethods = !!page.donationOptions?.paymentMethods;
  const checkPercentage = page.donationOptions?.paymentMethods?.ach
    ? page.donationOptions?.paymentMethods?.ach?.feeValue
    : parseFloat(defaultPage.checkPercentage);
  const creditPercentage = page.donationOptions?.paymentMethods?.creditCard
    ? page.donationOptions?.paymentMethods?.creditCard?.feeValue
    : parseFloat(defaultPage.creditPercentage);
  const address = (page.address && page.address.line1) || '';
  const address2 = (page.address && page.address.line2) || '';
  const city = (page.address && page.address.city) || '';
  const zip = (page.address && page.address.zip) || '';
  const state = (page.address && page.address.state) || '';
  const email = (page.contact && page.contact.email) || '';
  const phone = (page.contact && page.contact.phone) || '';
  const provideEmail = email.length > 0;
  const providePhone = phone.length > 0;
  const provideAddress = address.length > 0;
  const logoImage = page.logoImage
    ? { id: page.logoImage.id, url: page.logoImage.url }
    : null;
  const bannerImage = page.bannerImage
    ? { id: page.bannerImage.id, url: page.bannerImage.url }
    : null;
  const defaultView = page.defaultView ?? LOCATION_PAGE_LAYOUTS.DETAIL;
  const { defaultBannerImageUrl } = page;

  let mobileAppPermissions = null;
  let streamData = null;
  let streamGivingOption = null;

  if (page.mobileAppPermissions) {
    mobileAppPermissions = {
      clientPK: page.mobileAppPermissions.clientPK,
      requiresJoinRequest: page.mobileAppPermissions.requiresJoinRequest,
      usersCanInvite: page.mobileAppPermissions.usersCanInvite,
      usersCanCreateEvents: page.mobileAppPermissions.usersCanCreateEvents,
      usersCanCreateSubgroups:
        page.mobileAppPermissions.usersCanCreateSubgroups,
      defaultGroupName: page.mobileAppPermissions.defaultGroupName,
    };
  }

  if (page.streamData) {
    const timeZones = getTimeZonesList();
    const timezone = timeZones.find(
      (tz) => tz.ianaName === page.streamData.timeZone,
    );

    const newStartDate = new Date(page.streamData.startDateTime);
    const newEndDate = new Date(page.streamData.endDateTime);

    const processedStartDate = processDate(
      newStartDate.toISOString(),
      timezone.ianaName,
    );
    const processedEndDate = processDate(
      newEndDate.toISOString(),
      timezone.ianaName,
    );

    const matchDateTimeRegex = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/i;

    const startDateTimeStr = processedStartDate.match(matchDateTimeRegex);
    const startDate = moment(startDateTimeStr[0]);
    const startTime = startDate.format('h:mm A');

    const endDateTimeStr = processedEndDate.match(matchDateTimeRegex);
    const endDate = moment(endDateTimeStr[0]);
    const endTime = endDate.format('h:mm A');

    streamData = {
      streamId: page.streamData.streamID,
      streamerId: page.streamData.streamerID,
      applicationID: page.streamData.applicationID,
      videoType: page.streamData.videoType ?? PAGE_YOUTUBE_VIDEO,
      videoUrl: page.streamData.videoUrl,
      startDate,
      startTime,
      endDate,
      endTime: endDate ? endTime : '',
      timeZone: timezone,
      repeatWeekly: page.streamData.repeatWeekly,
      description: page.streamData.description
        ? parseDescription(page.streamData.description)
        : '',
      chatTurnOnMinutes: page.streamData.chatTurnOnMinutes,
      chatTurnOffMinutes: page.streamData.chatTurnOffMinutes,
      givingReference: page.streamData.givingReference,
      chatEnabled: page.streamData.chatEnabled,
      title: page.streamData.title,
      platform: page.streamData.platform,
      embedCode: page.streamData.embedCode,
    };
  }

  if (page.streamGivingOption) {
    streamGivingOption = {
      campaignId: page.streamGivingOption.campaignId,
      locationId: page.streamGivingOption.locationId,
    };
  }
  return {
    id: page.id,
    pageName: page.name || '',
    address,
    address2,
    city,
    zip,
    state,
    phone,
    email,
    hasPaymentMethods,
    enableProcessingFees: !!page.donationOptions?.enableFeeContribution,
    checkPercentage,
    creditPercentage,
    status: page.status,
    url: page.url,
    homePage: page.homePageUrl || '',
    provideEmail,
    providePhone,
    provideAddress,
    organization: {
      id: page.organizationId || null,
    },
    primaryColor: page.primaryColor,
    logoImage,
    bannerImage,
    defaultBannerImageUrl,
    locationURI: page.locationURI,
    channelId: page.channelId,
    mobileAppPermissions,
    hasCommunityFeatures: page.hasCommunityFeatures,
    allowSocialSharing: page.allowSocialSharing,
    streamData,
    streamGivingOption,
    primary: page.primary,
    streamerId: page?.streamerId, // temporary assignment of streamer id
    displayPageName: page.displayName,
    addressRequired: page.addressRequired,
    defaultView,
    enableDevotionals: page.enableDevotionals,
    enableBibleReader: page.enableBibleReader,
  };
};

export default { frontendToBackend, backendToFrontend };
