import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectUserFullName } from '../../../store/user/selectors';
import Navbar from './Navbar';
import { setOrganization } from '../../../store/user/actions';
import {
  toggleLeftNav,
  toggleProfileNav,
  toggleAppsNav,
} from '../../../store/navigation/actions';

export function mapStateToProps(state) {
  const { leftNavOpen, profileNavOpen, appsNavOpen } = state.navigation;
  const {
    user: { associatedOrganizations, organizationName },
  } = state.user;
  const organizationId = state.organizations.currentOrganization.id;
  const fullName = selectUserFullName(state);

  return {
    leftNavOpen,
    profileNavOpen,
    appsNavOpen,
    fullName,
    associatedOrganizations,
    organizationId,
    organizationName,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    navActions: bindActionCreators(
      { toggleLeftNav, toggleProfileNav, toggleAppsNav, setOrganization },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
