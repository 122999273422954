import { useCallback, useEffect, useRef, useState } from 'react';
import {
  objectOf,
  string,
  func,
  arrayOf,
  object,
  bool,
  oneOf,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';
import { renderToString } from 'react-dom/server';
import Quill from 'quill';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatClearIcon from '@material-ui/icons/FormatClear';
import LinkIcon from '@material-ui/icons/Link';
import EmojiPickerIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import { useTranslation } from 'react-i18next';
import data from '@emoji-mart/data';
import EmojiPicker from '@emoji-mart/react';

import './quill.snow.css';
import styles from './styles';
import LinkDialog from './LinkDialog';
import {
  ensureValidScheme,
  quillFormat,
  quillClean,
  stripClipboardFormatMatcher,
} from './quill-util';
import { theme } from '../../../globals';
import { TEXT_EDITOR } from '../../../globals/constants';
import useRevertToDefault from './useRevertToDefault';

const icons = Quill.import('ui/icons');

icons.bold = renderToString(<FormatBoldIcon />);
icons.italic = renderToString(<FormatItalicIcon />);
icons.underline = renderToString(<FormatUnderlinedIcon />);
icons.list.bullet = renderToString(<FormatListBulletedIcon />);
icons.list.ordered = renderToString(<FormatListNumberedIcon />);
icons.clean = renderToString(<FormatClearIcon />);
icons.link = renderToString(<LinkIcon />);
icons.emoji = renderToString(<EmojiPickerIcon />);

// Allowed formats (to prevent unwanted values from being added when pasting)
const formats = ['bold', 'italic', 'underline', 'list', 'link'];

const defaultEditorTextState = {
  textLength: 0,
  maxTextLengthExceeded: false,
  canAddContributorName: true,
};

export function OutlinedTextEditor({
  classes,
  name,
  placeholder,
  onChange,
  value,
  showContributorName,
  notifyLength,
  variant,
  defaultText,
  revertToDefault,
  setRevertToDefault,
}) {
  const quill = useRef(null);
  const onChangeRef = useRef(onChange);
  const initialValue = useRef(value);

  const { t } = useTranslation();
  const trans = useRef(t);

  const [linkModalState, setLinkModalState] = useState({
    initialDisplayText: undefined,
    initialUrl: undefined,
    isOpen: false,
    insertionRangeUponSave: undefined,
  });
  const closeLinkModal = () => setLinkModalState({ isOpen: false });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiButtonRef = useRef(null);

  const [editorText, setEditorText] = useState(defaultEditorTextState);

  const getCurrentEditorTextInfo = useCallback(
    (currentTextLength) => {
      if (!currentTextLength || currentTextLength < 0)
        return { ...defaultEditorTextState, canAddContributorName: false };
      const textLength =
        currentTextLength > 1
          ? currentTextLength - TEXT_EDITOR.OFFSET_CHAR
          : currentTextLength;
      return {
        textLength,
        maxTextLengthExceeded: textLength > TEXT_EDITOR.MAX_LENGTH,
        canAddContributorName:
          TEXT_EDITOR.MAX_LENGTH - textLength >=
            trans.current('textEditor.contributorName.value')?.length ?? 0,
      };
    },
    [trans],
  );

  useRevertToDefault({
    quill,
    defaultText,
    revertToDefault,
    setRevertToDefault,
  });

  const onLinkDialogSave = (linkText, url) => {
    closeLinkModal();
    const fixedUrl = ensureValidScheme(url);
    quill.current.focus(); // Maybe there's a better way but without this, 'getSelection' returns null
    const { index, length } = linkModalState.insertionRangeUponSave;
    // Delete the text first in order to make sure all formatting besides the link
    // is removed. Otherwise could just do this:  quill.current.formatText(index, length, 'link', url);
    quill.current.deleteText(index, length);
    quill.current.insertText(index, linkText, 'link', fixedUrl);
  };

  const onContributorNameClick = useCallback(() => {
    const curQuill = quill.current;
    curQuill.focus();
    const range = curQuill.getSelection(true);
    curQuill.insertText(
      range.index,
      trans.current('textEditor.contributorName.value'),
    );
  }, [trans]);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const addCNameTooltip = () => {
    document.querySelector('.ql-cname').classList.add('tooltipText');
    document
      .querySelector('.ql-cname')
      .setAttribute(
        'tool-tip',
        trans.current('textEditor.contributorName.tooltip'),
      );
  };
  const removeCNameTooltip = () => {
    const element = document.querySelector('.ql-cname');
    if (element) {
      element.removeAttribute('tool-tip');
      element.classList.remove('tooltipText');
    }
  };

  useEffect(() => {
    const onLinkButtonClick = (isCreate) => {
      quill.current.focus(); // Maybe there's a better way but without this, 'getSelection' returns null
      const { index, length } = quill.current.getSelection();
      if (index && quill.current.getFormat(index)?.link) {
        // If already inside a link. don't show the dialog for creating a link
        return;
      }
      if (isCreate) {
        const initLinkText =
          length >= 1 ? quill.current.getText(index, length) : undefined;
        setLinkModalState({
          initialDisplayText: initLinkText,
          initialUrl: undefined,
          isOpen: true,
          insertionRangeUponSave: { index, length },
        });
      } else {
        // Remove link from highlighted text
        quill.current.format('link', false);
      }
    };

    quill.current = new Quill(`#${name}-rich-id`, {
      theme: 'snow',
      modules: {
        keyboard: {
          bindings: {
            bold: {
              handler: () => {
                const { bold } = quill.current.getFormat();
                quillFormat(quill.current, 'bold', !bold);
              },
            },
            italic: {
              handler: () => {
                const { italic } = quill.current.getFormat();
                quillFormat(quill.current, 'italic', !italic);
              },
            },
            underline: {
              handler: () => {
                const { underline } = quill.current.getFormat();
                quillFormat(quill.current, 'underline', !underline);
              },
            },
          },
        },
        toolbar: {
          container: `#${name}-toolbar-id`,
          handlers: {
            link: onLinkButtonClick,
            bold: (val) => quillFormat(quill.current, 'bold', val),
            italic: (val) => quillFormat(quill.current, 'italic', val),
            underline: (val) => quillFormat(quill.current, 'underline', val),
            clean: (val) => quillClean(quill.current, val),
            emoji: () => setShowEmojiPicker(true),
            cname: showContributorName ? onContributorNameClick : () => {},
          },
        },
      },
      clipboard: {
        matchVisual: false,
      },
      placeholder,
      formats,
    });

    quill.current.clipboard.matchers.unshift([
      '*',
      stripClipboardFormatMatcher,
    ]);

    quill.current.theme.tooltip.edit = (_, preview) => {
      const { linkRange } = quill.current.theme.tooltip;
      setLinkModalState({
        initialDisplayText: quill.current.getText(linkRange),
        initialUrl: preview,
        isOpen: true,
        insertionRangeUponSave: linkRange,
      });
    };

    quill.current.setContents(initialValue.current);

    quill.current.on('text-change', () => {
      /* the text editor has a \n that counts as an initial character */
      const { textLength, maxTextLengthExceeded, canAddContributorName } =
        getCurrentEditorTextInfo(quill.current.getText().length);

      setEditorText({
        textLength,
        maxTextLengthExceeded,
        canAddContributorName,
      });

      if (!canAddContributorName) {
        addCNameTooltip();
      } else {
        removeCNameTooltip();
      }

      if (maxTextLengthExceeded) {
        quill.current.deleteText(TEXT_EDITOR.MAX_LENGTH, textLength);
        return;
      }

      onChangeRef.current(quill.current.getContents().ops);
      if (notifyLength) notifyLength(quill.current.getLength() - 1);
    });

    // Tooltips
    const preview = document.querySelector('.ql-preview');
    preview.setAttribute(
      'tool-tip',
      trans.current('textEditor.linkDialog.openLink'),
    );
    const action = document.querySelector('.ql-action');
    action.setAttribute(
      'tool-tip',
      trans.current('textEditor.linkDialog.editLink'),
    );
    const remove = document.querySelector('.ql-remove');
    remove.setAttribute(
      'tool-tip',
      trans.current('textEditor.linkDialog.unlink'),
    );

    const cname = document.querySelector('.ql-cname');
    const { canAddContributorName } = getCurrentEditorTextInfo(
      quill.current.getText().length,
    );
    cname?.setAttribute(
      'tool-tip',
      trans.current(
        canAddContributorName
          ? ''
          : trans.current('textEditor.contributorName.tooltip'),
      ),
    );

    setEditorText((prev) => ({ ...prev, canAddContributorName }));

    // ommiting cname since its starts off without a visible tooltip.
    remove.setAttribute(
      'tool-tip',
      trans.current('textEditor.linkDialog.unlink'),
    );
    const elements = [preview, action, remove];
    elements.forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.className += ' tooltipText';
    });

    if (notifyLength) notifyLength(quill.current.getLength() - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCurrentEditorTextInfo,
    name,
    onContributorNameClick,
    placeholder,
    showContributorName,
    trans,
    notifyLength,
  ]);

  const onSelectEmoji = (emoji) => {
    quill.current.focus(); // Maybe there's a better way but without this, 'getSelection' returns null
    const { index } = quill.current.getSelection();
    quill.current.insertText(index, emoji.native);
    setTimeout(() => quill.current.setSelection(index + 2), 0);
    setShowEmojiPicker(false);
  };
  return (
    <>
      <div
        className={classes.editorContainer}
        data-testid="outlined-text-editor"
      >
        <div id={`${name}-toolbar-id`} className={classes.toolbar}>
          <div className="button-group">
            <button className="ql-bold" type="button" aria-label="Bold" />
            <button className="ql-italic" type="button" aria-label="Italic" />
            <button
              className="ql-underline"
              type="button"
              aria-label="Underline"
            />
          </div>

          <div className="button-group-separator" />
          <div className="button-group">
            <button
              className="ql-list"
              type="button"
              value="bullet"
              aria-label="Bulleted List"
            />
            <button
              className="ql-list"
              type="button"
              value="ordered"
              aria-label="Ordered List"
            />
          </div>
          <div className="button-group-separator" />
          <div className="button-group">
            <button className="ql-link" type="button" aria-label="Link" />
            <button
              className="ql-clean"
              type="button"
              aria-label="Remove Formatting"
            />
            <button
              className="ql-emoji"
              ref={emojiButtonRef}
              type="button"
              aria-label="Emoji"
            />
          </div>
          <div className="button-group adjustLeft">
            {showContributorName && (
              <span style={{ display: 'inline-block' }}>
                <button
                  disabled={!editorText.canAddContributorName}
                  className={[
                    'ql-cname',
                    !editorText.canAddContributorName ? 'disabled' : '',
                  ].join(' ')}
                  type="button"
                  aria-label="contributorName"
                >
                  {trans.current('textEditor.contributorName.name')}
                </button>
              </span>
            )}
          </div>
        </div>
        <div
          id={`${name}-rich-id`}
          className={`${classes.editor} ${
            variant === 'large' ? classes.largeEditor : ''
          }`}
        />
        {linkModalState.isOpen /* Force component to unmount when closed to get rid of old state */ && (
          <LinkDialog
            isOpen={linkModalState.isOpen}
            defaultDisplayText={linkModalState.initialDisplayText}
            defaultUrl={linkModalState.initialUrl}
            onSave={onLinkDialogSave}
            onCancel={closeLinkModal}
          />
        )}
      </div>
      <Popover
        data-testid="emoji-popover"
        open={showEmojiPicker}
        onClose={() => setShowEmojiPicker(false)}
        className={classes.emojiPopover}
        transitionDuration={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={emojiButtonRef.current}
      >
        <EmojiPicker
          autoFocus // Focus search textbox
          previewEmoji="slightly_smiling_face"
          set="native"
          theme="light"
          data={data}
          emojiButtonColors={[theme.palette.primary.main]}
          className={classes.picker}
          onEmojiSelect={onSelectEmoji}
        />
      </Popover>
    </>
  );
}

OutlinedTextEditor.propTypes = {
  classes: objectOf(string).isRequired,
  name: string,
  placeholder: string,
  onChange: func.isRequired,
  value: arrayOf(object),
  showContributorName: bool,
  notifyLength: func, // Use a memoized callback function to avoid performance issues
  variant: oneOf(['default', 'large']),
  defaultText: string,
  revertToDefault: bool,
  setRevertToDefault: func,
};

OutlinedTextEditor.defaultProps = {
  name: 'editor',
  placeholder: '',
  value: [],
  showContributorName: false,
  notifyLength: null,
  variant: 'default',
  defaultText: '',
  revertToDefault: false,
  setRevertToDefault: null,
};

export default withStyles(styles)(OutlinedTextEditor);
