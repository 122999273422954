import { connect } from 'react-redux';

import Review from './Review';
import { saveOrganizationDefaults } from '../../../../store/organizations/actions';
import { getPages } from '../../../../store/pages/actions';
import { disablePromptToCompleteOrganizationSettings } from '../../../../store/user/actions';

export function mapStateToProps(state) {
  return {
    organizationId: state.organizations.currentOrganization.id,
    promptToCompleteOrganizationSettings:
      state.user.user.promptToCompleteOrganizationSettings,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, {
  saveOrganizationDefaults,
  getPages,
  disablePromptToCompleteOrganizationSettings,
})(Review);
