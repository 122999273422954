import { connect } from 'react-redux';
import StreamList from './StreamList';
import {
  savePage,
  removePage,
  resetNewPage,
} from '../../../store/pages/actions';
import { setSuccess } from '../../../store/alerts/actions';

const mapStateToProps = (state) => ({
  pages: (state.pages.pages || []).sort((a, b) => {
    return a.streamData?.title < b.streamData?.title ? -1 : 1;
  }),
  loadingSave: state.pages.loadingSave,
  wasInternal: state.user.user.wasInternal,
  organizationStreamingDisabled: state.user.user.organizationStreamingDisabled,
});

export default connect(mapStateToProps, {
  savePage,
  setSuccess,
  removePage,
  resetNewPage,
})(StreamList);
