import { LOGIN_ORG_START, LOGIN_ORG_FINISH, LOGIN_ORG_ERROR } from './types';

const INITIAL_STATE = {
  loadingLogin: false,
  error: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    // LOGIN
    case LOGIN_ORG_START:
      return {
        ...state,
        loadingLogin: true,
        error: null,
      };

    case LOGIN_ORG_FINISH:
      return {
        ...state,
        loadingLogin: false,
        error: null,
      };

    case LOGIN_ORG_ERROR:
      return {
        ...state,
        loadingLogin: false,
        error: action.error,
      };

    default:
      return state;
  }
};
