import { string, func, bool, number, objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CustomFormik from '../../CustomFormik';
import PageTitle from '../../PageTitle';
import ContactForm from './Form';
import styles from './styles';
import { createSchema, maxLengths } from './validation';
import useGetOrgSettings from '../../../../utils/hooks/useGetOrgSettings';
import organizationPageNavConfig from '../../../navigation/WizardLayout/organization.page.config';

export function Contact({
  classes,
  organizationId,
  saveOrganizationSettings,
  goToNext,
  goToPrev,
  pageTitleKey,
  setError,
  isFinished,
  submitCountFromParent,
  onGoBack,
  promptToCompleteOrganizationSettings,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const schema = createSchema();
  const { settings, loadingOrgSettings, savingOrgSettings } =
    useGetOrgSettings(organizationId);
  const isEditor = !!onGoBack;
  const orgSettingsLoaded =
    loadingOrgSettings !== undefined && !loadingOrgSettings;

  const continueWizard = useCallback(() => {
    const config = organizationPageNavConfig(settings);
    const nextPage = config.find((step) => step.next);
    history.push(nextPage.path);
  }, [settings, history]);

  useEffect(() => {
    if (
      promptToCompleteOrganizationSettings &&
      history.location.pathname === '/organization/wizard/continue' &&
      orgSettingsLoaded
    ) {
      continueWizard();
    }
  }, [
    promptToCompleteOrganizationSettings,
    history.location.pathname,
    orgSettingsLoaded,
    continueWizard,
  ]);

  const handleSubmit = async (values) => {
    const formValues = {
      ...settings,
      ...values,
    };
    const {
      dbaName,
      address,
      address2,
      city,
      state,
      zip,
      phone,
      ...updatableValues
    } = formValues;

    // If onGoBack is not null, this means is in Edit mode.
    const succeeded = await saveOrganizationSettings(
      organizationId,
      updatableValues,
      !!onGoBack,
    );

    if (succeeded) {
      if (onGoBack) {
        onGoBack();
      } else {
        goToNext();
      }
    }
  };

  return (
    <>
      <PageTitle
        className={isEditor ? classes.editorGutter : classes.pageTitle}
      >
        {t(pageTitleKey)}
      </PageTitle>
      <CustomFormik
        onErrorsChange={setError}
        enableReinitialize
        initialValues={{
          dbaName: settings?.dbaName || '',
          address: settings?.address || '',
          address2: settings?.address2 || '',
          city: settings?.city || '',
          state: settings?.state || '',
          zip: settings?.zip || '',
          phone: settings?.phone || '',
          email: settings?.email || '',
          website: settings?.website || '',
        }}
        onSubmit={handleSubmit}
        render={(formikProps) => (
          <ContactForm
            goToPrev={goToPrev}
            loading={loadingOrgSettings || savingOrgSettings}
            isFinished={isFinished}
            submitCountFromParent={submitCountFromParent}
            maxLengths={maxLengths}
            onGoBack={onGoBack}
            {...formikProps}
          />
        )}
        validationSchema={schema}
      />
    </>
  );
}

Contact.propTypes = {
  classes: objectOf(string).isRequired,
  organizationId: string.isRequired,
  saveOrganizationSettings: func.isRequired,
  goToNext: func,
  goToPrev: func,
  onGoBack: func,
  pageTitleKey: string,
  setError: func,
  isFinished: bool,
  submitCountFromParent: number,
  promptToCompleteOrganizationSettings: bool,
};

Contact.defaultProps = {
  goToNext: null,
  goToPrev: null,
  onGoBack: null,
  pageTitleKey: '',
  setError: null,
  isFinished: false,
  submitCountFromParent: 0,
  promptToCompleteOrganizationSettings: false,
};

export default withStyles(styles)(Contact);
