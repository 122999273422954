import { string, object, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import PageTitle from '../PageTitle';
import CustomFormik from '../CustomFormik';
import BasicInfoForm from './BasicInfoForm';
import { createSchema } from './validation';

export function VancoMobileBasicInfoWizard({
  savePage,
  goToNext,
  goToPrev,
  pageTitleKey,
  page,
  updatePage,
  setError,
  loadingSave,
  isFinished,
}) {
  const { t } = useTranslation();
  const schema = createSchema();

  const onSubmit = async (values) => {
    const formPage = {
      ...page,
      ...values,
    };

    await savePage(formPage);
    goToNext();
  };

  // TODO: Remove with ChannelManagement FF cleanup
  return (
    <>
      <PageTitle>{t(pageTitleKey)}</PageTitle>
      <CustomFormik
        onChange={updatePage}
        onErrorsChange={setError}
        initialValues={{
          pageName: page.pageName,
          address: page.address,
          address2: page.address2,
          city: page.city,
          zip: page.zip,
          state: page.state,
          provideAddress: page.provideAddress || true,
          phone: page.phone,
          email: page.email,
          providePhone: page.providePhone,
          provideEmail: page.provideEmail,
        }}
        onSubmit={onSubmit}
        enableReinitialize
        render={(formikProps) => (
          <BasicInfoForm
            {...formikProps}
            goToPrev={goToPrev}
            loading={loadingSave}
            isFinished={isFinished}
          />
        )}
        validationSchema={schema}
      />
    </>
  );
}

VancoMobileBasicInfoWizard.propTypes = {
  savePage: func.isRequired,
  goToNext: func.isRequired,
  goToPrev: func.isRequired,
  pageTitleKey: string,
  page: object.isRequired,
  updatePage: func,
  setError: func,
  loadingSave: bool.isRequired,
  isFinished: bool,
};

VancoMobileBasicInfoWizard.defaultProps = {
  pageTitleKey: '',
  updatePage: null,
  setError: null,
  isFinished: false,
};

export default VancoMobileBasicInfoWizard;
