import { Button, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import styles from './styles';
import { ReactComponent as FileIcon } from '../assets/addFile.svg';
import InfoBadge from '../InfoBadge';
import ProgressCircle from '../ProgressCircle';
import { REQUIRED_COLUMNS } from '../utils/const';

function ImportFileInput({
  classes,
  disabled,
  onFileSelected,
  isUploading,
  progress,
  progressVariant,
  error,
  errorMessage,
}) {
  const { t } = useTranslation();
  const [isDraggingFile, setIsDraggingFile] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileError, setFileError] = useState(false);

  const fileInputRef = useRef(null);
  const fileRef = useRef(null);
  let fileReader;

  useEffect(() => {
    setFileError(error);
  }, [error, setFileError]);

  function isCSV(type) {
    return type === 'text/csv';
  }
  function areColumndsValid(content) {
    const [columnsString] = content.split(/[\r\n]+/);
    const columns = columnsString.split(',');
    return REQUIRED_COLUMNS.every((col) => columns.includes(col));
  }
  const validateFile = () => {
    const content = fileReader.result;
    // validate here if all the required columns exist
    if (!content || !areColumndsValid(content)) {
      setFileName('');
      setFileError(true);
      onFileSelected(null, true);
      fileRef.current = null;
    }
  };
  function isValidContent() {
    fileReader = new FileReader();
    fileReader.onloadend = validateFile;
    fileReader.readAsText(fileRef.current);
  }

  function handleSelection(files) {
    const [loadedFile] = files;
    if (!isCSV(loadedFile.type)) {
      setFileError(true);
      onFileSelected(null, true);
      return;
    }
    setFileName(loadedFile.name);
    setFileError(false);
    fileRef.current = loadedFile;
    onFileSelected(loadedFile, false);
    isValidContent();
  }

  const onDropFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    if (isUploading) return;
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleSelection(e.dataTransfer.files);
    }
    setIsDraggingFile(false);
  };
  const onSelectFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isUploading || disabled) return;
    if (e.target.files && e.target.files[0]) {
      handleSelection(e.target.files);
    }
  };
  const onDragInto = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isUploading || disabled) return;
    setIsDraggingFile(true);
  };
  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const onDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isUploading) return;
    setIsDraggingFile(false);
  };
  const onClickBrowse = () => {
    if (disabled) return;
    fileInputRef.current.click();
  };
  return (
    <section className={classes.container}>
      {fileError && (
        <InfoBadge level="error">
          {errorMessage ||
            t('ministry.platform.import.fileInput.infoBadge.error')}
        </InfoBadge>
      )}
      <form
        className={`${classes.dropArea} ${
          disabled ? classes.disabledInput : ''
        } ${isDraggingFile ? classes.dropAreaHover : ''}`}
        onDrop={onDropFile}
        onDragEnter={onDragInto}
        onDragOver={onDragOver}
        onDragLeave={onDragOut}
      >
        <div
          className={`${classes.innerContainer} ${
            isDraggingFile ? classes.innerContainerHover : ''
          }`}
        >
          {isUploading ? (
            <ProgressCircle progress={progress} variant={progressVariant} />
          ) : (
            <div className={classes.iconWrapper}>
              <FileIcon className={classes.iconStyle} />
            </div>
          )}
          <Typography className={classes.pickerText}>
            {isUploading
              ? t('ministry.platform.import.fileInput.title.loading')
              : t('ministry.platform.import.fileInput.title')}
          </Typography>
          <Typography className={classes.pickerText}>
            {isUploading
              ? t('ministry.platform.import.fileInput.title.from')
              : t('ministry.platform.import.fileInput.title.or')}
          </Typography>
          <input
            type="file"
            className={classes.hiddenInput}
            ref={fileInputRef}
            onChange={onSelectFile}
            accept="text/csv"
          />
          {isUploading ? (
            <div className={classes.selectedFileContainer}>
              <Typography className={classes.selectedFileText}>
                {fileName}
              </Typography>
            </div>
          ) : (
            <div className={classes.fileInputContainer}>
              <Typography className={classes.fileNameContainer}>
                {fileName || t('ministry.platform.import.fileInput.browse')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={`${classes.browseButtonContainer}`}
                classes={{ disabled: classes.disabledButton }}
                onClick={onClickBrowse}
                disabled={disabled}
              >
                {t('ministry.platform.import.fileInput.button.browse')}
              </Button>
            </div>
          )}
        </div>
      </form>
    </section>
  );
}
ImportFileInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isUploading: PropTypes.bool,
  progress: PropTypes.number,
  progressVariant: PropTypes.oneOf(['determinate', 'indeterminate']),
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

ImportFileInput.defaultProps = {
  disabled: false,
  isUploading: false,
  progress: 0,
  progressVariant: 'determinate',
  error: false,
  errorMessage: null,
};

export default withStyles(styles)(ImportFileInput);
