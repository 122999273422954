import { connect } from 'react-redux';

import VancoMobileOrganizationDetails from './VancoMobileOrganizationDetails';
import { savePage } from '../../../store/pages/actions';
import { setSuccess } from '../../../store/alerts/actions';

export const mapStateToProps = state => ({
  loadingSave: state.pages.loadingSave,
});

export default connect(mapStateToProps, {
  savePage,
  setSuccess,
})(VancoMobileOrganizationDetails);
