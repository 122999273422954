export const REQUIRED_COLUMNS = [
  'UserID',
  'FirstName',
  'LastName',
  'EmailAddress',
  'AmountUSD',
  'AccountCode',
  'Frequency',
  'StartDate',
  'EndDate',
];

export const IMPORT_STATUS = {
  COMPLETE: 'Completed',
  FAILED: 'Failed',
  IN_PROGRESS: 'InProgress',
  COMPLETE_WITH_ERRORS: 'CompletedWithErrors',
};

export const SAMPLE_CSV_HEADER =
  'UserID,FirstName,LastName,EmailAddress,AmountUSD,AccountCode,Frequency,StartDate,EndDate';

export default { REQUIRED_COLUMNS, IMPORT_STATUS, SAMPLE_CSV_HEADER };
