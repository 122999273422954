import { connect } from 'react-redux';

import Redirect from './Redirect';
import { resetNewPage } from '../../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    pages: state.pages.pages,
    loadingPages: state.pages.loadingPages,
    hasLoadedPages: state.pages.hasLoadedPages,
  };
}

export default connect(
  mapStateToProps,
  { resetNewPage },
)(Redirect);
