import BaseService from '../BaseService';

class VancoMobileUsersService extends BaseService {
  getUsers() {
    return this.http.get('users/mobile');
  }

  getUser(id) {
    return this.http.get(`users/mobile/${id}`);
  }

  updateUser(user) {
    return this.http.put(`users/mobile/${user.id}`, { data: user });
  }

  inviteUsers(users) {
    return this.http.put('users/mobile/invite', { data: users });
  }

  getInviteCode() {
    return this.http.get('users/mobile/invitecode');
  }
}

export default new VancoMobileUsersService();
