import { pageNavConfig } from './page.config';
import { mobilePageNavConfig } from './mobile.page.config';
import { streamPageNavConfig } from './stream.page.config';
import { tileNavConfig } from './tile.config';
import { organizationPageNavConfig } from './organization.page.config';

export const getNavConfig = ({
  page,
  tile,
  settings,
  isMobilePage,
  isStreamPage,
  isOrganizationPage,
  communityFeaturesChoosen,
  features,
}) => {
  if (tile?.id) {
    return tileNavConfig(tile);
  }

  if (isMobilePage) {
    return mobilePageNavConfig(page || {}, communityFeaturesChoosen);
  }

  if (isStreamPage) {
    return streamPageNavConfig(page || {}, features);
  }

  if (isOrganizationPage) {
    return organizationPageNavConfig(settings || {});
  }

  return pageNavConfig(page || {}, features);
};

export const getExitPath = ({
  isTile,
  isMobilePage,
  isStreamPage,
  isOrganizationPage,
  pages = [],
}) => {
  if (isTile) {
    return '/';
  }

  // TODO: Remove with ChannelManagement FF cleanup
  if (isMobilePage) {
    return '/mobile/get-started';
  }

  if (isStreamPage) {
    return '/streams';
  }

  if (isOrganizationPage) {
    return '/organization/wizard/contact';
  }

  return pages?.length > 0 ? '/sites' : '/getStarted';
};

export default {
  getNavConfig,
  getExitPath,
};
