import { PublicClientApplication } from '@azure/msal-browser';

// This AuthService communicates with Azure AD B2C
export class AzureAuthService {
  static msalInstance;

  static initialized;

  static config = {
    auth: {
      authority: `${window.env.REACT_APP_AZURE_AUTH_DOMAIN}/${window.env.REACT_APP_AZURE_AUTH_TENANT}/${window.env.REACT_APP_AZURE_AUTH_SIGNIN}`,
      knownAuthorities: [window.env.REACT_APP_AZURE_AUTH_DOMAIN],
      clientId: window.env.REACT_APP_AZURE_AUTH_CLIENT_ID,
      responseType: 'code',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  };

  constructor() {
    this.msalInstance = new PublicClientApplication(AzureAuthService.config);
    this.initialized = false;
  }

  // Initializes the MSAL instance, returns the result of the login redirection
  async loginAsync() {
    let authResult = null;

    if (!this.initialized) {
      await this.msalInstance.initialize();
      authResult = await this.msalInstance.handleRedirectPromise();
      this.initialized = true;
    }

    if (authResult && authResult.account) {
      this.msalInstance.setActiveAccount(authResult.account);
    } else {
      return this.msalInstance.loginRedirect({
        state: '{ "redirectPath": "/"}',
      });
    }

    return authResult;
  }

  // Acquire session tokens using the provided account
  async acquireTokenSilentAsync(account) {
    if (!this.initialized) {
      await this.msalInstance.initialize();
      this.initialized = true;
    }

    const request = {
      account,
    };
    return this.msalInstance.acquireTokenSilent(request);
  }

  // Logout user
  async logoutAsync() {
    this.msalInstance.setActiveAccount(null);
    await this.msalInstance.logoutRedirect();
  }

  // Set the active account in the MSAL Instance
  setActiveAccount(account) {
    this.msalInstance.setActiveAccount(account);
  }

  // Get the active account in the MSAL Instance
  getActiveAccount() {
    return this.msalInstance.getActiveAccount();
  }
}

export default new AzureAuthService();
