import { connect } from 'react-redux';

import TileOverview from './TileOverview';
import { saveTile, resetNewTile } from '../../../store/tiles/actions';

export function mapStateToProps(state) {
  return {
    loadingSave: state.tiles.loadingSave,
    newTile: state.tiles.newTile,
  };
}

export default connect(mapStateToProps, { saveTile, resetNewTile })(
  TileOverview,
);
