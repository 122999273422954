export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: 48,
      minHeight: 300,
    },
    bold: {
      fontWeight: 'bold',
    },
    requestHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
    },
    headerText: {
      fontWeight: 'bold',
      fontSize: 24,
    },
    kioskIcon: {
      marginRight: 24,
      transform: 'scale(1.5)',
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
    kioskButton: {
      marginTop: 42,
    },
    readyMessage: {
      fontWeight: 'bold',
      fontSize: 24,
      marginTop: 16,
    },
  };
}
