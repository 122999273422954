export default () => ({
  paper: {
    marginTop: 5,
    minWidth: 216,
    padding: '10px 8px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 32px 8px 8px',
    borderRadius: 4,
    cursor: 'pointer',
    background: 'none',
    border: 0,
    width: '100%',
    textAlign: 'left',
    '&:hover': {
      background: '#EEEEEE',
    },
    '&:focus': {
      outlineStyle: 'none',
    },
  },
  button: {
    borderRadius: 4,
  },
  divider: {
    margin: '7px -8px',
  },
});
