import { useRef, useState, useEffect } from 'react';
import { Profile } from '@vancoplatform/web-auth';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';

export function Settings({ classes }) {
  const iframeRef = useRef(null);
  const [profileRendered, setProfileRendered] = useState(false);
  useEffect(() => {
    if (!profileRendered && iframeRef.current) {
      setProfileRendered(true);
      const profile = new Profile({
        element: iframeRef.current,
        url: window.env.REACT_APP_IDS_DOMAIN,
        tenant: window.env.REACT_APP_IDS_TENANT,
        clientid: window.env.REACT_APP_IDS_CLIENT_ID,
      });

      profile.render();
    }
  }, [profileRendered, setProfileRendered]);

  return (
    <div
      id="ids-profile-root"
      data-testid="ids-profile-root"
      className={classes.container}
      ref={iframeRef}
    />
  );
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
