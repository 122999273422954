/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
import { bool, func, objectOf, string, oneOf } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Modal, PrimaryButton } from '../..';

import styles from './styles';

export function UpdateMobileUserModal({
  classes,
  open,
  onClose,
  onConfirm,
  type,
  userName,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title className={classes.title}>
        {t(`vanco.mobile.modal.${type}.title`)}
      </Modal.Title>
      <Modal.Content className={classes.content}>
        <Typography>
          <b>{userName}</b> {t(`vanco.mobile.modal.${type}.description`)}
        </Typography>
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <Button
          onClick={onClose}
          size="large"
          color="primary"
          variant="outlined"
          className={classes.closeButton}
          disabled={disabled}
        >
          {t('button.cancel')}
        </Button>
        <PrimaryButton
          size="large"
          data-testid="transfer-ownership"
          onClick={onConfirm}
          disabled={disabled}
        >
          {t(`vanco.mobile.modal.${type}.action`)}
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
}

UpdateMobileUserModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  type: oneOf([
    'suspendUser',
    'unsuspendUser',
    'makeModerator',
    'removeModerator',
  ]).isRequired,
  userName: string.isRequired,
  disabled: bool,
};

UpdateMobileUserModal.defaultProps = {
  open: false,
  disabled: false,
};

export default withStyles(styles)(UpdateMobileUserModal);
