import { NAVBAR_HEIGHT } from '../../../globals/dimensions';

export default function styles() {
  return {
    main: {
      display: 'flex',
      flex: 1,
      height: '100%',
      paddingTop: NAVBAR_HEIGHT,
      overflow: 'hidden',
    },
    contentWrap: {
      flex: 1,
    },
    content: {
      height: 'calc(100vh - 75px)',
      overflowY: 'auto',
    },
  };
}
