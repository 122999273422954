import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { withStyles } from '@material-ui/core/styles';

import { Modal } from '../../../common';

import { EDIT_USER_STEPS } from '../../../../globals/constants';
import SwipeableContainer from '../../../common/SwipeableContainer';
import EditUserRoleForm from '../EditUserRoleForm';
import TransferOwnershipForm from '../TransferOwnershipForm';

import styles from './styles';

export function EditUserModal({
  classes,
  open,
  user,
  onClose,
  onUserEdit,
  onTransferOwnership,
  isLoading,
}) {
  const { EDIT_ROLE, TRANSFER_OWNERSHIP } = EDIT_USER_STEPS;
  const { t } = useTranslation();
  const [formStep, setFormStep] = useState(EDIT_ROLE);

  const userNameString =
    user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName} (${user.userName})`
      : user.userName;

  useEffect(() => {
    if (!open) {
      setFormStep(EDIT_ROLE);
    }
  }, [open, EDIT_ROLE]);

  const getTitle = () => {
    let title = '';

    switch (formStep) {
      case EDIT_ROLE:
        title = t('users.editRole.title');
        break;
      case TRANSFER_OWNERSHIP:
        title = t('users.transferOwnership.title');
        break;
      default:
    }

    return title;
  };

  const onBackStep = () => {
    setFormStep(EDIT_ROLE);
  };

  const onTransferOwnershipSelect = () => {
    setFormStep(TRANSFER_OWNERSHIP);
  };

  return (
    <Modal
        open={open}
        onClose={onClose}
        data-testid="edit-user-modal"
        className={classes.modal}
      >
        <Modal.Title
          icon={formStep === TRANSFER_OWNERSHIP ? <ArrowBack /> : null}
          onIconClick={onBackStep}
        >
          {getTitle()}
        </Modal.Title>
        <SwipeableContainer currentSlideIndex={formStep}>
          <SwipeableContainer.Slide slideIndex={EDIT_ROLE}>
            <EditUserRoleForm
              user={{ ...user, userNameString }}
              onTransferOwnership={onTransferOwnershipSelect}
              onClose={onClose}
              onSave={onUserEdit}
              isLoading={isLoading}
            />
          </SwipeableContainer.Slide>
          <SwipeableContainer.Slide slideIndex={TRANSFER_OWNERSHIP}>
            <TransferOwnershipForm
              user={user}
              onClose={onClose}
              onSave={onTransferOwnership}
              isLoading={isLoading}
            />
          </SwipeableContainer.Slide>
        </SwipeableContainer>
      </Modal>
  );
}

export default withStyles(styles)(EditUserModal);

EditUserModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onUserEdit: PropTypes.func.isRequired,
  onTransferOwnership: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
