import { connect } from 'react-redux';
import KioskRequest from './KioskRequest';
import { requestKiosk } from '../../../../store/kiosk/actions';

export function mapStateToProps(state) {
  return {
    wasKioskRequested: state.kiosk.wasKioskRequested,
    loadingKioskRequest: state.kiosk.loadingKioskRequest,
  };
}

export default connect(mapStateToProps, { requestKiosk })(KioskRequest);
