/* eslint-disable class-methods-use-this */
import BaseService from '../BaseService';
import {
  getOrRenewAccessToken,
  logout,
  refreshPermissions,
} from '../../store/user/actions';
import Cookies from '../../utils/Cookies';
import { handleException } from '../../store/alerts/actions';
import UserService from '../UserService';
import { internalLogout } from '../../store/internal/actions';

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

export class InterceptorService extends BaseService {
  makeRequestInterceptors = (store) => {
    return async (config) => {
      let newConfig = await this.addAccessTokenToHeaders(store, config);
      newConfig = this.addCsrfTokenToHeaders(newConfig);
      newConfig = this.addOrganizationId(store, newConfig);
      return newConfig;
    };
  };

  // TODO: We should remove this eventually when we know more about what API calls need
  // the organization ID.
  addOrganizationId = (store, config) => {
    const organizationId =
      store.getState().organizations?.currentOrganization?.id;
    const newConfig = {
      ...config,
    };

    // Check if the request should skip adding organizationId
    if (config.skipAddOrganizationId) {
      return newConfig;
    }

    if (organizationId) {
      if (newConfig.params) {
        newConfig.params.organizationId = organizationId;
      } else {
        newConfig.params = { organizationId };
      }
    }

    return newConfig;
  };

  addAccessTokenToHeaders = async (store, config) => {
    // Because we are logging out a user do not attempt to add an access token
    // otherwise getOrRenewAccessToken will infinite loop because the token
    // could be expired.
    const blackList = [UserService.getLogoutUrl()];
    if (blackList.includes(config.url)) {
      return config;
    }

    let accessToken;
    try {
      accessToken = await store.dispatch(getOrRenewAccessToken());
    } catch (e) {
      handleException(e, false);
    }
    const headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return {
      ...config,
      headers,
    };
  };

  addCsrfTokenToHeaders = (config) => {
    const csrfToken = Cookies.getCSRFToken();
    const headers = {
      ...config.headers,
      'x-csrf-token': csrfToken,
    };
    return {
      ...config,
      headers,
    };
  };

  makeRejectionInterceptor = (store) => {
    const isUserInternal = () => {
      const { isInternal = false, wasInternal = false } =
        store.getState()?.user?.user || {};
      return Boolean(isInternal || wasInternal);
    };
    return async (error) => {
      if (!error.response) {
        return Promise.reject(error);
      }
      const { status } = error.response;
      if (status === UNAUTHORIZED) {
        store.dispatch(logout());
      } else if (status === FORBIDDEN) {
        if (isUserInternal()) {
          await store.dispatch(internalLogout());
          return Promise.resolve();
        }
        return store
          .dispatch(refreshPermissions())
          .then((e) => Promise.reject(e ?? error));
      }
      return Promise.reject(error);
    };
  };

  addInterceptors(store) {
    // Add a request interceptor
    this.http.http.interceptors.request.use(
      this.makeRequestInterceptors(store),
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    // Add a response interceptor
    this.http.http.interceptors.response.use(
      (response) => response,
      this.makeRejectionInterceptor(store),
    );
  }
}

export default new InterceptorService();
