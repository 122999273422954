export default function styles() {
  return {
    modal: {
      width: 548,
    },
    modalTitle: {
      borderBottom: 0,
      paddingBottom: 0,
      marginBottom: 0,
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    description: {
      fontSize: 16,
      marginBottom: 32,
      '&:last-of-type': {
        marginBottom: 37,
      },
    },
    buttonsContainer: {
      justifyContent: 'flex-start',
      borderTop: 0,
      paddingTop: 0,
    },
  };
}
