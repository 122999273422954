export default function styles(theme) {
  return {
    tooltip: {
      backgroundColor: theme.palette.common.white,
      border: '1px solid rgba(0, 0, 0, 0.04)',
      boxShadow:
        '0px 5px 15px rgba(0, 0, 0, 0.06), 0px 15px 35px rgba(0, 0, 0, 0.08)',
      borderRadius: '4px',
      minWidth: 340,
    },
    tooltipContent: {
      margin: '16px 8px',
    },
    siteNamesText: {
      fontSize: 14,
    },
    channelLabel: {
      fontSize: 12,
      fontWeight: 600,
      color: '#717171',
      margin: '8px 0',
    },
    list: {
      paddingLeft: 20,
      marginTop: 0,
      '& li::marker': {
        color: 'green',
        content: '"• "',
        fontSize: 24,
        marginTop: 0,
        paddingTop: 0,
        listStyle: 'none',
      },
    },
    iconContainer: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'inline-flex',
      border: '1px solid #DADADA',
      boxSizing: 'border-box',
      borderRadius: '50%',
      fontSize: 20,
      justifyContent: 'center',
      padding: 5,
      position: 'relative',
      marginRight: 8,
    },
  };
}
