/* eslint-disable react/function-component-definition */
import { createContext, useContext, useMemo } from 'react';
import { PAYWALL_TEST_IDS } from 'globals/constants';
import { Page, PageDescription, PageTitle } from 'components/common';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import insightsPaywall from './InsightsPaywall.png';
import { useStyles } from './styles';

const InsightsPaywallContext = createContext({});
const useInsightsPaywallContext = () => useContext(InsightsPaywallContext);

const Description = () => {
  const { t, isAccountOwner, classes } = useInsightsPaywallContext();
  const contactClass = classes.vancoContact;

  const mailTo = `mailto: ${t('paywall.description2.emailUs.email')}`;
  const callTo = `tel: ${t('paywall.description2.callUs.number')}`;

  return isAccountOwner ? (
    <>
      {t('paywall.insights.accountOwner.description')}{' '}
      {t('paywall.description2.callUs')}{' '}
      <a href={callTo} className={contactClass}>
        {t('paywall.description2.callUs.number')}
      </a>{' '}
      {t('paywall.description2.emailUs')}{' '}
      <a href={mailTo} className={contactClass}>
        {t('paywall.description2.emailUs.email')}
      </a>
      .
    </>
  ) : (
    <>{t('paywall.insights.description')}</>
  );
};

const AccountOwnerFooter = () => {
  const { t, classes } = useInsightsPaywallContext();

  const handleGetInsightsClick = () =>
    window.open(window.env.REACT_APP_PAYWALL_FORM_URL, '_tab');

  return (
    <>
      <PageDescription className={classes.titlePremiumF}>
        {t('paywall.insights.footer')}
      </PageDescription>
      <div className={classes.buttonContainer}>
        <ButtonBase
          className={classes.button}
          size="large"
          onClick={handleGetInsightsClick}
        >
          {t('paywall.insights.button')}
        </ButtonBase>
      </div>
    </>
  );
};

const InsightsPaywall = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isAccountOwner } = useUser();

  const descriptionId = isAccountOwner
    ? PAYWALL_TEST_IDS.INSIGHTS.ACC_OWNER_DESC
    : PAYWALL_TEST_IDS.INSIGHTS.DESC;

  const providerValue = useMemo(
    () => ({ t, isAccountOwner, classes }),
    [t, isAccountOwner, classes],
  );

  return (
    <InsightsPaywallContext.Provider value={providerValue}>
      <Page variant="centered">
        <PageTitle className={classes.title}>
          {t('paywall.insights.title')}
        </PageTitle>

        <PageTitle className={classes.subtitle}>
          {t('paywall.insights.subTitle')}
        </PageTitle>

        <PageDescription
          data-testid={descriptionId}
          className={classes.pageDescription}
        >
          <Description />
        </PageDescription>

        <div className={classes.imageContainer}>
          <img
            src={insightsPaywall}
            alt="paywallImage"
            className={classes.image}
          />
        </div>

        {isAccountOwner ? <AccountOwnerFooter /> : null}
      </Page>
    </InsightsPaywallContext.Provider>
  );
};

export default InsightsPaywall;
