import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, objectOf, string } from 'prop-types';
import { withStyles } from '@material-ui/core';

import Page from '../Page';
import PageTitle from '../PageTitle';
import PagePaper from '../PagePaper';
import CustomFormik from '../CustomFormik';
import PreventLeavePageModal from '../PreventLeavePageModal';
import Form from './Form';

import useGetPageSettings from '../../../utils/hooks/useGetPageSettings';
import usePreventModal from '../../../utils/hooks/usePreventModal';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';

import styles from './styles';
import { defaultPage } from '../../../globals/defaults';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

export function VancoMobileEditPermissions({
  classes,
  loadingSave,
  updateAddressRequired,
}) {
  const { t } = useTranslation();
  usePageTitle(t('vanco.mobile.donor.settings.title'));
  const [dirty, setDirty] = useState(false);

  const { goBack, onCancel, cancelLeave } = usePreventModal('/mobile/home');
  const { isLoading, page } = useGetPageSettings(
    CHANNEL_TYPE.MOBILE,
    PAGE_ACTIVE,
  );

  const onSubmit = async (values) => {
    setDirty(false);
    await updateAddressRequired(page.id, values.addressRequired);
    goBack();
  };

  if (isLoading) {
    return null;
  }

  const handleChange = () => setDirty(true);

  return (
    <Page variant="centered">
      <PageTitle className={classes.title} onGoBack={goBack}>
        {t('vanco.mobile.donor.settings.title')}
      </PageTitle>
      <PagePaper className={classes.paper}>
        <CustomFormik
          initialValues={{
            addressRequired:
              page.addressRequired || defaultPage.addressRequired,
          }}
          onSubmit={onSubmit}
          onChange={handleChange}
          render={(formikProps) => (
            <Form
              {...formikProps}
              loading={loadingSave}
              dirty={dirty}
              onCancel={onCancel}
            />
          )}
        />
      </PagePaper>
      <PreventLeavePageModal shouldOpenOnLeave={dirty && !cancelLeave} />
    </Page>
  );
}

VancoMobileEditPermissions.propTypes = {
  classes: objectOf(string).isRequired,
  loadingSave: bool.isRequired,
  updateAddressRequired: func.isRequired,
};

export default withStyles(styles)(VancoMobileEditPermissions);
