export default function styles() {
  return {
    wrap: {
      borderBottom: '1px solid #EBEBEB',
      '&:first-child': {
        paddingTop: 29,
      },
    },
    menuLink: {
      padding: '14px 46px',
      minHeight: 52,
      display: 'flex',
      '&$noIcon': {
        padding: '14px 32px 14px 17px',
      },
    },
    label: {
      marginLeft: 15,
    },
    active: {
      fontWeight: 700,
    },
    noIcon: {},
  };
}
