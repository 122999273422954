import { Button, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';

import useNewImport from '../hooks/useNewImport';
import useTenantInfo from '../hooks/useTenantInfo';
import ImportFileInput from '../ImportFileInput';
import InfoBadge from '../InfoBadge';
import ReloadFIP from '../ReloadFIP';
import styles from './styles';

const queryClient = new QueryClient();
function ImportManager({
  classes,
  idsPayerTenant,
  accessToken,
  onUploadSuccess,
}) {
  const { t } = useTranslation();
  const fileRef = useRef(null);
  const importMutation = useNewImport();
  const [hasSetupFIP, setHasSetupFIP] = useState(false);
  const [followedRedirect, setFollowedRedirect] = useState(false);
  const [isStartDisabled, setIsStartDisabled] = useState(true);
  const identityProviderURL = `https://${window.env.REACT_APP_IDS_DOMAIN}/developers/${idsPayerTenant}/sso-providers/new`;
  const [errorMessage, setErrorMessage] = useState(null);
  const [tenantData, tenantError, tenantDataIsLoading] = useTenantInfo(
    idsPayerTenant,
    accessToken,
  );

  useEffect(() => {
    if (importMutation.isSuccess) {
      onUploadSuccess();
    }
  }, [importMutation.isSuccess, onUploadSuccess]);

  useEffect(() => {
    if (!importMutation.isIdle) {
      setIsStartDisabled(importMutation.isLoading || importMutation.isError);
    }
    if (importMutation.isError && importMutation.errors) {
      const [errorResponse] = importMutation.errors;
      setErrorMessage(errorResponse?.errorMessage);
    }
  }, [
    importMutation.isIdle,
    importMutation.isError,
    importMutation.isLoading,
    importMutation.errors,
  ]);

  const startUpload = () => {
    importMutation.createNewImport({
      file: fileRef.current,
      tenant: idsPayerTenant,
      authToken: accessToken,
    });
  };
  const onFileSelected = (selectedFile, error) => {
    if (selectedFile && !error) {
      fileRef.current = selectedFile;
      importMutation.reset();
      setIsStartDisabled(false);
    } else {
      setIsStartDisabled(true);
    }
  };
  useEffect(() => {
    if (!tenantError && tenantData) {
      // check for only 1 provider and fail it if there are more
      setHasSetupFIP(tenantData.ssoProviders.length === 1);
    } else {
      setHasSetupFIP(false);
    }
  }, [tenantDataIsLoading, tenantError, tenantData]);

  const goToFIP = () => setFollowedRedirect(true);
  return (
    <QueryClientProvider client={queryClient}>
      {followedRedirect ? (
        <ReloadFIP />
      ) : (
        <section className={classes.container}>
          {!hasSetupFIP && (
            <InfoBadge>
              {t('ministry.platform.import.fileInput.infoBadge.info')}
              <a
                className={classes.infoText}
                onClick={goToFIP}
                href={identityProviderURL}
                rel="noreferrer"
                target="_blank"
              >
                {t('ministry.platform.import.fileInput.infoBadge.info.link')}
              </a>
            </InfoBadge>
          )}
          <ImportFileInput
            onFileSelected={onFileSelected}
            progress={
              fileRef.current?.size
                ? 100 * (importMutation.progress / fileRef.current.size)
                : 0
            }
            isUploading={importMutation.isLoading}
            progressVariant="indeterminate"
            error={importMutation.isError}
            errorMessage={errorMessage}
          />

          <Button
            variant="contained"
            color="primary"
            classes={{ disabled: classes.disabledButton }}
            disabled={isStartDisabled}
            onClick={startUpload}
          >
            {t('ministry.platform.import.fileInput.button.start')}
          </Button>
        </section>
      )}
      ;
    </QueryClientProvider>
  );
}

ImportManager.propTypes = {
  classes: PropTypes.object.isRequired,
  idsPayerTenant: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
};

export default withStyles(styles)(ImportManager);
