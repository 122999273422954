export default function styles() {
  return {
    header: {
      marginTop: 40,
      marginBottom: 10,
    },
    radioGroup: {
      width: '100%',
    },
    inputWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      width: 90,
      marginRight: 10,
    },
  };
}
