import { IconButton, withStyles } from '@material-ui/core';
import { func, node, objectOf, string } from 'prop-types';

import styles from './styles';

function ImageContainer({
  classes,
  imageUrl,
  backgroundColor,
  onRemove,
  children,
}) {
  const backgroundImage = imageUrl
    ? { backgroundImage: `url(${imageUrl})` }
    : {};

  return (
    <div
      data-testid="image-or-background-color"
      className={classes.root}
      style={{
        backgroundColor,
        ...backgroundImage,
      }}
    >
      {!imageUrl && children}
      {imageUrl && onRemove && (
        <IconButton
          size="small"
          onClick={onRemove}
          className={classes.removeImageButton}
          role="button"
          aria-label={`Image ${imageUrl}`}
        >
          <svg className={classes.removeImageIcon}>
            <circle cx="12" cy="12" r="11.0" fill="white" />
            <line x1="7" y1="7" x2="17" y2="17" />
            <line x1="7" y1="17" x2="17" y2="7" />
          </svg>
        </IconButton>
      )}
    </div>
  );
}

ImageContainer.propTypes = {
  classes: objectOf(string).isRequired,
  imageUrl: string,
  backgroundColor: string.isRequired,
  onRemove: func,
  children: node,
};

ImageContainer.defaultProps = {
  imageUrl: null,
  onRemove: null,
  children: null,
};

export default withStyles(styles)(ImageContainer);
