import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { useCallback, useEffect } from 'react';
import { useACSTBlocking } from 'utils/hooks';
import PageTitle from '../../common/PageTitle';
import PagePaper from '../../common/PagePaper';
import { Page } from '../../common';
import Tabs from './Tabs';
import styles from './styles';
import ReportsList from './ReportsList';
import { REPORTS_EVENTS_TABS } from '../../../globals/constants';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

const tabPaths = {
  [REPORTS_EVENTS_TABS.FINANCIAL.toLowerCase()]: REPORTS_EVENTS_TABS.FINANCIAL,
};

const today = moment(new Date());
const tomorrow = moment(new Date()).add(1, 'day');
const dayNumber = moment(new Date()).isoWeekday();
const firstDay = new Date(today.year(), today.month(), 1);

let monday;
// It means it's not Monday but, Tuesday Wed...
if (dayNumber > 1) {
  monday = moment(new Date()).subtract(dayNumber - 1, 'day');
} else {
  monday = moment(new Date());
}

const eventsFiltered = [
  {
    name: 'Transaction Detail',
    description: 'Transaction Detail Report',
    url: 'https://app.sigmacomputing.com/embed/2hMJOPhZ6YBmzDOCVUT1S8/tag/Live',
    filter: `&TD_DD=${tomorrow.format('YYYY-MM-DD')}`,
    tutorial: 'TransactionDetails.mov',
  },
  {
    name: 'Failed Transactions',
    description: 'Failed Transactions Report',
    url: 'https://app.sigmacomputing.com/embed/5GAgcybk60Tn8OZrxObySl/tag/Live',
    filter: `&FT_RD=${today.format('YYYY-MM-DD')}`,
    tutorial: 'FailedTransactions.mov',
  },
  {
    name: 'Confirmation Report',
    description: 'Confirmation Report',
    url: 'https://app.sigmacomputing.com/embed/7lipEqP9aIDRRyhGKLzjrm/tag/Live',
    filter: `&CR_RD=${today.format('YYYY-MM-DD')}`,
    tutorial: 'ConfirmationReport.mov',
  },
  {
    name: 'Weekly Activity Report',
    description: 'Weekly Activity Report',
    url: 'https://app.sigmacomputing.com/embed/6mknn6QycV0YbL4DbEB4J5/tag/Live',
    filter: `&WAR_RW=${monday.format('YYYY-MM-DD')}`,
    tutorial: 'WeeklyActivity.mov',
  },
  {
    name: 'Monthly Activity Report',
    description: 'Monthly Activity Report',
    url: 'https://app.sigmacomputing.com/embed/7KkKjND74izTX6tQGj51gY/tag/Live',
    filter: `&MAR_RM=${moment(firstDay).format('YYYY-MM-DD')}`,
    tutorial: 'MonthlyActivity.mov',
  },
];

export function Reports({ classes }) {
  const { filter = REPORTS_EVENTS_TABS.FINANCIAL } = useParams();
  const eventTab = tabPaths[filter] || REPORTS_EVENTS_TABS.FINANCIAL;
  const { isPFOrganization } = useACSTBlocking();
  const onChangeTab = () => {};

  const { t } = useTranslation();
  usePageTitle(t('app.menu.reporting'));

  const history = useHistory();

  const redirectToError = useCallback(() => {
    history.push('/*');
  }, [history]);

  // Prevent PF orgs to use Reporting page
  useEffect(() => {
    if (isPFOrganization) {
      redirectToError();
    }
  }, [isPFOrganization, redirectToError]);

  return (
    <Grid className={classes.mainContainer}>
      <Grid item lg={12}>
        <Page>
          <PageTitle className={classes.title}>
            {t('sigmareports.title')}
          </PageTitle>
          <Tabs value={eventTab} onChange={onChangeTab} />
          <PagePaper className={classes.pagePaper}>
            <ReportsList events={eventsFiltered} eventTab={eventTab} />
          </PagePaper>
        </Page>
      </Grid>
    </Grid>
  );
}

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Reports);
