import PropTypes, { objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as Language } from '../../../assets/vancoOnlineIcon.svg';

import { SetupLayout } from '../../common';
import pageNavConfig from '../../navigation/WizardLayout/page.config';
import {
  PAGE_PENDING,
  CHANNEL_TYPE,
  PAGE_ACTIVE,
} from '../../../globals/constants';
import useSetCurrentPageByChannel from '../../../utils/hooks/useSetCurrentPageByChannel';

import styles from './styles';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

function GetStarted({
  classes,
  loadingCreate,
  history,
  resetNewPage,
  loadingPages,
  hasLoadedPages,
  features,
}) {
  const { t } = useTranslation();
  usePageTitle(t('titles.getStarted'));

  const currentPage = useSetCurrentPageByChannel({
    channelType: CHANNEL_TYPE.ONLINE,
    pageStatus: [PAGE_PENDING, PAGE_ACTIVE],
  });

  const page = currentPage || {};

  if (loadingPages || !hasLoadedPages) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const startWizard = async () => {
    resetNewPage();
    history.push(`/wizard/page-info`);
  };

  const continueWizard = () => {
    resetNewPage();
    const config = pageNavConfig(currentPage, features);

    if (currentPage.status === PAGE_ACTIVE) {
      history.push(
        config[config.length - 1].path.replace(':pageId', currentPage.id),
      );
    } else {
      const nextPage = config.find((step) => step.next);
      history.push(nextPage.path.replace(':pageId', currentPage.id));
    }
  };

  return (
    <SetupLayout>
      <SetupLayout.Title>
        <Language
          className={classes.colorIcon}
          data-testid="left-nav-language-icon"
        />
        {t('wizard.getStarted.title')}
      </SetupLayout.Title>
      <SetupLayout.Content>{t('wizard.getStarted.body')}</SetupLayout.Content>
      {!loadingCreate && !page.status && (
        <SetupLayout.Button
          className={classes.button}
          size="large"
          onClick={startWizard}
        >
          {t('button.getStarted')}
        </SetupLayout.Button>
      )}
      {!loadingCreate && page.status && (
        <SetupLayout.Button
          className={classes.button}
          size="large"
          onClick={continueWizard}
        >
          {t('button.continueSetup')}
        </SetupLayout.Button>
      )}
      {loadingCreate && <CircularProgress color="primary" />}
    </SetupLayout>
  );
}

GetStarted.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingCreate: PropTypes.bool.isRequired,
  resetNewPage: PropTypes.func.isRequired,
  loadingPages: PropTypes.bool.isRequired,
  hasLoadedPages: PropTypes.bool.isRequired,
  features: objectOf(PropTypes.bool).isRequired,
};

export default withStyles(styles)(GetStarted);
