import { connect } from 'react-redux';
import SitesList from './SitesList';
import {
  savePage,
  removePage,
  resetNewPage,
} from '../../../store/pages/actions';
import { setSuccess } from '../../../store/alerts/actions';

const mapStateToProps = (state) => ({
  pages: (state.pages.pages || []).sort((a, b) => {
    return a.pageName < b.pageName ? -1 : 1;
  }),
  loadingSave: state.pages.loadingSave,
});

export default connect(mapStateToProps, {
  savePage,
  setSuccess,
  removePage,
  resetNewPage,
})(SitesList);
