export default theme => ({
  container: {
    border: '1px solid #ccc',
    borderRadius: 6,
    minHeight: 88,
    maxHeight: 224,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 16,
    marginTop: 16,
    maxWidth: 500,
  },
  input: {
    border: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    outline: 'none',
    display: 'block',
    width: '100%',
  },
  emailBadge: {
    background: '#f6f6f7',
    borderRadius: 6,
    padding: '6px 30px 6px 8px',
    marginBottom: 10,
    fontSize: 16,
    display: 'table',
    position: 'relative',
  },
  emailBadgeError: {
    background: '#FBF3F5',
  },
  errorIcon: {
    fontSize: 16,
    position: 'relative',
    top: 3,
    marginRight: 4,
    color: '#C82947',
  },
  emailText: {
    maxWidth: 'calc(480px - 65px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: theme.typography.fontFamily,
  },
  remove: {
    color: '#666666',
    fontSize: 16,
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },
});
