export default function styles() {
  return {
    bannerHeading: {
      marginTop: '48px',
      marginBottom: '16px',
      maxWidth: 700,
    },
    bannerDescription: {
      maxWidth: 700,
      marginBottom: '10px',
    },
    right: {
      float: 'right',
      marginTop: 40,
    },
    uploadIcon: {
      marginRight: '8px',
    },
    imageBox: {
      width: 325,
      height: 180,
      borderRadius: 0,
    },
    removeImageButton: {
      top: 12,
      right: 12,
    },
  };
}
