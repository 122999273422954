export default function styles(theme) {
  return {
    mainContainer: {
      flex: 'auto',
      width: '100%',
    },
    pagePaper: {
      padding: 20,
    },
    table: {
      marginTop: '60px',
    },
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      marginBottom: 0,
    },
    titleOrig: {
      marginTop: 'auto',
      marginBottom: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    buttonContainer: {
      marginTop: 8,
    },
    goToSiteButton: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: '8px',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '8px',
      },
    },

    linkSiteText: {
      marginRight: '10px',
    },

    leftMargin: {
      marginLeft: '8px',
    },
    mainToolbar: {
      display: 'flex',
      marginBottom: '38px',
    },
    filterButton: {
      fontSize: '16px',
      lineHeight: '24px',
      marginLeft: '16px',
      fontWeight: 700,
      '& > span svg': {
        marginLeft: '8px',
        height: '24px',
        width: '24px',
      },
    },
    group1: {
      display: 'flex',
      flexGrow: 1,
    },
    group2: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
    mainAffect: {},
    divider: {
      marginTop: theme.spacing(4),
    },
    accountList: {
      padding: 0,
      height: '100vh',
      marginTop: -10,
      overflowY: 'auto',
    },
    listItem: {
      borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
      padding: '24px',
    },
    overflow: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    noEntryText: {
      fontSize: '20px',
      lineHeight: '25.1px',
    },
    tryAgainText: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    emphesisWord: {
      marginLeft: '4px',
      fontWeight: 'bold',
    },
    messageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '35vh',
    },
  };
}
