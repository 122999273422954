import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core';

import StaticLogo from './StaticLogo';
import AccountSelector from './AccountSelector';
import { ReactComponent as Profile } from '../../../assets/profileIcon.svg';

import styles from './styles';

export function Navbar({
  classes,
  navActions,
  leftNavOpen,
  profileNavOpen,
  isLoggedIn,
  fullName,
  location,
  organizationId,
  associatedOrganizations,
  organizationName,
}) {
  const { t } = useTranslation();
  const currentPath = location.pathname;
  const isDesktop = useMediaQuery('(min-width:1280px)');
  const isProfile = currentPath.includes('profile');
  const staticLogoPages = ['wizard', 'internal'];
  const hideAccountSelector = staticLogoPages.some(s =>
    currentPath.includes(s),
  );
  const showMenu = !isDesktop || isProfile;
  const displayStaticLogo = !isLoggedIn || isProfile || hideAccountSelector;

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {isLoggedIn && showMenu && (
          <>
            <Button
              className={classes.menuButton}
              onClick={navActions.toggleLeftNav}
            >
              {!leftNavOpen && <MenuIcon />}
              {leftNavOpen && <CloseIcon />}
              <Typography className={classes.profileMenuText}>
                {t('app.menu')}
              </Typography>
            </Button>
            <IconButton
              className={classes.menuIconButton}
              onClick={navActions.toggleLeftNav}
            >
              {!leftNavOpen && <MenuIcon />}
              {leftNavOpen && <CloseIcon />}
            </IconButton>
          </>
        )}

        {displayStaticLogo && <StaticLogo isMobile={showMenu} />}

        {!displayStaticLogo && (
          <AccountSelector
            associatedOrganizations={associatedOrganizations}
            isMobile={showMenu}
            organizationId={organizationId}
            setOrganization={navActions.setOrganization}
            organizationName={organizationName}
          />
        )}

        {isLoggedIn && (
          <div className={classes.sectionDesktop}>
            <IconButton
              id="mobile-profile-nav-toggle-btn"
              aria-haspopup="true"
              className={classes.mobileProfileIcon}
              onClick={navActions.toggleProfileNav}
            >
              {!profileNavOpen && <Profile />}
              {profileNavOpen && <CloseIcon />}
            </IconButton>
            <Button
              className={classes.desktopProfile}
              onClick={navActions.toggleProfileNav}
              id="profile-nav-toggle-btn"
            >
              <Profile className={classes.profileIcon} />
              <Typography className={classes.name}>
                {fullName || t('app.profile')}
              </Typography>
            </Button>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
  navActions: PropTypes.object.isRequired,
  leftNavOpen: PropTypes.bool.isRequired,
  profileNavOpen: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  fullName: PropTypes.string,
  location: PropTypes.object.isRequired,
  organizationId: PropTypes.string,
  associatedOrganizations: PropTypes.arrayOf(PropTypes.object),
  organizationName: PropTypes.string,
};

Navbar.defaultProps = {
  fullName: '',
  associatedOrganizations: [],
  organizationId: '',
  organizationName: '',
};

export default withStyles(styles)(withRouter(Navbar));
