import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import VancoMobileUsersService from '../../../api/VancoMobileUsersService';
import { setSuccess, handleException } from '../../../store/alerts/actions';

const invite = async ({ users }) => {
  const res = await VancoMobileUsersService.inviteUsers(users);
  return res.data.data;
};

export function useInviteMobileUsers() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mutation = useMutation(invite, {
    onSuccess: () => {
      queryClient.invalidateQueries('mobile-users');
      dispatch(setSuccess(t('vanco.mobile.home.users.email.modal.sent')));
    },
    onError: error => {
      dispatch(handleException(error));
    },
  });

  return mutation;
}

export default useInviteMobileUsers;
