export default function styles(theme) {
  return {
    container: {
      padding: '91px 51px',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        margin: 0,
      },
    },
    main: {
      display: 'flex',
      flex: 1,
      height: '100%',
      paddingTop: 10,
      overflow: 'hidden',
    },
    contentWrap: {
      display: 'flex',
      alignItems: 'flex-start',
      flex: 1,
      overflow: 'auto',
      height: '100%',
    },
    contentContainer: {
      display: 'flex',
      textAlign: 'center',
      flex: 1,
      flexDirection: 'column',
      padding: 42,
      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },
    },
    title: {
      display: 'flex',
      fontSize: '24px',
      justifyContent: 'center',
      lineHeight: 'normal',
      marginBottom: '16px',
      alignItems: 'center',
      '& svg': {
        marginRight: '1rem',
      },
    },
    content: {
      width: '40rem',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    button: {
      '&:first-of-type': {
        marginLeft: 0,
      },
      marginTop: 36,
      marginLeft: 8,
    },
    circularProgress: {
      marginTop: 36,
    },
  };
}
