import moment from 'moment';
import * as Yup from 'yup';
import i18n from '../../../../../utils/i18n';
import * as globals from '../../../../../globals/constants';

const createOpenAmountSchema = () => {
  return {
    fundId: Yup.string().required(),
    amount: Yup.string()
      .required()
      .test(
        'isAmountTooLow',
        i18n.t('formValidation.amountTooLow'),
        (value) => {
          return parseFloat(value) >= 3.0;
        },
      ),
    frequency: Yup.string().required(),
    startDate: Yup.object()
      .nullable()
      .test(
        'isRequired',
        i18n.t('formValidation.required'),
        function test(value) {
          return (
            this.parent.frequency === globals.PAYMENT_FREQUENCY.ONE_TIME_NOW ||
            Boolean(value)
          );
        },
      )
      .test(
        'isScheduled',
        i18n.t('formValidation.selectNewDate'),
        function test(value) {
          const today = moment().hour(12).minutes(0).seconds(0).milliseconds(0);
          const tomorrow = moment(today).add(1, 'day');

          let valid = true;
          let message = '';

          // Note: we need to return true if value is falsey so that it fails the required validation
          if (
            value &&
            this.parent.frequency !== globals.PAYMENT_FREQUENCY.ONE_TIME_NOW
          ) {
            if (value.isBefore(today)) {
              valid = false;
              message = i18n.t('formValidation.selectNewDate');
            } else if (!value.isAfter(tomorrow)) {
              valid = false;
              message = i18n.t('formValidation.selectFutureDate');
            }
          }

          return valid || this.createError({ message });
        },
      )
      .test(
        'isDateOverOneYear',
        i18n.t('formValidation.selectLessThanOneYear'),
        (value) => {
          return value
            ? value.isBefore(
                moment()
                  .add(1, 'year')
                  .hour(12)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0),
              )
            : true;
        },
      ),
    endDate: Yup.date()
      .nullable()
      .test('enddate', function validate(value) {
        const { startDate } = this.parent;
        // start date is a required field - so dont trigger errors for this field when start date is missing
        // since this field is optional allow for value to be null
        if (
          startDate === null ||
          startDate === undefined ||
          value === null ||
          value === undefined
        ) {
          return true;
        }
        // end date must be after - cannot be on the same day or before
        return startDate.isValid() && moment(value).isAfter(startDate)
          ? true
          : this.createError({
              message: i18n.t('formValidation.endDateMustBeGreater', {
                startDate: startDate.format('L'),
              }),
            });
      }),
  };
};

export const createSchema = () => {
  Yup.setLocale({
    mixed: {
      required: `${i18n.t('formValidation.required')}`,
    },
  });

  return Yup.object().shape(createOpenAmountSchema());
};

export default createSchema;
