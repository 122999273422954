import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import Form from '../../Form';

import styles from './styles';

export function OffsetForm({
  classes,
  values,
  handleSubmit,
  setFieldValue,
  goToPrev,
  loading,
  isFinished,
  handleReset,
  dirty,
}) {
  const { t } = useTranslation();
  const { allowSocialSharing } = values;

  const handleRadioChange = async e => {
    const { name, value } = e.target;
    await setFieldValue({ target: { name, value: value === 'true' } });
  };

  return (
    <Form>
      <Grid container className={classes.container}>
        <Typography className={classes.text} variant="body2">
          {t('wizard.socialSharing.description')}
        </Typography>
        <RadioGroup component={RadioGroup} onChange={handleRadioChange}>
          <FormControlLabel
            className={classes.radioButton}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Radio
                checked={allowSocialSharing}
                name="allowSocialSharing"
                color="primary"
                value
              />
            }
            label={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Typography className={classes.subtitle2}>
                {t('wizard.socialSharing.enable')}
              </Typography>
            }
          />
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Radio
                checked={!allowSocialSharing}
                name="allowSocialSharing"
                color="primary"
                value={false}
              />
            }
            label={
              /* eslint-disable-next-line react/jsx-wrap-multilines */
              <Typography>{t('wizard.socialSharing.disable')}</Typography>
            }
          />
        </RadioGroup>
      </Grid>
      {!loading && goToPrev && (
        <WizardButtons
          isFinished={isFinished}
          dirty={dirty}
          goToNext={handleSubmit}
          goToPrev={goToPrev}
          undo={handleReset}
        />
      )}
      {loading && goToPrev && (
        <div className={classes.spinnerContainer}>
          <CircularProgress
            color="primary"
            data-testid="social-sharing-save-spinner"
          />
        </div>
      )}
    </Form>
  );
}

OffsetForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  goToPrev: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
};

OffsetForm.defaultProps = {
  goToPrev: null,
};

export default withStyles(styles)(OffsetForm);
