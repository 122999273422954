export default function styles(theme) {
  return {
    formControl: {
      width: '100%',
      marginTop: 16,
      marginBottom: 8,
    },
    select: {
      minWidth: 200,
    },
    label: {
      // fixes label from being overlapped by textfield border
      background: theme.palette.white,
      width: 'auto',
      marginTop: -7,
      marginLeft: 7,
      paddingLeft: 5,
      paddingRight: 5,
      zIndex: 10,
    },
  };
}
