export default function styles(theme) {
  return {
    subtitle: {
      marginTop: 15,
      marginBottom: 5,
    },
    stateContainer: {
      paddingLeft: 20,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
  };
}
