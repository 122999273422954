export default function styles() {
  const commonStyles = {
    alignItems: 'center',
    display: 'flex',
  };

  return {
    titleIcon: {
      marginLeft: '-16px',
      marginRight: 5,
      color: '#666666',
      '&:hover': {
        color: '#222222',
        backgroundColor: '#F0F0F0',
      },
    },
    text: {
      ...commonStyles,
      marginBottom: 10,
    },
    textSmall: {
      ...commonStyles,
      fontSize: 18,
    },
    textMedium: {
      ...commonStyles,
      fontSize: 20,
    },
    textModDefault: {
      ...commonStyles,
      marginBottom: 26,
    },
    readOnly: {
      marginLeft: 10,
      fontWeight: 500,
      fontSize: 14,
    },
    mainDiv: {
      display: 'flex',
    },
  };
}
