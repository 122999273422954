import { connect } from 'react-redux';
import Branding from './Branding';
import { saveOrganizationSettings } from '../../../../store/organizations/actions';

export function mapStateToProps(state) {
  return {
    organizationId: state.organizations.currentOrganization.id,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, { saveOrganizationSettings })(Branding);
