import { useCallback, useMemo, useRef } from 'react';
import { shape, arrayOf, objectOf, string, func, object } from 'prop-types';
import { Button, FormHelperText, withStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import DraggableList from '../../DraggableList';
import TextItem from '../TextItem';
import FormHelperTextBottom from '../../FormHelperTextBottom';
import { createValidation } from './validation';

import styles from './styles';

function getItems(textFields, onEdit, onDelete, onBlur, maxLengths) {
  return textFields.map((textField) => ({
    // Composed key to avoid re-render issues (https://github.com/clauderic/react-sortable-hoc/issues/103)
    key: `${textField.id}-${textField.customText}`,
    node: (
      <TextItem
        textData={textField}
        onEdit={onEdit}
        onDelete={onDelete}
        onBlur={onBlur}
        maxLengths={maxLengths}
      />
    ),
  }));
}

function DropdownFields({ classes, textFields, onChange, maxLengths, errors }) {
  const { t } = useTranslation();
  const editDropdownFieldRef = useRef(null);

  const validation = useMemo(() => createValidation(t), [t]);

  const updateValues = useCallback(
    (updatedValues) => {
      onChange(updatedValues);
    },
    [onChange],
  );

  const onEditItem = useCallback(
    (item) => {
      const updatedValues = textFields.map((x) =>
        x.id === item.id
          ? {
              ...x,
              isEditing: true,
            }
          : x,
      );
      updateValues(updatedValues);
    },
    [updateValues, textFields],
  );

  const onDelete = useCallback(
    (item) => {
      const updatedValues = textFields.filter((x) => x.id !== item.id);
      updateValues(updatedValues);
    },
    [updateValues, textFields],
  );

  const onBlur = useCallback(
    (item, newValue) => {
      if (newValue.trim().length === 0) {
        const updatedValues = textFields.filter((x) => x.id !== item.id);
        updateValues(updatedValues);
      } else {
        const { isValid, message } = validation.validate(newValue, {
          textFields,
          itemId: item.id,
        });
        const updatedValues = textFields.map((x) =>
          x.id === item.id
            ? {
                ...x,
                customText: newValue,
                isEditing: !isValid,
                error: message,
              }
            : x,
        );
        updateValues(updatedValues);
      }
    },
    [updateValues, textFields, validation],
  );

  const textFieldList = useMemo(
    () => getItems(textFields, onEditItem, onDelete, onBlur, maxLengths),
    [textFields, onEditItem, onDelete, onBlur, maxLengths],
  );

  const orderTextFields = (items, movedItems) => {
    if (!movedItems && !items) return [];

    return movedItems.map((movedItem, index) => {
      const item = items.find(
        (i) => `${i.id}-${i.customText}` === movedItem.key,
      );
      const ordinal = index;
      return { ...item, ordinal };
    });
  };

  const onReorder = useCallback(
    (newList) => {
      const ordered = orderTextFields(textFields, newList);
      updateValues(ordered);
    },
    [updateValues, textFields],
  );

  const handleNewTextItem = async () => {
    const id = `new-${Date.now()}`;
    const emptyEntry = {
      id,
      customText: '',
      isEditing: true,
      editDropdownFieldRef,
      isNew: true,
      ordinal: Math.max(...textFields.map((o) => o.ordinal), 0) + 1,
    };
    const newList = [...textFields.filter((field) => !field.error), emptyEntry];
    updateValues(newList);
  };

  return (
    <div className={classes.wrapper}>
      <DraggableList onReorder={onReorder} listItems={textFieldList} axis="y" />
      {errors.memoFieldResponses && !textFields.length && (
        <FormHelperText className={classes.error}>
          {errors.memoFieldResponses}
        </FormHelperText>
      )}
      <Button onClick={handleNewTextItem} size="medium" color="primary">
        {t('tile.memoLine.dropdownfield.add')}
      </Button>
      {errors.memoFieldResponses && textFields.length === 1 && (
        <FormHelperTextBottom
          className={classes.helperText}
          left={errors.memoFieldResponses}
        />
      )}
    </div>
  );
}

DropdownFields.propTypes = {
  classes: objectOf(string).isRequired,
  textFields: arrayOf(shape({})).isRequired,
  onChange: func.isRequired,
  maxLengths: object.isRequired,
  errors: object.isRequired,
};

export default withStyles(styles)(DropdownFields);
