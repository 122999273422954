export default function styles(theme) {
  return {
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 40,
      '& button': {
        '&:first-of-type': {
          marginRight: 5,
        },
        [theme.breakpoints.down('xs')]: {
          '&:first-of-type': {
            marginRight: 0,
          },
        },
      },
    },
    largeButtons: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    previewSection: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      '& img': {
        width: '100%',
        height: 'auto',
      },
    },
  };
}
