import * as Yup from 'yup';

import i18n from '../../../utils/i18n';

export const maxLengths = {};

export const createSchema = () => {
  return Yup.object().shape({
    status: Yup.string().required(
      i18n.t('validation.required', {
        requiredVerb: 'select a ',
        requiredNoun: 'status',
      }),
    ),
    // Visibility validation
    visibility: Yup.bool(),
    locations: Yup.array().when('visibility', {
      is: true,
      then: (s) => s.min(1),
      otherwise: (s) => s.min(0),
    }),
  });
};
