export const generateSignedUrl = (baseUrl, email, clientPK, filter = '') => {
  const uuid = crypto.randomUUID().toString();
  const enc = new TextEncoder('utf-8');
  const time = Math.floor(Date.now().valueOf() / 1000 - 86400).toString();
  const team = 'Client%20Analytics%20-%20Vanco%20Online';
  const sessionLength = '2592000'; // 30 days maximum for sigma (in seconds)

  const environment = () => {
    const env = window.env.REACT_APP_ENVIRONMENT;

    if (env !== 'prod') {
      return '&:ua_environment=STAGE';
    }

    return '';
  };

  const modifyEmail = () => {
    const [localPart, domain] = email.split('@');
    return `${localPart}+${clientPK}@${domain}`;
  };

  const emailWithClientPk = modifyEmail();

  const finalUrl =
    `${baseUrl}?:nonce=${uuid}` +
    `&:client_id=67a9ecfe2803fab1f28764d76c26548eff2764573ac507fec053464dca5304a9` +
    `&:email=${encodeURIComponent(emailWithClientPk)}` +
    `&:external_user_id=1007` +
    `&:external_user_team=${team}` +
    `&:account_type=Embed%20User%20-%20View` +
    `&:mode=userbacked` +
    `&:session_length=${sessionLength}` +
    `&:time=${time}` +
    `&:ua_organizationId=${clientPK}` +
    `${environment()}` +
    `&:ua_clientid=none` +
    `&:menu_position=none${filter}` +
    `&:hide_folder_navigation=true` +
    `&:hide_tooltip=true` +
    `&:hide_menu=True`;

  return crypto.subtle
    .importKey(
      'raw',
      enc.encode(
        '771b098dbb7d536a8c6bd86566b4c1a3b3e6e7c5985d27e1f74fd49d8a6c839ee49e25617c9fa482428ec3a1ec58e56a7e8cdde810fe326a6cc44e9c3dd795a8',
      ),
      { name: 'HMAC', hash: 'SHA-256' },
      false,
      ['sign', 'verify'],
    )
    .then((key) =>
      crypto.subtle
        .sign('HMAC', key, enc.encode(finalUrl))
        .then((signature) => {
          const b = new Uint8Array(signature);
          const signatureStr = Array.from(b, (x) =>
            x.toString(16).padStart(2, '0'),
          ).join('');
          return `${finalUrl}&:signature=${signatureStr}`;
        }),
    );
};

export const setupPostMessageListener = (setLoaded) => {
  const postMessageListener = (event) => {
    if (event.origin.includes('https://app.sigmacomputing.com')) {
      setLoaded(true);
      window.removeEventListener('message', postMessageListener, false);
    }
  };

  window.addEventListener('message', postMessageListener, false);
  return () =>
    window.removeEventListener('message', postMessageListener, false);
};
