export default function styles(theme) {
  return {
    mainContainer: {
      flex: 'auto',
      width: '100%',
    },
    title: {
      fontSize: '2rem',
      lineHeight: 'normal',
      marginBottom: '2rem',
    },
    tableContainer: {
      padding: '30%',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        padding: '20%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        padding: '5%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
    },
    divider: {
      marginTop: theme.spacing(4),
    },
  };
}
