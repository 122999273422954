import { arrayOf, shape, string, objectOf, oneOf } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getFormatedDate, getFormatedHour } from '../../../../utils/date';
import Table, {
  TableHead,
  TableBody,
  TableHeadCell,
  TableRow,
  TableCell,
} from '../../Table';
import EmptyList from '../../Table/EmptyList';
import { MOBILE_EVENTS_TABS } from '../../../../globals/constants';

import styles from './styles';

export function EventsList({ classes, events, eventTab }) {
  const { t } = useTranslation();
  const now = new Date();
  const filteredEvents =
    eventTab === MOBILE_EVENTS_TABS.UPCOMING
      ? events
          .filter(e => e.dateTimeStart >= now)
          .sort((a, b) => (a.dateTimeStart > b.dateTimeStart ? 1 : -1))
      : events
          .filter(e => e.dateTimeStart < now)
          .sort((a, b) => (a.dateTimeStart > b.dateTimeStart ? -1 : 1));

  const emptyListTitle = t(
    `vanco.mobile.events.no.${eventTab.toLowerCase()}.title`,
  );
  const emptyListDescription = t(
    `vanco.mobile.events.no.${eventTab.toLowerCase()}.description`,
  );

  const history = useHistory();
  const clickEvent = eventId => history.push(`/mobile/event/${eventId}`);
  return (
    <>
      {filteredEvents.length < 1 && (
        <EmptyList title={emptyListTitle} description={emptyListDescription} />
      )}
      {filteredEvents.length > 0 && (
        <Table aria-label="Vanco Mobile Events Table">
          <TableHead>
            <TableRow isHeader>
              <TableHeadCell>
                <Typography variant="body2">
                  {t('vanco.mobile.events.event')}
                </Typography>
              </TableHeadCell>
              <TableHeadCell className={classes.date}>
                <Typography variant="body2">
                  {t('vanco.mobile.events.date')}
                </Typography>
              </TableHeadCell>
              <Hidden smDown>
                <TableHeadCell className={classes.assistance}>
                  <Typography variant="body2">
                    {eventTab === MOBILE_EVENTS_TABS.UPCOMING
                      ? t('vanco.mobile.events.going')
                      : t('vanco.mobile.events.went')}
                  </Typography>
                </TableHeadCell>
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEvents.map(event => (
              <TableRow key={event.id} onClick={() => clickEvent(event.id)}>
                <TableCell>
                  <Typography component="span" className={classes.eventName}>
                    {(event.name || '').trim()}
                  </Typography>
                </TableCell>
                <TableCell className={classes.date}>
                  {event.dateTimeStart.getDay() ===
                  event.dateTimeEnd.getDay() ? (
                    <Typography component="span">
                      {`${getFormatedDate(
                        event.dateTimeStart,
                      )} – ${getFormatedHour(event.dateTimeEnd)}`}
                    </Typography>
                  ) : (
                    <>
                      <Typography component="span">
                        {`${getFormatedDate(event.dateTimeStart)} –`}
                      </Typography>
                      <Typography
                        component="span"
                        className={classes.singleRow}
                      >
                        {getFormatedDate(event.dateTimeEnd)}
                      </Typography>
                    </>
                  )}
                </TableCell>
                <Hidden smDown>
                  <TableCell className={classes.assistance}>
                    {event.hasAttendeeLimit ? (
                      <>
                        <Typography component="span">
                          {`${event.attendingCount} / `}
                        </Typography>
                        <Typography
                          component="span"
                          className={classes.attendeeLimit}
                        >
                          {`${event.attendeeLimit}  ${t(
                            'vanco.mobile.events.available',
                          )}`}
                        </Typography>
                      </>
                    ) : (
                      <Typography component="span">
                        {event.attendingCount}
                      </Typography>
                    )}
                  </TableCell>
                </Hidden>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

EventsList.propTypes = {
  classes: objectOf(string).isRequired,
  events: arrayOf(
    shape({
      id: string.isRequired,
    }),
  ),
  eventTab: oneOf([MOBILE_EVENTS_TABS.UPCOMING, MOBILE_EVENTS_TABS.PAST]),
};

EventsList.defaultProps = {
  events: [],
  eventTab: MOBILE_EVENTS_TABS.UPCOMING,
};

export default withStyles(styles)(EventsList);
