import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

export function UnsplashImage({
  classes,
  id,
  urls,
  user,
  links,
  isSelected,
  onSelect,
}) {
  function onClick() {
    onSelect({
      id,
      urls,
      links,
      src: urls.small,
      userName: user.name,
      userUrl: user.links.html,
    });
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={classes.wrapper}
      data-testid="unsplash-button"
      aria-label="button"
    >
      <div
        className={`${classes.image} ${isSelected ? classes.selected : ''}`}
        style={{
          backgroundImage: `url('${urls.small}')`,
        }}
        data-testid="unsplash-image"
      />
    </button>
  );
}

UnsplashImage.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  urls: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

UnsplashImage.defaultProps = {
  isSelected: false,
  onSelect: () => {},
};

export default withStyles(styles)(UnsplashImage);
