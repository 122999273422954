import PropTypes from 'prop-types';
import { createSchema, pageNameLength } from './validation';
import CustomFormik from '../CustomFormik';
import Form from './Form';
import { getExitPath } from '../../navigation/WizardLayout/config';
import useGetStreamerSettings from '../../../utils/hooks/useGetStreamerSettings';

export function StreamSite({
  pages,
  history,
  organizationName,
  createStreamerSettings,
  updateStreamerSettings,
  streamerId,
  loadingCreate,
  loadingSave,
}) {
  const schema = createSchema();
  const { data: streamerSettings, status } = useGetStreamerSettings({
    enabled: !!streamerId,
  });
  const isLoading = status === 'loading';

  const handleSubmit = async (values, submitProps) => {
    if (streamerId) {
      updateStreamerSettings({
        ...values,
        streamerId,
      });
      submitProps.resetForm({ values });
    } else {
      // For now, we will continue to use the createStreamerModel model (may change to createStreamerSettingsModel in the future)
      const createStreamerModel = { name: values.title };
      const res = await createStreamerSettings(createStreamerModel);
      if (res.streamerId) {
        history.push(`/stream/${res.streamerId}/streams`);
      }
    }
  };

  const cancelSetup = () => {
    const exitPath = getExitPath({
      isTile: false,
      isMobilePage: false,
      isStreamPage: true,
      pages,
    });
    history.push(exitPath);
  };

  const getInitialData = () => {
    return { title: streamerSettings.title || `${organizationName} Live` };
  };

  return (
    <CustomFormik
      initialValues={getInitialData()}
      onSubmit={handleSubmit}
      render={(formikProps) => (
        <Form
          loading={isLoading || loadingCreate || loadingSave}
          maxLengths={{ pageName: pageNameLength }}
          page={null}
          cancelSetup={cancelSetup}
          isEditor={!!streamerId}
          {...formikProps}
        />
      )}
      validationSchema={schema}
    />
  );
}

StreamSite.propTypes = {
  history: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  organizationName: PropTypes.string,
  createStreamerSettings: PropTypes.func.isRequired,
  updateStreamerSettings: PropTypes.func.isRequired,
  streamerId: PropTypes.string,
  loadingCreate: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
};
StreamSite.defaultProps = {
  organizationName: '',
  streamerId: null,
};
export default StreamSite;
