import { useEffect } from 'react';
import { string, func, object, bool } from 'prop-types';
import CustomFormik from '../CustomFormik';
import Form from './Form';
import { DEFAULT_COLOR } from '../../../globals/constants';
import { createSchema, pageNameLength } from './validation';

export function StreamPageBranding({
  pageTitleKey,
  goToNext,
  goToPrev,
  page,
  savePage,
  loadingSave,
  newPage,
  resetNewPage,
  updatePage,
  isFinished,
  organizationName,
  updateStreamData,
}) {
  const schema = createSchema();
  const isWizard = !updatePage && !updateStreamData;

  useEffect(() => {
    if (goToNext && newPage.id) {
      goToNext();
      resetNewPage();
    }
  }, [newPage, goToNext, resetNewPage]);

  const handleSubmit = values => {
    const formPage = {
      ...page,
      ...values,
      pageName: organizationName,
      streamData: {
        ...page.streamData,
        title: values.title,
      },
    };

    savePage(formPage);
  };

  const handleUpdate = (key, value) => {
    if (isWizard) return;
    if (key === 'title') {
      updateStreamData(key, value);
    } else {
      updatePage(key, value);
    }
  };

  return (
    <CustomFormik
        onChange={handleUpdate}
        enableReinitialize
        initialValues={{
          primaryColor: page.primaryColor || DEFAULT_COLOR,
          title: page.streamData?.title || organizationName,
          logoImage: page.logoImage || null,
          pageTitleKey,
        }}
        onSubmit={handleSubmit}
        render={formikProps => (
          <Form
            goToPrev={goToPrev}
            loading={loadingSave}
            isFinished={isFinished}
            maxLengths={{ pageName: pageNameLength }}
            page={page}
            isWizard={isWizard}
            {...formikProps}
          />
        )}
        validationSchema={schema}
      />
  );
}

StreamPageBranding.propTypes = {
  pageTitleKey: string,
  goToNext: func,
  goToPrev: func,
  page: object.isRequired,
  savePage: func.isRequired,
  loadingSave: bool.isRequired,
  newPage: object.isRequired,
  resetNewPage: func.isRequired,
  updatePage: func,
  setError: func,
  isFinished: bool,
  organizationName: string,
  updateStreamData: func,
};

StreamPageBranding.defaultProps = {
  pageTitleKey: null,
  goToNext: null,
  goToPrev: null,
  updatePage: null,
  setError: null,
  isFinished: false,
  organizationName: '',
  updateStreamData: null,
};

export default StreamPageBranding;
