export default function styles(theme) {
  return {
    modalTitle: {
      padding: '16px 24px',
      '& h6': {
        paddingLeft: 0,
        textAlign: 'left',
      },
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      marginBottom: 16,
    },
    actionButtonGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      padding: 16,
      gap: 16,
    },
    disableRadio: {
      display: 'flex',
      alignItems: 'baseline',
    },
    disableRadioLabel: {
      alignSelf: 'flex-start',
    },
    dateDescription: {
      color: theme.palette.text.subdued,
    },
    radioIcon: {
      paddingTop: 0,
    },
    datePicker: {
      marginTop: 16,
      maxWidth: 250,
    },
    radioButton: {},
    bottomDivider: {
      marginTop: 16,
    },
  };
}
