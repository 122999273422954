import { connect } from 'react-redux';

import TileCreate from './TileCreate';
import { createTile, resetNewTile } from '../../../store/tiles/actions';

export function mapStateToProps(state) {
  return {
    loadingCreate: state.tiles.loadingCreate,
    newTile: state.tiles.newTile,
  };
}

export default connect(
  mapStateToProps,
  { createTile, resetNewTile },
)(TileCreate);
