import { string, objectOf } from 'prop-types';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PageTitle from '../../../common/PageTitle';
import PagePaper from '../../../common/PagePaper';
import PrimaryButton from '../../../common/PrimaryButton';
import ButtonMenu from '../../../common/ButtonMenu';
import { Page } from '../../../common';
import ReportsIframe from '../ReportsIframe';
import styles from './styles';
import ReportsModal from '../../../common/ReportsModal';
import { REPORT_HELP_VIDEOS } from '../../../../globals/constants';

export function SigmaReport({ classes }) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalUrl, setModalUrl] = useState('');

  const goBack = () => {
    history.replace('/reporting');
  };

  const mainUrl = window.env.REACT_APP_PEOPLE_STORAGE;

  const getUrlVideo = (type) => {
    const isHelpVideo = Object.values(REPORT_HELP_VIDEOS).some((item) =>
      type.includes(item),
    );

    const url = isHelpVideo
      ? `${mainUrl}/general-functionality/${type}`
      : `${mainUrl}/merchant-reporting/${location.state.tutorial}`;
    setModalUrl(url);
    setModalOpen(true);
  };

  return (
    <Grid className={classes.mainContainer}>
      <Grid item lg={12} className={classes.mainContainer}>
        <Page className={classes.mainContainer}>
          <PageTitle>
            <Button
              onClick={goBack}
              className={classes.exitButton}
              data-testid="exit-button"
            >
              <ArrowBackIcon color="primary" className={classes.arrowIcon} />
              <Typography variant="h3" className={classes.title}>
                {location.state.title}
              </Typography>
            </Button>
          </PageTitle>
          <div className={classes.titleContainer}>
            <PrimaryButton
              onClick={() => getUrlVideo('TUTORIAL')}
              className={classes.button}
              size="medium"
            >
              {t('reports.tutorial.button')}
            </PrimaryButton>
            <ButtonMenu buttonContent="Help videos" color="inherit">
              <ButtonMenu.Item
                onClick={() => getUrlVideo(REPORT_HELP_VIDEOS.CONTROLS)}
              >
                <Typography>{t('reports.videos.controls')}</Typography>
              </ButtonMenu.Item>
              <ButtonMenu.Divider />
              <ButtonMenu.Item
                onClick={() => getUrlVideo(REPORT_HELP_VIDEOS.DOWNLOAD)}
              >
                <Typography>{t('reports.videos.download')}</Typography>
              </ButtonMenu.Item>
              <ButtonMenu.Divider />
              <ButtonMenu.Item
                onClick={() => getUrlVideo(REPORT_HELP_VIDEOS.REFRESH_DATA)}
              >
                <Typography>{t('reports.videos.refresh')}</Typography>
              </ButtonMenu.Item>
              <ButtonMenu.Divider />
              <ButtonMenu.Item
                onClick={() => getUrlVideo(REPORT_HELP_VIDEOS.SCHEDULE_EXPORTS)}
              >
                <Typography>{t('reports.videos.exports')}</Typography>
              </ButtonMenu.Item>
              <ButtonMenu.Divider />
              <ButtonMenu.Item
                onClick={() => getUrlVideo(REPORT_HELP_VIDEOS.TOOLTIP)}
              >
                <Typography>{t('reports.videos.tooltip')}</Typography>
              </ButtonMenu.Item>
              <ButtonMenu.Divider />
              <ButtonMenu.Item
                onClick={() => getUrlVideo(REPORT_HELP_VIDEOS.UNDERLYING_DATA)}
              >
                <Typography>{t('reports.videos.data')}</Typography>
              </ButtonMenu.Item>
            </ButtonMenu>
          </div>
          <PagePaper className={classes.pagePaper}>
            <ReportsIframe
              url={location.state.url}
              id="mviframe"
              name="mviframe"
              filter={location.state.filter}
            />
          </PagePaper>
          {modalOpen && (
            <ReportsModal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              url={modalUrl}
            />
          )}
        </Page>
      </Grid>
    </Grid>
  );
}

SigmaReport.propTypes = {
  classes: objectOf(string).isRequired,
};

export default withStyles(styles)(SigmaReport);
