import { forwardRef, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import CxpPaymentMethodField from './CxpPaymentMethodField';

const CxpPaymentMethodFormField = forwardRef((props, ref) => {
  const {
    field,
    id,
    label,
    paymentMethod,
    required,
    onChange,
    onBlur,
    onFocus,
    helperText,
    ...other
  } = props;

  const [errorText, setErrorText] = useState(helperText);
  const [labelWidth, setLabelWidth] = useState(0);
  const labelRef = useRef(null);

  useEffect(() => {
    setLabelWidth(labelRef.current.offsetWidth);
  }, []);

  useEffect(() => {
    setErrorText(helperText);
  }, [helperText]);

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleFocus = (event) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  const helperTextId = errorText && id ? `${id}-helper-text` : undefined;

  return (
    <FormControl
      error={!!errorText}
      ref={ref}
      required={required}
      variant="outlined"
      {...other}
    >
      <InputLabel htmlFor={id} ref={labelRef}>
        {label}
      </InputLabel>

      <CxpPaymentMethodField
        field={field}
        id={id}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        paymentMethod={paymentMethod}
        labelWidth={labelWidth}
      />

      {errorText && (
        <FormHelperText id={helperTextId}>{errorText}</FormHelperText>
      )}
    </FormControl>
  );
});

CxpPaymentMethodFormField.propTypes = {
  field: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  paymentMethod: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

CxpPaymentMethodFormField.defaultProps = {
  required: false,
  onChange: null,
  onBlur: null,
  onFocus: null,
  helperText: '',
};

export default CxpPaymentMethodFormField;
