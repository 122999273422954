import { connect } from 'react-redux';
import withRequiredGrants from 'components/hoc/withRequiredGrants';
import { SECTION_PERMISSIONS } from 'globals/grants';
import { NotFound } from 'components/common';
import { setSuccess } from '../../../store/alerts/actions';
import TilesList from './TilesList';

const mapStateToProps = (state) => ({
  loadingDialogIsOpen: state.loadingDialog.open,
  pages: state.pages.pages,
  features: state.features?.features,
  primaryLocatioURI:
    state.pages.pages?.find((c) => c.primary === true)?.locationURI || '',
});

// EXAMPLE of required grants HOC
export default withRequiredGrants(
  connect(mapStateToProps, { setSuccess })(TilesList),
  [SECTION_PERMISSIONS.GIVING.TILES.READ],
  NotFound,
);
