import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { useQueryClient } from 'react-query';
import CustomFormik from '../CustomFormik';

import {
  TILE_ACTIVE,
  TILE_PENDING,
  TILE_ARCHIVED,
} from '../../../globals/constants';
import { defaultTile } from '../../../globals/defaults';
import PageTitle from '../PageTitle';
import styles from './styles';
import Form from './Form';
import { createSchema } from './validation';

export function TileOverview({
  tile,
  goToPrev,
  goToNext,
  newTile,
  saveTile,
  loadingSave,
  updateTile,
  setError,
  isFinished,
  history,
  resetNewTile,
  unarchiveTile,
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const schema = createSchema();

  useEffect(() => {
    if (newTile.id && goToPrev) {
      const { status } = newTile;
      // Redirect the user to the tile management tab that has the new tile
      if (status === TILE_ARCHIVED) {
        history.push('/tiles/archived');
      } else if (status === TILE_PENDING) {
        history.push('/tiles/pending');
      } else {
        history.push('/tiles/all');
      }
      resetNewTile();
    }
  }, [newTile, history, resetNewTile, goToPrev]);

  const onSubmit = async (values) => {
    const formTile = {
      ...tile,
      ...values,
    };
    await saveTile(formTile);
    queryClient.setQueryData(['tile', formTile.id], formTile);
  };

  return (
    <>
      <PageTitle>{t('tile.overviewTitle')}</PageTitle>
      <CustomFormik
        onChange={updateTile}
        onErrorsChange={setError}
        retrieveErrors
        initialValues={{
          status: tile.status === TILE_PENDING ? TILE_ACTIVE : tile.status,
          locations: tile.locations,
          visibility: tile.locations && tile.locations.length > 0,
        }}
        onSubmit={onSubmit}
        render={(formikProps) => {
          return (
            <Form
              goToPrev={goToPrev}
              goToNext={goToNext}
              loading={loadingSave}
              isFinished={isFinished}
              tile={tile}
              unarchiveTile={unarchiveTile}
              {...formikProps}
            />
          );
        }}
        validationSchema={schema}
      />
    </>
  );
}

TileOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  tile: PropTypes.object,
  goToPrev: PropTypes.func,
  goToNext: PropTypes.func,
  newTile: PropTypes.object.isRequired,
  saveTile: PropTypes.func.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool,
  updateTile: PropTypes.func,
  setError: PropTypes.func,
  history: PropTypes.object.isRequired,
  resetNewTile: PropTypes.func.isRequired,
  unarchiveTile: PropTypes.func,
  features: PropTypes.objectOf(PropTypes.bool),
};

TileOverview.defaultProps = {
  tile: defaultTile,
  goToPrev: null,
  goToNext: null,
  updateTile: null,
  setError: null,
  isFinished: false,
  unarchiveTile: null,
  features: {},
};

export default withStyles(styles)(TileOverview);
