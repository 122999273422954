import { node } from 'prop-types';
import { useCardContext } from '../context';

function CardMedia({ children }) {
  const { classes } = useCardContext();

  return <div className={classes.cardMedia}>{children}</div>;
}

CardMedia.propTypes = {
  children: node,
};

CardMedia.defaultProps = {
  children: null,
};

export default CardMedia;
