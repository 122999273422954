/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import PrimaryButton from '../PrimaryButton';

import styles from './styles';

export function ReportsModal({ isOpen, onClose, classes, url }) {
  const { dialogPaper } = classes;

  const { t } = useTranslation();
  return (
    <Modal
      classes={{ dialogPaper, closeIcon: undefined }}
      open={isOpen}
      onClose={onClose}
    >
      <Modal.Content className={classes.modalContent}>
        <video controls className={classes.videoPlayer}>
          <source src={url} type="video/mp4" data-testid="video-control" />
          Your browser does not support the video tag.
        </video>
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <PrimaryButton
          className={classes.cancelButton}
          color="primary"
          onClick={onClose}
        >
          {t('button.close')}
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
}

ReportsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

ReportsModal.defaultProps = {};

export default withStyles(styles)(ReportsModal);
