import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import {
  DeleteOutlineOutlined,
  EditOutlined,
  EmailOutlined,
} from '@material-ui/icons';
import styles from './styles';
import { PopoverMenu } from '../../../common';
import { useFeatures } from '../../../../utils/hooks';
import { ROLES } from '../../../../globals/constants';

export function User({
  classes,
  user,
  isYou,
  canModifyEmailSettings,
  setRemoveUserModalOpen,
  setEditUserModalOpen,
  setEmailSettingsModalOpen,
  setEditUserPermissionsModalOpen,
}) {
  const { firstName, lastName, userName, role, canRemove } = user;
  const { t } = useTranslation();
  const { AdminUserPermissions } = useFeatures();
  const isAccountOwner = role === ROLES.ACCOUNT_OWNER;

  const openRemoveUserModal = () => {
    setRemoveUserModalOpen(true, user);
  };

  const openEditUserModal = () => {
    setEditUserModalOpen(true, user);
  };

  const openEditUserPermissionsModal = () => {
    setEditUserPermissionsModalOpen(true, user);
  };

  const openEmailSettingsModal = () => {
    setEmailSettingsModalOpen(true, user);
  };

  return (
    <TableRow>
      <TableCell>
        <div>
          {firstName && lastName ? (
            <Typography variant="body2" component="span">
              {`${firstName} ${lastName}`}
            </Typography>
          ) : (
            <Typography variant="body2" component="span">
              {userName}
            </Typography>
          )}
          {isYou && (
            <Typography component="span" className={classes.youText}>
              {` ${t('users.you')}`}
            </Typography>
          )}
        </div>
        {firstName && lastName && <div>{userName}</div>}
      </TableCell>
      <TableCell>
        <div className={classes.roleCell}>
          <Typography>{role}</Typography>
          {!isYou &&
            (canRemove || canModifyEmailSettings) &&
            !isAccountOwner && (
              <div className={classes.buttons}>
                <PopoverMenu>
                  {!isYou && canRemove && (
                    <PopoverMenu.Item onSelect={openEditUserModal}>
                      <EditOutlined />
                      {t('users.edit.role')}
                    </PopoverMenu.Item>
                  )}
                  {!isYou && AdminUserPermissions && (
                    <PopoverMenu.Item onSelect={openEditUserPermissionsModal}>
                      <EditOutlined />
                      {t('users.edit.permissions')}
                    </PopoverMenu.Item>
                  )}
                  {canModifyEmailSettings && (
                    <PopoverMenu.Item onSelect={openEmailSettingsModal}>
                      <EmailOutlined />
                      {t('users.edit.emailNotifications')}
                    </PopoverMenu.Item>
                  )}
                  {!isYou && canRemove && (
                    <PopoverMenu.Item onSelect={openRemoveUserModal}>
                      <DeleteOutlineOutlined />
                      {t('users.remove')}
                    </PopoverMenu.Item>
                  )}
                </PopoverMenu>
              </div>
            )}
        </div>
      </TableCell>
    </TableRow>
  );
}

User.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isYou: PropTypes.bool.isRequired,
  canModifyEmailSettings: PropTypes.bool.isRequired,
  setRemoveUserModalOpen: PropTypes.func.isRequired,
  setEditUserModalOpen: PropTypes.func.isRequired,
  setEmailSettingsModalOpen: PropTypes.func.isRequired,
  setEditUserPermissionsModalOpen: PropTypes.func.isRequired,
};

export default withStyles(styles)(User);
