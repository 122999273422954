import {
  GET_FEATURES_START,
  GET_FEATURES_FINISH,
  GET_FEATURES_ERROR,
} from './types';

const INITIAL_STATE = {
  features: {},
  error: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    // FETCH

    case GET_FEATURES_START:
      return {
        ...state,
      };

    case GET_FEATURES_FINISH: {
      const oldKeys = Object.keys(state.features ?? {});
      const newKeys = Object.keys(action.data ?? {});
      // Don't bother checking the values for each key
      // because, for the features object, every value is always true
      const areEqual =
        oldKeys.length === newKeys.length &&
        oldKeys.every((oldKey, index) => oldKey === newKeys[index]);

      const features = areEqual ? state.features : action.data;
      return {
        ...state,
        // Since the data is loaded from a cookie, we keep the old object
        // if it hasn't changed; this prevents a scenario where a component gets unmounted
        features,
      };
    }

    case GET_FEATURES_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};
