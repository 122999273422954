export default function formatPhoneNumber(phone) {
  if (phone === null) {
    return null;
  }
  // Some numbers start with +1 but we don't want to show the '+'
  const justTheDigits = (phone.match(/\d/g) ?? []).join('');
  // Important that this ends with a $ so that we only take the last 10 digits
  // and ignore any country code if it starts with one.
  const phoneRegex = /(?<areaCode>\d{3})(?<next3>\d{3})(?<last4>\d{4})$/g;
  const match = phoneRegex.exec(justTheDigits);
  if (!match) {
    // Fall back to just returning the raw string if we can't parse it for some reason
    return phone;
  }
  const { groups } = match;
  return `(${groups.areaCode}) ${groups.next3}-${groups.last4}`;
}
