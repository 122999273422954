import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { PageTitle, Page } from '../../common';
import styles from './styles';
import useImportFile from '../../import/hooks/useImportFile';
import useImportFiles from '../../import/hooks/useImportFiles';
import { IMPORT_STATUS } from '../../import/utils/const';
import ImportFileDetailTable from '../../import/ImportFileDetailTable';
import ActionsMenu from '../../import/ActionsMenu';

function ImportedFileDetail({ classes, accessToken, idsPayerTenant }) {
  const history = useHistory();
  const { importId } = useParams();
  const [isImportButtonDisabled, setisImportButtonDisabled] = useState(true);

  const [importFiles, importedFilesError, importedFilesLoading] =
    useImportFiles(idsPayerTenant, accessToken);

  const [importedFile, importedFileError, importedFileLoading] = useImportFile(
    accessToken,
    importId,
  );

  useEffect(() => {
    if (!importedFilesLoading && (!importFiles || importFiles.length < 1)) {
      history.push('/new-import');
    }
    if (!importedFilesError && importFiles?.length > 0) {
      setisImportButtonDisabled(
        importFiles.some((i) => i.status === IMPORT_STATUS.IN_PROGRESS),
      );
    }
  }, [importFiles, importedFilesLoading, history, importedFilesError]);

  const onGoBack = () => {
    history.push(`/import`);
  };

  return (
    <Page>
      <div className={classes.titleContainer}>
        <PageTitle onGoBack={onGoBack}>
          {importFiles
            ? importFiles.find((i) => i.id === importId).fileName
            : ''}
        </PageTitle>
        {!importedFilesLoading && (
          <ActionsMenu isImportButtonDisabled={isImportButtonDisabled} />
        )}
      </div>

      {!importedFileLoading && !importedFileError && (
        <div className={classes.container}>
          {importedFile && <ImportFileDetailTable data={importedFile} />}
        </div>
      )}
    </Page>
  );
}

ImportedFileDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  accessToken: PropTypes.string.isRequired,
  idsPayerTenant: PropTypes.string.isRequired,
};

export default withStyles(styles)(ImportedFileDetail);
