/* eslint-disable react/function-component-definition */
import { PAYWALL_MAP, REPORTING_ROUTES } from 'globals/constants';
import PropTypes from 'prop-types';
import { useLocation, Redirect } from 'react-router-dom';
import usePaywall from 'utils/hooks/usePaywall';

const withPaywall = (Component) => {
  const WithPaywallState = ({ paywallKey, ...props }) => {
    const { insights } = usePaywall();
    const location = useLocation();

    const validationsMap = {
      [PAYWALL_MAP.INSIGHTS]: insights,
      default: false,
    };

    const redirectionMap = {
      [PAYWALL_MAP.INSIGHTS]: REPORTING_ROUTES.insightsPaywall,
      default: '*',
    };

    const shouldRedirect =
      validationsMap?.[paywallKey] ?? validationsMap.default;
    if (shouldRedirect) {
      const redirectPath =
        redirectionMap?.[paywallKey] ?? redirectionMap.default;
      return (
        <Redirect
          data-testid="paywallRedirect"
          to={{
            pathname: redirectPath,
            state: { from: location },
          }}
        />
      );
    }

    return <Component {...props} />;
  };

  WithPaywallState.defaultProps = {
    paywallKey: PAYWALL_MAP.NONE,
  };

  WithPaywallState.propTypes = {
    paywallKey: PropTypes.string,
  };

  return WithPaywallState;
};

export default withPaywall;
