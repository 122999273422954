import { useState, useMemo /* useEffect */ } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { arrayOf, shape, string, objectOf, oneOf } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Table, {
  TableHead,
  TableBody,
  TableHeadCell,
  TableRow,
  TableCell,
} from '../../../common/Table';
import EmptyList from '../../../common/Table/EmptyList';
import { REPORTS_EVENTS_TABS } from '../../../../globals/constants';
import styles from './styles';

export function ReportsList({ classes, events, eventTab }) {
  const [sortColumns, setSortColumns] = useState();

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = useMemo(() => {
      const sortableItems = [...items];
      if (sortConfig !== null) {
        if (sortColumns !== undefined && sortColumns.length > 0) {
          sortableItems.sort((a, b) => {
            let tempColumns = 0;

            sortColumns.forEach((column) => {
              switch (column.column) {
                case 'name':
                  if (column.order === 'ASC') {
                    tempColumns = a.name
                      .toLowerCase()
                      .localeCompare(b.name.toLowerCase());
                  } else {
                    tempColumns = b.name
                      .toLowerCase()
                      .localeCompare(a.name.toLowerCase());
                  }
                  break;

                case 'description':
                  if (column.order === 'ASC') {
                    tempColumns = a.description
                      .toLowerCase()
                      .localeCompare(b.description.toLowerCase());
                  } else {
                    tempColumns = b.description
                      .toLowerCase()
                      .localeCompare(a.description.toLowerCase());
                  }
                  break;

                default:
                  break;
              }
            });

            return tempColumns;
          });
        }
      }

      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (columnName) => {
      if (sortColumns === undefined) {
        setSortColumns([{ column: columnName, order: 'ASC' }]);
      } else if (
        sortColumns.some(
          (item) => columnName === item.column && item.order === 'ASC',
        )
      ) {
        setSortColumns([{ column: columnName, order: 'DESC' }]);
      } else if (
        sortColumns.some(
          (item) => columnName === item.column && item.order === 'DESC',
        )
      ) {
        setSortColumns(
          sortColumns.filter((item) => columnName !== item.column),
        );
      } else {
        setSortColumns([{ column: columnName, order: 'ASC' }]);
      }

      setSortConfig({ columnName });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { t } = useTranslation();

  // Helper function to sort events by dateTimeStart
  const sortEventsByName = (eventsArr) =>
    eventsArr.sort((a, b) => a.name - b.name);

  // Function to filter and sort events based on the tab
  const filterAndSortEvents = (eventsArr) => {
    if (!Array.isArray(eventsArr)) {
      return []; // Return an empty array if events is not an array
    }

    return sortEventsByName(eventsArr);
  };

  // Use the function to get filteredEvents
  const filteredEvents = filterAndSortEvents(events);

  const emptyListTitle = t(
    `vanco.mobile.events.no.${eventTab.toLowerCase()}.title`,
  );
  const emptyListDescription = t(
    `vanco.mobile.events.no.${eventTab.toLowerCase()}.description`,
  );

  const history = useHistory();

  const clickEvent = (_url, _title, _filter, _tutorial) => {
    history.push({
      pathname: `/reporting/detail`,
      state: { url: _url, title: _title, filter: _filter, tutorial: _tutorial },
    });
  };
  const { items, requestSort } = useSortableData(filteredEvents);

  return (
    <>
      {filteredEvents.length < 1 && (
        <EmptyList title={emptyListTitle} description={emptyListDescription} />
      )}
      {filteredEvents.length > 0 && (
        <Table aria-label="" className={classes.table}>
          <TableHead>
            <TableRow isHeader>
              <TableHeadCell className={classes.eventName}>
                <Typography
                  className={classes.headerTextWithButtons}
                  onClick={() => requestSort('name')}
                >
                  {t('reports.header.name')}
                </Typography>
                {sortColumns?.some(
                  (item) => item.column === 'name' && item.order === 'ASC',
                ) && <ArrowUpwardIcon />}
                {sortColumns?.some(
                  (item) => item.column === 'name' && item.order === 'DESC',
                ) && <ArrowDownwardIcon />}
              </TableHeadCell>

              <TableHeadCell className={classes.eventStatus}>
                <Typography
                  className={classes.headerTextWithButtons}
                  onClick={() => requestSort('description')}
                >
                  {t('reports.header.description')}
                </Typography>
                {sortColumns?.some(
                  (item) =>
                    item.column === 'description' && item.order === 'ASC',
                ) && <ArrowUpwardIcon />}
                {sortColumns?.some(
                  (item) =>
                    item.column === 'description' && item.order === 'DESC',
                ) && <ArrowDownwardIcon />}
              </TableHeadCell>

              <TableHeadCell className={classes.eventActions}>
                <Typography variant="body2">
                  {t('reports.header.action')}
                </Typography>
              </TableHeadCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {items.map((event) => (
              <TableRow
                key={event.url}
                onClick={() =>
                  clickEvent(
                    event.url,
                    event.name,
                    event.filter,
                    event.tutorial,
                  )
                }
              >
                <TableCell>
                  <Typography component="span">
                    {(event.name || '').trim()}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography component="span">
                    {(event.description || '').trim()}
                  </Typography>
                </TableCell>

                <TableCell className={classes.eventActions}>
                  <ArrowOutwardIcon />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

ReportsList.propTypes = {
  classes: objectOf(string).isRequired,
  events: arrayOf(
    shape({
      url: string.isRequired,
    }),
  ),
  eventTab: oneOf([REPORTS_EVENTS_TABS.FINANCIAL]),
};

ReportsList.defaultProps = {
  events: [],
  eventTab: REPORTS_EVENTS_TABS.FINANCIAL,
};

export default withStyles(styles)(ReportsList);
