const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(4),
  },
});

export default styles;
