import { arrayOf, shape, string, objectOf } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import Table, {
  TableHead,
  TableBody,
  TableHeadCell,
  TableRow,
  TableCell,
} from '../../Table';
import EmptyList from '../../Table/EmptyList';

import styles from './styles';

export function UsersList({ classes, groups }) {
  const { t } = useTranslation();
  const history = useHistory();
  const onClickGroup = id => () => history.push(`/mobile/groups/${id}`);

  const emptyListTitle = t('vanco.mobile.appGroups.noGroupsTitle');
  const emptyListDescription = t('vanco.mobile.appGroups.noGroupsDescription');
  return (
    <>
      {groups.length < 1 && (
        <EmptyList title={emptyListTitle} description={emptyListDescription} />
      )}
      {groups.length > 0 && (
        <Table aria-label="Vanco Mobile Groups Table">
          <TableHead>
            <TableRow isHeader>
              <TableHeadCell>
                <Typography variant="body2">
                  {t('vanco.mobile.appGroups.tableGroup')}
                </Typography>
              </TableHeadCell>
              <TableHeadCell className={classes.access}>
                <Typography variant="body2">
                  {t('vanco.mobile.appGroups.tableAccess')}
                </Typography>
              </TableHeadCell>
              <TableHeadCell className={classes.members}>
                <Typography variant="body2">
                  {t('vanco.mobile.appGroups.tableMembers')}
                </Typography>
              </TableHeadCell>
              <TableHeadCell className={classes.lastActivity}>
                <Typography variant="body2">
                  {t('vanco.mobile.appGroups.tableLastActivity')}
                </Typography>
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map(group => (
              <TableRow key={group.id} onClick={onClickGroup(group.id)}>
                <TableCell>
                  <Typography component="span" className={classes.groupName}>
                    {group.name}
                  </Typography>
                </TableCell>
                <TableCell className={classes.access}>
                  <Typography component="span">{group.access}</Typography>
                </TableCell>
                <TableCell className={classes.members}>
                  <Typography component="span">{group.membersCount}</Typography>
                </TableCell>
                <TableCell className={classes.lastActivity}>
                  <Typography component="span">
                    {group.lastActivity
                      ? format(new Date(group.lastActivity), 'MMM d, yyyy')
                      : ''}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

UsersList.propTypes = {
  classes: objectOf(string).isRequired,
  groups: arrayOf(
    shape({
      id: string.isRequired,
    }),
  ),
};

UsersList.defaultProps = {
  groups: [],
};

export default withStyles(styles)(UsersList);
