import { connect } from 'react-redux';

import VancoMobileReviewWizard from './VancoMobileReviewWizard';
import {
  savePage,
  setNewMobilePageModalOpen,
  setPageCommunityFeaturesChoosen,
} from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    loadingPage: state.pages.loadingPage,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, {
  savePage,
  setNewMobilePageModalOpen,
  setPageCommunityFeaturesChoosen,
})(VancoMobileReviewWizard);
