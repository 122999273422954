export default theme => ({
  title: {
    marginBottom: 39,
  },
  paper: {
    padding: 24,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 40,
    '& button': {
      '&:first-of-type': {
        marginRight: 5,
      },
      [theme.breakpoints.down('xs')]: {
        '&:first-of-type': {
          marginRight: 0,
        },
      },
    },
  },
});
