export default function styles(theme) {
  return {
    buttons: {
      display: 'inline-block',
      marginTop: 40,
    },
    buttonMenu: {
      marginLeft: 15,
      backgroundColor: theme.palette.secondary.light,
    },
    section: {
      marginTop: 8,
      float: 'right',
    },
    pagePaper: {
      flex: 'auto',
      padding: 0,
      marginTop: 15,
      height: '99%',
    },
    button: {
      height: 40,
    },
    mainContainer: {
      flex: 'auto',
      width: '100%',
      height: '100%',
    },
    exitButton: {
      display: 'inline-block',
      '& span': {
        '&:first-of-type': {
          display: 'flex',
          alignItems: 'center',
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
    arrowIcon: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginRight: 0,
    },
    title: {
      fontColor: 'black',
      fontWeight: 'bold',
      marginLeft: 0,
    },
    titleContainer: {
      display: 'flex',
      gap: '15px',
    },
  };
}
