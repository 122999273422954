import { useFeatures, useUser } from 'utils/hooks';
import { userIsAllowed } from 'utils/permissions';

const withRequiredGrants = (Component, requiredGrants, Fallback = null) => {
  // eslint-disable-next-line func-names
  return function (props) {
    // eslint-disable-next-line react/prop-types
    const { optionalGrants } = props;
    const user = useUser();
    const { AdminUserPermissions } = useFeatures();
    const { grantedActions } = user;

    // Bypass grant check if feature flag is off
    if (
      !AdminUserPermissions ||
      userIsAllowed(grantedActions, requiredGrants, optionalGrants)
    ) {
      return <Component {...props} />;
    }
    return Fallback ? <Fallback {...props} /> : null;
  };
};

export default withRequiredGrants;
