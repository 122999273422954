import { Form } from 'formik';
import { object, func, string, objectOf, bool } from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import PrimaryButton from '../../PrimaryButton';
import AddressInputs from '../../AddressInputs';
import PageNameInput from '../../PageNameInput';
import VancoMobileContactInputs from '../../VancoMobileContactInputs';

import styles from './styles';

export function DetailsForm({
  classes,
  values,
  errors,
  maxLengths,
  setFieldValue,
  touched,
  handleBlur,
  loading,
  handleCancel,
  dirty,
}) {
  const { t } = useTranslation();

  const handleChange = e => {
    const { name, value } = e.target;
    setFieldValue({ target: { name, value } });
  };

  return (
    <Form className={classes.form}>
      <Typography variant="h5" className={classes.subtitle}>
        {t('mobileSetup.organizationName')}
      </Typography>
      <Typography className={classes.text}>
        {t('mobileSetup.organizationName.description')}
      </Typography>
      <div className={[classes.container, classes.pageName].join(' ')}>
        <PageNameInput
          label="Organization name"
          value={values.pageName}
          error={errors.pageName}
          touched={touched.pageName}
          maxLength={maxLengths.pageName}
          handleBlur={handleBlur}
          handleChange={handleChange}
          autoFocus={(values.pageName || '').length < 1}
        />
      </div>
      <Typography className={classes.subtitle} variant="body2">
        {t('vanco.mobile.edit.address.title')}
      </Typography>
      <Typography className={classes.text}>
        {t('vanco.mobile.edit.address.desription')}
      </Typography>
      <div className={classes.container}>
        <AddressInputs
          values={values}
          maxLengths={maxLengths}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setCityOwnRow
        />
      </div>
      <div className={classes.container}>
        <Typography
          className={`${classes.subtitle} ${classes.contactInformationHeader}`}
          variant="body2"
        >
          {t('mobileSetup.contactInformation')}
        </Typography>
        <Typography className={classes.text}>
          {t('mobileSetup.provideAtLeastOne')}
        </Typography>
        <VancoMobileContactInputs
          values={values}
          maxLengths={maxLengths}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
        />
      </div>
      <Grid item className={classes.buttonsContainer}>
        {!loading ? (
          <>
            <Button color="primary" onClick={handleCancel} size="large">
              {t('button.cancel')}
            </Button>
            <PrimaryButton
              type="submit"
              size="large"
              disabled={!dirty || Object.keys(errors || {}).length > 0}
            >
              {t('button.save')}
            </PrimaryButton>
          </>
        ) : (
          <CircularProgress
            color="primary"
            data-testid="mobile-setup-spinner"
          />
        )}
      </Grid>
    </Form>
  );
}

DetailsForm.propTypes = {
  classes: objectOf(string).isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  maxLengths: object.isRequired,
  setFieldValue: func.isRequired,
  touched: object.isRequired,
  handleBlur: func.isRequired,
  loading: bool.isRequired,
  handleCancel: func.isRequired,
  dirty: bool.isRequired,
};

DetailsForm.defaultProps = {};

export default withStyles(styles)(DetailsForm);
