import { useQuery } from 'react-query';

import { backendToFrontend } from '../../../store/funds/transform';
import FundService from '../../../api/FundService';

async function getFunds({ queryKey }) {
  const [, organizationId] = queryKey;
  const response = await FundService.getFunds(organizationId);
  return response.data.data.map(backendToFrontend);
}

export default function useFunds(organizationId) {
  return useQuery(['funds', organizationId], getFunds);
}
