import { withStyles } from '@material-ui/core/styles';
import { string, objectOf, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Contact from '../Contact';
import Branding from '../Branding';
import Fees from '../Fees';
import Page from '../../Page';
import PagePaper from '../../PagePaper';
import PageTitle from '../../PageTitle';

import styles from './styles';

export function Editor({ classes, variant }) {
  const { t } = useTranslation();
  const history = useHistory();

  const onGoBack = () => {
    history.push(`/organization`);
  };

  return (
    <Page variant="cardContainer">
      <PageTitle onGoBack={onGoBack}>
        {t(`organization.pageWizard.${variant}.key`)}
      </PageTitle>
      <PagePaper className={classes.pagePaper}>
        {variant === 'contact' && <Contact onGoBack={onGoBack} />}
        {variant === 'branding' && <Branding onGoBack={onGoBack} />}
        {variant === 'fees' && <Fees onGoBack={onGoBack} />}
      </PagePaper>
    </Page>
  );
}

Editor.propTypes = {
  classes: objectOf(string).isRequired,
  variant: oneOf(['contact', 'branding', 'fees']),
};

Editor.defaultProps = { variant: 'contact' };

export default withStyles(styles)(Editor);
