export default function styles(theme) {
  const text = {
    textAlign: 'center',
    padding: '10px 20px',
    color: theme.palette.secondary.light,
    fontSize: '1.5em',
    alignSelf: 'center',
    width: '100%',
  };

  const content = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  return {
    baseStyle: {
      width: 325,
      maxWidth: '100%',
      marginTop: 20,
      marginBottom: 20,
    },
    container: {
      position: 'relative',
      width: '100%',
      // Calculated aspect ratio padding: https://codepen.io/onge/pen/MaqwBK
      paddingTop: '61.63%',
    },
    content,
    contentError: {
      ...content,
      borderColor: theme.palette.error.main,
    },
    image: {
      position: 'relative',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      width: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    text,
    textError: {
      ...text,
      color: theme.palette.error.main,
      paddingTop: 0,
    },
    uploadIcon: {
      color: theme.palette.primary.main,
      position: 'relative',
      top: 5,
      left: 7,
    },
    removeButton: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    removeIcon: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      padding: 0,
      boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
      borderRadius: '50%',
    },
    photoIcon: {
      color: theme.palette.primary.main,
    },
  };
}
