import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { validations } from '../../../utils';
import { WizardContext } from '../../../utils/wizard';

function FormWrap({
  onChange,
  onErrorsChange,
  setFieldValue,
  errors,
  render,
  dirty,
  setDirty,
  handleSubmit,
  setSubmit,
  isValid,
  ...rest
}) {
  function setValue(e, ele, skipOnChange = false) {
    const { name, value } = e.target;
    let newValue = value;

    if (typeof value === 'string') {
      newValue = value.replace(validations.nonAscii, '');
    }
    setFieldValue(name, newValue);

    if (onChange && !skipOnChange) {
      onChange(name, newValue);
    }

    if (setDirty) {
      setDirty(dirty);
    }
  }

  function handleSubmitWrapper() {
    handleSubmit();
    if (isValid) {
      setDirty(false);
    }
  }

  useEffect(() => {
    if (onErrorsChange) {
      onErrorsChange(!!Object.keys(errors).length);
    }
  }, [errors, onErrorsChange]);

  useEffect(() => {
    if (setDirty) {
      setDirty(dirty);
    }
  }, [dirty, setDirty]);

  useEffect(() => {
    if (setSubmit) {
      setSubmit(() => handleSubmit);
    }
  }, [setSubmit, handleSubmit]);

  return render({
    ...rest,
    errors,
    dirty,
    handleSubmit: handleSubmitWrapper,
    setFieldValue: setValue,
  });
}

function CustomFormik({
  initialValues,
  onSubmit,
  render,
  validationSchema,
  onChange,
  onErrorsChange,
  enableReinitialize,
  initialStatus,
}) {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={enableReinitialize}
      initialStatus={initialStatus}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <WizardContext.Consumer>
          {({ setDirty, setSubmit }) => (
            <FormWrap
              onChange={onChange}
              onErrorsChange={onErrorsChange}
              render={render}
              setDirty={setDirty}
              setSubmit={setSubmit}
              {...props}
            />
          )}
        </WizardContext.Consumer>
      )}
    </Formik>
  );
}

CustomFormik.propTypes = {
  enableReinitialize: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  render: PropTypes.func.isRequired,
  validationSchema: PropTypes.object,
  onChange: PropTypes.func,
  onErrorsChange: PropTypes.func,
  initialStatus: PropTypes.object,
};

CustomFormik.defaultProps = {
  validationSchema: null,
  onSubmit: null,
  onChange: null,
  onErrorsChange: null,
  enableReinitialize: false,
  initialStatus: {},
};

export default CustomFormik;
