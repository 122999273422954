import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, objectOf, string } from 'prop-types';
import { withStyles } from '@material-ui/core';

import Page from '../Page';
import PageTitle from '../PageTitle';
import PagePaper from '../PagePaper';
import CustomFormik from '../CustomFormik';
import PreventLeavePageModal from '../PreventLeavePageModal';
import Form from './Form';

import useGetPageSettings from '../../../utils/hooks/useGetPageSettings';
import usePreventModal from '../../../utils/hooks/usePreventModal';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';
import { getCheckPercentage, getCreditPercentage } from '../../../utils/page';
import { createSchema } from './validation';

import styles from './styles';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

export function VancoMobileEditProcessingFees({
  classes,
  loadingSave,
  savePage,
  setSuccess,
}) {
  const { t } = useTranslation();
  usePageTitle(t('vanco.mobile.editProcessingFees'));
  const schema = createSchema();
  const [dirty, setDirty] = useState(false);
  const { goBack, onCancel, cancelLeave } = usePreventModal('/mobile/home');
  const { isLoading, page, updatePage } = useGetPageSettings(
    CHANNEL_TYPE.MOBILE,
    PAGE_ACTIVE,
  );

  const onSubmit = async (values) => {
    setDirty(false);
    await savePage({
      ...page,
      ...values,
      hasPaymentMethods: true,
    });
    setSuccess(t('vanco.mobile.editProcessingFeesSuccess'));
    goBack();
  };

  const handleUpdatePage = (key, value) => {
    setDirty(true);
    updatePage({
      ...page,
      [key]: value,
    });
  };

  if (isLoading || Object.keys(page).length < 1) {
    return null;
  }

  // TODO: Remove with ChannelManagement FF cleanup
  return (
    <Page variant="centered">
      <PageTitle className={classes.title} onGoBack={goBack}>
        {t('donations.processingFees')}
      </PageTitle>
      <PagePaper className={classes.paper}>
        <CustomFormik
          initialValues={{
            enableProcessingFees: page.enableProcessingFees || false,
            checkPercentage: !dirty
              ? getCheckPercentage(page)
              : page.checkPercentage,
            creditPercentage: !dirty
              ? getCreditPercentage(page)
              : page.creditPercentage,
          }}
          onSubmit={onSubmit}
          render={(formikProps) => (
            <Form
              {...formikProps}
              loading={loadingSave}
              dirty={dirty}
              onCancel={onCancel}
            />
          )}
          onChange={handleUpdatePage}
          validationSchema={schema}
        />
      </PagePaper>
      <PreventLeavePageModal shouldOpenOnLeave={dirty && !cancelLeave} />
    </Page>
  );
}

VancoMobileEditProcessingFees.propTypes = {
  classes: objectOf(string).isRequired,
  loadingSave: bool.isRequired,
  savePage: func.isRequired,
  setSuccess: func.isRequired,
};

export default withStyles(styles)(VancoMobileEditProcessingFees);
