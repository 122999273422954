export default function styles(theme) {
  return {
    content: {
      marginBottom: 20,
    },
    buttonLabelHeader: {
      padding: '14px 0 12px 16px',
      color: '#999999',
      fontSize: '1.3rem',
    },
    buttonLabelDivider: {
      margin: '12px 0 10px 50px',
      color: '#EBEBEB',
    },
    inlineItems: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 20,
    },
    doneIcon: {
      marginLeft: -2,
      paddingRight: 8,
      width: 32,
      height: 32,
    },
    unselected: {
      paddingLeft: 30,
    },
    responseDetailMainText: {
      marginTop: theme.spacing(4),
    },
    responseDetailText: {
      color: theme.palette.text.subdued,
      marginLeft: 32,
      marginTop: -6,
    },
    textFieldMargin: {
      paddingLeft: 32,
    },
    disclosure: {
      marginTop: 24,
    },
    disclosureMessage: {
      padding: 22,
      marginBottom: 22,
    },
  };
}
