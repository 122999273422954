export default function styles() {
  return {
    text: {
      fontWeight: 'normal',
      marginTop: 20,
      marginBottom: 20,
    },
    container: {
      maxWidth: 600,
    },
    spinnerContainer: {
      marginTop: 40,
    },
  };
}
