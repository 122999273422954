import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AuthService from '../../../api/AuthService';
import AzureAuthService from '../../../api/AzureAuthService';
import { handleException } from '../../../store/alerts/actions';
import { login, loginFailed } from '../../../store/user/actions';
import { getFeatures } from '../../../store/features/actions';
import { useFeatures } from '../useFeatures/useFeatures';

export function useAzureLogin(iframeRef) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn: isUserLoggedIn, redirectPath } = useSelector(
    (state) => state.user,
  );
  const { AzureClientAuth } = useFeatures();

  // Handle Azure login
  const handleAzureLogin = useCallback(async () => {
    try {
      const authResult = await AzureAuthService.loginAsync();
      if (authResult) {
        dispatch(login(authResult));
      }
    } catch (e) {
      console.error('Azure login failed:', e);
      handleException(e);
      dispatch(loginFailed(e));
    }
  }, [dispatch]);

  // Handle IDS Login
  const handleIframeLogin = useCallback(async () => {
    // since iframeRef may be null if we are logged in we need to check if its null
    try {
      if (iframeRef.current) {
        const authResult = await AuthService.login(iframeRef.current);
        if (authResult.accessToken) {
          dispatch(login(authResult));
        }
      }
    } catch (e) {
      console.error('Iframe login failed:', e);
      handleException(e);
      dispatch(loginFailed(e));
    }
  }, [dispatch, iframeRef]);

  useEffect(() => {
    if (isUserLoggedIn) {
      history.push(redirectPath);
    } else {
      // Fetch feature flags
      dispatch(getFeatures());

      if (AzureClientAuth) {
        handleAzureLogin();
        return;
      }

      handleIframeLogin(iframeRef);
    }
  }, [
    isUserLoggedIn,
    history,
    AzureClientAuth,
    iframeRef,
    dispatch,
    handleAzureLogin,
    handleIframeLogin,
    redirectPath,
  ]);
}

export default useAzureLogin;
