export default theme => ({
  root: {
    width: 128,
    height: 128,
    borderRadius: 8,
    display: 'inline-flex',
    justifyContent: 'center',
    margin: '16px 18px 6px 0',
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  removeImageButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  removeImageIcon: {
    height: 24,
    width: 24,
    stroke: theme.palette.primary.main,
    strokeWidth: '2px',
  },
});
