import {
  MY_VANCO_ORIGIN_NAME,
  NO_ADDRESS_PROVIDED,
  PAYMENT_FREQUENCY_ONE_TIME_FUTURE,
  PAYMENT_METHOD,
  RECURRING_FREQUENCIES,
  SERVICE_PAYMENT_METHOD,
} from '../../../../globals/constants';
import {
  addFeesToAmount,
  getStrippedPhoneNumber,
} from '../../../../utils/basket';
import { formatDateToServerRequest } from '../../../../utils/calendar';
import { isValidObject } from '../../../../utils/validations';

export const getDefaultPaymentMethod = (paymentMethods) => {
  if (!paymentMethods || !Object.keys(paymentMethods).length)
    return PAYMENT_METHOD.CREDIT_CARD;

  const { creditCard = null, ach = null } = paymentMethods;

  if (!creditCard && !ach) return PAYMENT_METHOD.CREDIT_CARD;

  const isAchOnly = !creditCard?.isAllowed && ach?.isAllowed;
  return isAchOnly ? PAYMENT_METHOD.ACH : PAYMENT_METHOD.CREDIT_CARD;
};

export const userToEnsureFactory = ({ selectedPaymentType, formValues }) => {
  const paymentTypeExists = [
    PAYMENT_METHOD.CREDIT_CARD,
    PAYMENT_METHOD.ACH,
  ].includes(selectedPaymentType);

  if (!paymentTypeExists) return {};

  const {
    firstName = '',
    lastName = '',
    email = '',
  } = formValues?.[selectedPaymentType] ?? {};

  return {
    givenName: firstName,
    familyName: lastName,
    email,
  };
};

export const addressesToStreet = (addressLine1, addressLine2) =>
  `${addressLine1 ?? ''}\n${addressLine2 ?? ''}`;

export const getAddressFromPayerUser = ({
  stateOrProvince = '',
  city = '',
  addressA = '',
  addressB = '',
  postalCode = '',
  phoneNumber = '',
} = {}) => ({
  state: stateOrProvince,
  city,
  addressLine1: addressA,
  addressLine2: addressB,
  zipCode: postalCode,
  phoneNumber,
});

export const getAddressFromForm = ({
  billingInfo: {
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    state = '',
    zipCode = '',
    phoneNumber = '',
  } = {},
}) => ({
  state,
  city,
  addressLine1,
  addressLine2,
  zipCode,
  phoneNumber,
});

export const hasFormCompletedAddress = ({
  state = '',
  city = '',
  addressLine1 = '',
  zipCode = '',
} = {}) => Boolean(state && city && addressLine1 && zipCode);

export const conditionallyAddAddress = ({
  state = '',
  city = '',
  street1 = '',
  street2 = '',
  zipCode = '',
} = {}) => ({
  ...(state && { state }),
  ...(city && { city }),
  ...(street1 && { addressLine1: street1 }),
  ...(street2 && { addressLine2: street2 }),
  ...(zipCode && { postalCode: zipCode }),
});

export const getFormattedInformation = (addressInfo) => {
  if (!isValidObject(addressInfo)) return {};

  const streets = addressInfo?.street?.split('\n');
  return {
    street1: streets?.length >= 1 ? streets[0] : '',
    street2: streets?.length > 1 ? streets[1] : '',
    strippedPhone: getStrippedPhoneNumber(addressInfo.phoneNumber),
  };
};

const getAddressInfo = (formAddress, payerUserAddress) => {
  /*
   * Form values rules over IDS (payerUser) values
   */
  if (!isValidObject(formAddress) && !isValidObject(payerUserAddress))
    return {};

  const {
    state: formState,
    city: formCity,
    addressLine1: formAddressLine1,
    addressLine2: formAddressLine2,
    zipCode: formZipCode,
    phoneNumber: formPhoneNumber,
  } = formAddress;
  const {
    state: payerState,
    city: payerCity,
    addressLine1: payerAddressLine1,
    addressLine2: payerAddressLine2,
    zipCode: payerZipCode,
    phoneNumber: payerPhoneNumber,
  } = payerUserAddress;

  const addressLine1 = formAddressLine1 || payerAddressLine1;
  const addressLine2 = formAddressLine2 || payerAddressLine2;

  const addressInfo = {
    state: formState || payerState,
    city: formCity || payerCity,
    street: addressesToStreet(addressLine1, addressLine2),
    zipCode: formZipCode || payerZipCode,
    phoneNumber: formPhoneNumber || payerPhoneNumber,
  };

  const { street1, street2, strippedPhone } =
    getFormattedInformation(addressInfo);

  const { state = '', city = '', zipCode = '' } = addressInfo;

  return {
    state,
    city,
    street1,
    street2,
    zipCode,
    strippedPhone,
  };
};

/**
 * @param payerUser - the user information from the ensureUser endpoint
 * @param formValues - the formik values {creditCard, ach, billingInfo}
 * @param selectedPaymentType - should be CREDIT_CARD or ACH, it comes from Redux
 */
export const hexeaRequestFactory = ({
  payerUser = {},
  formValues = {},
  selectedPaymentType,
}) => {
  const paymentMethodExists = [
    PAYMENT_METHOD.CREDIT_CARD,
    PAYMENT_METHOD.ACH,
  ].includes(selectedPaymentType);

  if (
    !paymentMethodExists ||
    !isValidObject(payerUser) ||
    !isValidObject(formValues)
  )
    return {};

  const { street1, street2, strippedPhone, state, city } = getAddressInfo(
    getAddressFromForm(formValues),
    getAddressFromPayerUser(payerUser),
  );

  if (selectedPaymentType === PAYMENT_METHOD.CREDIT_CARD) {
    const {
      firstName = '',
      lastName = '',
      email = '',
      zipCode = '',
    } = formValues.creditCard || {};

    return {
      nameOnCard: `${firstName} ${lastName}`.trim(),
      authorizationType: PAYMENT_METHOD.CXP_AUTHORIZATION_TYPE_IDS,
      address: conditionallyAddAddress({
        state,
        city,
        street1,
        street2,
        zipCode,
      }),
      phone: strippedPhone,
      email,
      isSaved: false,
      partnerPayerRef: payerUser.id,
    };
  }

  if (selectedPaymentType === PAYMENT_METHOD.ACH) {
    const { zipCode = '' } = formValues.billingInfo || {};
    const {
      accountNumber = '',
      accountType = '',
      routingNumber = '',
      firstName = '',
      lastName = '',
      email = '',
    } = formValues.ach || {};

    return {
      accountNumber,
      accountType,
      routingNumber,
      firstName,
      lastName,
      email,
      phone: strippedPhone,
      isSaved: false,
      partnerPayerRef: payerUser.id,
      address: conditionallyAddAddress({
        state,
        city,
        street1: street1 || NO_ADDRESS_PROVIDED,
        street2,
        zipCode,
      }),
    };
  }

  return {};
};

export const isFuturePayment = (frequency) =>
  frequency === PAYMENT_FREQUENCY_ONE_TIME_FUTURE;

export const isRecurringPayment = (frequency) =>
  RECURRING_FREQUENCIES.includes(frequency);

export const mapRecurringTransaction = (startDate, endDate) => ({
  startDate: formatDateToServerRequest(startDate),
  endDate: formatDateToServerRequest(endDate),
});

export const transactionItemsFactory = (
  basket,
  feeValue,
  includeProcessingFee = false,
) =>
  basket?.map(
    ({ fundId, frequency, amount, memoLine, startDate, endDate }) => ({
      frequency,
      campaign: {
        id: fundId,
        applicationGroupId: 0,
      },
      amount,
      includeProcessingFee,
      memoLine,
      ...(includeProcessingFee && {
        totalAmount: addFeesToAmount(amount, feeValue),
      }),
      ...(isFuturePayment(frequency) && {
        paymentDate: formatDateToServerRequest(startDate),
      }),
      ...(isRecurringPayment(frequency) &&
        mapRecurringTransaction(startDate, endDate)),
    }),
  ) ?? [];

export const generateServiceMethodType = (paymentMethodType) => {
  const paymentMethodExists = [
    PAYMENT_METHOD.ACH_TOKENIZE,
    PAYMENT_METHOD.CREDIT_CARD_TOKENIZE,
  ].includes(paymentMethodType);

  if (!paymentMethodExists) return '';

  return paymentMethodType === PAYMENT_METHOD.CREDIT_CARD_TOKENIZE
    ? SERVICE_PAYMENT_METHOD.CREDIT_CARD
    : SERVICE_PAYMENT_METHOD.ACH;
};

export const createTransactionsFactory = ({
  tokenPaymentDetails,
  basket,
  selectedFees: { applyProcessingFee: includeProcessingFee, feeValue },
  formValues,
  payerUser,
  selectedPaymentType,
}) => {
  const hasInvalidData =
    !isValidObject(tokenPaymentDetails) ||
    !isValidObject(formValues) ||
    !payerUser?.id ||
    basket?.length === 0;

  if (hasInvalidData) return {};

  const {
    brand,
    expireMonth,
    expireYear,
    paymentMethodType,
    paymentMethodToken,
    accountType,
    accountNumberLast4,
    last4,
  } = tokenPaymentDetails;

  const transactionItems = transactionItemsFactory(
    basket,
    feeValue,
    includeProcessingFee,
  );

  const { id, emailConfirmed } = payerUser;

  const {
    street1: addressLine1,
    street2: addressLine2,
    strippedPhone: phoneNumber,
    state: stateOrProvince,
    city,
    zipCode: postalCode,
  } = getAddressInfo(
    getAddressFromForm(formValues),
    getAddressFromPayerUser(payerUser),
  );

  const {
    firstName: givenName,
    lastName: familyName,
    // at this point payerUser.email should be the same compared to this one
    email,
  } = formValues[selectedPaymentType];

  const paymentMethodMap = {
    [PAYMENT_METHOD.ACH]: {
      last4: accountNumberLast4,
      accountType,
    },
    [PAYMENT_METHOD.CREDIT_CARD]: {
      brand,
      expireMonth,
      expireYear,
      last4,
    },
  };

  return {
    reCaptchaToken: '',
    paymentMethod: {
      paymentMethodType: generateServiceMethodType(paymentMethodType),
      paymentMethodToken,
      uuid: id,
      isDefault: false,
      includeProcessingFee,
      ...(paymentMethodMap[selectedPaymentType] ?? {}),
    },
    transactionItems,
    notificationEmail: email,
    execute: true,
    user: {
      id,
      givenName,
      familyName,
      email,
      emailConfirmed,
      phoneNumber,
      phoneNumberConfirmed: false,
      stateOrProvince,
      city,
      postalCode,
      country: '',
      addressLine1,
      addressLine2,
    },
    origin: MY_VANCO_ORIGIN_NAME,
  };
};
