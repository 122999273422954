export default function styles() {
  return {
    title: {
      marginBottom: 31,
    },
    content: {
      marginBottom: 20,
    },
    selectButton: {
      alignSelf: 'flex-start',
      marginLeft: -8,
    },
    errorDivMargin: {
      alignSelf: 'flex-start',
      marginBottom: 20,
    },
  };
}
