import withStyles from '@material-ui/core/styles/withStyles';
import { objectOf, string } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

function EmptyList({ classes, title, description }) {
  return (
    <div className={classes.emptyList}>
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography component="span">{description}</Typography>
    </div>
  );
}

EmptyList.propTypes = {
  classes: objectOf(string).isRequired,
  title: string.isRequired,
  description: string.isRequired,
};

export default withStyles(styles)(EmptyList);
