import axios from 'axios';

const FF_URL = window.env.REACT_APP_FAITH_FORWARD_BACKEND;

export async function getBibleVersion(orgId, accessToken) {
  const response = await axios.get(
    `${FF_URL}/vancoGetBibleVersion?vancoChurchId=${orgId}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  return response.data;
}

export function setBibleVersion(orgId, version, accessToken) {
  return axios.post(
    `${FF_URL}/vancoSetBibleVersion`,
    {
      vancoChurchId: orgId,
      bibleVersion: version,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
}
