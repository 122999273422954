import { shape, objectOf, string, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Typography, Tooltip } from '@material-ui/core';

import ReplayIcon from '@material-ui/icons/Replay';
import LaunchIcon from '@material-ui/icons/Launch';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import CodeIcon from '@material-ui/icons/Code';
import { useSelector } from 'react-redux';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { ReactComponent as EditIcon } from '../../../../assets/edit-20px.svg';
import { ReactComponent as ArchiveIcon } from '../../../../assets/archive-20px.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete-20px.svg';
import { ReactComponent as DefaultSiteIcon } from '../../../../assets/defaultSiteIcon.svg';
import { isPending, isArchived, isActive } from '../../../../utils/page';
import { PopoverMenu, TableRow, TableCell, Badge } from '../../../common';
import pageNavConfig from '../../../navigation/WizardLayout/page.config';
import { PAGE_ACTIVE, PAGE_ARCHIVED } from '../../../../globals/constants';
import { validations, copyText } from '../../../../utils';

import styles from './styles';

export function Page({
  classes,
  page,
  onSavePage,
  onDeletePage,
  onEmbedPage,
  onGenerateQRCode,
  setSuccess,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const siteLink = `${window.env.REACT_APP_DON_URL}/${page.locationURI}`;
  const { features } = useSelector((state) => ({
    features: state.features?.features,
  }));

  const onEditPage = () => {
    const editPath = isPending(page)
      ? pageNavConfig(page, features)
          .find((step) => step.next)
          .path.replace(':pageId', page.id)
      : `/${page.id}/page-settings`;
    history.push(editPath);
  };

  const onArchivePage = () => {
    onSavePage({
      ...page,
      status: PAGE_ARCHIVED,
    });
  };

  const onUnarchivePage = () => {
    onSavePage({
      ...page,
      status: PAGE_ACTIVE,
    });
  };

  const onDelete = () => {
    onDeletePage(page);
  };

  const onSelect = () => {
    onEditPage();
  };

  const onCopySiteLink = () => {
    copyText(siteLink);
    setSuccess(t('siteSettings.linkCopied'));
  };

  const onViewSite = () => {
    if (validations.urlRegex.test(siteLink)) window.open(siteLink, '_blank');
  };

  const onEmbed = () => {
    onEmbedPage(page);
  };

  const onViewQRCode = () => {
    onGenerateQRCode(page);
  };

  const pageStatus = page.status.toLowerCase();
  const dataTestId = { 'data-testid': `more-menu-${page.id}` };
  const isDefaultSite = page.primary && features.ChannelManagement;

  const decorateWithTooltip = (description, child) => {
    const body = (
      <div className={classes.tooltipContent}>
        <Typography>{description}</Typography>
      </div>
    );
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={body}
        placement="bottom-start"
      >
        {child}
      </Tooltip>
    );
  };

  return (
    <TableRow onClick={onSelect} data-testid={page.id}>
      <TableCell className={!page.pageName ? classes.untitled : ''}>
        <Typography component="span" className={classes.pageName}>
          {page.pageName}
          {isDefaultSite &&
            decorateWithTooltip(
              t('site.default.description'),
              <DefaultSiteIcon
                className={classes.defaultIcon}
                data-testid="default-page-icon"
              />,
            )}
        </Typography>
      </TableCell>
      <TableCell className={classes.statusColumn}>
        <Badge variant={pageStatus}>{t(`site.status.${pageStatus}`)}</Badge>
      </TableCell>
      <TableCell className={classes.moreMenu} menu>
        <PopoverMenu buttonProps={dataTestId}>
          <PopoverMenu.Item onSelect={onEditPage}>
            <EditIcon />
            {t('site.edit')}
          </PopoverMenu.Item>
          {isActive(page) && (
            <PopoverMenu.Item onSelect={onViewSite}>
              <LaunchIcon />
              {t('site.viewSite')}
            </PopoverMenu.Item>
          )}
          <PopoverMenu.Divider />

          {isArchived(page) && (
            <PopoverMenu.Item onSelect={onUnarchivePage}>
              <ReplayIcon />
              {t('site.unarchive')}
            </PopoverMenu.Item>
          )}
          {isActive(page) && (
            <div>
              <PopoverMenu.Item onSelect={onCopySiteLink}>
                <InsertLinkIcon />
                <Typography>{t('site.copySiteLink')}</Typography>
              </PopoverMenu.Item>
              <PopoverMenu.Item onSelect={onEmbed}>
                <CodeIcon />
                <Typography>{t('site.embedSite')}</Typography>
              </PopoverMenu.Item>
              <PopoverMenu.Item onSelect={onViewQRCode}>
                <QrCodeIcon className={classes.icon} sx={{ fontSize: 24 }} />
                <Typography>{t('site.qrCode')}</Typography>
              </PopoverMenu.Item>
              {!isDefaultSite && <PopoverMenu.Divider />}
            </div>
          )}
          {!isDefaultSite && (
            <div>
              {isActive(page) && (
                <PopoverMenu.Item onSelect={onArchivePage}>
                  <ArchiveIcon />
                  {t('site.archive')}
                </PopoverMenu.Item>
              )}
              <PopoverMenu.Item variant="danger" onSelect={onDelete}>
                <DeleteIcon />
                {t('site.delete')}
              </PopoverMenu.Item>
            </div>
          )}
        </PopoverMenu>
      </TableCell>
    </TableRow>
  );
}

Page.propTypes = {
  classes: objectOf(string).isRequired,
  page: shape({}).isRequired,
  onSavePage: func.isRequired,
  onDeletePage: func.isRequired,
  onEmbedPage: func,
  onGenerateQRCode: func,
  setSuccess: func,
};

Page.defaultProps = {
  onEmbedPage: null,
  onGenerateQRCode: null,
  setSuccess: null,
};

export default withStyles(styles)(Page);
