import { useEffect } from 'react';
import { MY_VANCO_ORIGIN_NAME } from '../../../globals/constants';

const usePageTitle = (title, exactTitle = false) => {
  const formatTitle = () => {
    if (exactTitle) {
      return title;
    }
    if (title) {
      return `${title} | ${MY_VANCO_ORIGIN_NAME}`;
    }
    return MY_VANCO_ORIGIN_NAME;
  };

  useEffect(() => {
    document.title = formatTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);
};

export default usePageTitle;
