import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { validateImageSize, getImgFromFile } from '../../../../utils/images';

import Form from '../../Form';
import CropModal from '../../CropModal';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import Unsplash from '../../Unsplash';
import SelectedImage from '../SelectedImage';

import styles from './styles';

export function TileImageForm({
  classes,
  setFieldValue,
  handleSubmit,
  values,
  handleReset,
  dirty,
  loadingSave,
  goToNext,
  goToPrev,
  isFinished,
  tile,
  updateTileImage,
  loadingUpdateImage,
}) {
  const [cropOpen, setCropOpen] = useState(false);
  const [fileInputIsLoading, setFileInputLoading] = useState(false);
  const [uploadImageError, setUploadImageError] = useState(null);
  const initialCrop = {
    unit: '%',
    width: 100,
  };
  const [crop, setCrop] = useState(initialCrop);

  const setCropOpenWithStateReset = (isOpen) => {
    if (isOpen) {
      // Reset to initialCrop so that we don't store the height value from the old crop.
      // If we don't do this, it will use the old height percentage and the aspect ratio
      // will be wrong for other image heights.
      setCrop(initialCrop);
    }
    setCropOpen(isOpen);
  };

  const { t } = useTranslation();

  const closeCrop = () => {
    setCropOpenWithStateReset(false);
    handleReset();
  };

  const cropSave = async () => {
    const image = { crop, ...values.image };
    const updatedTile = {
      ...tile,
      image,
    };
    const tempImg = await updateTileImage(updatedTile);
    if (tempImg) {
      setFieldValue({ target: { name: 'image', value: tempImg } });
      setCropOpenWithStateReset(false);
    }
  };

  const onRemove = () => {
    const newImage = { crop: null, src: null };
    setFieldValue({ target: { name: 'image', value: newImage } });
  };

  const handleSelectedImage = (img) => {
    const image = {
      src: img.src,
      cropImage: img.urls.regular,
      rawImage: img.urls.raw,
      downloadLocation: img.links.download_location,
      isUnsplashImage: true,
      userName: img.userName,
    };
    setFieldValue({ target: { name: 'image', value: image } }, null, true);
    setCropOpenWithStateReset(true);
  };

  const onUserUploadImage = async (e) => {
    const [file] = e.target.files;
    const isValidImageSize = validateImageSize(file);
    setFileInputLoading(true);
    if (file && isValidImageSize.isValid) {
      const img = await getImgFromFile(file);

      if (img) {
        const imageUrl = img.cropImage;
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
          setFieldValue({ target: { name: 'image', value: img } }, null, true);
          setCropOpenWithStateReset(true);
          setUploadImageError(null);
        };
      } else {
        handleReset();
        setUploadImageError(
          t('upload.file.type.error', {
            fileTypes: `JPG, PNG, ${t('word.or')} GIF`,
          }),
        );
      }
    } else {
      handleReset();
      setUploadImageError(isValidImageSize.errorMessage);
    }
    setFileInputLoading(false);
  };

  return (
    <Form>
      <SelectedImage
        url={values.image.src}
        onRemove={onRemove}
        onUserUploadImage={onUserUploadImage}
        inputFileValue={values.inputFileValue}
        fileInputIsLoading={fileInputIsLoading}
        uploadImageError={uploadImageError}
      />
      <Unsplash onSelect={handleSelectedImage} selected={values.image} />
      {!loadingSave && goToNext && goToPrev && (
        <WizardButtons
          isFinished={isFinished}
          dirty={dirty}
          goToNext={handleSubmit}
          goToPrev={goToPrev}
          undo={handleReset}
        />
      )}
      {loadingSave && goToPrev && <CircularProgress color="primary" />}
      {goToNext && (
        <Button
          size="large"
          color="primary"
          onClick={() => {
            handleReset();
            handleSubmit();
          }}
          className={classes.right}
        >
          {t('button.skipStep')}
        </Button>
      )}
      {cropOpen && (
        <CropModal
          crop={crop}
          onCrop={(c, percentCrop) => {
            setCrop(percentCrop);
          }}
          open={cropOpen}
          image={values.image}
          onClose={closeCrop}
          onSave={cropSave}
          loadingUpdateImage={loadingUpdateImage}
        />
      )}
    </Form>
  );
}

TileImageForm.propTypes = {
  classes: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  handleReset: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  goToNext: PropTypes.func,
  goToPrev: PropTypes.func,
  isFinished: PropTypes.bool.isRequired,
  tile: PropTypes.object,
  updateTileImage: PropTypes.func.isRequired,
  loadingUpdateImage: PropTypes.bool.isRequired,
};

TileImageForm.defaultProps = {
  goToNext: null,
  goToPrev: null,
  tile: null,
};

export default withStyles(styles)(TileImageForm);
