import { useState, useEffect } from 'react';
import PropTypes, { objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import LaunchIcon from '@material-ui/icons/Launch';
import CodeIcon from '@material-ui/icons/Code';
import QrCodeIcon from '@mui/icons-material/QrCode';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { Button } from '@material-ui/core';
import {
  Page,
  PagePaper,
  PageTitle,
  HeaderManagement,
  ColorOptions,
  FeeDonationOptions,
  SocialSharing,
  PrimaryButton,
  PreventLeavePageModal,
  TilesOrder,
  PageStatusModal,
  StreamPageInfo,
  StreamPageGiving,
  StreamPageBranding,
  PageDivider,
  StreamPageChat,
  UnpublishPage,
  EmbedModal,
  SiteSettings,
  QRCodeModal,
} from '../../common';
import DeleteModal from '../../common/DeletePageModal';
import { defaultPage } from '../../../globals/defaults';
import Tabs from './Tabs';
import ButtonMenu from '../../common/ButtonMenu';
import { ReactComponent as ArchiveIcon } from '../../../assets/archive-20px.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-20px.svg';
import { validations, copyText } from '../../../utils';
import {
  PAGE_ACTIVE,
  PAGE_ARCHIVED,
  VO_QR_QUERY_PARAMETER,
} from '../../../globals/constants';
import {
  usePreventModal,
  useDeletePage,
  useQRCodeModal,
} from '../../../utils/hooks';

import styles from './styles';
import { isActive, isArchived } from '../../../utils/page';
import { formatStreamInfo } from '../../../utils/stream';

const getTabsContent = (isStreamPage, features) => {
  if (isStreamPage) {
    if (features.ChannelManagement)
      return [StreamPageInfo, StreamPageGiving, StreamPageChat];

    return [
      StreamPageInfo,
      StreamPageBranding,
      StreamPageGiving,
      StreamPageChat,
    ];
  }

  if (features.ChannelManagement) return [TilesOrder, SiteSettings];

  return [
    TilesOrder,
    HeaderManagement,
    ColorOptions,
    FeeDonationOptions,
    SocialSharing,
  ];
};

const getTabPaths = (isStreamPage, features) => {
  if (isStreamPage) {
    if (features.ChannelManagement) return ['/page-info', '/giving', '/chat'];

    return ['/page-info', '/branding', '/giving', '/chat'];
  }

  if (features.ChannelManagement) return ['/tiles-order', '/page-info'];

  return [
    '/tiles-order',
    '/page-info',
    '/color-options',
    '/donation-options',
    '/social-sharing',
  ];
};

export function PageBuilder({
  classes,
  history,
  location,
  page,
  savePage,
  loadingSave,
  resetNewPage,
  setSnackbar,
  setSuccess,
  removePage,
  features,
}) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [pageToUpdate, setPageToUpdate] = useState(page);
  const [pageToDelete, setPageToDelete] = useState(page);
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(false);
  const [embedModalOpen, setEmbedModalOpen] = useState(false);
  const [pageStatusModalOpen, setPageStatusModalOpen] = useState(false);
  const [deletePageModalOpen, setDeletePageModalOpen] = useState(false);
  const {
    qrCodeModalOpen,
    openQRCodeModal,
    onCloseQRCodeModal,
    onErrorQRCodeModal,
  } = useQRCodeModal();
  const [submitCount, setSubmitCount] = useState(0);
  const { deletePage, isDeletable, isDeleting, isDeletableFetching } =
    useDeletePage(pageToDelete, {
      enabled: deletePageModalOpen,
    });
  const currentPath = location.pathname;
  const isStreamPage = currentPath.includes('/stream');
  // For now, only Stream Page Editor will display the new UI
  const isNewGUI = isStreamPage;
  const { goBack, cancelLeave } = usePreventModal(
    isStreamPage ? '/streams' : '/sites',
  );
  const siteLink = !isStreamPage
    ? `${window.env.REACT_APP_DON_URL}/${page.locationURI}/home`
    : `${window.env.REACT_APP_STREAM_URL}/watch/${formatStreamInfo(
        page.streamData?.streamerId,
      )}/${formatStreamInfo(page.streamData?.streamId)}`;
  const tabPaths = getTabPaths(isStreamPage, features);
  const tabIndex = tabPaths.findIndex((path) => currentPath.includes(path));
  const TabContent = getTabsContent(isStreamPage, features)[activeTab];
  const onCloseEmbedModal = () => setEmbedModalOpen(false);
  const openEmbedModal = () => setEmbedModalOpen(true);

  const verbiage = '.off';

  const isDefaultVOSite =
    !isStreamPage && page.primary && features.ChannelManagement;

  useEffect(() => {
    setActiveTab(tabIndex !== -1 ? tabIndex : 0);
  }, [tabIndex]);

  useEffect(() => {
    if (page.id !== pageToUpdate.id) {
      setPageToUpdate(page);
    }
  }, [page, pageToUpdate]);

  const handleTabChange = (e, val) => {
    let newLocation;
    if (isStreamPage) {
      newLocation = `/streams/${page.id}/stream-settings${tabPaths[val]}`;
    } else {
      newLocation = `/${page.id}/page-settings${tabPaths[val]}`;
    }
    history.push(newLocation);
  };

  const handleUpdatePage = (key, value) => {
    setDirty(true);
    setPageToUpdate({
      ...pageToUpdate,
      [key]: value,
    });
  };

  const handleUpdateStreamData = (key, value) => {
    setDirty(true);
    setPageToUpdate({
      ...pageToUpdate,
      streamData: {
        ...pageToUpdate.streamData,
        [key]: value,
      },
    });
  };

  const handleUpdateStream = (overrides) => {
    setDirty(true);
    setPageToUpdate({
      ...pageToUpdate,
      streamData: {
        ...pageToUpdate.streamData,
        ...overrides.streamData,
      },
      streamGivingOption: {
        ...overrides.streamGivingOption,
      },
    });
  };

  const publishPage = async () => {
    if (dirty) {
      if (!error) {
        if (isStreamPage) {
          await savePage(pageToUpdate, true, t('site.changes.saved'));
        } else {
          savePage(pageToUpdate, true);
        }
        setDirty(false);
      } else if (isNewGUI) {
        // For streaming editor (new GUI) Submit button will be enabled even if the form has errors
        setSubmitCount(submitCount + 1);
      } else {
        setSnackbar(t('errors.formErrors'));
      }
    }
  };

  const onCopy = () => {
    copyText(siteLink);
    setSuccess(t('siteSettings.linkCopied'));
  };

  const onGoToSite = () => {
    if (validations.urlRegex.test(siteLink)) window.open(siteLink, '_blank');
  };

  const onLeave = () => {
    setDirty(false);
    setSubmitCount(0);
    setPageToUpdate(page);
  };

  const onChangePageStatus = () => {
    setPageStatusModalOpen(true);
  };

  const closePageStatusModal = () => {
    setPageStatusModalOpen(false);
  };

  const onConfirmChangeStatus = async () => {
    let message = '';
    if (isActive(pageToUpdate)) {
      message = isStreamPage
        ? t(`stream.unpublish.success${verbiage}`)
        : t('site.archived.success');
      await savePage(
        {
          ...pageToUpdate,
          status: PAGE_ARCHIVED,
        },
        true,
        message,
      );
    } else {
      message = isStreamPage
        ? t(`stream.publish.success${verbiage}`)
        : t('site.unarchived.success');
      await savePage(
        {
          ...pageToUpdate,
          status: PAGE_ACTIVE,
        },
        true,
        message,
      );
    }

    closePageStatusModal();
    setSuccess(message);
  };

  const onSelectPageToDelete = () => {
    setPageToDelete(page);
    setDeletePageModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeletePageModalOpen(false);
  };

  const onDeletePage = () => {
    deletePage(pageToDelete, {
      onSuccess: () => {
        closeDeleteModal();
        history.push(isStreamPage ? '/streams' : '/sites');
        removePage(pageToDelete);
        setSuccess(
          t(isStreamPage ? 'stream.delete.success' : 'site.delete.success'),
        );
      },
    });
  };

  return (
    <Page variant={isNewGUI ? 'centeredWide' : 'normal'}>
      <div className={classes.title}>
        <PageTitle onGoBack={goBack}>{page.pageName}</PageTitle>
        <div className={classes.leftMargin}>
          <ButtonMenu
            buttonContent={t('site.actions')}
            disabled={isArchived(page)}
          >
            <ButtonMenu.Item onClick={onGoToSite}>
              <LaunchIcon className={classes.icon} />
              <Typography>
                {t(
                  isStreamPage ? 'stream.viewStream' : 'siteSettings.goToSite',
                )}
              </Typography>
            </ButtonMenu.Item>
            <ButtonMenu.Divider />
            <ButtonMenu.Item onClick={onCopy}>
              <InsertLinkIcon className={classes.icon} />
              <Typography>
                {t(
                  isStreamPage
                    ? 'stream.copyStreamLink'
                    : 'siteSettings.CopySiteLink',
                )}
              </Typography>
            </ButtonMenu.Item>
            <ButtonMenu.Item onClick={openEmbedModal}>
              <CodeIcon className={classes.icon} />
              <Typography>
                {t(isStreamPage ? 'stream.embedStream' : 'site.embed')}
              </Typography>
            </ButtonMenu.Item>
            <ButtonMenu.Item onClick={openQRCodeModal}>
              <QrCodeIcon className={classes.icon} sx={{ fontSize: 24 }} />
              <Typography>{t('site.qrCode')}</Typography>
            </ButtonMenu.Item>
            {!isDefaultVOSite && (
              <div>
                <ButtonMenu.Divider />
                <ButtonMenu.Item onClick={onChangePageStatus}>
                  {!isStreamPage && <ArchiveIcon className={classes.icon} />}
                  {isStreamPage && <VisibilityOff className={classes.icon} />}
                  <Typography>
                    {t(
                      isStreamPage
                        ? `site.unpublish${verbiage}`
                        : 'site.archive',
                    )}
                  </Typography>
                </ButtonMenu.Item>
                <ButtonMenu.Item onClick={onSelectPageToDelete}>
                  <DeleteIcon className={`${classes.icon} ${classes.error}`} />
                  <Typography className={classes.error}>
                    {t(isStreamPage ? 'stream.delete' : 'site.delete')}
                  </Typography>
                </ButtonMenu.Item>
              </div>
            )}
          </ButtonMenu>
        </div>
      </div>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        isStreamPage={isStreamPage}
        features={features}
      />
      <PagePaper className={isNewGUI ? classes.pagePaper : ''}>
        {isStreamPage && isArchived(pageToUpdate) && (
          <UnpublishPage
            onClick={onChangePageStatus}
            disabled={loadingSave}
            isStreamPage
            dirty={dirty}
          />
        )}
        <div className={isNewGUI ? classes.tabContent : ''}>
          <TabContent
            page={pageToUpdate}
            updatePage={handleUpdatePage}
            updateStreamData={handleUpdateStreamData}
            updateStream={handleUpdateStream}
            resetNewPage={resetNewPage}
            setError={setError}
            onUnarchivePage={onChangePageStatus}
            submitCountFromParent={submitCount}
          />
        </div>
        {isNewGUI && <PageDivider variant="wideEditor" />}
        {tabIndex > 0 && !isStreamPage && (
          <div className={classes.buttonContainerDeprecated}>
            {loadingSave ? (
              <CircularProgress color="primary" />
            ) : (
              <PrimaryButton
                size="large"
                disabled={!dirty || error}
                type="button"
                onClick={publishPage}
              >
                {t('button.saveChanges')}
              </PrimaryButton>
            )}
          </div>
        )}
        {isStreamPage && (
          <div className={classes.buttonContainer}>
            {loadingSave && <CircularProgress color="primary" />}
            {!loadingSave &&
              (isArchived(pageToUpdate) ? (
                <>
                  <Button
                    size="large"
                    disabled={!dirty || error}
                    variant="outlined"
                    onClick={publishPage}
                  >
                    {t('button.saveChanges')}
                  </Button>
                  <PrimaryButton
                    size="large"
                    disabled={!dirty || error}
                    type="button"
                    onClick={onChangePageStatus}
                    className={classes.savePublishButton}
                  >
                    {t('button.savePublish')}
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton
                  size="large"
                  disabled={!dirty}
                  type="button"
                  onClick={publishPage}
                >
                  {t('button.saveChanges')}
                </PrimaryButton>
              ))}
          </div>
        )}
      </PagePaper>
      <PreventLeavePageModal
        shouldOpenOnLeave={dirty && !cancelLeave}
        onLeave={onLeave}
      />
      <PageStatusModal
        disabled={loadingSave}
        onClose={closePageStatusModal}
        open={pageStatusModalOpen}
        onAccept={onConfirmChangeStatus}
        variant={
          // eslint-disable-next-line no-nested-ternary
          isActive(pageToUpdate)
            ? isStreamPage
              ? 'unpublish'
              : 'archive'
            : isStreamPage
              ? 'publish'
              : 'unarchive'
        }
      />
      <EmbedModal
        domain={window.env.REACT_APP_DON_URL}
        locationURI={page.locationURI}
        open={embedModalOpen}
        onClose={onCloseEmbedModal}
        page={page}
        variant={isStreamPage ? 'stream' : 'default'}
      />
      <DeleteModal
        isDeletable={isDeletable}
        isLoading={isDeletableFetching}
        disabled={isDeleting}
        onClose={closeDeleteModal}
        open={deletePageModalOpen}
        onDelete={onDeletePage}
        page={pageToDelete}
        entityType={isStreamPage ? 'stream' : 'site'}
      />
      <QRCodeModal
        siteLink={`${siteLink}?${VO_QR_QUERY_PARAMETER}`}
        open={qrCodeModalOpen}
        onClose={onCloseQRCodeModal}
        onError={onErrorQRCodeModal}
        name={page.pageName}
      />
    </Page>
  );
}

PageBuilder.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  page: PropTypes.object,
  savePage: PropTypes.func.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  resetNewPage: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  removePage: PropTypes.func.isRequired,
  features: objectOf(PropTypes.bool).isRequired,
};

PageBuilder.defaultProps = {
  page: defaultPage,
};

export default withStyles(styles)(PageBuilder);
