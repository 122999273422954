import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

function PageDescription(props) {
  const { children, classes, ...typographyProps } = props;
  return (
    <Typography variant="body1" className={classes.text} {...typographyProps}>
      {children}
    </Typography>
  );
}

PageDescription.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

PageDescription.defaultProps = {
  children: '',
};

export default withStyles(styles)(PageDescription);
