export default () => ({
  desktopLogo: {
    height: 24,
  },
  mobileLogo: {
    height: 34,
    display: 'block',
  },
  grow: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
