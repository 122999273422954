import { useEffect, useState } from 'react';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  withStyles,
} from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import MaterialTable from '@material-ui/core/Table';
import PropTypes from 'prop-types';

import styles from './styles';
import { IMPORT_STATUS } from '../utils/const';

function Table({ classes, cols, data, onItemSelected }) {
  const [sortedData, setSortedData] = useState(data);
  const [sortedBy, setSortedBy] = useState([null, null]);

  useEffect(() => {
    setSortedData(data);
  }, [data, setSortedData]);

  function renderCell(item, column) {
    const Wrapper = column.wrapper;
    if (!Wrapper) return item;
    return <Wrapper>{item}</Wrapper>;
  }
  function sortByCol(columnKey, order) {
    if (order === 'asc') {
      return data.sort((a, b) => {
        if (!b[columnKey]) return -1;
        return b[columnKey].localeCompare(a[columnKey]);
      });
    }
    return data.sort((a, b) => {
      if (!a[columnKey]) return -1;
      return a[columnKey].localeCompare(b[columnKey]);
    });
  }

  const onSortBy = (columnKey) => () => {
    const [currentSortCol, currentSortOrder] = sortedBy;
    let nextSortOrder = 'asc';
    if (currentSortCol === columnKey && currentSortOrder === 'asc') {
      nextSortOrder = 'desc';
    }
    const sorted = sortByCol(columnKey, nextSortOrder);
    setSortedData(sorted);
    setSortedBy([columnKey, nextSortOrder]);
  };

  const onRowClick = (item) => {
    if (onItemSelected) {
      onItemSelected(item);
    }
  };

  return (
    <MaterialTable>
      <colgroup>
        {cols.map((el) => (
          <col key={el.key} width={el.width || 'auto'} />
        ))}
      </colgroup>
      <TableHead>
        <TableRow>
          {cols.map((col) => (
            <TableCell
              sortDirection="asc"
              key={`${col.key}`}
              style={{
                color: 'black',
                fontWeight: 'bold',
              }}
            >
              {col.displayName}
              {col.sortable && (
                <IconButton onClick={onSortBy(col.key)}>
                  <ImportExportIcon />
                </IconButton>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedData.map((item) => (
          <TableRow
            key={`${item.id}`}
            onClick={() => onRowClick(item)}
            className={
              item.status === IMPORT_STATUS.FAILED ||
              item.status === IMPORT_STATUS.COMPLETE_WITH_ERRORS
                ? classes.tableRow
                : ''
            }
          >
            {cols.map((col) => (
              <TableCell key={`${item.id}—${col.key}`}>
                {renderCell(
                  col.subKey ? item[col.key][col.subKey] : item[col.key],
                  col,
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MaterialTable>
  );
}

Table.propTypes = {
  classes: PropTypes.object.isRequired,
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      displayName: PropTypes.string,
      width: PropTypes.string,
      wrapper: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
        PropTypes.object,
      ]),
      sortable: PropTypes.bool,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onItemSelected: PropTypes.func,
};

Table.defaultProps = {
  onItemSelected: null,
};

export default withStyles(styles)(Table);
