import { connect } from 'react-redux';
import { setSuccess } from '../../../../store/alerts/actions';
import { saveOrganizationSettings } from '../../../../store/organizations/actions';

import DisableMobileModal from './DisableMobileModal';

export default connect(null, {
  setSuccess,
  saveOrganizationSettings,
})(DisableMobileModal);
