import { commonStyles } from '../styles';

export default function styles(theme) {
  return {
    ...commonStyles(theme),
    pageName: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    defaultIcon: {
      marginLeft: theme.spacing(1),
      minWidth: 22,
      minHeight: 22,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      border: '1px solid rgba(137, 152, 165, 0.08)',
      boxShadow:
        '0px 12px 32px rgba(52, 52, 72, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.08)',
      borderRadius: 6,
      minWidth: 340,
    },
    tooltipContent: {
      padding: '16px 20px',
      fontSize: 14,
    },
  };
}
