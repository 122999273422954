export default function styles() {
  return {
    title: {
      '& h6': {
        color: '#222222',
        textAlign: 'left',
        paddingLeft: 0,
      },
    },
    content: {
      '& p:not(:first-child)': {
        marginTop: '1em',
      },
    },
    errorText: {
      color: '#222222',
      paddingBottom: 12,
    },
    submitButton: {
      fontWeight: 'bold',
    },
  };
}
