import { useQuery } from 'react-query';

import DashboardService from '../../../api/DashboardService';

async function getDasboardData({ queryKey }) {
  const [, type] = queryKey;
  const response = await DashboardService.getDashboardUrl(type);
  return response.data.data;
}

export default function useDashboard({ type }) {
  const { data, status } = useQuery(['dashboard', type], getDasboardData);
  let url = null;

  if (status === 'success') {
    url = data.url;
  }

  return { url, status, error: status === 'error' };
}
