import { Button, Paper, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as KioskIcon } from '../../../assets/kioskSectionIcon.svg';
import styles from './styles';

function KioskWelcome({ classes, onClickStart }) {
  const { t } = useTranslation();
  return (
    <Paper>
      <div className={classes.container}>
        <span className={classes.requestHeader}>
          <KioskIcon className={classes.kioskIcon} />
          <Typography className={classes.headerText}>
            {t(`kiosk.info.title`)}
          </Typography>
        </span>
        <Typography className={classes.boldSubtitle}>
          {t(`kiosk.info.subtitle1`)}
        </Typography>
        <Typography>{t(`kiosk.info.subtitle2`)}</Typography>
        <Typography className={classes.readyMessage}>
          {t('kiosk.setup.settings.welcome.ready')}
        </Typography>
        <span>
          <Button
            className={classes.kioskButton}
            variant="contained"
            color="primary"
            onClick={onClickStart}
          >
            {t(`kiosk.info.button`)}
          </Button>
        </span>
      </div>
    </Paper>
  );
}

KioskWelcome.propTypes = {
  classes: PropTypes.object.isRequired,
  onClickStart: PropTypes.func.isRequired,
};

export default withStyles(styles)(KioskWelcome);
