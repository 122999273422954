export default function styles(theme) {
  return {
    divider: {
      margin: '48px 0 12px 0',
    },
    paymentMethodRoot: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      marginTop: '27px',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    tabs: {
      marginTop: '27px',
    },
    defaultColor: '#3041FA',
    paymentMethodTitle: {
      lineHeight: '1.23',
      letterSpacing: '0.6px',
      marginBottom: '20px',
    },
    paymentMethodJoin: {
      display: 'flex',
      '& > a': {
        marginLeft: '8px',
      },
    },
    paymentMethodSubTitle: {
      lineHeight: '1.23',
      letterSpacing: '0.6px',
    },
    progressHolder: {
      width: '100%',
      position: 'absolute',
      alignSelf: 'baseline',
    },
    paymentProgress: {
      width: '100%',
    },
    button: {
      padding: 0,
      width: 'fit-content',
      display: 'inline',
      fontWeight: 'bold',
      lineHeight: '1.5',
      '&:hover': {
        textDecoration: 'underline',
        background: 'transparent',
        cursor: 'pointer',
      },
    },
    horizontalGutter: {
      marginTop: theme.spacing(3),
    },
    contactButton: {
      marginTop: theme.spacing(3),
    },
    secureMessage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& svg': {
        fontSize: 20,
        color: '#5E6266',
      },
      paddingBottom: theme.spacing(5),
    },
    submitButton: {
      marginTop: theme.spacing(6),
    },
  };
}
