import * as Yup from 'yup';

import i18n from '../../../../utils/i18n';
import { decimalRange } from '../../../../utils/validations';

export const maxLengths = {
  pageName: 55,
  address: 30,
  address2: 30,
  city: 25,
  state: 2,
  zip: 5,
  email: 45,
  phone: 10,
};
export const minLengths = {
  state: 2,
};

export const createSchema = () => {
  const checkMax = 2;
  const creditMax = 4;
  const checkRegex = decimalRange(0, checkMax);
  const creditRegex = decimalRange(0, creditMax);
  return Yup.object().shape({
    enableProcessingFees: Yup.bool().required(i18n.t('validation.required')),
    checkPercentage: Yup.string().when('enableProcessingFees', {
      is: true,
      then: (s) =>
        s
          .matches(
            checkRegex,
            i18n.t('validation.outOfRange', {
              type: 'percentage',
              max: checkMax.toString(),
            }),
          )
          .required(
            i18n.t('validation.required', {
              requiredVerb: 'enter a',
              requiredNoun: 'percentage',
            }),
          ),
    }),
    creditPercentage: Yup.string().when('enableProcessingFees', {
      is: true,
      then: (s) =>
        s
          .matches(
            creditRegex,
            i18n.t('validation.outOfRange', {
              type: 'percentage',
              max: creditMax.toString(),
            }),
          )
          .required(
            i18n.t('validation.required', {
              requiredVerb: 'enter a',
              requiredNoun: 'percentage',
            }),
          ),
    }),
    allowOffsetFund: Yup.bool(),
    fund: Yup.object()
      .nullable()
      .when('allowOffsetFund', {
        is: true,
        then: (s) => s.nullable().required(i18n.t('validation.fund')),
      }),
  });
};
