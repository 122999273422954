export default function styles() {
  return {
    iframeStyle: {
      padding: 0,
      width: '100%',
      // Firefox
      scrollbarWidth: 'none',
      // IE
      msOverflowStyle: 'none',
      // Chrome / Safari
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      height: '100%',
      border: 'none',
    },

    hide: {
      visibility: 'hidden',
      height: '100%',
    },
    visible: {
      visibility: 'visible',
      height: '100%',
    },
    spinnerContainer: {
      marginTop: 0,
      textAlign: 'center',
    },
    spinner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
    },
  };
}
