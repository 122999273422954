export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      marginTop: 20,
      fontWeight: 400,
      '& legend': {
        fontSize: 12,
        fontFamily: theme.typography.fontFamily,
      },
      '& .MuiInputLabel-root': {
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
      },
      '& .MuiOutlinedInput-input': {
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
      },
      '& .MuiFormHelperText-root': {
        fontSize: 13,
        fontFamily: theme.typography.fontFamily,
      },
    },
    formItemGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
    inlineFormItem: {
      flex: '1', // was 1 1 0, changed to fix IE display bug
      '&:not(:first-child)': {
        marginLeft: 16,
        '& div': {
          minHeight: 56,
        },
      },
      // minHeight: 56,
    },
    [theme.breakpoints.down('xs')]: {
      inlineFormItem: {
        '&:not(:first-child)': {
          marginLeft: '0',
        },
        // minHeight: 56, // fix for flex iOS fields shrinking
      },
      formItemGroup: {
        flexDirection: 'column',
        gap: 20,
      },
    },
  };
}
