/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import styles from './styles';

function NoPaymentsSupported({ classes }) {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <Typography variant="h2">
        {t('newTransaction.noPaymentsSupported')}
      </Typography>
    </div>
  );
}

NoPaymentsSupported.defaultProps = {
  classes: {},
};

NoPaymentsSupported.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(NoPaymentsSupported);
