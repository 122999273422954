import { makeStyles } from '@material-ui/core';

export default makeStyles(() => {
  return {
    warningMessage: {
      '& *': {
        fontSize: '18px',
      },
      '& > section:first-child': {
        // Target only the first section
        marginBottom: 20,
      },
      '& > section:last-child': {
        // Target only the last section
        marginBottom: 5,
      },
    },
    title: {
      '& *': {
        fontSize: '24px',
      },
      lineHeight: '3.2rem',
    },
  };
});
