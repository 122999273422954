import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { PAYMENT_FREQUENCY_DISPLAY_NAMES } from '../../../../globals/constants';
import styles from './styles';
import InlineErrorMessage from '../../InlineErrorMessage';
import PageDescription from '../../PageDescription';

const allFrequencies = Object.keys(PAYMENT_FREQUENCY_DISPLAY_NAMES).map(
  (key) => ({ name: PAYMENT_FREQUENCY_DISPLAY_NAMES[key], value: key }),
);

function GiftFrequencies({ classes, values, setFieldValue, errors }) {
  const { paymentFrequencies } = values;
  const { t } = useTranslation();
  const handleFrequencyChange = (item) => (e) => {
    const { name, value } = e.target;
    const newPaymentFrequenciesValue = paymentFrequencies.includes(item.value)
      ? paymentFrequencies.filter((v) => v !== item.value)
      : paymentFrequencies.concat(value);
    setFieldValue({
      target: {
        name,
        value: newPaymentFrequenciesValue,
      },
    });
  };

  const isDeselectAll = paymentFrequencies.length === allFrequencies.length;
  const toggleAllFrequencies = () => {
    const newPaymentFrequenciesValue = isDeselectAll
      ? []
      : allFrequencies.map((v) => v.value);
    setFieldValue({
      target: {
        name: 'paymentFrequencies',
        value: newPaymentFrequenciesValue,
      },
    });
  };

  return (
    <>
      <Typography variant="body2">{t('gift.frequency.title')}</Typography>
      <PageDescription>{t('gift.frequency.description')}</PageDescription>
      <Button
        onClick={toggleAllFrequencies}
        className={classes.selectButton}
        color="primary"
      >
        {t(
          isDeselectAll
            ? 'gift.frequency.deselectAll'
            : 'gift.frequency.selectAll',
        )}
      </Button>
      <Grid container className={classes.content}>
        {allFrequencies.map(({ value, name }) => {
          return (
            <Grid item xs={12} key={name}>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Checkbox
                    id={value}
                    name="paymentFrequencies"
                    color="primary"
                    checked={paymentFrequencies.includes(value)}
                    value={value}
                    onChange={handleFrequencyChange({ name, value })}
                  />
                }
                label={name}
              />
            </Grid>
          );
        })}
      </Grid>
      {errors.paymentFrequencies && (
        <InlineErrorMessage
          classes={{ root: classes.errorDivMargin }}
          errorMessage={errors.paymentFrequencies}
        />
      )}
    </>
  );
}

GiftFrequencies.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GiftFrequencies);
