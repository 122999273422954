export default function styles() {
  return {
    modal: {
      paddingBottom: 16,
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      flex: 1,
      gap: 8,
    },
    loaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: 16,
      paddingBottom: 16,
    },
  };
}
