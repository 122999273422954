export default function styles(theme) {
  return {
    titlePremiumF: {
      textAlign: 'center',
      marginTop: '40px',
      marginBottom: '25px',
      fontSize: '2.2rem',
    },
    title: {
      textAlign: 'center',
      width: '100%',
      display: 'inline-block',
      textDecoration: 'underline',
    },
    subtitle: {
      textAlign: 'center',
      width: '100%',
      display: 'inline-block',
    },
    pageDescription: {
      textAlign: 'center',
      fontSize: '2.2rem',
    },
    buttonContainer: {
      textAlign: 'center',
    },
    button: {
      padding: '13px 30px 13px 30px',
      fontSize: '1.6rem',
      fontWeight: 'bold',
      lineHeight: '1.25',
      background: theme.palette.primary.accent,
      color: theme.palette.white,
      borderRadius: '10px',
      fontFamily: '"Muli", sans-serif',
    },
    imageContainer: {
      textAlign: 'center',
      width: '100%',
      display: 'inline-block',
    },
    image: {
      width: '50%',
      marginTop: '40px',
    },
    vancoContact: {
      color: 'blue',
      fontSize: 20,
    },
  };
}
