export default function styles() {
  return {
    title: {
      position: 'relative',
      top: 0,
    },
    iframe: {
      display: 'block',
      width: '100%',
      height: '100%',
      border: 0,
    },
    rightOptions: {
      display: 'block',
      height: 0,
      float: 'right',
      position: 'relative',
      bottom: 10,
    },
    dropdown: {
      background: 'white',
      minWidth: 200,
    },
    titleGridItem: {
      paddingTop: 0,
    },
  };
}
