import visa from '../../../../../assets/cardType/visa.svg';
import mastercard from '../../../../../assets/cardType/mastercard.svg';
import discover from '../../../../../assets/cardType/discover.svg';
import amex from '../../../../../assets/cardType/american_express.svg';
import bank from '../../../../../assets/cardType/bank.svg';
import { PAYMENT_METHOD } from '../../../../../globals/constants';

const getCardSvgSource = (cardName) => {
  switch (cardName) {
    case PAYMENT_METHOD.CARD_TYPE_AMEX:
      return amex;
    case PAYMENT_METHOD.CARD_TYPE_MASTERCARD:
      return mastercard;
    case PAYMENT_METHOD.CARD_TYPE_VISA:
      return visa;
    case PAYMENT_METHOD.CARD_TYPE_DISCOVER:
      return discover;
    default:
      return bank;
  }
};

export default getCardSvgSource;
