export default function styles(theme) {
  return {
    title: {
      padding: '0px 24px',
      '& h6': {
        textAlign: 'center',
        paddingLeft: 0,
      },
    },
    titleContainer: {
      height: 60,
      marginBottom: 0,
    },
    content: {
      padding: '0px 24px 24px 24px',
    },
    closeIcon: {
      marginRight: '-12px',
    },
    actions: {
      padding: '16px 24px',
      '& button': {
        height: 40,
        borderRadius: 6,
      },
    },
    link: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    addressLink: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      textDecoration: 'underline',
      marginLeft: 4,
    },
    item: {
      paddingTop: 24,
    },
    description: {
      marginLeft: 4,
    },
  };
}
