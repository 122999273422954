import { useRef } from 'react';
import { bool, object, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@material-ui/core';

import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';
import { VANCO_VIDEO_TYPE } from '../../../globals/constants';
import useStyles from './styles';
import useIFrameMessages from '../../../utils/hooks/useIFrameMessages';

function BaseVideoComponent({ variant, customEventsMap, isLoading }) {
  const { t } = useTranslation();
  const iFrameRef = useRef(null);

  useIFrameMessages({
    iFrameRef,
    origin: window.env.REACT_APP_FAITHFORWARD_ORIGIN,
    eventsMap: customEventsMap,
  });
  const classes = useStyles();

  const { accessToken, organizationId } = useSelector((state) => ({
    accessToken: state.user.session.accessToken,
    organizationId: state.organizations.currentOrganization.id,
  }));

  const formattedAccessToken = `Bearer ${accessToken}`;
  const url = `${window.env.REACT_APP_FAITHFORWARD_ORIGIN}/app/${variant}?iframe=true&vancoChurchId=${organizationId}&accessToken=${formattedAccessToken}&env=${window.env.REACT_APP_FAITHFORWARD_ENV}`;

  const titleKey = {
    [VANCO_VIDEO_TYPE.VIDEO]: 'app.menu.streaming.smartLibrary',
    [VANCO_VIDEO_TYPE.CLIP]: 'app.menu.streaming.smartClips',
    [VANCO_VIDEO_TYPE.SETTINGS]: 'app.menu.streaming.channelSettings',
  };

  usePageTitle(t(titleKey[variant]));

  return (
    <div className={classes.wrapper}>
      <Backdrop
        open={isLoading}
        className={classes.backdrop}
        data-testid="backdrop"
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <iframe
        src={url}
        title="Faith Forward"
        className={classes.iframeStyle}
        ref={iFrameRef}
        allow="fullscreen; clipboard-write"
      />
    </div>
  );
}

BaseVideoComponent.propTypes = {
  variant: oneOf([
    VANCO_VIDEO_TYPE.CLIP,
    VANCO_VIDEO_TYPE.VIDEO,
    VANCO_VIDEO_TYPE.SETTINGS,
  ]).isRequired,
  customEventsMap: object,
  isLoading: bool,
};

BaseVideoComponent.defaultProps = {
  customEventsMap: {},
  isLoading: false,
};

export default BaseVideoComponent;
