import { useState } from 'react';
import { func, bool, objectOf, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';

import PagePaper from '../PagePaper';
import PageTitle from '../PageTitle';
import Form from './Form';
import CustomFormik from '../CustomFormik';
import Page from '../Page';
import PreventLeavePageModal from '../PreventLeavePageModal';

import useGetPageSettings from '../../../utils/hooks/useGetPageSettings';
import usePreventModal from '../../../utils/hooks/usePreventModal';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';
import { maxLengths } from '../../../utils/validations';
import { createSchema } from './validation';

import styles from './styles';

function VancoMobileOrganizationDetails({
  classes,
  savePage,
  setSuccess,
  loadingSave,
}) {
  const { t } = useTranslation();
  const schema = createSchema();
  const [dirty, setDirty] = useState(false);
  const { goBack, onCancel, cancelLeave } = usePreventModal('/mobile/home');
  const { isLoading, page, updatePage } = useGetPageSettings(
    CHANNEL_TYPE.MOBILE,
    PAGE_ACTIVE,
  );

  const update = async (values) => {
    await savePage({
      ...page,
      ...values,
    });
  };

  const onSubmit = async (values) => {
    await update(values);
    setSuccess(t('vanco.mobile.edit.orgDetails.success'));
    setDirty(false);
    goBack();
  };

  const handleUpdateForm = (key, value) => {
    setDirty(true);
    updatePage({
      ...page,
      [key]: value,
    });
  };

  if (isLoading) {
    return null;
  }

  // TODO: Remove with ChannelManagement FF cleanup
  return (
    <Page variant="centered">
      <PageTitle className={classes.title} onGoBack={goBack}>
        {t('vanco.mobile.home.settings.organizationDetails')}
      </PageTitle>
      <PagePaper className={classes.paper}>
        <CustomFormik
          initialValues={{
            pageName: page.pageName,
            address: page.address,
            address2: page.address2,
            city: page.city,
            zip: page.zip,
            state: page.state,
            phone: page.phone,
            email: page.email,
            providePhone: page.providePhone,
            provideEmail: page.provideEmail,
            provideAddress: true,
          }}
          onSubmit={onSubmit}
          render={(formikProps) => (
            <Form
              {...formikProps}
              maxLengths={maxLengths}
              loading={loadingSave}
              handleCancel={onCancel}
              dirty={dirty}
            />
          )}
          validationSchema={schema}
          onChange={handleUpdateForm}
        />
      </PagePaper>
      <PreventLeavePageModal shouldOpenOnLeave={dirty && !cancelLeave} />
    </Page>
  );
}

VancoMobileOrganizationDetails.propTypes = {
  classes: objectOf(string).isRequired,
  savePage: func.isRequired,
  setSuccess: func.isRequired,
  loadingSave: bool.isRequired,
};

export default withStyles(styles)(VancoMobileOrganizationDetails);
