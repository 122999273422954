import { connect } from 'react-redux';

import TileImageSelect from './TileImageSelect';
import { saveTile, updateTileImage } from '../../../store/tiles/actions';

export function mapStateToProps(state) {
  return {
    loadingUpdateImage: state.tiles.loadingUpdateImage,
    loadingTile: state.tiles.loadingTile,
    loadingSave: state.tiles.loadingSave,
    newTile: state.tiles.newTile,
  };
}

export default connect(
  mapStateToProps,
  { saveTile, updateTileImage },
)(TileImageSelect);
