import { useState } from 'react';
import { string, bool, func, object } from 'prop-types';
import WorldIcon from '@material-ui/icons/Public';
import ShortTextIcon from '@material-ui/icons/ShortText';
import { Button, Grid, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../PrimaryButton';
import OutlinedTextField from '../OutlinedTextField';
import { isValidURL } from './quill-util';
import Modal from '../Modal/index';

function LinkDialog({
  classes,
  isOpen,
  defaultDisplayText,
  defaultUrl,
  onSave,
  onCancel,
}) {
  const { t } = useTranslation();

  const [displayText, setDisplayText] = useState(defaultDisplayText);
  const [displayTextTouched, setDisplayTextTouched] = useState(false);
  const [linkUrlTouched, setLinkUrlTouched] = useState(false);
  const [linkUrl, setLinkUrl] = useState(defaultUrl);

  const isLinkUrlValid = isValidURL(linkUrl);

  const handleDisplayTextChange = e => {
    setDisplayTextTouched(true);
    setDisplayText(e.target.value);
  };
  const handleLinkUrlChange = e => {
    if (!defaultDisplayText && !displayTextTouched) {
      setDisplayText(e.target.value);
    }
    setLinkUrl(e.target.value);
  };

  return (
    <Modal
      fullScreen={false}
      open={isOpen}
      onClose={() => onCancel(false)}
      className={classes.modalContainer}
    >
      <Modal.Title className={classes.titleContainer}>
        {t('textEditor.linkDialog.editLink')}
      </Modal.Title>
      <Modal.Content dividers className={classes.contentPadding}>
        <form id="frm">
          <Grid container spacing={1}>
            <Grid item xs={1} className={classes.firstRow}>
              <ShortTextIcon />
            </Grid>
            <Grid item xs={11} className={classes.firstRow}>
              <OutlinedTextField
                placeholder={t('textEditor.linkDialog.textToDisplay')}
                value={displayText}
                onChange={handleDisplayTextChange}
              />
            </Grid>
            <Grid item xs={1} className={classes.secondRow}>
              <WorldIcon />
            </Grid>
            <Grid item xs={11} className={classes.secondRow}>
              <OutlinedTextField
                data-testid="url-field"
                placeholder={t('textEditor.linkDialog.pasteLinkHere')}
                value={linkUrl}
                error={linkUrlTouched && !isLinkUrlValid}
                helperText={
                  !linkUrlTouched || isLinkUrlValid
                    ? null
                    : t('textEditor.linkDialog.invalidUrl')
                }
                onChange={handleLinkUrlChange}
                onBlur={() => setLinkUrlTouched(true)}
                autoFocus
              />
            </Grid>
          </Grid>
          <div className={classes.space} />
        </form>
      </Modal.Content>
      <Modal.Actions className={classes.linkDialogActions}>
        <Button onClick={onCancel} size="medium" color="primary">
          {t('button.cancel')}
        </Button>
        <PrimaryButton
          data-testid="save-button"
          size="medium"
          type="submit"
          form="frm"
          disabled={!displayText || !isLinkUrlValid}
          onClick={() => onSave(displayText, linkUrl.trim())}
        >
          {t('button.save')}
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
}
LinkDialog.propTypes = {
  classes: object.isRequired,
  isOpen: bool.isRequired,
  defaultDisplayText: string,
  defaultUrl: string,
  onSave: func.isRequired,
  onCancel: func.isRequired,
};
LinkDialog.defaultProps = {
  defaultDisplayText: undefined,
  defaultUrl: undefined,
};

const styles = () => ({
  linkDialogActions: {
    padding: '24px 24px',
    border: 'none',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      '&:first-of-type': {
        marginRight: 8,
      },
    },
  },
  linkDialogIcon: {
    alignSelf: 'center',
  },
  modalContainer: {
    maxWidth: 548,
  },
  titleContainer: {
    // Remove the border that our custom modal adds since we're turning on dividers for the dialog content
    borderBottom: 'none',
    marginBottom: -2,
    marginTop: -2,
    padding: 11,
  },
  space: {
    height: 25,
  },
  firstRow: {
    alignSelf: 'center',
    marginTop: 16,
    marginBottom: 0,
    '& svg': {
      marginTop: 10,
    },
  },
  secondRow: {
    alignSelf: 'center',
    marginTop: -21,
    marginBottom: -6,
    padding: 0,
    '& svg': {
      marginTop: 10,
    },
  },
  contentPadding: {
    padding: '0px 24px 0px 24px',
  },
});

export default withStyles(styles)(LinkDialog);
