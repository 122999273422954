export default function styles() {
  return {
    content: {
      paddingBottom: 30,
    },
    actions: {
      padding: 20,
      justifyContent: 'flex-start',
    },
    closeButton: {
      boxShadow: '0 0px 4px 0 rgba(0, 0, 0, 0.2)',
    },
  };
}
