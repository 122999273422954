import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

import { ReactComponent as UploadIcon } from '../../../../assets/logoUploadIcon.svg';
import CropModal from '../../CropModal';
import Unsplash from '../../Unsplash';
import FileInput from '../../FileInput';
import ImageContainer from '../../ImageContainer';
import InlineErrorMessage from '../../InlineErrorMessage';
import { updateBannerImage } from '../../../../store/pages/actions';
import useImageConfiguration from '../../../../utils/hooks/useImageConfiguration/useImageConfiguration';

import styles from './styles';

export function BannerSection({
  classes,
  pageId,
  bannerImage,
  defaultBannerImageUrl,
  primaryColor,
  setImage,
}) {
  const { t } = useTranslation();
  const {
    fileInputIsLoading,
    loadingUpdateImage,
    uploadImageError,
    uncroppedImage,
    setUncroppedImage,
    cropOpen,
    setCropOpen,
    crop,
    setCrop,
    cropSave,
    onUserUploadImage,
  } = useImageConfiguration({
    pageId,
    setImage,
    updateImage: updateBannerImage,
    cropAspect: 16 / 9,
  });

  const showUnsplash = !bannerImage?.url;

  const handleSelectedImage = img => {
    const image = {
      src: img.src,
      cropImage: img.urls.regular,
      rawImage: img.urls.raw,
      downloadLocation: img.links.download_location,
      isUnsplashImage: true,
      userName: img.userName,
    };
    setUncroppedImage(image);
    setCropOpen(true);
  };

  const onRemove = bannerImage?.url ? () => setImage(null) : null;
  const backgroundColor = bannerImage?.url ? 'transparent' : primaryColor;

  return (
    <>
      <Typography variant="h5" className={classes.bannerHeading}>
        {t('mobileSetup.branding.bannerHeading')}
      </Typography>
      <Typography variant="body1" className={classes.bannerDescription}>
        {t('mobileSetup.branding.bannerDescription')}
      </Typography>
      <Grid container alignItems="center">
        <ImageContainer
          imageUrl={bannerImage?.url ?? defaultBannerImageUrl}
          backgroundColor={backgroundColor}
          onRemove={onRemove}
          classes={{
            root: classes.imageBox,
            removeImageButton: classes.removeImageButton,
          }}
        />
        <FileInput
          onChange={onUserUploadImage}
          id="bannerimage"
          name="bannerimage"
          icon={<UploadIcon className={classes.uploadIcon} />}
          label={t('mobileSetup.branding.uploadImageButtonLabel')}
          accept="image/x-png,image/jpeg"
          value=""
          loading={fileInputIsLoading}
          disabled={fileInputIsLoading}
        />
      </Grid>
      {uploadImageError && (
        <Grid container>
          <Grid item xs={12}>
            <InlineErrorMessage errorMessage={uploadImageError} />
          </Grid>
        </Grid>
      )}
      {showUnsplash && (
        <Unsplash
          onSelect={handleSelectedImage}
          selected={bannerImage}
          style={{ width: '100%' }}
        />
      )}
      {cropOpen && (
        <CropModal
          crop={crop}
          onCrop={(c, percentCrop) => setCrop(percentCrop)}
          open={cropOpen}
          image={uncroppedImage}
          onClose={() => setCropOpen(false)}
          onSave={cropSave}
          loadingUpdateImage={loadingUpdateImage}
        />
      )}
    </>
  );
}

BannerSection.propTypes = {
  classes: PropTypes.object.isRequired,
  pageId: PropTypes.string,
  bannerImage: PropTypes.shape({ url: PropTypes.string }),
  defaultBannerImageUrl: PropTypes.string,
  primaryColor: PropTypes.string.isRequired,
  setImage: PropTypes.func.isRequired,
};

BannerSection.defaultProps = {
  pageId: null,
  bannerImage: null,
  defaultBannerImageUrl: null,
};

export default withStyles(styles)(BannerSection);
