import { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import styles from './styles';
import ExampleModal from '../ExampleModal';

export function ExampleLink({ children, classes, image, ...rest }) {
  const [exampleOpen, setExampleOpen] = useState(false);

  return (
    <>
      <Button
        className={classes.button}
        color="primary"
        disableRipple
        onClick={() => setExampleOpen(true)}
        {...rest}
      >
        {children}
      </Button>
      <ExampleModal
        image={image}
        open={exampleOpen}
        onClose={() => setExampleOpen(false)}
      />
    </>
  );
}

ExampleLink.propTypes = {
  image: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(ExampleLink);
