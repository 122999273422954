import {
  OPEN_NEWTRANSACTION,
  CLOSE_NEWTRANSACTION,
  OPEN_AI_ASSIST,
  CLOSE_AI_ASSIST,
  DISMISS_AI_OVERWRITE_WARNING,
} from './types';

const INITIAL_STATE = {
  modalsOpen: [],
  showEmailAIAssistantWarning: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case OPEN_NEWTRANSACTION:
      return {
        ...state,
        modalsOpen: [...state.modalsOpen, OPEN_NEWTRANSACTION],
      };
    case CLOSE_NEWTRANSACTION:
      return {
        ...state,
        modalsOpen: state.modalsOpen.filter(
          (modalType) => modalType !== OPEN_NEWTRANSACTION,
        ),
      };
    case OPEN_AI_ASSIST:
      return {
        ...state,
        modalsOpen: [...state.modalsOpen, OPEN_AI_ASSIST],
        showEmailAIAssistantWarning: action.showEmailAIAssistantWarning,
      };
    case CLOSE_AI_ASSIST:
      return {
        ...state,
        modalsOpen: state.modalsOpen.filter(
          (modalType) => modalType !== OPEN_AI_ASSIST,
        ),
      };
    case DISMISS_AI_OVERWRITE_WARNING:
      return {
        ...state,
        showEmailAIAssistantWarning: false,
      };
    default:
      return state;
  }
};
