import { forwardRef } from 'react';
import { Button, Divider, Grid, withStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TILE_TYPE_GIFT } from '../../../../globals/constants';
import createSchema from './Validation/AddDonationValidationUtils';
import createSchemaPayment from './Validation/PaymentFixedAmountUtils';
import styles from './styles';
import PreventLeavePageModal from '../../PreventLeavePageModal';

const AddDonationForm = forwardRef(
  (
    { classes, renderTransactionItem, handleSubmit, transactionType, isDirty },
    ref,
  ) => {
    const { t } = useTranslation();

    function getValidationSchema() {
      if (transactionType === TILE_TYPE_GIFT) {
        return createSchema();
      }
      return createSchemaPayment();
    }

    return (
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={{
          fundId: '',
          amount: '',
          frequency: '',
          memoLine: '',
          startDate: null,
          endDate: null,
          showTextField: false,
        }}
        validationSchema={getValidationSchema()}
        innerRef={ref}
      >
        {(formikProps) => {
          return (
            <Form className={classes.form}>
              {renderTransactionItem(formikProps)}
              <Grid container justifyContent="center">
                <Button
                  type="submit"
                  variant="outlined"
                  className={classes.submit}
                >
                  {t('button.add')}
                </Button>
              </Grid>
              <Divider className={classes.spaceDivider} variant="fullWidth" />
              <PreventLeavePageModal
                shouldOpenOnLeave={formikProps.dirty || isDirty}
              />
            </Form>
          );
        }}
      </Formik>
    );
  },
);

AddDonationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  renderTransactionItem: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  transactionType: PropTypes.string.isRequired,
  isDirty: PropTypes.bool.isRequired,
};

export default withStyles(styles)(AddDonationForm);
