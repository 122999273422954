import BaseService from '../BaseService';

class InternalService extends BaseService {
  setSession(orgId) {
    return this.http.put(`users/session`, {
      data: {
        organization: { id: orgId },
      },
    });
  }

  patchOrganization = (orgId, values) => {
    const patchValues = [];
    Object.keys(values).forEach((key) => {
      patchValues.push(this.patchHelper(key, values[key]));
    });
    return this.http.patch(`admin/organization/${orgId}`, patchValues);
  };
}

export default new InternalService();
