// CREATE page
export const CREATE_PAGE_START = 'CREATE_PAGE_START';
export const CREATE_PAGE_FINISH = 'CREATE_PAGE_FINISH';
export const CREATE_PAGE_ERROR = 'CREATE_PAGE_ERROR';

// GET pages
export const GET_PAGES_START = 'GET_PAGES_START';
export const GET_PAGES_FINISH = 'GET_PAGES_FINISH';
export const GET_PAGES_ERROR = 'GET_PAGES_ERROR';

// Clear pages
export const CLEAR_PAGES = 'CLEAR_PAGES';

// GET page
export const GET_PAGE_START = 'GET_PAGE_START';
export const GET_PAGE_FINISH = 'GET_PAGE_FINISH';
export const GET_PAGE_ERROR = 'GET_PAGE_ERROR';

// SAVE page
export const SAVE_PAGE_START = 'SAVE_PAGE_START';
export const SAVE_PAGE_FINISH = 'SAVE_PAGE_FINISH';
export const SAVE_PAGE_ERROR = 'SAVE_PAGE_ERROR';

// GET current page
export const GET_CURRENT_PAGE_START = 'GET_CURRENT_PAGE_START';
export const GET_CURRENT_PAGE_FINISH = 'GET_CURRENT_PAGE_FINISH';
export const GET_CURRENT_PAGE_ERROR = 'GET_CURRENT_PAGE_ERROR';

// RESET page
export const RESET_NEW_PAGE = 'RESET_NEW_PAGE';

// CURRENT page
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

// UPDATE page
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_ADDRESS_REQUIRED_START = 'UPDATE_ADDRESS_REQUIRED_START';
export const UPDATE_ADDRESS_REQUIRED_FINISH = 'UPDATE_ADDRESS_REQUIRED_FINISH';
export const UPDATE_ADDRESS_REQUIRED_ERROR = 'UPDATE_ADDRESS_REQUIRED_ERROR';

export const PATCH_LOCATION_START = 'PATCH_LOCATION_START';
export const PATCH_LOCATION_FINISH = 'PATCH_LOCATION_FINISH';
export const PATCH_LOCATION_ERROR = 'PATCH_LOCATION_ERROR';

export const SET_NEW_MOBILE_PAGE_MODAL_OPEN = 'SET_NEW_MOBILE_PAGE_MODAL_OPEN';
export const SET_PAGE_COMMUNITY_FEATURES_CHOOSEN =
  'SET_PAGE_COMMUNITY_FEATURES_CHOOSEN';

// REMOVE page
export const REMOVE_PAGE = 'REMOVE_PAGE';

// STREAMER
export const SET_STREAMER_ID = 'SET_STREAMER_ID';
export const CREATE_STREAMER_SETTINGS_START = 'CREATE_STREAMER_SETTINGS_START';
export const CREATE_STREAMER_SETTINGS_FINISH =
  'CREATE_STREAMER_SETTINGS_FINISH';
export const CREATE_STREAMER_SETTINGS_ERROR = 'CREATE_STREAMER_SETTINGS_ERROR';
export const UPDATE_STREAMER_SETTINGS_START = 'UPDATE_STREAMER_SETTINGS_START';
export const UPDATE_STREAMER_SETTINGS_FINISH =
  'UPDATE_STREAMER_SETTINGS_FINISH';
export const UPDATE_STREAMER_SETTINGS_ERROR = 'UPDATE_STREAMER_SETTINGS_ERROR';
