import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useGetPage } from '../useGetPage/useGetPage';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';
import VancoMobileEventsService from '../../../api/VancoMobileEventsService';
import { handleException } from '../../../store/alerts/actions';
import backendToFrontend from '../../transforms/mobileEvents';

async function getMobileEvents() {
  const response = await VancoMobileEventsService.getEvents();
  return backendToFrontend(response.data.data);
}

export function useGetMobilePageEvents(config = {}) {
  const dispatch = useDispatch();
  const { page, isLoading } = useGetPage(CHANNEL_TYPE.MOBILE, PAGE_ACTIVE);
  const {
    mobileOrgId,
    events,
    status,
    isLoading: eventsIsLoading,
    ...rest
  } = useQuery({
    queryKey: ['mobile-events', page.id],
    queryFn: getMobileEvents,
    enabled: !!page.id,
    onError: error => {
      dispatch(handleException(error));
    },
    ...config,
  });

  return {
    ...rest,
    events,
    isLoading: isLoading || eventsIsLoading,
    status,
  };
}

export default useGetMobilePageEvents;
