import { useEffect } from 'react';
import { FastField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import styles from './styles';

function UserInformationField({ classes }) {
  const { validateForm } = useFormikContext();
  const { t } = useTranslation();
  const selectedPaymentType = useSelector(
    ({ transaction }) => transaction.selectedPaymentType,
  );

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <>
      <span className={classes.formItemGroup}>
        <FastField
          name={`${selectedPaymentType}.firstName`}
          component={TextField}
          autoComplete="section-payment first-name"
          id="card-first-name"
          inputProps={{ inputMode: 'text' }}
          className={classes.flexFormItem}
          label={t('transactions.billingInfo.firstName')}
          variant="outlined"
          data-testid="firstName-field"
          disabled={false}
        />
        <FastField
          name={`${selectedPaymentType}.lastName`}
          component={TextField}
          autoComplete="section-payment last-name"
          id="card-last-name"
          inputProps={{ inputMode: 'text' }}
          className={classes.secondFormItem}
          label={t('transactions.billingInfo.lastName')}
          variant="outlined"
          data-testid="lastName-field"
          disabled={false}
        />
      </span>
      <span className={classes.formItemGroup}>
        <FastField
          name={`${selectedPaymentType}.email`}
          component={TextField}
          autoComplete="section-payment email"
          id="email"
          inputProps={{ inputMode: 'email' }}
          className={classes.flexFormItem}
          label={t('transactions.billingInfo.email')}
          variant="outlined"
          data-testid="email-field"
          disabled={false}
        />
      </span>
    </>
  );
}

UserInformationField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserInformationField);
