import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageContainer from '../../ImageContainer';
import useGetOrgSettings from '../../../../utils/hooks/useGetOrgSettings';
import PageTitle from '../../PageTitle';

import styles from './styles';
import { getPhone, getOrganizationInitials } from '../../../../utils/page';
import organizationPageNavConfig from '../../../navigation/WizardLayout/organization.page.config';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import { useFunds } from '../../../../utils/hooks';

export function Review({
  organizationId,
  saveOrganizationDefaults,
  getPages,
  history,
  goToPrev,
  goToNext,
  classes,
  isEditor,
  promptToCompleteOrganizationSettings,
  disablePromptToCompleteOrganizationSettings,
  features,
}) {
  const { t } = useTranslation();
  const { settings, loadingOrgSettings, savingOrgSettings } =
    useGetOrgSettings(organizationId);
  const { data: funds } = useFunds(organizationId);
  const currentFundName =
    funds && settings.fund
      ? funds.find((f) => f.fundRefId === settings.fund.fundRefId)?.name
      : '';
  const orgSettingsLoaded =
    loadingOrgSettings !== undefined && !loadingOrgSettings;
  const hasAddress = settings.address && settings.address.length;
  const settingsCreated = !!settings.organizationId;
  const settingsCompleted =
    !!settingsCreated &&
    !!settings.primaryColor &&
    settings.hasPaymentMethods &&
    settings.defaultsComplete;

  const continueWizard = useCallback(() => {
    if (!settingsCreated) {
      history.replace('/organization/wizard/contact');
    } else {
      const config = organizationPageNavConfig(settings);
      const nextPage = config.find((step) => step.next);
      history.push(nextPage.path);
    }
  }, [settingsCreated, history, settings]);

  useEffect(() => {
    if (
      isEditor &&
      (promptToCompleteOrganizationSettings ||
        (orgSettingsLoaded && !settingsCompleted))
    ) {
      continueWizard();
    }
  }, [
    promptToCompleteOrganizationSettings,
    settingsCompleted,
    isEditor,
    orgSettingsLoaded,
    continueWizard,
  ]);

  const getAddress = () => {
    const { address, address2, city, state, zip } = settings;
    if (!hasAddress) return '';
    if (address2) {
      return `${address}
    ${address2}
    ${city}, ${state} ${zip}`;
    }

    return `${address}
    ${city}, ${state} ${zip}`;
  };

  const handleSubmit = async () => {
    // Avoid potential issues if the user navigates back and clicks submit again.
    if (!settings.defaultsComplete) {
      await saveOrganizationDefaults(organizationId);
    }
    await disablePromptToCompleteOrganizationSettings();
    await getPages();
    goToNext();
  };

  function getNotProvided() {
    return (
      <Typography className={classes.notProvided}>
        {t('organization.review.notProvided')}
      </Typography>
    );
  }

  const buildRedirectPath = (page) =>
    isEditor ? `/organization/${page}` : `/organization/wizard/${page}`;

  return (
    settings && (
      <div className={classes.container}>
        {!isEditor && (
          <>
            <PageTitle className={classes.title}>
              {t('organization.review.title')}
            </PageTitle>
            <section className={classes.noteSection}>
              <Typography variant="body2">
                {t('organization.review.note1')}
              </Typography>
              <Typography>{t('organization.review.note2')}</Typography>
            </section>
          </>
        )}

        {/* Basic Info */}
        <Grid container className={classes.grid}>
          <Grid item md={11} sm={11} xs={11}>
            <Typography className={classes.gridTitle}>
              {t('organization.review.contact')}
            </Typography>
          </Grid>
          <Grid item md={1} sm={1} xs={1}>
            <Button
              color="primary"
              onClick={() => history.push(buildRedirectPath('contact'))}
            >
              {t('organization.review.edit')}
            </Button>
          </Grid>
          <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
            <Typography variant="body2" className={classes.body2}>
              {t('organization.review.name')}
            </Typography>
          </Grid>
          <Grid item md={8} sm={12} xs={12} className={classes.gridRow}>
            <Typography className={classes.body}>{settings.dbaName}</Typography>
          </Grid>
          <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
            <Typography variant="body2" className={classes.body2}>
              {t('organization.review.address')}
            </Typography>
          </Grid>
          <Grid item md={8} sm={12} xs={12} className={classes.gridRow}>
            {!!getAddress().length && (
              <Typography className={classes.address}>
                {getAddress()}
              </Typography>
            )}
            {!getAddress().length && getNotProvided()}
          </Grid>
          <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
            <Typography variant="body2" className={classes.body2}>
              {t('organization.review.phone')}
            </Typography>
          </Grid>
          <Grid item md={8} sm={12} xs={12} className={classes.gridRow}>
            {settings.phone && (
              <Typography className={classes.body}>
                {getPhone(settings)}
              </Typography>
            )}
            {(!settings.phone || settings.phone.length < 1) && getNotProvided()}
          </Grid>
          <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
            <Typography variant="body2" className={classes.body2}>
              {t('organization.review.email')}
            </Typography>
          </Grid>
          <Grid item md={8} sm={12} xs={12} className={classes.gridRow}>
            {!!settings.email?.length && (
              <Typography className={classes.body}>{settings.email}</Typography>
            )}
            {!settings.email?.length && getNotProvided()}
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Typography variant="body2" className={classes.body2}>
              {t('organization.review.websiteUrl')}
            </Typography>
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            {!!settings.website?.length && (
              <Typography className={classes.body}>
                {settings.website}
              </Typography>
            )}
            {!settings.website?.length && getNotProvided()}
          </Grid>
        </Grid>

        {/* Appearance */}
        <Grid container className={classes.grid}>
          <Grid item md={11} sm={11} xs={11}>
            <Typography className={classes.gridTitle}>
              {t('organization.review.branding')}
            </Typography>
          </Grid>
          <Grid item md={1} sm={1} xs={1}>
            <Button
              color="primary"
              onClick={() => history.push(buildRedirectPath('branding'))}
            >
              {t('organization.review.edit')}
            </Button>
          </Grid>
          <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
            <Typography variant="body2" className={classes.body2}>
              {t('organization.branding.color.title')}
            </Typography>
          </Grid>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            className={`${classes.gridRow} ${classes.primaryColorContainer}`}
          >
            {settings.primaryColor ? (
              <>
                <div
                  className={classes.primaryColor}
                  style={{ backgroundColor: settings.primaryColor }}
                  data-testid="review-primary-color"
                />
                <Typography className={classes.body}>
                  {settings.primaryColor}
                </Typography>
              </>
            ) : (
              getNotProvided()
            )}
          </Grid>
          <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
            <Typography variant="body2" className={classes.body2}>
              {t('organization.branding.logo.title')}
            </Typography>
          </Grid>
          <Grid item md={8} sm={12} xs={12} className={classes.gridRow}>
            {settings.primaryColor ? (
              <ImageContainer
                imageUrl={settings.logoImage?.url}
                backgroundColor={settings.primaryColor}
              >
                <Typography
                  variant="body2"
                  className={classes.organizationInitials}
                >
                  {getOrganizationInitials(settings.dbaName)}
                </Typography>
              </ImageContainer>
            ) : (
              getNotProvided()
            )}
          </Grid>

          <Grid item md={4} sm={12} xs={12} className={classes.gridRow}>
            <Typography variant="body2" className={classes.body2}>
              {t('organization.branding.banner.title')}
            </Typography>
          </Grid>
          <Grid item md={8} sm={12} xs={12} className={classes.gridRow}>
            {settings.primaryColor ? (
              <ImageContainer
                imageUrl={
                  settings.bannerImage?.url ?? settings.defaultBannerImageUrl
                }
                backgroundColor={settings.primaryColor}
                classes={{
                  root: classes.imageBox,
                }}
              />
            ) : (
              getNotProvided()
            )}
          </Grid>
        </Grid>

        {/* Processing Fees */}
        <Grid container className={classes.grid}>
          <Grid item md={11} sm={11} xs={11}>
            <Typography className={classes.gridTitle}>
              {t('organization.review.processingFees')}
            </Typography>
          </Grid>
          <Grid item md={1} sm={1} xs={1}>
            <Button
              color="primary"
              onClick={() => history.push(buildRedirectPath('fees'))}
            >
              {t('organization.review.edit')}
            </Button>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Typography variant="body2" className={classes.body2}>
              {t('organization.review.acceptSupport')}
            </Typography>
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Typography className={classes.body}>
              {settings.enableProcessingFees ? t('app.yes') : t('app.no')}
            </Typography>
          </Grid>
          {settings.enableProcessingFees && (
            <>
              <Grid item md={4} sm={12} xs={12} />
              <Grid item md={8} sm={12} xs={12}>
                <Typography>
                  {settings.applyFeeByDefault
                    ? t('organization.fees.preselected.yes.summary')
                    : t('organization.fees.preselected.no.summary')}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} />
              <Grid item md={8} sm={12} xs={12}>
                <Typography>{`${settings.creditPercentage}% for card transactions`}</Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} />
              <Grid item md={8} sm={12} xs={12}>
                <Typography>{`${settings.checkPercentage}% for bank transactions`}</Typography>
              </Grid>
              {features.Offsets && settings.fund && (
                <>
                  <Grid item md={4} sm={12} xs={12} />
                  <Grid item md={8} sm={12} xs={12}>
                    <Typography>{`${t(
                      'organization.review.offset',
                    )} ${currentFundName}`}</Typography>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>

        {goToNext && !loadingOrgSettings && !savingOrgSettings && (
          <WizardButtons
            isFinished={false}
            dirty={false}
            goToNext={handleSubmit}
            goToPrev={goToPrev}
            undo={() => {}}
            submitText={t('button.done')}
          />
        )}
        {goToNext && (loadingOrgSettings || savingOrgSettings) && (
          <div className={classes.spinnerWizard}>
            <CircularProgress color="primary" />
          </div>
        )}
      </div>
    )
  );
}

Review.propTypes = {
  classes: PropTypes.object.isRequired,
  saveOrganizationDefaults: PropTypes.func.isRequired,
  getPages: PropTypes.func.isRequired,
  goToNext: PropTypes.func,
  goToPrev: PropTypes.func,
  history: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  isEditor: PropTypes.bool,
  promptToCompleteOrganizationSettings: PropTypes.bool.isRequired,
  disablePromptToCompleteOrganizationSettings: PropTypes.func.isRequired,
  features: PropTypes.objectOf(PropTypes.bool).isRequired,
};

Review.defaultProps = {
  isEditor: false,
  goToNext: undefined,
  goToPrev: undefined,
};

export default withStyles(styles)(withRouter(Review));
