import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTransactionData } from '../../../store/transaction/actions';
import NewTransaction from './NewTransaction';

export function mapStateToProps(state) {
  return {
    loadingCreate: state.transaction.loadingCreate,
    createPageError: state.pages.error,
    loadingData: state.transaction.loadingData,
    hasLoadedData: state.transaction.hasLoadedData,
    hasData: !!state.transaction.pcct,
    hasLoadedPages: state.pages.hasLoadedPages,
    features: state.features?.features,
    loc: state.location,
    selectedPaymentType: state.transaction.selectedPaymentType,
    paymentMethods: state.transaction?.paymentMethods ?? {},
  };
}

export const mapDispatchToProps = (dispatch) => ({
  transactionActions: bindActionCreators({ getTransactionData }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTransaction);
