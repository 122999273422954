import * as Yup from 'yup';

import { CUSTOM_TEXT } from '../../../globals/constants';
import i18n from '../../../utils/i18n';

export const maxLengths = {
  shortDescription: 200,
  customButtonText: 30,
  customHeaderText: 50,
};

export const createSchema = () => {
  return Yup.object().shape({
    shortDescription: Yup.string().max(
      maxLengths.shortDescription,
      i18n.t('validation.long'),
    ),
    buttonText: Yup.string().required(
      i18n.t('validation.required', {
        requiredNoun: i18n.t('cta.label1').toLocaleLowerCase(),
        requiredVerb: i18n.t('word.select'),
      }),
    ),
    customButtonText: Yup.string().when('buttonText', {
      is: CUSTOM_TEXT,
      then: (s) =>
        s
          .required(
            i18n.t('validation.pleaseEnter', {
              requiredNoun: i18n.t('cta.label2').toLocaleLowerCase(),
            }),
          )
          .max(maxLengths.customButtonText, i18n.t('validation.long')),
    }),
  });
};
