import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';

import FileInput from '../../FileInput';

import styles from './styles';

export function SelectedImage({
  classes,
  url,
  onRemove,
  onUserUploadImage,
  inputFileValue,
  fileInputIsLoading,
  uploadImageError,
}) {
  const { t } = useTranslation();
  const [image, setImage] = useState({});
  const [imageStatus, setImageStatus] = useState('loading');

  function handleImageLoaded() {
    setImageStatus('loaded');
  }

  function handleImageErrored() {
    setImageStatus('failed');
  }

  useEffect(() => {
    const img = new Image();
    img.src = url;
    img.onload = handleImageLoaded;
    img.onerror = handleImageErrored;
    setImage(img);
  }, [url]);

  function renderLoading() {
    if (imageStatus === 'loading') {
      return <CircularProgress color="primary" />;
    }
    return null;
  }

  function renderRemoveIcon() {
    if (onRemove)
      return (
        <Tooltip title={t('imageSelection.removeImage')} placement="top">
          <IconButton
            key="close"
            aria-label="close"
            color="secondary"
            className={classes.removeButton}
            onClick={onRemove}
            data-testid="remove-button"
          >
            <CancelIcon className={classes.removeIcon} />
          </IconButton>
        </Tooltip>
      );
    return null;
  }

  return (
    <section className={classes.baseStyle}>
      <div className={classes.container}>
        {!url && (
          <div
            className={
              uploadImageError ? classes.contentError : classes.content
            }
          >
            <FileInput
              onChange={onUserUploadImage}
              id="image"
              name="image"
              icon={<PhotoLibraryOutlinedIcon className={classes.photoIcon} />}
              label={t('imageSelection.uploadImage')}
              accept="image/png,image/gif,image/jpeg"
              value={inputFileValue}
              loading={fileInputIsLoading}
              disabled={fileInputIsLoading}
            />
            <Typography className={classes.text}>
              {t('imageSelection.validExtensions')}
            </Typography>
            {uploadImageError && (
              <Typography className={classes.textError}>
                {uploadImageError}
              </Typography>
            )}
          </div>
        )}
        {url && (
          <aside className={classes.content}>
            <div
              className={classes.image}
              style={{ backgroundImage: `url(${image.src})` }}
            >
              {renderRemoveIcon()}
              {renderLoading()}
            </div>
          </aside>
        )}
      </div>
    </section>
  );
}

SelectedImage.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string,
  onRemove: PropTypes.func,
  onUserUploadImage: PropTypes.func,
  inputFileValue: PropTypes.string,
  fileInputIsLoading: PropTypes.bool,
  uploadImageError: PropTypes.string,
};

SelectedImage.defaultProps = {
  onRemove: null,
  onUserUploadImage: null,
  url: null,
  inputFileValue: '',
  fileInputIsLoading: false,
  uploadImageError: null,
};

export default withStyles(styles)(SelectedImage);
