export const GET_TRANSACTION_DATA_START = 'GET_TRANSACTION_DATA_START';
export const GET_TRANSACTION_DATA_FINISH = 'GET_TRANSACTION_DATA_FINISH';
export const GET_TRANSACTION_DATA_ERROR = 'GET_TRANSACTION_DATA_ERROR';

export const SET_IS_PROCESSING_TRANSACTIONS = 'SET_IS_PROCESSING_TRANSACTIONS';
export const PROCESS_TRANSACTIONS_FINISH = 'PROCESS_TRANSACTIONS_FINISH';

export const SET_HEXEA_OBJECT = 'SET_HEXEA_OBJECT';
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
export const UPDATE_BASKET = 'UPDATE_BASKET';
export const UPDATE_TRANSACTION_FEES = 'UPDATE_TRANSACTION_FEES';

export const CLEAN_UP_TRANSACTIONS = 'CLEAN_UP_TRANSACTIONS';
