import { connect } from 'react-redux';

import PrivateRoute from './PrivateRoute';

export function mapStateToProps(state) {
  return {
    isLoggedIn: state.user.isLoggedIn,
    loadingAuth: state.user.loadingAuth,
    grantedActions: state.user.user.grantedActions,
  };
}

export default connect(
  mapStateToProps,
  {},
)(PrivateRoute);
