const title = {
  flexGrow: 1,
  fontWeight: 'bold',
  textAlign: 'center',
  alignSelf: 'center',
};

export default function styles() {
  return {
    dialogPaper: {
      width: '100%',
      maxWidth: '548px',
      borderRadius: '8px',
      boxShadow:
        '0px 1px 3px 0px rgba(60,64,67,0.3), 0px 4px 8px 3px rgba(60,64,67,0.15)',
    },
    titleContainer: {
      alignItems: 'center',
      height: 64,
      paddingTop: 18,
      borderBottom: '1px solid #EAEAEA',
      display: 'flex',
      marginBottom: 10,
    },
    title,
    titleWithPadding: {
      ...title,
      paddingLeft: 40,
    },
    titleIcon: {
      height: 40,
      position: 'relative',
      width: 40,
      '& svg': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    closeIcon: {},
    content: {
      fontSize: '1.6rem',
      padding: '20px 40px 0px 40px',
    },
    actions: {
      borderTop: '1px solid #EAEAEA',
      padding: '25px 40px',
    },
  };
}
