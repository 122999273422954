/* eslint-disable import/prefer-default-export */
import { SECTIONS_TYPE } from 'globals/constants';
import { SECTION_PERMISSIONS } from 'globals/grants';

export const DEFAULT_SETTINGS_PERMISSION = {
  sectionId: SECTIONS_TYPE.Settings,
  key: SECTION_PERMISSIONS.SETTINGS.ROOT,
  label: 'Settings',
  value: true,
};

export const hasSettingsPermission = (permissions) => {
  if (!Array.isArray(permissions) || permissions.length === 0) {
    return false;
  }

  return permissions.some((p) => p.key === SECTION_PERMISSIONS.SETTINGS.ROOT);
};
