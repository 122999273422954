export default theme => ({
  pagePaper: {
    padding: 24,
    maxWidth: 660,
    width: '100%',
    marginTop: 15,
    borderRadius: 12,
  },
  icon: {
    color: '#666666',
    marginRight: 8,
  },
  profileImage: {
    borderRadius: 8,
    height: 132,
    width: 132,
    display: 'inline-flex',
    flexShrink: 0,
    marginRight: 16,
  },
  detailsLabel: {
    marginBottom: 20,
    float: 'left',
  },
  imagePlaceholder: {
    background: theme.palette.primary.main,
    justifyContent: 'center',
  },
  initials: {
    color: 'white',
    fontSize: 60,
    alignSelf: 'center',
  },
  divider: {
    marginTop: 16,
  },
  blockIcon: {
    marginRight: 8,
    width: 20,
    height: 20,
  },
  moderatorIcon: {
    marginRight: 8,
  },
  button: {
    color: theme.palette.primary.main,
    marginRight: 6,
    '&:first-of-type': {
      marginRight: 42,
    },
  },
  disclosure: {
    marginTop: 18,
    marginBottom: -8,
  },
  unsuspendButton: {
    alignSelf: 'flex-start',
    backgroundColor: '#FFF',
    margin: '16px 0 0 28px',
    [theme.breakpoints.down('xs')]: {
      margin: '8px 0 0 0',
    },
  },
  unsuspendButtonIcon: {
    marginRight: '10px',
    width: 20,
    height: 20,
  },
  actionButtons: {
    float: 'right',
    marginTop: 26,
    [theme.breakpoints.down('xs')]: {
      float: 'none',
    },
  },
  inline: {
    display: 'inline-flex',
  },
  title: {
    display: 'flow-root',
  },
  badge: {
    float: 'right',
  },
});
