import {
  Divider,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
  FormLabel,
} from '@material-ui/core';
import { forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import { DatePicker } from '..';
import styles from './styles';

const FeatureEnablingForm = forwardRef(
  (
    {
      classes,
      enabledText,
      disabledText,
      dateHelperText,
      onEnable,
      onCancel = () => {},
      enabled = false,
      date = null,
      id,
      label = undefined,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const formikRef = useRef();
    const currentDate = moment().startOf('day');
    const resetForm = () => {
      formikRef.current?.resetForm();
      onCancel();
    };
    return (
      <Formik
        enableReinitialize
        onSubmit={async (values) => {
          if (onEnable) {
            await onEnable({
              featureId: values.featureId,
              disableDate: values.featureEnabled
                ? null
                : values.disableDate || null,
            });
          }
        }}
        ref={ref}
        innerRef={formikRef}
        initialValues={{
          featureId: id,
          featureEnabled: enabled,
          disableDate: date ? moment(date) : null,
        }}
      >
        {(formikProps) => {
          return (
            <>
              {label && (
                <FormLabel>
                  <Typography>{label}</Typography>
                </FormLabel>
              )}
              <RadioGroup name="featureEnabled">
                <FormControlLabel
                  className={classes.radioButton}
                  control={
                    <Radio
                      color="primary"
                      checked={formikProps.values.featureEnabled}
                      onChange={() => {
                        formikProps.setFieldValue('featureEnabled', true);
                      }}
                    />
                  }
                  label={enabledText}
                />
                <FormControlLabel
                  className={classes.radioButton}
                  classes={{
                    root: classes.disableRadio,
                    label: classes.disableRadioLabel,
                  }}
                  control={
                    <Radio
                      color="primary"
                      className={classes.radioIcon}
                      checked={!formikProps.values.featureEnabled}
                      onChange={() => {
                        formikProps.setFieldValue('featureEnabled', false);
                      }}
                    />
                  }
                  labelPlacement="end"
                  label={
                    <>
                      <Typography className={classes.labelHeight}>
                        {disabledText}
                      </Typography>

                      {!formikProps.values.featureEnabled && (
                        <>
                          <Typography className={classes.dateDescription}>
                            {dateHelperText}
                          </Typography>
                          <FormControl fullWidth className={classes.datePicker}>
                            <Field
                              item
                              id="disableDate"
                              name="disableDate"
                              value={formikProps.values.disabledDate}
                              earliestAllowedDate={currentDate}
                              handleDateChange={(name, value) => {
                                formikProps.setFieldValue(name, value);
                              }}
                              hasError={formikProps.errors.disabledDate}
                              component={DatePicker}
                              position="relative"
                            />
                          </FormControl>
                        </>
                      )}
                    </>
                  }
                />
              </RadioGroup>
              <Divider className={classes.bottomDivider} />
              <span className={classes.actionButtonGroup}>
                <Button size="medium" variant="outlined" onClick={resetForm}>
                  {t('kiosk.setup.cancel')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={formikProps.isSubmitting}
                  onClick={formikProps.handleSubmit}
                >
                  {t('kiosk.setup.save')}
                </Button>
              </span>
            </>
          );
        }}
      </Formik>
    );
  },
);

FeatureEnablingForm.propTypes = {
  classes: PropTypes.object.isRequired,
  enabledText: PropTypes.string.isRequired,
  disabledText: PropTypes.string.isRequired,
  dateHelperText: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onEnable: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  onCancel: PropTypes.func,
  enabled: PropTypes.bool.isRequired,
  date: PropTypes.oneOfType([PropTypes.objectOf(moment), PropTypes.string]),
};

FeatureEnablingForm.defaultProps = {
  onCancel: () => {},
  date: null,
};
export default withStyles(styles)(FeatureEnablingForm);
