export default (theme) => ({
  basketItemRoot: {
    marginTop: '10px',
    marginBottom: '15px',
  },
  itemHeader: {
    lineHeight: '1.6',
    letterSpacing: '0.3px',
    marginRight: '-8px',
  },
  itemButtonContainer: {
    display: 'flex',
  },
  itemHeaderLink: {
    color: theme.palette.secondary.medium,
  },
  processingFee: {
    color: theme.palette.primary.main,
  },
  itemText: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '1.8',
    letterSpacing: '0.3px',
  },
  quantitySelect: {
    marginLeft: '5px',
    textAlign: 'right',
  },
  leftMemo: {
    textAlign: 'left',
    maxWidth: '50%',
  },
  rightMemo: {
    textAlign: 'right',
    maxWidth: '50%',
  },
});
