import { useEffect } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import { Page } from '../../common';
import styles from './styles';

function Logout({ postLogout }) {
  useEffect(() => {
    postLogout();
  }, [postLogout]);

  return (
    <Page>
      <CircularProgress color="primary" />
    </Page>
  );
}

Logout.propTypes = {
  postLogout: PropTypes.func.isRequired,
};

Logout.defaultProps = {};

export default withStyles(styles)(Logout);
