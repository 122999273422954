/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { object, func, string, objectOf, bool } from 'prop-types';
import { withStyles, MenuItem, Select } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import styles from './styles';

function TimePicker({
  classes,
  className,
  values,
  touched,
  errors,
  setFieldValue,
  handleBlur,
  inputPlaceholderText,
  timeZoneVariableName,
  defaultTime,
  ...selectProps
}) {
  const { value: curTimeValue, ...selectPropsUpdated } = selectProps;
  const { name } = selectPropsUpdated;

  // value to be displayed
  const [timeValue, setTimeValue] = useState(curTimeValue);
  // use suggestive events - if conditions are met
  const isDefaultTimeValue = curTimeValue === -1 && defaultTime !== '';

  const [times, setTimes] = useState([
    {
      id: 0,
      hour: 0,
      minute: 0,
      isAm: true,
      formatted: '',
    },
  ]);

  const formatTime = s => {
    // eslint-disable-next-line no-nested-ternary
    const hour = `${s.hour === 0 ? 12 : s.hour > 12 ? s.hour - 12 : s.hour}`;
    const minute = `${s.minute === 0 ? '00' : s.minute} `;
    const amSetting = `${s.isAm ? 'AM' : 'PM'}`;

    return `${hour}:${minute}${amSetting}`;
  };

  useEffect(() => {
    const update = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 24; i++) {
      for (let x = 0; x < 60; x += 15) {
        const item = {
          id: +`${i}${x === 0 ? '00' : x}`,
          hour: i,
          minute: x,
          isAm: i < 12,
        };
        const formatted = formatTime(item);

        update.push({ ...item, formatted });
      }
    }

    setTimes(update);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ensure state is kept current
  useEffect(() => {
    setTimeValue(curTimeValue);
  }, [curTimeValue]);

  // sets the select's default value to the suggested value provided
  const handleOnOpen = e => {
    setTimeValue(defaultTime);
  };

  // modifies the select to display that nothing is selected
  const handleClose = () => {
    setTimeValue(-1);
  };

  const handleClick = e => {
    // click event can occur on a normal time element or the forced value one
    const curValue = e.target.dataset?.value
      ? e.target.dataset.value
      : e.target.value;
    // do nothing if the process has been disrupted or the time selected is not the targeted default time
    if (!isDefaultTimeValue || curValue !== defaultTime) {
      return;
    }

    // mock object of expected values to be processed by the on-change function
    const evt = { target: { name, value: curValue } };

    // invoke the onChange event manually - this will trigger formik to cause a re-render of the component with the updated value
    // this will also cause this process to be disconnected.
    const { onChange } = selectPropsUpdated;
    onChange(evt);
  };

  return (
    <Select
        className={className}
        classes={{ select: classes.selectFix }}
        value={timeValue}
        fullWidth
        {...selectPropsUpdated}
        onBlur={handleBlur}
        MenuProps={{ classes: { paper: classes.menuFix } }}
        onOpen={isDefaultTimeValue ? handleOnOpen : null}
        onClose={isDefaultTimeValue ? handleClose : null}
        onClick={isDefaultTimeValue ? handleClick : null}
        renderValue={e => {
          return (
            <span className={classes.iconSpanFix}>
              <AccessTime data-testid="timeIcon" />
              {e === -1 ? inputPlaceholderText : e}
            </span>
          );
        }}
      >
        <MenuItem key="" value={-1} className={classes.optionBorder}>
          {inputPlaceholderText}
        </MenuItem>

        {times.map((x, index) => (
          <MenuItem
            key={x.id}
            value={x.formatted}
            selected
            className={index !== times.length - 1 ? classes.optionBorder : null}
          >
            {x.formatted}
          </MenuItem>
        ))}
      </Select>
  );
}

TimePicker.propTypes = {
  classes: objectOf(string).isRequired,
  values: object.isRequired,
  touched: object.isRequired,
  errors: object.isRequired,
  setFieldValue: func.isRequired,
  handleBlur: func.isRequired,
  inputPlaceholderText: string,
  className: string,
  timeZoneVariableName: string,
  defaultTime: string,
};

TimePicker.defaultProps = {
  inputPlaceholderText: '',
  className: '',
  timeZoneVariableName: '',
  defaultTime: '',
};

export default withStyles(styles)(TimePicker);
