export default function styles(theme) {
  return {
    description: {
      marginTop: theme.spacing(1),
    },
    radioGroup: {
      marginTop: theme.spacing(2),
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 40,
      '& button': {
        '&:first-of-type': {
          marginRight: 5,
        },
        [theme.breakpoints.down('xs')]: {
          '&:first-of-type': {
            marginRight: 0,
          },
        },
      },
    },
  };
}
