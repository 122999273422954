import getParent from './getParent';

export default function getOpener(win: Window = window): Window | null {
  if (!win) {
    return null;
  }

  if (getParent(win)) {
    return null;
  }

  try {
    return win.opener;
  } catch (err) {
    return null;
  }
}
