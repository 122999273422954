export default function styles(theme) {
  return {
    radioGroupItem: {
      alignItems: 'baseline',
      marginRight: 0,
    },
    radioGroupLabel: {
      alignSelf: 'flex-start',
      width: '100%',
    },
    content: {
      padding: '14px 24px 24px',
    },
    marginFirstControl: {
      marginTop: theme.spacing(1),
    },
    marginRadio: {
      marginTop: '-9px',
    },
    actions: {
      padding: '20px 24px',
    },
  };
}
