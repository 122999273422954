import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import PrimaryButton from '../PrimaryButton';

export function LinkButton({
  history,
  staticContext,
  primary,
  to,
  children,
  ...rest
}) {
  const navigate = () => {
    history.push(to);
  };

  const ButtonType = primary ? PrimaryButton : Button;

  return (
    <ButtonType onClick={navigate} {...rest}>
      {children}
    </ButtonType>
  );
}

LinkButton.propTypes = {
  history: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
  primary: PropTypes.bool,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

LinkButton.defaultProps = {
  staticContext: null,
  primary: false,
};

export default withRouter(LinkButton);
