import { SECTION_PERMISSIONS } from 'globals/grants';

export const NOT_FOUND_ROUTE = '/not-found';
export const NOT_ALLOWED_ROUTE = '/not-allowed';

export const IGNORE_PATHS_LIST = [
  NOT_FOUND_ROUTE,
  NOT_ALLOWED_ROUTE,
  '/login',
  '/logout',
  '/internallogin',
  '/profile',
];

export const GIVING_READ_PERMISSIONS = [
  SECTION_PERMISSIONS.GIVING.KIOSK.READ,
  SECTION_PERMISSIONS.GIVING.MOBILE.READ,
  SECTION_PERMISSIONS.GIVING.SITES.READ,
  SECTION_PERMISSIONS.GIVING.TILES.READ,
  SECTION_PERMISSIONS.GIVING.TRANSACTIONS.READ,
];

export const DONORS_READ_PERMISSIONS = [
  SECTION_PERMISSIONS.DONORS.DONORS.READ,
  SECTION_PERMISSIONS.DONORS.TAGS.READ,
];

export const OUTREACH_READ_PERMISSIONS = [
  SECTION_PERMISSIONS.OUTREACH.CONTENT_MANAGER.READ,
  SECTION_PERMISSIONS.OUTREACH.EMAILS.READ,
  SECTION_PERMISSIONS.OUTREACH.SMS.READ,
  SECTION_PERMISSIONS.OUTREACH.TEMPLATES.READ,
  SECTION_PERMISSIONS.OUTREACH.WRITING_DESK.READ,
];

export const SMART_VIDEO_READ_PERMISSIONS = [
  SECTION_PERMISSIONS.SMART_VIDEO.LIVE_STREAMING.READ,
  SECTION_PERMISSIONS.SMART_VIDEO.SETTINGS.READ,
  SECTION_PERMISSIONS.SMART_VIDEO.SMART_CLIPS.READ,
  SECTION_PERMISSIONS.SMART_VIDEO.SMART_LIBRARY.READ,
];

export const REPORTING_READ_PERMISSIONS = [
  SECTION_PERMISSIONS.REPORTING.INSIGHTS.READ,
  SECTION_PERMISSIONS.REPORTING.REPORTS.READ,
];

export const SETTINGS_READ_PERMISSIONS = [
  SECTION_PERMISSIONS.SETTINGS.EMAIL_RECEIPTS.READ,
  SECTION_PERMISSIONS.SETTINGS.IDENTITY_PROVIDER.READ,
  SECTION_PERMISSIONS.SETTINGS.ORGANIZATION.READ,
  SECTION_PERMISSIONS.SETTINGS.PAID_FEATURES.READ,
  SECTION_PERMISSIONS.SETTINGS.USERS.READ,
];
