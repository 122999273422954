export default function styles() {
  return {
    title: {
      padding: '16px 24px',
      '& h6': {
        paddingLeft: 0,
        textAlign: 'left',
      },
    },
  };
}
