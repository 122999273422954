import * as globals from '../../globals/constants';

export function toCapitalized(word) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function getTransactionCount(basketItems) {
  return basketItems.reduce((sum, item) => {
    if (item.campaignDetails.type === globals.CAMPAIGN_TYPE.GIFT) {
      return sum + Object.keys(item.transactionData).length;
    }
    return (
      sum +
      Object.values(item.transactionData).reduce((total, t) => {
        return total + (t.quantity || 0);
      }, 0)
    );
  }, 0);
}

/**
 * Determines if the browser is IE11
 */
export function isIE11() {
  return !!window.MSInputMethodContext && !!document.documentMode;
}

export function hasScheduledTransactions(basketItems) {
  let isScheduled = false;
  basketItems.forEach((item) => {
    if (item.transactionData) {
      Object.keys(item.transactionData).forEach((transaction) => {
        if (
          !isScheduled &&
          item.transactionData[transaction].frequency !==
            globals.PAYMENT_FREQUENCY.ONE_TIME_NOW
        ) {
          isScheduled = true;
        }
      });
    }
  });
  return isScheduled;
}

export function getCurrencyFormat(amount) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}

/**
 * Returns true if two basketItems have same fundID, frequency and startDate.
 */
export function basketItemsMatch(basketItem1, basketItem2) {
  if (
    basketItem1.fundId === basketItem2.fundId &&
    basketItem1.frequency === basketItem2.frequency &&
    // In case the startDate is a Moment object instead of a string, call stringify on it
    JSON.stringify(basketItem1.startDate ?? '') ===
      JSON.stringify(basketItem2.startDate ?? '')
  ) {
    return true;
  }
  return false;
}

/**
 * Returns the transaction display name
 */
export function getTransactionDisplayName(transaction) {
  const { displayName, quantity } = transaction;

  if (!quantity) {
    return displayName;
  }

  return `${displayName} x ${quantity}`;
}

export default function formatPhoneNumber(phone) {
  if (phone === null) {
    return null;
  }
  // Some numbers start with +1 but we don't want to show the '+'
  const justTheDigits = (phone.match(/\d/g) ?? []).join('');
  // Important that this ends with a $ so that we only take the last 10 digits
  // and ignore any country code if it starts with one.
  const phoneRegex = /(?<areaCode>\d{3})(?<next3>\d{3})(?<last4>\d{4})$/g;
  const match = phoneRegex.exec(justTheDigits);
  if (!match) {
    // Fall back to just returning the raw string if we can't parse it for some reason
    return phone;
  }
  const { groups } = match;
  return `${groups.areaCode}-${groups.next3}-${groups.last4}`;
}

export function getStrippedPhoneNumber(phone) {
  return phone ? phone.replace(/\D/g, '').slice(-10) : undefined;
}

export function getCurrentPathNameWithParams() {
  let currentUrl = window.location.pathname;
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.forEach((key, value) => {
    currentUrl = `${currentUrl}?${value}=${key}`;
  });
  return currentUrl;
}

export function getSearchParamByKey(key) {
  const query = new URLSearchParams(window.location.search);
  return query.get(key);
}

export function filterFailedTransactions(formValues, transactions) {
  const newFormValues = {};
  const failedRecurrences = transactions
    .map((t) => {
      if (t.error?.failedTransactions) {
        return t.error.failedTransactions.map((ft) => ft.recurrenceId);
      }
      return null;
    })
    .filter((t) => t)
    .flat();

  failedRecurrences.forEach((fr) => {
    newFormValues[fr] = formValues[fr];
  });
  return newFormValues;
}

/**
 * The objective of the function is to determine the selected payment type based on the allowed payment methods and the user's selection.
 * @param {*} paymentMethods: an object containing information about the allowed payment methods (credit card and ACH) and whether they are allowed or not
 * @param {*} selectedPaymentType: a string representing the user's selected payment type (creditCard or ach)
 * @returns
 */
export function getSelectedPaymentType(paymentMethods, selectedPaymentType) {
  // None of the payment methods are allowed
  // const { isInWebview } = new RNWebViewHandler();
  const isCardAllowed = paymentMethods?.creditCard?.isAllowed;
  if (!isCardAllowed && !paymentMethods?.ach?.isAllowed) return null;

  // if (isCardAllowed && isInWebview && selectedPaymentType === globals.SWIPE) {
  //   return selectedPaymentType;
  // }

  if (paymentMethods?.[selectedPaymentType]?.isAllowed)
    return selectedPaymentType;

  return isCardAllowed ? globals.CREDIT_CARD : globals.ACH;
}

export function getFloatFromText(textValue, decimals = 0) {
  let floatNumber = null;
  if (textValue && !Number.isNaN(Number(textValue))) {
    const dotIndex = textValue.indexOf('.');
    const hasDecimals = dotIndex !== -1;
    floatNumber = parseFloat(
      hasDecimals ? textValue.substring(0, dotIndex + decimals + 1) : textValue,
    );
  }
  return floatNumber;
}

export function addFeesToAmount(amount = 0.0, processingFee = 0) {
  const parsedAmount = parseFloat(amount);
  return parsedAmount + parsedAmount * (processingFee / 100);
}
