import { send } from 'post-robot';
import postRobot9 from 'post-robot9/dist/post-robot.ie';
import getAncestor from './getAncestor';

export interface ResponseMessageEvent<T> {
  source: Window | null;
  origin: string;
  data: T;
}

let use9 = true;

export default async function sendToParent<T extends object = object>(
  name: string,
  data: object | undefined = undefined,
  options:
    | {
        domain: string;
      }
    | undefined = undefined
): Promise<ResponseMessageEvent<T>> {
  return new Promise((resolve, reject) => {
    let failed = 0;
    let remaining = 1;
    let resolved = false;
    send(getAncestor(), name, data, options)
      .then((r) => {
        if (resolved) return;
        resolved = true;
        console.log('post-robot: resolved using v10');
        resolve(r as ResponseMessageEvent<T>);
        use9 = false;
      })
      .catch((e) => {
        if (++failed === remaining) {
          reject(e);
        }
      });

    if (use9) {
      remaining++;
      postRobot9
        .sendToParent(name, data, options)
        .then((r) => {
          if (resolved) return;
          resolved = true;
          console.log('post-robot: resolved using v9');
          resolve(r);
        })
        .catch((e) => {
          if (++failed === remaining) {
            reject(e);
          }
        });
    }
  });
}
