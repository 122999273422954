export default function styles(theme) {
  return {
    container: {
      marginBottom: 32,
      backgroundColor: '#F5F5F7',
      display: 'flex',
      borderRadius: '8px',
      padding: '24px',
      '&.normal': {
        flexDirection: 'column',
      },
      '&.wide': {
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
        },
      },
    },
    headingContainer: { display: 'flex' },
    infoIcon: { alignSelf: 'center', marginRight: '14px' },
    titleText: {
      alignSelf: 'center',
    },
    descriptionText: {
      '&.normal': {
        margin: '16px 0 24px 0',
      },
      '&.wide': {
        alignSelf: 'center',
        margin: '0 8px 0 8px',
        [theme.breakpoints.down('xs')]: {
          alignSelf: 'start',
          margin: '16px 0 24px 0',
        },
      },
    },
    button: {
      alignSelf: 'flex-start',
      backgroundColor: '#FFF',
      // For some reason the default min-width (from MuiButton-root class) of 65px
      // allows button to shrink smaller than the text inside it
      minWidth: 'inherit',
      '&.wide': {
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
          marginLeft: 'inherit',
        },
      },
    },
    buttonIcon: { marginRight: '10px' },
  };
}
