import {
  getDisableDate,
  isSameOrBefore,
} from '../../utils/date/timeZoneHelper';
import { getApiErrorMessage } from '../../utils/errors';

const getGrantedActions = (grant, actions) => {
  return Object.entries(actions).reduce(
    (allowedActions, [actionKey, actionValue]) => {
      if (actionValue) {
        allowedActions.push(
          `${grant.toUpperCase()}_${actionKey.toUpperCase()}`,
        );
      }
      return allowedActions;
    },
    [],
  );
};

export const transformGrantedActions = (user) => {
  return Object.entries(user.grantedActions)
    .reduce((grantedActions, [grantKey, actions]) => {
      const allowedActions = getGrantedActions(grantKey, actions);
      return grantedActions.concat(allowedActions);
    }, [])
    .concat((user.accountPermissions || []).map((p) => p.toUpperCase()));
};

/* Backend user to a frontend representation of a user */
export const backendToFrontend = (user) => {
  const isInternal =
    user.requiredActions &&
    Array.isArray(user.requiredActions) &&
    user.requiredActions.filter((f) => {
      return f === 'AssociateTenant';
    }).length !== 0;
  const organization = user.associatedOrganizations.find(
    (o) => o.organizationId === user.organizationId,
  );

  const {
    streamingDisableDate,
    smartLibraryDisableDate,
    smartClipsDisableDate,
  } = organization ?? {};

  const disableStreamingDate = getDisableDate(streamingDisableDate);
  const disableSmartLibraryDate = getDisableDate(smartLibraryDisableDate);
  const disableSmartClipsDate = getDisableDate(smartClipsDisableDate);

  const transformed = {
    isInternal,
    wasInternal: isInternal,
    organizationId: user.organizationId,
    organizationName: organization ? organization.organizationName : null,
    grantedActions: transformGrantedActions(user),
    associatedOrganizations: user.associatedOrganizations,
    role: user.role,
    organizationStreamingDisableDate: disableStreamingDate,
    organizationStreamingDisabled: disableStreamingDate
      ? isSameOrBefore(disableStreamingDate)
      : false,
    lastLogin: user.lastLogin,
    organizationHasVOSite: organization ? organization.hasVOSite : false,
    organizationHasVMSite: organization ? organization.hasVMSite : false,
    promptToCompleteOrganizationSettings:
      user.promptToCompleteOrganizationSettings,
    streamerId: organization ? organization.streamerId : '',
    smartLibraryDisableDate: disableSmartLibraryDate,
    smartLibraryDisabled: disableSmartLibraryDate
      ? isSameOrBefore(disableSmartLibraryDate)
      : false,
    smartClipsDisableDate: disableSmartClipsDate,
    smartClipsDisabled: disableSmartClipsDate
      ? isSameOrBefore(disableSmartClipsDate)
      : false,
  };

  return transformed;
};

export const userOrgFrontendToBackend = (user, organizationId) => {
  const userInfo = user.hasAccount
    ? { userId: user.clientId }
    : { emailAddress: user.userName };

  return {
    ...userInfo,
    organizationId,
    role: user.role,
    userAccountGrantRequest: {
      idsuid: '',
      accountGrants: user?.accountGrants || [],
    },
  };
};

export const transformUserOrgBackendError = (error) => {
  const backendExpectedErrors = {
    2102: 'users.addUser.currentAccount.error',
    2103: 'users.addUser.anotherAccount.error',
  };

  const errorMessage = getApiErrorMessage(error, backendExpectedErrors);

  return errorMessage || 'errors.somethingWrong';
};

export default {
  backendToFrontend,
  userOrgFrontendToBackend,
};
