import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import InsertLinkIcon from '@material-ui/icons/InsertLink';
import LaunchIcon from '@material-ui/icons/Launch';
import { PrimaryButton } from '..';
import { validations, copyText } from '../../../utils';
import {
  MOBILE_EVENTS_TABS,
  CHANNEL_TYPE,
  PAGE_ACTIVE,
} from '../../../globals/constants';
import { useGetMobilePageEvents, useGetPage } from '../../../utils/hooks';
import Page from '../Page';
import PageTitle from '../PageTitle';
import PagePaper from '../PagePaper';
import Tabs from './Tabs';
import EventsList from './EventsList';

import styles from './styles';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

const tabPaths = {
  [MOBILE_EVENTS_TABS.UPCOMING.toLowerCase()]: MOBILE_EVENTS_TABS.UPCOMING,
  [MOBILE_EVENTS_TABS.PAST.toLowerCase()]: MOBILE_EVENTS_TABS.PAST,
};

export function VancoMobileEvents({ classes, setCopyNotification, vmUrl }) {
  const { t } = useTranslation();
  usePageTitle(t('vanco.mobile.home.users.viewUsers'));
  const { page } = useGetPage(CHANNEL_TYPE.MOBILE, PAGE_ACTIVE);
  const { data, status } = useGetMobilePageEvents();
  const { mobileOrgId, events } = data || {};
  const siteLink = `${vmUrl}/events/${mobileOrgId}`;
  const { filter = MOBILE_EVENTS_TABS.UPCOMING } = useParams();
  const eventTab = tabPaths[filter] || MOBILE_EVENTS_TABS.UPCOMING;
  const history = useHistory();

  const goBack = () => {
    history.push('/mobile/home');
  };

  const onChangeTab = (_, val) => {
    history.push(`/mobile/events/${val.toLowerCase()}`);
  };

  const onCopy = () => {
    copyText(siteLink);
    setCopyNotification(t('siteSettings.linkCopied'));
  };

  const onGoToSite = () => {
    if (validations.urlRegex.test(siteLink)) window.open(siteLink, '_blank');
  };
  return (
    <Page>
      {status === 'loading' && null}
      {status === 'success' && (
        <>
          <div className={classes.title}>
            <PageTitle
              onGoBack={goBack}
              className={classes.title}
              readOnly={!page.hasCommunityFeatures}
            >
              {t('vanco.mobile.events.title')}
            </PageTitle>
            {mobileOrgId && (
              <div className={classes.leftMargin}>
                <PrimaryButton
                  variant="outlined"
                  value={siteLink}
                  onClick={onCopy}
                  color="primary"
                >
                  <InsertLinkIcon className={classes.linkSiteText} />
                  {t('vanco.mobile.home.events.copySiteLink')}
                </PrimaryButton>
                <PrimaryButton
                  variant="outlined"
                  className={classes.goToSiteButton}
                  value={siteLink}
                  onClick={onGoToSite}
                  color="primary"
                >
                  <LaunchIcon className={classes.linkSiteText} />
                  {t('vanco.mobile.home.events.goToSite')}
                </PrimaryButton>
              </div>
            )}
          </div>
          <Tabs value={eventTab} onChange={onChangeTab} />
          <PagePaper className={classes.pagePaper}>
            <EventsList events={events} eventTab={eventTab} />
          </PagePaper>
        </>
      )}
    </Page>
  );
}

VancoMobileEvents.propTypes = {
  classes: PropTypes.object.isRequired,
  setCopyNotification: PropTypes.func.isRequired,
  vmUrl: PropTypes.string.isRequired,
};

export default withStyles(styles)(VancoMobileEvents);
