export default function styles(theme) {
  const divider = {
    margin: '0 -24px 0 -24px',
    [theme.breakpoints.down('md')]: {
      margin: '0 -42px 0 -42px',
    },
  };
  return {
    fundTitle: {
      marginTop: 25,
    },
    paymentForm: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '40px',
    },
    divider: {
      ...divider,
      marginBottom: 24,
    },
    titlePadding: {
      paddingBottom: 24,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 42,
      },
    },
  };
}
