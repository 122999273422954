import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import InlineErrorMessage from '../InlineErrorMessage';
import PageSelector from './PageSelector';
import { useGetPages } from '../../../utils/hooks';
import { getPagesByChannelAndStatus } from '../../../utils/page';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';

import styles from './styles';

export function ChannelVisibility({
  classes,
  errors,
  locations,
  visibility,
  setFieldValue,
  shouldSetDefaultLocations,
  tileType,
}) {
  const { t } = useTranslation();
  const setFieldValueRef = useRef(setFieldValue);
  const { data: pages = [], status: getPagesStatus } = useGetPages();
  const displayContent = getPagesStatus !== 'loading';
  const onlinePages = getPagesByChannelAndStatus(
    pages,
    CHANNEL_TYPE.ONLINE,
    PAGE_ACTIVE,
  );
  const mobilePages = getPagesByChannelAndStatus(pages, CHANNEL_TYPE.MOBILE);

  useEffect(() => {
    setFieldValueRef.current = setFieldValue;
  }, [setFieldValue]);

  useEffect(() => {
    if (visibility && pages.length === 1 && shouldSetDefaultLocations) {
      const defaultLocations = [
        { id: pages[0].id, channelId: pages[0].channelId },
      ];
      setFieldValueRef.current({
        target: { name: 'locations', value: defaultLocations },
      });
    }
  }, [pages, visibility, shouldSetDefaultLocations]);

  const handleVisibility = async (event) => {
    async function updateVisibilityFields(newVisibilityValue, newLocations) {
      // update order matters for validation
      if (newVisibilityValue) {
        await setFieldValueRef.current({
          target: { name: 'locations', value: newLocations },
        });
        await setFieldValueRef.current({
          target: { name: 'visibility', value: true },
        });
      } else {
        await setFieldValueRef.current({
          target: { name: 'visibility', value: false },
        });
        await setFieldValueRef.current({
          target: { name: 'locations', value: newLocations },
        });
      }
    }
    const visible = event.target.value === 'true';
    let newLocations = [];
    if (visible && pages.length === 1) {
      newLocations = [{ id: pages[0].id, channelId: pages[0].channelId }];
    }
    await updateVisibilityFields(visible, newLocations);
  };

  const handleSelectChannel = (event) => {
    let newLocations;
    if (event.target.checked) {
      newLocations = [
        ...locations,
        {
          id: event.target.name,
          channelId: pages.find((p) => p.id === event.target.name).channelId,
        },
      ];
    } else {
      newLocations = locations.filter((l) => l.id !== event.target.name);
    }
    setFieldValue({ target: { name: 'locations', value: newLocations } });
  };

  return (
    <Grid className={classes.maxWidth}>
      <Grid item xs={12}>
        <FormControl
          required
          component="fieldset"
          className={classes.formControl}
        >
          <RadioGroup
            name="visibility"
            value={String(visibility)}
            onChange={handleVisibility}
          >
            <div className={classes.borderTop}>
              <FormControlLabel
                checked={visibility}
                value="true"
                className={classes.marginMainRadio}
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Radio
                    color={visibility ? 'primary' : 'default'}
                    className={
                      visibility
                        ? classes.primaryColor
                        : classes.unselectedControl
                    }
                    data-testid="rdVisible"
                  />
                }
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Grid className={classes.descriptionMargin}>
                    <Grid item>
                      <Typography
                        variant="body2"
                        className={classes.radioFirstLine}
                      >
                        {t('tile.visible')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.radioSecondLine}>
                        {t('tile.visibleDescription')}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </div>
            {visibility &&
              displayContent &&
              (onlinePages.length > 0 || mobilePages.length > 0) && (
                <FormGroup className={classes.visibilityGroup}>
                  {onlinePages.length > 0 && (
                    <>
                      <Typography className={classes.channelName}>
                        {t('app.vancoOnline').toUpperCase()}
                      </Typography>
                      {onlinePages.map((p) => (
                        <PageSelector
                          key={p.id}
                          page={p}
                          locations={locations}
                          errors={errors}
                          onChange={handleSelectChannel}
                          tileType={tileType}
                          className={classes.secondaryRadioControl}
                        />
                      ))}
                    </>
                  )}
                  {mobilePages.length > 0 && (
                    <>
                      <Typography className={classes.channelName}>
                        {t('app.vancoMobile').toUpperCase()}
                      </Typography>
                      {mobilePages.map((p) => (
                        <PageSelector
                          key={p.id}
                          page={p}
                          locations={locations}
                          errors={errors}
                          onChange={handleSelectChannel}
                          tileType={tileType}
                          className={classes.secondaryRadioControl}
                        />
                      ))}
                    </>
                  )}
                </FormGroup>
              )}
            <div className={classes.border}>
              <FormControlLabel
                checked={!visibility}
                value="false"
                className={classes.marginMainRadio}
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Radio
                    color={visibility ? 'default' : 'primary'}
                    className={
                      visibility
                        ? classes.unselectedControl
                        : classes.primaryColor
                    }
                    data-testid="rdHidden"
                  />
                }
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Grid className={classes.descriptionMargin}>
                    <Grid item>
                      <Typography
                        variant="body2"
                        className={classes.radioFirstLine}
                      >
                        {t('tile.hidden')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.radioSecondLine}>
                        {t('tile.hiddenDescription')}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </div>
          </RadioGroup>
          {errors && errors.locations && (
            <InlineErrorMessage errorMessage={t('tile.visibilityError')} />
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
}

ChannelVisibility.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  visibility: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  shouldSetDefaultLocations: PropTypes.bool.isRequired,
  tileType: PropTypes.string.isRequired,
};

export default withStyles(styles)(ChannelVisibility);
