export default function styles(theme) {
  return {
    header: {
      marginLeft: 10,
      marginBottom: 10,
    },
    categoryBox: {
      height: '92%', // Somehow this makes grid items within a row equal heights.
      marginTop: '29px',
      padding: '24px',
    },
    linkText: {
      color: theme.palette.primary.main,
      fontWeight: 700,
      marginTop: '8px',
      cursor: 'pointer',
    },
    divider: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    categoryTitleContainer: {
      display: 'flex',
    },
    communityTitleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    descriptionText: {
      fontSize: '1.4rem',
    },
    settingsSubContainer: {
      position: 'relative', // Use relative for this container so it will contain absolute-positioned children
    },
    settingsVerticalDivider: {
      position: 'absolute',
      left: '50%',
      top: '4px',
      height: '90%',
    },
    horizontalDivider2: {
      margin: 'revert', // Revert the default Material margin of 0px
      width: 'calc(100% - 45px)',
    },
    icon: {
      marginTop: 2,
      '& path': {
        fill: '#444444',
      },
    },
    setUpButton: {
      borderColor: theme.palette.primary.main,
      borderRadius: 4,
      color: theme.palette.primary.main,
      fontSize: 14,
    },
    badge: {
      color: '#444444',
    },
    popover: {
      pointerEvents: 'none',
      position: 'absolute',
    },
    popoverContent: {
      borderRadius: 4,
      backgroundColor: '#FFFFFF',
      width: 320,
      padding: '12px 16px',
      '& p': {
        fontSize: '1.4rem',
        fontWeight: 400,
        textAlign: 'left',
      },
    },
    flex: {
      display: 'flex',
    },
    readOnly: {
      marginLeft: 6,
      fontWeight: 500,
      fontSize: 14,
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    inviteIcon: {
      marginRight: 20,
    },
    qrIcon: {
      color: '#444444',
      marginLeft: -2,
      '& path': {
        fill: '#444444',
      },
    },
    switchRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 12,
    },
    switchWrapper: {
      flex: 1,
    },
    switchLabel: {
      fontWeight: '700',
      flex: 3,
    },
    bibleIcon: {
      marginTop: 2,
    },
    versionWrapper: {
      width: '100%',
      height: 50,
      marginBottom: 16,
    },
    versionDropdown: {
      width: '100%',
    },
  };
}
