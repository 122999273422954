import isEmpty from 'lodash/isEmpty';
import i18next from 'i18next';
import * as globals from '../../globals/constants';

// Initializes the initialValues object used by Formik. Either populates with existing data if editing a basket item,
// or creates a new object if we're adding a new item
export const getInitialValues = ({ transactionData }) => {
  if (isEmpty(transactionData)) {
    return {};
  }

  return {
    draftTransaction: {
      fundId: '',
      amount: 0,
      frequency: '',
      memoLine: '',
      startDate: null,
      endDate: null,
      showTextField: false,
    },
    basket: [],
  };

  // if (transactionData.type === globals.CAMPAIGN_TYPE.PAYMENT) {
  //   return transactionData.transactionItems.reduce((map, item) => {
  //     const newMap = map;
  //     newMap[0] = {
  //       fundId: '',
  //       itemAmount: item.price,
  //       amount: item.price,
  //       frequency: globals.PAYMENT_FREQUENCY_ONE_TIME_NOW,
  //       quantity: 1,
  //       memoLine: '',
  //     };

  //     return newMap;
  //   }, {});
  // }

  // return transactionData.transactionItems.reduce((map, item) => {
  //   const newMap = map;
  //   switch (item.type) {
  //     case globals.TRANSACTION_ITEM_TYPES.OPEN_AMOUNT:
  //       newMap[0] = {
  //         fundId: '',
  //         amount: initialAmount ?? '',
  //         frequency: getDefaultFrequency(item),
  //         recurringOption: enableDefaultFrequency
  //           ? globals.PAYMENT_FREQUENCY_OPTIONS.RECURRING
  //           : globals.PAYMENT_FREQUENCY_OPTIONS.ONCE,
  //         startDate: null,
  //         endDate: null,
  //         memoLine: '',
  //         showEndDate: item.showEndDate,
  //       };
  //       break;
  //     default:
  //   }

  //   return newMap;
  // }, {});
};

// This sets the inital touched object which is required to get Formik to properly validate
// existing basket data on component mount
export const getInitialTouched = ({ campaignDetails, transactionData }) => {
  if (
    isEmpty(campaignDetails) ||
    campaignDetails.type === globals.CAMPAIGN_TYPE.PAYMENT
  ) {
    return {};
  }

  return campaignDetails.transactionItems.reduce((map, item) => {
    const newMap = map;
    const data = transactionData[item.fundId];

    switch (item.type) {
      case globals.TRANSACTION_ITEM_TYPES.OPEN_AMOUNT:
        newMap[item.fundId] = {
          amount: !!data?.amount,
          frequency: !!data?.frequency,
          startDate:
            data?.frequency === globals.PAYMENT_FREQUENCY.ONE_TIME_NOW
              ? false
              : !!data?.startDate,
        };
        break;
      default:
    }
    return newMap;
  }, {});
};

export const getBasketQuantity = (campaignId, { transactionData = {} }) => {
  const transaction = transactionData[campaignId];
  if (isEmpty(transaction)) {
    return 0;
  }

  return transaction.quantity;
};

export const hasError = (item, basketData) => {
  if (item.type === globals.CAMPAIGN_TYPE.PAYMENT) {
    const {
      campaignDetails: { id },
      transactionData = {},
    } = item;
    const basketQuantity = getBasketQuantity(id, basketData);
    const itemQuantity = transactionData[id]?.quantity || 0;
    return basketQuantity + itemQuantity > globals.MAX_PAYMENT_ITEMS;
  }

  return false;
};

export const getErrorMessage = (item, basketData) => {
  if (item.campaignDetails.type === globals.CAMPAIGN_TYPE.PAYMENT) {
    const {
      campaignDetails: { id },
    } = item;
    const total = getBasketQuantity(id, basketData);
    return total >= globals.MAX_PAYMENT_ITEMS
      ? i18next.t('basket.errorModal.maxSize')
      : i18next.t('basket.errorModal.extraItems', {
          extraItems: globals.MAX_PAYMENT_ITEMS - total,
        });
  }

  return null;
};
