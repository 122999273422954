import MaterialCircularProgress from '@material-ui/core/CircularProgress';

import { useSetupLayoutContext } from '../context';

export function CircularProgress() {
  const { classes } = useSetupLayoutContext();

  return (
    <MaterialCircularProgress
      color="primary"
      className={classes.circularProgress}
    />
  );
}

export default CircularProgress;
