import PropTypes from 'prop-types';
import { Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

function Installment({ values, setFieldValue, disabled }) {
  const { t } = useTranslation();

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFieldValue({ target: { name, value: checked } });
  };

  useEffect(() => {
    if (disabled && values.allowInstallments) {
      setFieldValue({
        target: { name: 'allowInstallments', value: !disabled },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <div style={{ paddingBottom: '20px' }}>
      <Typography
        variant="body2"
        style={{ color: disabled ? '#8E9194' : '#222222' }}
      >
        {t('gift.installments.allow')}
      </Typography>
      <Typography style={{ color: disabled ? '#8E9194' : 'rgb(34, 34, 34)' }}>
        {t('gift.installments.description')}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            id="allowInstallments"
            name="allowInstallments"
            data-testid="allowInstallmentsTestId"
            color="primary"
            onChange={handleCheckboxChange}
            disabled={disabled}
            checked={values.allowInstallments || false}
          />
        }
        label={t('gift.installments.allow')}
        style={{ marginTop: '15px' }}
      />
    </div>
  );
}

Installment.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Installment;
