export default function styles(theme) {
  return {
    button: {
      marginTop: 36,
    },
    colorIcon: {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
    actions: {
      padding: '16px 42px 0',
      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },
    },
    container: {
      display: 'inline',
    },
  };
}
