export default () => ({
  title: {
    marginTop: 22,
    lineHeight: '1.43',
  },
  subtitle: {
    marginBottom: 8,
  },
  addressSubtitle: {
    marginTop: 40,
  },
  spinner: {
    marginTop: 40,
  },
});
