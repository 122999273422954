import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getImgFromFile, validateImageSize } from '../../images';

export default function useImageConfiguration({
  pageId,
  setImage,
  updateImage,
  cropAspect,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [uncroppedImage, setUncroppedImage] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const initialCrop = {
    aspect: cropAspect,
    width: 100,
    unit: '%',
  };
  const [crop, setCrop] = useState(initialCrop);
  const [fileInputIsLoading, setFileInputLoading] = useState(false);
  const [loadingUpdateImage, setLoadingUpdateImage] = useState(false);
  const [uploadImageError, setUploadImageError] = useState(null);

  const setCropOpenWithStateReset = isOpen => {
    if (isOpen) {
      // Reset to initialCrop so that we don't store the height value from the old crop.
      // If we don't do this, it will use the old height percentage and the aspect ratio
      // will be wrong for other image heights.
      setCrop(initialCrop);
    }
    setCropOpen(isOpen);
  };

  async function cropSave() {
    setLoadingUpdateImage(true);
    const tempImg = await dispatch(
      updateImage({
        pageId,
        image: {
          crop,
          ...uncroppedImage,
        },
      }),
    );
    setLoadingUpdateImage(false);
    if (tempImg) {
      setCropOpenWithStateReset(false);
      setImage(tempImg);
    }
  }

  async function onUserUploadImage(e) {
    const [file] = e.target.files;
    const isValidImageSize = validateImageSize(file);
    setFileInputLoading(true);
    if (file && isValidImageSize.isValid) {
      const img = await getImgFromFile(file);

      if (img) {
        setUncroppedImage(img);
        setCropOpenWithStateReset(true);
        setUploadImageError(null);
      } else {
        setUploadImageError(
          t('upload.file.type.error', {
            fileTypes: `JPG ${t('word.or')} PNG`,
          }),
        );
      }
    } else {
      setUploadImageError(isValidImageSize.errorMessage);
    }
    setFileInputLoading(false);
  }

  return {
    uncroppedImage,
    setUncroppedImage,
    cropOpen,
    setCropOpen: setCropOpenWithStateReset,
    crop,
    setCrop,
    fileInputIsLoading,
    loadingUpdateImage,
    uploadImageError,
    cropSave,
    onUserUploadImage,
  };
}
