function UseFacebookEmbedCode() {
  const formatValue = value => {
    let sanitizedUrl = '';
    // sanitize , trim so no spacing issues
    try {
      sanitizedUrl = decodeURIComponent(value.trim());
    } catch {
      // eslint-disable-next-line no-empty
    }
    return sanitizedUrl;
  };

  const isEmbedCode = value => {
    return value ? value.trim().startsWith('<iframe') : false;
  };

  const isTinyUrl = value => {
    return value ? value.trim().startsWith('https://fb.watch/') : false;
  };

  const isValidTinyUrl = value => {
    const formattedValue = formatValue(value);
    // https://fb.watch/903Sy4O2bp
    // must start with https://fb.watch/<any alpha numeric chars> <optional ending backslash>
    // adding any char that is not numeric to the end of the url - should fail this check
    const matches = formattedValue.match(
      // eslint-disable-next-line no-useless-escape
      /^https:\/\/fb\.watch\/[0-9a-zA-Z\.]*\/?$/,
    );

    return matches && matches.length > 0;
  };

  const extractUrlFromFullUrl = fullUrl => {
    let result = '';
    const matches = fullUrl.match(
      // eslint-disable-next-line no-useless-escape
      /^(https:\/\/www\.facebook\.com\/[0-9a-zA-Z\.]*\/videos\/)(?:.*?\/(?=\d+))?(\d*\/?)$/,
    );
    if (matches && matches.length > 2) {
      result = matches[1] + matches[2];
    }
    return result;
  };

  const isValidFullURL = value => {
    const formattedValue = formatValue(value);

    // https://www.facebook.com/vancopaymentsolutions/videos/<optional title>/1057836725030957
    // must start with www.facebook.com/<any content>/videos/<optional title>/<any number of digits> <optional ending backslash>
    // adding any char that is not alphanumeric to the end of the url - should fail this check
    // if optional title is present in URL, it will be filtered out
    const url = extractUrlFromFullUrl(formattedValue);

    return url !== '';
  };

  const extractUrlFromEmbed = embedCode => {
    let facebookUrl = '';

    // extracts href from within iframe src
    const matches = embedCode.match(/(?:src=["']).*?href=(.*?)(?=["'])/);

    if (matches && matches?.length > 0) {
      // sanitize url
      const decodedValue = formatValue(matches[0]);
      // isolate href value
      const decodedMatches = decodedValue.match(
        // eslint-disable-next-line no-useless-escape
        /href=(https:\/\/www.facebook.com\/[0-9a-zA-Z\.]*\/videos\/\d*\/?)/,
      );
      // url is captured in second group
      if (decodedMatches && decodedMatches.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        facebookUrl = decodedMatches[1];
      }
    }

    return facebookUrl;
  };

  return {
    isEmbedCode,
    isTinyUrl,
    isValidTinyUrl,
    isValidFullURL,
    extractUrlFromEmbed,
    extractUrlFromFullUrl,
  };
}

export default UseFacebookEmbedCode;
