import { objectOf, string, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import vancoLogo from '../../../../assets/logos/logo.svg';
import vancoMobileLogo from '../../../../assets/logos/mobile.svg';

import styles from './styles';

export function StaticLogo({ classes, isMobile }) {
  return (
    <>
      {!isMobile && (
        <Link to="/">
          <img
            src={vancoLogo}
            alt="Vanco Logo"
            className={classes.desktopLogo}
          />
        </Link>
      )}
      <div className={classes.grow}>
        {isMobile && (
          <Link to="/">
            <img
              src={vancoMobileLogo}
              alt="Vanco Mobile Logo"
              className={classes.mobileLogo}
            />
          </Link>
        )}
      </div>
    </>
  );
}

StaticLogo.propTypes = {
  classes: objectOf(string).isRequired,
  isMobile: bool,
};

StaticLogo.defaultProps = {
  isMobile: false,
};

export default withStyles(styles)(StaticLogo);
