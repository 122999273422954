import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

export function CustomDrawer({
  classes,
  className,
  children,
  anchor,
  inline,
  blur,
  onClose,
  elevated,
  permanent,
  aboveNav,
  open,
  size,
}) {
  const props = {
    anchor: !permanent ? anchor : null,
    open: !permanent ? open : null,
  };
  const style =
    anchor === 'left' || anchor === 'right'
      ? { width: size }
      : { height: size };

  return (
    <>
      <Paper
        className={`
          ${className || ''}
          ${classes.root}
          ${!inline ? classes.fixed : ''}
          ${!inline && anchor === 'right' ? classes.right : ''}
          ${!inline && anchor === 'top' ? classes.top : ''}
          ${!inline && anchor === 'bottom' ? classes.bottom : ''}
          ${!permanent ? classes.animated : classes.permanent}
          ${open ? classes.open : ''}
          ${!aboveNav ? classes.belowNav : ''}
          ${elevated ? classes.elevated : ''}
        `}
        style={style}
        square
        {...props}
      >
        {children}
      </Paper>
      {blur && open && (
        <>
          {/* eslint-disable-next-line */}
          <div role="button" onClick={onClose} className={classes.overlay} />
        </>
      )}
    </>
  );
}

CustomDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  anchor: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  inline: PropTypes.bool,
  blur: PropTypes.bool,
  onClose: PropTypes.func,
  elevated: PropTypes.bool,
  permanent: PropTypes.bool,
  aboveNav: PropTypes.bool,
  open: PropTypes.bool,
  size: PropTypes.number,
};

CustomDrawer.defaultProps = {
  className: null,
  anchor: 'left',
  inline: false,
  blur: false,
  onClose: () => {},
  elevated: false,
  permanent: false,
  aboveNav: false,
  open: false,
  size: 255,
};

export default withStyles(styles)(CustomDrawer);
