import { push } from 'connected-react-router';
import moment from 'moment';
import i18n from '../../utils/i18n';
import UserService from '../../api/UserService';
import InternalService from '../../api/InternalService';
import { setAppLoading } from '../loading/actions';
import { handleException, handleResponseError } from '../alerts/actions';
import { LOGIN_ORG_START, LOGIN_ORG_FINISH, LOGIN_ORG_ERROR } from './types';
import Cookies from '../../utils/Cookies';
import { getFeatures } from '../features/actions';
import { SET_USER } from '../user/types';
import {
  getDisableDate,
  isSameOrBefore,
} from '../../utils/date/timeZoneHelper';

// login for admins to log into an organization
export function internalLogin(org) {
  return async (dispatch) => {
    dispatch(setAppLoading(true));
    dispatch({ type: LOGIN_ORG_START });

    try {
      const res = await InternalService.setSession(org.id);

      if (res.status === 200) {
        const { associatedOrganizations } = res.data.data;

        const organization = associatedOrganizations?.find(
          (o) => o.organizationId.toLowerCase() === org.id.toLowerCase(),
        );
        await dispatch(getFeatures());
        const user = Cookies.getUserCookie();

        const {
          streamingDisableDate,
          smartLibraryDisableDate,
          smartClipsDisableDate,
        } = organization ?? {};

        const disableStreamingDate = streamingDisableDate
          ? moment(streamingDisableDate).startOf('day')
          : undefined;
        const disableSmartLibraryDate = smartLibraryDisableDate
          ? moment(smartLibraryDisableDate).startOf('day')
          : undefined;
        const disableSmartClipsDate = smartClipsDisableDate
          ? moment(smartClipsDisableDate).startOf('day')
          : undefined;

        const updatedUser = {
          ...user,
          isInternal: false,
          wasInternal: true,
          organizationId: org.id,
          organizationTenant: organization?.idsPayerTenant,
          organizationName: organization?.organizationName,
          organizationStreamingDisableDate: disableStreamingDate,
          organizationStreamingDisabled: disableStreamingDate
            ? isSameOrBefore(disableStreamingDate)
            : false,
          smartLibraryDisableDate: disableSmartLibraryDate,
          smartLibraryDisabled: disableSmartLibraryDate
            ? isSameOrBefore(disableSmartLibraryDate)
            : false,
          smartClipsDisableDate: disableSmartClipsDate,
          smartClipsDisabled: disableSmartClipsDate
            ? isSameOrBefore(disableSmartClipsDate)
            : false,
        };

        Cookies.setUserCookie(updatedUser);
        dispatch({
          type: SET_USER,
          user: updatedUser,
        });
        dispatch({ type: LOGIN_ORG_FINISH });
        await dispatch(push('/'));
      } else {
        dispatch({ type: LOGIN_ORG_ERROR });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({ type: LOGIN_ORG_ERROR });
      dispatch(handleException(e));
    }

    dispatch(setAppLoading(false));
  };
}

// logout for admins to log out of an organization
export function internalLogout() {
  return async (dispatch) => {
    dispatch(setAppLoading(true));

    try {
      const res = await UserService.logout();

      if (res.status === 200) {
        Cookies.deleteCookies();
        // Navigate to MyVanco Internal site
        window.location.href = window.env.REACT_APP_IP_URL;
      } else {
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch(handleException(e));
    }

    dispatch(setAppLoading(false));
  };
}
export function setStreamingDisableDate(organizationId, values) {
  return async (dispatch, getState) => {
    const disableDateError = i18n.t('vancoVideo.saving.error');
    dispatch(setAppLoading(true));
    const currentFrontEndUser = getState().user.user;
    try {
      const response = await InternalService.patchOrganization(
        organizationId,
        values,
      );

      if (response && response.status === 200) {
        const associatedOrg = currentFrontEndUser.associatedOrganizations.find(
          (o) => o.organizationId === organizationId,
        );

        const {
          streamingDisableDate,
          smartLibraryDisableDate,
          smartClipsDisableDate,
        } = response?.data?.data ?? {};

        const disableStreamingDate = getDisableDate(streamingDisableDate);
        const disableSmartLibraryDate = getDisableDate(smartLibraryDisableDate);
        const disableSmartClipsDate = getDisableDate(smartClipsDisableDate);

        if (associatedOrg) {
          associatedOrg.streamingDisableDate = disableStreamingDate;
          associatedOrg.smartLibraryDisableDate = disableSmartLibraryDate;
          associatedOrg.smartClipsDisableDate = disableSmartClipsDate;
        }

        const newFrontendUser = {
          ...currentFrontEndUser,
          organizationStreamingDisableDate: disableStreamingDate,
          organizationStreamingDisabled: disableStreamingDate
            ? isSameOrBefore(disableStreamingDate)
            : false,
          smartLibraryDisableDate: disableSmartLibraryDate,
          smartLibraryDisabled: disableSmartLibraryDate
            ? isSameOrBefore(disableSmartLibraryDate)
            : false,
          smartClipsDisableDate: disableSmartClipsDate,
          smartClipsDisabled: disableSmartClipsDate
            ? isSameOrBefore(disableSmartClipsDate)
            : false,
        };

        Cookies.setUserCookie(newFrontendUser);
        dispatch({ type: SET_USER, user: newFrontendUser });

        return true;
      }
      response.error = disableDateError;
      dispatch(handleResponseError(response));
      return false;
    } catch (error) {
      error.message = disableDateError;
      dispatch(handleException(error));
      return false;
    } finally {
      dispatch(setAppLoading(false));
    }
  };
}
