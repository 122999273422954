import { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getOrganizationGrants } from '../../../store/organizations/actions';

// filter the sections that are enabled, if at least one of the subsections is enabled adds the section name to the list in uppercase
const filterSections = (grants) => {
  return Array.from(
    new Set(
      grants
        .filter((g) => g.enabled)
        .map((grant) => grant.section.toUpperCase()),
    ),
  );
};

const formatGrants = (grants) => {
  const grantStrings = grants
    .filter((g) => g.enabled)
    .map((grant) => {
      const { section, subSection, permission } = grant;
      return `${section.toUpperCase()}_${subSection.toUpperCase()}_${permission.toUpperCase()}`;
    })
    .concat(filterSections(grants));

  return grantStrings.reduce((acc, grant) => {
    acc[grant] = true;
    return acc;
  }, {});
};

const useOrganizationPermissions = () => {
  const {
    loadingOrganizationGrants,
    organizationGrantsLoaded,
    currentOrganization,
  } = useSelector((state) => state.organizations, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!organizationGrantsLoaded) {
      dispatch(getOrganizationGrants());
    }
  }, [dispatch, organizationGrantsLoaded]);

  return {
    loadingGrants: loadingOrganizationGrants,
    detailedPermissions: currentOrganization?.grants || [],
    organizationGrants: formatGrants(currentOrganization?.grants || []),
  };
};

export default useOrganizationPermissions;
