export default () => ({
  organizationInitials: {
    fontSize: 48,
    alignSelf: 'center',
    color: 'white',
  },
  uploadIcon: {
    marginRight: '8px',
  },
  logoContainer: {
    marginTop: 24,
  },
  logoContainerWithImage: {
    marginTop: 8,
  },
  text: {
    marginTop: 8,
  },
});
