import axios from 'axios';
import axiosRetry from 'axios-retry';

// `timeout` specifies the number of milliseconds before the request times out.
// If the request takes longer than `timeout`, the request will be aborted.
const TIMEOUT = 30 * 1000;

export class Api {
  static http;

  constructor() {
    const ua = window.navigator.userAgent;
    const ie = ua.indexOf('Trident');

    const headers = {};
    if (ie >= 0) {
      headers.Pragma = 'no-cache';
    }

    const { REACT_APP_BACKEND_API } = window.env;
    this.http = axios.create({
      baseURL: REACT_APP_BACKEND_API,
      timeout: TIMEOUT,
      withCredentials: true,
      headers,
    });

    axiosRetry(this.http, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 2000; // linear increase
      },
      retryCondition: (error) => {
        return error?.response?.status === 500;
      },
    });
  }

  get(url = '', config = {}) {
    return this.http.get(url, config);
  }

  post(url = '', data = {}, config = {}) {
    return this.http.post(url, data, config);
  }

  put(url = '', data = {}, config = {}) {
    return this.http.put(url, data, config);
  }

  patch(url = '', data = {}, config = {}) {
    return this.http.patch(url, data, config);
  }

  delete(url = '', data = {}, config = {}) {
    return this.http.delete(url, data, config);
  }
}

export default new Api();
