import { connect } from 'react-redux';

import AppSnackbar from './AppSnackbar';
import { clearAlert } from '../../../store/alerts/actions';

export function mapStateToProps(state) {
  return {
    variant: state.alerts.variant,
    message: state.alerts.message,
  };
}

export default connect(
  mapStateToProps,
  { clearAlert },
)(AppSnackbar);
