import { string, func, bool, objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';
import { CircularProgress, withStyles } from '@material-ui/core';

import useGetOrgSettings from '../../../../utils/hooks/useGetOrgSettings';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import PageTitle from '../../PageTitle';
import CustomFormik from '../../CustomFormik';
import { DEFAULT_COLOR } from '../../../../globals/constants';
import BrandingForm from './Form';
import styles from './styles';
import PreventLeavePageModal from '../../PreventLeavePageModal';
import EditorButtons from '../EditorButtons';

export function Branding({
  classes,
  pageTitleKey,
  organizationId,
  saveOrganizationSettings,
  goToNext,
  goToPrev,
  isFinished,
  onGoBack,
}) {
  const { t } = useTranslation();
  const { settings, loadingOrgSettings, savingOrgSettings } =
    useGetOrgSettings(organizationId);
  const isEditor = !!onGoBack;
  const loading = loadingOrgSettings || savingOrgSettings;

  const onSubmit = async (values) => {
    const formValues = {
      ...settings,
      ...values,
    };
    const succeeded = await saveOrganizationSettings(
      organizationId,
      formValues,
      !!onGoBack,
    );
    if (succeeded) {
      if (onGoBack) {
        onGoBack();
      } else {
        goToNext();
      }
    }
  };

  const colorSelectorProps = {
    loadingOrgSettings,
    goToPrev,
    isFinished,
    settings,
  };
  return (
    <>
      <PageTitle
        className={isEditor ? classes.editorGutter : classes.pageTitle}
      >
        {t(pageTitleKey)}
      </PageTitle>
      <CustomFormik
        enableReinitialize
        initialValues={{
          primaryColor: settings.primaryColor || DEFAULT_COLOR,
          logoImage: settings.logoImage,
          bannerImage: settings.bannerImage,
        }}
        onSubmit={onSubmit}
        render={(formikProps) => {
          const { dirty, handleSubmit, handleReset } = formikProps;
          return (
            <Form>
              <BrandingForm formikProps={formikProps} {...colorSelectorProps} />
              {!loading && goToPrev && (
                <WizardButtons
                  isFinished={isFinished}
                  dirty={dirty}
                  goToNext={handleSubmit}
                  goToPrev={goToPrev}
                  undo={handleReset}
                />
              )}
              {!loading && isEditor && (
                <EditorButtons
                  onCancel={onGoBack}
                  onHandleSubmit={handleSubmit}
                  dirty={dirty}
                />
              )}
              {loading && (
                <div
                  className={
                    isEditor
                      ? classes.spinnerContainer
                      : classes.spinnerContainerWizard
                  }
                >
                  <CircularProgress
                    color="primary"
                    data-testid="color-options-save-spinner"
                  />
                </div>
              )}
              {isEditor && (
                <PreventLeavePageModal
                  shouldOpenOnLeave={dirty && !loading && !savingOrgSettings}
                  onLeave={onGoBack}
                />
              )}
            </Form>
          );
        }}
      />
    </>
  );
}

Branding.propTypes = {
  classes: objectOf(string).isRequired,
  pageTitleKey: string,
  organizationId: string.isRequired,
  saveOrganizationSettings: func.isRequired,
  goToNext: func,
  goToPrev: func,
  isFinished: bool,
  onGoBack: func,
};

Branding.defaultProps = {
  pageTitleKey: '',
  goToNext: null,
  goToPrev: null,
  isFinished: false,
  onGoBack: null,
};

export default withStyles(styles)(Branding);
