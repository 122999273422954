import { func, objectOf, string, bool } from 'prop-types';
import {
  Button,
  withStyles,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UnarchiveIcon from '@material-ui/icons/Replay';
import styles from './styles';
import DisclosureMessage from '../DisclosureMessage';

function UnpublishPage({ classes, onClick, disabled, dirty }) {
  const { t } = useTranslation();
  const matches = useMediaQuery('(min-width:820px)');

  const verbiage = '.off';

  return (
    <DisclosureMessage
      className={classes.disclosure}
      title={
        !matches ? t(`site.unarchive.stream.disclosureTitle${verbiage}`) : ''
      }
      message=""
      variant={matches ? 'inline' : 'default'}
    >
      <div>
        {matches && (
          <Typography component="span" className={classes.title}>
            {t(`site.unarchive.stream.disclosureTitle${verbiage}`)}
          </Typography>
        )}
        <Typography component="span">
          {` ${t(`site.unarchive.stream.disclosureMessage${verbiage}`)}`}
        </Typography>
      </div>
      <Button
        size="medium"
        className={`${classes.unpublishButton} ${
          dirty ? classes.unpublishExtended : ''
        }`}
        variant="outlined"
        onClick={() => onClick()}
        disabled={disabled}
      >
        <UnarchiveIcon className={classes.unpublishButtonIcon} />
        {dirty
          ? t(`button.savePublish`)
          : t(`site.unarchive.stream.disclosureButton${verbiage}`)}
      </Button>
    </DisclosureMessage>
  );
}

UnpublishPage.propTypes = {
  classes: objectOf(string).isRequired,
  onClick: func.isRequired,
  disabled: bool,
  dirty: bool.isRequired,
};

UnpublishPage.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(UnpublishPage);
