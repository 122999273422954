import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { constants } from '../../../globals';

const { TILE_ACTIVE, TILE_ARCHIVED } = constants;

export function EmptyTileList({ classes, activeTab }) {
  const { t } = useTranslation();

  let heading = '';
  let body = '';
  if (activeTab === TILE_ACTIVE) {
    heading = 'tileManagement.emptyActiveTileListHeading';
    body = 'tileManagement.emptyActiveTileListBody';
  } else if (activeTab === TILE_ARCHIVED) {
    heading = 'tileManagement.emptyArchiveTileListHeading';
    body = 'tileManagement.emptyArchiveTileListBody';
  } else {
    heading = 'tileManagement.emptyDraftTileListHeading';
    body = 'tileManagement.emptyDraftTileListBody';
  }

  return (
    <div className={classes.empty}>
      <h2 className={classes.emptyHeading}>{t(heading)}</h2>
      <p className={classes.emptyBody}>{t(body)}</p>
    </div>
  );
}

EmptyTileList.propTypes = {
  classes: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default withStyles(styles)(EmptyTileList);
