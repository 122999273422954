import { formInputText } from '../../../globals/typography';

export default function styles(theme) {
  return {
    editDrawer: {
      maxWidth: '100%',
    },
    closeButton: {
      marginTop: 40,
      marginBottom: 0,
      marginLeft: 20,
      width: 'auto',
    },
    closeText: {
      position: 'relative',
      display: 'inline-block',
      color: theme.palette.secondary.light,
      marginLeft: 3,
      bottom: 1,
      ...formInputText,
    },
    closeIcon: {
      width: 20,
      height: 20,
      color: theme.palette.secondary.light,
    },
    titleNoBorder: {
      fontWeight: 'bold',
      padding: '42px 32px 16px 32px',
    },
    confirm: {
      padding: '95px 60px',
    },
    confirmTitle: {
      marginBottom: 14,
    },
    confirmText: {
      fontWeight: 'bold',
      marginBottom: 34,
    },
    confirmButton: {
      marginBottom: 35,
    },
    image: {
      margin: '5px 0px 16px 33px',
      flexShrink: 0,
      display: 'block',
      maxWidth: 336,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
      height: 189,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundColor: theme.palette.secondary.light,
      borderRadius: 6,
    },
    description: {
      margin: '16px 33px 32px 33px',
      color: '#666666',
      flexShrink: 1,
      overflow: 'hidden',
    },
    onlyDescription: {
      margin: '0px 33px 32px 33px',
      color: '#666666',
      flexShrink: 1,
      overflow: 'hidden',
    },
    divider: {
      borderBottom: '1px solid #EBEBEB',
    },
  };
}
