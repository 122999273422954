import { string, objectOf, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs, { Tab } from '../../Tabs';
import { MOBILE_EVENTS_TABS } from '../../../../globals/constants';

import styles from './styles';

function MobileEventsTabs({ classes, value, onChange }) {
  const { t } = useTranslation();
  return (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      value={value}
      onChange={onChange}
      variant="scrollable"
    >
      <Tab
        classes={{ root: classes.tab }}
        value={MOBILE_EVENTS_TABS.UPCOMING}
        label={t('vanco.mobile.events.upcomingTab')}
      />
      <Tab
        classes={{ root: classes.tab }}
        value={MOBILE_EVENTS_TABS.PAST}
        label={t('vanco.mobile.events.pastTab')}
      />
    </Tabs>
  );
}

MobileEventsTabs.propTypes = {
  classes: objectOf(string).isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
};

export default withStyles(styles)(MobileEventsTabs);
