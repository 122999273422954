import { func, node, string, bool } from 'prop-types';
import MaterialTableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import { useTableContext } from '../context';

export function TableRow({ onClick, className, isHeader, children, ...rest }) {
  const { classes, disabled } = useTableContext();

  const rowClasses = clsx({
    [classes.tableRow]: !disabled && !isHeader,
    [classes.tableRowHeader]: isHeader,
    [classes.tableRowDisabled]: disabled || isHeader,
    [className]: !!className,
  });

  return (
    <MaterialTableRow
      {...rest}
      onClick={disabled ? null : onClick}
      className={rowClasses}
    >
      {children}
    </MaterialTableRow>
  );
}

TableRow.propTypes = {
  onClick: func,
  children: node,
  className: string,
  isHeader: bool,
};

TableRow.defaultProps = {
  onClick: null,
  children: null,
  className: '',
  isHeader: false,
};

export default TableRow;
