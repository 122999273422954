import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import styles from './styles';

function TileBuilderTabs({ classes, value, onChange, isGift }) {
  const { t } = useTranslation();

  const tabNameKeys = [
    'tile.overview',
    'tile.title',
    'tile.customText',
    'tile.imageSelection',
    isGift ? 'tile.gift' : 'tile.payment',
  ];
  return (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      value={value}
      onChange={onChange}
      className={classes.tabs}
      variant="scrollable"
    >
      {tabNameKeys.map(key => (
        <Tab label={t(key)} key={key} />
      ))}
    </Tabs>
  );
}

TileBuilderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isGift: PropTypes.bool.isRequired,
};

export default withStyles(styles)(TileBuilderTabs);
