import React, { useRef, useState } from 'react';
import PropTypes, { node } from 'prop-types';
import { useMediaQuery, withStyles } from '@material-ui/core';
import { matchPath } from 'react-router-dom';
import styles from './styles';
import Navbar from '../WizardLayout/Navbar';
import WizardNav from '../WizardNav/WizardNav';
import { getNavConfig } from '../WizardLayout/config';

import { findCurrentSection } from '../../../utils/wizard';
import { Page } from '../../common';
import { useScrollToTop } from '../../../utils/hooks';
import MobileWizardNav from '../MobileWizardNav';

export function StreamSiteLayout({
  classes,
  children,
  isLoggedIn,
  pages,
  features,
  history,
  page,
  tile,
  settings,
  location,
}) {
  const contentRef = useRef();
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  useScrollToTop(contentRef, location);

  const [menuOpen, setMenuOpen] = useState(false);

  const isMobilePage = location.pathname.includes('mobile');
  const isStreamPage = location.pathname.includes('stream');
  const isOrganizationPage = location.pathname.includes('organization');

  const configWithState = getNavConfig({
    page,
    tile,
    settings,
    isMobilePage,
    isStreamPage,
    isOrganizationPage,
    communityFeaturesChoosen: false,
    features,
    findCurrentSection,
  });

  const [navState] = useState(configWithState);
  const [dirty, setDirty] = useState(false);
  const [submit] = useState(null);
  const stepperSteps = navState.filter((c) => c.navTitle);
  const match = matchPath(location.pathname, {
    path: navState.map((n) => n.path).sort((a, b) => b.length - a.length),
  }) || { params: {} };
  const { path } = match;

  const currentSection = findCurrentSection(navState, path);

  return (
    <section className={isLargeScreen ? classes.mainCentered : classes.main}>
      <section
        className={
          isLargeScreen ? classes.contentWrapCentered : classes.contentWrap
        }
      >
        <Navbar
          isLoggedIn={isLoggedIn}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          pages={pages}
          newWizard
          features={features}
          setForcePreventModal={() => {}}
          forcePreventModal={false}
        />

        <WizardNav
          history={history}
          totalSections={stepperSteps.length}
          navConfig={stepperSteps}
          currentSection={currentSection}
          page={page}
          tile={tile}
          open={menuOpen}
          setDirty={setDirty}
          dirty={dirty}
          submit={submit}
          isStreamPage={isStreamPage}
          isOrganizationPage={isOrganizationPage}
          newWizard
          clearTempImage={() => {}}
          isStreamSite
        />
        <Page
          className={isLargeScreen ? classes.container : classes.containerSmall}
          ref={contentRef}
        >
          {currentSection.navTitle && (
            <MobileWizardNav
              totalSections={stepperSteps.length}
              navConfig={stepperSteps}
              currentSection={currentSection}
            />
          )}
          <section className={classes.streamSiteSection}>
            {React.cloneElement(children, {
              history,
              pages,
              location,
              features,
            })}
          </section>
        </Page>
      </section>
    </section>
  );
}

StreamSiteLayout.propTypes = {
  children: node.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  features: PropTypes.object,
  history: PropTypes.object.isRequired,
  page: PropTypes.object,
  tile: PropTypes.object,
  settings: PropTypes.object,
  location: PropTypes.object.isRequired,
};
StreamSiteLayout.defaultProps = {
  features: {},
  page: null,
  tile: null,
  settings: null,
};
export default withStyles(styles)(StreamSiteLayout);
