export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 32,
    },
    infoText: {
      color: theme.palette.primary.main,
    },
    disabledButton: {
      opacity: 0.2,
      color: `${theme.palette.white} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  };
}
