import { makeStyles } from '@material-ui/core';

export default makeStyles(() => {
  return {
    createtileModal: {
      maxWidth: '646px',
    },
    header: {
      borderBottom: 0,
      padding: 21,
      marginBottom: 0,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 40px 40px',
    },
    title: {
      alignSelf: 'center',
      fontSize: '2.4rem',
      textAlign: 'center',
      maxWidth: 350,
      lineHeight: '3.2rem',
      marginBottom: 62,
    },
  };
});
