import { func, object, bool } from 'prop-types';
import {
  FormControl,
  Grid,
  Button,
  Typography,
  Divider,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { getCountText } from 'utils';

import OutlinedTextField from 'components/common/OutlinedTextField';
import FormHelperTextBottom from 'components/common/FormHelperTextBottom';
import { maxLengths } from 'utils/validations';

import { useTranslation } from 'react-i18next';
import Modal from 'components/common/Modal';
import OutlinedSelect from 'components/common/OutlinedSelect';
import PrimaryButton from 'components/common/PrimaryButton';
import useStyles from './styles';

function AiAssistForm(props) {
  const {
    setFieldValue,
    handleSubmit,
    handleClose,
    values,
    errors,
    isSubmitting,
    touched,
  } = props;

  const { purposeEntry, mainPointsEntry, toneEntry, responseLength } = values;
  const { t } = useTranslation();
  const classes = useStyles();

  // Field Configuration
  const fields = [
    {
      id: 'purposeEntry',
      name: 'purposeEntry',
      label: t('modal.aiassist.purpose.title'),
      maxLength: maxLengths.purposeEntry,
      value: purposeEntry,
      error: errors.purposeEntry,
      touched: touched.purposeEntry,
    },
    {
      id: 'mainPointsEntry',
      name: 'mainPointsEntry',
      label: t('modal.aiassist.mainpoints.title'),
      maxLength: maxLengths.mainPointsEntry,
      value: mainPointsEntry,
      error: errors.mainPointsEntry,
      touched: touched.mainPointsEntry,
    },
    {
      id: 'toneEntry',
      name: 'toneEntry',
      label: t('modal.aiassist.tone.title'),
      maxLength: maxLengths.toneEntry,
      placeholder: t('modal.aiassist.tone.placeholder'),
      value: toneEntry,
      error: errors.toneEntry,
      touched: touched.toneEntry,
    },
  ];

  const responseLengths = [
    {
      name: t('modal.aiassist.responseLength.short'),
      description: t('modal.aiassist.responseLength.short.description'),
    },
    {
      name: t('modal.aiassist.responseLength.average'),
      description: t('modal.aiassist.responseLength.average.description'),
    },
    {
      name: t('modal.aiassist.responseLength.long'),
      description: t('modal.aiassist.responseLength.long.description'),
    },
  ];

  const handleOnChange = (e) => {
    setFieldValue({
      target: { name: e.target.name, value: e.target.value },
    });
  };

  const renderValue = (selected) => {
    if (selected) {
      const selectedItem = responseLengths.find(
        (item) => item.name === selected,
      );
      return selectedItem ? selectedItem.name : '';
    }
    return <em>{t('modal.aiassist.responseLength.placeholder')}</em>;
  };

  return (
    <>
      <Modal.Title className={classes.title}>
        {t('modal.aiassist.title')}
      </Modal.Title>

      <Modal.Content className={classes.content}>
        <Grid container>
          {fields.map((field) => (
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              key={field.id}
              className={classes.shortDescriptionContainer}
            >
              <section className={classes.heading}>
                <Typography>{field.label}</Typography>
              </section>
              <FormControl fullWidth>
                <OutlinedTextField
                  {...field} // Spread field props for cleaner code
                  label=""
                  error={Boolean(field.error) && Boolean(field.touched)}
                  touched={field.touched ? field.touched.toString() : ''}
                  onChange={handleOnChange}
                  multiline
                  minRows="4"
                  inputProps={{
                    className: classes.pbox,
                    'data-testid': field.id, // field id for data-testid
                  }}
                />
                <FormHelperTextBottom
                  left={
                    field.error && Boolean(field.touched) ? field.error : ''
                  }
                  data-testid={`${field.id}-counter`}
                  right={getCountText(field.value.length, field.maxLength)}
                />
              </FormControl>
            </Grid>
          ))}
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            className={classes.shortDescriptionContainer}
          >
            <section className={classes.heading}>
              <Typography>
                {t('modal.aiassist.responseLength.title')}
              </Typography>
            </section>
            <OutlinedSelect
              name="responseLength"
              value={responseLength}
              displayEmpty
              onChange={handleOnChange}
              id="responseLength"
              error={Boolean(errors.responseLength)}
              renderValue={renderValue}
            >
              {responseLengths.map((r, index) => {
                const isLastItem = index === responseLengths.length - 1;
                return (
                  <MenuItem key={r.name} value={r.name}>
                    <div className={classes.menuEntrySplit}>
                      <div>{r.name}</div>
                      {r.description !== '' ? <div>{r.description}</div> : null}
                      {!isLastItem && (
                        <Divider
                          className={classes.divider}
                          variant="fullWidth"
                        />
                      )}
                    </div>
                  </MenuItem>
                );
              })}
            </OutlinedSelect>
          </Grid>
        </Grid>
        <Divider className={classes.divider} variant="fullWidth" />
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        {isSubmitting ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            <Button
              onClick={handleClose}
              size="medium"
              variant="outlined"
              className={classes.closeButton}
              disabled={isSubmitting}
            >
              {t('button.cancel')}
            </Button>
            <PrimaryButton
              type="submit"
              size="medium"
              data-testid="disable-date-submit"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {t('button.save')}
            </PrimaryButton>
          </>
        )}
      </Modal.Actions>
    </>
  );
}

AiAssistForm.propTypes = {
  handleSubmit: func.isRequired,
  handleClose: func.isRequired,
  setFieldValue: func.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  isSubmitting: bool.isRequired,
  touched: object.isRequired,
};

export default AiAssistForm;
