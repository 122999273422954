import { CircularProgress, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './styles';

function ProgressCircle({ classes, progress, variant }) {
  return (
    <span className={classes.circleContainer}>
      {variant === 'determinate' && (
        <Typography className={classes.progressText}>
          {`${progress}`}
          <span className={classes.percentIcon}>%</span>
        </Typography>
      )}
      <CircularProgress
        classes={{ svg: classes.svg }}
        variant={variant}
        value={progress}
        size={80}
        thickness={4}
      />
    </span>
  );
}

ProgressCircle.propTypes = {
  classes: PropTypes.object.isRequired,
  progress: PropTypes.number,
  variant: PropTypes.oneOf(['determinate', 'indeterminate']),
};

ProgressCircle.defaultProps = {
  progress: 0,
  variant: 'determinate',
};

export default withStyles(styles)(ProgressCircle);
