import {
  IMAGE_ACTIVE,
  IMAGE_STAGE,
  TILE_TYPE_GIFT,
  TILE_TYPE_PAYMENT,
  BUTTON_TEXT_OPTIONS_GIFT,
  BUTTON_TEXT_OPTIONS_PAYMENT,
  MEMO_LINE_OPTIONS,
} from '../../globals/constants';
import { parseDescription, getLocations } from '../../utils/tiles';

/* Frontend tile to a backend representation of a tile (called campaign) */
export const frontendToBackend = (tile, page = {}) => {
  const buttonText =
    BUTTON_TEXT_OPTIONS_GIFT.includes(tile.buttonText) ||
    BUTTON_TEXT_OPTIONS_PAYMENT.includes(tile.buttonText)
      ? tile.buttonText
      : tile.customButtonText;
  const textField = MEMO_LINE_OPTIONS.includes(tile.textField)
    ? tile.textField
    : tile.customTextField;
  const headerText = buttonText;
  const funds = tile.fund
    ? [
        {
          accountCode: tile.fund.accountCode,
          fundRefId: tile.fund.fundRefId,
        },
      ]
    : [];
  const paymentFrequencies =
    tile.type === TILE_TYPE_GIFT ? tile.paymentFrequencies : null;

  const description = JSON.stringify(tile.description);

  const location = page.id ? { id: page.id } : null;

  const paymentOptions =
    tile.type === TILE_TYPE_PAYMENT &&
    tile.paymentOptions &&
    tile.paymentOptions.price > 0
      ? { price: tile.paymentOptions.price }
      : null;
  const campaign = {
    id: tile.id,
    location,
    content: {
      title: tile.title,
      description,
      shortDescription: tile.shortDescription,
      detail: {
        callToActionText: buttonText,
        donationOptionsTitle: headerText,
        image: {
          id: tile.image.id,
          url: tile.image.src,
          status: IMAGE_ACTIVE,
          altText: 'long long 10 char string', // TODO: fix this
        },
      },
    },
    type: tile.type,
    funds,
    status: tile.status,
    ordinal: tile.ordinal,
    donationOptions: {
      paymentFrequencies,
    },
    locationIds: tile.locations.map(({ id }) => id),
    paymentOptions,
    showTextField: tile.showTextField,
    ...(tile.showTextField
      ? {
          textField,
          responseRequired: tile.responseRequired,
          isTextFieldResponseType: tile.isTextFieldResponseType,
          memoFields: tile.memoFieldResponses.map((m) => {
            return { ...m, id: m.isNew ? 0 : m.id };
          }),
        }
      : {
          textField: '',
          responseRequired: false,
          isTextFieldResponseType: true,
          memoFields: [],
        }),
    showEndDate: tile.showEndDate,
    allowInstallments: tile.allowInstallments,
  };

  return campaign;
};

/* Backend campaign to a frontend representation of a campaign (called tile) */
export const backendToFrontend = (campaign) => {
  const { content } = campaign;
  const fund =
    campaign.funds && campaign.funds.length && campaign.funds[0]
      ? campaign.funds[0]
      : undefined;
  const paymentFrequencies =
    (campaign.donationOptions && campaign.donationOptions.paymentFrequencies) ||
    [];

  const paymentOptions =
    (campaign.paymentOptions &&
      campaign.paymentOptions.price > 0 && {
        price: campaign.paymentOptions.price,
      }) ||
    '';

  const tile = {
    id: campaign.id,
    title: content && (content.title || ''),
    shortDescription: content && (content.shortDescription || ''),
    description: content && parseDescription(content.description || ''),
    status: campaign.status,
    buttonText: content.detail.callToActionText || '',
    headerText: content.detail.donationOptionsTitle || '',
    customButtonText: content.detail.callToActionText || '',
    customHeaderText: content.detail.donationOptionsTitle || '',
    questions: [],
    image: {
      id: content.detail.image.id,
      src: content.detail.image.url,
      url: content.detail.image.url,
    },
    fund,
    type: campaign.type,
    paymentFrequencies,
    ordinal: campaign.ordinal,
    lastUpdatedAt: campaign.lastUpdatedDate,
    locations: campaign.locations || [],
    archivedDate: campaign.archivedDate,
    paymentOptions,
    textField: campaign.textField || '',
    customTextField: campaign.textField || '',
    showTextField: campaign.showTextField,
    showEndDate: campaign.showEndDate,
    responseRequired: campaign.responseRequired,
    isTextFieldResponseType: campaign.isTextFieldResponseType,
    memoFieldResponses: campaign.memoFields || [],
    allowInstallments: campaign.allowInstallments,
  };
  return tile;
};

export const imageFrontendToBackend = (tile) => {
  const { image } = tile;

  return {
    id: tile.id,
    imageId: image.id,
    source: 'Unsplash', // TODO: this should actually be the real image source but for now its just Unsplash images
    status: IMAGE_STAGE,
    blob: image.blob,
  };
};

export const imageBackendToFrontend = (tile, image) => {
  return {
    ...tile,
    image: {
      ...tile.image,
      id: image.id,
      src: image.url,
      url: image.url,
    },
  };
};

export const backendToTilesList = (data) => {
  const locations = (data?.included || [])[0]?.locations || [];
  return (data?.data || [])
    .map((tile) => {
      const newTile = { ...tile };
      newTile.locations = getLocations(newTile, locations);
      return backendToFrontend(newTile);
    })
    .sort((a, b) =>
      (a.title || 'untitled').toLowerCase() <
      (b.title || 'untitled').toLowerCase()
        ? -1
        : 1,
    );
};

export default {
  frontendToBackend,
  backendToFrontend,
  imageFrontendToBackend,
  backendToTilesList,
};
