import { useSelector } from 'react-redux';
import { ROLES } from 'globals/constants';

const useUser = () => {
  const { user = {}, isLoggedIn } = useSelector((store) => store?.user) ?? {};

  return {
    ...user,
    isAccountOwner: user?.role === ROLES.ACCOUNT_OWNER,
    isLoggedIn,
  };
};

export default useUser;
