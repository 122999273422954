export default function styles(theme) {
  return {
    formControl: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
    },
    optionBorder: {
      borderBottom: '1px solid #DEE1E3',
    },
    menuFix: {
      height: '500px',
      maxHeight: '500px',
    },
    iconStyle: {
      border: '1px solid #DEE1E3',
      borderRight: 'unset',
      display: 'flex',
      width: '40px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconSpanFix: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: theme.spacing(1),
        color: '#5E6266',
      },
    },
    selectFix: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderRight: 'none',
    },
  };
}
