export default function styles() {
  return {
    content: {
      display: 'flex',
      flex: 1,
      marginTop: 20,
      marginBottom: 20,
    },
    contentCenter: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      marginTop: 20,
      marginBottom: 20,
    },
    labelSection: {
      marginTop: 10,
      marginBottom: 10,
    },
    question: {
      marginBottom: 10,
    },
    title: {
      marginTop: 30,
      marginBottom: 20,
    },
  };
}
