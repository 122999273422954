import { node, func, string } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { useModalContext } from '../context';

export function Title({
  icon,
  onIconClick,
  className,
  children,
  ...restProps
}) {
  const { classes, onClose } = useModalContext();

  return (
    <DialogTitle
      classes={{ root: classes.titleContainer }}
      className={className}
      disableTypography
      {...restProps}
    >
      {icon && (
        <IconButton className={classes.titleIcon} onClick={onIconClick}>
          {icon}
        </IconButton>
      )}
      <Typography
        variant="subtitle1"
        className={icon ? classes.title : classes.titleWithPadding}
      >
        {children}
      </Typography>
      <IconButton
        className={`${classes.titleIcon} ${classes.closeIcon}`}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
}

Title.propTypes = {
  children: node,
  icon: node,
  onIconClick: func,
  className: string,
};

Title.defaultProps = {
  children: null,
  icon: null,
  onIconClick: null,
  className: '',
};

export default Title;
