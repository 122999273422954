import { connect } from 'react-redux';

import ColorOptions from './ColorOptions';
import { savePage } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    loadingPage: state.pages.loadingPage,
    loadingSave: state.pages.loadingSave,
    newPage: state.pages.newPage,
  };
}

export default connect(mapStateToProps, { savePage })(ColorOptions);
