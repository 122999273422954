import { defaultOrg } from '../../globals/defaults';

/* Frontend settings to a backend representation of settings */
export const frontendToBackend = (settings) => {
  const enableFees = settings.enableProcessingFees;

  const settingsObject = {
    organizationId: settings.organizationId,
    contactEmail: settings.email,
    homePageUrl: settings.website,
    primaryColor: settings.primaryColor,
    logoImage: settings.logoImage,
    bannerImage: settings.bannerImage,
    defaultBannerImageUrl: settings.defaultBannerImageUrl,
    donationOptions: {
      enableFeeContribution: enableFees,
      transactionMethods: null,
    },
    mobileEnabled: settings.mobileEnabled ?? true,
    offsetFundRefId: settings.fund ? settings.fund.fundRefId : undefined,
    applyFeeByDefault: settings.applyFeeByDefault,
  };

  if (settings.hasPaymentMethods) {
    settingsObject.donationOptions.transactionMethods = {
      creditCard: {
        transactionMethod: 'CreditCard',
        active: enableFees,
        feeValue: enableFees ? settings.creditPercentage : '',
      },
      ach: {
        transactionMethod: 'ACH',
        active: enableFees,
        feeValue: enableFees ? settings.checkPercentage : '',
      },
    };
  }

  return settingsObject;
};

/* Backend settings to a frontend representation of settings */
export const backendToFrontend = (settings) => {
  const address = (settings.address && settings.address.line1) || '';
  const address2 = (settings.address && settings.address.line2) || '';
  const city = (settings.address && settings.address.city) || '';
  const zip = (settings.address && settings.address.zip) || '';
  const state = (settings.address && settings.address.state) || '';
  const hasPaymentMethods = !!settings.donationOptions?.transactionMethods;
  const checkPercentage = hasPaymentMethods
    ? settings.donationOptions.transactionMethods.ach?.feeValue
    : defaultOrg.checkPercentage;
  const creditPercentage = hasPaymentMethods
    ? settings.donationOptions.transactionMethods.creditCard?.feeValue
    : defaultOrg.creditPercentage;

  return {
    organizationId: settings.organizationId,
    dbaName: settings.dbaName,
    address,
    address2,
    city,
    zip,
    state,
    email: settings.contactEmail,
    phone: settings.contactPhone,
    website: settings.homePageUrl,
    primaryColor: settings.primaryColor,
    logoImage: settings.logoImage,
    bannerImage: settings.bannerImage,
    defaultBannerImageUrl: settings.defaultBannerImageUrl,
    enableProcessingFees: !!settings.donationOptions?.enableFeeContribution,
    hasPaymentMethods,
    checkPercentage,
    creditPercentage,
    defaultsComplete: settings.defaultsComplete,
    mobileEnabled: settings.mobileEnabled ?? true,
    fund: settings.offsetFundRefId
      ? { fundRefId: settings.offsetFundRefId }
      : undefined,
    applyFeeByDefault: settings.applyFeeByDefault,
  };
};

export default { frontendToBackend, backendToFrontend };
