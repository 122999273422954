export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    link: {
      color: theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  };
}
