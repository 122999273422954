/* Frontend bank to a backend representation of a bank */
export const frontendToBackend = bank => {
  return {
    bankAccountId: bank.id,
    displayName: bank.name,
  };
};

/* Backend bank to a frontend representation of a bank */
export const backendToFrontend = bank => {
  return {
    id: bank.bankAccountId,
    name: bank.displayName,
    routingNumber: bank.routingNumber,
    accountLast4: bank.accountLast4,
    accountType: bank.accountType,
    companyName: bank.companyName,
    bankName: bank.bankName,
    status: bank.status,
  };
};

export default { frontendToBackend, backendToFrontend };
