import { objectOf, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';
import { useGetMobilePageGroups, useGetPage } from '../../../utils/hooks';
import Page from '../Page';
import PageTitle from '../PageTitle';
import PagePaper from '../PagePaper';
import Tabs, { Tab } from '../Tabs';
import GroupsList from './GroupsList';

import styles from './styles';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

function groupsTabs({ classes, t }) {
  const allTab = 'All';
  return (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      value={allTab}
      variant="scrollable"
    >
      <Tab
        classes={{ root: classes.tab }}
        value={allTab}
        label={t('word.all')}
      />
    </Tabs>
  );
}

export function VancoMobileGroups({ classes }) {
  const { t } = useTranslation();
  usePageTitle(t('vanco.mobile.home.groups.view'));
  const { page } = useGetPage(CHANNEL_TYPE.MOBILE, PAGE_ACTIVE);
  const { groups, status } = useGetMobilePageGroups();
  const history = useHistory();
  const goBack = () => history.push('/mobile/home');

  return (
    <Page>
      {status === 'success' && (
        <>
          <PageTitle onGoBack={goBack} readOnly={!page.hasCommunityFeatures}>
            {t('vanco.mobile.home.groups.title')}
          </PageTitle>
          {groupsTabs({ classes, t })}
          <PagePaper className={classes.pagePaper}>
            <GroupsList groups={groups} />
          </PagePaper>
        </>
      )}
    </Page>
  );
}

VancoMobileGroups.propTypes = {
  classes: objectOf(string).isRequired,
};

export default withStyles(styles)(VancoMobileGroups);
