import { NAVBAR_HEIGHT } from '../../../../globals/dimensions';

export default (theme) => ({
  appBar: {
    backgroundColor: theme.palette.white,
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
  },
  toolbar: {
    minHeight: NAVBAR_HEIGHT,
    paddingLeft: 58,
    paddingRight: 58,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  mobileLogo: {
    height: 34,
    display: 'block',
  },
  exitButton: {
    position: 'absolute',
    '& span': {
      '&:first-of-type': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  exitButtonWizard: {
    position: 'absolute',
    '& span': {
      '&:first-of-type': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    height: 40,
  },
  menuText: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    position: 'relative',
    padding: 0,
  },
  mobileWizardButton: {
    width: 'auto',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  grow: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  exitIcon: {
    fontSize: '1.9rem',
    transform: 'rotate(180deg)',
    marginRight: 10,
  },
  arrowIcon: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginRight: 4,
  },
  logoButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});
