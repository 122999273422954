import * as Yup from 'yup';
import i18n from '../../../utils/i18n';

const createSchema = () => {
  return Yup.object().shape({
    enableStreamSelected: Yup.bool(),
    disableDate: Yup.date().when('isKioskEnabled', {
      is: false,
      then: (s) =>
        s.required(
          i18n.t('validation.pleaseEnter', {
            requiredNoun: `${i18n.t(
              'kiosk.setup.settings.manage.cancelationDate',
            )}`,
          }),
        ),
      otherwise: Yup.date().nullable(),
    }),
  });
};

export default createSchema;
