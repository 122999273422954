import { useEffect } from 'react';
import { objectOf, string, bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useQueryClient } from 'react-query';
import {
  Page,
  PagePaper,
  PageStatusModal,
  EmbedModal,
  QRCodeModal,
} from '../../common';
import StreamPageHeader from '../../common/StreamPageHeader';
import List from './List';
import { getPagesByChannelAndStatus, isActive } from '../../../utils/page';
import {
  useACSTBlocking,
  useCustomState,
  useDeletePage,
  useFeatures,
  useGetPages,
  useQRCodeModal,
} from '../../../utils/hooks';
import DeleteModal from '../../common/DeletePageModal';
import {
  CHANNEL_TYPE,
  VO_QR_QUERY_PARAMETER,
} from '../../../globals/constants';

import styles from './styles';
import { formatStreamInfo } from '../../../utils/stream';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

const initialState = {
  pageToUpdate: null,
  showArchivedPages: false,
  pageStatusModalOpen: false,
  pageToDelete: null,
  deletePageModalOpen: false,
  pageToEmbed: null,
  embedPageModalOpen: false,
};

export function StreamList({
  classes,
  loadingSave,
  savePage,
  setSuccess,
  removePage,
  history,
  wasInternal,
  organizationStreamingDisabled,
}) {
  const { data: pages = [], status: getPagesStatus } = useGetPages();
  const displayContent = getPagesStatus !== 'loading';
  const { t } = useTranslation();

  const { VancoVideo: ffVancoVideo } = useFeatures();

  const verbiage = '.off';

  const pageTitle = ffVancoVideo
    ? t('app.menu.streaming.liveStreaming')
    : t('app.vancoStreaming', {
        title: t('liveTitle'),
      });

  usePageTitle(pageTitle);

  const queryClient = useQueryClient();
  const [
    {
      pageToUpdate,
      pageStatusModalOpen,
      pageToDelete,
      deletePageModalOpen,
      pageToEmbed,
      embedPageModalOpen,
      pageToGenerateQRCode,
    },
    setState,
  ] = useCustomState(initialState);
  const {
    qrCodeModalOpen,
    openQRCodeModal,
    onCloseQRCodeModal,
    onErrorQRCodeModal,
  } = useQRCodeModal();
  const { deletePage, isDeletable, isDeleting, isDeletableFetching } =
    useDeletePage(pageToDelete, {
      enabled: deletePageModalOpen,
    });
  const onlyStreamPages = getPagesByChannelAndStatus(
    pages,
    CHANNEL_TYPE.STREAM,
  );

  const { hideFeaturesForACST } = useACSTBlocking();
  const redirectToNotFound = !ffVancoVideo || hideFeaturesForACST;

  useEffect(() => {
    if (history.location?.state?.showSuccessToast) {
      const state = { ...history.location.state };
      delete state.showSuccessToast;
      history.replace({ ...history.location, state });
      setSuccess(t('stream.created.success'));
    }
  });

  useEffect(() => {
    if (redirectToNotFound) {
      history.push('/not-found');
    }
  }, [redirectToNotFound, history]);

  const closeModal = () => {
    setState({ pageStatusModalOpen: false });
  };

  const onSavePage = (updatedPage) => {
    setState({
      pageToUpdate: updatedPage,
      pageStatusModalOpen: true,
    });
  };

  const onConfirmSave = async () => {
    const message = isActive(pageToUpdate)
      ? t(`stream.publish.success${verbiage}`)
      : t(`stream.unpublish.success${verbiage}`);
    await savePage(pageToUpdate, true, message);
    closeModal();
    queryClient.removeQueries(['tile']);
    queryClient.invalidateQueries(['pages']);
  };

  const onSelectPageToDelete = (selectedPage) => {
    setState({
      pageToDelete: selectedPage,
      deletePageModalOpen: true,
    });
  };

  const onDeletePage = () => {
    deletePage(pageToDelete, {
      onSuccess: () => {
        setState({
          deletePageModalOpen: false,
        });
        removePage(pageToDelete);
        setSuccess(t('stream.delete.success'));
      },
    });
  };

  const closeDeleteModal = () => {
    setState({
      deletePageModalOpen: false,
    });
  };

  const onSelectPageToEmbed = (selectedPage) => {
    setState({
      pageToEmbed: selectedPage,
      embedPageModalOpen: true,
    });
  };

  const closeEmbedModal = () => {
    setState({
      embedPageModalOpen: false,
    });
  };

  const onGenerateQRCode = (selectedPage) => {
    setState({
      pageToGenerateQRCode: selectedPage,
    });
    openQRCodeModal();
  };

  return (
    <>
      <Grid className={classes.mainContainer}>
        {displayContent && (
          <Grid item lg={12}>
            <Page>
              {wasInternal && <StreamPageHeader />}
              {!organizationStreamingDisabled && (
                <PagePaper className={classes.tableContainer}>
                  <List
                    pages={onlyStreamPages}
                    onSavePage={onSavePage}
                    onDeletePage={onSelectPageToDelete}
                    onEmbedPage={onSelectPageToEmbed}
                    onGenerateQRCode={onGenerateQRCode}
                    setSuccess={setSuccess}
                  />
                </PagePaper>
              )}
            </Page>
          </Grid>
        )}
      </Grid>
      <PageStatusModal
        disabled={loadingSave}
        onClose={closeModal}
        open={pageStatusModalOpen}
        onAccept={onConfirmSave}
        variant={
          pageToUpdate && isActive(pageToUpdate) ? 'publish' : 'unpublish'
        }
      />
      <DeleteModal
        isDeletable={isDeletable}
        isLoading={isDeletableFetching}
        disabled={isDeleting}
        onClose={closeDeleteModal}
        open={deletePageModalOpen}
        onDelete={onDeletePage}
        page={pageToDelete}
        entityType="stream"
      />
      <EmbedModal
        domain={window.env.REACT_APP_DON_URL}
        locationURI={pageToEmbed ? pageToEmbed.locationURI : ''}
        onClose={closeEmbedModal}
        open={embedPageModalOpen}
        page={pageToEmbed}
        variant="stream"
      />
      {pageToGenerateQRCode && (
        <QRCodeModal
          siteLink={`${
            window.env.REACT_APP_STREAM_URL
          }/watch/${formatStreamInfo(
            pageToGenerateQRCode.streamData?.streamerId,
          )}/${formatStreamInfo(
            pageToGenerateQRCode.streamData?.streamId,
          )}?${VO_QR_QUERY_PARAMETER}`}
          open={qrCodeModalOpen}
          onClose={onCloseQRCodeModal}
          onError={onErrorQRCodeModal}
          name={pageToGenerateQRCode.pageName}
        />
      )}
    </>
  );
}

StreamList.propTypes = {
  classes: objectOf(string).isRequired,
  loadingSave: bool.isRequired,
  savePage: func.isRequired,
  setSuccess: func.isRequired,
  removePage: func.isRequired,
  history: object.isRequired,
  wasInternal: bool.isRequired,
  organizationStreamingDisabled: bool.isRequired,
};

export default withStyles(styles)(StreamList);
