import { makeStyles } from '@material-ui/core';

export default makeStyles(() => {
  return {
    pbox: {
      width: 564,
    },
    menuEntrySplit: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '& > div:first-child': {
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
    title: {
      '& *': {
        fontSize: '24px',
      },
      lineHeight: '3.2rem',
    },
  };
});
