import { object, func, string, objectOf } from 'prop-types';
import { withStyles, MenuItem, Select } from '@material-ui/core';
import { useEffect, useState } from 'react';
import styles from './styles';
import { getTimeZonesList, getCurrentTimeZone } from '../../../utils/date';
import { ReactComponent as Checkmark } from '../../../assets/checkmark.svg';

function TimeZonePicker({
  classes,
  className,
  value,
  touched,
  errors,
  setFieldValue,
  handleBlur,
  inputPlaceholderText,
  variableName,
  ...timezoneProps
}) {
  const [timeZones, setTimeZones] = useState(null);
  const [currentTimeZone, setCurrentTimeZone] = useState(null);

  useEffect(() => {
    if (!timeZones) setTimeZones(getTimeZonesList());
  }, [timeZones]);

  useEffect(() => {
    if (!currentTimeZone) setCurrentTimeZone(getCurrentTimeZone());
  }, [currentTimeZone]);

  if (!timeZones || !currentTimeZone) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const showCurrentTimeZone = () => {
    setTimeout(() => {
      if (currentTimeZone.ianaName === value.ianaName) {
        document.getElementById('current-time-zone').scrollIntoView();
      }
    });
  };

  return (
    <Select
      className={className}
      value={value}
      defaultValue=""
      MenuProps={{ classes: { paper: classes.menuFix } }}
      renderValue={(v) => v.abbreviation}
      onOpen={showCurrentTimeZone}
      {...timezoneProps}
    >
      <MenuItem
        id="current-time-zone"
        value={currentTimeZone}
        className={classes.optionBorder}
        selected={currentTimeZone.ianaName === value.ianaName}
      >
        {`(${currentTimeZone.offset}) ${currentTimeZone.displayName} (${currentTimeZone.abbreviation})`}
        {currentTimeZone.ianaName === value.ianaName ? (
          <Checkmark className={classes.rightAlignIcon} />
        ) : null}
      </MenuItem>
      {timeZones.map((x) => (
        <MenuItem
          selected={
            x.ianaName === value.ianaName &&
            x.ianaName !== currentTimeZone.ianaName
          }
          className={
            x.ianaName === currentTimeZone.ianaName
              ? classes.removeSelectedBackground
              : null
          }
          key={x.ianaName}
          value={x}
        >
          {`(${x.offset}) ${x.displayName} (${x.abbreviation})`}
          {x.ianaName === value.ianaName &&
          x.ianaName !== currentTimeZone.ianaName ? (
            <Checkmark className={classes.rightAlignIcon} />
          ) : null}
        </MenuItem>
      ))}
    </Select>
  );
}

TimeZonePicker.propTypes = {
  classes: objectOf(string).isRequired,
  value: object.isRequired,
  touched: object.isRequired,
  errors: object.isRequired,
  setFieldValue: func.isRequired,
  handleBlur: func.isRequired,
  inputPlaceholderText: string,
  className: string,
  variableName: string,
};

TimeZonePicker.defaultProps = {
  inputPlaceholderText: '',
  className: '',
  variableName: '',
};

export default withStyles(styles)(TimeZonePicker);
