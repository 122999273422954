export default (theme) => ({
  flexFormItem: {
    flexGrow: '1',
  },
  secondFormItem: {
    flexGrow: '1',
  },
  formItemGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    gap: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    formItemGroup: {
      flexDirection: 'column',
    },
  },
});
