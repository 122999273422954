import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function OptionalLink({ isLink, children, to, ...rest }) {
  return isLink ? (
    <Link to={to} data-testid="link" {...rest}>
      {children}
    </Link>
  ) : (
    <div data-testid="notlink" {...rest}>
      {children}
    </div>
  );
}

OptionalLink.propTypes = {
  isLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
};

OptionalLink.defaultProps = {
  to: '',
  isLink: false,
};

export default OptionalLink;
