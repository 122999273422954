import { useEffect } from 'react';
import { string, func, object, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Disable } from 'react-disable';

import UnarchivePage from '../UnarchivePage';
import PageTitle from '../PageTitle';
import CustomFormik from '../CustomFormik';
import Form from './Form';
import { DEFAULT_COLOR } from '../../../globals/constants';
import { isArchived } from '../../../utils/page';

export function ColorOptions({
  pageTitleKey,
  goToNext,
  goToPrev,
  page,
  savePage,
  loadingSave,
  newPage,
  resetNewPage,
  updatePage,
  isFinished,
  onUnarchivePage,
}) {
  const { t } = useTranslation();
  const disabled = isArchived(page);

  useEffect(() => {
    if (goToNext && newPage.id) {
      goToNext();
      resetNewPage();
    }
  }, [newPage, goToNext, resetNewPage]);

  const handleSubmit = values => {
    const formPage = {
      ...page,
      ...values,
    };

    savePage(formPage);
  };

  return (
    <>
      {disabled && <UnarchivePage onClick={onUnarchivePage} />}
      <Disable disabled={disabled}>
        <PageTitle>{t(pageTitleKey)}</PageTitle>
        <CustomFormik
          onChange={updatePage}
          enableReinitialize
          initialValues={{
            primaryColor: page.primaryColor || DEFAULT_COLOR,
          }}
          onSubmit={handleSubmit}
          render={formikProps => (
            <Form
              goToPrev={goToPrev}
              loading={loadingSave}
              isFinished={isFinished}
              {...formikProps}
            />
          )}
        />
      </Disable>
    </>
  );
}

ColorOptions.propTypes = {
  pageTitleKey: string,
  goToNext: func,
  goToPrev: func,
  page: object.isRequired,
  savePage: func.isRequired,
  loadingSave: bool.isRequired,
  newPage: object.isRequired,
  resetNewPage: func.isRequired,
  updatePage: func,
  setError: func,
  isFinished: bool,
  onUnarchivePage: func,
};

ColorOptions.defaultProps = {
  pageTitleKey: 'colorOptions.title',
  goToNext: null,
  goToPrev: null,
  updatePage: null,
  setError: null,
  isFinished: false,
  onUnarchivePage: null,
};

export default ColorOptions;
