import { func, objectOf, string, object, number, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';

import {
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
  Grid,
  Divider,
  FormLabel,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useCallback } from 'react';
import styles from './styles';
import { Modal, PrimaryButton } from '../../..';
import DatePicker from '../../../DatePicker';
import { useFeatures } from '../../../../../utils/hooks';

function DisableStreamForm(props) {
  const {
    classes,
    setFieldValue,
    handleSubmit,
    handleClose,
    values,
    errors,
    submitCount,
    setValues,
    isSubmitting,
  } = props;

  const {
    streamingDisableDate,
    enableStreamSelected,
    enableLibrarySelected,
    enableClipSelected,
    smartLibraryDisableDate,
    smartClipsDisableDate,
  } = values;

  const { t } = useTranslation();

  const {
    VancoVideo: ffVancoVideo,
    SmartLibrary: ffSmartLibrary,
    SmartClips: ffSmartClips,
  } = useFeatures();

  const currentDate = moment().startOf('day');
  const dateHasError = errors.streamingDisableDate && submitCount > 0;
  const showSmartLibraryDisableDescription =
    smartLibraryDisableDate === undefined;
  const showSmartClipDisableDescription = smartClipsDisableDate === undefined;

  const displayLibraryOption = ffVancoVideo && ffSmartLibrary;
  const displayClipsOption = ffVancoVideo && ffSmartClips;

  const handleRadioSelectionToggle = async (e, type) => {
    const isEnabled = e.target.value === 'true';

    if (type === 'video') {
      await setValues({
        ...values,
        streamingDisableDate: undefined,
        enableStreamSelected: isEnabled,
      });
    } else if (type === 'library') {
      await setValues({
        ...values,
        smartLibraryDisableDate: undefined,
        enableLibrarySelected: isEnabled,
      });
    } else {
      await setValues({
        ...values,
        smartClipsDisableDate: undefined,
        enableClipSelected: isEnabled,
      });
    }
  };

  const handleDateChange = useCallback(
    async (name, value, shouldValidate) => {
      await setFieldValue({
        target: {
          name,
          value: value ? value.startOf('day') : undefined,
          shouldValidate,
        },
      });
    },
    [setFieldValue],
  );

  const renderRadios = ({
    name,
    label,
    value,
    type,
    checkLabel,
    dateField: { name: dateName, id: dateId, dateValue },
    hideDivider = false,
    showDisableDescription = false,
  }) => {
    return (
      <FormControl fullWidth>
        {!hideDivider && (
          <Divider className={classes.divider} variant="fullWidth" />
        )}
        <FormLabel>
          <Typography>{label}</Typography>
        </FormLabel>
        <RadioGroup
          name="enableStreamChange"
          value={value}
          onChange={(e) => handleRadioSelectionToggle(e, type)}
        >
          <FormControlLabel
            checked={value}
            value="true"
            className={classes.marginFirstControl}
            control={<Radio color={value ? 'primary' : 'default'} />}
            label={t('disableStreamModal.enable', { title: checkLabel ?? '' })}
          />
          <FormControlLabel
            classes={{
              root: classes.radioGroupItem,
              label: classes.radioGroupLabel,
            }}
            checked={!value}
            value="false"
            control={
              <Radio
                color={value ? 'default' : 'primary'}
                className={classes.marginRadio}
              />
            }
            labelPlacement="end"
            label={
              <Typography>
                {t('disableStreamModal.disable', { title: checkLabel ?? '' })}
              </Typography>
            }
          />
        </RadioGroup>
        {!values[name] && (
          <>
            {showDisableDescription && (
              <Grid item xs={12}>
                <Typography className={classes.descriptionText}>
                  {t('disableStreamModal.disableDescription', {
                    title: checkLabel,
                  })}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Field
                id={dateId}
                name={dateName}
                value={dateValue}
                helperTextClass={classes.inputHelperText}
                earliestAllowedDate={currentDate}
                handleDateChange={handleDateChange}
                hasError={dateHasError}
                component={DatePicker}
                inputClass={classes.dateInput}
                position="relative"
              />
            </Grid>
          </>
        )}
      </FormControl>
    );
  };

  return (
    <>
      <Modal.Content
        className={classes.content}
        style={{ overflowY: 'visible' }}
      >
        {renderRadios({
          label: t('disableStreamModal.description', {
            title: t('app.menu.smartVideo'),
          }),
          value: enableStreamSelected,
          name: 'enableStreamSelected',
          type: 'video',
          dateField: {
            name: 'streamingDisableDate',
            id: 'streamingDisableDate',
            dateValue: streamingDisableDate,
          },
          hideDivider: true,
          showDisableDescription: true,
        })}
        {displayLibraryOption &&
          renderRadios({
            label: t('disableStreamModal.smart.description', {
              resource: t(`disableStreamModal.library.resource`),
              type: t(`disableStreamModal.library`),
            }),
            value: enableLibrarySelected,
            name: 'enableLibrarySelected',
            type: 'library',
            checkLabel: t(`disableStreamModal.library.checkLabel`),
            dateField: {
              name: 'smartLibraryDisableDate',
              id: 'smartLibraryDisableDate',
              dateValue: smartLibraryDisableDate,
            },
            hideDivider: false,
            showDisableDescription: showSmartLibraryDisableDescription,
          })}
        {displayClipsOption &&
          renderRadios({
            label: t('disableStreamModal.smart.description', {
              resource: t(`disableStreamModal.clips.resource`),
              type: t(`disableStreamModal.clips`),
            }),
            value: enableClipSelected,
            name: 'enableClipSelected',
            type: 'clips',
            checkLabel: t(`disableStreamModal.clips.checkLabel`),
            dateField: {
              name: 'smartClipsDisableDate',
              id: 'smartClipsDisableDate',
              dateValue: smartClipsDisableDate,
            },
            hideDivider: !ffSmartLibrary,
            showDisableDescription: showSmartClipDisableDescription,
          })}
        <Divider className={classes.divider} variant="fullWidth" />
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <Button
          onClick={handleClose}
          size="medium"
          variant="outlined"
          className={classes.closeButton}
          disabled={isSubmitting}
        >
          {t('button.cancel')}
        </Button>
        <PrimaryButton
          type="submit"
          size="medium"
          data-testid="disable-date-submit"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {t('disableStreamModal.button')}
        </PrimaryButton>
      </Modal.Actions>
    </>
  );
}

DisableStreamForm.propTypes = {
  classes: objectOf(string).isRequired,
  handleSubmit: func.isRequired,
  handleClose: func.isRequired,
  setFieldValue: func.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  submitCount: number.isRequired,
  setValues: func.isRequired,
  isSubmitting: bool.isRequired,
};

export default withStyles(styles)(DisableStreamForm);
