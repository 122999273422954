export default function styles(theme) {
  return {
    container: {
      alignItems: 'center',
      background: '#FFFFFF',
      border: '1px solid #E6E6E6',
      borderRadius: 4,
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.07)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
      display: 'flex',
      padding: '20px 22px',
      marginBottom: 16,
      width: 600,
      '& svg': {
        marginRight: 22,
      },
      justifyContent: 'inherit', // Override the default for ButtonBase which is 'center'
    },
    add: {
      fontWeight: 700,
    },
  };
}
