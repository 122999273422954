import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';

import FormHelperTextBottom from '../../FormHelperTextBottom';
import OutlinedTextField from '../../OutlinedTextField';
import Form from '../../Form';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import { getCountText } from '../../../../utils';

function FundForm({
  values,
  setFieldValue,
  handleSubmit,
  errors,
  goToPrev,
  touched,
  maxLengths,
  handleBlur,
  dirty,
  handleReset,
  isFinished,
  loadingSave,
}) {
  const { t } = useTranslation();
  const { title } = values;

  return (
    <Form>
      <Grid container>
        <Grid item md={9} sm={12} xs={12}>
          <FormControl fullWidth>
            <OutlinedTextField
              id="title"
              name="title"
              data-testid="title"
              error={errors.title && touched.title}
              value={title}
              label={t('tile.title.label')}
              placeholder={t('tile.title.placeholder')}
              inputProps={{
                maxLength: maxLengths.title,
              }}
              onBlur={handleBlur}
              onChange={setFieldValue}
              autoComplete="off"
              autoFocus={!title.length}
            />
            <FormHelperTextBottom
              left={touched.title && errors.title}
              right={getCountText(title.length, maxLengths.title)}
            />
          </FormControl>
        </Grid>
      </Grid>
      {!loadingSave && goToPrev && (
        <WizardButtons
          isFinished={isFinished}
          dirty={dirty}
          goToNext={handleSubmit}
          goToPrev={goToPrev}
          hidePrevious
          undo={handleReset}
        />
      )}
      {loadingSave && goToPrev && <CircularProgress color="primary" />}
    </Form>
  );
}

FundForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  goToPrev: PropTypes.func,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  maxLengths: PropTypes.object.isRequired,
  dirty: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  isFinished: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
};

FundForm.defaultProps = {
  goToPrev: null,
};

export default FundForm;
