import PropTypes from 'prop-types';
import { withStyles, Typography, FormControl } from '@material-ui/core';
import { FastField, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StateDropdown from './StateDropdown';
import styles from './styles';

export function BillingInfo({ classes, nonRequired }) {
  const { validateForm } = useFormikContext(); // {,values}
  const { t } = useTranslation();
  const optionalText = {
    optional: nonRequired ? t('transactions.billingInfo.optional') : '',
  };

  useEffect(() => {
    validateForm();
  }, [nonRequired, validateForm]);

  return (
    <Typography component="div" className={classes.container}>
      <FormControl fullWidth className={classes.formControl}>
        <FastField
          name="billingInfo.addressLine1"
          autoComplete="section-billing address-line1"
          component={TextField}
          id="address-1"
          className={classes.formItem}
          label={t('transactions.billingInfo.addressLine1', optionalText)}
          variant="outlined"
          disabled={false}
        />
        <FastField
          name="billingInfo.addressLine2"
          autoComplete="section-billing address-line2"
          component={TextField}
          id="address-2"
          className={classes.formItem}
          label={t('transactions.billingInfo.addressLine2')}
          variant="outlined"
          disabled={false}
        />
        <FastField
          name="billingInfo.city"
          autoComplete="section-billing address-level2"
          component={TextField}
          id="city"
          className={classes.formItem}
          label={t('transactions.billingInfo.city', optionalText)}
          variant="outlined"
          disabled={false}
        />
        <span className={classes.formItemGroup}>
          <FastField
            name="billingInfo.state"
            component={StateDropdown}
            className={classes.stateField}
            optionalText={optionalText}
            disabled={false}
          />
          <FastField
            name="billingInfo.zipCode"
            autoComplete="section-billing postal-code"
            component={TextField}
            id="zipCode"
            inputProps={{ inputMode: 'numeric' }}
            label={t('transactions.billingInfo.zipCode', optionalText)}
            variant="outlined"
            className={classes.secondFormItem}
            disabled={false}
          />
        </span>
        <FastField
          name="billingInfo.phoneNumber"
          autoComplete="section-billing tel"
          component={TextField}
          id="phone"
          inputProps={{ inputMode: 'tel' }}
          className={classes.formItem}
          label={t('transactions.billingInfo.phoneNumber')}
          variant="outlined"
          disabled={false}
        />
      </FormControl>
    </Typography>
  );
}

BillingInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  nonRequired: PropTypes.bool,
};

BillingInfo.defaultProps = {
  nonRequired: false,
};

export default withStyles(styles)(BillingInfo);
