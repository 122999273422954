import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { PAYMENT_METHOD } from '../../../globals/constants';
import styles from './styles';
import CardBrands from './CardBrands';
import CreditCardPaymentMethod from './CreditCardPaymentMethod';
import NachaConfirmation from './NachaConfirmation';
import ACHPaymentMethod from './ACHPaymentMethod';
import BillingInfo from './BillingInfo';
import { isValidObject } from '../../../utils/validations';
import PrimaryButton from '../PrimaryButton';
import useSessionUser from '../../../store/transaction/useSessionUser';

const initialState = {
  cardInfo: {},
  hexeaPaymentMethod: null,
  showErrors: false,
  showContactInfo: false,
  cardInfoValidity: false,
};

function PaymentMethod({
  classes,
  formikProps,
  hexea,
  selectedPaymentType,
  paymentMethods,
  paymentMethodActions,
  isProcessingTransactions,
  hasItemsInBasket,
}) {
  const { t } = useTranslation();

  const [cxpFieldKey, setCxpFieldKey] = useState(0);

  const [localState, setLocalState] = useState(initialState);
  const { accessToken } = useSessionUser();
  const displayPaymentOptions =
    isValidObject(hexea) && isValidObject(localState.hexeaPaymentMethod);

  const { creditCard = {}, ach = {} } = paymentMethods;

  const getHexeaPaymentMethod = (value = selectedPaymentType) =>
    hexea.paymentMethod(
      value === PAYMENT_METHOD.CREDIT_CARD
        ? PAYMENT_METHOD.CREDIT_CARD_TOKENIZE
        : PAYMENT_METHOD.ACH_TOKENIZE,
    );

  useEffect(() => {
    /*
     * updates the payment method
     */
    if (!isValidObject(hexea)) {
      return;
    }

    const hexeaPaymentMethod = getHexeaPaymentMethod();

    setLocalState((prevState) => ({
      ...prevState,
      hexeaPaymentMethod,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hexea, setLocalState]);

  const handleInputChanged = (node, name, value) => {
    if (name === undefined || value === undefined) {
      return;
    }
    setLocalState((prevState) => ({
      ...prevState,
      [node]: {
        ...localState[node],
        [name]: value,
      },
    }));
  };

  const handleCardInfoValidity = (child, isValid) =>
    setLocalState((prevState) => ({
      ...prevState,
      cardInfoValidity: { [child]: isValid },
    }));

  const handleContactInfo = () => {
    setLocalState((prevState) => ({
      ...prevState,
      showContactInfo: !prevState.showContactInfo,
    }));
  };

  const handlePaymentTypeChanged = (_, value) => {
    paymentMethodActions.setPaymentType(value);

    setLocalState({
      ...localState,
      hexeaPaymentMethod: getHexeaPaymentMethod(value),
    });
  };

  const handleOnSubmit = () => {
    if (!accessToken) throw new Error(t('errors.session.expired'));

    const areFormFieldsValid = Object.keys(formikProps.errors).length === 0;

    const isCardValid =
      selectedPaymentType === PAYMENT_METHOD.CREDIT_CARD &&
      localState.cardInfoValidity.cardInfo;

    const isAchValid = selectedPaymentType === PAYMENT_METHOD.ACH;

    const cannotProcessTransactions =
      (!isCardValid && !isAchValid) || !areFormFieldsValid || !hasItemsInBasket;

    if (cannotProcessTransactions) return;

    paymentMethodActions.processTransactions({
      hexeaPaymentMethod: localState.hexeaPaymentMethod,
      formValues: { ...formikProps.values, currentCxpKey: cxpFieldKey },
      formActions: {
        resetForm: formikProps.resetForm,
        resetCCForm: setCxpFieldKey,
      },
      translation: t,
    });
  };

  return (
    <div className={classes.paymentMethodRoot}>
      <Typography variant="h5" className={classes.title}>
        {t('transactions.payment.method')}
      </Typography>

      <Tabs
        value={selectedPaymentType}
        onChange={handlePaymentTypeChanged}
        indicatorColor="primary"
        className={classes.tabs}
        variant="scrollable"
        scrollButtons="auto"
      >
        {creditCard?.isAllowed && (
          <Tab
            value={PAYMENT_METHOD.CREDIT_CARD}
            label={
              creditCard.debitOnly
                ? t('transactions.payment.debitCardAllowed')
                : t('transactions.payment.creditDebitAllowed')
            }
          />
        )}
        {ach?.isAllowed && (
          <Tab
            value={PAYMENT_METHOD.ACH}
            label={t('transactions.payment.bankAccount')}
          />
        )}
      </Tabs>

      {displayPaymentOptions && (
        <>
          {selectedPaymentType === PAYMENT_METHOD.CREDIT_CARD && (
            <>
              <CardBrands
                allowedCardBrands={creditCard?.allowedCardBrands || []}
              />
              <CreditCardPaymentMethod
                className="creditCardWidget"
                hexeaPaymentMethod={localState.hexeaPaymentMethod}
                onInputChanged={handleInputChanged}
                onCardInfoValidity={handleCardInfoValidity}
                showErrors={localState.showErrors}
                cxpFieldKey={cxpFieldKey}
              />
            </>
          )}

          {selectedPaymentType === PAYMENT_METHOD.ACH && (
            <>
              <ACHPaymentMethod className="ACHWidget" />
              <NachaConfirmation
                name={`${PAYMENT_METHOD.ACH}.nacha`}
                id="nacha"
                {...formikProps}
              />
            </>
          )}
        </>
      )}

      <Divider className={classes.divider} />

      <Button
        className={classes.contactButton}
        variant="text"
        onClick={() => handleContactInfo()}
        endIcon={<ArrowDropDownIcon />}
      >
        {t('transactions.contact.info')}
      </Button>

      {localState.showContactInfo && (
        <BillingInfo contactData={formikProps.values.billingInfo} nonRequired />
      )}

      <PrimaryButton
        className={classes.submitButton}
        disableTouchRipple
        size="large"
        type="submit"
        disabled={isProcessingTransactions}
        onClick={handleOnSubmit}
      >
        {t('transactions.payment.submitTitle')}
      </PrimaryButton>
    </div>
  );
}

PaymentMethod.propTypes = {
  classes: PropTypes.object.isRequired,
  formikProps: PropTypes.object.isRequired,
  hexea: PropTypes.object,
  selectedPaymentType: PropTypes.string,
  paymentMethods: PropTypes.object.isRequired,
  paymentMethodActions: PropTypes.object.isRequired,
  isProcessingTransactions: PropTypes.bool,
  hasItemsInBasket: PropTypes.bool,
};

PaymentMethod.defaultProps = {
  selectedPaymentType: PAYMENT_METHOD.CREDIT_CARD,
  isProcessingTransactions: false,
  hexea: null,
  hasItemsInBasket: false,
};

export default withStyles(styles)(PaymentMethod);
