import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import { ReactComponent as TilesIcon } from '../../../assets/tilesIcon.svg';
import Page from '../Page';
import PagePaper from '../PagePaper';
import PageTitle from '../PageTitle';
import PageDescription from '../PageDescription';
import PrimaryButton from '../PrimaryButton';

export function NoTiles({ classes, onCreateTile }) {
  const { t } = useTranslation();

  return (
    <section className={classes.main}>
      <section className={classes.contentWrap}>
        <Page className={classes.contentContainer}>
          <PagePaper className={classes.container}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <PageTitle className={classes.title}>
                  <TilesIcon
                    className={classes.activeIcon}
                    data-testid="tiles-icon"
                  />
                  {t('tile.setUp.heading1')}
                </PageTitle>
                <PageDescription
                  className={classes.body}
                  data-testid="NoTileDescription"
                >
                  {t('tile.setUp.description1')}
                </PageDescription>
                <PrimaryButton
                  className={classes.button}
                  size="large"
                  onClick={onCreateTile}
                >
                  {t('button.createTile')}
                </PrimaryButton>
              </Grid>
            </Grid>
          </PagePaper>
        </Page>
      </section>
    </section>
  );
}

NoTiles.propTypes = {
  classes: PropTypes.object.isRequired,
  onCreateTile: PropTypes.func.isRequired,
};

export default withStyles(styles)(NoTiles);
