import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Disable } from 'react-disable';
import UnarchivePage from '../UnarchivePage';
import PageTitle from '../PageTitle';
import PageDescription from '../PageDescription';
import CustomFormik from '../CustomFormik';
import Form from './Form';
import { defaultPage } from '../../../globals/defaults';
import { createSchema, minLengths, maxLengths } from './validation';
import { isArchived } from '../../../utils/page';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

export function HeaderManagement({
  goToNext,
  goToPrev,
  page,
  savePage,
  loadingSave,
  loadingCreate,
  newPage,
  resetNewPage,
  updatePage,
  setError,
  isFinished,
  pageTitleKey,
  pages,
  loadingPages,
  createPage,
  onUnarchivePage,
}) {
  const { t } = useTranslation();
  const schema = createSchema({ page, pages });
  const disabled = isArchived(page);
  usePageTitle(pageTitleKey);

  useEffect(() => {
    if (goToNext && newPage.id) {
      goToNext();
      resetNewPage();
    }
  }, [newPage, goToNext, resetNewPage]);

  const saveLocation = (values) => {
    const formPage = {
      ...page,
      ...values,
    };
    savePage(formPage);
  };

  const createNewPage = async (values) => {
    await createPage({
      ...defaultPage,
      allowSocialSharing: undefined, // Updated defaultPage. This component will be deprecated soon.
      ...values,
    });
  };

  const onSubmit = (values) => {
    if (page?.id) {
      saveLocation(values);
    } else {
      createNewPage(values);
    }
  };

  return (
    <>
      {disabled && (
        <UnarchivePage onClick={onUnarchivePage} disabled={loadingSave} />
      )}
      <Disable disabled={disabled}>
        <PageTitle>{t(pageTitleKey)}</PageTitle>
        <PageDescription />
        <CustomFormik
          onChange={updatePage}
          onErrorsChange={setError}
          initialValues={{
            pageName: page?.pageName || defaultPage.pageName,
            displayPageName:
              page.displayPageName ?? defaultPage.displayPageName,
            address: page?.address || defaultPage.address,
            address2: page?.address2 || defaultPage.address2,
            city: page?.city || defaultPage.city,
            zip: page?.zip || defaultPage.zip,
            state: page?.state || defaultPage.state,
            phone: page?.phone || defaultPage.phone,
            email: page?.email || defaultPage.email,
            homePage: page?.homePage || defaultPage.homePage,
            providePhone: page?.providePhone || false,
            provideAddress: page?.provideAddress || false,
            provideEmail: page?.provideEmail || false,
          }}
          onSubmit={onSubmit}
          enableReinitialize
          render={(formikProps) => (
            <Form
              maxLengths={maxLengths}
              minLengths={minLengths}
              goToPrev={goToPrev}
              loading={loadingSave || loadingPages || loadingCreate}
              isFinished={isFinished}
              {...formikProps}
            />
          )}
          validationSchema={schema}
        />
      </Disable>
    </>
  );
}

HeaderManagement.propTypes = {
  goToNext: PropTypes.func,
  goToPrev: PropTypes.func,
  page: PropTypes.object,
  savePage: PropTypes.func.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  newPage: PropTypes.object.isRequired,
  resetNewPage: PropTypes.func.isRequired,
  updatePage: PropTypes.func,
  setError: PropTypes.func,
  isFinished: PropTypes.bool,
  pageTitleKey: PropTypes.string,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadingPages: PropTypes.bool.isRequired,
  createPage: PropTypes.func.isRequired,
  loadingCreate: PropTypes.bool.isRequired,
  onUnarchivePage: PropTypes.func,
};

HeaderManagement.defaultProps = {
  goToNext: null,
  goToPrev: null,
  updatePage: null,
  setError: null,
  isFinished: false,
  pageTitleKey: 'headerManagement.basicInfo',
  page: {},
  onUnarchivePage: null,
};

export default HeaderManagement;
