import i18n from '../../../utils/i18n';
import {
  pageNameValidation,
  addressValidation,
  contactInfoValidations,
} from '../../../utils/validations';

export const createSchema = () => {
  return addressValidation(i18n)
    .concat(
      pageNameValidation({
        i18n,
        errorMessage: 'mobileSetup.organizationName.error',
      }),
    )
    .concat(contactInfoValidations(i18n));
};

export default {
  createSchema,
};
