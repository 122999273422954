import { Grid, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { func, objectOf, shape, string } from 'prop-types';

import InlineErrorMessage from '../../InlineErrorMessage';
import CropModal from '../../CropModal';
import FileInput from '../../FileInput';
import ImageContainer from '../../ImageContainer';
import { ReactComponent as UploadIcon } from '../../../../assets/logoUploadIcon.svg';
import { getOrganizationInitials } from '../../../../utils/page';
import { updateLogoImage } from '../../../../store/pages/actions';

import styles from './styles';
import useImageConfiguration from '../../../../utils/hooks/useImageConfiguration/useImageConfiguration';

function PageLogo({ page, classes, primaryColor, logoImage, setLogoImage }) {
  const { t } = useTranslation();
  const {
    fileInputIsLoading,
    loadingUpdateImage,
    uploadImageError,
    uncroppedImage,
    cropOpen,
    setCropOpen,
    crop,
    setCrop,
    cropSave,
    onUserUploadImage,
  } = useImageConfiguration({
    pageId: page.id,
    setImage: setLogoImage,
    updateImage: updateLogoImage,
    cropAspect: 1,
  });
  const organizationInitials = getOrganizationInitials(page.pageName);

  const logoButtonLabel = t(
    logoImage?.url ? 'button.changeLogo' : 'button.uploadLogo',
  );
  return (
    <>
      <Typography variant="h5">
        {t('stream.page.branding.logo.title')}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {t('stream.page.branding.logo.description')}
      </Typography>
      <Grid
        container
        alignItems="center"
        className={
          logoImage?.url
            ? classes.logoContainerWithImage
            : classes.logoContainer
        }
      >
        {logoImage?.url && (
          <ImageContainer
            imageUrl={logoImage?.url}
            backgroundColor={primaryColor}
            onRemove={() => setLogoImage(null)}
          >
            <Typography
              variant="body2"
              className={classes.organizationInitials}
            >
              {organizationInitials}
            </Typography>
          </ImageContainer>
        )}

        <FileInput
          onChange={onUserUploadImage}
          id="image"
          name="image"
          icon={
            !logoImage?.url && <UploadIcon className={classes.uploadIcon} />
          }
          label={logoButtonLabel}
          accept="image/x-png,image/jpeg"
          value=""
          loading={fileInputIsLoading}
          disabled={fileInputIsLoading}
        />
      </Grid>
      {uploadImageError && (
        <Grid container>
          <Grid item xs={12}>
            <InlineErrorMessage errorMessage={uploadImageError} />
          </Grid>
        </Grid>
      )}
      {cropOpen && (
        <CropModal
          crop={crop}
          onCrop={(c, percentCrop) => setCrop(percentCrop)}
          open={cropOpen}
          image={uncroppedImage}
          onClose={() => setCropOpen(false)}
          onSave={cropSave}
          loadingUpdateImage={loadingUpdateImage}
        />
      )}
    </>
  );
}

PageLogo.propTypes = {
  page: shape({}).isRequired,
  classes: objectOf(string).isRequired,
  primaryColor: string.isRequired,
  logoImage: shape({}),
  setLogoImage: func.isRequired,
};

PageLogo.defaultProps = {
  logoImage: null,
};

export default withStyles(styles)(PageLogo);
