export const mergeFundsWithBanks = (funds, banks) => {
  if (!funds) return [];
  if (!banks && funds) return funds;

  const banksIndex = banks.reduce((map, bank) => {
    // eslint-disable-next-line no-param-reassign
    map[bank.id] = bank;
    return map;
  }, {});
  const fundsWithBanks = funds.map(fund => {
    return { ...fund, bank: banksIndex[fund.bankAccount] };
  });

  return fundsWithBanks;
};

export default { mergeFundsWithBanks };
