import { connect } from 'react-redux';

import StreamPageChat from './StreamPageChat';
import { savePage } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    loadingSave: state.pages.loadingSave,
    newPage: state.pages.newPage,
  };
}

export default connect(mapStateToProps, { savePage })(StreamPageChat);
