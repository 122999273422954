import { connect } from 'react-redux';

import VancoMobileAppPermissionsWizard from './VancoMobileAppPermissionsWizard';
import {
  savePage,
  setPageCommunityFeaturesChoosen,
} from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    loadingPage: state.pages.loadingPage,
    loadingSave: state.pages.loadingSave,
  };
}

export default connect(mapStateToProps, {
  savePage,
  setPageCommunityFeaturesChoosen,
})(VancoMobileAppPermissionsWizard);
