/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { Box, useMediaQuery } from '@material-ui/core';
import PrimaryButton from '../../../common/PrimaryButton';
import styles from './styles';

export function WizardButtons({
  classes,
  isFinished,
  dirty,
  goToNext,
  goToPrev,
  undo,
  hidePrevious,
  disableSave,
  newWizard,
  isStreamSite,
  submitText,
}) {
  const { t } = useTranslation();
  const matches = useMediaQuery('(min-width:600px)');

  return isStreamSite ? (
    <section className={classes.section}>
      <Box display="flex">
        <Box flexGrow={1}>
          {!hidePrevious && (
            <Button
              variant="outlined"
              onClick={goToPrev}
              className={classes.button}
            >
              {t('button.cancel')}
            </Button>
          )}
        </Box>
        <Box>
          <PrimaryButton
            disabled={disableSave}
            onClick={goToNext}
            className={classes.button}
          >
            {t('button.save')}
          </PrimaryButton>
        </Box>
      </Box>
    </section>
  ) : newWizard && !isStreamSite ? (
    <section className={classes.section}>
      <Box display="flex">
        <Box flexGrow={1}>
          {!hidePrevious && (
            <Button
              variant="outlined"
              onClick={goToPrev}
              className={classes.button}
            >
              {t('button.back')}
            </Button>
          )}
        </Box>
        <Box>
          <PrimaryButton
            disabled={disableSave}
            onClick={goToNext}
            className={classes.button}
          >
            {t('button.saveContinue')}
          </PrimaryButton>
        </Box>
      </Box>
    </section>
  ) : (
    <section className={classes.wizardButtons}>
      {isFinished && !dirty && (
        <Button
          variant="outlined"
          size="large"
          color="primary"
          onClick={goToNext}
        >
          {submitText || t('button.nextStep')}
        </Button>
      )}
      {isFinished && dirty && (
        <PrimaryButton size="large" onClick={goToNext}>
          {submitText || t('button.saveChanges')}
        </PrimaryButton>
      )}
      {!isFinished && (
        <PrimaryButton size="large" onClick={goToNext}>
          {submitText || t('button.saveContinue')}
        </PrimaryButton>
      )}
      {!hidePrevious && (!dirty || !isFinished) && (
        <Button
          size="large"
          color="primary"
          onClick={goToPrev}
          className={matches ? classes.previousButton : ''}
        >
          {t('button.previousStep')}
        </Button>
      )}
      {dirty && isFinished && (
        <Button size="large" color="primary" onClick={undo}>
          {t('button.undoChanges')}
        </Button>
      )}
    </section>
  );
}

WizardButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  isFinished: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  goToNext: PropTypes.func.isRequired,
  goToPrev: PropTypes.func.isRequired,
  undo: PropTypes.func.isRequired,
  hidePrevious: PropTypes.bool,
  disableSave: PropTypes.bool,
  newWizard: PropTypes.bool,
  isStreamSite: PropTypes.bool,
  submitText: PropTypes.string,
};

WizardButtons.defaultProps = {
  hidePrevious: false,
  newWizard: false,
  disableSave: false,
  isStreamSite: false,
  submitText: undefined,
};

export default withStyles(styles)(WizardButtons);
