import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTranslation } from 'react-i18next';
import OutlinedTextField from '../../OutlinedTextField';

import styles from './styles';
import FormHelperTextBottom from '../../FormHelperTextBottom';
import { validations } from '../../../../utils';

function PaymentForm({
  classes,
  values,
  touched,
  setFieldValue,
  errors,
  handleBlur,
  maxLengths,
}) {
  const { paymentOptions } = values;
  const { t } = useTranslation();
  const beforeDecimal = 10;
  const afterDecimal = 2;

  const handlePriceBlur = e => {
    const { value } = e.target;
    const withDecimal = parseFloat(value, beforeDecimal).toFixed(afterDecimal);
    const newValue = withDecimal === 'NaN' ? value : withDecimal;
    setFieldValue({
      target: { name: 'paymentOptions', value: { price: newValue } },
    });
    handleBlur(e);
  };

  const handlePriceChange = e => {
    const { value } = e.target;
    const newValue = value
      .replace(validations.nonDecimal, '')
      .replace(validations.beforeDecimal(beforeDecimal), '$1')
      .replace(validations.nonDoubleDot, '$1')
      .replace(validations.afterDecimal(afterDecimal), '$1');
    setFieldValue({
      target: { name: 'paymentOptions', value: { price: newValue } },
    });
  };

  return (
    <Grid container className={classes.content}>
      <Grid item sm={12}>
        <Typography variant="body2">{t('wizard.tile.price')}</Typography>
      </Grid>
      <Grid item sm={12} md={4} className={classes.bottomSpace}>
        <FormControl fullWidth>
          <OutlinedTextField
            id="price"
            name="paymentOptions.price"
            data-testid="price"
            value={paymentOptions.price}
            placeholder="0.00"
            onBlur={handlePriceBlur}
            onChange={handlePriceChange}
            error={
              touched.paymentOptions?.price && !!errors.paymentOptions?.price
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: maxLengths.price,
              autoComplete: 'off',
              'data-testid': 'price-input',
            }}
          />
        </FormControl>
        <FormHelperTextBottom
          left={touched.paymentOptions?.price && errors.paymentOptions?.price}
        />
      </Grid>
    </Grid>
  );
}

PaymentForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  maxLengths: PropTypes.object.isRequired,
};

export default withStyles(styles)(PaymentForm);
