import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Lock from '@material-ui/icons/Lock';

import { Box } from '@material-ui/core';
import { isCurrentSection } from '../../../../utils/wizard';
import { types } from '../../../../globals';
import styles from './styles';

export function Section({
  classes,
  page,
  currentSection,
  isFinished,
  isNextStep,
  newWizard,
}) {
  const isNotFinished = !isFinished;
  const isCurrentStep = isCurrentSection(currentSection, page);
  let textClass = newWizard
    ? classes.unfinishedText
    : classes.unfinishedTextDeprecated;

  if (isFinished) {
    textClass = newWizard ? classes.sectionTextBold : classes.sectionText;
  }

  if (isCurrentStep) {
    textClass = newWizard
      ? classes.sectionTextBold
      : classes.sectionTextBoldDeprecated;
  }

  return (
    newWizard ? (
      <section className={classes.section}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h5" className={textClass}>
              {page.navTitle}
            </Typography>
          </Box>
          {isFinished && (
            <Box>
              <CheckCircle
                data-testid="checked"
                className={classes.checkIcon}
              />
            </Box>
          )}
          {isNotFinished && !isCurrentStep && !isNextStep && (
            <Box>
              <Lock className={classes.lockIcon} data-testid="lock" />
            </Box>
          )}
        </Box>
      </section>
    ) : (
      <section className={classes.sectionDeprecated}>
        {isFinished && (
          <Check data-testid="checked" className={classes.icon} />
        )}
        {!isFinished && isNextStep && (
          <span className={classes.notFinishedCircle} data-testid="current" />
        )}
        {isNotFinished && !isNextStep && (
          <span
            className={classes.notFinishedPadding}
            data-testid="incomplete"
          />
        )}
        <Typography variant="h5" className={textClass}>
          {page.navTitle}
        </Typography>
      </section>
    )
  );
}

Section.propTypes = {
  classes: PropTypes.object.isRequired,
  currentSection: types.wizardPageType.isRequired,
  page: types.wizardPageType.isRequired,
  isFinished: PropTypes.bool.isRequired,
  isNextStep: PropTypes.bool.isRequired,
  newWizard: PropTypes.bool,
};

Section.defaultProps = {
  newWizard: false,
};

export default withStyles(styles)(Section);
