export default function styles(theme) {
  return {
    roleCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    youText: {
      color: theme.palette.secondary.medium,
      fontWeight: 'bold',
    },
    buttons: {
      '& button:not(:last-child)': {
        margin: '6px',
      },
    },
  };
}
