import { connect } from 'react-redux';
import TileComplete from './TileComplete';
import { selectFirstName } from '../../../store/user/selectors';

export function mapStateToProps(state) {
  return {
    firstName: selectFirstName(state),
  };
}

export default connect(
  mapStateToProps,
  {},
)(TileComplete);
