import { useMemo } from 'react';
import {
  objectOf,
  string,
  func,
  oneOf,
  node,
  oneOfType,
  number,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import MaterialTabs from '@material-ui/core/Tabs';
import TabsContext from './context';

import Tab from './Tab';

import styles from './styles';

export function Tabs({ classes, value, onChange, variant, children, ...rest }) {
  const contextProviderProps = useMemo(() => ({classes}), [classes]);
  return (
    <TabsContext.Provider value={contextProviderProps}>
      <MaterialTabs
        value={value}
        onChange={onChange}
        className={classes.tabs}
        variant={variant}
        {...rest}
      >
        {children}
      </MaterialTabs>
    </TabsContext.Provider>
  );
}

Tabs.Tab = Tab;

Tabs.propTypes = {
  classes: objectOf(string).isRequired,
  value: oneOfType([string, number]).isRequired,
  onChange: func,
  variant: oneOf(['standard', 'scrollable', 'fullWidth']),
  children: node.isRequired,
};

Tabs.defaultProps = {
  variant: 'standard',
  onChange: null,
};

export default withStyles(styles)(Tabs);
