import { useMemo } from 'react';
import { objectOf, string, func, node, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useMediaQuery, Dialog } from '@material-ui/core';

import ModalContext from './context';
import Title from './Title';
import Content from './Content';
import Actions from './Actions';

import styles from './styles';

export function Modal({
  classes,
  className,
  open,
  onClose,
  children,
  ...restProps
}) {
  const matches = useMediaQuery('(min-width:1280px)');
  const contextProviderProps = useMemo(() => ({classes, onClose}), [classes, onClose]);

  return (
    <Dialog
      fullScreen={!matches}
      open={open}
      onClose={onClose}
      classes={{ paper: `${classes.dialogPaper} ${className}` }}
      {...restProps}
    >
      <ModalContext.Provider value={contextProviderProps}>
        {children}
      </ModalContext.Provider>
    </Dialog>
  );
}

Modal.Title = Title;
Modal.Content = Content;
Modal.Actions = Actions;

Modal.propTypes = {
  classes: objectOf(string).isRequired,
  className: string,
  open: bool.isRequired,
  onClose: func.isRequired,
  children: node.isRequired,
};

Modal.defaultProps = {
  className: '',
};

export default withStyles(styles)(Modal);
