import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSetCurrentPageByChannel from '../useSetCurrentPageByChannel';
import { getPage } from '../../../store/pages/actions';
import { selectPage } from '../../../store/pages/selectors';

export function useGetPage(channelType, pageStatus) {
  const dispatch = useDispatch();
  const currentPage = useSetCurrentPageByChannel({ channelType, pageStatus });

  const { loadingPage, hasLoadedPages, page } = useSelector(state => ({
    loadingPage: state.pages.loadingPage,
    hasLoadedPages: state.pages.hasLoadedPages,
    page: selectPage(state, state.pages.currentPage.id) || {},
  }));

  useEffect(() => {
    if (currentPage && currentPage.id) {
      dispatch(getPage(currentPage));
    }
  }, [currentPage, dispatch]);

  return {
    page,
    isLoading: loadingPage || !hasLoadedPages,
  };
}

export default useGetPage;
