import { object, func, string, objectOf, bool } from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import OutlinedSelect from '../OutlinedSelect';
import OutlinedTextField from '../OutlinedTextField';
import FormHelperTextBottom from '../FormHelperTextBottom';

import { validations } from '../../../utils';
import states from './states.json';

import styles from './styles';

function AddressInputs({
  classes,
  values,
  maxLengths,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  setCityOwnRow,
}) {
  const { address, address2, city, state, zip } = values;
  const { t } = useTranslation();

  const handleChange = e => {
    const { name, value } = e.target;
    let newVal = value;
    if (['city'].includes(name)) {
      newVal = newVal.replace(validations.nonAlphanumeric, '');
    }
    setFieldValue({ target: { name, value: newVal } });
  };

  return (
    <>
      <Grid item>
        <FormControl fullWidth className={setCityOwnRow ? classes.reduced : ''}>
          <OutlinedTextField
            showCount
            id="address"
            name="address"
            data-testid="address"
            value={address}
            label={t('headerManagement.address')}
            inputProps={{
              maxLength: maxLengths.address,
              'data-testid': 'address-input',
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.address && touched.address}
            errorMessage={touched.address && errors.address}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth className={setCityOwnRow ? classes.reduced : ''}>
          <OutlinedTextField
            showCount
            id="address2"
            name="address2"
            data-testid="address2"
            value={address2}
            label={t('headerManagement.address2')}
            inputProps={{
              maxLength: maxLengths.address2,
              'data-testid': 'address2-input',
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.address2 && touched.address2}
            errorMessage={touched.address2 && errors.address2}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item xs={12} md={setCityOwnRow ? 12 : 4}>
            <FormControl
              fullWidth
              className={setCityOwnRow ? classes.reduced : ''}
            >
              <OutlinedTextField
                showCount
                id="city"
                name="city"
                data-testid="city"
                value={city}
                label={t('headerManagement.city')}
                inputProps={{
                  maxLength: maxLengths.city,
                  'data-testid': 'city-input',
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.city && touched.city}
                errorMessage={touched.city && errors.city}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={setCityOwnRow ? 6 : 4}
            className={setCityOwnRow ? '' : classes.stateContainer}
          >
            <FormControl fullWidth>
              <OutlinedSelect
                id="state"
                name="state"
                value={state || ''}
                label={t('headerManagement.state')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.state && touched.state}
                inputProps={{
                  'data-testid': 'select-state',
                }}
              >
                <MenuItem value="">
                  <em>Select State</em>
                </MenuItem>
                {states.map(s => {
                  return (
                    <MenuItem key={s.abbreviation} value={s.abbreviation}>
                      <span>{s.name}</span>
                    </MenuItem>
                  );
                })}
              </OutlinedSelect>
              {errors.state && touched.state && (
                <FormHelperTextBottom left={errors.state} />
              )}
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={setCityOwnRow ? 6 : 4}
            className={classes.stateContainer}
          >
            <FormControl fullWidth>
              <OutlinedTextField
                showCount
                id="zip"
                name="zip"
                data-testid="zip"
                value={zip}
                label={t('headerManagement.zip')}
                inputProps={{
                  maxLength: maxLengths.zip,
                  'data-testid': 'zip-input',
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.zip && touched.zip}
                errorMessage={touched.zip && errors.zip}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

AddressInputs.propTypes = {
  classes: objectOf(string).isRequired,
  values: object.isRequired,
  maxLengths: object.isRequired,
  setFieldValue: func.isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  handleBlur: func.isRequired,
  setCityOwnRow: bool,
};

AddressInputs.defaultProps = {
  setCityOwnRow: false,
};

export default withStyles(styles)(AddressInputs);
