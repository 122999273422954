import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { NotFound } from '../../common';

import { userIsAllowed } from '../../../utils/permissions';
import withScrollToTop from '../../hoc/withScrollToTop';

export function PrivateRoute({
  isLoggedIn,
  loadingAuth,
  component: Component,
  grantedActions,
  requiredGrants,
  optionalGrants,
  ...rest
}) {
  const WrappedComponent = useMemo(
    () => withScrollToTop(Component),
    [Component],
  );

  const renderRoute = (props) => {
    const { location } = props;
    if (loadingAuth) {
      return null;
    }
    if (
      isLoggedIn &&
      !userIsAllowed(grantedActions, requiredGrants, optionalGrants)
    ) {
      return <NotFound />;
    }
    if (isLoggedIn) {
      return <WrappedComponent {...rest} {...props} />;
    }
    return (
      <Redirect
        data-testid="redirect"
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  };
  return (
    <Route {...rest} render={renderRoute} style={{ overflow: 'hidden' }} />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  location: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loadingAuth: PropTypes.bool.isRequired,
  grantedActions: PropTypes.arrayOf(PropTypes.string).isRequired,
  requiredGrants: PropTypes.arrayOf(PropTypes.string),
  optionalGrants: PropTypes.arrayOf(PropTypes.string),
};

PrivateRoute.defaultProps = {
  requiredGrants: [],
  optionalGrants: [],
};

export default withRouter(PrivateRoute);
