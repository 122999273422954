export default function styles(theme) {
  return {
    addUser: {
      float: 'right',
      position: 'relative',
      bottom: 80,
      [theme.breakpoints.down('sm')]: {
        float: 'none',
        bottom: 0,
      },
      [theme.breakpoints.down('xs')]: {
        float: 'none',
        bottom: 0,
      },
    },
    list: {
      backgroundColor: theme.palette.white,
    },
  };
}
