import { connect } from 'react-redux';

import Reports from './Reports';

export function mapStateToProps(state) {
  return {
    isPFOrganization: state.organizations.currentOrganization.isPFOrganization,
  };
}

export default connect(mapStateToProps)(Reports);
