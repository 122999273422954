import useFeatures from 'utils/hooks/useFeatures/useFeatures';
import useUser from 'utils/hooks/useUser';

const usePaidFeatures = () => {
  const { wasInternal } = useUser();
  const {
    KioskSetup: ffKioskSetup,
    VancoVideo: ffVancoVideo,
    ContentManager: ffContentManager,
    Communications: ffCommunications,
    Insights: ffInsights,
  } = useFeatures();

  const showPaidFeatures =
    wasInternal &&
    [
      ffKioskSetup,
      ffCommunications,
      ffContentManager,
      ffVancoVideo,
      ffInsights,
    ].includes(true);

  return showPaidFeatures;
};

export default usePaidFeatures;
