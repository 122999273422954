import { connect } from 'react-redux';

import { savePage, removePage } from '../../../store/pages/actions';
import { setError, setSuccess } from '../../../store/alerts/actions';

import StreamList from './StreamList';

export function mapStateToProps(state) {
  return {
    loadingSave: state.pages.loadingSave,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, {
  savePage,
  setSnackbar: setError,
  setSuccess,
  removePage,
})(StreamList);
