import {
  TOGGLE_LEFT_NAV,
  TOGGLE_PROFILE_NAV,
  TOGGLE_APPS_NAV,
  CLOSE_NAVS,
} from './types';

export function toggleLeftNav() {
  return {
    type: TOGGLE_LEFT_NAV,
  };
}

export function toggleProfileNav() {
  return {
    type: TOGGLE_PROFILE_NAV,
  };
}

export function toggleAppsNav() {
  return {
    type: TOGGLE_APPS_NAV,
  };
}

export function closeNavs() {
  return {
    type: CLOSE_NAVS,
  };
}
