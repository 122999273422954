import { PAGE_ACTIVE } from '../../../globals/constants';
import i18n from '../../../utils/i18n';

export const streamPagePaths = {
  GET_STARTED: '/stream/get-started',
  INFO_WITHOUT_ID: '/wizard/stream/page-info',
  INFO: '/wizard/stream/:pageId/page-info',
  BRANDING: '/wizard/stream/:pageId/branding', // Remove on ChannelManagement FF cleanup
  GIVING: '/wizard/stream/:pageId/giving',
  CHAT: '/wizard/stream/:pageId/chat',
  REVIEW: '/wizard/stream/:pageId/review',
  STREAM_SITE_CREATE: '/stream/settings',
};

export function createConfig(page) {
  const paths = streamPagePaths;
  const newNavTitlePath = page?.id ? paths.INFO : paths.INFO_WITHOUT_ID;
  return [
    {
      path: paths.GET_STARTED,
    },
    {
      navTitle: i18n.t('stream.pageWizard.basicInfo.key'),
      pageTitleKey: 'stream.pageWizard.basicInfo.title',
      path: newNavTitlePath,
      nextPath: paths.GIVING,
    },
    {
      navTitle: i18n.t('stream.pageWizard.giving.key'),
      pageTitleKey: 'stream.pageWizard.giving.title',
      path: paths.GIVING,
      nextPath: paths.CHAT,
    },
    {
      navTitle: i18n.t('stream.pageWizard.chat.key'),
      pageTitleKey: 'stream.pageWizard.chat.title',
      path: paths.CHAT,
      nextPath: paths.REVIEW,
    },
    {
      navTitle: i18n.t('stream.pageWizard.review.key'),
      pageTitleKey: 'stream.pageWizard.review.title',
      path: paths.REVIEW,
      nextPath: '/streams',
    },
  ];
}

export function createConfigDeprecated(page) {
  const paths = streamPagePaths;
  const newNavTitlePath = page?.id ? paths.INFO : paths.INFO_WITHOUT_ID;
  return [
    {
      path: paths.GET_STARTED,
    },
    {
      navTitle: i18n.t('stream.pageWizard.basicInfo.key'),
      pageTitleKey: 'stream.pageWizard.basicInfo.title',
      path: newNavTitlePath,
      nextPath: paths.BRANDING,
    },
    {
      navTitle: i18n.t('stream.pageWizard.branding.key'),
      pageTitleKey: 'stream.pageWizard.branding.title',
      path: paths.BRANDING,
      nextPath: paths.GIVING,
    },
    {
      navTitle: i18n.t('stream.pageWizard.giving.key'),
      pageTitleKey: 'stream.pageWizard.giving.title',
      path: paths.GIVING,
      nextPath: paths.CHAT,
    },
    {
      navTitle: i18n.t('stream.pageWizard.chat.key'),
      pageTitleKey: 'stream.pageWizard.chat.title',
      path: paths.CHAT,
      nextPath: paths.REVIEW,
    },
    {
      navTitle: i18n.t('stream.pageWizard.review.key'),
      pageTitleKey: 'stream.pageWizard.review.title',
      path: paths.REVIEW,
      nextPath: '/streams',
    },
  ];
}

export function createMultipleStreamsConfig() {
  const paths = streamPagePaths;
  return [
    {
      path: paths.GET_STARTED,
    },
    {
      navTitle: i18n.t('stream.siteCreate.basicInfo.key'),
      pageTitleKey: 'stream.siteCreate.basicInfo.title',
      path: paths.STREAM_SITE_CREATE,
    },
  ];
}

export function streamPageNavConfig(page, features) {
  let nextFound = false;
  // eslint-disable-next-line no-nested-ternary
  const config = features?.MultipleStreams
    ? createMultipleStreamsConfig()
    : features?.ChannelManagement
    ? createConfig(page)
    : createConfigDeprecated(page);
  return config.map((step) => {
    const newStep = { ...step };
    switch (step.navTitle) {
      case i18n.t('stream.pageWizard.basicInfo.key'): {
        const finished = !!page.id;
        if (finished) {
          newStep.isFinished = true;
        }
        break;
      }
      case i18n.t('stream.pageWizard.branding.key'): {
        const finished = !!page.primaryColor;
        if (finished) {
          newStep.isFinished = true;
        }
        break;
      }
      case i18n.t('stream.pageWizard.giving.key'): {
        if (
          page.streamGivingOption &&
          (!!page.streamGivingOption.campaignId ||
            !!page.streamGivingOption.locationId)
        ) {
          newStep.isFinished = true;
        }
        break;
      }
      case i18n.t('stream.pageWizard.chat.key'): {
        if (
          !!page.id &&
          (!page.streamData?.chatEnabled ||
            page.streamData?.chatTurnOnMinutes !== -1)
        ) {
          newStep.isFinished = true;
        }
        break;
      }
      default:
      // Do nothing
    }

    if (page.status === PAGE_ACTIVE) {
      newStep.isFinished = true;
    } else if (newStep.navTitle && !newStep.isFinished && !nextFound) {
      newStep.next = true;
      nextFound = true;
    }

    return newStep;
  });
}

export default streamPageNavConfig;
