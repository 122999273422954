import { connect } from 'react-redux';
import { createPage } from '../../../store/pages/actions';

import GetStartedVancoMobile from './GetStartedVancoMobile';

export const mapStateToProps = state => ({
  loadingPages: state.pages.loadingPages,
  hasLoadedPages: state.pages.hasLoadedPages,
  features: state.features?.features,
  loadingCreate: state.pages.loadingCreate,
  communityFeaturesChoosen: state.pages.communityFeaturesChoosen,
});

export default connect(mapStateToProps, { createPage })(GetStartedVancoMobile);
