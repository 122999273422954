import { shallowEqual, useSelector } from 'react-redux';

export const useFeatures = () =>
  useSelector(
    (state) => ({
      ...(state?.features?.features ?? {}),
      hasLoadedFeatures: state?.features?.hasLoadedFeatures ?? false,
      isFetching: state?.features?.isFetching ?? false,
    }),
    shallowEqual,
  );

export default useFeatures;
