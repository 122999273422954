import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

import {
  UNSPLASH_IMAGES_PER_PAGE,
  UNSPLASH_UTM_PARAMS,
  UNSPLASH_SEARCH_TERM,
} from '../../../globals/constants';
import { useDebounce } from '../../../utils/hooks';
import { getPhotos, clearPhotos } from '../../../store/unsplash/actions';
import OutlinedTextField from '../OutlinedTextField';
import UnsplashImage from './UnsplashImage';
import ExternalLink from '../ExternalLink';
import styles from './styles';

export function Unsplash({
  classes,
  unsplashActions,
  loadingPhotos,
  photos,
  currentPage,
  error,
  onSelect,
  selected,
}) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search || UNSPLASH_SEARCH_TERM, 500);

  useEffect(() => {
    unsplashActions.clearPhotos();
    unsplashActions.getPhotos(1, debouncedSearch);
  }, [debouncedSearch, unsplashActions]);

  const setSelected = img => {
    onSelect(img);
  };

  const onSearch = e => {
    const { value } = e.target;
    setSearch(value);
  };

  const onViewMore = () => {
    unsplashActions.getPhotos(currentPage + 1, debouncedSearch);
  };

  function renderPhotos() {
    if (photos.length <= 0) return null;

    return photos.map((photo, index) => {
      const { urls, user, links } = photo;
      const isSelected = selected && photo.id === selected.id;

      if (index >= currentPage * UNSPLASH_IMAGES_PER_PAGE) return null;

      return (
        <UnsplashImage
          key={photo.id}
          id={photo.id}
          urls={urls}
          user={user}
          links={links}
          isSelected={isSelected}
          onSelect={setSelected}
        />
      );
    });
  }

  function renderPhotosFooter() {
    if (error)
      return (
        <div className={classes.error}>
          <Typography color="inherit">{error}</Typography>
        </div>
      );
    if (loadingPhotos)
      return (
        <div className={classes.loadMore}>
          <CircularProgress size={26} color="primary" data-testid="loading" />
        </div>
      );
    if (photos.length === 0)
      return (
        <div className={classes.empty}>
          <Typography>{t('imageSelection.noResults')}</Typography>
        </div>
      );
    if (photos.length > 0)
      return (
        <div className={classes.loadMore}>
          <Button className={classes.loadButton} onClick={onViewMore}>
            {t('imageSelection.viewMore')}
          </Button>
        </div>
      );
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <OutlinedTextField
        className={classes.search}
        value={search}
        onChange={onSearch}
        placeholder={t('imageSelection.searchImages')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.imagesBy}>
        {t('imageSelection.imagesBy')}
        <ExternalLink
          to={`https://unsplash.com${UNSPLASH_UTM_PARAMS}`}
          className={classes.unsplashLink}
        >
          Unsplash
        </ExternalLink>
      </div>
      <div className={classes.images}>
        {renderPhotos()}
        {renderPhotosFooter()}
      </div>
    </div>
  );
}

Unsplash.propTypes = {
  classes: PropTypes.object.isRequired,
  unsplashActions: PropTypes.object.isRequired,
  loadingPhotos: PropTypes.bool.isRequired,
  photos: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPage: PropTypes.number.isRequired,
  error: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    src: PropTypes.string,
  }),
};

Unsplash.defaultProps = {
  error: null,
  selected: {
    id: '',
    src: '',
  },
};

export function mapStateToProps(state) {
  const { loadingPhotos, photos, currentPage, photosError } = state.unsplash;

  return { loadingPhotos, photos, currentPage, error: photosError };
}

export function mapDispatchToProps(dispatch) {
  return {
    unsplashActions: bindActionCreators({ getPhotos, clearPhotos }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Unsplash));
