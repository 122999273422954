import { string, object, node, oneOf, func } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { Button, Box } from '@material-ui/core';
import { ReactComponent as CardInfoIcon } from '../../../assets/cardInfoIcon.svg';
import styles from './styles';

function DisclosureMessage({
  classes,
  variant,
  title,
  message,
  className,
  children,
  onLearnMore,
}) {
  const { t } = useTranslation();
  const isDefault = variant === 'default';
  return (
    variant === 'single' ? (
      <Box className={`${classes.disclosureSingle} ${className}`}>
        <Box>
          <CardInfoIcon className={classes.icon} />
        </Box>

        <Box>
          <Typography className={classes.disclosureMessageSingle}>
            {message}
            {onLearnMore && (
              <Button
                onClick={onLearnMore}
                className={classes.learMoreButton}
                color="primary"
              >
                {t('app.learnMore')}
              </Button>
            )}
          </Typography>
        </Box>
      </Box>
    ) : (
      <div
        className={`${
          isDefault ? classes.disclosure : classes.disclosureInline
        } ${className}`}
      >
        <Typography
          className={
            isDefault
              ? classes.disclosureTitle
              : classes.disclosureTitleInline
          }
        >
          <CardInfoIcon />
          {title}
        </Typography>
        <Typography
          className={
            isDefault
              ? classes.disclosureMessage
              : classes.disclosureMessageInline
          }
        >
          {message}
        </Typography>
        {children}
      </div>
    )
  );
}

DisclosureMessage.propTypes = {
  classes: object.isRequired,
  variant: oneOf(['default', 'inline', 'single']),
  title: string,
  message: string.isRequired,
  className: string,
  children: node,
  onLearnMore: func,
};

DisclosureMessage.defaultProps = {
  children: null,
  className: '',
  title: '',
  variant: 'default',
  onLearnMore: null,
};

export default withStyles(styles)(DisclosureMessage);
