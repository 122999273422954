import { bool, node, string } from 'prop-types';
import { TableCell } from '@material-ui/core';
import clsx from 'clsx';
import { useTableContext } from '../context';

export function TableHeadCell({ menu, className, children }) {
  const { classes, disabled } = useTableContext();

  const cellClasses = clsx({
    [classes.tableHeadCell]: disabled,
    [classes.menu]: menu,
    [className]: !!className,
  });

  return <TableCell className={cellClasses}>{children}</TableCell>;
}

TableHeadCell.propTypes = {
  menu: bool,
  children: node,
  className: string,
};

TableHeadCell.defaultProps = {
  menu: false,
  children: null,
  className: '',
};

export default TableHeadCell;
