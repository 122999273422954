import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import styles from './styles';

function PrimaryButton(props) {
  const { children, ...buttonProps } = props;
  return (
    <Button variant="contained" color="primary" {...buttonProps}>
      {children}
    </Button>
  );
}

PrimaryButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(PrimaryButton);
