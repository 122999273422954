import { Form as FormikForm } from 'formik';
import { object, func, bool, objectOf, string } from 'prop-types';
import { withStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import AppPermissionsInputs from '../../AppPermissionsInputs/AppPermissionsInputs';
import PrimaryButton from '../../PrimaryButton';

import styles from './styles';

export function Form({
  classes,
  values,
  errors,
  setFieldValue,
  touched,
  handleBlur,
  loading,
  dirty,
  onCancel,
  maxLengths,
  features,
}) {
  const { t } = useTranslation();
  return (
    <FormikForm>
      <AppPermissionsInputs
        values={values}
        errors={errors}
        maxLengths={maxLengths}
        setFieldValue={setFieldValue}
        touched={touched}
        handleBlur={handleBlur}
        isSettingsView
        features={features}
      />
      <Grid item className={classes.buttonsContainer}>
        {!loading ? (
          <>
            <Button onClick={onCancel} color="primary" size="large">
              {t('button.cancel')}
            </Button>
            <PrimaryButton
              size="large"
              type="submit"
              disabled={!dirty || Object.keys(errors || {}).length > 0}
            >
              {t('button.save')}
            </PrimaryButton>
          </>
        ) : (
          <CircularProgress
            color="primary"
            data-testid="mobile-setup-spinner"
          />
        )}
      </Grid>
    </FormikForm>
  );
}

Form.propTypes = {
  classes: objectOf(string).isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  setFieldValue: func.isRequired,
  touched: object.isRequired,
  handleBlur: func.isRequired,
  loading: bool.isRequired,
  dirty: bool.isRequired,
  onCancel: func,
  maxLengths: object.isRequired,
  features: objectOf(bool),
};

Form.defaultProps = {
  onCancel: undefined,
  features: {},
};

export default withStyles(styles)(Form);
