import { bool, func, objectOf, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Form } from 'formik';
import { useTranslation } from 'react-i18next';
import useGetOrgSettings from '../../../../utils/hooks/useGetOrgSettings';
import { Modal } from '../../../common';
import CustomFormik from '../../../common/CustomFormik';
import DisableStreamForm from './Form';
import { createSchema } from './validation';

import styles from './styles';

export function DisableMobileModal({
  setSuccess,
  saveOrganizationSettings,
  classes,
  open,
  onClose,
  organizationId,
}) {
  const { settings } = useGetOrgSettings(organizationId);

  const { t } = useTranslation();
  const schema = createSchema();

  const onSubmit = async (values) => {
    const formValues = {
      ...settings,
      mobileEnabled: values.enableVancoMobile,
    };

    const succeeded = await saveOrganizationSettings(
      organizationId,
      formValues,
    );
    if (succeeded) {
      const successMessage = values.enableVancoMobile
        ? `${t('vanco.mobile.manageAccess.modal.enableSuccess')}`
        : t('vanco.mobile.manageAccess.modal.disableSuccess');
      setSuccess(successMessage);
    }
    onClose();
  };

  const close = (resetForm) => () => {
    onClose();
    resetForm();
  };

  return (
    <CustomFormik
      initialValues={{
        enableVancoMobile: settings?.mobileEnabled,
      }}
      enableReinitialize
      onSubmit={onSubmit}
      render={(formikProps) => (
        <Form>
          <Modal open={open} onClose={close(formikProps.resetForm)}>
            <Modal.Title className={classes.title}>
              {t('vanco.mobile.manageAccess.modal.title')}
            </Modal.Title>
            <DisableStreamForm
              handleSubmit={onSubmit}
              handleClose={close(formikProps.resetForm)}
              {...formikProps}
            />
          </Modal>
        </Form>
      )}
      validationSchema={schema}
    />
  );
}

DisableMobileModal.propTypes = {
  setSuccess: func.isRequired,
  saveOrganizationSettings: func.isRequired,
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func.isRequired,
  organizationId: string.isRequired,
};

DisableMobileModal.defaultProps = {
  open: false,
};

export default withStyles(styles)(DisableMobileModal);
