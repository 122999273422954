import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from '@material-ui/core';
import Done from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import styles from './styles';
import {
  ButtonMenu,
  OutlinedSelect,
  OutlinedTextField,
  Page,
  PageTitle,
} from '../../../common';
import { ReactComponent as KioskIcon } from '../../../../assets/kioskSectionIcon.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/copyBlackIcon.svg';
import { BUTTON_TEXT_OPTIONS_KIOSK } from '../../../../globals/constants';
import copyText from '../../../../utils/text/copyText';
import KioskDisableModal from '../../../common/KioskDisableModal';
import useGetKiosk, {
  GET_KIOSK_QUERY,
} from '../../../../utils/hooks/useGetKiosk';
import { CUSTOM_LABEL_OPTION, KioskFormKeys, useKioskForm } from './validation';
import KioskWelcome from '../../../common/KioskWelcome';

function KioskSetup({
  classes,
  wasInternal,
  updateKiosk,
  setError,
  setSuccess,
}) {
  const { t } = useTranslation();
  const { kiosk, hasKiosk } = useGetKiosk();
  const kioskForm = useKioskForm(kiosk);
  const queryClient = useQueryClient();
  const [showActionModal, setShowActionModal] = useState(false);
  const [showWelcomeBanner, setShowWelcomeBanner] = useState(
    !wasInternal && kiosk?.createdDate === kiosk?.lastUpdatedDate,
  );

  const openModal = () => setShowActionModal(true);
  const closeModal = () => setShowActionModal(false);
  const copyCodeSuccess = () =>
    setSuccess(t('kiosk.setup.credentials.code.copySuccess'));
  const copyCodeFail = () =>
    setError(t('kiosk.setup.credentials.code.copyFail'));

  const toggleMessageField = () => {
    const { showWelcomeMessage } = kioskForm.values;
    if (showWelcomeMessage) {
      kioskForm.setFieldValue(KioskFormKeys.WELCOME_MESSAGE, null);
    }
    kioskForm.setFieldValue(KioskFormKeys.SHOW_MESSAGE, !showWelcomeMessage);
  };

  const copyAccessCode = () => {
    copyText(kiosk?.accessCode).then(copyCodeSuccess).catch(copyCodeFail);
  };

  const closeBanner = () => {
    setShowWelcomeBanner(false);
  };

  const onButtonLabelSelected = (e) => {
    const label = e.target.value;
    if (label === CUSTOM_LABEL_OPTION) {
      kioskForm.setFieldValue(KioskFormKeys.CUSTOM_LABEL, true);
      return;
    }
    kioskForm.setFieldValue(KioskFormKeys.CUSTOM_LABEL, false);
    kioskForm.setFieldValue(KioskFormKeys.BUTTON_LABEL, label);
  };

  const onChangeSuccess = () => {
    queryClient.resetQueries({ queryKey: [GET_KIOSK_QUERY], exact: true });
  };

  const saveChanges = async () => {
    kioskForm.setSubmitting(true);
    const wasUpdated = await updateKiosk(kioskForm.values);
    if (wasUpdated) {
      setSuccess(t('kiosk.setup.settings.update.success'));
    }
    onChangeSuccess();
    kioskForm.setSubmitting(false);
  };

  if (showWelcomeBanner) {
    return (
      <Page>
        <KioskWelcome onClickStart={closeBanner} />
      </Page>
    );
  }
  return (
    <Page>
      <div className={classes.titleContainer}>
        <PageTitle>{t('kiosk.setup.title')}</PageTitle>
        {wasInternal && (
          <ButtonMenu buttonContent={t('kiosk.setup.actions')}>
            <ButtonMenu.Item onClick={openModal}>
              <KioskIcon className={classes.kioskIcon} />
              <Typography>{t('kiosk.setup.manage')}</Typography>
            </ButtonMenu.Item>
          </ButtonMenu>
        )}
      </div>
      {!hasKiosk && wasInternal && (
        <Typography>
          {t('kiosk.setup.disabled')}
          <Button
            className={classes.linkButton}
            color="primary"
            onClick={openModal}
          >
            {t('kiosk.setup.access')}
          </Button>
        </Typography>
      )}
      {hasKiosk && (
        <Paper className={classes.paperContainer}>
          <div>
            <PageTitle className={classes.sectionTitle}>
              {t('kiosk.setup.credentials.title')}
            </PageTitle>
            <span className={classes.credentialWrapper}>
              <Typography className={classes.credentialKey}>
                {t('kiosk.setup.credentials.clientId')}
              </Typography>
              <Typography>{kiosk?.organization.clientId}</Typography>
            </span>
            <span className={classes.credentialWrapper}>
              <Typography className={classes.credentialKey}>
                {t('kiosk.setup.credentials.code')}
              </Typography>
              <Typography>{kiosk?.accessCode}</Typography>
              <IconButton onClick={copyAccessCode}>
                <CopyIcon />
              </IconButton>
            </span>
          </div>
          <Divider className={classes.credentialDivider} />
          <div>
            <PageTitle className={classes.sectionTitle}>
              {t('kiosk.setup.settings.title')}
            </PageTitle>
            <div className={classes.settingsSubsection}>
              <Typography className={classes.paragraphHeader}>
                {t('kiosk.setup.settings.message.title')}
              </Typography>
              <Typography className={classes.paragraph}>
                {t('kiosk.setup.settings.message.description')}
              </Typography>
              <RadioGroup onChange={toggleMessageField}>
                <FormControlLabel
                  value="false"
                  className={classes.radioButton}
                  control={
                    <Radio
                      color="primary"
                      checked={!kioskForm.values.showWelcomeMessage}
                    />
                  }
                  label={t('kiosk.setup.settings.message.no')}
                />
                <FormControlLabel
                  value="true"
                  className={classes.radioButton}
                  control={
                    <Radio
                      color="primary"
                      checked={kioskForm.values.showWelcomeMessage}
                    />
                  }
                  label={t('kiosk.setup.settings.message.yes')}
                />
              </RadioGroup>
              {kioskForm.values.showWelcomeMessage && (
                <span className={classes.messageTextField}>
                  <OutlinedTextField
                    id={KioskFormKeys.WELCOME_MESSAGE}
                    name={KioskFormKeys.WELCOME_MESSAGE}
                    showCount
                    value={kioskForm.values.welcomeMessage}
                    label={t('kiosk.setup.settings.message.label')}
                    onChange={kioskForm.handleChange}
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </span>
              )}
            </div>
            <div className={classes.settingsSubsection}>
              <Typography className={classes.paragraphHeader}>
                {t('kiosk.setup.settings.customButton.title')}
              </Typography>
              <Typography className={classes.paragraph}>
                {t('kiosk.setup.settings.customButton.description')}
              </Typography>

              <div className={classes.configurationSection}>
                <OutlinedSelect
                  className={classes.labelDropdown}
                  label={t('kiosk.setup.settings.customButton.select.label')}
                  onChange={onButtonLabelSelected}
                  value={
                    kioskForm.values.isCustomButtonLabel
                      ? CUSTOM_LABEL_OPTION
                      : kioskForm.values.buttonLabel
                  }
                  renderValue={(value) => value}
                >
                  <Typography
                    variant="body2"
                    className={classes.buttonLabelHeader}
                  >
                    {t('kiosk.setup.settings.customButton.select.custom')}
                  </Typography>
                  <MenuItem
                    value={CUSTOM_LABEL_OPTION}
                    key={CUSTOM_LABEL_OPTION}
                  >
                    {kioskForm.values.isCustomButtonLabel ? (
                      <Done color="primary" className={classes.doneIcon} />
                    ) : (
                      <span className={classes.unselected} />
                    )}
                    {t('kiosk.setup.settings.customButton.text.label')}
                  </MenuItem>
                  <Divider className={classes.menuItemDivider} />
                  <Typography
                    variant="body2"
                    className={classes.buttonLabelHeader}
                  >
                    {t('kiosk.setup.settings.customButton.select.suggestion')}
                  </Typography>
                  {BUTTON_TEXT_OPTIONS_KIOSK.map((option) => (
                    <MenuItem value={option} key={option}>
                      {kioskForm.values.buttonLabel === option &&
                      !kioskForm.values.isCustomButtonLabel ? (
                        <Done color="primary" className={classes.doneIcon} />
                      ) : (
                        <span className={classes.unselected} />
                      )}
                      {option}
                    </MenuItem>
                  ))}
                </OutlinedSelect>
                {kioskForm.values.isCustomButtonLabel && (
                  <OutlinedTextField
                    id={KioskFormKeys.BUTTON_LABEL}
                    name={KioskFormKeys.BUTTON_LABEL}
                    className={classes.labelTextField}
                    showCount
                    value={kioskForm.values.buttonLabel}
                    label={t('kiosk.setup.settings.customButton.text.label')}
                    inputProps={{
                      maxLength: 15,
                    }}
                    onChange={kioskForm.handleChange}
                  />
                )}
              </div>
            </div>
            <span className={classes.actionButtonGroup}>
              <Button color="primary" onClick={kioskForm.resetForm}>
                {t('kiosk.setup.cancel')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={kioskForm.isSubmitting}
                onClick={saveChanges}
              >
                {t('kiosk.setup.save')}
              </Button>
            </span>
          </div>
        </Paper>
      )}
      {wasInternal && (
        <KioskDisableModal
          open={showActionModal}
          onClose={closeModal}
          onChangeSuccess={onChangeSuccess}
        />
      )}
    </Page>
  );
}
KioskSetup.propTypes = {
  classes: PropTypes.object.isRequired,
  wasInternal: PropTypes.bool.isRequired,
  updateKiosk: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
};

export default withStyles(styles)(KioskSetup);
