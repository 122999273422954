import { arrayOf, bool, func, objectOf, object, string } from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Tabs from './Tabs';
import Tile from './Tile';
import {
  Page,
  PageTitle,
  PagePaper,
  NoTiles,
  QRCodeModal,
  CreateButton,
} from '../../common';
import EditDrawer from '../../common/EditDrawer';
import ArchivedModal from './ArchivedModal';
import DeleteModal from './DeleteModal';
import List from './List';
import ArchivedTiles from './ArchivedTiles';
import CreateTileModal from '../../common/CreateTileModal';

import { constants } from '../../../globals';
import { defaultTile } from '../../../globals/defaults';
import {
  useCreateTile,
  useGetTiles,
  useCustomState,
  useDeleteTileWithConfirmModal,
  usePatchTile,
  useQRCodeModal,
} from '../../../utils/hooks';
import {
  sortTilesByArchivedDate,
  filterTilesByStatus,
} from '../../../utils/tiles';

import styles from './styles';
import { VO_QR_QUERY_PARAMETER } from '../../../globals/constants';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

const tabPaths = {
  [constants.TILE_ALL.toLowerCase()]: constants.TILE_ALL,
  [constants.TILE_VISIBLE.toLowerCase()]: constants.TILE_VISIBLE,
  [constants.TILE_HIDDEN.toLowerCase()]: constants.TILE_HIDDEN,
  [constants.TILE_PENDING.toLowerCase()]: constants.TILE_PENDING,
};

const initialState = {
  selectedTile: null,
  archiveTileModalOpen: false,
  drawerOpen: false,
  archiveModalOpen: false,
  createModalOpen: false,
};
export function TilesList({
  classes,
  loadingDialogIsOpen,
  pages,
  setSuccess,
  primaryLocatioURI,
}) {
  const { t } = useTranslation();
  usePageTitle(t('titles.document.title'));
  const history = useHistory();
  const { status = constants.TILE_ALL } = useParams();
  const tilesStatus = tabPaths[status] || constants.TILE_ALL;
  const [state, setState, resetState] = useCustomState(initialState);
  const { mutate: createTile, status: createStatus } = useCreateTile();
  const { mutate: saveTile } = usePatchTile();
  const onDeleteSucess = () => setState({ selectedTile: null });
  const {
    deleteModalOpen,
    setDeleteModalOpen,
    isDeletable,
    isDeletableFetching,
    deleteTile: deleteTileMutate,
    isDeleting,
  } = useDeleteTileWithConfirmModal(state.selectedTile, onDeleteSucess);
  const { mutate: moveToArchived, status: moveToArchivedStatus } = usePatchTile(
    {
      showSuccessMessage: false,
    },
  );
  const {
    qrCodeModalOpen,
    openQRCodeModal,
    onCloseQRCodeModal,
    onErrorQRCodeModal,
  } = useQRCodeModal();

  const { mutate: unarchiveTile } = usePatchTile({ showSuccessMessage: false });
  const { data: tiles = [], status: getTilesStatus } = useGetTiles();
  const displayContent = getTilesStatus !== 'loading' && !loadingDialogIsOpen;

  const hasOnlinePage = pages.some(
    (p) => p.channelId === constants.CHANNEL_TYPE.ONLINE,
  );

  const nonArchivedTiles = tiles.filter(
    (o) => o.status !== constants.TILE_ARCHIVED,
  );
  const filteredTiles = useMemo(
    () => filterTilesByStatus(nonArchivedTiles, tilesStatus),
    [nonArchivedTiles, tilesStatus],
  );

  const archivedTiles = sortTilesByArchivedDate(
    filterTilesByStatus(tiles, constants.TILE_ARCHIVED),
  );

  const onTabsChange = (_, val) => {
    history.push(`/tiles/${val.toLowerCase()}`);
  };

  const onOpenQRCodeModal = (tile) => {
    setState({ selectedTile: tile });
    openQRCodeModal();
  };

  const onCreateTile = (tileType) => {
    createTile(
      { tile: { ...defaultTile, type: tileType } },
      {
        onSuccess: (newTile) => {
          setState({ createModalOpen: false });
          const nextPath = `/wizard/tile/${newTile.id}/title`;
          history.push(nextPath);
        },
      },
    );
  };

  const createNewTile = () => {
    setState({ createModalOpen: true });
  };

  const onEdit = (tile) => {
    setState({ selectedTile: tile, drawerOpen: true });
  };

  const onDeleteTile = (tile) => {
    setState({ selectedTile: tile });
    setDeleteModalOpen(true);
  };

  const moveToActive = () => {
    saveTile({
      tile: state.selectedTile,
      values: {
        status: constants.TILE_ACTIVE,
      },
    });
    resetState();
  };

  const onArchivedTile = (tile) => {
    setState({
      archiveModalOpen: true,
      selectedTile: tile,
    });
  };

  const moveTileToArchived = () => {
    moveToArchived(
      {
        tile: state.selectedTile,
        values: { status: constants.TILE_ARCHIVED },
      },
      {
        onSuccess: () => {
          setSuccess(t('tile.archived.success'));
          resetState();
        },
      },
    );
  };

  const onUnarchiveTile = (tile) => {
    unarchiveTile(
      {
        tile,
        values: {
          status: constants.TILE_ACTIVE,
        },
      },
      {
        onSuccess: () => {
          setSuccess(t('tile.unarchived'));
          history.push(`/tile/${tile.id}`);
        },
      },
    );
  };

  const closeModal = () => {
    setState({
      archiveModalOpen: false,
    });
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const deleteTile = () => {
    deleteTileMutate(state.selectedTile);
    if (state.drawerOpen) setState({ drawerOpen: false });
  };

  return (
    <>
      {tiles.length < 1 && displayContent && (
        <NoTiles onCreateTile={createNewTile} />
      )}
      {tiles.length > 0 && displayContent && (
        <>
          <Grid className={classes.mainContainer}>
            <Grid item lg={12}>
              <Page>
                <div className={classes.titleContainer}>
                  <PageTitle className={classes.title}>
                    {t('tile.header')}
                  </PageTitle>
                  {createStatus === 'loading' && (
                    <CircularProgress color="primary" />
                  )}
                  {createStatus !== 'loading' && (
                    <CreateButton
                      onClick={createNewTile}
                      variant="contained"
                      data-testid="new-tile-button"
                    >
                      {t('tileManagement.newTile')}
                    </CreateButton>
                  )}
                </div>
                <Tabs value={tilesStatus} onChange={onTabsChange} />
                <PagePaper className={classes.tableContainer}>
                  <List disabled={filteredTiles.length < 1}>
                    {filteredTiles.map((tile) => (
                      <Tile
                        key={tile.id}
                        tile={tile}
                        pages={pages}
                        onClick={onEdit}
                        onArchivedTile={onArchivedTile}
                        onDeleteTile={onDeleteTile}
                        onGenerateQRCode={onOpenQRCodeModal}
                        primaryLocatioURI={primaryLocatioURI}
                      />
                    ))}
                  </List>
                </PagePaper>
              </Page>
            </Grid>
            {tilesStatus === constants.TILE_ALL && (
              <Grid item lg={12}>
                <ArchivedTiles
                  tiles={archivedTiles}
                  onItemClick={onEdit}
                  onDeleteTile={onDeleteTile}
                  onUnarchiveTile={onUnarchiveTile}
                  pages={pages}
                />
              </Grid>
            )}
          </Grid>
          {state.selectedTile && (
            <EditDrawer
              moveToActive={moveToActive}
              moveToArchived={onArchivedTile}
              deleteTile={onDeleteTile}
              selectedTile={state.selectedTile}
              onClose={() => setState(initialState)}
              isOpen={state.drawerOpen}
            />
          )}
        </>
      )}
      <ArchivedModal
        disabled={moveToArchivedStatus === 'loading'}
        onClose={closeModal}
        open={state.archiveModalOpen}
        onAccept={moveTileToArchived}
      />
      {state.selectedTile && (
        <DeleteModal
          isDeletable={isDeletable}
          isLoading={isDeletableFetching}
          disabled={isDeleting}
          onClose={closeDeleteModal}
          open={deleteModalOpen}
          onDelete={deleteTile}
          tile={state.selectedTile}
        />
      )}
      <CreateTileModal
        open={state.createModalOpen}
        onClose={() => setState({ createModalOpen: false })}
        disablePaymentButton={!hasOnlinePage}
        isLoading={createStatus === 'loading'}
        onCreateTile={onCreateTile}
      />
      {state.selectedTile && (
        <QRCodeModal
          siteLink={`${window.env.REACT_APP_DON_URL}/${primaryLocatioURI}/campaign/${state.selectedTile.id}?access=tile_direct&${VO_QR_QUERY_PARAMETER}`}
          open={qrCodeModalOpen}
          onClose={onCloseQRCodeModal}
          onError={onErrorQRCodeModal}
          name={state.selectedTile.title}
        />
      )}
    </>
  );
}

TilesList.propTypes = {
  classes: objectOf(string).isRequired,
  loadingDialogIsOpen: bool.isRequired,
  pages: arrayOf(object).isRequired,
  setSuccess: func.isRequired,
  primaryLocatioURI: string.isRequired,
};

export default withStyles(styles)(TilesList);
