import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(({ value }) => value);

const SortableList = SortableContainer(({ disabled, flexDirection, items }) => (
  <div
    style={{
      display: 'flex',
      flexDirection,
      flexWrap: 'wrap',
    }}
  >
    {items.map((item, index) => (
      <SortableItem
        key={item.key}
        index={index}
        value={item.node}
        disabled={disabled}
      />
    ))}
  </div>
));

function DraggableList({
  disabled,
  listItems,
  onReorder,
  flexDirection,
  axis,
}) {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(listItems);
  }, [listItems]);

  const onDragEnd = ({ oldIndex, newIndex }) => {
    const newList = [...list];
    newList.splice(oldIndex, 1);
    newList.splice(newIndex, 0, list[oldIndex]);
    setList(newList);
    onReorder(newList);
  };

  return (
    <SortableList
      disabled={disabled}
      flexDirection={flexDirection}
      items={list}
      axis={axis}
      onSortEnd={onDragEnd}
      distance={5}
    />
  );
}

DraggableList.propTypes = {
  disabled: PropTypes.bool,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      node: PropTypes.node.isRequired,
    }),
  ).isRequired,
  onReorder: PropTypes.func,
  flexDirection: PropTypes.string,
  axis: PropTypes.string,
};

DraggableList.defaultProps = {
  disabled: false,
  onReorder: () => {},
  flexDirection: 'column',
  axis: 'xy',
};

export default DraggableList;
