import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useGetPage } from '../useGetPage/useGetPage';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';
import VancoMobileUsersService from '../../../api/VancoMobileUsersService';
import { handleException } from '../../../store/alerts/actions';

async function getMobileUser({ queryKey }) {
  const [, id] = queryKey;
  const response = await VancoMobileUsersService.getUser(id);
  return response.data.data;
}

export function useGetMobilePageUser(userId, config = {}) {
  const dispatch = useDispatch();
  const { page, isLoading } = useGetPage(CHANNEL_TYPE.MOBILE, PAGE_ACTIVE);
  const { data: user, status, isLoading: usersIsLoading, ...rest } = useQuery({
    queryKey: ['mobile-users', userId],
    queryFn: getMobileUser,
    enabled: !!page.id,
    onError: error => dispatch(handleException(error)),
    ...config,
  });

  return {
    ...rest,
    user,
    isLoading: isLoading || usersIsLoading,
    status,
  };
}

export default useGetMobilePageUser;
