export const backendToFrontend = eventOrg => {
  return {
    mobileOrgId: eventOrg.mobileOrgId,
    events: eventOrg.events.map(e => {
      return {
        ...e,
        dateTimeStart: new Date(e.dateTimeStart),
        dateTimeEnd: new Date(e.dateTimeEnd),
      };
    }),
  };
};

export default backendToFrontend;
