import { node } from 'prop-types';
import { Typography } from '@material-ui/core';

import { useCardContext } from '../context';

function CardTitle({ children }) {
  const { classes } = useCardContext();

  return (
    <Typography variant="h4" className={classes.title}>
      {children}
    </Typography>
  );
}

CardTitle.propTypes = {
  children: node,
};

CardTitle.defaultProps = {
  children: null,
};

export default CardTitle;
