export default function styles(theme) {
  return {
    wrapper: {
      overflow: 'visible',
      position: 'relative',
      width: '24%',
      paddingBottom: '15.75%',
      marginBottom: 20,
      padding: 0,
      border: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      outline: 'none',
      '&:focus': {
        border: 0,
      },
      '&::-moz-focus-inner': {
        border: 0,
      },
      [theme.breakpoints.down('sm')]: {
        width: '48%',
        paddingBottom: '31.5%',
      },
    },
    image: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      '&$selected': {
        boxShadow: `0 0 0 5px ${theme.palette.primary.main}`,
        borderRadius: 9,
      },
    },
    selected: {},
  };
}
