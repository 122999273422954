import { objectOf, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';

import Page from '../Page';
import PagePaper from '../PagePaper';
import PageTitle from '../PageTitle';
import TilesOrder from '../TilesOrder';

import useSetCurrentPageByChannel from '../../../utils/hooks/useSetCurrentPageByChannel';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';

import styles from './styles';

export function VancoMobileOrderTiles({ classes }) {
  const { t } = useTranslation();
  const history = useHistory();
  const currentPage = useSetCurrentPageByChannel({
    channelType: CHANNEL_TYPE.MOBILE,
    pageStatus: [PAGE_ACTIVE],
  });

  const handleBack = () => {
    history.push(`/mobile/home`);
  };

  return (
    <Page variant="centered">
      <PageTitle className={classes.title} onGoBack={handleBack}>
        {t('vanco.mobile.tiles')}
      </PageTitle>
      <PagePaper className={classes.paper}>
        <TilesOrder page={currentPage} />
      </PagePaper>
    </Page>
  );
}

VancoMobileOrderTiles.propTypes = {
  classes: objectOf(string).isRequired,
};

export default withStyles(styles)(VancoMobileOrderTiles);
