import { useState } from 'react';
import { bool, func, object, objectOf, oneOf, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Modal from '../Modal';
import OutlinedTextField from '../OutlinedTextField';
import { copyText } from '../../../utils';
import { formatStreamInfo } from '../../../utils/stream';
import { constants } from '../../../globals';
import { maxLengths } from '../../../utils/validations';

import styles from './styles';
import {
  DEFAULT_IFRAME_HEIGHT,
  DEFAULT_IFRAME_WIDTH,
} from '../../../globals/constants';

export function EmbedModal({
  classes,
  open,
  onClose,
  domain,
  locationURI,
  page,
  variant,
}) {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const [layoutType, setLayoutType] = useState(
    constants.LAYOUT_TYPE.RESPONSIVE,
  );
  const [width, setWidth] = useState(DEFAULT_IFRAME_WIDTH);
  const [height, setHeight] = useState(DEFAULT_IFRAME_HEIGHT);

  const isResponsive = () => layoutType === constants.LAYOUT_TYPE.RESPONSIVE;
  const isFixed = () => layoutType === constants.LAYOUT_TYPE.FIXED;

  const isStream = variant === 'stream';
  const allowFixed = isStream || variant === 'library';

  const streamLink = `${
    window.env.REACT_APP_STREAM_URL
  }/embed/${formatStreamInfo(page?.streamData?.streamerId)}/${formatStreamInfo(
    page?.streamData?.streamId,
  )}`;

  const getContent = () => {
    if (isStream) {
      return `<iframe id="v-frame" style="width:${
        isFixed() ? `${width}px` : '100%'
      };height:${
        isFixed() ? `${height}px` : '100vh'
      };" frameborder="0" allow="clipboard-write" src="${streamLink}" scrolling="no"></iframe>
      <script type="application/javascript">
        window.onmessage=(e=>{const n=document.getElementById("v-frame");"${
          window.env.REACT_APP_STREAM_URL
        }"===e.origin&&e.data.height&&(n.height=e.data.height,n.overflow="hidden")});
      </script>`;
    }
    return `<iframe id="v-frame" width="100%" frameborder="0" allow="clipboard-write" src="${domain}/${locationURI}" scrolling="no"></iframe>
    <script type="application/javascript">
      window.onmessage=(e=>{const n=document.getElementById("v-frame");"${domain}"===e.origin&&e.data.height&&(n.height=e.data.height,n.overflow="hidden")});
    </script>`;
  };

  const onCopyCode = () => {
    setIsCopied(true);
    copyText(getContent());
  };

  const onLayoutChange = async (e) => {
    const { value } = e.target;
    setLayoutType(value);
    setIsCopied(false);
  };

  const onHeightChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setHeight(value);
  };

  const onWidthChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setWidth(value);
  };

  const close = () => {
    setIsCopied(false);
    setWidth(DEFAULT_IFRAME_WIDTH);
    setHeight(DEFAULT_IFRAME_HEIGHT);
    onClose();
  };

  return (
    <Modal open={open} onClose={close}>
      <Modal.Title>{t(isStream ? 'stream.embed' : 'site.embed')}</Modal.Title>
      <Modal.Content className={classes.modalContent}>
        <Typography>
          {t(isStream ? 'stream.embed.description' : 'site.embed.description')}
        </Typography>
        {allowFixed && (
          <>
            <Typography variant="body2" className={classes.subtitle}>
              {t('stream.embed.layoutOptions')}
            </Typography>
            <RadioGroup component={RadioGroup} onChange={onLayoutChange}>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Radio
                    checked={isResponsive()}
                    name="layoutType"
                    color="primary"
                    value={constants.LAYOUT_TYPE.RESPONSIVE}
                  />
                }
                label={
                  <Typography>{t('stream.embed.responsiveLayout')}</Typography>
                }
              />
              {!isFixed() && (
                <Typography className={classes.optionDescription}>
                  {t('stream.embed.responsiveLayout.description')}
                  <strong>
                    {t('stream.embed.responsiveLayout.description2')}
                  </strong>
                </Typography>
              )}
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Radio
                    checked={isFixed()}
                    name="layoutType"
                    color="primary"
                    value={constants.LAYOUT_TYPE.FIXED}
                  />
                }
                label={<Typography>{t('stream.embed.fixedLayout')}</Typography>}
              />
              {isFixed() && (
                <>
                  <Typography className={classes.optionDescription}>
                    {t('stream.embed.fixedLayout.description')}
                  </Typography>
                  <div className={classes.dimensions}>
                    <OutlinedTextField
                      className={classes.dimensionInput}
                      value={width}
                      onChange={onWidthChange}
                      inputProps={{ maxLength: maxLengths.pixels }}
                    />
                    <Typography className={classes.dimensionSymbol}>
                      &times;
                    </Typography>
                    <OutlinedTextField
                      className={classes.dimensionInput}
                      value={height}
                      onChange={onHeightChange}
                      inputProps={{ maxLength: maxLengths.pixels }}
                    />
                  </div>
                </>
              )}
            </RadioGroup>
          </>
        )}
        <div className={classes.container}>
          <pre className={classes.pre}>
            <Typography className={classes.code}>{getContent()}</Typography>
          </pre>
        </div>
        {isCopied && (
          <div className={classes.copied}>
            <CheckCircleIcon />
            <span>{t('site.code.copied')}</span>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions className={classes.modalActions}>
        <Button
          className={classes.cancelButton}
          type="button"
          variant="outlined"
          onClick={close}
        >
          <Typography className={classes.cancelButtonText}>
            {t('button.cancel')}
          </Typography>
        </Button>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={onCopyCode}
        >
          <Typography className={classes.copyButtonText}>
            {t('site.copy.code')}
          </Typography>
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

EmbedModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func,
  domain: string.isRequired,
  locationURI: string.isRequired,
  page: object,
  variant: oneOf(['default', 'stream', 'library']),
};

EmbedModal.defaultProps = {
  open: false,
  onClose: null,
  page: null,
  variant: 'default',
};

export default withStyles(styles)(EmbedModal);
