import UserService from 'api/UserService';
import { SECTION_PERMISSIONS } from 'globals/grants';
import { useQuery } from 'react-query';
import useUser from '../useUser';
import useCurrentOrganization from '../useCurrentOrganization';
import useFeatures from '../useFeatures/useFeatures';

const mapListToObjectKey = (permissions) => {
  const permissionMap = {};
  permissions?.forEach((item) => {
    permissionMap[item.toUpperCase()] = true;
  });
  return permissionMap;
};

function formatPermissions(backend) {
  const permissionMap = {};
  Object.keys(backend.grantedActions).forEach((key) => {
    const entityPermissions = backend.grantedActions[key];
    Object.keys(entityPermissions).forEach((permissionKey) => {
      permissionMap[`${key.toUpperCase()}_${permissionKey.toUpperCase()}`] =
        entityPermissions[permissionKey];
    });
  });

  backend.accountPermissions?.forEach((permission) => {
    permissionMap[permission.toUpperCase()] = true;
  });

  Object.keys(SECTION_PERMISSIONS).forEach((sectionKey) => {
    if (
      backend.accountPermissions.some((g) => {
        const grant = g.toUpperCase();
        return grant.includes(`${sectionKey}_`);
      })
    ) {
      permissionMap[sectionKey] = true;
    }
  });

  return permissionMap;
}

async function getUserPermissions() {
  const permissionsResponse = await UserService.getUserPermissions();
  return formatPermissions(permissionsResponse.data?.data) || {};
}

export default function usePermissions() {
  const { isLoggedIn, grantedActions } = useUser();
  const { id: orgId } = useCurrentOrganization();
  const { AdminUserPermissions } = useFeatures();

  const { isFetching, data } = useQuery(
    ['user-permissions', orgId],
    getUserPermissions,
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: isLoggedIn && AdminUserPermissions,
    },
  );
  const permissions =
    isFetching || !data || !Object.keys(data)?.length
      ? mapListToObjectKey(grantedActions)
      : data;
  return {
    permissionsLoading: isFetching,
    ...permissions,
  };
}
