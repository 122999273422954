import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

function Form(props) {
  const { children, classes } = props;

  function onSubmit(e) {
    e.preventDefault();
  }

  return (
    <form className={classes.form} onSubmit={onSubmit} {...props}>
      {children}
    </form>
  );
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

Form.defaultProps = {
  children: null,
};

export default withStyles(styles)(Form);
