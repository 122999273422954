import * as Yup from 'yup';
import i18n from '../../../../utils/i18n';

const createSmartLibrarySchema = () =>
  Yup.object().shape({
    enableLibrarySelected: Yup.bool(),
    smartLibraryDisableDate: Yup.date()
      .nullable()
      .test('smartLibraryDisableDate', function validate() {
        const {
          enableStreamSelected,
          enableLibrarySelected,
          smartLibraryDisableDate,
        } = this.parent;
        return enableStreamSelected &&
          !enableLibrarySelected &&
          !smartLibraryDisableDate
          ? this.createError({
              message: i18n.t('validation.pleaseEnter', {
                requiredNoun: `${i18n
                  .t('disableStreamModal.cancelationDate')
                  .toLocaleLowerCase()}`,
              }),
            })
          : true;
      }),
  });

const createSmartClipSchema = () =>
  Yup.object().shape({
    enableClipSelected: Yup.bool(),
    smartClipsDisableDate: Yup.date()
      .nullable()
      .test('smartClipsDisableDate', function validate() {
        const {
          enableStreamSelected,
          enableClipSelected,
          smartClipsDisableDate,
        } = this.parent;
        return enableStreamSelected &&
          !enableClipSelected &&
          !smartClipsDisableDate
          ? this.createError({
              message: i18n.t('validation.pleaseEnter', {
                requiredNoun: `${i18n
                  .t('disableStreamModal.cancelationDate')
                  .toLocaleLowerCase()}`,
              }),
            })
          : true;
      }),
  });

export const createSchema = (features) => {
  let schema = Yup.object().shape({
    enableStreamSelected: Yup.bool(),
    streamingDisableDate: Yup.date().when('enableStreamSelected', {
      is: false,
      then: (s) =>
        s.required(
          i18n.t('validation.pleaseEnter', {
            requiredNoun: `${i18n
              .t('disableStreamModal.cancelationDate')
              .toLocaleLowerCase()}`,
          }),
        ),
      otherwise: (s) => s.nullable(),
    }),
  });

  if (features.ffSmartLibrary)
    schema = schema.concat(createSmartLibrarySchema());
  if (features.ffSmartClips) schema = schema.concat(createSmartClipSchema());

  return schema;
};

export default createSchema;
