export default function styles(theme) {
  return {
    disclosure: {
      marginBottom: 24,
    },
    title: {
      fontWeight: 'bold',
    },
    unpublishButton: {
      backgroundColor: '#FFF',
      marginLeft: 'auto',
      alignSelf: 'center',
      minWidth: 'auto',
      [theme.breakpoints.down('xs')]: {
        margin: '8px 0 0 0',
      },
    },
    unpublishButtonIcon: {
      marginRight: '10px',
      width: 20,
      height: 20,
    },
    unpublishExtended: {
      minWidth: 177,
    },
  };
}
