import { random, string } from '@vancoplatform/utils';
import * as storage from '../helper/storage';
import * as cookieStorage from '../helper/cookieStorage';
import * as crypto from '../helper/crypto';
import { AuthorizeOptions, TransactionOptions, Transaction } from '../types';

const DEFAULT_NAMESPACE = 'vanco.ids.';
const DEFAULT_STORAGE = storage;

class TransactionManager {
  namespace: string;
  keyLength: number;
  storage: typeof storage | typeof cookieStorage;
  constructor(options: TransactionOptions) {
    options = options || {};
    this.namespace = options.namespace || DEFAULT_NAMESPACE;
    this.keyLength = options.keyLength || 32;
    this.storage = options.useFallbackStorage ? cookieStorage : DEFAULT_STORAGE;
  }

  async process(options: AuthorizeOptions<unknown>) {
    if (!options.responseType) {
      throw new Error('responseType is required');
    }
    const usePKCE =
      options.usePKCE && options.responseType.indexOf('code') !== -1;

    const transaction = await this.generateTransaction(
      options.appState,
      options.state,
      options.nonce,
      options.codeVerifier,
      usePKCE
    );
    if (!options.state) {
      options.state = transaction.state;
      delete options.appState;
    }

    if (!options.nonce) {
      options.nonce = transaction.nonce;
    }

    if (transaction.codeChallenge && transaction.codeChallengeMethod) {
      options.codeChallenge = transaction.codeChallenge;
      options.codeChallengeMethod = transaction.codeChallengeMethod;
    }

    delete options.usePKCE;

    return options;
  }

  processLogout(options: { appState?: unknown; state?: string }) {
    const transaction = this.generateLogoutTransaction(
      options.appState,
      options.state
    );
    if (!options.state) {
      options.state = transaction.state;
      delete options.appState;
    }

    return options;
  }

  async generateTransaction(
    appState: unknown,
    state: string,
    nonce: string,
    codeVerifier: string,
    generateCodeChallenge: boolean
  ) {
    const stateKey = state || random.randomString(this.keyLength);
    nonce = nonce || random.randomString(this.keyLength);
    codeVerifier = crypto.supportsSha256()
      ? codeVerifier ||
        (generateCodeChallenge ? random.randomString(this.keyLength) : null)
      : null;

    let codeChallenge = null,
      codeChallengeMethod: 'S256' | null = null;
    if (codeVerifier) {
      codeChallenge = await crypto.computeChallenge(codeVerifier);
      codeChallengeMethod = 'S256';
    }

    state =
      state ||
      string.stringToBase64Url(JSON.stringify({ k: stateKey, s: appState }));

    this.storage.setItem(this.namespace + stateKey, {
      nonce,
      state,
      codeVerifier,
      exchangeAuthCode: generateCodeChallenge,
    });

    return {
      state,
      nonce,
      codeChallenge,
      codeChallengeMethod,
    };
  }

  generateLogoutTransaction(appState: unknown, state: string) {
    const stateKey = state || random.randomString(this.keyLength);

    state =
      state ||
      string.stringToBase64Url(JSON.stringify({ k: stateKey, s: appState }));

    this.storage.setItem(this.namespace + stateKey, {
      state,
    });
    return {
      state,
    };
  }

  getStoredTransaction<TAppState = unknown>(
    state: string
  ): Transaction<TAppState> {
    if (!state) return null;

    try {
      const { k: stateKey, s: appState } = JSON.parse(
        string.base64UrlDecode(state)
      );

      const transactionData = this.readTransactionDataFromStorage(stateKey);

      if (!transactionData) {
        return {
          appState,
        };
      }
      return {
        ...transactionData,
        appState,
      };
    } catch (e) {
      return this.readTransactionDataFromStorage(state);
    }
  }

  readTransactionDataFromStorage(state: string) {
    const transactionData = this.storage.getItem(this.namespace + state);
    if (transactionData?.deleted) {
      console.log('The item has already been deleted from storage');
    } else {
      this.clearTransaction(state);
    }
    return transactionData;
  }

  clearTransaction(state: string) {
    this.storage.setItem(this.namespace + state, { deleted: true });
  }
}

export default TransactionManager;
