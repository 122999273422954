import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { defaultTile } from '../../../globals/defaults';
import {
  Page,
  PagePaper,
  PageTitle,
  TileTitle,
  TileDescription,
  TileImageSelect,
  TileGift,
  TilePayment,
  TileOverview,
  PrimaryButton,
  PreventLeavePageModal,
} from '../../common';
import Tabs from './Tabs';
import styles from './styles';
import {
  TILE_ACTIVE,
  TILE_ARCHIVED,
  TILE_TYPE_GIFT,
} from '../../../globals/constants';
import UnarchiveSection from '../../common/TileOverview/UnarchiveSection';

export function TileBuilder({
  classes,
  history,
  location,
  saveTile,
  loadingSave,
  setSnackbar,
  setSuccess,
  tile,
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState(0);
  const [tileToUpdate, setTileToUpdate] = useState(defaultTile);
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(false);

  const tabsContent = [
    TileOverview,
    TileTitle,
    TileDescription,
    TileImageSelect,
    tile.type === TILE_TYPE_GIFT ? TileGift : TilePayment,
  ];

  const tabPaths = [
    '/preview',
    '/title',
    '/description',
    '/image',
    tile.type === TILE_TYPE_GIFT ? '/gift' : '/payment',
  ];

  const tabIndex = tabPaths.findIndex((path) =>
    location.pathname.includes(path),
  );

  useEffect(() => {
    setActiveTab(tabIndex !== -1 ? tabIndex : 0);
  }, [tabIndex]);

  useEffect(() => {
    if (tile.id !== tileToUpdate.id) {
      setTileToUpdate(tile);
    }
    if (tile.fund !== tileToUpdate.fund && !tileToUpdate.fund) {
      setTileToUpdate({
        ...tileToUpdate,
        fund: tile.fund,
        donationOptions: tile.donationOptions,
        paymentOptions: tile.paymentOptions,
        paymentFrequencies: tile.paymentFrequencies,
      });
    }
  }, [tile, tileToUpdate]);

  const handleTabChange = (e, val) => {
    history.push(`/tile/${tile.id}${tabPaths[val]}`);
  };

  const TabContent = tabsContent[activeTab];

  const handleBackArrow = () => {
    history.push('/tiles/all');
  };

  const handleUpdateTile = (key, value) => {
    setDirty(true);
    setTileToUpdate((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const publishTile = async () => {
    if (dirty) {
      if (!error) {
        if (activeTab === 0) {
          await saveTile(tileToUpdate, false);
          setSuccess(t('tile.visibilitySuccessMessage'));
        } else {
          await saveTile(tileToUpdate, true);
        }
        queryClient.setQueryData(['tile', tileToUpdate.id], tileToUpdate);
        setDirty(false);
      } else {
        setSnackbar(t('errors.formErrors'));
      }
    }
  };

  const unarchiveTile = async () => {
    if (!error) {
      const updatedTile = {
        ...tileToUpdate,
        status: TILE_ACTIVE,
        locations: [],
      };
      await saveTile(updatedTile, false);
      setSuccess(t('tile.unarchived'));
      queryClient.setQueryData(['tile', updatedTile.id], updatedTile);
      setTileToUpdate(updatedTile);
      setDirty(false);
    }
  };

  const onLeave = () => {
    setDirty(false);
    setTileToUpdate(tile);
  };

  const disableArchivedTileUI = tile.status === TILE_ARCHIVED && tabIndex > 0;

  return (
    <Page>
      <PageTitle className={classes.title} onGoBack={handleBackArrow}>
        {tile.title}
      </PageTitle>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        isGift={tile.type === TILE_TYPE_GIFT}
      />
      <PagePaper>
        {disableArchivedTileUI && (
          <UnarchiveSection variant="wide" unarchiveTile={unarchiveTile} />
        )}
        <div style={{ position: 'relative' }}>
          <TabContent
            tile={tile}
            updateTile={handleUpdateTile}
            setError={setError}
            history={history}
            unarchiveTile={unarchiveTile}
          />
          {disableArchivedTileUI && (
            <div className={classes.archivedTileOverlay} />
          )}
        </div>
        <div className={classes.buttonContainer}>
          {!loadingSave ? (
            tile.status !== TILE_ARCHIVED && (
              <PrimaryButton
                size="large"
                disabled={!dirty || error}
                type="button"
                onClick={publishTile}
              >
                {t('button.saveChanges')}
              </PrimaryButton>
            )
          ) : (
            <CircularProgress color="primary" />
          )}
        </div>
      </PagePaper>
      <PreventLeavePageModal shouldOpenOnLeave={dirty} onLeave={onLeave} />
    </Page>
  );
}

TileBuilder.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  saveTile: PropTypes.func.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  tile: PropTypes.object,
};

TileBuilder.defaultProps = {
  tile: null,
};

export default withStyles(styles)(TileBuilder);
