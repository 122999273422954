export default {
  primary: {
    main: '#30119b', // indigo-blue
    light: '#9E9E9E', // grey
    accent: '#e64361', // pink
  },
  secondary: {
    light: '#979797', // grey
    medium: '#757575', // medium grey
    main: '#222222', // black
  },
  icon: {
    main: '#30119b',
    medium: '#444444',
    light: '#828282',
    default: '#5E6266',
  },
  text: {
    default: '#212224',
    disabled: '#8E9194',
    subdued: '#676B70',
    critical: '#E31212',
  },
  surface: {
    subdued: '#FAFBFB',
  },
  // non-material ui base colors
  h1: '#1f2126',
  h2: '#222222',
  h3: '#141414',
  white: 'white',
  giving: '#F69349',
  donors: '#63B2F8',
  outreach: '#44C2A8',
  smartVideo: '#F46F75',
  data: '#766EAD',
  import: '#311599',
  settings: '#9E9E9E',
  overlay: 'rgba(255, 255, 255, 0.83)',
  error: { main: '#C82947', light: '#FBF3F5' },
  icons: '#1b2125',
};
