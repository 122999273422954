import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { string, object, objectOf } from 'prop-types';

import ColorSelector from '../../../ColorSelector';
import { COLOR_OPTIONS } from '../../../../../globals/constants';
import OrganizationLogoSection from './OrganizationLogoSection';
import BannerSection from './BannerSection';
import styles from './styles';

function BrandingForm({ classes, formikProps, settings }) {
  const { t } = useTranslation();
  const {
    values: { primaryColor, logoImage, bannerImage },
    setFieldValue,
  } = formikProps;

  const onColorSelect = (color) =>
    setFieldValue({ target: { name: 'primaryColor', value: color } });

  const setLogoImage = (img) =>
    setFieldValue({ target: { name: 'logoImage', value: img } });
  const setBannerImage = (img) =>
    setFieldValue({ target: { name: 'bannerImage', value: img } });

  return (
    <div>
      <Typography variant="h5" className={classes.colorsHeader}>
        {t('organization.branding.color.title')}
      </Typography>
      <Typography className={classes.text} variant="body1">
        {t('organization.branding.color.description')}
      </Typography>
      <Grid container className={classes.colorsContainer}>
        {COLOR_OPTIONS.map((c) => (
          <ColorSelector
            key={c}
            color={c}
            onClick={onColorSelect}
            isSelected={c === primaryColor}
          />
        ))}
      </Grid>
      <Divider />
      <OrganizationLogoSection
        settings={settings}
        primaryColor={primaryColor}
        logoImage={logoImage}
        setLogoImage={setLogoImage}
      />
      <Divider style={{ marginTop: 40 }} />
      <BannerSection
        organizationId={settings.organizationId}
        primaryColor={primaryColor}
        bannerImage={bannerImage}
        defaultBannerImageUrl={settings.defaultBannerImageUrl}
        setImage={setBannerImage}
      />
    </div>
  );
}

BrandingForm.propTypes = {
  classes: objectOf(string).isRequired,
  formikProps: object.isRequired,
  settings: object.isRequired,
};

export default withStyles(styles)(BrandingForm);
