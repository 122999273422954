import { connect } from 'react-redux';

import Fees from './Fees';
import { saveOrganizationSettings } from '../../../../store/organizations/actions';

export function mapStateToProps(state) {
  return {
    organizationId: state.organizations.currentOrganization.id,
  };
}

export default connect(mapStateToProps, { saveOrganizationSettings })(Fees);
