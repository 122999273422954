import { bool, func, objectOf, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Modal, PrimaryButton } from '../../../common';

import styles from './styles';

export function ArchivedModal({
  classes,
  open,
  onClose,
  onAccept,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title className={classes.title}>
        {t('archivedModal.title')}
      </Modal.Title>
      <Modal.Content className={classes.content}>
        <Typography>{t('archivedModal.description')}</Typography>
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <Button
          onClick={onClose}
          size="large"
          color="primary"
          variant="text"
          className={classes.closeButton}
          disabled={disabled}
        >
          {t('button.cancel')}
        </Button>
        <PrimaryButton
          size="large"
          data-testid="transfer-ownership"
          onClick={onAccept}
          disabled={disabled}
        >
          {t('archivedModal.button')}
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
}

ArchivedModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func.isRequired,
  onAccept: func.isRequired,
  disabled: bool,
};

ArchivedModal.defaultProps = {
  open: false,
  disabled: false,
};

export default withStyles(styles)(ArchivedModal);
