export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
      '& div': {
        minHeight: 56,
      },
      '& label': {
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
      },
      '& legend': {
        fontSize: 12,
        fontFamily: theme.typography.fontFamily,
      },
      '& .MuiInputLabel-root': {
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
      },
      '& .MuiOutlinedInput-input': {
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
      },
      '& .MuiFormHelperText-root': {
        fontSize: 13,
        fontFamily: theme.typography.fontFamily,
      },
    },
    formItem: {
      marginBottom: '20px !important',
    },
    flexFormItem: {
      flexGrow: '1',
    },
    stateField: {
      flexGrow: '3',
      flexBasis: '75%',
    },
    secondFormItem: {
      marginLeft: '16px !important',
      flexGrow: '1',
      '& .MuiFormControl-root': {
        marginLeft: 16,
        marginTop: 20,
      },
    },
    formControl: {
      '& .MuiFormControl-root-MuiTextField-root': {
        margin: 1,
      },
    },
    formItemGroup: {
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
    [theme.breakpoints.down('sm')]: {
      formItemGroup: {
        flexDirection: 'column',
      },
      flexFormItem: {
        marginBottom: '20px !important',
      },
      stateField: {
        marginBottom: '20px !important',
        flexBasis: 'auto',
        flexGrow: '1',
      },
      secondFormItem: {
        marginLeft: '0px !important',
      },
      formItem: {
        marginBottom: '20px !important',
      },
    },
  };
}
