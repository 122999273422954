export default function styles() {
  const divider = {
    marginTop: 24,
  };
  return {
    textMargin: {
      marginTop: 8,
    },
    divider: {
      ...divider,
      marginBottom: 24,
    },
    form: {
      marginBottom: 0,
    },
    pagePaper: {
      padding: '24px 24px 20px 24px',
      width: 600,
      margin: '32px 0 0',
    },
    spinnerContainer: {
      marginTop: 20,
    },
  };
}
