import { CXP_ERROR_CODES } from '../../globals/constants';
import i18n from '../i18n';

/**
 * Parses error responses from the API to try and drill down to a single error message that can be used by all downstream components.
 * NOTE: I took this code from donations-payer app
 * @param {Error} error - The error to be parsed.
 * @param {Object} errorCodes - Mapped error codes to display
 * @returns {String} - The message parsed from the response. If none can be found, returns an empty string.
 */
export function getApiErrorMessage(error, errorCodes = {}) {
  let message = '';
  // look through error array for a matching code
  if (error?.response?.data?.errors?.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const e of error.response.data.errors) {
      const { code } = e;
      if (errorCodes[code]) {
        message = errorCodes[code];
        break;
      }
    }
  }

  if (!message) {
    message = error?.response?.data?.message || '';
  }

  return message;
}

export function getCxpErrorMessage(e) {
  const errors = e?.response?.data?.errors;
  let message;
  if (!errors || !errors.length > 0) return message;

  const { code } = errors[0];

  switch (code) {
    case CXP_ERROR_CODES.ADDRESS_AND_POSTAL_CODE_MISSMATCH: {
      message = i18n.t('errors.cxp.missmatch', {
        value: i18n.t('errors.cxp.addres.and.zip'),
      });
      break;
    }
    case CXP_ERROR_CODES.POSTAL_CODE_MISSMATCH: {
      message = i18n.t('errors.cxp.missmatch', {
        value: i18n.t('errors.cxp.zip'),
      });
      break;
    }
    case CXP_ERROR_CODES.ADDRESS_MISSMATCH: {
      message = i18n.t('errors.cxp.missmatch', {
        value: i18n.t('errors.cxp.addres'),
      });
      break;
    }
    default:
  }

  return message;
}

export default {
  getApiErrorMessage,
  getCxpErrorMessage,
};
