import { arrayOf, shape, string, objectOf, oneOf } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Table, {
  TableHead,
  TableBody,
  TableHeadCell,
  TableRow,
  TableCell,
} from '../../Table';
import Badge from '../../Badge';
import { ReactComponent as StaffIcon } from '../../../../assets/staffIcon.svg';
import { ReactComponent as ModeratorIcon } from '../../../../assets/moderatorIcon.svg';
import EmptyList from '../../Table/EmptyList';
import {
  MOBILE_USER_ROLE,
  MOBILE_USER_TAB_ALL,
} from '../../../../globals/constants';

import styles from './styles';

export function UsersList({ classes, users, userTab }) {
  const { t } = useTranslation();
  const filteredUsers = (userTab === MOBILE_USER_TAB_ALL
    ? users
    : users.filter(u => u.role === userTab)
  ).sort((a, b) =>
    ((a.name || '').trim() || a.email).localeCompare(
      (b.name || '').trim() || b.email,
    ),
  );

  const history = useHistory();
  const clickUser = userId => history.push(`/mobile/user/${userId}`);

  const emptyListTitle = t(
    `vanco.mobile.appUsers.no.${userTab.toLowerCase()}.title`,
  );
  const emptyListDescription = t(
    `vanco.mobile.appUsers.no.${userTab.toLowerCase()}.description`,
  );
  return (
    <>
      {filteredUsers.length < 1 &&
        userTab !== MOBILE_USER_TAB_ALL &&
        userTab !== MOBILE_USER_ROLE.STAFF && (
          <EmptyList
            title={emptyListTitle}
            description={emptyListDescription}
          />
        )}
      {filteredUsers.length > 0 && (
        <Table aria-label="Vanco Mobile Users Table">
          <TableHead>
            <TableRow isHeader>
              <TableHeadCell>
                <Typography variant="body2">
                  {t('vanco.mobile.appUsers.tableUser')}
                </Typography>
              </TableHeadCell>
              <TableHeadCell className={classes.email}>
                <Typography variant="body2">
                  {t('vanco.mobile.appUsers.tableEmail')}
                </Typography>
              </TableHeadCell>
              <TableHeadCell className={classes.status}>
                <Typography variant="body2">
                  {t('vanco.mobile.appUsers.tableStatus')}
                </Typography>
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map(user => (
              <TableRow key={user.id} onClick={() => clickUser(user.id)}>
                <TableCell>
                  <Typography component="span" className={classes.userName}>
                    {/* the trim is to prevent a name just with empty spaces */}
                    {(user.name || '').trim() || user.email}
                  </Typography>
                  {(user.role === MOBILE_USER_ROLE.MODERATOR ||
                    user.role === MOBILE_USER_ROLE.STAFF) && (
                    <Badge variant="primary">
                      {user.role === MOBILE_USER_ROLE.MODERATOR ? (
                        <ModeratorIcon />
                      ) : (
                        <StaffIcon />
                      )}
                      {user.role}
                    </Badge>
                  )}
                </TableCell>
                <TableCell className={classes.email}>
                  <Typography component="span">{user.email}</Typography>
                </TableCell>
                <TableCell className={classes.status}>
                  <Typography component="span">{user.status}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

UsersList.propTypes = {
  classes: objectOf(string).isRequired,
  users: arrayOf(
    shape({
      id: string.isRequired,
    }),
  ),
  userTab: oneOf([
    MOBILE_USER_TAB_ALL,
    MOBILE_USER_ROLE.MODERATOR,
    MOBILE_USER_ROLE.MEMBER,
    MOBILE_USER_ROLE.STAFF,
  ]),
};

UsersList.defaultProps = {
  users: [],
  userTab: MOBILE_USER_TAB_ALL,
};

export default withStyles(styles)(UsersList);
