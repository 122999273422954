export default function styles(theme) {
  return {
    circleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#e9e8f5',
      width: 100,
      height: 100,
      borderRadius: 100,
      marginBottom: 12,
    },
    svg: {
      strokeLinecap: 'round !important',
    },
    progressText: {
      position: 'absolute',
      zIndex: 1,
      fontSize: 24,
      fontWeight: 900,
      color: theme.palette.primary.main,
    },
    percentIcon: {
      fontSize: 12,
      fontWeight: 600,
    },
  };
}
