import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { Box, Divider, Grid } from '@material-ui/core';
import { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Page, PagePaper, PageTitle } from '../../common';

import styles from './styles';
import { TILE_TYPE_GIFT } from '../../../globals/constants';
import PaymentMethod from '../../common/PaymentMethod';
import { createSchema, initialTransactionValues } from './validation';
import AddDonation from '../../common/AddDonation';
import MyBasket from '../../common/Basket/MyBasket';
import NoPaymentsSupported from '../../common/NoPaymentsSupported';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

function NewTransaction({
  classes,
  loadingData,
  hasLoadedData,
  hasData,
  transactionActions,
  selectedPaymentType,
  paymentMethods,
}) {
  const { t } = useTranslation();
  usePageTitle(t('titles.getStarted'));
  const { creditCard = {}, ach = {} } = paymentMethods;

  useEffect(() => {
    transactionActions.getTransactionData();
  }, [transactionActions]);

  if (!hasData && (loadingData || !hasLoadedData)) {
    return false;
  }

  if (!creditCard?.isAllowed && !ach?.isAllowed) return <NoPaymentsSupported />;

  return (
    <Grid className={classes.mainContainer}>
      <Grid item lg={12}>
        <Page>
          <PageTitle className={classes.title}>
            {t('app.menu.newTransaction')}
          </PageTitle>

          <PagePaper className={classes.tableContainer}>
            <Box className={classes.tableContainer}>
              <AddDonation transactionType={TILE_TYPE_GIFT} />
              <MyBasket id="basket-widget" />
              <Divider />
              <Formik
                enableReinitialize
                initialValues={initialTransactionValues}
                validationSchema={createSchema(selectedPaymentType)}
              >
                {(formikProps) => (
                  <Form>
                    <PaymentMethod formikProps={formikProps} />
                  </Form>
                )}
              </Formik>
            </Box>
          </PagePaper>
        </Page>
      </Grid>
    </Grid>
  );
}

NewTransaction.propTypes = {
  classes: PropTypes.object.isRequired,
  loadingData: PropTypes.bool.isRequired,
  hasData: PropTypes.bool.isRequired,
  hasLoadedData: PropTypes.bool.isRequired,
  transactionActions: PropTypes.object.isRequired,
  selectedPaymentType: PropTypes.string.isRequired,
  paymentMethods: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewTransaction);
