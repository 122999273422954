import { object, func, objectOf, string, bool } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import { isValidObject } from 'utils/validations';
import useOrganizationPermissions from 'utils/hooks/useOrganizationPermissions';
import { ROLES, SECTIONS_TYPE } from '../../../../globals/constants';
import { SECTION_PERMISSIONS } from '../../../../globals/grants';
import {
  FormHelperTextBottom,
  OutlinedTextField,
  PrimaryButton,
  Modal,
} from '../../../common';
import styles from './styles';
import UserPermissionOptions from '../UserPermissionOptions';
import { useFeatures, usePermissions } from '../../../../utils/hooks';

function NewUserForm({
  values,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  onClose,
  classes,
  isLoading,
  backendError,
}) {
  const { t } = useTranslation();
  const { userName, clientId, role, hasAccount } = values;
  const permissions = usePermissions();
  const { AdminUserPermissions } = useFeatures();
  const { organizationGrants } = useOrganizationPermissions();
  const checkboxes = values.accountGrants
    ?.filter((p) => permissions?.[p.key])
    .map((p) => ({ ...p, disabled: !organizationGrants[p.key] }));
  // ensure 'Settings' permission is always included and set to true
  const ensureSettingsPermission = (grants) => {
    const settingsGrant = grants.find(
      (g) => g.key === SECTION_PERMISSIONS.SETTINGS.ROOT,
    );
    if (!isValidObject(settingsGrant)) {
      return [
        ...grants,
        {
          sectionId: SECTIONS_TYPE.Settings,
          key: SECTION_PERMISSIONS.SETTINGS.ROOT,
          label: 'Settings',
          value: true,
        },
      ];
    }
    settingsGrant.value = true;
    return grants;
  };

  const onSectionChecked = (section, index) => {
    const newValue = [...values.accountGrants];
    newValue[index] = { ...section, value: !section.value };
    const updatedGrants = ensureSettingsPermission(newValue);
    setFieldValue({ target: { name: 'accountGrants', value: updatedGrants } });
  };

  return (
    <>
      <Modal.Content>
        <Grid container className={classes.grid}>
          {hasAccount ? (
            <Grid item md={12} sm={12} xs={12}>
              <FormControl fullWidth>
                <Typography variant="h5" className={classes.formTitle}>
                  {t('users.addUser.clientId.label')}
                </Typography>
                <OutlinedTextField
                  id="clientId"
                  name="clientId"
                  data-testid="clientId"
                  error={touched.clientId && !!errors.clientId}
                  value={clientId}
                  label={t('users.addUser.clientId.placeholder')}
                  onBlur={handleBlur}
                  onChange={setFieldValue}
                  autoComplete="off"
                  className={classes.textInput}
                />
                <FormHelperTextBottom
                  left={touched.clientId && errors.clientId}
                />
              </FormControl>
            </Grid>
          ) : (
            <Grid item md={12} sm={12} xs={12}>
              <FormControl fullWidth>
                <Typography variant="h5" className={classes.formTitle}>
                  {t('users.addUser.email.label')}
                </Typography>
                <OutlinedTextField
                  id="userName"
                  name="userName"
                  data-testid="userName"
                  error={touched.userName && !!errors.userName}
                  value={userName}
                  label={t('headerManagement.emailAddress')}
                  onBlur={handleBlur}
                  onChange={setFieldValue}
                  autoComplete="off"
                  className={classes.textInput}
                />
                <FormHelperTextBottom
                  left={touched.userName && errors.userName}
                />
              </FormControl>
            </Grid>
          )}
          {backendError && (
            <Grid item md={12} sm={12} xs={12} className={classes.inlineError}>
              <ErrorIcon className={classes.errorIcon} />
              {/* If we receive an unexpected error the message could be too large to display confortably */}
              <Typography variant="body1" className={classes.errorText}>
                {t(backendError, {
                  requiredNoun: hasAccount ? clientId : userName,
                })}
              </Typography>
            </Grid>
          )}

          {AdminUserPermissions ? (
            <Grid item md={12} sm={12} xs={12}>
              {permissions.permissionsLoading ? (
                <div className={classes.loaderContainer}>
                  <CircularProgress />
                </div>
              ) : (
                <UserPermissionOptions
                  permissions={ensureSettingsPermission(checkboxes)}
                  onPermissionChange={onSectionChecked}
                />
              )}
            </Grid>
          ) : (
            <Grid item md={12} sm={12} xs={12}>
              <FormControl fullWidth>
                <RadioGroup
                  name="role"
                  value={role}
                  onChange={setFieldValue}
                  className={classes.radioGroup}
                >
                  <FormControlLabel
                    value={ROLES.ADMINISTRATOR}
                    control={
                      <Radio className={classes.radioButton} color="primary" />
                    }
                    label={
                      /* eslint-disable-next-line react/jsx-wrap-multilines */
                      <>
                        <Typography variant="h5" className={classes.formTitle}>
                          {ROLES.ADMINISTRATOR}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.formSubtitle}
                        >
                          {t('users.roles.administrator.description')}
                        </Typography>
                      </>
                    }
                  />
                </RadioGroup>
                <FormHelperTextBottom left={touched.role && errors.role} />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        {!isLoading && (
          <>
            <Button
              onClick={onClose}
              size="large"
              color="primary"
              variant="outlined"
            >
              {t('button.cancel')}
            </Button>
            <PrimaryButton size="large" type="submit">
              {t('users.addUser.sendInvite')}
            </PrimaryButton>
          </>
        )}
        {isLoading && (
          <CircularProgress data-testid="create-user-modal-spinner" />
        )}
      </Modal.Actions>
    </>
  );
}

NewUserForm.propTypes = {
  setFieldValue: func.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  handleBlur: func.isRequired,
  onClose: func.isRequired,
  classes: objectOf(string).isRequired,
  isLoading: bool.isRequired,
  backendError: string,
};

NewUserForm.defaultProps = {
  backendError: null,
};

export default withStyles(styles)(NewUserForm);
