import { useEffect, useState } from 'react';
import {
  DEFAULT_TILE_FREQUENCIES,
  PAYMENT_FREQUENCY_ONE_TIME,
} from '../../../globals/constants';

export default ({ transactionData, selectedFundId } = {}) => {
  const [defaultFrequency, setDefaultFrequency] = useState('');
  const [showEndDate, setShowEndDate] = useState();

  useEffect(() => {
    if (!selectedFundId) return;

    const { tiles } = transactionData;
    const { donationOptions, showEndDate: fundShowEndDate } =
      tiles.find((tile) => tile.id === selectedFundId) ?? {};

    if (!donationOptions) return;

    const { defaultOneTimeFrequency, defaultRecurringFrequency } =
      donationOptions;

    setDefaultFrequency(defaultOneTimeFrequency || defaultRecurringFrequency);
    setShowEndDate(fundShowEndDate);
  }, [selectedFundId, transactionData]);

  return selectedFundId
    ? {
        tileFrequencies: DEFAULT_TILE_FREQUENCIES,
        defaultFrequency,
        showEndDate,
      }
    : {
        tileFrequencies: DEFAULT_TILE_FREQUENCIES,
        defaultFrequency: PAYMENT_FREQUENCY_ONE_TIME,
        showEndDate: false,
      };
};
