import { useMemo } from 'react';
import { shape, arrayOf, objectOf, string, func } from 'prop-types';
import { withStyles } from '@material-ui/core';

import DraggableList from '../../DraggableList';
import TileItem from '../TileItem';

import styles from './styles';

function getTiles(tiles, hideTile) {
  return tiles.map(tile => ({
    key: tile.id,
    node: <TileItem tile={tile} hideTile={hideTile} />,
  }));
}

function Tiles({ classes, tiles, hideTile, onReorder }) {
  const tilesList = useMemo(() => getTiles(tiles, hideTile), [tiles, hideTile]);

  return (
    <div className={classes.tilesWrapper}>
      <DraggableList onReorder={onReorder} listItems={tilesList} axis="y" />
    </div>
  );
}

Tiles.propTypes = {
  classes: objectOf(string).isRequired,
  tiles: arrayOf(shape({})).isRequired,
  hideTile: func.isRequired,
  onReorder: func.isRequired,
};

export default withStyles(styles)(Tiles);
