import { func, objectOf, string, bool } from 'prop-types';
import { Button, withStyles, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UnarchiveIcon from '@material-ui/icons/Replay';
import styles from './styles';
import DisclosureMessage from '../DisclosureMessage';

function UnarchivePage({ classes, onClick, disabled }) {
  const { t } = useTranslation();
  const matches = useMediaQuery('(min-width:820px)');

  return (
    <DisclosureMessage
      className={classes.disclosure}
      title={t('site.unarchive.disclosureTitle')}
      message={t('site.unarchive.disclosureMessage')}
      variant={matches ? 'inline' : 'default'}
    >
      <Button
        size="medium"
        className={`${classes.unarchiveButton}`}
        variant="outlined"
        onClick={() => onClick()}
        disabled={disabled}
      >
        <UnarchiveIcon className={classes.unarchiveButtonIcon} />
        {t('site.unarchive.disclosureButton')}
      </Button>
    </DisclosureMessage>
  );
}

UnarchivePage.propTypes = {
  classes: objectOf(string).isRequired,
  onClick: func.isRequired,
  disabled: bool,
};

UnarchivePage.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(UnarchivePage);
