export default function styles(theme) {
  return {
    summary: {
      '&$expanded': {
        minHeight: 48,
      },
    },
    summaryContent: {
      margin: 0,
      '&$expanded': {
        margin: 0,
      },
    },
    menuLink: {
      padding: '14px 47px',
      display: 'flex',
    },
    details: {
      borderTop: '1px solid #EBEBEB',
      padding: '6px 18px 6px 42px',
    },
    label: {
      marginLeft: 15,
      color: 'white',
    },
    labelInactive: {
      marginLeft: 15,
      color: 'black',
    },
    active: {
      fontWeight: 700,
    },
    listItemText: {
      fontSize: '1.4rem',
      marginLeft: 28,
      marginRight: 8,
    },
    expanded: {},
    orangeBackground: {
      background: theme.palette.giving,
    },
    blueBackground: {
      background: theme.palette.donors,
    },
    greenBackground: {
      background: theme.palette.outreach,
    },
    pinkBackground: {
      background: theme.palette.smartVideo,
    },
    purpleBackground: {
      background: theme.palette.data,
    },
    internalPurpleBackground: {
      background: theme.palette.import,
    },
    greyBackground: {
      background: theme.palette.settings,
    },
  };
}
