export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    dropArea: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      borderWidth: 3,
      borderStyle: 'dashed',
      borderColor: theme.palette.primary.main,
      minWidth: 700,
      borderRadius: 8,
      padding: 32,
      margin: 40,
    },
    dropAreaHover: {
      background: 'rgba(127, 17, 224, 0.2)',
    },
    innerContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      pointerEvents: 'none',
    },
    innerContainerHover: {
      opacity: 0.5,
    },
    disabledInput: {
      opacity: 0.2,
    },
    disabledButton: {
      opacity: 0.2,
      color: `${theme.palette.white} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#e9e8f5',
      width: 100,
      height: 100,
      borderRadius: 100,
      marginBottom: 12,
    },
    iconStyle: {
      '& path': {
        stroke: theme.palette.primary.main,
      },
    },
    pickerText: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: '1.6rem',
      marginBottom: 10,
    },
    hiddenInput: {
      display: 'none',
    },
    fileInputContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    fileNameContainer: {
      flex: 1,
      fontSize: '1.6rem',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.text.disabled,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      padding: 8,
    },
    browseButtonContainer: {
      borderRadius: '0 6px 6px 0 !important',
      pointerEvents: 'auto',
      opacity: 1,
    },
    buttonText: {
      fontSize: '1.6rem',
      lineHeight: '42px',
      color: theme.palette.white,
    },
    selectedFileContainer: {
      width: '100%',
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #9C9D9F;',
      borderRadius: 6,
      padding: 16,
    },
    selectedFileText: {
      lineHeight: '20px',
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
  };
}
