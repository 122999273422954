import { bool, func, objectOf, string, node } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DeleteModalSpinner from './DeleteModalSpinner';
import Modal from '../Modal';

import styles from './styles';

export function DeleteModal({
  classes,
  open,
  onClose,
  isLoading,
  title,
  message,
  actions,
}) {
  return <Modal open={open} onClose={onClose}>
    {isLoading ? (
      <DeleteModalSpinner />
    ) : (
      <>
        <Modal.Title className={classes.title}>{title}</Modal.Title>
        <Modal.Content className={classes.content}>{message}</Modal.Content>
        <Modal.Actions className={classes.actions}>{actions}</Modal.Actions>
      </>
    )}
  </Modal>
}

DeleteModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func.isRequired,
  isLoading: bool,
  title: node,
  message: node,
  actions: node,
};

DeleteModal.defaultProps = {
  open: false,
  isLoading: false,
  title: null,
  message: null,
  actions: null,
};

export default withStyles(styles)(DeleteModal);
