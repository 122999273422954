import i18n from '../../../utils/i18n';

export const organizationPagePaths = {
  CONTINUE: '/organization/wizard/continue',
  CONTACT: '/organization/wizard/contact',
  BRANDING: '/organization/wizard/branding',
  FEES: '/organization/wizard/fees',
  REVIEW: '/organization/wizard/review',
};

export function createConfig() {
  const paths = organizationPagePaths;
  const translationComplete =
    i18n.t('organization.pageWizard.contact.key') !==
    'organization.pageWizard.contact.key';
  return [
    {
      // Added to avoid Bad path error for this wizard
      path: paths.CONTINUE,
    },
    {
      navTitle: translationComplete
        ? i18n.t('organization.pageWizard.contact.key')
        : '',
      pageTitleKey: 'organization.contact.title',
      path: paths.CONTACT,
      nextPath: paths.BRANDING,
    },
    {
      navTitle: i18n.t('organization.pageWizard.branding.key'),
      pageTitleKey: 'organization.pageWizard.branding.title',
      path: paths.BRANDING,
      nextPath: paths.FEES,
    },
    {
      navTitle: i18n.t('organization.pageWizard.fees.key'),
      pageTitleKey: 'organization.fees.title',
      path: paths.FEES,
      nextPath: paths.REVIEW,
    },
    {
      navTitle: i18n.t('organization.pageWizard.review.key'),
      pageTitleKey: 'organization.pageWizard.review.title',
      path: paths.REVIEW,
      nextPath: '/',
    },
  ];
}

export function organizationPageNavConfig(org) {
  let nextFound = false;
  const config = createConfig();
  return config.map((step) => {
    const newStep = { ...step };
    switch (step.navTitle) {
      case i18n.t('organization.pageWizard.contact.key'): {
        // If organizationId is assigned to Org Settings object, this means a record already exist.
        const finished = !!org.organizationId;
        if (finished) {
          newStep.isFinished = true;
        }
        break;
      }
      case i18n.t('organization.pageWizard.branding.key'): {
        if (org.primaryColor) {
          newStep.isFinished = true;
        }
        break;
      }
      case i18n.t('organization.pageWizard.fees.key'): {
        if (org.hasPaymentMethods) {
          newStep.isFinished = true;
        }
        break;
      }
      case i18n.t('organization.pageWizard.review.key'): {
        if (org.defaultsComplete) {
          newStep.isFinished = true;
        }
        break;
      }
      default:
      // Do nothing
    }

    if (newStep.navTitle && !newStep.isFinished && !nextFound) {
      newStep.next = true;
      nextFound = true;
    }

    return newStep;
  });
}

export default organizationPageNavConfig;
