export const PAGE_ACTIVE = 'Active';
export const PAGE_PENDING = 'Pending';
export const PAGE_ARCHIVED = 'Archived';

export const TILE_ACTIVE = 'Active';
export const TILE_PENDING = 'Pending';
export const TILE_ALL = 'ALL';
export const TILE_ARCHIVED = 'Archived';
export const TILE_VISIBLE = 'Visible';
export const TILE_HIDDEN = 'Hidden';
export const CUSTOM_TEXT = 'Use custom text';
export const CUSTOM_LABEL = 'Custom label';

export const TILE_TYPE_GIFT = 'Gift';
export const TILE_TYPE_PAYMENT = 'Payment';

export const PAGE_PLATFORM_YOUTUBE = 'YouTube';
export const PAGE_PLATFORM_FACEBOOK = 'Facebook';
export const PAGE_FACEBOOK_VIDEO = 'Facebook_Video';
export const PAGE_YOUTUBE_VIDEO = 'YouTube_Video';
export const PAGE_YOUTUBE_CHANNEL = 'YouTube_Channel';

export const UNSPLASH_SEARCH_TERM = 'Trees';

export const IMAGE_ACTIVE = 'Active';
export const IMAGE_STAGE = 'Stage';

export const PAYMENT_FREQUENCY_MONTHLY = 'Monthly';
export const PAYMENT_FREQUENCY_WEEKLY = 'Weekly';
export const PAYMENT_FREQUENCY_QUARTERLY = 'Quarterly';
export const PAYMENT_FREQUENCY_ONE_TIME = 'OneTimeNow';
export const PAYMENT_FREQUENCY_ONE_TIME_FUTURE = 'OneTimeFuture';
export const PAYMENT_FREQUENCY_SEMI_MONTHLY = 'SemiMonthly';
export const PAYMENT_FREQUENCY_BI_WEEKLY = 'BiWeekly';
export const PAYMENT_FREQUENCY_TWICE_A_MONTH = 'BiMonthly';
export const PAYMENT_FREQUENCY_ANNUALLY = 'Annually';

export const ONE_TIME_OPTION = 'oneTime';
export const RECURRING_OPTION = 'recurring';

export const DEFAULT_TILE_FREQUENCIES = [
  { name: PAYMENT_FREQUENCY_ONE_TIME, group: ONE_TIME_OPTION },
  { name: PAYMENT_FREQUENCY_ONE_TIME_FUTURE, group: RECURRING_OPTION },
  { name: PAYMENT_FREQUENCY_WEEKLY, group: RECURRING_OPTION },
  { name: PAYMENT_FREQUENCY_BI_WEEKLY, group: RECURRING_OPTION },
  { name: PAYMENT_FREQUENCY_TWICE_A_MONTH, group: RECURRING_OPTION },
  { name: PAYMENT_FREQUENCY_MONTHLY, group: RECURRING_OPTION },
  { name: PAYMENT_FREQUENCY_QUARTERLY, group: RECURRING_OPTION },
  { name: PAYMENT_FREQUENCY_SEMI_MONTHLY, group: RECURRING_OPTION },
  { name: PAYMENT_FREQUENCY_ANNUALLY, group: RECURRING_OPTION },
];

export const RECURRING_FREQUENCIES = [
  PAYMENT_FREQUENCY_MONTHLY,
  PAYMENT_FREQUENCY_WEEKLY,
  PAYMENT_FREQUENCY_QUARTERLY,
  PAYMENT_FREQUENCY_SEMI_MONTHLY,
  PAYMENT_FREQUENCY_BI_WEEKLY,
  PAYMENT_FREQUENCY_TWICE_A_MONTH,
  PAYMENT_FREQUENCY_ANNUALLY,
];

export const PAYMENT_FREQUENCY_DISPLAY_NAMES = {
  [PAYMENT_FREQUENCY_ONE_TIME]: 'Once, now',
  [PAYMENT_FREQUENCY_ONE_TIME_FUTURE]: 'Once, on a future date',
  [PAYMENT_FREQUENCY_WEEKLY]: 'Every week',
  [PAYMENT_FREQUENCY_BI_WEEKLY]: 'Every two weeks',
  [PAYMENT_FREQUENCY_TWICE_A_MONTH]: 'Twice a month (1st and 15th)',
  [PAYMENT_FREQUENCY_MONTHLY]: 'Monthly',
  [PAYMENT_FREQUENCY_QUARTERLY]: 'Every quarter',
  [PAYMENT_FREQUENCY_ANNUALLY]: 'Every year',
};

export const PAYMENT_FREQUENCY_TRANSLATION_KEYS_MAP = {
  [PAYMENT_FREQUENCY_ONE_TIME]: 'payment.frequency.one.time',
  [PAYMENT_FREQUENCY_ONE_TIME_FUTURE]: 'payment.frequency.one.time.future',
  [PAYMENT_FREQUENCY_WEEKLY]: 'payment.frequency.weekly',
  [PAYMENT_FREQUENCY_BI_WEEKLY]: 'payment.frequency.bi.weekly',
  [PAYMENT_FREQUENCY_TWICE_A_MONTH]: 'payment.frequency.twice.a.month',
  [PAYMENT_FREQUENCY_MONTHLY]: 'payment.frequency.monthly',
  [PAYMENT_FREQUENCY_QUARTERLY]: 'payment.frequency.quarterly',
  [PAYMENT_FREQUENCY_ANNUALLY]: 'payment.frequency.annually',
  [PAYMENT_FREQUENCY_SEMI_MONTHLY]: 'payment.frequency.semimonthly',
};

export const PAYMENT_FREQUENCY_LIST = {
  [PAYMENT_FREQUENCY_ONE_TIME]: {
    fullDescription: 'Once, now',
    shortDescription: 'One-Time',
    displayOrder: 1,
  },
  [PAYMENT_FREQUENCY_ONE_TIME_FUTURE]: {
    fullDescription: 'Once, on a future date',
    shortDescription: 'One-Time',
    displayOrder: 2,
  },
  [PAYMENT_FREQUENCY_WEEKLY]: {
    fullDescription: 'Every week',
    shortDescription: 'Weekly',
    displayOrder: 3,
  },
  [PAYMENT_FREQUENCY_BI_WEEKLY]: {
    fullDescription: 'Every two weeks',
    shortDescription: 'Biweekly',
    displayOrder: 4,
  },
  [PAYMENT_FREQUENCY_TWICE_A_MONTH]: {
    fullDescription: 'Twice a month (1st & 15th)',
    shortDescription: 'BiMonthly',
    displayOrder: 5,
  },
  [PAYMENT_FREQUENCY_MONTHLY]: {
    fullDescription: 'Monthly',
    shortDescription: 'Monthly',
    displayOrder: 6,
  },
  // TODO: DEFINE Semi-Monthly
  [PAYMENT_FREQUENCY_SEMI_MONTHLY]: {
    fullDescription: 'Semi-Monthly',
    shortDescription: 'SemiMonthly',
    displayOrder: 7,
  },
  [PAYMENT_FREQUENCY_QUARTERLY]: {
    fullDescription: 'Quarterly',
    shortDescription: 'Quarterly',
    displayOrder: 8,
  },
  [PAYMENT_FREQUENCY_ANNUALLY]: {
    fullDescription: 'Annually',
    shortDescription: 'Annual',
    displayOrder: 9,
  },
};

export const UNSPLASH_IMAGES_PER_PAGE = 8;
export const UNSPLASH_UTM_PARAMS = '?utm_source=MyVanco&utm_medium=referral';

// Session length before we clear basket items/attempt to log out user.
// We should try to keep it in sync with the IDS token expiration length if that value changes.
export const SESSION_LENGTH_MILLISECONDS = 1000 * 60 * 60; // 1000 * seconds * minutes

export const BUTTON_TEXT_OPTIONS_GIFT = [
  'Contribute',
  'Contribute now',
  'Donate',
  'Donate now',
  'Give',
  'Give now',
  'Offer support',
  'Start giving',
  'Start donating',
];

export const BUTTON_TEXT_OPTIONS_PAYMENT = [
  'Buy',
  'Buy now',
  'Get tickets',
  'Make a payment',
  'Pay',
  'Pay now',
  'Purchase',
  'Purchase now',
  'Register',
  'Register now ',
  'Sign up',
];

export const MEMO_LINE_OPTIONS = [
  'Designation',
  'Give to',
  'In honor of',
  'In memory of',
  'Memo',
  'Note',
  'Please specify',
  'Thankful for',
];

export const MEMO_LINE_PAYMENT_OPTIONS = [
  'Designation',
  'Memo',
  'Note',
  'Please specify',
];

export const ROLES = {
  GLOBAL_ADMINISTRATOR: 'Global Administrator',
  ACCOUNT_OWNER: 'Account Owner',
  ADMINISTRATOR: 'Administrator',
};

export const EDIT_USER_STEPS = {
  EDIT_ROLE: 0,
  TRANSFER_OWNERSHIP: 1,
};
export const DEFAULT_COLOR = '#5727F5';

export const COLOR_OPTIONS = [
  DEFAULT_COLOR,
  '#3041FA',
  '#006EFD',
  '#0B7FAA',
  '#02866C',
  '#028A03',
  '#20750C',
  '#D14900',
  '#DE0909',
  '#E10694',
  '#CD00DB',
  '#B12AFF',
  '#894EFF',
  '#6061FF',
];

export const CHANNEL_TYPE = {
  ONLINE: 1,
  MOBILE: 2,
  STREAM: 3,
};

export const MOBILE_USER_TAB_ALL = 'All';

export const MOBILE_USER_ROLE = {
  MEMBER: 'Member',
  MODERATOR: 'Moderator',
  STAFF: 'Staff',
};

export const MOBILE_USER_STATUS = {
  ACTIVE: 'Active',
  SUSPENDED: 'Suspended',
};

export const MOBILE_EVENTS_TABS = {
  UPCOMING: 'Upcoming',
  PAST: 'Past',
};

export const GROUP_ACCESS = {
  OPEN: 'Open',
  INVITE_ONLY: 'Invite only',
};

export const LAYOUT_TYPE = {
  RESPONSIVE: 'Responsive',
  FIXED: 'Fixed',
};

export const REPORTS_EVENTS_TABS = {
  FINANCIAL: 'Financial',
};

export const DEFAULT_IFRAME_WIDTH = 768;
export const DEFAULT_IFRAME_HEIGHT = 1024;

export const MAX_REVIEW_DISPLAY_LENGTH = 80;

export const VO_WIZARD = 'VO_WIZARD';
export const VO_QR_QUERY_PARAMETER = 'source=qr';

export const COMMUNICATIONS_COMPLETED_TEMPLATE = 'realtime';
export const COMMUNICATIONS_SCHEDULED_TEMPLATE = 'scheduled';
export const COMMUNICATIONS_CONTRIBUTOR_NAME_KEY = '%contributor name%';

export const PAYMENT_METHOD = {
  CREDIT_CARD: 'creditCard',
  ACH: 'ach',
  CREDIT_CARD_TOKENIZE: 'card',
  ACH_TOKENIZE: 'echeck',
  CARD_TYPE_VISA: 'visa',
  CARD_TYPE_MASTERCARD: 'mastercard',
  CARD_TYPE_DISCOVER: 'discover',
  CARD_TYPE_AMEX: 'amex',
  ACCOUNT_CHECKING: 'checking',
  ACCOUNT_SAVINGS: 'savings',
  CXP_AUTHORIZATION_TYPE_IDS: 'IDS',
};

export const SERVICE_PAYMENT_METHOD = {
  CREDIT_CARD: 'CreditCard',
  ACH: 'ACH',
};

export default {
  TILE_ACTIVE,
  TILE_PENDING,
  TILE_ARCHIVED,
  TILE_ALL,
  TILE_VISIBLE,
  TILE_HIDDEN,
  CUSTOM_TEXT,
  PAYMENT_FREQUENCY_DISPLAY_NAMES,
  SESSION_LENGTH_MILLISECONDS,
  COLOR_OPTIONS,
  CHANNEL_TYPE,
  MOBILE_USER_TAB_ALL,
  MOBILE_USER_ROLE,
  MOBILE_USER_STATUS,
  GROUP_ACCESS,
  PAGE_ACTIVE,
  PAGE_PENDING,
  PAGE_ARCHIVED,
  PAGE_PLATFORM_YOUTUBE,
  PAGE_PLATFORM_FACEBOOK,
  PAGE_FACEBOOK_VIDEO,
  PAGE_YOUTUBE_VIDEO,
  PAGE_YOUTUBE_CHANNEL,
  LAYOUT_TYPE,
  VO_QR_QUERY_PARAMETER,
  PAYMENT_METHOD,
};

export const TEXT_EDITOR = {
  MAX_LENGTH: 5000, // max char len for quil textarea
  OFFSET_CHAR: 1,
};
export const COMMUNICATIONS_EMAIL = {
  SUBJECT_TYPE: 'subject',
  BODY_TYPE: 'body',
  COMPLETED_TEMPLATE: 'realtime',
  SCHEDULED_TEMPLATE: 'scheduled',
};

export const BUTTON_TEXT_OPTIONS_KIOSK = [
  'Contribute',
  'Contribute now',
  'Donate',
  'Donate now',
  'Give',
  'Give now',
  'Offer support',
  'Start giving',
  'Start donating',
];

export const TRANSACTION_ITEM_TYPES = {
  OPEN_AMOUNT: 'Gift',
  PAYMENT_FIXED_AMOUNT: 'Payment',
};

export const CAMPAIGN_TYPE = { GIFT: 'Gift', PAYMENT: 'Payment' };
export const PAYMENT_FREQUENCY = {
  ONE_TIME_NOW: 'OneTimeNow',
  MONTHLY: 'Monthly',
  WEEKLY: 'Weekly',
  ONE_TIME_FUTURE: 'OneTimeFuture',
  BI_WEEKLY: 'BiWeekly',
  TWICE_A_MONTH: 'BiMonthly',
  QUARTERLY: 'Quarterly',
  ANNUALLY: 'Annually',
};
export const MAX_PAYMENT_ITEMS = 10;
export const PAYMENT_FREQUENCY_OPTIONS = {
  RECURRING: 'recurring',
  ONCE: 'once',
};
export const CREDIT_CARD = 'creditCard';
export const ACH = 'ach';

export const CXP_ERROR_CODES = {
  ADDRESS_AND_POSTAL_CODE_MISSMATCH: '7053',
  POSTAL_CODE_MISSMATCH: '7054',
  ADDRESS_MISSMATCH: '7055',
};

export const NO_ADDRESS_PROVIDED = 'No Address Provided';

export const MY_VANCO_ORIGIN_NAME = 'MyVanco';

export const FREQUENCY_GROUPS = {
  ONE_TIME: 'One time',
  RECURRING: 'Recurring',
};

export const ACCESS_TOKEN = 'accessToken';
export const SUCCESSFUL_HTTP_RESPONSES = {
  OK: 200,
};
export const ERROR_HTTP_RESPONSES = {
  INTERNAL_SERVER: 500,
};

export const LOCATION_PAGE_LAYOUTS = {
  DETAIL: 'detail',
  EXPRESS: 'express',
};

export const DONORS_ROUTES = {
  donors: '/donors',
  tags: '/donors-settings',
};

export const GIVING_ROUTES = {
  tile: '/tile',
  tiles: '/tiles',
  sites: '/sites',
  getStarted: '/getStarted',
  donations: '/donations',
  pageSettings: '/page-settings',
  mobile: '/mobile/',
  kiosk: '/kiosk',
  newTransaction: '/new-transaction',
};

export const IMPORT_ROUTES = {
  import: '/import',
  newImport: '/new-import',
};

export const SETTINGS_ROUTES = {
  users: '/users',
  organization: '/organization',
  communications: '/communications',
  paidfeatures: '/paidfeatures',
  featureManagement: '/feature-management',
};

export const REPORTING_ROUTES = {
  reporting: '/reporting',
  givingDashboard: '/giving-dashboard',
  insightsPaywall: '/insights-paywall',
};

export const VIDEO_ROUTES = {
  liveStreaming: '/streams',
  liveStreamingSetup: '/stream/get-started',
  smartLibrary: '/smart-library',
  smartLibraryPaywall: '/smart-library-paywall',
  smartClips: '/smart-clips',
  smartClipsPaywall: '/smart-clips-paywall',
  videoSettings: '/video-settings',
};

export const VIDEO_WIZARD_ROUTES = {
  review: '/wizard/stream/:pageId/review',
  pageInfo: '/wizard/stream/:pageId/page-info',
  pageInfoDetail: '/wizard/stream/:pageId/page-info',
  pageChatDetail: '/wizard/stream/:pageId/chat',
  pageGivingDetail: '/wizard/stream/:pageId/giving',
};

export const PAYWALL_ROUTES = {
  '/emails-paywall': 'outreachEmails',
  '/templates-paywall': 'outreachEmails',
  '/smart-library-paywall': 'smartLibrary',
  '/smart-clips-paywall': 'smartClips',
};

export const PAYWALL_IFRAME_ROUTES = {
  outreachEmails: '/emails-paywall',
  outreachTemplates: '/templates-paywall',
};

export const PAYWALL_PAGETYPE = {
  smartLibrary: 'smartLibrary',
  smartClips: 'smartClips',
  outreachEmails: 'outreachEmails',
};

export const COMMS_ROUTE = {
  emails: '/emails',
  emailsPaywall: '/emails-paywall',
  sms: '/sms',
  pushNotifications: '/push-notifications',
  templates: '/templates',
  settings: 'communications-settings',
  contentManager: 'content/cms',
  writingDesk: 'content/desk',
};

export const TITLES_SUBROUTES = {
  tiles: '/tiles',
  tile: '/tile',
};

export const SITES_SUBROUTES = {
  pageSettings: '/page-settings',
  site: '/site',
  sites: '/sites',
  pageInfo: '/page',
  getStarted: '/getStarted',
};

export const MOBILE_SUBROUTES = {
  mobile: '/mobile',
  tilesOrder: '/tiles-order',
};

export const STREAM_SUBROUTES = {
  stream: '/stream',
};

export const VANCO_VIDEO_TYPE = {
  CLIP: 'clips',
  VIDEO: 'videos',
  SETTINGS: 'settings/videos',
};

export const FAITH_FORWARD_EVENT_TYPE = {
  signupRequired: 'signupRequired',
  openVideoFeed: 'openVideoFeed',
  copyVideoFeedLink: 'copyVideoFeedLink',
  copyEmbedCode: 'copyEmbedCode',
  openQRCode: 'openQRCode',
  setChannelUrl: 'setChannelUrl',
};

export const POST_MESSAGE_EVENT_NAME = 'message';

export const VIDEO_SETTINGS_TABS = {
  channel: 'channel',
  liveStreaming: 'live-streaming',
};

export const IMAGE_ASPECTS = {
  SQUARE: 1 / 1,
  RECTANGLE: 16 / 9,
};

export const ALERT_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFORMATION: 'info',
  WARNING: 'warning',
};

export const ANALYTICS_ROUTES = {
  reports: '/reports',
  basicGivingDashboard: '/basic-giving',
};

export const LOCATION_PATCH_FIELDS = {
  DEFAULT_VIEW: 'defaultView',
  ENABLE_BIBLE_READER: 'enableBibleReader',
  ENABLE_DEVOTIONALS: 'enableDevotionals',
};

export const BIBLE_VERSIONS = [
  { id: 'kjv', title: 'King James' },
  { id: 'web', title: 'World English Bible' },
];

export const ACST_PREFIXES = [
  'SPG',
  'MP',
  'RLM',
  'AAC',
  'ABT',
  'HDM',
  'ESMP',
  'ESAAC',
  'ESRLM',
  'ESABT',
  'ESHDM',
  'CTG',
  'ESCTG',
];
export const PF_PREFIXES = ['PF', 'ESPF'];

export const VARIANTS = {
  UNPUBLISH: 'unpublish',
  PUBLISH: 'publish',
  ARCHIVE: 'archive',
  DELETE: 'delete',
  UNARCHIVE: 'unarchive',
};

export const FEATURES_TYPE = {
  Communications: 1,
  ContentManager: 2,
  BibleReader: 3,
  Devotionals: 4,
  SmartLibrary: 5,
  SmartClipsDisableDate: 6,
  Insights: 7,
};

export const REPORT_HELP_VIDEOS = {
  CONTROLS: 'Controls.mov',
  DOWNLOAD: 'Download.mov',
  REFRESH_DATA: 'RefreshData.mov',
  SCHEDULE_EXPORTS: 'ScheduleExports.mov',
  TOOLTIP: 'Tooltip.mov',
  UNDERLYING_DATA: 'UnderlyingData.mov',
};

export const UNKNOWN = 'unknown';

export const PAYWALL_MAP = {
  INSIGHTS: 'insights',
  NONE: 'none',
};

export const PAYWALL_TEST_IDS = {
  INSIGHTS: {
    ACC_OWNER_DESC: 'accountOwnerDescription',
    DESC: 'description',
  },
};

export const SECTIONS_TYPE = {
  Giving: 1,
  Donors: 2,
  OutReach: 3,
  Smart_Video: 4,
  Import: 5,
  Reporting: 6,
  Settings: 7,
};

export const SUB_SECTION_TYPE = {
  Giving_Tiles: 1,
  Giving_Transactions: 2,
  Giving_Sites: 3,
  Giving_Mobile: 4,
  Giving_Kiosk: 5,
  Donors_Donors: 6,
  Donors_Tags: 7,
  OutReach_Emails: 8,
  OutReach_SMS: 9,
  OutReach_Writing_Desk: 10,
  OutReach_Content_Manager: 11,
  OutReach_Templates: 12,
  Smart_Video_Live_Streaming: 13,
  Smart_Video_Smart_Library: 14,
  Smart_Video_Smart_Clips: 15,
  Smart_Video_Settings: 16,
  Import_Giving: 17,
  Reporting_Reports: 18,
  Reporting_Insights: 19,
  Settings_Organization: 20,
  Settings_Users: 21,
  Settings_Email_Receipts: 22,
  Settings_Paid_Features: 23,
  Settings_Identity_Provider: 24,
};

export const ORGANIZATION_CONTINUE_WIZARD_ROUTE =
  '/organization/wizard/continue';
