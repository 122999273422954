export function selectPage(state, pageId) {
  return state.pages.pages.find(page => page.id === pageId);
}

export function selectActivePages(state) {
  return state.pages.pages.filter(page => page.status === 'Active');
}

export function selectPendingPages(state) {
  return state.pages.pages.filter(page => page.status === 'Pending');
}

export function selectArchivedPages(state) {
  return state.pages.pages.filter(page => page.status === 'Archived');
}

export default {
  selectPage,
  selectActivePages,
  selectPendingPages,
  selectArchivedPages,
};
