export default function styles() {
  return {
    button: {
      padding: 0,
      width: 'auto',
      '&:hover': {
        textDecoration: 'underline',
        background: 'transparent',
      },
    },
  };
}
