export default function styles(theme) {
  return {
    title: {
      marginBottom: 30,
    },
    noteSection: {
      marginBottom: 30,
    },
    address: {
      whiteSpace: 'pre-line',
    },
    grid: {
      border: '1px solid #EBEBEB',
      borderRadius: 12,
      padding: '20px 25px',
      marginBottom: 20,
    },
    gridTitle: {
      color: theme.palette.primary.light,
      fontWeight: 'bold',
      marginBottom: 20,
    },
    body2: {
      lineHeight: 1,
    },
    body: {
      lineHeight: 1,
    },
    gridRow: {
      marginBottom: 20,
    },
    notProvided: {
      color: theme.palette.secondary.medium,
    },
    primaryColorContainer: {
      alignItems: 'center',
      display: 'flex',
      marginTop: -5,
      position: 'relative',
    },
    primaryColor: {
      borderRadius: 2,
      marginRight: 15,
      height: 24,
      width: 24,
    },
  };
}
