import FormControl from '@material-ui/core/FormControl';
import { bool, func, number, oneOfType, string } from 'prop-types';

import OutlinedTextField from '../OutlinedTextField';

export function PageNameInput({
  value,
  error,
  touched,
  maxLength,
  handleBlur,
  handleChange,
  label,
  placeholder,
  ...rest
}) {
  return (
    <FormControl fullWidth>
      <OutlinedTextField
        showCount
        id="pageName"
        name="pageName"
        data-testid="pageName"
        error={error.length > 0 && touched}
        value={value}
        label={label}
        placeholder={placeholder}
        inputProps={{
          maxLength,
        }}
        onBlur={handleBlur}
        onChange={handleChange}
        autoComplete="off"
        errorMessage={touched && error.length > 0 ? error : null}
        {...rest}
      />
    </FormControl>
  );
}

PageNameInput.propTypes = {
  value: oneOfType([string, number]),
  error: string,
  touched: bool,
  maxLength: number,
  handleBlur: func,
  handleChange: func,
  label: string,
  placeholder: string,
};

PageNameInput.defaultProps = {
  value: null,
  error: '',
  touched: false,
  maxLength: null,
  handleBlur: null,
  handleChange: null,
  label: '',
  placeholder: '',
};

export default PageNameInput;
