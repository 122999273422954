import { connect } from 'react-redux';

import Contact from './Contact';
import { saveOrganizationSettings } from '../../../../store/organizations/actions';

export function mapStateToProps(state) {
  return {
    organizationId: state.organizations.currentOrganization.id,
    promptToCompleteOrganizationSettings:
      state.user.user.promptToCompleteOrganizationSettings,
  };
}

export default connect(mapStateToProps, {
  saveOrganizationSettings,
})(Contact);
