import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFeatures } from '../../../store/organizations/actions';
import { FEATURES_TYPE } from '../../../globals/constants';
import { useFeatures } from '../useFeatures/useFeatures';
import useUser from '../useUser';
import { isSameOrBefore } from '../../date/timeZoneHelper';

function isVisibleFeature(feature, isInternalUser) {
  const disableDate =
    feature.length > 0 && feature[0]?.disableDate
      ? isSameOrBefore(feature[0]?.disableDate)
      : false;

  return !disableDate || isInternalUser;
}

export function useGetOrgFeatures(orgId) {
  const dispatch = useDispatch();

  const { features, loadingFeaturesSettings } = useSelector((state) => ({
    features: state.organizations?.currentOrganization.features || {},
    loadingOrgSettings: state.organizations?.loadingFeaturesSettings,
  }));

  const { wasInternal: isInternalUser } = useUser();

  const { ContentManager: ffContentManager, Communications: ffCommunications } =
    useFeatures();

  useEffect(() => {
    if (orgId && !loadingFeaturesSettings) {
      dispatch(getUserFeatures(orgId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, dispatch]);

  let shouldDisplayOutreachEmail = isInternalUser;
  let shouldDisplayContentManager = isInternalUser;

  if (features.length > 0) {
    const commsFeature = features?.filter(
      (feature) => feature.featureId === FEATURES_TYPE.Communications,
    );

    const contentFeature = features?.filter(
      (feature) => feature.featureId === FEATURES_TYPE.ContentManager,
    );

    const isCommunicationsVisible = isVisibleFeature(
      commsFeature,
      isInternalUser,
    );
    const isContentManagerVisible = isVisibleFeature(
      contentFeature,
      isInternalUser,
    );

    shouldDisplayOutreachEmail = ffCommunications && isCommunicationsVisible;
    shouldDisplayContentManager = ffContentManager && isContentManagerVisible;
  }

  return {
    features,
    loadingFeaturesSettings,
    shouldDisplayOutreachEmail,
    shouldDisplayContentManager,
  };
}

export default useGetOrgFeatures;
