import BaseService from '../BaseService';

class TileService extends BaseService {
  getTiles(page) {
    return this.http.get(`campaigns?location=${page.id}&include=locations`);
  }

  getTilesByOrg() {
    return this.http.get(`campaigns?include=locations`);
  }

  getTile(tile) {
    return this.http.get(`campaigns/${tile.id}`);
  }

  getTileWithLocations(tile) {
    return this.http.get(`campaigns/${tile.id}?include=locations`);
  }

  saveTile(tile) {
    return this.http.put(`campaigns/${tile.id}`, { data: tile });
  }

  saveTiles(page, tiles) {
    // TODO: Remove the '?revisedOrdinalNumbering=true' part
    return this.http.put(
      `locations/${page.id}/campaigns?revisedOrdinalNumbering=true`,
      { data: tiles },
    );
  }

  patchTile(tileId, values, operation) {
    const patchValues = [];
    Object.keys(values).forEach(key => {
      patchValues.push(this.patchHelper(key, values[key], operation));
    });
    return this.http.patch(`campaigns/${tileId}`, patchValues);
  }

  createTile(tile) {
    return this.http.post(`campaigns`, { data: tile });
  }

  deleteTile(tile) {
    return this.http.delete(`campaigns/${tile.id}`);
  }

  updateTileImage(tile) {
    return this.http.post(`campaigns/${tile.id}/images`, { data: tile });
  }

  getIsDeletable(tile) {
    return this.http.get(`campaigns/${tile.id}/deletable`);
  }

  updateTileStatus(tileIds, status, pageId) {
    return this.http.post(`locations/${pageId}/campaignstatus`, {
      data: { campaignIds: tileIds, status },
    });
  }
}

export default new TileService();
