import { useDispatch } from 'react-redux';
import { useIsDeletablePage, useDeletePage as useDelete } from '../pages';
import { handleException } from '../../../store/alerts/actions';

export function useDeletePage(page, config = {}) {
  const dispatch = useDispatch();
  const { enabled = true, ...rest } = config;
  const {
    data: isDeletable,
    status: isDeletableStatus,
    isFetching: isDeletableFetching,
  } = useIsDeletablePage(page, {
    enabled: Boolean(page?.id && enabled === true),
    refetchOnWindowFocus: false,
    retry: 0,
    onError: error => {
      dispatch(handleException(error));
    },
    ...rest,
  });
  const { mutate: deletePage, isLoading, status: deletingStatus } = useDelete();

  return {
    isDeletable: !!isDeletable,
    isDeletableStatus,
    isDeletableFetching,
    deletePage,
    isDeleting: isLoading,
    deletingStatus,
  };
}

export default useDeletePage;
