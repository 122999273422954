import { commonStyles } from '../styles';

export default function styles(theme) {
  return {
    arrowIcon: {
      marginLeft: 4,
    },
    archivedPage: {
      paddingTop: 8,
      paddingBottom: 48,
    },
    showArchivedButton: {
      padding: '8px 12px 8px 16px',
      margin: '0 42px',
      borderRadius: 4,
    },
    emptySpace: {
      height: 100,
    },
    ...commonStyles(theme),
  };
}
