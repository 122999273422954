import commonStyles from '../styles';

export default function styles(theme) {
  return {
    form: {
      marginBottom: theme.spacing(4),
    },
    ...commonStyles(theme),
  };
}
