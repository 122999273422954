export default function styles() {
  return {
    title: {
      padding: '24px 41px 28px',
    },
    content: {
      padding: 20,
    },
    actions: {
      justifyContent: 'space-between',
      margin: '24px 41px',
    },
    image: {
      maxWidth: 500,
      maxHeight: 500,
    },
  };
}
