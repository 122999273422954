import { WebAuth } from '@vancoplatform/web-auth';
import { helperWindow } from '../../utils';

// This AuthService communicates with IDS
export class AuthService {
  static client;

  static redirectPath = window.location.pathname;

  static config = {
    tenant: window.env.REACT_APP_IDS_TENANT,
    domain: window.env.REACT_APP_IDS_DOMAIN,
    clientId: window.env.REACT_APP_IDS_CLIENT_ID,
    audience: window.env.REACT_APP_IDS_AUDIENCE, // bug on IDS so this is required
    responseType: 'code',
    scope: window.env.REACT_APP_IDS_SCOPES,
    redirectUri: helperWindow.origin(),
    acrValues: 'ids:loa:pci:nc',
    state: JSON.stringify({
      // Note that redirectPath is no longer used and user is routed to /tiles no matter what
      redirectPath: ['/logout', '/login'].includes(this.redirectPath)
        ? '/'
        : this.redirectPath,
    }),
  };

  constructor() {
    this.client = new WebAuth(AuthService.config);
  }

  login(loginContainer) {
    return this.client.embedded.authorize(
      { acrValues: AuthService.config.acrValues }, // bug on IDS so this is required
      loginContainer,
    );
  }

  renewAuth(idToken) {
    return this.client.renewAuth({ idTokenHint: idToken });
  }

  logout(idToken) {
    this.client.logout({
      postLogoutRedirectUri: `${window.env.REACT_APP_MP_URL}/logout`,
      idTokenHint: idToken,
    });
  }
}

export default new AuthService();
