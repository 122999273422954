export default function styles(theme) {
  return {
    container: {
      width: 840,
    },
    title: {
      marginBottom: theme.spacing(2),
    },
  };
}
