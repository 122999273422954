import { bool, func, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Smartphone from '@material-ui/icons/Smartphone';
import { useHistory } from 'react-router-dom';

import { SetupLayout } from '../../common';
import {
  CHANNEL_TYPE,
  PAGE_ACTIVE,
  PAGE_PENDING,
} from '../../../globals/constants';
import useSetCurrentPageByChannel from '../../../utils/hooks/useSetCurrentPageByChannel';
import pageNavConfig from '../../navigation/WizardLayout/mobile.page.config';
import { defaultPage } from '../../../globals/defaults';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

// TODO: Remove with ChannelManagement FF cleanup

function GetStartedVancoMobile({
  loadingPages,
  hasLoadedPages,
  features,
  createPage,
  loadingCreate,
  communityFeaturesChoosen,
}) {
  const { t } = useTranslation();
  usePageTitle(t('titles.vanco.mobile.setup'));
  const history = useHistory();
  const currentPage = useSetCurrentPageByChannel({
    channelType: CHANNEL_TYPE.MOBILE,
    pageStatus: [PAGE_PENDING, PAGE_ACTIVE],
  });

  const initPageSetup = async () => {
    const newPage = await createPage({
      ...defaultPage,
      channelId: CHANNEL_TYPE.MOBILE,
    });

    // Prevents to sent to an incorrect route if creation failed
    if (!newPage?.id) return;

    history.push(`/wizard/mobile/${newPage.id}/app-info`);
  };

  const setupContinue = () => {
    const config = pageNavConfig(
      currentPage,
      communityFeaturesChoosen,
      features,
    );

    if (currentPage.status === PAGE_ACTIVE) {
      history.push(
        config[config.length - 1].path.replace(':pageId', currentPage.id),
      );
    } else {
      const nextPage = config.find((step) => step.next);
      history.push(nextPage.path.replace(':pageId', currentPage.id));
    }
  };

  const onContinueButtonClick = () => {
    if (!currentPage.id) {
      initPageSetup();
    } else {
      setupContinue();
    }
  };

  if (loadingPages || !hasLoadedPages) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <SetupLayout>
      <SetupLayout.Title>
        <Smartphone
          color="primary"
          data-testid="get-started-vanco-online-icon"
        />
        {t('vanco.mobile.get.started.title')}
      </SetupLayout.Title>
      <SetupLayout.Content>
        {t('vanco.mobile.get.started.description')}
      </SetupLayout.Content>
      {!loadingCreate ? (
        <SetupLayout.Button onClick={onContinueButtonClick}>
          {currentPage.status === PAGE_ACTIVE || currentPage.id
            ? t('button.continueSetup')
            : t('button.getStarted')}
        </SetupLayout.Button>
      ) : (
        <SetupLayout.CircularProgress />
      )}
    </SetupLayout>
  );
}

GetStartedVancoMobile.propTypes = {
  loadingPages: bool.isRequired,
  hasLoadedPages: bool.isRequired,
  features: shape({}),
  createPage: func.isRequired,
  loadingCreate: bool.isRequired,
  communityFeaturesChoosen: bool,
};

GetStartedVancoMobile.defaultProps = {
  communityFeaturesChoosen: false,
  features: {},
};

export default GetStartedVancoMobile;
