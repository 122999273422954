import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import PageTitle from '../PageTitle';
import PageDescription from '../PageDescription';
import CustomFormik from '../CustomFormik';
import Form from './Form';
import { defaultTile } from '../../../globals/defaults';
import { createSchema, maxLengths } from './validation';

export function TileTitle({
  pageTitleKey,
  tile = defaultTile,
  saveTile,
  loadingTile,
  loadingSave,
  goToNext,
  goToPrev,
  updateTile,
  setError,
  newTile,
  isFinished,
}) {
  const { t } = useTranslation();
  const schema = createSchema();
  const queryClient = useQueryClient();
  const loading = loadingTile || !tile;
  useEffect(() => {
    if (newTile.id && goToNext) {
      goToNext();
    }
  }, [newTile, goToNext]);

  if (loading) {
    return null;
  }

  const onSubmit = async values => {
    const formTile = {
      ...tile,
      ...values,
    };
    await saveTile(formTile);
    queryClient.setQueryData(['tile', formTile.id], formTile);
  };

  return (
    <>
      <PageTitle>{t(pageTitleKey)}</PageTitle>
      <PageDescription>{t('title.description')}</PageDescription>
      <CustomFormik
        onChange={updateTile}
        onErrorsChange={setError}
        initialValues={{
          title: tile.title,
        }}
        onSubmit={onSubmit}
        render={formikProps => (
          <Form
            maxLengths={maxLengths}
            goToPrev={goToPrev}
            isFinished={isFinished}
            loadingSave={loadingSave}
            {...formikProps}
          />
        )}
        validationSchema={schema}
      />
    </>
  );
}

TileTitle.propTypes = {
  pageTitleKey: PropTypes.string,
  tile: PropTypes.object,
  loadingTile: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  saveTile: PropTypes.func.isRequired,
  goToNext: PropTypes.func,
  goToPrev: PropTypes.func,
  updateTile: PropTypes.func,
  setError: PropTypes.func,
  newTile: PropTypes.object.isRequired,
  isFinished: PropTypes.bool,
};

TileTitle.defaultProps = {
  pageTitleKey: 'title.header',
  tile: defaultTile,
  goToNext: () => {},
  goToPrev: null,
  updateTile: null,
  setError: null,
  isFinished: false,
};

export default TileTitle;
