import { Form } from 'formik';
import { object, func, objectOf, string, bool } from 'prop-types';
import { withStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import AppPermissionsInputs from '../../AppPermissionsInputs/AppPermissionsInputs';

import styles from './styles';

// TODO: Remove with ChannelManagement FF cleanup
export function AppPermissionsForm({
  classes,
  values,
  errors,
  maxLengths,
  setFieldValue,
  touched,
  handleBlur,
  handleSubmit,
  goToPrev,
  loading,
  isFinished,
  handleReset,
  dirty,
}) {
  return (
    <Form>
      <Grid container>
        <AppPermissionsInputs
          values={values}
          errors={errors}
          maxLengths={maxLengths}
          setFieldValue={setFieldValue}
          touched={touched}
          handleBlur={handleBlur}
        />
        <Grid item xs={12} sm={12} md={12}>
          {!loading && goToPrev && (
            <WizardButtons
              isFinished={isFinished}
              dirty={dirty}
              goToNext={handleSubmit}
              goToPrev={goToPrev}
              undo={handleReset}
            />
          )}
          {loading && goToPrev && (
            <CircularProgress color="primary" className={classes.spinner} />
          )}
        </Grid>
      </Grid>
    </Form>
  );
}

AppPermissionsForm.propTypes = {
  classes: objectOf(string).isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  maxLengths: object.isRequired,
  setFieldValue: func.isRequired,
  touched: object.isRequired,
  handleBlur: func.isRequired,
  handleSubmit: func.isRequired,
  goToPrev: func.isRequired,
  loading: bool.isRequired,
  isFinished: bool.isRequired,
  handleReset: func.isRequired,
  dirty: bool.isRequired,
};

export default withStyles(styles)(AppPermissionsForm);
