// ensures all values
export const isNotNullOrEmptyOrUndefined = (stringValue) => {
  return (
    stringValue !== null &&
    stringValue?.trim() !== '' &&
    typeof stringValue !== 'undefined'
  );
};

// remove empty spaces and return lines
export const isValid = (stringValue) => {
  if (!isNotNullOrEmptyOrUndefined(stringValue)) {
    return false;
  }

  const result = isNotNullOrEmptyOrUndefined(
    stringValue.replace(/[\n]/, '').trim(),
  );
  return result;
};
