import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGetOrgSettings } from '../../../utils/hooks';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

function OrgWrapper(Component, pageTitleKey) {
  function Wrapped({ organizationId, features, ...rest }) {
    const { settings } = useGetOrgSettings(organizationId);
    const { t } = useTranslation();
    usePageTitle(t(pageTitleKey));
    return (
      settings && (
        <Component settings={settings} features={features} {...rest} />
      )
    );
  }

  Wrapped.propTypes = {
    organizationId: PropTypes.string.isRequired,
    features: PropTypes.objectOf(PropTypes.bool),
  };

  Wrapped.defaultProps = {
    features: {},
  };

  return Wrapped;
}

export function mapStateToProps(state) {
  return {
    organizationId: state.organizations.currentOrganization.id,
    features: state.features?.features,
  };
}

const withOrg = compose(connect(mapStateToProps), OrgWrapper);

export default withOrg;
