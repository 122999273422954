import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Modal from '../../Modal';
import FormHelperTextBottom from '../../FormHelperTextBottom';
import OutlinedTextField from '../../OutlinedTextField';
import PrimaryButton from '../../PrimaryButton';
import styles from './styles';
import { getCountText } from '../../../../utils';
import OutlinedSelect from '../../OutlinedSelect';
import { getBankDisplayText } from '../../../../store/banks/helpers';

function NewFundForm({
  values,
  setFieldValue,
  handleSubmit,
  errors,
  touched,
  dirty,
  maxLengths,
  handleBlur,
  onClose,
  classes,
  loadingCreate,
  banks,
  backendErrors,
}) {
  const { t } = useTranslation();
  const { name, accountCode, bankAccount } = values;
  const getNameError = () => {
    if (touched.name && errors.name) return errors.name;
    if (!dirty && backendErrors.name) return backendErrors.name;
    return '';
  };
  const getCodeError = () => {
    if (touched.accountCode && errors.accountCode) return errors.accountCode;
    if (!dirty && backendErrors.accountCode) return backendErrors.accountCode;
    return '';
  };

  return (
    <>
      <Modal.Content>
        <Grid container className={classes.grid}>
          <Grid item md={12} sm={12} xs={12}>
            <FormControl fullWidth>
              <Typography variant="body2">
                {t('fund.addFund.fundName.title')}
              </Typography>
              <OutlinedTextField
                id="name"
                name="name"
                data-testid="name"
                error={getNameError() !== ''}
                value={name}
                label={t('fund.addFund.fundName')}
                inputProps={{
                  maxLength: maxLengths.name,
                }}
                onBlur={handleBlur}
                onChange={setFieldValue}
                autoComplete="off"
                autoFocus
              />
              <FormHelperTextBottom
                left={getNameError()}
                right={getCountText(name.length, maxLengths.name)}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <FormControl fullWidth>
              <Typography variant="body2">
                {t('fund.addFund.fundCode.title')}
              </Typography>
              <Typography>{t('fund.addFund.fundCode.description')}</Typography>
              <OutlinedTextField
                id="accountCode"
                name="accountCode"
                data-testid="accountCode"
                error={getCodeError() !== ''}
                value={accountCode}
                label={t('fund.addFund.fundCode')}
                inputProps={{
                  maxLength: maxLengths.accountCode,
                }}
                onBlur={handleBlur}
                onChange={setFieldValue}
                autoComplete="off"
              />
              <FormHelperTextBottom
                left={getCodeError()}
                right={getCountText(accountCode.length, maxLengths.accountCode)}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <FormControl fullWidth>
              <Typography variant="body2">
                {t('fund.addFund.bankAccount.title')}
              </Typography>
              <Typography>
                {t('fund.addFund.bankAccount.description')}
              </Typography>
              <OutlinedSelect
                name="bankAccount"
                value={bankAccount}
                onChange={setFieldValue}
                label={t('fund.addFund.bankAccount')}
                id="bankAccount"
                error={!!errors.bankAccount}
              >
                <MenuItem value="">
                  <em>Select Bank Account</em>
                </MenuItem>
                {banks.map(bank => {
                  return (
                    <MenuItem key={bank.id} value={bank.id}>
                      {getBankDisplayText(bank)}
                    </MenuItem>
                  );
                })}
              </OutlinedSelect>
              <FormHelperTextBottom
                left={touched.bankAccount && errors.bankAccount}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        {!loadingCreate && (
          <Button
            onClick={onClose}
            size="large"
            color="primary"
            variant="outlined"
          >
            {t('button.cancel')}
          </Button>
        )}
        {loadingCreate && <CircularProgress color="primary" />}
        {!loadingCreate && (
          <PrimaryButton size="large" onClick={handleSubmit}>
            {t('fund.addFund.leftButton')}
          </PrimaryButton>
        )}
      </Modal.Actions>
    </>
  );
}

NewFundForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  maxLengths: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  loadingCreate: PropTypes.bool.isRequired,
  banks: PropTypes.arrayOf(PropTypes.object).isRequired,
  dirty: PropTypes.bool.isRequired,
  backendErrors: PropTypes.object.isRequired,
};

NewFundForm.defaultProps = {};

export default withStyles(styles)(NewFundForm);
