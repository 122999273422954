import { useQuery } from 'react-query';
import ids from '../api/idsAPI';

function useTenantInfo(tenantId, accessToken) {
  const { data, isLoading, error } = useQuery('tenant', () =>
    ids.getTenantInfo(tenantId, accessToken),
  );

  return [data?.data, error, isLoading];
}

export default useTenantInfo;
