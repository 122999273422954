import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useGetPage } from '../useGetPage/useGetPage';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';
import VancoMobileEventsService from '../../../api/VancoMobileEventsService';
import { handleException } from '../../../store/alerts/actions';
import backendToFrontend from '../../transforms/mobileEvent';

async function getMobileEvent({ queryKey }) {
  const [, id] = queryKey;
  const response = await VancoMobileEventsService.getEvent(id);
  return backendToFrontend(response.data.data);
}

export function useGetMobilePageEvent(eventId, config = {}) {
  const dispatch = useDispatch();
  const { page, isLoading } = useGetPage(CHANNEL_TYPE.MOBILE, PAGE_ACTIVE);
  const { data: event, status, isLoading: eventIsLoading, ...rest } = useQuery({
    queryKey: ['mobile-event', eventId],
    queryFn: getMobileEvent,
    enabled: !!page.id,
    onError: error => {
      dispatch(handleException(error));
    },
    ...config,
  });

  return {
    ...rest,
    event,
    isLoading: isLoading || eventIsLoading,
    status,
  };
}

export default useGetMobilePageEvent;
