import { useEffect, useState } from 'react';
import { Button, Paper, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import styles from './styles';
import { Page } from '../../../common';
import { ReactComponent as KioskIcon } from '../../../../assets/kioskSectionIcon.svg';
import kioskPromo from './kiosk-promo.png';

const KioskSteps = {
  INFO: 'info',
  REQUEST: 'request',
  SUCCESS: 'success',
};

function RequestKiosk({
  classes,
  wasKioskRequested,
  loadingKioskRequest,
  requestKiosk,
}) {
  const { t } = useTranslation();
  const [step, setStep] = useState(
    wasKioskRequested ? KioskSteps.SUCCESS : KioskSteps.INFO,
  );
  const showButton = step !== KioskSteps.SUCCESS;
  const boldSubtitle = clsx(step === KioskSteps.SUCCESS && classes.bold);

  useEffect(() => {
    if (wasKioskRequested) {
      setStep(KioskSteps.SUCCESS);
    }
  }, [wasKioskRequested]);

  function sendKioskRequest() {
    requestKiosk();
  }

  const onButtonClick = () => {
    if (step === KioskSteps.INFO) {
      setStep(KioskSteps.REQUEST);
    } else if (step === KioskSteps.REQUEST) {
      sendKioskRequest();
    }
  };

  return (
    <Page>
      <Paper>
        <div className={classes.container}>
          <span className={classes.requestHeader}>
            <KioskIcon className={classes.kioskIcon} />
            <Typography className={classes.headerText}>
              {t(`kiosk.${step}.title`)}
            </Typography>
          </span>
          <Typography className={boldSubtitle}>
            {t(`kiosk.${step}.subtitle1`)}
          </Typography>
          <Typography>{t(`kiosk.${step}.subtitle2`)}</Typography>
          <img
            src={kioskPromo}
            alt="KioskPromotion"
            className={classes.promoImage}
          />
          <span>
            {showButton && (
              <Button
                className={classes.kioskButton}
                variant="contained"
                color="primary"
                onClick={onButtonClick}
                disabled={loadingKioskRequest}
              >
                {t(`kiosk.${step}.button`)}
              </Button>
            )}
          </span>
        </div>
      </Paper>
    </Page>
  );
}

RequestKiosk.propTypes = {
  classes: PropTypes.object.isRequired,
  wasKioskRequested: PropTypes.bool.isRequired,
  loadingKioskRequest: PropTypes.bool.isRequired,
  requestKiosk: PropTypes.func.isRequired,
};

export default withStyles(styles)(RequestKiosk);
