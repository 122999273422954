import { connect } from 'react-redux';

import TileDescription from './TileDescription';
import { saveTile } from '../../../store/tiles/actions';

export function mapStateToProps(state) {
  return {
    loadingTile: state.tiles.loadingTile,
    loadingSave: state.tiles.loadingSave,
    newTile: state.tiles.newTile,
  };
}

export default connect(mapStateToProps, { saveTile })(TileDescription);
