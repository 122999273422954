export default () => ({
  firstSubtitle: {
    marginTop: 22,
  },
  subtitle2: {
    fontSize: 16,
    marginTop: 32,
  },
  subtitle3: {
    fontSize: 16,
    marginTop: 16,
  },
  subtitle4: {
    marginTop: 32,
  },
  largeSubtitle: {
    fontSize: 24,
    marginTop: 8,
  },
  description: {
    marginTop: 8,
  },
  descriptionGray: {
    color: '#606368',
    marginTop: 8,
  },
  description2: {
    marginTop: 4,
  },
  checkMargin: {
    marginTop: 4,
  },
  checkMargin2: {
    marginTop: -4,
  },
  divider: {
    marginTop: 44,
    marginBottom: 44,
  },
  groupName: {
    marginTop: 24,
  },
  spinner: {
    marginTop: 40,
  },
  checkColor: {
    color: '#9E9E9E',
  },
});
