export default function styles(theme) {
  return {
    container: {
      color: theme.palette.black,
      padding: '2px 8px 2px 8px',
      borderRadius: '15px',
    },
    complete: {
      backgroundColor: '#B2E9C8',
    },
    failed: {
      backgroundColor: '#FBD3D9',
    },
    incomplete: {
      backgroundColor: '#D5DBFB',
    },
    completeWithErrors: {
      backgroundColor: '#FBD4CC',
    },
  };
}
