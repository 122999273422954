import axios from 'axios';
import UnsplashService from '../../api/UnsplashService';
import { constants } from '../../globals';

export const sortTilesByOrdinal = tiles => {
  return tiles.sort((a, b) => {
    // When we return 1, the function communicates to sort() that the object b takes
    // precedence in sorting over the object a. Returning -1 would do the opposite.
    if ('ordinal' in a && 'ordinal' in b) return a.ordinal < b.ordinal ? 1 : -1;
    if ('ordinal' in a) return -1;
    if ('ordinal' in b) return 1;
    return 1;
  });
};

export const orderTiles = (tiles, movedTiles) => {
  if (!tiles && movedTiles) return movedTiles;
  if (!movedTiles && tiles) return tiles;
  if (!movedTiles && !tiles) return [];
  // Tiles are displayed with high ordinals at the top and low ones at the bottom
  // so the first item in the returned array will have the highest ordinal
  return movedTiles.map((movedTile, index) => {
    const tile = tiles.find(t => movedTile.key === t.id) ?? movedTile;
    const ordinal = movedTiles.length - 1 - index;
    return { ...tile, ordinal };
  });
};

export const sortTilesByUpdatedAt = tiles => {
  return tiles.sort((a, b) => {
    if (a.lastUpdatedAt > b.lastUpdatedAt) return -1;
    return 1;
  });
};

export const sortTilesByArchivedDate = tiles => {
  return tiles.sort((a, b) => {
    if (a.archivedDate > b.archivedDate) return -1;
    return 1;
  });
};

export const getCroppedImageBase64 = (image, isLogo = false) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    const originalWidth = 1600; // The width to grab from Unsplash before cropping
    const quality = 80;
    const format = 'jpg';
    const croppedWidth = isLogo ? 256 : 800;
    const croppedHeight = isLogo ? 256 : croppedWidth * 0.61630695443645;
    const canvas = document.createElement('canvas');
    const { crop, isUnsplashImage } = image;
    const imageUrl = isUnsplashImage
      ? `${image.rawImage}?fm=${format}&w=${originalWidth}&fit=max&q=${quality}`
      : image.src;

    // How to avoid IE security error using canvas.toDataURL():
    // https://developpaper.com/explain-how-to-solve-the-cross-domain-problem-of-canvas-image-getimagedata-todataurl/
    const { data } = await axios.get(imageUrl, { responseType: 'blob' });
    const url = URL.createObjectURL(data);
    const img = new Image();
    img.src = url;
    /* eslint-disable-next-line func-names */
    img.onload = function() {
      canvas.width = croppedWidth;
      canvas.height = croppedHeight;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        img,
        (crop.x / 100) * img.naturalWidth,
        (crop.y / 100) * img.naturalHeight,
        (crop.width / 100) * img.naturalWidth,
        (crop.height / 100) * img.naturalHeight,
        0,
        0,
        croppedWidth,
        croppedHeight,
      );

      URL.revokeObjectURL(url);

      if (isUnsplashImage) {
        // Hit Unsplash download endpoint (for telemetry only, no actual download)
        UnsplashService.trackDownloadImage({
          downloadLocation: image.downloadLocation,
        });
      }

      // The endpoint expects the type to be omitted, so we remove it from
      // the Base64 string
      resolve(canvas.toDataURL('image/jpeg').split(',')[1]);
    };
  });
};

export const textDescriptionToDelta = description => {
  return description.split('\n').map(t => {
    return { insert: `${t}${String.fromCharCode(10)}` };
  });
};

export const parseDescription = description => {
  if (typeof description === 'string') {
    try {
      const content = JSON.parse(description);
      return typeof content === 'object'
        ? content
        : textDescriptionToDelta(content);
    } catch {
      return textDescriptionToDelta(description);
    }
  } else {
    return description;
  }
};

export const isActive = tile => tile.status === constants.TILE_ACTIVE;

export const isPending = tile => tile.status === constants.TILE_PENDING;

export const isHidden = tile =>
  tile.status !== constants.TILE_PENDING && tile.locations.length < 1;

export const isVisible = tile =>
  tile.status === constants.TILE_ACTIVE && tile.locations.length > 0;

export const isArchived = tile => tile.status === constants.TILE_ARCHIVED;

export const hasVancoOnlineChannels = tile =>
  tile.locations &&
  tile.locations.some(l => l.channelId === constants.CHANNEL_TYPE.ONLINE);

export const hasVancoMobileChannels = tile =>
  tile.locations &&
  tile.locations.some(l => l.channelId === constants.CHANNEL_TYPE.MOBILE);

export const getSiteNames = (tileLocations, pages, channelId) => {
  const locationIds = tileLocations
    .filter(l => l.channelId === channelId)
    .map(l => l.id);
  return pages
    .filter(p => locationIds.some(locId => p.id === locId))
    .map(p => p.pageName);
};

export const filterTilesByStatus = (tiles, status) => {
  if (!tiles || tiles.length < 1) {
    return [];
  }

  switch (status) {
    case constants.TILE_ACTIVE:
      return tiles.filter(isActive);
    case constants.TILE_PENDING:
      return tiles.filter(isPending);
    case constants.TILE_HIDDEN:
      return tiles.filter(isHidden);
    case constants.TILE_VISIBLE:
      return tiles.filter(isVisible);
    case constants.TILE_ARCHIVED:
      return tiles.filter(isArchived);
    case constants.TILE_ALL:
    default:
      return tiles;
  }
};

export const getLocations = (tile, locations) => {
  if (
    (!tile.locationIds || tile.locationIds.length < 1) &&
    locations.length < 1
  ) {
    return [];
  }

  return tile.locationIds.reduce((locationsInfo, location) => {
    const locationInfo = locations.find(l => l.id === location);
    if (locationInfo) {
      locationsInfo.push({
        id: locationInfo.id,
        channelId: locationInfo.channelId,
      });
    }
    return locationsInfo;
  }, []);
};

export const getTilesToOrder = (tiles = [], page = {}) => {
  return tiles
    .filter(
      t =>
        t.locations.length > 0 &&
        t.locations.some(tile => tile.id === page.id) &&
        t.status === constants.TILE_ACTIVE,
    )
    .sort((a, b) => b.ordinal - a.ordinal);
};

export function getChannelName(channelId) {
  switch (channelId) {
    case constants.CHANNEL_TYPE.ONLINE:
      return 'Vanco Online';
    case constants.CHANNEL_TYPE.MOBILE:
      return 'Vanco Mobile';
    default:
      return 'UNKNOWN CHANNEL';
  }
}
