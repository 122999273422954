/* eslint-disable class-methods-use-this */
import JSCookies from 'js-cookie';
import psl from 'psl';

import {
  SESSION_COOKIE_ID,
  CSRF_COOKIE_ID,
  USER_COOKIE_ID,
  FEATURES_COOKIE_ID,
} from './types';

class Cookies {
  getDomain() {
    return psl.get(window.location.hostname) ?? '';
  }

  getSessionCookie() {
    return JSON.parse(JSCookies.get(SESSION_COOKIE_ID) || null) || null;
  }

  setSessionCookie(session) {
    const domain = this.getDomain();
    JSCookies.set(SESSION_COOKIE_ID, JSON.stringify(session), { domain });
  }

  deleteSessionCookie() {
    const domain = this.getDomain();
    JSCookies.remove(SESSION_COOKIE_ID, { path: '/', domain });
  }

  getCSRFToken() {
    return JSCookies.get(CSRF_COOKIE_ID) || null;
  }

  setCSRFToken(token) {
    JSCookies.set(CSRF_COOKIE_ID, token);
  }

  deleteCSRFToken() {
    JSCookies.remove(CSRF_COOKIE_ID);
  }

  getUserCookie() {
    return JSON.parse(JSCookies.get(USER_COOKIE_ID) || null) || null;
  }

  setUserCookie(user) {
    const domain = this.getDomain();
    JSCookies.set(USER_COOKIE_ID, JSON.stringify(user), { domain });
  }

  deleteUserCookie() {
    const domain = this.getDomain();
    JSCookies.remove(USER_COOKIE_ID, { path: '/', domain });
  }

  getFeaturesCookie() {
    return JSON.parse(JSCookies.get(FEATURES_COOKIE_ID) || null);
  }

  setFeaturesCookie(features) {
    JSCookies.set(FEATURES_COOKIE_ID, JSON.stringify(features));
  }

  deleteFeaturesCookie() {
    JSCookies.remove(FEATURES_COOKIE_ID);
  }

  deleteCookies() {
    this.deleteCSRFToken();
    this.deleteSessionCookie();
    this.deleteUserCookie();
    this.deleteFeaturesCookie();
  }
}

export default new Cookies();
