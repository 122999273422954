import PropTypes, { string } from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './styles';

import { PAYMENT_METHOD } from '../../../../globals/constants';
import getCardSvgSource from './helpers/cardBrandHelpers';

function CardIcon({ cardName, svgSource, classes }) {
  return (
    <img
      className={classes.cardIcon}
      id={`card-${cardName}`}
      src={svgSource}
      alt={`svg-${cardName}`}
    />
  );
}

CardIcon.defaultProps = {
  classes: {},
};

CardIcon.propTypes = {
  cardName: PropTypes.oneOf([
    PAYMENT_METHOD.CARD_TYPE_AMEX,
    PAYMENT_METHOD.CARD_TYPE_MASTERCARD,
    PAYMENT_METHOD.CARD_TYPE_VISA,
    PAYMENT_METHOD.CARD_TYPE_DISCOVER,
  ]).isRequired,
  svgSource: PropTypes.string.isRequired,
  classes: PropTypes.object,
};

export function CardBrands({ classes, allowedCardBrands }) {
  return (
    <div className={classes.formItemGroup}>
      {allowedCardBrands.map((card) => {
        const cardName = card.toLowerCase();
        return (
          <CardIcon
            classes={classes}
            key={cardName}
            cardName={cardName}
            svgSource={getCardSvgSource(cardName)}
          />
        );
      })}
    </div>
  );
}

CardBrands.propTypes = {
  classes: PropTypes.object.isRequired,
  allowedCardBrands: PropTypes.arrayOf(string).isRequired,
};

export default withStyles(styles)(CardBrands);
