import { Typography, withStyles } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { objectOf, string } from 'prop-types';

const styles = () => {
  return {
    root: {
      padding: '19px 23px',
      backgroundColor: '#FBF3F5',
      border: '1px solid #C82947',
      borderRadius: 4,
      marginTop: 16,
    },
    errorIcon: {
      color: '#C82947',
      marginRight: 16,
    },
    errorText: {
      display: 'flex',
    },
  };
};

function InlineErrorMessage({ classes, errorMessage }) {
  return (
    <div className={classes.root}>
      <Typography className={classes.errorText} data-testid="errorMessage">
        <ErrorOutline className={classes.errorIcon} />
        {errorMessage}
      </Typography>
    </div>
  );
}

InlineErrorMessage.propTypes = {
  classes: objectOf(string).isRequired,
  errorMessage: string.isRequired,
};

export default withStyles(styles)(InlineErrorMessage);
