import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
  },
  notFound: {
    fontSize: '2.8rem',
  },
}));
