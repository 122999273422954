import PropTypes from 'prop-types';
import { withStyles, Typography, Divider } from '@material-ui/core';
import { Field } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
// import Dropdown from '../../common/Dropdown';
import { getCurrencyFormat } from '../../../../utils/basket';
// import { MAX_PAYMENT_ITEMS } from '../../../../globals/newTransaction';
import TextFieldWithCharCount from '../../TextFieldWithCharCount/TextFieldWithCharCount';
import MemoFieldsDropdown from './MemoFieldsDropdown';

const stylesOverrides = () => ({
  price: {
    color: '#222222',
    fontSize: 18,
    marginBottom: 40,
  },
  quantity: {
    color: '#222222',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  inputField: {
    height: 60,
    width: 76,
    marginBottom: 12,
    background: '#FFFFFF',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  memoInputField: {
    margin: '10px 0px 12px 0px',
    width: '100%',
    background: '#FFFFFF',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  dividerStyleFix: {
    margin: 0,
    marginBottom: '12px',
  },
  dateTitle: {
    color: '#212224',
    fontSize: '16px',
    lineHeight: '24px',
  },
  inputHelperText: {
    margin: '3px 12px 0',
    maxWidth: '100%',
  },
});

// const options = Array.from(Array(MAX_PAYMENT_ITEMS).keys()).map((item) => ({
//   value: item + 1,
//   text: (item + 1).toString(),
// }));

export function PaymentFixedAmount({
  classes,
  details,
  // values,
  // setFieldValue,
  features,
}) {
  const { t } = useTranslation();
  // const onQuantityChange = (e) => {
  //   setFieldValue(`${details.fundId}`, {
  //     ...values[details.fundId],
  //     quantity: e.target.value,
  //     amount: (
  //       parseFloat(values[details.fundId].itemAmount) * e.target.value
  //     ).toString(),
  //   });
  // };

  const getLabelText = () => {
    let formattedText = details.memoLine.textField;
    formattedText += details.memoLine.responseRequired
      ? ` (${t('tile.responseRequired')})`
      : ` (${t('tile.responseOptional')})`;
    return formattedText;
  };

  const showMemoFieldsDropDown =
    features?.TileMemoDropdown &&
    !details.isTextFieldResponseType &&
    details.memoFields?.length > 0;

  const orderedMemoFields = details.memoFields?.sort((a, b) =>
    a.ordinal > b.ordinal ? 1 : -1,
  );

  return (
    <>
      <Typography className={classes.price}>
        {getCurrencyFormat(details.price)}
      </Typography>
      <Typography className={classes.quantity}>{t('tile.quantity')}</Typography>
      {/* <Field
        id="donation-quantity"
        data-testid="donation-quantity"
        name={`${details.fundId}.quantity`}
        component={Dropdown}
        options={options}
        onChange={onQuantityChange}
        className={classes.inputField}
      /> */}

      {false && details.memoLine.showTextField && !showMemoFieldsDropDown && (
        <>
          <Divider variant="middle" className={classes.dividerStyleFix} />

          <Typography className={classes.dateTitle} variant="caption">
            {getLabelText()}
          </Typography>
          <Field
            id="donation-memoline"
            name={`${details.fundId}.memoLine`}
            variant="outlined"
            autoComplete="off"
            maxLength={50}
            maxRows={2}
            multiline
            className={classes.memoInputField}
            helperTextClass={classes.inputHelperText}
            component={TextFieldWithCharCount}
          />
        </>
      )}
      {false && details.memoLine.showTextField && showMemoFieldsDropDown && (
        <>
          <Divider variant="middle" className={classes.dividerStyleFix} />
          <Field
            id="memo-fields"
            textFieldLabel={`${t('accountPayment.select')} ${getLabelText()}`}
            name={`${details.fundId}.memoLine`}
            component={MemoFieldsDropdown}
            maxLength={50}
            memoFields={orderedMemoFields}
            className={classes.memoInputField}
            helperTextClass={classes.inputHelperText}
          />
        </>
      )}
    </>
  );
}

PaymentFixedAmount.propTypes = {
  classes: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  // values: shape({}).isRequired,
  // setFieldValue: func.isRequired,
  features: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  features: state.features?.features,
});

export default compose(
  withStyles(stylesOverrides),
  connect(mapStateToProps),
)(PaymentFixedAmount);
