import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import Cookies from '../../../../utils/Cookies';
import styles from './styles';
import Modal from '../../../common/Modal';
import { PrimaryButton } from '../../../common';

export function TransferOwnershipForm({
  classes,
  onClose,
  user,
  onSave,
  isLoading,
}) {
  const { t } = useTranslation();

  const { wasInternal } = Cookies.getUserCookie();
  const notification = wasInternal
    ? 'users.transferOwnership.noticeInternal'
    : 'users.transferOwnership.notice';

  return (
    <>
      <Modal.Content>
        <div className={classes.container}>
          <FormControl fullWidth>
            <Grid container direction="row" className={classes.infoContainer}>
              <Grid item xs={1}>
                <InfoIcon className={classes.infoIcon} />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body2" className={classes.infoNotice}>
                  {t(notification, {
                    userName: user.userName,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </FormControl>
        </div>
      </Modal.Content>
      <Modal.Actions>
        {isLoading ? (
          <CircularProgress data-testid="transfer-ownsershipt-form-spinner" />
        ) : (
          <>
            <Button
              onClick={onClose}
              size="large"
              color="primary"
              variant="outlined"
              disabled={isLoading}
            >
              {t('button.cancel')}
            </Button>
            <PrimaryButton
              size="large"
              data-testid="transfer-ownership"
              onClick={onSave}
            >
              {t('users.transferOwnership.submit')}
            </PrimaryButton>
          </>
        )}
      </Modal.Actions>
    </>
  );
}

TransferOwnershipForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

TransferOwnershipForm.defaultProps = {
  isLoading: false,
};

export default withStyles(styles)(TransferOwnershipForm);
