import { NAVBAR_HEIGHT } from '../../../globals/dimensions';

export default function styles(theme) {
  return {
    root: {
      overflow: 'auto',
      flexShrink: 0,
    },
    fixed: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      '&$right': {
        right: 0,
      },
      '&$top': {
        left: 0,
        right: 0,
        bottom: 'auto',
      },
      '&$bottom': {
        left: 0,
        right: 0,
        top: 'auto',
        bottom: 0,
      },
      '&$belowNav': {
        top: NAVBAR_HEIGHT,
      },
    },
    belowNav: {
      zIndex: theme.zIndex.mobileStepper,
    },
    elevated: {
      boxShadow: '5px 0 10px 0 rgba(0, 0, 0, 0.15)',
      '&$right': {
        boxShadow: '-5px 0 10px 0 rgba(0, 0, 0, 0.15)',
      },
      '&$top': {
        boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.15)',
      },
      '&$bottom': {
        boxShadow: '0 -5px 10px 0 rgba(0, 0, 0, 0.15)',
      },
    },
    overlay: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.overlay,
      zIndex: theme.zIndex.mobileStepper - 1,
      cursor: 'pointer',
    },
    animated: {
      transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      // Account for drop-shadow
      transform: 'translateX(-105%)',
      '&$right': {
        transform: 'translateX(105%)',
      },
      '&$top': {
        transform: 'translateY(-105%)',
      },
      '&$bottom': {
        transform: 'translateY(105%)',
      },
      '&$open': {
        transform: 'translateX(0) translateY(0)',
      },
    },
    permanent: {
      // If this is a permanent drawer, other drawers should have their
      // overlay appear over it
      zIndex: theme.zIndex.mobileStepper - 2,
    },
    open: {},
    right: {},
    top: {},
    bottom: {},
  };
}
