import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import FormHelperTextBottom from '../../FormHelperTextBottom';
import OutlinedSelect from '../../OutlinedSelect';
import styles from './styles';
import { getBankDisplayText } from '../../../../store/banks/helpers';
import CreateButton from '../../CreateButton';

function FundForm({
  values,
  setFieldValue,
  errors,
  handleNewFund,
  funds,
  loading,
  banksError,
  fundsError,
  newFund,
  labelKey,
  handleBlur,
}) {
  const { t } = useTranslation();
  const { fund } = values;
  const handleFundSelect = (e) => {
    const { name } = e.target;
    const fundRefId = e.target.value;
    const selectedFund = funds.find((f) => f.fundRefId === fundRefId);
    setFieldValue({ target: { name, value: selectedFund || null } });
  };

  useEffect(() => {
    if (newFund && newFund.fundRefId !== fund?.fundRefId) {
      setFieldValue({ target: { name: 'fund', value: newFund } });
    }
  }, [newFund, setFieldValue, fund]);

  return (
    <>
      <Grid container>
        <Grid item md={9} sm={12} xs={12}>
          {!loading && !fundsError && (
            <>
              <OutlinedSelect
                name="fund"
                value={fund ? fund.fundRefId : ''}
                onChange={handleFundSelect}
                label={t(labelKey)}
                id="fund"
                error={!!errors.fund}
                onBlur={handleBlur}
              >
                <MenuItem value="">
                  <em>Select Fund</em>
                </MenuItem>
                {funds.map((f) => {
                  return (
                    <MenuItem key={f.fundRefId} value={f.fundRefId}>
                      {f.bank && `${f.name} (${getBankDisplayText(f.bank)} )`}
                      {!f.bank && `${f.name}`}
                    </MenuItem>
                  );
                })}
              </OutlinedSelect>
              {errors.fund && <FormHelperTextBottom left={errors.fund} />}
              {!banksError && (
                <CreateButton
                  onClick={handleNewFund}
                  color="primary"
                  data-testid="add-fund-button"
                >
                  {t('fund.button.addFund')}
                </CreateButton>
              )}
              {banksError && <FormHelperTextBottom left={t('errors.banks')} />}
            </>
          )}
          {loading && !fundsError && <CircularProgress />}
          {fundsError && <FormHelperTextBottom left={t('errors.funds')} />}
        </Grid>
        <Hidden smDown>
          <Grid item md={4}>
            <FormHelperText />
          </Grid>
        </Hidden>
      </Grid>
      <br />
    </>
  );
}

FundForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleNewFund: PropTypes.func.isRequired,
  funds: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  fundsError: PropTypes.bool.isRequired,
  banksError: PropTypes.bool.isRequired,
  newFund: PropTypes.object,
  labelKey: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
};

FundForm.defaultProps = {
  newFund: null,
  labelKey: 'fund.selectFund',
};

export default withStyles(styles)(FundForm);
