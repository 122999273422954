export default function styles() {
  return {
    hide: {
      visibility: 'hidden',
      height: '100%',
    },
    visible: {
      visibility: 'visible',
      height: '100%',
    },
    spinnerContainer: {
      marginTop: 0,
      textAlign: 'center',
      background: 'black',
    },
    spinner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
    },
    iframeDiv: {
      margin: '0px !importat',
      padding: 0,
      width: '100%',
      height: '97%',
      overflow: 'hidden',
    },
    containerDiv: {
      height: '100%',
    },
    iframeStyle: {
      marginTop: '0px !important ',
      width: '100%',
      height: '95% !important',
      // Firefox
      scrollbarWidth: 'none',
      // IE
      msOverflowStyle: 'none',
      // Chrome / Safari
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      overflow: 'hidden',
      scrolling: 'no',
      border: 'none',
    },
  };
}
