export default function styles(theme) {
  return {
    content: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: 220,
      justifyContent: 'center',
      width: 330,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    message: {
      marginTop: 10,
    },
  };
}
