import {
  useRef,
  useState,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from 'react';
import { node, oneOf, objectOf, string, func, bool } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MaterialDivider from '@material-ui/core/Divider';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import styles from './styles';

export const ButtonMenuContext = createContext({});
export const useButtonMenuContext = () => useContext(ButtonMenuContext);

function Divider() {
  const { classes } = useButtonMenuContext();
  return <MaterialDivider className={classes.divider} />;
}

function Item({ onClick, children, disabled }) {
  const { classes, closeMenu } = useButtonMenuContext();

  const onClickEvent = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
    closeMenu();
  };

  return (
    <button
      className={classes.item}
      type="button"
      role="menuitem"
      tabIndex="0"
      onClick={onClickEvent}
      disabled={disabled}
      style={{
        backgroundColor: disabled ? 'lightGray' : 'none',
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
    >
      {children}
    </button>
  );
}

export function ButtonMenu({
  classes,
  color,
  buttonContent,
  menuPlacement,
  children,
  disabled,
}) {
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleClickAway = () => {
    setOpen(false);
  };

  const closeMenu = useCallback(() => setOpen(false), [setOpen]);

  const togglePopper = () => setOpen((oldOpen) => !oldOpen);
  const contextProviderProps = useMemo(
    () => ({ classes, closeMenu }),
    [classes, closeMenu],
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ display: 'inline' }}>
        <ButtonMenuContext.Provider value={contextProviderProps}>
          <Popper
            open={open}
            anchorEl={buttonRef.current}
            placement={menuPlacement}
            transition
            role="menu"
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} timeout={350}>
                <Paper className={classes.paper}>{children}</Paper>
              </Grow>
            )}
          </Popper>
        </ButtonMenuContext.Provider>
        <Button
          className={classes.button}
          variant="contained"
          color={color}
          ref={buttonRef}
          onClick={togglePopper}
          endIcon={<ArrowDropDownIcon />}
          disabled={disabled}
        >
          {buttonContent}
        </Button>
      </div>
    </ClickAwayListener>
  );
}

ButtonMenu.propTypes = {
  classes: objectOf(string).isRequired,
  buttonContent: node,
  color: string,
  menuPlacement: oneOf([
    'top-start',
    'top',
    'top-end',
    'left-start',
    'left',
    'left-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end',
  ]),
  children: node,
  disabled: bool,
};

ButtonMenu.defaultProps = {
  color: 'primary',
  buttonContent: null,
  menuPlacement: 'bottom-end',
  children: null,
  disabled: false,
};

Item.propTypes = {
  onClick: func,
  children: node,
  disabled: bool,
};

Item.defaultProps = {
  onClick: null,
  children: null,
  disabled: false,
};

ButtonMenu.Item = Item;

ButtonMenu.Divider = Divider;

export default withStyles(styles)(ButtonMenu);
