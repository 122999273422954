import i18n from '../i18n';

const MAX_IMAGE_SIZE = 100;
const IE_MAX_IMAGE_SIZE = 15;

export function getBlobFromBase64(b64, contentType) {
  const byteCharacters = window.atob(b64);
  const byteArray = Uint8Array.from(byteCharacters, (c) => c.charCodeAt(0));
  return new Blob([byteArray], { type: contentType });
}

export function getMimeType(b64) {
  // list of signatures https://en.wikipedia.org/wiki/List_of_file_signatures
  // hex to base64 tool to convert signatures https://base64.guru/converter/encode/hex
  const signatures = Object.entries({
    '/9j/': 'image/jpeg',
    R0lGODdh: 'image/gif',
    R0lGODlh: 'image/gif',
    iVBORw0KGgo: 'image/png',
  });

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of signatures) {
    if (b64.indexOf(key) === 0) {
      return value;
    }
  }

  return null;
}

export function validateImageSize(image) {
  const ua = window.navigator.userAgent;
  const isIE = /MSIE|Trident|Edge\//.test(ua);
  const size = image.size / 1024 / 1024;
  const maxSize = isIE ? IE_MAX_IMAGE_SIZE : MAX_IMAGE_SIZE;
  const isValid = size < maxSize;

  return {
    isValid,
    errorMessage: isValid ? null : i18n.t('upload.image.error', { maxSize }),
  };
}

export function getBase64FromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (r) => resolve(r.target.result);
    reader.onerror = (e) => reject(e);
    reader.readAsDataURL(file);
  });
}

export async function getImgFromFile(file) {
  const base64Image = await getBase64FromFile(file);
  const [, encodedImage] = base64Image.split(',');
  const contentType = getMimeType(encodedImage);

  if (!contentType) {
    return null;
  }
  const blob = getBlobFromBase64(encodedImage, contentType);
  const imageUrl = URL.createObjectURL(blob);
  return {
    src: imageUrl,
    cropImage: imageUrl,
    isUnsplashImage: false,
  };
}

export function downloadBase64File(base64Data, fileName) {
  const downloadLink = document.createElement('a');
  downloadLink.href = base64Data;
  downloadLink.download = fileName;
  downloadLink.click();
}
