import { formInputText } from '../../../globals/typography';

export default function styles(theme) {
  return {
    dialog: {
      maxWidth: 1000,
      position: 'relative',
    },
    title: {
      padding: '57px 48px 20px 48px',
    },
    content: {
      padding: '0 48px',
    },
    label: {
      marginTop: 80,
      fontWeight: 'bold',
    },
    textField: {
      width: '50%',
    },
    actions: {
      padding: 48,
      margin: 0,
      justifyContent: 'flex-start',
    },
    close: {
      display: 'flex',
      position: 'absolute',
      top: 20,
      right: 20,
    },
    closeText: {
      position: 'relative',
      display: 'inline-block',
      color: theme.palette.secondary.light,
      marginLeft: 3,
      bottom: 1,
      ...formInputText,
    },
    closeIcon: {
      width: 20,
      height: 20,
      color: theme.palette.secondary.light,
    },
  };
}
