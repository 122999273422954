export default function styles(theme) {
  const common = {
    marginLeft: `-${theme.spacing(3)}px`,
    marginRight: `-${theme.spacing(3)}px`,
  };
  const commonWide = {
    ...common,
    width: 'calc(100% + 48px)',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% + 84px)',
      marginLeft: -42,
      marginRight: -42,
    },
  };
  return {
    divider: {
      ...common,
      width: 600,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 0,
      },
    },
    dividerEditor: {
      ...common,
      width: 600,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 0,
      },
    },
    dividerWide: {
      ...commonWide,
    },
    dividerTitle: {
      ...commonWide,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    dividerWideEditor: {
      ...commonWide,
      marginLeft: `-${theme.spacing(5)}px`,
      width: 'calc(100% + 80px)',
    },
    dividerStreamSite: {
      marginTop: theme.spacing(3),
      width: '100%',
    },
  };
}
