import { SET_APP_LOADING } from './types';

const INITIAL_STATE = {
  /* When greater than 0 the app will show the AppProgressBar. 
     Each network requests increments this value when it starts
     and decrements it when its finished.
  */
  appIsLoading: 0,
};

export default (state = INITIAL_STATE, action = {}) => {
  const { appIsLoading } = state;

  switch (action.type) {
    case SET_APP_LOADING: {
      const newAppIsLoading = action.loading
        ? appIsLoading + 1
        : appIsLoading - 1;

      return {
        ...state,
        appIsLoading: Math.max(newAppIsLoading, 0),
      };
    }

    default:
      return state;
  }
};
