import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import PrimaryButton from '../../PrimaryButton';
import Form from '../../Form';
import Tile from '../../Tile';
import styles from './styles';
import ChannelVisibility from '../../ChannelVisibility';

function TileReviewForm({
  handleSubmit,
  values,
  errors,
  setFieldValue,
  goToPrev,
  goToNext,
  loading,
  tile,
  classes,
}) {
  const { locations, visibility } = values;
  const { t } = useTranslation();

  return (
    <>
      <Form>
        <section className={classes.content}>
          <Tile tile={tile} />
        </section>

        <section className={classes.visibility}>
          <Typography variant="h4">
            {t('tile.reviewVisibilityTitle')}
          </Typography>
          <Typography variant="body1">
            {t('tile.reviewVisibilityDescription')}
          </Typography>
        </section>
        <ChannelVisibility
          errors={errors}
          locations={locations}
          visibility={visibility}
          setFieldValue={setFieldValue}
          shouldSetDefaultLocations
          tileType={tile.type}
        />
      </Form>

      {goToNext && !loading && (
        <>
          <PrimaryButton size="large" onClick={handleSubmit}>
            {t('button.done')}
          </PrimaryButton>
          <Button size="large" color="primary" onClick={goToPrev}>
            {t('button.previousStep')}
          </Button>
        </>
      )}
      {loading && goToPrev && <CircularProgress color="primary" />}
    </>
  );
}

TileReviewForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  goToPrev: PropTypes.func,
  goToNext: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  tile: PropTypes.object.isRequired,
};

TileReviewForm.defaultProps = {
  goToPrev: null,
  goToNext: null,
};

export default withStyles(styles)(TileReviewForm);
