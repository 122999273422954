import { connect } from 'react-redux';
import VancoMobileEvents from './VancoMobileEvents';
import { setSuccess } from '../../../store/alerts/actions';

export const mapStateToProps = () => ({
  vmUrl: window.env.REACT_APP_MOBILE_URL,
});

export default connect(mapStateToProps, {
  setCopyNotification: setSuccess,
})(VancoMobileEvents);
