import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const createSchema = () => {
  return Yup.object().shape({
    tileId: Yup.string(),
    siteId: Yup.string().test('tileId', function testTileId() {
      const { tileId, siteId } = this.parent;
      return tileId || siteId
        ? true
        : this.createError({
            message: 'Select a campaign or a site.', // This error is not displayed, only used to disable Submit button
          });
    }),
  });
};
