import { connect } from 'react-redux';

import HomeRedirect from './HomeRedirect';

function mapStateToProps(state) {
  return {
    isInternal: state.user.user.isInternal,
  };
}

export default connect(mapStateToProps, {})(HomeRedirect);
