export default function styles(theme) {
  return {
    title: {
      marginBottom: 39,
    },
    paper: {
      padding: 24,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  };
}
