import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Disable } from 'react-disable';

import UnarchivePage from '../UnarchivePage';
import PageTitle from '../PageTitle';
import CustomFormik from '../CustomFormik';
import Form from './Form';
import { createSchema } from './validation';
import { isArchived } from '../../../utils/page';

export function FeeDonationOptions({
  pageTitleKey,
  goToNext,
  goToPrev,
  page,
  savePage,
  loadingSave,
  newPage,
  resetNewPage,
  updatePage,
  setError,
  isFinished,
  onUnarchivePage,
}) {
  const schema = createSchema();
  const { t } = useTranslation();
  const checkPercentage =
    typeof page.checkPercentage === 'number'
      ? page.checkPercentage.toFixed(2)
      : page.checkPercentage || '';
  const creditPercentage =
    typeof page.creditPercentage === 'number'
      ? page.creditPercentage.toFixed(2)
      : page.creditPercentage || '';
  const disabled = isArchived(page);

  useEffect(() => {
    if (goToNext && newPage.id) {
      goToNext();
      resetNewPage();
    }
  }, [newPage, goToNext, resetNewPage]);

  const handleSubmit = values => {
    const formPage = {
      ...page,
      ...values,
      hasPaymentMethods: true,
    };
    savePage(formPage);
  };

  // Using useMemo here to make sure formValues only changes when
  // page.enableProcessingFees changes (and *not* if the percentages change).
  // The enableReinitialize flag means that any change to the form's values
  // will cause the Formik form to reset and then form information about which
  // fields have been touched will be lost and validation errors won't show up in UI.
  const formValues = useMemo(
    () => ({
      enableProcessingFees: page.hasPaymentMethods
        ? page.enableProcessingFees
        : true,
      checkPercentage,
      creditPercentage,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page.enableProcessingFees],
  );

  return (
    <>
      {disabled && <UnarchivePage onClick={onUnarchivePage} />}
      <Disable disabled={disabled}>
        <PageTitle>{t(pageTitleKey)}</PageTitle>
        <CustomFormik
          onChange={updatePage}
          onErrorsChange={setError}
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          render={formikProps => (
            <Form
              goToPrev={goToPrev}
              loading={loadingSave}
              isFinished={isFinished}
              {...formikProps}
            />
          )}
          validationSchema={schema}
        />
      </Disable>
    </>
  );
}

FeeDonationOptions.propTypes = {
  pageTitleKey: PropTypes.string,
  goToNext: PropTypes.func,
  goToPrev: PropTypes.func,
  page: PropTypes.object.isRequired,
  savePage: PropTypes.func.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  newPage: PropTypes.object.isRequired,
  resetNewPage: PropTypes.func.isRequired,
  updatePage: PropTypes.func,
  setError: PropTypes.func,
  isFinished: PropTypes.bool,
  onUnarchivePage: PropTypes.func,
};

FeeDonationOptions.defaultProps = {
  pageTitleKey: 'additionalOptions.title',
  goToNext: null,
  goToPrev: null,
  updatePage: null,
  setError: null,
  isFinished: false,
  onUnarchivePage: null,
};

export default FeeDonationOptions;
