import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams, matchPath, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLocations } from '../../tiles';
import { setAppLoading } from '../../../store/loading/actions';
import {
  backendToFrontend,
  frontendToBackend,
  backendToTilesList,
} from '../../../store/tiles/transform';
import { resetNewTile } from '../../../store/tiles/actions';
import { tileConfigPaths } from '../../../components/navigation/WizardLayout/tile.config';
import {
  handleException,
  setError,
  setSuccess,
} from '../../../store/alerts/actions';
import TileService from '../../../api/TileService';

async function getTileById({ queryKey }) {
  const [, tileId] = queryKey;
  const { data } = await TileService.getTileWithLocations({ id: tileId });
  const newTile = { ...data.data };
  const locations = (data?.included || [])[0]?.locations || [];
  newTile.locations = getLocations(newTile, locations);
  return backendToFrontend(newTile);
}

async function createTile({ tile, page }) {
  const response = await TileService.createTile(frontendToBackend(tile, page));
  return backendToFrontend(response.data.data);
}

async function saveTile({ tile, page }) {
  const response = await TileService.saveTile(frontendToBackend(tile, page));
  return backendToFrontend(response.data.data);
}

async function patchTile({ tile, values, operation = 'replace' }) {
  const response = await TileService.patchTile(tile.id, values, operation);
  return backendToFrontend(response.data.data);
}

function saveTilesOrder({ page, tiles }) {
  return TileService.saveTiles(page, tiles);
}

function updateTileStatus({ pageId, tileIds, tileStatus }) {
  return TileService.updateTileStatus(tileIds, tileStatus, pageId);
}

async function deleteTile(tile) {
  const response = await TileService.deleteTile(tile);
  return response;
}

async function getTiles() {
  const { data } = await TileService.getTilesByOrg();
  return backendToTilesList(data);
}

async function getTilesByLocation({ queryKey }) {
  const [, locationId] = queryKey;
  const { data } = await TileService.getTiles({ id: locationId });
  return backendToTilesList(data);
}

async function getIsDeletable({ queryKey }) {
  const [, tileId] = queryKey;
  const { data } = await TileService.getIsDeletable({ id: tileId });
  return data.data.isDeletable;
}

export function useGetTile(tileId, config = {}) {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['tile', tileId],
    queryFn: getTileById,
    onError: (error) => {
      dispatch(handleException(error));
    },
    ...config,
  });
}

export function useCreateTile() {
  const dispatch = useDispatch();
  return useMutation(createTile, {
    onSuccess: () => {
      dispatch(resetNewTile());
    },
    onError: (error) => {
      dispatch(handleException(error));
    },
  });
}

export function useSaveTile(config = {}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(saveTile, {
    onMutate: ({ tile }) => {
      queryClient.cancelQueries(['tile', tile.id]);
      const prevTile = queryClient.getQueryData(['tile', tile.id]);
      queryClient.setQueryData(['tile', tile.id], tile);
      return () => queryClient.setQueryData(['tile', tile.id], prevTile);
    },
    onSuccess: () => {
      if (config.showSuccessMessage) {
        dispatch(setSuccess(t('tile.saved')));
      }
      queryClient.invalidateQueries('tile');
    },
    onError: (error, _, rollback) => {
      dispatch(handleException(error));
      rollback();
    },
  });
}

export function usePatchTile(config = {}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(patchTile, {
    onMutate: ({ tile, values }) => {
      queryClient.cancelQueries(['tile', tile.id]);
      const prevTile = queryClient.getQueryData(['tile', tile.id]);
      queryClient.setQueryData(['tile', tile.id], { ...tile, ...values });
      return () => queryClient.setQueryData(['tile', tile.id], prevTile);
    },
    onSuccess: () => {
      if (config.showSuccessMessage) {
        dispatch(setSuccess(t('tile.saved')));
      }
      queryClient.invalidateQueries('tile');
    },
    onError: (error, _, rollback) => {
      dispatch(handleException(error));
      rollback();
    },
  });
}

export function useHideTile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(patchTile, {
    onMutate: () => {
      dispatch(setAppLoading(true));
    },
    onSuccess: (tile) => {
      dispatch(setSuccess(t('tilesOrder.removeTileSuccessful')));
      queryClient.invalidateQueries('tile');
      queryClient.removeQueries(['tile', tile.id]);
      queryClient.invalidateQueries('tiles');
    },
    onError: (error) => {
      dispatch(handleException(error, false));
      dispatch(setError(t('tilesOrder.removeTileFailed')));
    },
    onSettled: () => {
      dispatch(setAppLoading(false));
    },
  });
}

export function useDeleteTile(config = {}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(deleteTile, {
    onSuccess: () => {
      queryClient.invalidateQueries('tile');
    },
    onError: (error) => {
      dispatch(handleException(error));
    },
    ...config,
  });
}

export function useGetTiles(config = {}) {
  const dispatch = useDispatch();
  const loadingDialogIsOpen = useSelector((state) => state.loadingDialog.open);
  return useQuery({
    queryKey: ['tile'],
    queryFn: getTiles,
    enabled: !loadingDialogIsOpen,
    onError: (error) => {
      dispatch(handleException(error));
    },
    ...config,
  });
}

export function useGetTilesByLocation(locationId, config = {}) {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['tiles', locationId],
    queryFn: getTilesByLocation,
    enabled: Boolean(locationId),
    onError: (error) => {
      dispatch(handleException(error));
    },
    ...config,
  });
}

export function useSaveTileOrder() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(saveTilesOrder, {
    onMutate: (pageAndTiles) => {
      dispatch(setAppLoading(true));

      const {
        page: { id: pageId },
        tiles,
      } = pageAndTiles;
      const queryKey = ['tiles', pageId];
      queryClient.cancelQueries(queryKey);
      // Snapshot the previous value
      const cachedPageAndTiles = queryClient.getQueryData(queryKey);
      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, () => tiles);
      // Return a rollback function - used to revert to original data if error occurs
      return () => queryClient.setQueryData(queryKey, cachedPageAndTiles);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('tiles');
    },
    onSettled: () => {
      dispatch(setAppLoading(false));
    },
    onError: (error, _, rollback) => {
      dispatch(handleException(error));
      return rollback();
    },
  });
}

export function useUpdateTileStatus() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(updateTileStatus, {
    onSuccess: () => {
      dispatch(setSuccess(t('tiles.added')));
      queryClient.invalidateQueries('tiles');
      queryClient.invalidateQueries('tile');
    },
    onSettled: () => dispatch(setAppLoading(false)),
    onError: (error) => dispatch(handleException(error)),
  });
}

export function useIsDeletableTile(tile, config) {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['tile-is-deletable', tile?.id],
    queryFn: getIsDeletable,
    enabled: config.enabled,
    onError: (error) => {
      dispatch(handleException(error));
    },
    ...config,
  });
}

export function useGetTileId() {
  const location = useLocation();
  const params = useParams();
  const overrideParams = matchPath(location.pathname, {
    path: Object.entries(tileConfigPaths),
  });
  let tileId = params.tileId ? params.tileId : null;
  if (overrideParams?.params?.tileId) {
    tileId = overrideParams.params.tileId
      ? overrideParams.params.tileId
      : tileId;
  }

  return tileId;
}

export default {
  useGetTile,
  useCreateTile,
  useGetTiles,
  useSaveTile,
  useDeleteTile,
  usePatchTile,
};
