import { connect } from 'react-redux';

import MemoTextField from './MemoTextField';

export function mapStateToProps(state) {
  return {
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, {})(MemoTextField);
