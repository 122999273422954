import { bool, func, objectOf, shape, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { DeleteModal as DeleteModalCommon, PrimaryButton } from '..';
import { constants } from '../../../globals';

import styles from './styles';

export function DeletePageModal({
  classes,
  open,
  onClose,
  onDelete,
  isDeletable,
  isLoading,
  disabled,
  page,
  entityType,
}) {
  const { t } = useTranslation();
  return (
    <DeleteModalCommon
      isLoading={isLoading}
      open={open}
      onClose={onClose}
      title={
        isDeletable
          ? t(`${entityType}.modal.delete.title`)
          : t(`${entityType}.modal.cant.delete.title`)
      }
      message={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Typography>
          {isDeletable ? (
            <>
              {`${t('deleteModal.delete.description')} `}
              <b>
                {page?.streamData
                  ? page.streamData.title
                  : page?.pageName || <i>Untitled</i>}
              </b>
              {'? '}
              {t('deleteModal.undone')}
            </>
          ) : (
            t(
              `${entityType}.modal.cant.delete${
                page?.status === constants.PAGE_ARCHIVED ? '.archived' : ''
              }`,
            )
          )}
        </Typography>
      }
      actions={
        isDeletable ? (
          <>
            <Button
              onClick={onClose}
              size="large"
              className={classes.closeButton}
              disabled={disabled}
              data-testid="delete-cancel"
            >
              {t('button.cancel')}
            </Button>
            <Button
              size="large"
              data-testid="delete-accept"
              className={classes.deleteButton}
              onClick={onDelete}
              disabled={disabled}
            >
              {t(`${entityType}.modal.delete.button`)}
            </Button>
          </>
        ) : (
          <PrimaryButton
            size="large"
            data-testid="delete-got-it"
            onClick={onClose}
          >
            {t('deleteModal.got.it')}
          </PrimaryButton>
        )
      }
    />
  );
}

DeletePageModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func.isRequired,
  onDelete: func.isRequired,
  isDeletable: bool,
  isLoading: bool,
  disabled: bool,
  page: shape({}),
  entityType: string.isRequired,
};

DeletePageModal.defaultProps = {
  open: false,
  disabled: false,
  isDeletable: false,
  isLoading: false,
  page: {},
};

export default withStyles(styles)(DeletePageModal);
