import { bool, func, objectOf, string } from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import { Modal, PrimaryButton } from '../../../common';

import styles from './styles';

function NewMobilePageModal({ classes, isOpen, onClose }) {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={onClose} className={classes.modal}>
      <Modal.Title className={classes.modalTitle}>
        {t('vanco.mobile.modal.title')}
      </Modal.Title>
      <Modal.Content>
        <Typography className={classes.title}>
          {t('vanco.mobile.modal.customize.title')}
        </Typography>
        <Typography className={classes.description}>
          {t('vanco.mobile.modal.customize.description')}
        </Typography>
        <Typography className={classes.title}>
          {t('vanco.mobile.modal.invite.title')}
        </Typography>
        <Typography className={classes.description}>
          {t('vanco.mobile.modal.invite.description')}
        </Typography>
        <Typography className={classes.title}>
          {t('vanco.mobile.modal.download.title')}
        </Typography>
        <Typography className={classes.description}>
          {t('vanco.mobile.modal.download.description')}
        </Typography>
      </Modal.Content>
      <Modal.Actions className={classes.buttonsContainer}>
        <PrimaryButton onClick={onClose} size="large" type="button">
          {t('vanco.mobile.got.it')}
        </PrimaryButton>
        <Button
          as="a"
          href="https://vancopayments.zendesk.com/hc/en-us/sections/360008918614-Vanco-Mobile-"
          target="_blank"
          size="large"
          color="primary"
          variant="outlined"
        >
          {t('vanco.mobile.learn.more')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

NewMobilePageModal.propTypes = {
  classes: objectOf(string).isRequired,
  isOpen: bool,
  onClose: func.isRequired,
};

NewMobilePageModal.defaultProps = {
  isOpen: false,
};

export default withStyles(styles)(NewMobilePageModal);
