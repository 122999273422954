// not all browsers support window.location.origin so here is a polyfill
// see: https://developer.mozilla.org/en-US/docs/Web/API/window.location#Properties
// eslint-disable-next-line import/prefer-default-export
export const origin = () => {
  const {
    location: { protocol, hostname, port },
  } = window;
  const portPart = port ? `:${port}` : '';
  return `${protocol}//${hostname}${portPart}`;
};

export default {
  origin,
};
