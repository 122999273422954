export default function styles() {
  return {
    container: {
      padding: 8,
    },
    formTitle: {
      lineHeight: '1.43',
    },
    formSubtitle: {
      fontWeight: 'normal',
    },
  };
}
