import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const ExternalLink = forwardRef(({ to, className, children }, ref) => {
  const httpRegex = /http:\/\/|https:\/\/|www./g;
  const isUrlDefined = to && to.length;
  const url = isUrlDefined ? `https://${to.replace(httpRegex, '')}` : '';
  return (
    <a
      href={url}
      ref={ref}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </a>
  );
});

ExternalLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ExternalLink.defaultProps = {
  to: '',
  className: '',
};

export default ExternalLink;
