import { hasValidCharsOnly } from '../../../../utils/validations';

// eslint-disable-next-line import/prefer-default-export
export const createValidation = (t) => {
  return {
    validate: (value, { textFields, itemId }) => {
      if (!hasValidCharsOnly(value)) {
        return {
          isValid: false,
          message: t('tile.memoLine.dropdownfield.error.invalidChars'),
        };
      }

      if (textFields.some((i) => i.customText === value && i.id !== itemId)) {
        return {
          isValid: false,
          message: t('tile.memoLine.dropdownfield.error.duplicated'),
        };
      }

      return { isValid: true };
    },
  };
};
