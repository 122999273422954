import { func } from 'prop-types';
import { Grid, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Modal from 'components/common/Modal';
import PrimaryButton from 'components/common/PrimaryButton';
import useStyles from './styles';

function AiAssistWarning(props) {
  const { handleSubmit, handleClose } = props;
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <>
      <Modal.Title className={classes.title}>
        {t('modal.aiassist.title')}
      </Modal.Title>

      <Modal.Content className={classes.content}>
        <Grid container>
          <Grid item md={12} sm={12} xs={12} className={classes.warningMessage}>
            <section>
              <Typography data-testid="primary-text">
                {t('modal.aiassist.warningMessage.primary')}
              </Typography>
              <Typography data-testid="secondary-text">
                {t('modal.aiassist.warningMessage.secondary')}
              </Typography>
            </section>

            <section>
              <Typography data-testid="final-text">
                {t('modal.aiassist.warningMessage.final')}
              </Typography>
            </section>
          </Grid>
        </Grid>
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <Button
          onClick={handleClose}
          size="medium"
          variant="outlined"
          data-testid="assist-warning-cancel"
          className={classes.closeButton}
        >
          {t('button.cancel')}
        </Button>
        <PrimaryButton
          type="submit"
          size="medium"
          data-testid="assist-warning-yes"
          onClick={handleSubmit}
        >
          {t('modal.aiassist.warningMessage.yes')}
        </PrimaryButton>
      </Modal.Actions>
    </>
  );
}

AiAssistWarning.propTypes = {
  handleSubmit: func.isRequired,
  handleClose: func.isRequired,
};

export default AiAssistWarning;
