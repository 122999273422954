export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      borderWidth: 3,
      borderStyle: 'dashed',
      borderColor: theme.palette.primary.main,
      minWidth: 700,
      borderRadius: 8,
      padding: 32,
      margin: 40,
      gap: 18,
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#e9e8f5',
      width: 100,
      height: 100,
      borderRadius: 100,
    },
    pickerText: {
      maxWidth: 550,
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: '1.6rem',
    },
    buttonIcon: {
      '& path': {
        stroke: theme.palette.white,
      },
      height: 20,
      width: 20,
    },
  };
}
