import { useTranslation } from 'react-i18next';

import Settings from './Settings';
import { Page, PageTitle } from '../../common';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

export default function Profile() {
  const { t } = useTranslation();
  usePageTitle(t('titles.profile'));
  return (
    <Page>
      <PageTitle>{t('profileSettings.title')}</PageTitle>
      <Settings />
    </Page>
  );
}
