import { connect } from 'react-redux';

import SiteSettings from './SiteSettings';
import { createPage, savePage } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    loadingPage: state.pages.loadingPage,
    loadingSave: state.pages.loadingSave,
    loadingCreate: state.pages.loadingCreate,
    newPage: state.pages.newPage,
    pages: state.pages.pages,
  };
}

export default connect(mapStateToProps, { createPage, savePage })(SiteSettings);
