import UnsplashService from '../../api/UnsplashService';
import {
  GET_PHOTOS_START,
  GET_PHOTOS_FINISH,
  GET_PHOTOS_ERROR,
  CLEAR_PHOTOS,
} from './types';
import { handleException } from '../alerts/actions';
import { UNSPLASH_IMAGES_PER_PAGE } from '../../globals/constants';

export function getPhotos(page, query) {
  return async (dispatch, getState) => {
    const { photos } = getState().unsplash;

    if (photos.length >= page * UNSPLASH_IMAGES_PER_PAGE) {
      dispatch({
        type: GET_PHOTOS_FINISH,
        data: photos,
        page,
      });
      return;
    }

    dispatch({ type: GET_PHOTOS_START });

    try {
      const res = await UnsplashService.getSearchPhotos(query, page);
      const json = res.response;

      if (res.status === 200) {
        const newPhotos = photos.concat(json?.results || json);

        dispatch({
          type: GET_PHOTOS_FINISH,
          data: newPhotos,
          page,
        });
      } else {
        throw new Error(json?.errors[0]);
      }
    } catch (e) {
      dispatch({
        type: GET_PHOTOS_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }
  };
}

export function clearPhotos() {
  return {
    type: CLEAR_PHOTOS,
  };
}
