import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';

import OutlinedTextField from '../../OutlinedTextField';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import Form from '../../Form';
import FormHelperTextBottom from '../../FormHelperTextBottom';

function TileMemoForm({
  handleSubmit,
  values,
  errors,
  setFieldValue,
  touched,
  handleBlur,
  goToPrev,
  loading,
  isFinished,
  handleReset,
  dirty,
}) {
  const { includeQuestion, prompt, required } = values;
  const { t } = useTranslation();

  const handleCheckbox = e => {
    const { name, checked } = e.target;
    setFieldValue({ target: { name, value: checked } });
  };

  return (
    <Form>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <FormControl>
            <FormControlLabel
              control={
                /* eslint-disable-next-line react/jsx-wrap-multilines */
                <Checkbox
                  color="primary"
                  name="includeQuestion"
                  checked={includeQuestion}
                  onChange={handleCheckbox}
                  inputProps={{
                    'data-testid': 'includeQuestion',
                  }}
                />
              }
              label={t('memo.enableQuestion')}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <OutlinedTextField
            name="prompt"
            label={t('memo.prompt')}
            placeholder={t('memo.envelopeNumber')}
            value={prompt}
            onBlur={handleBlur}
            onChange={setFieldValue}
            error={touched.prompt && !!errors.prompt}
            disabled={!includeQuestion}
            inputProps={{
              'data-testid': 'prompt',
            }}
          />
          {touched.prompt && !!errors.prompt && (
            <FormHelperTextBottom left={errors.prompt} />
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <FormControl>
            <FormControlLabel
              control={
                /* eslint-disable-next-line react/jsx-wrap-multilines */
                <Checkbox
                  color="primary"
                  name="required"
                  checked={required}
                  disabled={!includeQuestion}
                  onChange={handleCheckbox}
                  inputProps={{
                    'data-testid': 'required',
                  }}
                />
              }
              label={t('memo.required')}
            />
          </FormControl>
        </Grid>
      </Grid>
      {!loading && goToPrev && (
        <WizardButtons
          isFinished={isFinished}
          dirty={dirty}
          goToNext={handleSubmit}
          goToPrev={goToPrev}
          undo={handleReset}
        />
      )}
      {loading && <CircularProgress color="primary" />}
    </Form>
  );
}

TileMemoForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  goToPrev: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
};

TileMemoForm.defaultProps = {
  goToPrev: null,
};

export default TileMemoForm;
