import { useEffect } from 'react';
import PropTypes from 'prop-types';

function withScrollToTop(Component) {
  function Wrapped({ location, ...rest }) {
    const { pathname } = location;

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return <Component location={location} {...rest} />;
  }

  Wrapped.propTypes = {
    location: PropTypes.object.isRequired,
  };

  return Wrapped;
}

export default withScrollToTop;
