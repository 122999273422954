export default function styles(theme) {
  const iconBackground = {
    width: 40,
    height: 40,
    borderRadius: 100,
    justifyContent: 'center',
  };

  return {
    grid: {
      border: '1px solid #EBEBEB',
      borderRadius: 12,
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
      marginBottom: 20,
    },
    gridTitle: {
      color: theme.palette.text.subdued,
      fontWeight: 'bold',
      marginBottom: 20,
    },
    gridRow: {
      marginBottom: theme.spacing(2),
    },
    notProvided: {
      color: theme.palette.secondary.medium,
    },
    primaryColorContainer: {
      alignItems: 'center',
      display: 'flex',
      marginTop: -5,
      position: 'relative',
    },
    primaryColor: {
      borderRadius: 100,
      marginRight: 15,
      height: 24,
      width: 24,
    },
    divider: {
      margin: `${theme.spacing(3)}px ${-theme.spacing(3)}px`,
      width: '110%',
    },
    lastDivider: {
      margin: `0 ${-theme.spacing(3)}px`,
      width: '110%',
    },
    nonIconBackground: {
      ...iconBackground,
      display: 'flex',
    },
    iconBackground: {
      ...iconBackground,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    iconInitial: {
      fontSize: 18,
      alignSelf: 'center',
      color: 'white',
    },
    breakAll: {
      wordBreak: 'break-all',
    },
    spinnerContainer: {
      marginTop: theme.spacing(2),
    },
    buttonIcon: {
      marginRight: 8,
    },
    previewButton: {
      textAlign: 'right',
    },
    truncationButton: {
      marginBottom: 3,
      paddingTop: 0,
    },
  };
}
