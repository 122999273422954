import {
  Children,
  cloneElement,
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Switch, matchPath } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useScrollToTop } from '../../../utils/hooks';
import { Page, PagePaper, PreventLeavePageModal } from '../../common';
import {
  findCurrentSection,
  findNextPath,
  findPrevPath,
  WizardContext,
} from '../../../utils/wizard';
import { getNavConfig } from './config';
import { WizardNav, MobileWizardNav } from '..';
import styles from './styles';
import { parseQueryParamsToObject } from '../../../utils/url';

export function WizardLayout({
  classes,
  location,
  history,
  page,
  tile,
  settings,
  newTile,
  newPage,
  resetNewTile,
  resetNewPage,
  menuOpen,
  communityFeaturesChoosen,
  features,
  children,
  newWizard,
  forcePreventModal,
}) {
  const contentRef = useRef();
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  useScrollToTop(contentRef, location);
  const [dirty, setDirty] = useState(false);
  const [submit, setSubmit] = useState(null);
  const isMobilePage = location.pathname.includes('mobile');
  const isTile = location.pathname.includes('tile');
  const isStreamPage = location.pathname.includes('stream');
  const isOrganizationPage = location.pathname.includes('organization');
  const showVONewWizard =
    features.ChannelManagement &&
    !isTile &&
    !isMobilePage &&
    !isStreamPage &&
    !isOrganizationPage;
  const showNewwizard = newWizard || showVONewWizard;

  const contextProviderProps = useMemo(
    () => ({ submit, setSubmit, dirty, setDirty }),
    [submit, setSubmit, dirty, setDirty],
  );

  const generateNavConfig = useCallback(
    () =>
      getNavConfig({
        page,
        tile,
        settings,
        isMobilePage,
        isStreamPage,
        isOrganizationPage,
        communityFeaturesChoosen,
        features,
      }),
    [
      communityFeaturesChoosen,
      features,
      isMobilePage,
      isOrganizationPage,
      isStreamPage,
      page,
      settings,
      tile,
    ],
  );

  const configWithState = generateNavConfig();

  const [navState, setNavState] = useState(configWithState);
  useEffect(() => {
    setNavState(generateNavConfig());
  }, [generateNavConfig, settings]);

  const hideWizardNav =
    !isTile &&
    !isStreamPage &&
    !isOrganizationPage &&
    features.ChannelManagement;

  const match = matchPath(location.pathname, {
    path: navState.map((n) => n.path).sort((a, b) => b.length - a.length),
  }) || { params: {} };
  const {
    path,
    params: { tileId, pageId },
  } = match;

  const currentSection = findCurrentSection(navState, path);

  let { nextPath } = currentSection;

  if (!nextPath) {
    const nextIncomplete = navState.find((section) => section.next);
    if (currentSection.next || !nextIncomplete) {
      nextPath = findNextPath(navState, currentSection);
    } else {
      // If we're not already on the next step, we always want to go to the next step
      nextPath = nextIncomplete.path;
    }
  }

  if (nextPath) {
    if (tileId) {
      nextPath = nextPath.replace(':tileId', tileId);
    } else if (newTile && newTile.id) {
      nextPath = nextPath.replace(':tileId', newTile.id);
    }

    if (pageId) {
      nextPath = nextPath.replace(':pageId', pageId);
    } else if (newPage && newPage.id) {
      nextPath = nextPath.replace(':pageId', newPage.id);
    }
  }

  const goToNext = (showSuccessToast = false) => {
    resetNewTile();
    if (!currentSection.isFinished && currentSection.navTitle) {
      // Mark current step as complete, set next step
      const newNavState = [...navState];
      const currentStepIndex = newNavState.findIndex((step) => step.next);
      const newCurrentStep = {
        ...newNavState[currentStepIndex],
        isFinished: true,
        next: false,
      };
      newNavState.splice(currentStepIndex, 1, newCurrentStep);
      const nextStepIndex = newNavState.findIndex(
        (step) => step.navTitle && !step.isFinished,
      );
      if (nextStepIndex > -1) {
        const newNextStep = {
          ...newNavState[nextStepIndex],
          next: true,
        };
        newNavState.splice(nextStepIndex, 1, newNextStep);
      }
      setNavState(newNavState);
    }

    const params = parseQueryParamsToObject(location.search);
    if (params.redirect) {
      history.push(params.redirect);
    } else {
      history.push(nextPath, { showSuccessToast });
    }
  };

  const goToPrev = () => {
    let { prevPath } = currentSection;

    resetNewTile();

    if (!prevPath) {
      prevPath = findPrevPath(navState, currentSection);
    }

    if (prevPath) {
      if (tileId) prevPath = prevPath.replace(':tileId', tileId);
      if (pageId) prevPath = prevPath.replace(':pageId', pageId);

      history.push(prevPath);
    }
  };

  const stepperSteps = navState.filter((c) => c.navTitle);

  return (
    <section
      className={
        showNewwizard && isLargeScreen ? classes.mainCentered : classes.main
      }
    >
      <section
        className={
          showNewwizard && isLargeScreen
            ? classes.contentWrapCentered
            : classes.contentWrap
        }
      >
        <Page
          className={
            showNewwizard ? classes.container : classes.containerDeprecated
          }
          ref={contentRef}
        >
          {currentSection.navTitle && !hideWizardNav && (
            <MobileWizardNav
              totalSections={stepperSteps.length}
              navConfig={stepperSteps}
              currentSection={currentSection}
            />
          )}
          <WizardContext.Provider value={contextProviderProps}>
            {
              // We add this Switch here because we need the children to have the added props,
              // but we don't want the NotFound component to render when other Routes are matched.
              // Putting this Switch in the Router breaks the prop adding, since they get applied
              // to the Switch and not the Routes.
            }
            <PagePaper
              className={`${
                newWizard && isLargeScreen ? classes.pagePaper : ''
              } ${showVONewWizard && isLargeScreen ? classes.pagePaperVO : ''}`}
            >
              <Switch>
                {Children.map(children, (child) =>
                  cloneElement(child, {
                    navConfig: navState,
                    newTile,
                    goToNext,
                    goToPrev,
                    match,
                    isFinished: currentSection.isFinished,
                    tile: {
                      ...tile,
                    },
                    page: {
                      ...page,
                    },
                    resetNewPage,
                    pageTitleKey: currentSection.pageTitleKey,
                  }),
                )}
              </Switch>
            </PagePaper>
          </WizardContext.Provider>
        </Page>
        {currentSection.navTitle &&
          (!isOrganizationPage || settings.dbaName) &&
          !hideWizardNav && (
            <WizardNav
              history={history}
              totalSections={stepperSteps.length}
              navConfig={stepperSteps}
              currentSection={currentSection}
              page={page}
              tile={tile}
              open={menuOpen}
              setDirty={setDirty}
              dirty={dirty}
              submit={submit}
              isStreamPage={isStreamPage}
              isOrganizationPage={isOrganizationPage}
              newWizard={newWizard}
            />
          )}
      </section>
      <PreventLeavePageModal
        shouldOpenOnLeave={(dirty && !isTile) || forcePreventModal}
      />
    </section>
  );
}

WizardLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  page: PropTypes.object,
  tile: PropTypes.object,
  settings: PropTypes.object,
  newTile: PropTypes.object,
  newPage: PropTypes.object,
  resetNewTile: PropTypes.func.isRequired,
  resetNewPage: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  features: PropTypes.objectOf(PropTypes.bool),
  children: PropTypes.node,
  communityFeaturesChoosen: PropTypes.bool.isRequired,
  newWizard: PropTypes.bool,
  forcePreventModal: PropTypes.bool.isRequired,
};

WizardLayout.defaultProps = {
  children: [],
  page: null,
  tile: null,
  settings: null,
  newTile: null,
  newPage: null,
  features: {},
  newWizard: false,
};

export default withStyles(styles)(WizardLayout);
