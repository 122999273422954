import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import Form from '../../Form';
import ColorSelector from '../../ColorSelector';
import { COLOR_OPTIONS } from '../../../../globals/constants';

import styles from './styles';

export function OffsetForm({
  classes,
  values,
  handleSubmit,
  setFieldValue,
  goToPrev,
  loading,
  isFinished,
  handleReset,
  dirty,
}) {
  const { t } = useTranslation();

  const onColorSelect = color => {
    setFieldValue({ target: { name: 'primaryColor', value: color } });
  };

  return (
    <Form>
      <Grid container className={classes.colorsContainer}>
        <Typography className={classes.text} variant="body2">
          {t('wizard.color.options.text')}
        </Typography>
        {COLOR_OPTIONS.map(c => (
          <ColorSelector
            key={c}
            color={c}
            onClick={onColorSelect}
            isSelected={c === values.primaryColor}
          />
        ))}
      </Grid>
      {!loading && goToPrev && (
        <WizardButtons
          isFinished={isFinished}
          dirty={dirty}
          goToNext={handleSubmit}
          goToPrev={goToPrev}
          undo={handleReset}
        />
      )}
      {loading && goToPrev && (
        <div className={classes.spinnerContainer}>
          <CircularProgress
            color="primary"
            data-testid="color-options-save-spinner"
          />
        </div>
      )}
    </Form>
  );
}

OffsetForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  goToPrev: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
};

OffsetForm.defaultProps = {
  goToPrev: null,
};

export default withStyles(styles)(OffsetForm);
