import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import AddDonation from './AddDonation';
import { addToBasket } from '../../../store/transaction/actions';
import { setError } from '../../../store/alerts/actions';

const mapStateToProps = (state) => ({
  items: state.transaction.basket,
});

const mapDispatchToProps = (dispatch) => ({
  transactionActions: bindActionCreators({ addToBasket }, dispatch),
  setError,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDonation);
