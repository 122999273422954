export const commonStyles = (theme) => ({
  tableContainer: {
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  smallColumn: {
    width: 210,
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
    [theme.breakpoints.down('xs')]: {
      width: 120,
    },
  },
  untitled: {
    fontStyle: 'italic',
    fontWeight: 400,
  },
  moreMenu: {
    width: 65,
  },
});

export const commonModalStyles = () => {
  return {
    title: {
      padding: 24,
      marginBottom: 0,
      '& h6': {
        textAlign: 'left',
        paddingLeft: 0,
      },
    },
    content: {
      padding: '15px 24px 30px',
    },
    actions: {
      borderTop: 0,
      padding: '0 24px 24px',
    },
    closeButton: {
      padding: '13px 12px',
    },
  };
};

export default function styles(theme) {
  return {
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      marginBottom: 0,
    },
    newTileButton: {
      height: 40,
      padding: '0px 16px 0px 8px',
      width: 131,
    },
    mainContainer: {
      flex: 'auto',
    },
    ...commonStyles(theme),
  };
}
