export default function errorMiddleware() {
  return () => next => action => {
    const { error } = action;
    if (!error) {
      return next(action);
    }

    // log the error in the console
    /* eslint-disable-next-line no-console */
    console.error(error);

    return next(action);
  };
}
