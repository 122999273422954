import PageService from '../../api/PageService';
import { defaultPage } from '../../globals/defaults';
import {
  CREATE_PAGE_START,
  CREATE_PAGE_FINISH,
  CREATE_PAGE_ERROR,
  GET_PAGES_START,
  GET_PAGES_ERROR,
  GET_PAGES_FINISH,
  SAVE_PAGE_START,
  SAVE_PAGE_FINISH,
  SAVE_PAGE_ERROR,
  GET_PAGE_START,
  GET_PAGE_FINISH,
  GET_PAGE_ERROR,
  RESET_NEW_PAGE,
  SET_CURRENT_PAGE,
  GET_CURRENT_PAGE_START,
  GET_CURRENT_PAGE_FINISH,
  GET_CURRENT_PAGE_ERROR,
  UPDATE_PAGE,
  CLEAR_PAGES,
  SET_NEW_MOBILE_PAGE_MODAL_OPEN,
  SET_PAGE_COMMUNITY_FEATURES_CHOOSEN,
  REMOVE_PAGE,
  SET_STREAMER_ID,
  CREATE_STREAMER_SETTINGS_START,
  CREATE_STREAMER_SETTINGS_FINISH,
  CREATE_STREAMER_SETTINGS_ERROR,
  UPDATE_STREAMER_SETTINGS_START,
  UPDATE_STREAMER_SETTINGS_FINISH,
  UPDATE_STREAMER_SETTINGS_ERROR,
  UPDATE_ADDRESS_REQUIRED_START,
  UPDATE_ADDRESS_REQUIRED_FINISH,
  UPDATE_ADDRESS_REQUIRED_ERROR,
  PATCH_LOCATION_START,
  PATCH_LOCATION_FINISH,
  PATCH_LOCATION_ERROR,
} from './types';
import { frontendToBackend } from './transform';
import {
  handleException,
  handleResponseError,
  setSuccess,
} from '../alerts/actions';
import { setAppLoading } from '../loading/actions';
import { findPrimaryPage } from './helpers';
import i18n from '../../utils/i18n';
import { getCroppedImageBase64 } from '../../utils/tiles';

export function createPage(
  page = defaultPage,
  showSuccessMessage = false,
  successMessage = i18n.t('stream.created.success'),
) {
  return async (dispatch, getState) => {
    dispatch(setAppLoading(true));
    const { currentOrganization } = getState().organizations;
    dispatch({ type: CREATE_PAGE_START });

    try {
      const res = await PageService.createPage(
        frontendToBackend(page),
        currentOrganization,
      );

      if ([201, 200].includes(res.status)) {
        dispatch({
          type: CREATE_PAGE_FINISH,
          data: res.data.data,
        });

        if (showSuccessMessage) {
          dispatch(setSuccess(successMessage));
        }

        return res.data.data;
      }

      dispatch({
        type: CREATE_PAGE_ERROR,
        error: res.error,
      });
      dispatch(handleResponseError(res));
      return null;
    } catch (e) {
      dispatch({
        type: CREATE_PAGE_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
      return null;
    } finally {
      dispatch(setAppLoading(false));
    }
  };
}

export function getPage(page) {
  return async (dispatch) => {
    dispatch(setAppLoading(true));
    dispatch({ type: GET_PAGE_START });

    try {
      const res = await PageService.getPage(page);

      if ([201, 200].includes(res?.status)) {
        dispatch({
          type: GET_PAGE_FINISH,
          data: res.data.data,
        });
      } else {
        dispatch({
          type: GET_PAGE_ERROR,
          error: res?.error ?? {},
        });
        dispatch(handleResponseError(res ?? {}));
      }
    } catch (e) {
      const error =
        e?.response?.status === 404
          ? { message: i18n.t('errors.pageNotFound') }
          : e;
      dispatch({
        type: GET_PAGE_ERROR,
        error: error.message,
      });
      dispatch(handleException(error));
    }

    dispatch(setAppLoading(false));
  };
}

export function savePage(
  page,
  showSuccessMessage = false,
  successMessage = i18n.t('site.saved'),
) {
  return async (dispatch) => {
    dispatch(setAppLoading(true));
    dispatch({ type: SAVE_PAGE_START });

    try {
      const res = await PageService.savePage(frontendToBackend(page));
      if ([201, 200].includes(res.status)) {
        dispatch({
          type: SAVE_PAGE_FINISH,
          data: res.data.data,
        });
        if (showSuccessMessage) {
          dispatch(setSuccess(successMessage));
        }
      } else {
        dispatch({
          type: SAVE_PAGE_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: SAVE_PAGE_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    } finally {
      dispatch(setAppLoading(false));
    }
  };
}

export function getPages() {
  return async (dispatch) => {
    dispatch(setAppLoading(true));
    dispatch({ type: GET_PAGES_START });

    try {
      const res = await PageService.getPages();

      if (res.status === 200) {
        dispatch({
          type: GET_PAGES_FINISH,
          data: res.data.data,
        });
      } else {
        dispatch({
          type: GET_PAGES_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: GET_PAGES_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }

    dispatch(setAppLoading(false));
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    data: page,
  };
}

export function getCurrentPage() {
  return async (dispatch) => {
    dispatch(setAppLoading(true));
    dispatch({ type: GET_CURRENT_PAGE_START });

    try {
      const res = await PageService.getPages();

      if (res.status === 200) {
        const pages = res.data.data;

        if (findPrimaryPage(pages)) {
          dispatch({
            type: GET_CURRENT_PAGE_FINISH,
            data: pages[0],
          });
        } else {
          // do not display this error because new users won't have pages
          // but still store it as an error
          const error = i18n.t('errors.failedGetPage');
          dispatch({
            type: GET_CURRENT_PAGE_ERROR,
            error,
          });
        }
      } else {
        dispatch({
          type: GET_CURRENT_PAGE_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: GET_CURRENT_PAGE_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }

    dispatch(setAppLoading(false));
  };
}

export function resetNewPage() {
  return {
    type: RESET_NEW_PAGE,
  };
}

export function clearPages() {
  return {
    type: CLEAR_PAGES,
  };
}

// a local only update of a page
export function updatePage(page) {
  return {
    type: UPDATE_PAGE,
    data: page,
  };
}

export function setNewMobilePageModalOpen(isOpen) {
  return {
    type: SET_NEW_MOBILE_PAGE_MODAL_OPEN,
    isOpen,
  };
}

export function setPageCommunityFeaturesChoosen(isChoosen) {
  return {
    type: SET_PAGE_COMMUNITY_FEATURES_CHOOSEN,
    isChoosen,
  };
}

export function updateLogoImage({ pageId, image }) {
  return async (dispatch) => {
    try {
      const blob = await getCroppedImageBase64(image, true);
      const updatedImage = { id: pageId, blob };

      const res = await PageService.updateLocationLogoImage(updatedImage);

      if ([201, 200].includes(res.status)) {
        return res.data.data;
      }
      dispatch(handleResponseError(res));
    } catch (e) {
      dispatch(handleException(e));
    }
    return null;
  };
}

export function updateBannerImage({ pageId, image }) {
  return async (dispatch) => {
    try {
      const blob = await getCroppedImageBase64(image);
      const updatedImage = { id: pageId, blob };

      const res = await PageService.updateLocationBannerImage(updatedImage);

      if ([201, 200].includes(res.status)) {
        return res.data.data;
      }
      dispatch(handleResponseError(res));
    } catch (e) {
      dispatch(handleException(e));
    }
    return null;
  };
}

export const removePage = (page) => ({
  type: REMOVE_PAGE,
  page,
});

export function setStreamerId(streamerId) {
  return {
    type: SET_STREAMER_ID,
    streamerId,
  };
}

export function createStreamerSettings(streamerSettings) {
  return async (dispatch, getState) => {
    const { currentOrganization } = getState().organizations;
    dispatch({ type: CREATE_STREAMER_SETTINGS_START });

    try {
      const res = await PageService.createStreamerSettings(
        streamerSettings,
        currentOrganization,
      );

      if ([201, 200].includes(res.status)) {
        dispatch({
          type: CREATE_STREAMER_SETTINGS_FINISH,
          data: res.data.data,
        });
        return res.data.data;
      }

      dispatch({
        type: CREATE_STREAMER_SETTINGS_ERROR,
        error: res.error,
      });
      dispatch(handleResponseError(res));
      return null;
    } catch (e) {
      dispatch({
        type: CREATE_STREAMER_SETTINGS_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
      return null;
    }
  };
}

export function updateStreamerSettings(streamerSettings) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_STREAMER_SETTINGS_START });

    try {
      const res = await PageService.updateStreamerSettings(streamerSettings);
      if ([201, 200].includes(res.status)) {
        dispatch({
          type: UPDATE_STREAMER_SETTINGS_FINISH,
          data: res.data.data,
        });

        dispatch(setSuccess(i18n.t('site.changes.saved')));
      } else {
        dispatch({
          type: UPDATE_STREAMER_SETTINGS_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: UPDATE_STREAMER_SETTINGS_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }
  };
}

export function updateAddressRequired(locationId, required) {
  return async (dispatch) => {
    dispatch(setAppLoading(true));
    dispatch({ type: UPDATE_ADDRESS_REQUIRED_START });

    try {
      const res = await PageService.updateAddressRequired(locationId, required);
      if ([201, 200].includes(res.status)) {
        dispatch({
          type: UPDATE_ADDRESS_REQUIRED_FINISH,
          data: res.data.data,
        });

        dispatch(setSuccess(i18n.t('site.changes.saved')));
      } else {
        dispatch({
          type: UPDATE_ADDRESS_REQUIRED_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: UPDATE_ADDRESS_REQUIRED_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }
    dispatch(setAppLoading(false));
  };
}

export function patchLocation(page, field, data) {
  return async (dispatch) => {
    dispatch(setAppLoading(true));
    dispatch({ type: PATCH_LOCATION_START });
    const request = [
      {
        op: 'replace',
        path: `/${field}`,
        value: data,
      },
    ];
    try {
      const res = await PageService.patchLocation(page, request);
      if ([201, 200].includes(res.status)) {
        dispatch({
          type: PATCH_LOCATION_FINISH,
          data: res.data.data,
        });

        dispatch(setSuccess(i18n.t('site.changes.saved')));
      } else {
        dispatch({
          type: PATCH_LOCATION_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: PATCH_LOCATION_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }
    dispatch(setAppLoading(false));
  };
}
