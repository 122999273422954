export default function styles() {
  return {
    container: {
      marginBottom: 40,
    },
    infoContainer: {
      borderRadius: 4,
      backgroundColor: '#FCF9E9',
      padding: 10,
    },
    infoNotice: {
      marginBottom: 10,
    },
    infoIcon: {
      color: '#D97917',
    },
  };
}
