import { object, func, string, objectOf, number, bool } from 'prop-types';
import { Checkbox, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import FormControl from '@material-ui/core/FormControl';

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import styles from './styles';
import {
  useBankAccounts,
  useCreateFunds,
  useFunds,
} from '../../../../utils/hooks';
import NewFundModal from '../../TileFundConfiguration/NewFundModal';
import { selectBackendToFrontendErrors } from '../../../../store/funds/selectors';
import FundForm from '../../TileFundConfiguration/FundForm';
import { mergeFundsWithBanks } from '../../../../store/funds/helpers';

function OffsetFund({
  classes,
  values,
  errors,
  setFieldValue,
  handleBlur,
  submitCount,
  isEditor,
}) {
  const { allowOffsetFund } = values;
  const { t } = useTranslation();
  const { organizationId } = useSelector((state) => ({
    organizationId: state.organizations.currentOrganization.id,
  }));
  const { data: banks, error: banksError } = useBankAccounts(organizationId);
  const {
    data: funds,
    error: fundsError,
    status: fundsStatus,
  } = useFunds(organizationId);
  const {
    createFund,
    newFund,
    loadingFundCreate,
    fundCreateStatus,
    createFundError,
  } = useCreateFunds();
  const [newFundOpen, setNewFundOpen] = useState(false);
  const [initialNewFund, setInitialNewFund] = useState({
    accountCode: '',
    name: '',
    bankAccount: '',
    fundRefId: '',
  });

  const fundsWithBanks = mergeFundsWithBanks(funds, banks);
  const handleCheckOffsets = async (e) => {
    const { name, checked } = e.target;
    await setFieldValue({ target: { name, value: checked } });
  };

  const closeNewFund = () => {
    setNewFundOpen(false);
  };

  const newFundSave = () => (newFundData) => {
    createFund(
      { organizationId, fund: newFundData },
      {
        onSuccess: (newCreatedFund) => {
          setFieldValue({ target: { name: 'fund', value: newCreatedFund } });
        },
      },
    );
  };

  const handleNewFund = () => {
    const firstBankAccount = banks && banks.length > 0 ? banks[0].id : '';
    setInitialNewFund({
      accountCode: '',
      name: '',
      bankAccount: firstBankAccount,
      fundRefId: '',
    });
    setNewFundOpen(true);
  };

  useEffect(() => {
    if (fundCreateStatus === 'success') {
      setNewFundOpen(false);
    }
  }, [fundCreateStatus]);

  return (
    <>
      <FormControl>
        <FormControlLabel
          className={classes.checkMargin}
          control={
            /* eslint-disable-next-line react/jsx-wrap-multilines */
            <Checkbox
              name="allowOffsetFund"
              color="primary"
              checked={allowOffsetFund}
              value={allowOffsetFund}
              onChange={handleCheckOffsets}
              className={classes.checkColor}
              inputProps={{
                'data-testid': 'allowOffsetFund',
              }}
            />
          }
          label={t('organization.fees.offset')}
        />
      </FormControl>
      {allowOffsetFund && (
        <FundForm
          handleNewFund={handleNewFund}
          funds={fundsWithBanks || []}
          loading={fundsStatus === 'loading'}
          fundsError={!!fundsError}
          banksError={!!banksError}
          newFund={newFund}
          values={values}
          setFieldValue={setFieldValue}
          errors={
            errors && (isEditor || (!isEditor && submitCount > 0)) ? errors : {}
          }
          labelKey="fund.selectFund.offset"
          handleBlur={handleBlur}
        />
      )}
      <NewFundModal
        open={newFundOpen}
        onClose={closeNewFund}
        onSave={newFundSave()}
        loadingCreate={loadingFundCreate}
        banks={banks || []}
        initialNewFund={initialNewFund}
        backendErrors={selectBackendToFrontendErrors(
          createFundError?.response?.data?.errors,
        )}
      />
    </>
  );
}

OffsetFund.propTypes = {
  classes: objectOf(string).isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  setFieldValue: func.isRequired,
  handleBlur: func.isRequired,
  submitCount: number.isRequired,
  isEditor: bool.isRequired,
};

export default withStyles(styles)(OffsetFund);
