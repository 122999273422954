export default function styles(theme) {
  return {
    modal: {
      maxWidth: 700,
    },
    content: {
      marginBottom: 20,
    },
    optionDescription: {
      color: theme.palette.text.subdued,
      marginLeft: 32,
    },
  };
}
