import { bool, func, objectOf, string } from 'prop-types';
import { withStyles, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Modal from '../../Modal';
import PrimaryButton from '../../PrimaryButton';

import styles from './styles';

export function WarningMessageModal({ classes, open, onClose, onConfirm }) {
  const { t } = useTranslation();

  return (
    <Modal
      classes={{ ...classes, closeIcon: undefined }}
      open={open}
      onClose={onClose}
    >
      <Modal.Title className={classes.title}>
        {t('tile.memoLine.modal.title')}
      </Modal.Title>
      <Modal.Content>
        <Typography>{t('tile.memoLine.modal.description')}</Typography>
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <Button onClick={onClose} size="large" color="primary">
          {t('tile.memoLine.modal.cancel')}
        </Button>
        <PrimaryButton size="large" onClick={onConfirm}>
          {t('tile.memoLine.modal.continue')}
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
}

WarningMessageModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
};

export default withStyles(styles)(WarningMessageModal);
