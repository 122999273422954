import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import CreditCardPaymentSummary from './CreditCardPaymentSummary';
// import ACHPaymentSummary from './ACHPaymentSummary';

const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '0 auto 40px auto',
    flexDirection: 'column',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '6px',
  },
  cardTitle: {
    marginBottom: '12px',
  },
});

function PaymentMethodSummary(props) {
  const { classes, paymentMethod } = props;
  const { t } = useTranslation();

  return (
    paymentMethod && (
      <div className={classes.container}>
        <div className={classes.cardContainer}>
          <Typography variant="body2" className={classes.cardTitle}>
            {t('paymentMethodSummary.paymentMethod')}{' '}
          </Typography>

          <Typography>{t('basket.change')}</Typography>
        </div>
        <div className={classes.cardContainer}>
          {/* {paymentMethod.cardType && (
            <CreditCardPaymentSummary
              cardType={paymentMethod.cardType}
              last4={paymentMethod.last4}
            />
          )} */}
          {/* {paymentMethod.accountType && (
            <ACHPaymentSummary
              accountType={paymentMethod.accountType}
              last4={paymentMethod.last4}
            />
          )} */}
        </div>
      </div>
    )
  );
}

PaymentMethodSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  paymentMethod: PropTypes.object.isRequired,
};

export default withStyles(styles)(PaymentMethodSummary);
