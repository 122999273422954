import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useGetPage } from '../useGetPage/useGetPage';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';
import VancoMobileGroupsService from '../../../api/VancoMobileGroupsService';
import { handleException } from '../../../store/alerts/actions';

async function getMobileGroup({ queryKey }) {
  const [, id] = queryKey;
  const response = await VancoMobileGroupsService.getGroup(id);
  return response.data.data;
}

export function useGetMobilePageGroup(groupId, config = {}) {
  const dispatch = useDispatch();
  const { page, isLoading } = useGetPage(CHANNEL_TYPE.MOBILE, PAGE_ACTIVE);
  const { data: group, status, isLoading: groupIsLoading, ...rest } = useQuery({
    queryKey: ['mobile-groups', groupId],
    queryFn: getMobileGroup,
    enabled: !!page.id,
    onError: error => dispatch(handleException(error)),
    ...config,
  });

  return {
    ...rest,
    group,
    isLoading: isLoading || groupIsLoading,
    status,
  };
}

export default useGetMobilePageGroup;
