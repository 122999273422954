import { connect } from 'react-redux';
import CommunicationEmailTemplate from './CommunicationEmailTemplate';

export function mapStateToProps(state) {
  return {
    organizationName: state.user?.user?.organizationName,
    userFirstname: state.user?.session?.idTokenPayload?.given_name,
  };
}

export default connect(mapStateToProps)(CommunicationEmailTemplate);
