import { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button, Typography } from '@material-ui/core';
import { FastField } from 'formik';
import { useTranslation } from 'react-i18next';
import FormikCheckboxWithLink from '../../FormikCheckboxWithLink';
import Modal from '../../Modal';
import styles from './styles';

export function NachaConfirmation(props) {
  const { name, classes, errors, touched, setFieldValue, setFieldTouched } =
    props;
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <FastField
        name={name}
        component={FormikCheckboxWithLink}
        linkText={t('transactions.nacha.linkText')}
        onLinkClick={openModal}
        label={t('transactions.nacha.label')}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
      <Modal open={isOpen} onClose={closeModal}>
        <Modal.Title className={classes.title}>
          {t('transactions.nacha.modalTitle')}
        </Modal.Title>
        <Modal.Content className={classes.content}>
          <Typography>{t('transactions.nacha.modalText1')}</Typography>
          <Typography>{t('transactions.nacha.modalText2')}</Typography>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={classes.submitButton}
            color="primary"
            variant="contained"
            onClick={closeModal}
          >
            {t('transactions.nacha.modalButtonText')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

NachaConfirmation.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

NachaConfirmation.defaultProps = {};

export default withStyles(styles)(NachaConfirmation);
