import { bool, func, objectOf, string, oneOf } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import PrimaryButton from '../PrimaryButton';

import styles from './styles';
import { VARIANTS } from '../../../globals/constants';

export function PageStatusModal({
  classes,
  open,
  onClose,
  onAccept,
  disabled,
  variant,
}) {
  const { t } = useTranslation();

  const hasPublishVerbiage = [VARIANTS.PUBLISH, VARIANTS.UNPUBLISH].includes(
    variant,
  );

  const offVerbiage = hasPublishVerbiage ? '.off' : '';
  const title = t(`site.modal.${variant}.title${offVerbiage}`);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title className={classes.title}>{title}</Modal.Title>
      <Modal.Content className={classes.content}>
        <Typography>
          {t(`site.modal.${variant}.description${offVerbiage}`)}
        </Typography>
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <Button
          onClick={onClose}
          size="large"
          color="primary"
          variant="text"
          className={classes.closeButton}
          disabled={disabled}
        >
          {t('button.cancel')}
        </Button>
        <PrimaryButton
          size="large"
          data-testid="confirm-button"
          onClick={onAccept}
          disabled={disabled}
        >
          {t(`site.modal.${variant}.button${offVerbiage}`)}
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
}

PageStatusModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func.isRequired,
  onAccept: func.isRequired,
  disabled: bool,
  variant: oneOf(['archive', 'unarchive', 'publish', 'unpublish']).isRequired,
};

PageStatusModal.defaultProps = {
  open: false,
  disabled: false,
};

export default withStyles(styles)(PageStatusModal);
