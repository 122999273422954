import * as Yup from 'yup';
import i18n from '../../../utils/i18n';
/* eslint-disable import/prefer-default-export */

export const createSchema = () => {
  return Yup.object().shape({
    userName: Yup.string()
      .email(i18n.t('users.addUser.email.valdiation'))
      .required(i18n.t('users.addUser.email.valdiation')),
  });
};
