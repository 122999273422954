export default function styles(theme) {
  return {
    content: {
      display: 'flex',
      flex: 1,
      marginTop: 30,
      marginBottom: 20,
    },
    visibility: {
      marginTop: 30,
      marginBottom: 20,
      maxWidth: 600,
      paddingRight: 40,
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
      },
      '& p': {
        marginTop: 8,
      },
    },
  };
}
