/* eslint-disable import/no-extraneous-dependencies */
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import TelemetryService from 'api/TelemetryService';
import Router from './components/navigation/Router';
import { theme } from './globals';
import configureStore from './store';
import InterceptorService from './api/InterceptorService';

LogRocket.init('tnu1el/myvanco', {
  dom: { inputSanitizer: true },
});
setupLogRocketReact(LogRocket);

const store = configureStore();
const queryClient = new QueryClient();
InterceptorService.addInterceptors(store);

TelemetryService.initialize();

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback="">
          <CssBaseline />
          <MuiThemeProvider theme={theme}>
            <AppInsightsContext.Provider value={TelemetryService.reactPlugin}>
              <Router />
            </AppInsightsContext.Provider>
          </MuiThemeProvider>
        </Suspense>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
