import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Select,
  MenuItem,
  Box,
  Collapse,
} from '@material-ui/core';
import useStyles from './styles';

function FeatureSection({
  header,
  subsections,
  onAccessChange,
  onHeaderAccessChange,
  headerValue,
}) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const formatHeader = (titleName) => {
    return titleName.replace(/_/g, ' ');
  };

  useEffect(() => {
    setIsExpanded(headerValue === 'Custom');
  }, [headerValue]);

  const getSelectedPermission = (permissions) => {
    if (!permissions?.length) return 'None';
    const enabledPermissions = permissions.filter((p) => p.enabled);
    if (!enabledPermissions.length) return 'None';
    return enabledPermissions.length === 1
      ? enabledPermissions[0].type
      : enabledPermissions.reduce((prev, current) =>
          prev.id > current.id ? prev : current,
        ).type;
  };

  const handleSelectChange = (subsectionName, event) => {
    const newValue = event.target.value;
    onAccessChange(subsectionName, newValue);
  };

  const handleHeaderSelectChange = (event) => {
    onHeaderAccessChange(event.target.value);
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="permissions table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header} colSpan={2}>
              <Box className={classes.headerContent}>
                <Typography
                  variant="h6"
                  className={`${classes.title} ${classes.isbold}`}
                >
                  {formatHeader(header)}
                </Typography>
                <Box className={classes.headerSelectContainer}>
                  <Select
                    value={headerValue}
                    onChange={handleHeaderSelectChange}
                    className={classes.select}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="View">View</MenuItem>
                    <MenuItem value="Edit">Edit</MenuItem>
                    <MenuItem value="Custom">Custom</MenuItem>
                  </Select>
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.collapseCell} colSpan={2}>
              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Table>
                  <TableBody>
                    {subsections.map((subsection) => (
                      <TableRow key={subsection.name} className={classes.row}>
                        <TableCell className={classes.permissionCell}>
                          <Typography variant="h6" className={classes.rowTitle}>
                            {formatHeader(subsection.name)}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.accessCell}>
                          <Select
                            value={getSelectedPermission(
                              subsection.permissions,
                            )}
                            onChange={(event) =>
                              handleSelectChange(subsection.name, event)
                            }
                            className={classes.select}
                          >
                            <MenuItem value="None">None</MenuItem>
                            <MenuItem value="View">View</MenuItem>
                            <MenuItem value="Edit">Edit</MenuItem>
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

FeatureSection.propTypes = {
  header: PropTypes.string.isRequired,
  subsections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          type: PropTypes.string.isRequired,
          enabled: PropTypes.bool.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  onAccessChange: PropTypes.func.isRequired,
  onHeaderAccessChange: PropTypes.func.isRequired,
  headerValue: PropTypes.string.isRequired,
};

export default React.memo(FeatureSection);
