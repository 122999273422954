import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles((theme) => ({
  titlePremiumF: {
    textAlign: 'center',
    marginTop: '40px',
    marginBottom: '31px',
    fontSize: 20,
  },
  title: {
    textAlign: 'center',
    width: '100%',
    display: 'inline-block',
    textDecoration: 'underline',
    fontSize: 24,
    marginBottom: '30px',
  },
  subtitle: {
    textAlign: 'center',
    width: '100%',
    display: 'inline-block',
    fontSize: 20,
  },
  pageDescription: {
    textAlign: 'center',
    fontSize: 20,
  },
  buttonContainer: {
    textAlign: 'center',
  },
  button: {
    padding: '13px 30px 13px 30px',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '1.25',
    background: theme.palette.data,
    color: theme.palette.white,
    borderRadius: '10px',
    fontFamily: '"Muli", sans-serif',
  },
  imageContainer: {
    textAlign: 'center',
    width: '100%',
    display: 'inline-block',
  },
  image: {
    width: '50%',
    marginTop: '40px',
  },
  vancoContact: {
    color: 'blue',
    fontSize: 20,
  },
}));
