export default function styles() {
  return {
    wizardButtons: {
      display: 'inline-block',
      marginTop: 40,
    },
    section: {
      width: '100%',
      marginTop: 20,
    },
    button: {
      height: 40,
    },
    previousButton: {
      marginLeft: 20,
    },
  };
}
