import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import PagePaper from '../PagePaper';
import PageTitle from '../PageTitle';
import PageDescription from '../PageDescription';
import Form from './Form';
import CustomFormik from '../CustomFormik';
import { createSchema } from './validation';

export function TileMemo({
  pageTitleKey,
  goToNext,
  goToPrev,
  loadingTile,
  loadingSave,
  saveTile,
  updateTile,
  setError,
  tile,
  newTile,
  isFinished,
}) {
  const { t } = useTranslation();
  const schema = createSchema();

  useEffect(() => {
    if (newTile.id && goToNext) {
      goToNext();
    }
  }, [newTile, goToNext]);

  function handleUpdate(key, value) {
    if (key === 'required') {
      updateTile('questions', [{ ...tile.questions[0], required: value }]);
    } else if (key === 'prompt') {
      updateTile('questions', [{ ...tile.questions[0], prompt: value }]);
    } else {
      updateTile(key, value);
    }
  }

  function renderForm() {
    if (loadingTile || !tile) {
      return <CircularProgress color="primary" />;
    }

    const onSubmit = values => {
      const formTile = {
        ...tile,
        includeQuestion: values.includeQuestion,
        questions: values.includeQuestion
          ? [{ prompt: values.prompt, required: values.required }]
          : [],
      };
      saveTile(formTile);
    };

    return (
      <CustomFormik
        onChange={updateTile ? handleUpdate : null}
        onErrorsChange={setError}
        initialValues={{
          includeQuestion: tile.includeQuestion || false,
          prompt: tile.questions[0] ? tile.questions[0].prompt : '',
          required: tile.questions[0] ? tile.questions[0].required : false,
        }}
        onSubmit={onSubmit}
        render={formikProps => (
          <Form
            goToPrev={goToPrev}
            loading={loadingSave}
            isFinished={isFinished}
            {...formikProps}
          />
        )}
        validationSchema={schema}
      />
    );
  }

  return (
    <PagePaper>
      <PageTitle>{t(pageTitleKey)}</PageTitle>
      <PageDescription>{t('memo.description')}</PageDescription>
      {renderForm()}
    </PagePaper>
  );
}

TileMemo.propTypes = {
  pageTitleKey: PropTypes.string,
  goToNext: PropTypes.func,
  goToPrev: PropTypes.func,
  loadingTile: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  saveTile: PropTypes.func.isRequired,
  updateTile: PropTypes.func,
  setError: PropTypes.func,
  tile: PropTypes.object,
  newTile: PropTypes.object,
  isFinished: PropTypes.bool,
};

TileMemo.defaultProps = {
  pageTitleKey: 'tile.memo',
  goToNext: null,
  goToPrev: null,
  updateTile: null,
  setError: null,
  tile: null,
  newTile: null,
  isFinished: false,
};

export default TileMemo;
