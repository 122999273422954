export default function styles(theme) {
  return {
    primaryColor: {
      color: theme.palette.primary.main,
    },
    errorColor: {
      color: '#C82947',
    },
    unselectedControl: {
      color: '#CCCCCC',
    },
    radioFirstLine: {
      display: 'inline',
    },
    radioSecondLine: {
      fontSize: '1.4rem',
      color: '#444444',
    },
    formControl: {
      display: 'flex',
    },
    title: {
      marginTop: 30,
      marginBottom: 20,
    },
    borderTop: {
      borderTop: '1px solid #DDDDDD',
      borderLeft: '1px solid #DDDDDD',
      borderRight: '1px solid #DDDDDD',
      borderRadius: '4px 4px 0px 0px',
    },
    border: {
      border: '1px solid #DDDDDD',
      borderRadius: '0px 0px 4px 4px',
    },
    visibilityGroup: {
      borderTop: '1px solid #DDDDDD',
      borderLeft: '1px solid #DDDDDD',
      borderRight: '1px solid #DDDDDD',
      backgroundColor: '#F9F9FB',
    },
    secondaryRadioControl: {
      alignItems: 'baseline',
      margin: '0px 19px 0px 27px',
      '&:last-of-type': {
        marginBottom: 16,
      },
      '& .MuiButtonBase-root': {
        paddingTop: 0,
      },
    },
    descriptionMargin: {
      marginLeft: 4,
    },
    maxWidth: {
      maxWidth: 600,
      paddingRight: 40,
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
      },
    },
    marginMainRadio: {
      margin: '15px 15px 15px 12px',
    },
    popover: {
      pointerEvents: 'none',
      position: 'absolute',
    },
    popoverContent: {
      borderRadius: 4,
      backgroundColor: '#FFFFFF',
      width: 210,
      padding: '12px 16px',
      '& p': {
        fontSize: '1.2rem',
        fontWeight: 600,
        textAlign: 'left',
      },
    },
    channelName: {
      color: '#666666',
      display: 'block',
      margin: '25px 0 18px 40px',
      fontWeight: 700,
      fontSize: 12,
    },
  };
}
