import { bool, func, objectOf, string, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { Modal } from '../..';
import CustomFormik from '../../CustomFormik';
import DisableStreamForm from './Form';
import { createSchema } from './validation';

import styles from './styles';
import { useFeatures } from '../../../../utils/hooks';
import { getDisableDate } from '../../../../utils/date/timeZoneHelper';

export function DisableStreamModal({
  setSuccess,
  setStreamingDisableDate,
  classes,
  open,
  onClose,
  organizationId,
  streamingDisableDate,
  smartLibraryDisableDate,
  smartClipsDisableDate,
}) {
  const { t } = useTranslation();

  const {
    VancoVideo: ffVancoVideo,
    SmartLibrary: ffSmartLibrary,
    SmartClips: ffSmartClips,
  } = useFeatures();

  const videoTitle = t(`${ffVancoVideo ? 'video' : 'live'}Title`);
  const videoTitleHeader = t(
    `${ffSmartLibrary || ffSmartClips ? 'smartVideo' : 'vancoVideo'}Title`,
  );

  const schema = createSchema({ ffSmartLibrary, ffSmartClips });

  const onSubmit = async (values) => {
    const { streamingDisableDate: streamingDate } = values;

    /**
     * At this time, dates are moment objects that should be send as
     * local dates.
     */
    const patchStream = {
      streamingDisableDate: getDisableDate(streamingDate, null),
      smartLibraryDisableDate: getDisableDate(
        values.smartLibraryDisableDate,
        null,
      ),
      smartClipsDisableDate: getDisableDate(values.smartClipsDisableDate, null),
      isDisableProcessed: false,
    };

    const notified = await setStreamingDisableDate(organizationId, patchStream);

    if (notified) {
      const successMessage = streamingDate
        ? `${t('disableStreamModal.disableSuccess', {
            title: videoTitle,
          })} ${streamingDate.format('MM/DD/YYYY')}`
        : t('site.changes.saved');
      setSuccess(successMessage);
      onClose();
    }
  };

  const close = (resetForm, isSubmitting) => () => {
    if (!isSubmitting) {
      onClose();
      resetForm();
    }
  };

  return (
    <CustomFormik
      initialValues={{
        enableStreamSelected: !streamingDisableDate,
        streamingDisableDate: streamingDisableDate || undefined,
        enableLibrarySelected: !smartLibraryDisableDate,
        smartLibraryDisableDate: smartLibraryDisableDate || undefined,
        enableClipSelected: !smartClipsDisableDate,
        smartClipsDisableDate: smartClipsDisableDate || undefined,
      }}
      enableReinitialize
      onSubmit={onSubmit}
      render={(formikProps) => (
        <Form>
          <Modal
            open={open}
            onClose={close(formikProps.resetForm, formikProps.isSubmitting)}
          >
            <Modal.Title className={classes.title}>
              {t('disableStreamModal.title', {
                title: videoTitleHeader,
              })}
            </Modal.Title>
            <DisableStreamForm
              handleSubmit={onSubmit}
              handleClose={close(
                formikProps.resetForm,
                formikProps.isSubmitting,
              )}
              {...formikProps}
            />
          </Modal>
        </Form>
      )}
      validationSchema={schema}
    />
  );
}

DisableStreamModal.propTypes = {
  setSuccess: func.isRequired,
  setStreamingDisableDate: func.isRequired,
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func.isRequired,
  organizationId: string.isRequired,
  streamingDisableDate: object,
  smartLibraryDisableDate: object,
  smartClipsDisableDate: object,
};

DisableStreamModal.defaultProps = {
  open: false,
  streamingDisableDate: undefined,
  smartLibraryDisableDate: undefined,
  smartClipsDisableDate: undefined,
};

export default withStyles(styles)(DisableStreamModal);
