import { node, bool } from 'prop-types';
import clxs from 'clsx';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { useCardContext } from '../context';

function CardListItem({ children, disabled, ...rest }) {
  const { classes } = useCardContext();
  const listItemClasses = clxs({
    [classes.cardListItem]: !disabled,
    [classes.cardListItemDisabled]: disabled,
  });

  return (
    <li className={listItemClasses} {...rest}>
      <Typography className={classes.cardListItemText} component="span">
        {children}
      </Typography>
      {!disabled && <ArrowForwardIosIcon className={classes.fowardIcon} />}
    </li>
  );
}

CardListItem.propTypes = {
  children: node,
  disabled: bool,
};

CardListItem.defaultProps = {
  children: null,
  disabled: false,
};

export default CardListItem;
