import { useRef, useState } from 'react';
import { objectOf, string } from 'prop-types';
import { useField } from 'formik';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { isValidEmail } from '../../../utils/validations';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import styles from './styles';

export function MultipleEmailSelector({ classes, name }) {
  const [field, , helpers] = useField(name);
  const [email, setEmail] = useState('');
  const containerRef = useRef();
  const emialInput = useRef();
  const onContainerClick = () => emialInput.current.focus();
  const onEmailClick = e => e.stopPropagation();

  const scrollToBottom = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop =
          containerRef.current.scrollHeight - containerRef.current.clientHeight;
      }
    }, 0);
  };

  const setNewEmail = () => {
    if (email.length > 0) {
      helpers.setValue(
        (field.value || []).concat([{ email, isValid: isValidEmail(email) }]),
      );
      setEmail('');
      scrollToBottom();
    }
  };

  const onChange = e => {
    const {
      target: { value },
    } = e;
    const emailsChunks = (value || '').split(/[\s,;]+/);
    if (emailsChunks.length > 1) {
      const newEmails = [];
      emailsChunks.forEach(em => {
        if (em) {
          newEmails.push({ email: em, isValid: isValidEmail(em) });
        }
      });
      helpers.setValue((field.value || []).concat(newEmails));
      setEmail('');
      scrollToBottom();
    } else {
      setEmail(value);
    }
  };

  const onKeyDown = e => {
    const code = e.keyCode;
    if (code === 13) {
      e.preventDefault();
      setNewEmail();
    }
  };

  const onBlur = () => {
    setNewEmail();
  };

  const onRemove = emailToRemoveIndex => () => {
    helpers.setValue(
      (field.value || []).filter((e, index) => index !== emailToRemoveIndex),
    );
  };

  return (
    <div
      className={classes.container}
      onClick={onContainerClick}
      ref={containerRef}
    >
      {(field.value || []).map((e, i) => (
        <div
          className={clsx({
            [classes.emailBadge]: true,
            [classes.emailBadgeError]: !e.isValid,
          })}
          onClick={onEmailClick}
          // eslint-disable-next-line react/no-array-index-key
          key={`${e.email}-${i}`}
        >
          <div className={classes.emailText}>
            {!e.isValid && (
              <ErrorOutlineIcon
                data-testid={`error-icon-${i}`}
                className={classes.errorIcon}
              />
            )}
            {e.email}
          </div>
          <CancelIcon
            className={classes.remove}
            onClick={onRemove(i)}
            data-testid={`remove-button-${i}`}
          />
        </div>
      ))}
      <input
        className={classes.input}
        type="text"
        ref={emialInput}
        value={email}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        data-testid="multiple-email-input"
      />
    </div>
  );
}

MultipleEmailSelector.propTypes = {
  classes: objectOf(string).isRequired,
  name: string.isRequired,
};

export default withStyles(styles)(MultipleEmailSelector);
