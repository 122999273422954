/* eslint-disable import/prefer-default-export */
/**
 *
 * Converts an object to query param string
 * @param {object} obj: Object
 * @returns string containing object in query params
 */
export const parseObjectToQueryParams = (obj = {}) => {
  if (typeof obj !== 'object' || !obj) {
    return '';
  }
  return new URLSearchParams(obj).toString();
};

/**
 * Converts the first letter of a string to UpperCase
 * @param {string} str: A string
 * @returns the same string with the first letter in upper case
 */
export const firstLetterToUpperCase = (str = '') => {
  if (!str) {
    return str;
  }
  // Check if the string contains only one letter
  if (str.length < 1) {
    return str.toUpperCase();
  }
  return str[0].toUpperCase() + str.slice(1, str.length);
};

/**
 * Converts all object keys from snake case (snake_case) to camel case (camelCase)
 * @param {object} obj: Object or array of objects
 * @returns the same object with all the keys converted to camelCase
 */
export const objectKeysToCamelCase = (obj) => {
  if (!obj) {
    return null;
  }

  if (Array.isArray(obj)) {
    return obj.map(objectKeysToCamelCase);
  }

  if (typeof obj !== 'object') {
    return obj;
  }

  const newObject = {};

  const objKeys = Object.keys(obj);

  objKeys.forEach((key) => {
    const newKey = key
      .split('_')
      .map((word, index) => (index === 0 ? word : firstLetterToUpperCase(word)))
      .join('');
    newObject[newKey] = objectKeysToCamelCase(obj[key]);
  });

  return newObject;
};
