import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export class TelemetryService {
  static reactPlugin;

  static appInsights;

  static notLocalEnvironment = null;

  constructor() {
    this.notLocalEnvironment =
      window.location.hostname !== 'localhost' &&
      window.location.hostname !== '127.0.0.1';
  }

  initialize = () => {
    this.reactPlugin = new ReactPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: `InstrumentationKey=${window.env.REACT_APP_TELEMETRY_INSTRUMENTATION_KEY}`,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        extensions: [this.reactPlugin],
        enableCorsCorrelation: true,
        /**
         * Add any third party service domain which provokes a CORS error.
         * App insights automatically injects a "Traceparent" header to the requests
         * which can provoke a error if it is not part of the service Access-Control-Allow-Headers list.
         */
        correlationHeaderExcludedDomains: [
          'r.logr-ingest.com',
          'fast.appcues.com',
        ],
        /**
         * By default App Insights will log every tieme there is a request, so as LogRocket
         * does several requests we should ommit auto tracking for that domain.
         */
        excludeRequestFromAutoTrackingPatterns: ['r.logr-ingest.com'],
      },
    });

    if (this.notLocalEnvironment) {
      this.appInsights.loadAppInsights();
    } else {
      // eslint-disable-next-line no-console
      console.info(
        'Telemetry to App is turned off because you are using localhost',
      );
    }
  };

  handleTelemetryException = (ex) =>
    this.reactPlugin && this.reactPlugin.trackException(ex);
}

export default new TelemetryService();
