export default function styles(theme) {
  return {
    tzFormControl: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
    },
    optionBorder: {
      borderBottom: '1px solid #DEE1E3',
    },
    menuFix: {
      height: '500px',
      maxHeight: '500px',
    },
    iconStyle: {
      border: '1px solid #DEE1E3',
      borderRight: 'unset',
      display: 'flex',
      width: '40px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    removeSelectedBackground: {
      '&:not(:hover)': {
        backgroundColor: 'transparent !important',
      },
    },
    rightAlignIcon: {
      marginLeft: 'auto',
    },
    t1: {
      // borderRight:'1px solid blue',
      // borderRight: 'none',
      '& fieldset': {
        border: '1px solid red',
      },
    },
  };
}
