import { NAVBAR_HEIGHT } from '../../../globals/dimensions';

export default function styles(theme) {
  return {
    progressBar: {
      position: 'absolute',
      top: NAVBAR_HEIGHT,
      left: 0,
      right: 0,
      zIndex: theme.zIndex.appProgressBar,
    },
  };
}
