const hexeaScriptElementId = 'hexea-script';

const removeScriptIfExists = () => {
  const script = document.getElementById(hexeaScriptElementId);
  const iFrame = document.getElementsByName('hexeaSecureFrame1');

  if (script) {
    document.head.removeChild(script);
  }
  if (iFrame?.length > 0) {
    iFrame.forEach((frame) => document.body.removeChild(frame));
  }
};

/**
 * Creates a script tag to fetch the hexea code dynamically. This is required to support fetching different hexea sources for each environment.
 * We can import hexeaPromise in our other files and await its resolution in order to ensure we've properly loaded hexea
 * @returns {Promise<unknown>} The Promise that will resolve into the hexea object/function
 */
const hexeaPromise = () =>
  new Promise((resolve) => {
    removeScriptIfExists();

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = hexeaScriptElementId;
    script.onload = () => {
      resolve(window.Hexea);
    };
    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(script);
    script.src = window.env.REACT_APP_CXP_URL;
  });

export default hexeaPromise;
