import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import ExternalLink from '../ExternalLink';
import OutlinedTextField from '../OutlinedTextField';
import styles from './styles';

export function SiteDialog({ classes, open, onClose, page }) {
  const { t } = useTranslation();

  return (
    <Dialog classes={{ paper: classes.dialog }} open={open} onClose={onClose}>
      <DialogTitle classes={{ root: classes.title }} disableTypography>
        <Typography variant="h2">
          {t('wizard.congratulations.title')}
        </Typography>
        <Button className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
          <Typography variant="body1" className={classes.closeText}>
            {t('app.close')}
          </Typography>
        </Button>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Typography>{t('wizard.congratulations.description')}</Typography>
        <Typography className={classes.label}>
          {t('wizard.websiteUrl')}
        </Typography>
        <OutlinedTextField
          className={classes.textField}
          label={t('wizard.websiteUrl')}
          value={page.url}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <ExternalLink to={page.url}>
          <Button variant="outlined" color="primary">
            {t('wizard.previewLive')}
          </Button>
        </ExternalLink>
      </DialogActions>
    </Dialog>
  );
}

SiteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
};

export default withStyles(styles)(SiteDialog);
