import { string, objectOf, node, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

export function FileInput(props) {
  const {
    classes,
    id,
    icon,
    label,
    value,
    loading,
    disabled,
    ...inputProps
  } = props;
  return (
    <label
      htmlFor={id}
      className={disabled ? classes.labelDisabled : classes.label}
    >
      {loading && (
        <CircularProgress data-testid="file-input-spinner" size={22} />
      )}
      {!loading && icon}
      <span className={classes.text}>{value || label}</span>
      <input
        id={id}
        className={classes.input}
        type="file"
        {...inputProps}
        value={value}
        disabled={disabled}
        data-testid="file-input-element"
      />
    </label>
  );
}

FileInput.propTypes = {
  classes: objectOf(string).isRequired,
  id: string.isRequired,
  icon: node,
  label: node.isRequired,
  value: string,
  loading: bool,
  disabled: bool,
};

FileInput.defaultProps = {
  icon: null,
  value: '',
  loading: false,
  disabled: false,
};

export default withStyles(styles)(FileInput);
