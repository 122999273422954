import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOrganization } from '../../../store/user/actions';

export function useSelectOrganizationModal() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const onSelectOrganization = (orgId) => () => {
    closeModal();
    dispatch(setOrganization(orgId, true));
  };

  return {
    isOpen,
    openModal,
    closeModal,
    onSelectOrganization,
  };
}

export default useSelectOrganizationModal;
