import * as Yup from 'yup';
import { validations } from '../../../../utils';
import i18n from '../../../../utils/i18n';

export const maxLengths = {
  email: 45,
};

export const createSchema = () => {
  return Yup.object().shape({
    dbaName: Yup.string().required(
      i18n.t('validation.required', {
        requiredNoun: i18n
          .t('organization.contact.organizationName')
          .toLocaleLowerCase(),
      }),
    ),
    email: Yup.string().nullable().email(i18n.t('validation.email')),
    website: Yup.string()
      .nullable()
      .matches(
        validations.urlRegex,
        i18n.t('validation.url', {
          requiredNoun: i18n.t('headerManagement.homePage'),
        }),
      ),
  });
};
