import BaseService from '../BaseService';

class BankService extends BaseService {
  // eslint-disable-next-line class-methods-use-this
  getBanksUrl = organizationId => {
    return `organizations/${organizationId}/bankaccounts`;
  };

  getBanks(organizationId) {
    return this.http.get(this.getBanksUrl(organizationId));
  }
}

export default new BankService();
