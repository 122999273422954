export default function styles(theme) {
  return {
    paper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      overflow: 'hidden',
      width: 417,
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        width: '100%',
      },
      height: 515,
      '&:hover > $editOverlay': {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      '&$moving': {
        userSelect: 'none',
        cursor: 'grab',
      },
    },
    content: {
      display: 'flex',
      flexShrink: 1,
      alignItems: 'flex-start',
      flexDirection: 'column',
      paddingLeft: 25,
      paddingRight: 25,
      paddingTop: 17,
      paddingBottom: 16,
      height: '100%',
      overflow: 'hidden',
    },
    title: {
      marginBottom: 9,
    },
    body: {
      flexShrink: 1,
      marginBottom: 15,
      height: '100%',
      overflow: 'hidden',
    },
    image: {
      flexShrink: 0,
      display: 'block',
      maxWidth: 417,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
      height: 257,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundColor: theme.palette.secondary.light,
    },
    editOverlay: {
      display: 'none',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      cursor: 'pointer',
      backgroundColor: 'white',
      opacity: 0.95,
    },
    editImage: {
      marginBottom: 21,
    },
    moving: {},
  };
}
