import { Form } from 'formik';
import { object, func, objectOf, string, bool } from 'prop-types';
import { withStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import PrimaryButton from '../../PrimaryButton';
import FeeDonationInputs from '../../FeeDonationInputs';

import styles from './styles';

export function EditProcessingFeesForm({
  classes,
  values,
  errors,
  setFieldValue,
  touched,
  handleBlur,
  loading,
  dirty,
  onCancel,
}) {
  const { t } = useTranslation();
  return (
    <Form>
      <Typography className={classes.subtitle} variant="h5">
        {t('vanco.mobile.setup.fees.question')}
      </Typography>
      <FeeDonationInputs
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
      />
      <Grid item className={classes.buttonsContainer}>
        {!loading ? (
          <>
            <Button onClick={onCancel} color="primary" size="large">
              {t('button.cancel')}
            </Button>
            <PrimaryButton
              size="large"
              type="submit"
              disabled={!dirty || Object.keys(errors || {}).length > 0}
            >
              {t('button.save')}
            </PrimaryButton>
          </>
        ) : (
          <CircularProgress
            color="primary"
            data-testid="mobile-setup-spinner"
          />
        )}
      </Grid>
    </Form>
  );
}

EditProcessingFeesForm.propTypes = {
  classes: objectOf(string).isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  setFieldValue: func.isRequired,
  touched: object.isRequired,
  handleBlur: func.isRequired,
  loading: bool.isRequired,
  dirty: bool.isRequired,
  onCancel: func,
};

EditProcessingFeesForm.defaultProps = {
  onCancel: undefined,
};

export default withStyles(styles)(EditProcessingFeesForm);
