export default function styles(theme) {
  return {
    container: {
      alignItems: 'center',
      background: '#FFFFFF',
      border: '1px solid #E6E6E6',
      borderRadius: 4,
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.07)',
      cursor: 'grab',
      padding: '20px 22px',
      marginBottom: 16,
    },
    inline: {
      display: 'inline',
    },
    textContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    description: {
      color: '#222222',
      fontWeight: 600,
    },
    drag: {
      color: '#222222',
      marginRight: 22,
    },
    edit: {
      color: '#5E6266',
      cursor: 'pointer',
    },
    remove: {
      '& path': {
        fill: '#5E6266',
      },
      width: 24,
      height: 24,
      cursor: 'pointer',
      marginLeft: theme.spacing(1),
    },
    flex: {
      display: 'flex',
    },
  };
}
