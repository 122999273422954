export default (theme) => ({
  emptyMessage: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#757575DE',
    marginBottom: '15px',
  },
  basketContainer: {
    display: 'contents',
  },
  basket: {
    maxWidth: '600px',
  },
  content: {
    paddingBottom: '25px',
    width: '100%',
    margin: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    basket: {
      width: '88vw',
      margin: 'auto',
      marginTop: '3vh',
      position: 'static',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  [theme.breakpoints.up('md')]: {
    basketContainer: {
      alignSelf: 'baseline',
      marginTop: '5vh',
      maxWidth: '500px',
      minWidth: '300px',
      minHeight: '150px',
      width: '35vw',
      top: '10vh',
      position: 'sticky',
    },
  },
  title: {
    lineHeight: '1.35',
    letterSpacing: '0.4px',
    display: 'flex',
    marginBottom: '20px',
  },
  basketTopBar: {
    marginBottom: '25px',
  },
  basketIcon: {
    marginRight: '10px',
  },
  editLink: {
    lineHeight: '1.23',
    letterSpacing: '0.2px',
    textDecoration: 'none',
  },
  basketItem: {
    width: '80%',
    margin: 'auto',
  },
  buttonContainer: {
    marginTop: '25px',
    marginBottom: '10px',
  },
  checkoutButton: {
    width: '100%',
  },
  itemBreak: {
    borderColor: 'rgba(228, 229, 230, 0.5)',
    marginTop: '20px',
  },
  processingFeesSwitch: {
    marginTop: theme.spacing(2),
    fontSize: '18px',
  },
  paymentsCaptionContainer: {
    marginTop: theme.spacing(2),
    '& svg': {
      fill: theme.typography.caption.color,
      height: '18px',
    },
  },
  captionContainer: {
    marginTop: '22px',
    padding: '0px 24px',
  },
  captionLink: {
    color: theme.palette.primary.main,
  },
  inIframe: {
    boxShadow: 'none',
    border: '1px solid #DEE1E3',
  },
  totalAmount: {
    marginTop: theme.spacing(4),
  },
});
