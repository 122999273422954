import { bool, func, objectOf, string, object, arrayOf } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Grid,
  Typography,
  FormControl,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import styles from './styles';
import OutlinedSelect from '../../OutlinedSelect';

import Tile from '../../Tile';

import PageDivider from '../../PageDivider';

function StreamPageGivingForm(props) {
  const {
    classes,
    setFieldValue,
    handleSubmit,
    values,
    goToPrev,
    loading,
    isFinished,
    handleReset,
    dirty,
    errors,
    touched,
    handleBlur,
    isWizard,
    tiles,
    pages,
    pagesLoading,
    tilesLoading,
    hideDividers,
  } = props;
  const { t } = useTranslation();
  const { tileId, siteId } = values;

  const noActiveSites = !pagesLoading && pages.length === 0;

  const getSelectedTile = () => tiles.find((tile) => tile.id === tileId);
  const tileUnavailable =
    tileId && !tilesLoading && getSelectedTile() === undefined;
  const siteUnavailable =
    siteId && !pagesLoading && pages.find((s) => s.id === siteId) === undefined;
  useEffect(() => {
    const callSetField = async () => {
      setFieldValue({ target: { name: 'siteId', value: pages[0].id } });
    };
    if (!pagesLoading && pages.length === 1 && !siteId) {
      callSetField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesLoading, pages]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">{t('stream.page.giving.title')}</Typography>
        <Typography className={classes.mainDescription}>
          {t('stream.page.giving.description')}
        </Typography>
      </Grid>
      <Grid item className={classes.sectionVertGutter}>
        <Typography className={classes.subtitle}>
          {t('stream.page.giving.online.site')}
        </Typography>
        <Grid item xs={12} className={classes.show}>
          <FormControl fullWidth>
            <OutlinedSelect
              id="siteId"
              name="siteId"
              value={siteId && !siteUnavailable ? siteId : ''}
              label={t('stream.page.giving.select.site')}
              onChange={setFieldValue}
              onBlur={handleBlur}
              error={errors.siteId && touched.siteId}
              inputProps={{
                'data-testid': 'select-site',
              }}
              labelClass={noActiveSites ? classes.labelOverride : ''}
              className={noActiveSites ? classes.errorOverride : ''}
              disabled={noActiveSites}
            >
              {pages.map((s) => {
                return (
                  <MenuItem key={s.id} value={s.id}>
                    <span>{s.pageName}</span>
                  </MenuItem>
                );
              })}
            </OutlinedSelect>
          </FormControl>
          {noActiveSites && (
            <FormHelperText className={classes.helperText}>
              {t('stream.page.giving.online.site.error')}
            </FormHelperText>
          )}
          {siteUnavailable && (
            <FormHelperText className={classes.helperText}>
              {t('stream.page.giving.online.site.error.unavailable')}
            </FormHelperText>
          )}
        </Grid>
      </Grid>

      {tileId && getSelectedTile() && (
        <Grid container>
          <>
            {!hideDividers && (
              <PageDivider variant={isWizard ? 'default' : 'editor'} />
            )}
            <Grid item>
              <Typography variant="h5" className={classes.sectionTitle}>
                {t('stream.page.giving.preview.title')}
              </Typography>
              <Typography>
                {t('stream.page.giving.preview.description')}
              </Typography>

              <section className={classes.content}>
                <Tile tile={getSelectedTile()} />
              </section>
            </Grid>
          </>
        </Grid>
      )}

      {isWizard && !hideDividers && <PageDivider variant="wide" />}

      <Grid item xs={12}>
        {!loading && goToPrev && (
          <WizardButtons
            isFinished={isFinished}
            dirty={dirty}
            goToNext={handleSubmit}
            goToPrev={goToPrev}
            undo={handleReset}
            disableSave={
              !!((!tileId && !siteId) || tileUnavailable || siteUnavailable)
            }
            newWizard
          />
        )}
        {loading && goToPrev && (
          <div className={classes.spinnerContainer}>
            <CircularProgress
              color="primary"
              className={classes.spinner}
              data-testid="giving-spinner"
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
}

StreamPageGivingForm.propTypes = {
  classes: objectOf(string).isRequired,
  handleSubmit: func.isRequired,
  goToPrev: func,
  loading: bool.isRequired,
  isFinished: bool.isRequired,
  handleReset: func.isRequired,
  dirty: bool.isRequired,
  setFieldValue: func.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  handleBlur: func.isRequired,
  isWizard: bool.isRequired,
  tiles: arrayOf(object).isRequired,
  pages: arrayOf(object).isRequired,
  pagesLoading: bool.isRequired,
  tilesLoading: bool.isRequired,
  hideDividers: bool,
};

StreamPageGivingForm.defaultProps = {
  goToPrev: null,
  hideDividers: false,
};

export default withStyles(styles)(StreamPageGivingForm);
