import { shape, bool, func, string, number, objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import OutlinedTextField from '../OutlinedTextField';
import FormHelperTextBottom from '../FormHelperTextBottom';
import styles from './styles';

function VancoMobileContactInputs({
  classes,
  values,
  maxLengths,
  setFieldValue,
  errors,
  touched,
  handleBlur,
}) {
  const { t } = useTranslation();
  const { providePhone, phone, provideEmail, email } = values;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue({ target: { name, value } });
  };

  const handleCheckboxChange = (name) => (e) => {
    setFieldValue({ target: { name, value: e.target.checked } });
  };

  // TODO: Remove with ChannelManagement FF cleanup
  return (
    <>
      <Grid item>
        <FormControlLabel
          classes={{
            root: classes.firstCheckbox,
          }}
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Checkbox
              mame="providePhone"
              id="providePhone"
              color="primary"
              onChange={handleCheckboxChange('providePhone')}
              checked={!!providePhone}
              classes={{
                root: classes.checkbox,
              }}
            />
          }
          label={t('mobileSetup.providePhone')}
        />
      </Grid>
      {providePhone && (
        <Grid item>
          <FormControl fullWidth className={classes.formControl}>
            <OutlinedTextField
              showCount
              id="phone"
              name="phone"
              value={phone}
              label={t('headerManagement.phone')}
              inputProps={{
                maxLength: maxLengths.phone,
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.phone && touched.phone}
              errorMessage={touched.phone && errors.phone}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Checkbox
              mame="provideEmail"
              id="provideEmail"
              color="primary"
              onChange={handleCheckboxChange('provideEmail')}
              checked={!!provideEmail}
              classes={{
                root: classes.checkbox,
              }}
            />
          }
          label={t('mobileSetup.provideEmail')}
        />
      </Grid>
      {provideEmail && (
        <Grid item>
          <FormControl fullWidth className={classes.formControl}>
            <OutlinedTextField
              showCount
              id="email"
              name="email"
              value={email}
              label={t('headerManagement.emailAddress')}
              inputProps={{
                maxLength: maxLengths.email,
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email && touched.email}
              errorMessage={touched.email && errors.email}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item>
        <FormHelperTextBottom left={errors.provideContact} />
      </Grid>
    </>
  );
}

VancoMobileContactInputs.propTypes = {
  classes: objectOf(string).isRequired,
  values: shape({
    providePhone: bool,
    phone: string,
    provideEmail: bool,
    email: string,
  }).isRequired,
  maxLengths: shape({
    email: number,
    phone: number,
  }).isRequired,
  setFieldValue: func.isRequired,
  errors: shape({
    email: string,
    phone: string,
  }).isRequired,
  touched: objectOf(bool).isRequired,
  handleBlur: func.isRequired,
};

export default withStyles(styles)(VancoMobileContactInputs);
