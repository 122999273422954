export default function styles(theme) {
  return {
    subtitle: {
      marginTop: 15,
      marginBottom: 5,
    },
    subtitleTop: {
      marginTop: 5,
    },
    stateContainer: {
      paddingLeft: 20,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
    streamDisclosure: {
      display: 'flex',
    },
    streamDisclosureTitle: {
      display: 'unset',
    },
    streamDisclosureMessage: {
      display: 'unset',
      marginLeft: 'auto',
    },
    streamDisclosureSection: {
      border: '1px solid #BCC0C4',
      borderRadius: theme.spacing(1),
    },
    dateTitle: {
      color: '#212224',
      fontSize: theme.spacing(2),
      lineHeight: '24px',
    },
    dateInput: {
      marginTop: theme.spacing(1),
    },
    sectionVertGutter: {
      marginBottom: theme.spacing(3),
    },
    selectVertGutter: {
      marginBottom: theme.spacing(2),
    },
    radioGroupItem: {
      alignItems: 'baseline',
      marginRight: 0,
    },
    radioGroupLabel: {
      alignSelf: 'flex-start',
      width: '100%',
    },
    radioButton: {
      paddingTop: theme.spacing(2),
    },
    hidden: {
      display: 'none',
    },
    show: {
      display: 'auto',
    },
    errorOverride: {
      backgroundColor: '#FFF4F4',
    },
    labelOverride: {
      background: 'none',
      color: theme.palette.text.subdued,
    },
    timepickerStyle: {
      marginTop: theme.spacing(1),
      '& fieldset': {
        'border-top-right-radius': 0,
        'border-bottom-right-radius': 0,
      },
    },
    timeZoneStyle: {
      marginTop: theme.spacing(1),
      '& fieldset': {
        borderLeft: 'none',
        'border-top-left-radius': 0,
        'border-bottom-left-radius': 0,
      },
      '&:hover': {
        '& fieldset': {
          borderLeft: '1px solid',
        },
      },
    },
    timeContainer: {
      display: 'flex',
      marginBottom: 8,
    },
    repetitionFix: {
      marginLeft: 'initial',
    },
    checkboxColor: {
      color: '#8E9194',
    },
    inputField: {
      marginBottom: 8,
    },
    sectionTitle: {
      marginTop: theme.spacing(3),
    },
    spinnerContainer: {
      marginTop: theme.spacing(2),
    },
    groupPadding: {
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
    embedCodeTextField: {
      minHeight: 60,
    },
    learMoreButton: {
      paddingTop: 3,
    },
  };
}
