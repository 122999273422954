import { useEffect } from 'react';
import { POST_MESSAGE_EVENT_NAME } from '../../../globals/constants';

const useIFrameMessages = ({
  iFrameRef = null,
  origin = '',
  eventsMap = {},
}) => {
  useEffect(() => {
    const postMessageListener = async (event) => {
      if (!iFrameRef?.current) return;

      // Always verify the origin for security purposes
      if (event.source !== iFrameRef.current.contentWindow) return;

      const eventCallback = eventsMap[event.data?.type];
      if (event.origin === origin && eventCallback) {
        await eventCallback();
      }
    };

    // Add event listener
    window.addEventListener(POST_MESSAGE_EVENT_NAME, postMessageListener);

    return () => {
      // Remove event listener
      window.removeEventListener(POST_MESSAGE_EVENT_NAME, postMessageListener);
    };
  }, [eventsMap, iFrameRef, origin]);
};

export default useIFrameMessages;
