import BaseService from '../BaseService';

class VancoMobileEventsService extends BaseService {
  getEvents() {
    return this.http.get('events');
  }

  getEvent(id) {
    return this.http.get(`events/${id}`);
  }
}

export default new VancoMobileEventsService();
