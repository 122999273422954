import i18n from '../../utils/i18n';

function selectFundDuplicateCodeError(errors) {
  return errors.find(e => e.code === '1494');
}

function selectFundDuplicateNameError(errors) {
  return errors.find(e => e.code === '1495');
}

/* Backend errors to front-end formik repersentation of errors */
export const selectBackendToFrontendErrors = errors => {
  if (!errors || !Array.isArray(errors)) return {};

  const accountCode =
    selectFundDuplicateCodeError(errors) &&
    i18n.t('errors.duplicate', {
      requiredNoun: i18n.t('fund.addFund.fundCode').toLowerCase(),
    });
  const name =
    selectFundDuplicateNameError(errors) &&
    i18n.t('errors.duplicate', {
      requiredNoun: i18n.t('fund.addFund.fundName').toLowerCase(),
    });

  return { accountCode: accountCode || '', name: name || '' };
};

export default { selectBackendToFrontendErrors };
