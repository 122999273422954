import { object, func, objectOf, string, bool } from 'prop-types';
import {
  Divider,
  FormControl,
  Radio,
  RadioGroup,
  withStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';

import OutlinedTextField from '../OutlinedTextField';

import styles from './styles';

function AppPermissionsInputs({
  classes,
  values,
  errors,
  maxLengths,
  setFieldValue,
  touched,
  handleBlur,
  isSettingsView,
  features,
}) {
  const { t } = useTranslation();
  const descriptionClass = isSettingsView
    ? classes.descriptionGray
    : classes.description;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'requiresJoinRequest') {
      if (!value) {
        setFieldValue({ target: { name: 'usersCanInvite', value: false } });
      }
    }
    setFieldValue({ target: { name, value: e.target.checked } });
  };

  const handleChangeName = (e) => {
    const { name, value } = e.target;
    setFieldValue({ target: { name, value } });
  };

  const handleChangeCommunity = async (e) => {
    const { name, value } = e.target;
    const boolValue = value === 'true';
    await setFieldValue({
      target: { name, value: boolValue },
    });
  };

  const sizes = isSettingsView ? { xs: 12 } : { xs: 12, sm: 10, md: 9 };

  let allowInvite = 'mobileSetup.appPermissions.allowInvite';
  if (!features.ChannelManagement) {
    allowInvite = isSettingsView
      ? 'mobileSetup.appPermissions.requireAllowApprovalDeprecated'
      : 'mobileSetup.appPermissions.allowInviteDeprecated';
  }

  return (
    <>
      <>
        <Grid item {...sizes}>
          <Typography className={classes.subtitle4} variant="h5">
            {t('mobileSetup.appPermissions.communitySubtitle')}
          </Typography>
          <Typography className={descriptionClass}>
            {t(
              features.ChannelManagement
                ? 'mobileSetup.appPermissions.communityDescription'
                : 'mobileSetup.appPermissions.communityDescriptionDeprecated',
            )}
          </Typography>
        </Grid>
        <Grid item {...sizes}>
          <RadioGroup
            component={RadioGroup}
            className={classes.radioGroup}
            onChange={handleChangeCommunity}
          >
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Radio
                  className={classes.radioButton}
                  color="primary"
                  name="hasCommunityFeatures"
                  checked={!values.hasCommunityFeatures}
                  value="false"
                />
              }
              label={
                /* eslint-disable-next-line react/jsx-wrap-multilines */
                <Typography className={classes.formSubtitle}>
                  {t('mobileSetup.appPermissions.doNotEnableCommunity')}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Radio
                  name="hasCommunityFeatures"
                  className={classes.radioButton}
                  color="primary"
                  checked={values.hasCommunityFeatures}
                  value="true"
                />
              }
              label={
                /* eslint-disable-next-line react/jsx-wrap-multilines */
                <Typography className={classes.formSubtitle}>
                  {t('mobileSetup.appPermissions.yesEnableCommunity')}
                </Typography>
              }
            />
          </RadioGroup>
        </Grid>
        {values.hasCommunityFeatures && (
          <Grid item {...sizes}>
            <Divider className={classes.divider} />
          </Grid>
        )}
      </>
      {values.hasCommunityFeatures && (
        <>
          <Grid item {...sizes}>
            <Typography className={classes.firstSubtitle} variant="h5">
              {t('mobileSetup.appPermissions.approvalSubtitle')}
            </Typography>
            <Typography className={descriptionClass}>
              {t(
                isSettingsView && !features.ChannelManagement
                  ? 'mobileSetup.appPermissions.approvalDescriptionSettingsDeprecated'
                  : 'mobileSetup.appPermissions.approvalDescription',
              )}
            </Typography>
          </Grid>
          <Grid item {...sizes}>
            <FormControl>
              <FormControlLabel
                className={classes.checkMargin}
                control={
                  /* eslint-disable-next-line react/jsx-wrap-multilines */
                  <Checkbox
                    name="requiresJoinRequest"
                    color="primary"
                    checked={values.requiresJoinRequest}
                    value={values.requiresJoinRequest}
                    onChange={handleChange}
                    className={classes.checkColor}
                    inputProps={{
                      'data-testid': 'requiresJoinRequest',
                    }}
                  />
                }
                label={t(
                  features.ChannelManagement
                    ? 'mobileSetup.appPermissions.requireApproval'
                    : 'mobileSetup.appPermissions.requireApprovalDeprecated',
                )}
              />
              {values.requiresJoinRequest && (
                <FormControlLabel
                  className={classes.checkMargin2}
                  control={
                    /* eslint-disable-next-line react/jsx-wrap-multilines */
                    <Checkbox
                      name="usersCanInvite"
                      color="primary"
                      checked={values.usersCanInvite}
                      value={values.usersCanInvite}
                      onChange={handleChange}
                      className={classes.checkColor}
                      inputProps={{
                        'data-testid': 'usersCanInvite',
                      }}
                    />
                  }
                  label={t(allowInvite)}
                />
              )}
            </FormControl>
          </Grid>

          <Grid item {...sizes}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item {...sizes}>
            <Typography variant="h5">
              {t('mobileSetup.appPermissions.events')}
            </Typography>
            <Typography className={descriptionClass}>
              {t('mobileSetup.appPermissions.eventsDescription')}
            </Typography>
          </Grid>
          <Grid item {...sizes}>
            <FormControl className={classes.radioGroup}>
              <FormControlLabel
                className={classes.checkMargin}
                control={
                  /* eslint-disable-next-line react/jsx-wrap-multilines */
                  <Checkbox
                    name="usersCanCreateEvents"
                    color="primary"
                    checked={values.usersCanCreateEvents}
                    value={values.usersCanCreateEvents}
                    onChange={handleChange}
                    className={classes.checkColor}
                    inputProps={{
                      'data-testid': 'usersCanCreateEvents',
                    }}
                  />
                }
                label={t('mobileSetup.appPermissions.allowEvents')}
              />
            </FormControl>
          </Grid>

          <Grid item {...sizes}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item {...sizes}>
            <Typography variant="h5">
              {t('mobileSetup.appPermissions.groupsSubtitle')}
            </Typography>

            <Typography className={descriptionClass}>
              {t('mobileSetup.appPermissions.groupsDescription1')}
            </Typography>
          </Grid>

          <Grid item {...sizes}>
            <Typography className={classes.subtitle2} variant="body2">
              {t('mobileSetup.appPermissions.groupCreation')}
            </Typography>
            <Typography className={descriptionClass}>
              {t('mobileSetup.appPermissions.groupsDescription2')}
            </Typography>
            <FormControl className={classes.radioGroup}>
              <FormControlLabel
                className={classes.checkMargin}
                control={
                  /* eslint-disable-next-line react/jsx-wrap-multilines */
                  <Checkbox
                    name="usersCanCreateSubgroups"
                    color="primary"
                    checked={values.usersCanCreateSubgroups}
                    value={values.usersCanCreateSubgroups}
                    onChange={handleChange}
                    className={classes.checkColor}
                    inputProps={{
                      'data-testid': 'usersCanCreateSubgroups',
                    }}
                  />
                }
                label={t('mobileSetup.appPermissions.allowGroups')}
              />
            </FormControl>
          </Grid>

          <Grid item {...sizes}>
            <Typography className={classes.subtitle3} variant="body2">
              {t('mobileSetup.appPermissions.defaultGroupSubtitle')}
            </Typography>
            <Typography className={descriptionClass}>
              {t('mobileSetup.appPermissions.groupsDescription3')}
            </Typography>
          </Grid>

          <Grid item {...sizes}>
            <OutlinedTextField
              className={classes.groupName}
              showCount
              id="defaultGroupName"
              name="defaultGroupName"
              data-testid="defaultGroupName"
              value={values.defaultGroupName}
              label={t('mobileSetup.appPermissions.defaultGroupName')}
              inputProps={{
                maxLength: maxLengths.defaultGroupName,
                'data-testid': 'group-name-input',
              }}
              onChange={handleChangeName}
              onBlur={handleBlur}
              error={errors.defaultGroupName && touched.defaultGroupName}
              errorMessage={touched.defaultGroupName && errors.defaultGroupName}
            />
          </Grid>
        </>
      )}
    </>
  );
}

AppPermissionsInputs.propTypes = {
  classes: objectOf(string).isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  maxLengths: object.isRequired,
  setFieldValue: func.isRequired,
  touched: object.isRequired,
  handleBlur: func.isRequired,
  isSettingsView: bool,
  features: objectOf(bool),
};

AppPermissionsInputs.defaultProps = {
  isSettingsView: false,
  features: {},
};

export default withStyles(styles)(AppPermissionsInputs);
