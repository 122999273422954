import { withStyles } from '@material-ui/core/styles';
import { arrayOf, object, objectOf, string, func, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import Page from '../Page';
import {
  Table,
  TableHead,
  TableHeadCell,
  TableRow,
  TableBody,
  TableCell,
} from '../../../common';
import styles from './styles';

export function List({
  classes,
  pages,
  onSavePage,
  onDeletePage,
  onEmbedPage,
  onGenerateQRCode,
  variant,
  setSuccess,
}) {
  const { t } = useTranslation();

  return (
    <Table disabled={pages.length < 1}>
      <TableHead>
        <TableRow isHeader>
          <TableHeadCell>
            <Typography variant="body2">{t('site.description')}</Typography>
          </TableHeadCell>
          <TableHeadCell className={classes.statusColumn}>
            <Typography variant="body2">{t('site.status')}</Typography>
          </TableHeadCell>
          <TableHeadCell className={classes.moreMenu} menu />
        </TableRow>
      </TableHead>
      <TableBody>
        {pages.length > 0 ? (
          pages.map((page) => (
            <Page
              key={page.id}
              page={page}
              onSavePage={onSavePage}
              onDeletePage={onDeletePage}
              onEmbedPage={onEmbedPage}
              onGenerateQRCode={onGenerateQRCode}
              setSuccess={setSuccess}
            />
          ))
        ) : (
          <TableRow>
            <TableCell>
              <Typography component="span" className={classes.emptyList}>
                {variant === 'default'
                  ? t('site.empty.nonarchived')
                  : t('site.empty.archived')}
              </Typography>
            </TableCell>
            <TableCell />
            <TableCell menu />
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

List.propTypes = {
  classes: objectOf(string).isRequired,
  pages: arrayOf(object),
  onSavePage: func.isRequired,
  onDeletePage: func.isRequired,
  onEmbedPage: func,
  onGenerateQRCode: func,
  variant: oneOf(['default', 'archived']),
  setSuccess: func,
};

List.defaultProps = {
  pages: [],
  onEmbedPage: null,
  onGenerateQRCode: null,
  variant: 'default',
  setSuccess: null,
};

export default withStyles(styles)(List);
