import { connect } from 'react-redux';
import RetryLoading from './RetryLoading';
import { getPages } from '../../../store/pages/actions';

export function mapStateToProps(state) {
  return {
    retries: state.pages.retries,
  };
}

export default connect(mapStateToProps, { getPages })(RetryLoading);
