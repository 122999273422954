import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { bool, func, object, objectOf, oneOf, string } from 'prop-types';

import Modal from '../../Modal';
import PrimaryButton from '../../PrimaryButton';
import styles from './styles';
import ExternalLink from '../../ExternalLink';

function EventDetailsModal({ classes, open, onClose, variant, event }) {
  const { t } = useTranslation();

  const getLink = () => {
    const link =
      // eslint-disable-next-line no-nested-ternary
      variant === 'inPerson'
        ? `https://maps.google.com/?q=${event.address}`
        : variant === 'webLink'
        ? event.webLocationLink
        : event.zoomMeetingURL;
    return link;
  };

  const { item, description, addressLink, link, ...otherClasses } = classes;

  return (
    <Modal
      classes={{ ...otherClasses, closeIcon: undefined }}
      open={open}
      onClose={onClose}
    >
      <Modal.Title className={classes.title}>{event.name}</Modal.Title>
      <Modal.Content>
        {variant === 'inPerson' && (
          <Grid container>
            <Grid item className={item}>
              <Typography variant="body2" component="span">
                {t('vanco.mobile.events.location.name')}
              </Typography>
              <Typography className={description} component="span">
                {event.locationName}
              </Typography>
            </Grid>
            <Grid item className={item}>
              <Typography variant="body2" component="span">
                {t('vanco.mobile.events.address')}
              </Typography>
              <Typography variant="body2" component="span">
                <ExternalLink to={getLink()} className={addressLink}>
                  {event.address || ''}
                </ExternalLink>
              </Typography>
            </Grid>
            {event.personSpecialInstructions && (
              <Grid item className={item}>
                <Typography variant="body2" component="span">
                  {t('vanco.mobile.events.additional.details')}
                </Typography>
                <Typography className={description} component="span">
                  {event.personSpecialInstructions}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {variant === 'webLink' && (
          <Grid container>
            <Grid item className={item}>
              <Typography variant="body2">
                {`${t('vanco.mobile.events.web.link')}:`}
              </Typography>
              <Typography variant="body2">
                <ExternalLink to={event.webLocationLink} className={link}>
                  {event.webLocationLink || ''}
                </ExternalLink>
              </Typography>
            </Grid>
            {event.webLocationInstructions && (
              <Grid item className={item}>
                <Typography variant="body2" component="span">
                  {t('vanco.mobile.events.additional.details')}
                </Typography>
                <Typography className={description} component="span">
                  {event.webLocationInstructions}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {variant === 'zoomMeeting' && (
          <Grid container>
            <Grid item className={item}>
              <Typography>
                {`${event.creator.name} ${t(
                  'vanco.mobile.events.zoom.invite',
                )}`}
              </Typography>
            </Grid>
            <Grid item className={item}>
              <Typography>
                {t('vanco.mobile.events.join.zoom.meeting')}
              </Typography>
              <Typography variant="body2">
                <ExternalLink to={event.zoomMeetingURL} className={link}>
                  {event.zoomMeetingURL || ''}
                </ExternalLink>
              </Typography>
            </Grid>
          </Grid>
        )}
      </Modal.Content>
      <Modal.Actions className={classes.actions}>
        <Button variant="outlined" onClick={onClose}>
          {t('app.close')}
        </Button>
        <PrimaryButton data-testid="open-link-button">
          <ExternalLink to={getLink()}>
            {variant === 'zoomMeeting'
              ? t('vanco.mobile.events.join.meeting')
              : t('vanco.mobile.events.open.link')}
          </ExternalLink>
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
}

EventDetailsModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  variant: oneOf(['inPerson', 'webLink', 'zoomMeeting']).isRequired,
  event: object.isRequired,
};

export default withStyles(styles)(EventDetailsModal);
