export const commonStyles = theme => ({
  moreMenu: {
    width: 65,
  },
  statusColumn: {
    width: 320,
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
});

const commonTableContainer = theme => ({
  padding: 20,
  [theme.breakpoints.down('xs')]: {
    padding: 0,
    marginTop: 24,
  },
});

export default function styles(theme) {
  return {
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    tableContainer: {
      ...commonTableContainer(theme),
      marginTop: 44,
    },
    tableContainerArchived: {
      ...commonTableContainer(theme),
      marginTop: 16,
      paddingTop: '0px',
    },
    title: {
      marginBottom: 0,
    },
    mainContainer: {
      flex: 'auto',
    },
    arrowIcon: {
      marginLeft: 4,
    },
    archivedPage: {
      paddingTop: 0,
      paddingBottom: 48,
    },
    showArchivedButton: {
      marginLeft: 40,
      marginTop: -17,
      padding: '8px 12px 8px 16px',
      borderRadius: 4,
    },
    emptySpace: {
      height: 100,
    },
    ...commonStyles(theme),
  };
}
