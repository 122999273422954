import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import CommunicationService from '../../../api/CommunicationService/index';
import { handleException, setSuccess } from '../../../store/alerts/actions';
import { setAppLoading } from '../../../store/loading/actions';

async function getDynamicEmailTexts({ queryKey }) {
  const [, template] = queryKey;
  const response = await CommunicationService.getDynamicTexts(template);
  return response.data.data;
}

export function useDynamicEmailTexts(template) {
  return useQuery(['templateKey', template], getDynamicEmailTexts);
}

async function saveDynamicEmailTexts({ template, values }) {
  const data = { ...values, body: JSON.stringify(values.body) };
  const response = await CommunicationService.saveDynamicTexts(template, data);
  return response.data.data;
}

export function useSaveDynamicEmailTexts() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(saveDynamicEmailTexts, {
    onMutate: ({ template }) => {
      dispatch(setAppLoading(true));
      const prevData = queryClient.getQueryData(['templateKey', template]);
      return () =>
        queryClient.setQueryData(['templateKey', template], prevData);
    },
    onSuccess: () => {
      dispatch(setSuccess(t('site.changes.saved')));
      queryClient.invalidateQueries('templateKey');
    },
    onError: (error, _, rollback) => {
      dispatch(handleException(error));
      rollback();
    },
    onSettled: () => {
      dispatch(setAppLoading(false));
    },
  });
}

export default {
  useDynamicEmailTexts,
  useSaveDynamicEmailTexts,
};
