import * as Yup from 'yup';

import i18n from '../../../utils/i18n';

export const maxLengths = {
  title: 40,
};

export const createSchema = () => {
  return Yup.object().shape({
    title: Yup.string()
      .max(maxLengths.title, i18n.t('validation.long'))
      .required(
        i18n.t('validation.required', {
          requiredNoun: i18n.t('tile.title.label').toLocaleLowerCase(),
        }),
      ),
  });
};
