import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, objectOf, string } from 'prop-types';
import { withStyles } from '@material-ui/core';

import Page from '../Page';
import PageTitle from '../PageTitle';
import PagePaper from '../PagePaper';
import CustomFormik from '../CustomFormik';
import PreventLeavePageModal from '../PreventLeavePageModal';
import Form from './Form';

import useGetPageSettings from '../../../utils/hooks/useGetPageSettings';
import usePreventModal from '../../../utils/hooks/usePreventModal';
import ConfirmCommunityModal from './ConfirmCommunityModal';
import { getPagePermissions } from '../../../utils/page';
import { maxLengths } from '../../../utils/validations';
import { CHANNEL_TYPE, PAGE_ACTIVE } from '../../../globals/constants';
import { createSchema } from './validation';

import styles from './styles';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

export function VancoMobileEditPermissions({
  classes,
  loadingSave,
  savePage,
  setSuccess,
  features,
}) {
  const { t } = useTranslation();
  usePageTitle(t('vanco.mobile.home.settings.editAppPermissions'));
  const schema = createSchema();
  const [dirty, setDirty] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const { goBack, onCancel, cancelLeave } = usePreventModal('/mobile/home');
  const { isLoading, page, pageToUpdate, updatePage } = useGetPageSettings(
    CHANNEL_TYPE.MOBILE,
    PAGE_ACTIVE,
  );
  const [permissions, setPermissions] = useState({});
  const hasDefaultGroup =
    page.mobileAppPermissions?.defaultGroupName !== null &&
    page.mobileAppPermissions?.defaultGroupName !== '';

  async function onConfirmSubmit(values) {
    setDirty(false);
    await savePage({
      ...pageToUpdate,
      mobileAppPermissions: {
        ...values,
        clientPK: pageToUpdate.mobileAppPermissions
          ? pageToUpdate.mobileAppPermissions.clientPK
          : null,
      },
      hasCommunityFeatures: values.hasCommunityFeatures,
      updateMobileAppPermissions: true,
    });
    let successMessage;
    if (values.hasCommunityFeatures) {
      successMessage = t(
        'vanco.mobile.home.settings.saveAppPermissions.enabled',
      );
    } else {
      successMessage = t(
        'vanco.mobile.home.settings.saveAppPermissions.disabled',
      );
    }

    setSuccess(successMessage);
    goBack();
  }

  const onSubmit = async (values) => {
    if (
      (!hasDefaultGroup && values.hasCommunityFeatures) ||
      (values.hasCommunityFeatures && page.hasCommunityFeatures)
    ) {
      onConfirmSubmit(values);
    } else {
      // Show confirm Modal
      setPermissions(values);
      setConfirmModalOpen(true);
    }
  };

  const handleUpdatePage = (key, value) => {
    setDirty(true);
    updatePage({
      ...pageToUpdate,
      [key]: value,
    });
  };

  const closeModal = () => {
    setConfirmModalOpen(false);
  };

  const onConfirmDisable = () => {
    closeModal();
    onConfirmSubmit(permissions);
  };

  const getInitialPagePermissions = () => {
    const initialPermissions = getPagePermissions(page);
    if (!hasDefaultGroup) {
      initialPermissions.hasCommunityFeatures = true;
    }
    return { ...initialPermissions };
  };

  if (isLoading) {
    return null;
  }

  return (
    <Page variant="centered">
      <PageTitle className={classes.title} onGoBack={goBack}>
        {t(
          features.ChannelManagement
            ? 'vanco.mobile.home.settings.appPermissions'
            : 'vanco.mobile.home.settings.appPermissionsDeprecated',
        )}
      </PageTitle>
      <PagePaper className={classes.paper}>
        <CustomFormik
          initialValues={getInitialPagePermissions()}
          onSubmit={onSubmit}
          render={(formikProps) => (
            <Form
              {...formikProps}
              loading={loadingSave}
              dirty={dirty || !hasDefaultGroup}
              onCancel={onCancel}
              maxLengths={maxLengths}
              features={features}
            />
          )}
          onChange={handleUpdatePage}
          validationSchema={schema}
        />
      </PagePaper>
      <PreventLeavePageModal shouldOpenOnLeave={dirty && !cancelLeave} />
      <ConfirmCommunityModal
        onClose={closeModal}
        open={confirmModalOpen}
        onConfirm={onConfirmDisable}
        disabled={loadingSave}
        variant={permissions.hasCommunityFeatures ? 'enable' : 'disable'}
      />
    </Page>
  );
}

VancoMobileEditPermissions.propTypes = {
  classes: objectOf(string).isRequired,
  loadingSave: bool.isRequired,
  savePage: func.isRequired,
  setSuccess: func.isRequired,
  features: objectOf(bool),
};

VancoMobileEditPermissions.defaultProps = {
  features: {},
};

export default withStyles(styles)(VancoMobileEditPermissions);
