/* eslint-disable jsx-a11y/anchor-is-valid */

import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Formik } from 'formik';
import { PropTypes, bool, string, objectOf, object } from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  withStyles,
  Typography,
  Grid,
  Paper,
  Divider,
  Button,
  Popover,
} from '@material-ui/core';
import SpaOutlined from '@material-ui/icons/SpaOutlined';
import QuestionAnswer from '@material-ui/icons/QuestionAnswerOutlined';
import Home from '@material-ui/icons/HomeOutlined';
import Block from '@material-ui/icons/Block';

import QrCodeIcon from '@mui/icons-material/QrCode';
import {
  Badge,
  Page,
  PageTitle,
  ButtonMenu,
  CreateTileModal,
  QRCodeModal,
} from '../../common';
import NewMobilePageModal from './newMobilePageModal';
import { useGetPage } from '../../../utils/hooks/useGetPage/useGetPage';
import { useGetMobileInviteCode } from '../../../utils/hooks/useGetMobileInviteCode/useGetMobileInviteCode';
import {
  useCreateTile,
  useInviteMobileUsers,
  useQRCodeModal,
} from '../../../utils/hooks';
import {
  BIBLE_VERSIONS,
  CHANNEL_TYPE,
  LOCATION_PATCH_FIELDS,
  PAGE_ACTIVE,
  VO_QR_QUERY_PARAMETER,
} from '../../../globals/constants';
import { ReactComponent as MailIcon } from '../../../assets/mail.svg';
import { ReactComponent as CopyIcon } from '../../../assets/copyBlackIcon.svg';

import styles from './styles';
import { defaultTile } from '../../../globals/defaults';
import InviteByCodeModal from '../../common/VancoMobileUsers/InviteByCodeModal';
import InviteByEmailModal from '../../common/VancoMobileUsers/InviteByEmailModal';
import { copyText } from '../../../utils';
import { setSuccess } from '../../../store/alerts/actions';
import { ReactComponent as BibleIcon } from '../../../assets/bible.svg';

import DisableMobileModal from './DisableMobileModal';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';
import IOSSwitch from '../../common/IOSSwitch/IOSSwitch';
import CustomDropdown from '../../common/AddDonation/transactionItems/CustomDropdown';
import useBibleVersion from '../../../utils/hooks/faithForward';

function VancoMobileSettings({
  classes,
  newMobilePageModalOpen,
  tilesActions,
  features,
  wasInternal,
  organizationId,
}) {
  const { t } = useTranslation();
  usePageTitle(t('vanco.mobile.home.title'));
  const dispatch = useDispatch();
  const history = useHistory();
  const badgeRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const closePopover = () => setPopoverOpen(false);
  const openPopover = () => setPopoverOpen(true);
  const [inviteByCodeModalOpen, setInviteByCodeModalOpen] = useState(false);
  const [inviteByEmailModalOpen, setInviteByEmailModalOpen] = useState(false);
  const [createTileModalOpen, setCreateTileModalOpen] = useState(false);
  const {
    qrCodeModalOpen,
    openQRCodeModal,
    onCloseQRCodeModal,
    onErrorQRCodeModal,
  } = useQRCodeModal();
  const openInviteByCode = () => setInviteByCodeModalOpen(true);
  const closeInviteByCodeModal = () => setInviteByCodeModalOpen(false);
  const openInviteByEmailModal = () => setInviteByEmailModalOpen(true);
  const closeInviteByEmailModal = () => setInviteByEmailModalOpen(false);
  const closeCreateTileModalOpen = () => setCreateTileModalOpen(false);
  const { page, isLoading } = useGetPage(CHANNEL_TYPE.MOBILE, PAGE_ACTIVE);
  const { mutate: createTile, status: createStatus } = useCreateTile();
  const { mutate, status: inviteStatus } = useInviteMobileUsers();
  const closeModal = () => tilesActions.setNewMobilePageModalOpen(false);
  const { setBibleVersion, bibleVersion, loadingVersion } = useBibleVersion();
  const renderLink = (label, linkPath) => (
    <Link to={linkPath ?? ''}>
      <Typography className={classes.linkText}>{label}</Typography>
    </Link>
  );

  const [enableModalOpen, setEnableModalOpen] = useState(false);
  const openEnableModal = () => {
    setEnableModalOpen(true);
  };
  const closeEnableModal = () => {
    setEnableModalOpen(false);
  };

  const createNewTile = () => {
    setCreateTileModalOpen(true);
  };

  const onCreateTile = (tileType) => {
    createTile(
      { tile: { ...defaultTile, type: tileType } },
      {
        onSuccess: (newTile) => {
          setCreateTileModalOpen(false);
          const nextPath = `/wizard/tile/${newTile.id}/title`;
          history.push(nextPath);
        },
      },
    );
  };

  const onCopyCode = (code) => {
    copyText(code);
    dispatch(setSuccess('Invite code copied.'));
  };

  const onEmailFormSubmit = ({ emails }, { resetForm }) => {
    mutate(
      { users: emails },
      {
        onSuccess: () => {
          closeInviteByEmailModal();
          resetForm();
        },
      },
    );
  };

  const readOnlyPermissions =
    !page.hasCommunityFeatures &&
    page.mobileAppPermissions != null &&
    page.mobileAppPermissions?.defaultGroupName !== null &&
    page.mobileAppPermissions?.defaultGroupName !== '';

  const { invitationCode } = useGetMobileInviteCode();

  const onUpdateBibleReaderEnabled = (e) => {
    const { currentTarget } = e;
    const { checked } = currentTarget;
    tilesActions.patchLocation(
      page,
      LOCATION_PATCH_FIELDS.ENABLE_BIBLE_READER,
      checked,
    );
  };

  const onUpdateDevotionalEnabled = (e) => {
    const { currentTarget } = e;
    const { checked } = currentTarget;
    tilesActions.patchLocation(
      page,
      LOCATION_PATCH_FIELDS.ENABLE_DEVOTIONALS,
      checked,
    );
  };

  return (
    <Page>
      <div className={classes.titleContainer}>
        <PageTitle>{t('vanco.mobile.home.title')}</PageTitle>
        <ButtonMenu
          buttonContent={
            wasInternal
              ? t('site.actions')
              : t('vanco.mobile.home.users.invite.people.button')
          }
        >
          <ButtonMenu.Item onClick={openInviteByEmailModal}>
            <MailIcon className={classes.inviteIcon} />
            <Typography>
              {t('vanco.mobile.home.users.invite.via.email')}
            </Typography>
          </ButtonMenu.Item>
          <ButtonMenu.Item onClick={openQRCodeModal}>
            <QrCodeIcon
              className={`${classes.inviteIcon} ${classes.qrIcon}`}
              sx={{ fontSize: 20 }}
            />
            <Typography>{t('site.qrCode')}</Typography>
          </ButtonMenu.Item>
          {invitationCode && (
            <ButtonMenu.Item onClick={openInviteByCode}>
              <CopyIcon className={classes.inviteIcon} />
              <Typography>
                {t('vanco.mobile.home.users.copy.invite.code')}
              </Typography>
            </ButtonMenu.Item>
          )}
          {wasInternal && (
            <ButtonMenu.Item onClick={openEnableModal}>
              <Typography>
                {t('vanco.mobile.home.users.manageAccess.menuItem')}
              </Typography>
            </ButtonMenu.Item>
          )}
        </ButtonMenu>
      </div>

      {!features.ChannelManagement && (
        <Grid container spacing={2}>
          {/* Giving box */}
          <Grid item xs={12}>
            <Paper className={classes.categoryBox}>
              <div className={classes.categoryTitleContainer}>
                <span>
                  <SpaOutlined className={classes.icon} />
                </span>
                <Typography className={classes.header} variant="h2">
                  {t('vanco.mobile.home.giving.title')}
                </Typography>
              </div>
              <Typography className={classes.descriptionText}>
                {t('vanco.mobile.home.giving.description')}
              </Typography>
              <Divider className={classes.divider} />
              {renderLink(
                t('vanco.mobile.home.viewTiles'),
                '/mobile/tiles-order',
              )}
              <div>
                <Typography
                  className={classes.linkText}
                  onClick={createNewTile}
                >
                  {t('vanco.mobile.home.createTile')}
                </Typography>
              </div>
              {renderLink(
                t('vanco.mobile.home.settings.processingFees'),
                '/mobile/edit-processing-fees',
              )}
              {renderLink(
                t('vanco.mobile.home.configureSettings'),
                '/mobile/donor-settings',
              )}
            </Paper>
          </Grid>
          {/* Community box */}
          <Grid item xs={12}>
            <Paper className={classes.categoryBox}>
              <div className={classes.communityTitleContainer}>
                <div className={classes.categoryTitleContainer}>
                  <span>
                    <QuestionAnswer className={classes.icon} />
                  </span>
                  <Typography className={classes.header} variant="h2">
                    {t('vanco.mobile.home.community.title')}
                  </Typography>
                </div>

                {!page.hasCommunityFeatures && (
                  <>
                    <div
                      id="popup"
                      ref={badgeRef}
                      onMouseEnter={openPopover}
                      onMouseLeave={closePopover}
                    >
                      <Typography className={classes.badge}>
                        <Badge variant="default">
                          <Block />
                          {t('vanco.mobile.home.community.disabled')}
                        </Badge>
                      </Typography>
                    </div>
                    <Popover
                      anchorEl={badgeRef.current}
                      container={badgeRef.current}
                      className={classes.popover}
                      classes={{
                        paper: classes.popoverContent,
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={popoverOpen}
                      disableRestoreFocus
                    >
                      <Typography>
                        {t('vanco.mobile.home.community.disabledMessage')}
                      </Typography>
                    </Popover>
                  </>
                )}
              </div>
              <Typography className={classes.descriptionText}>
                {t('vanco.mobile.home.community.description')}
              </Typography>
              <Divider className={classes.divider} />
              {page.hasCommunityFeatures || readOnlyPermissions ? (
                <Grid
                  container
                  spacing={6}
                  className={classes.settingsSubContainer}
                >
                  <Divider
                    orientation="vertical"
                    className={classes.settingsVerticalDivider}
                  />
                  <Grid item xs={6}>
                    <div className={classes.flex}>
                      <Typography variant="body2">
                        {t('vanco.mobile.home.community.appDirectory.title')}
                      </Typography>
                      {readOnlyPermissions && (
                        <Typography className={classes.readOnly}>
                          <Badge variant="default">{t('app.readOnly')}</Badge>
                        </Typography>
                      )}
                    </div>
                    {renderLink(
                      t('vanco.mobile.home.community.viewMembers'),
                      '/mobile/users',
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.flex}>
                      <Typography variant="body2">
                        {t('vanco.mobile.home.community.events.title')}
                      </Typography>
                      {readOnlyPermissions && (
                        <Typography className={classes.readOnly}>
                          <Badge variant="default">{t('app.readOnly')}</Badge>
                        </Typography>
                      )}
                    </div>
                    {renderLink(
                      t('vanco.mobile.home.events.view'),
                      '/mobile/events',
                    )}
                  </Grid>
                  <Divider className={classes.horizontalDivider2} />
                  <Grid item xs={6}>
                    <div className={classes.flex}>
                      <Typography variant="body2">
                        {t('vanco.mobile.home.community.groups.title')}
                      </Typography>
                      {readOnlyPermissions && (
                        <Typography className={classes.readOnly}>
                          <Badge variant="default">{t('app.readOnly')}</Badge>
                        </Typography>
                      )}
                    </div>
                    {renderLink(
                      t('vanco.mobile.home.groups.view'),
                      '/mobile/groups',
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t('vanco.mobile.home.community.settings.title')}
                    </Typography>
                    {renderLink(
                      t('vanco.mobile.home.community.manageCommunity'),
                      '/mobile/edit-permissions',
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Button
                  variant="outlined"
                  className={classes.setUpButton}
                  onClick={() => history.push('/mobile/edit-permissions')}
                  data-testid="community-features-disabled"
                >
                  {t('vanco.mobile.home.community.setUp')}
                </Button>
              )}
            </Paper>
          </Grid>

          {/* Giving box */}
          <Grid item xs={12}>
            <Paper className={classes.categoryBox}>
              <div className={classes.categoryTitleContainer}>
                <span>
                  <Home className={classes.icon} />
                </span>
                <Typography className={classes.header} variant="h2">
                  {t('vanco.mobile.home.settings.title')}
                </Typography>
              </div>
              <Typography className={classes.descriptionText}>
                {t('vanco.mobile.home.settings.description')}
              </Typography>
              <Divider className={classes.divider} />
              {renderLink(
                t('vanco.mobile.home.organization.contact'),
                '/mobile/details',
              )}
              {renderLink(
                t('vanco.mobile.home.organization.appBranding'),
                '/mobile/branding',
              )}
            </Paper>
          </Grid>
        </Grid>
      )}
      {features.ChannelManagement && (
        <Grid container spacing={2}>
          {/* Giving box */}
          <Grid item xs={12}>
            <Paper className={classes.categoryBox}>
              <div className={classes.categoryTitleContainer}>
                <span>
                  <SpaOutlined className={classes.icon} />
                </span>
                <Typography className={classes.header} variant="h2">
                  {t('vanco.mobile.home.giving.title')}
                </Typography>
              </div>
              <Typography className={classes.descriptionText}>
                {t('vanco.mobile.home.giving.description')}
              </Typography>
              <Divider className={classes.divider} />
              {renderLink(
                t('vanco.mobile.home.manageTileOrder'),
                '/mobile/tiles-order',
              )}
              <div>
                <Typography
                  className={classes.linkText}
                  onClick={createNewTile}
                >
                  {t('vanco.mobile.home.createTile')}
                </Typography>
              </div>
              {renderLink(
                t('vanco.mobile.home.configureSettings'),
                '/mobile/donor-settings',
              )}
            </Paper>
          </Grid>
          {/* Bible Box */}
          {features.BibleReader && (
            <Grid item xs={12}>
              <Paper className={classes.categoryBox}>
                <div className={classes.categoryTitleContainer}>
                  <span>
                    <BibleIcon className={classes.bibleIcon} fontSize="16px" />
                  </span>
                  <Typography className={classes.header} variant="h2">
                    {t('vanco.mobile.home.bible.title')}
                  </Typography>
                </div>
                <Typography className={classes.descriptionText}>
                  {t('vanco.mobile.home.bible.description')}
                </Typography>
                <Divider className={classes.divider} />
                <Grid item xs={6}>
                  {!isLoading && !loadingVersion && (
                    <Formik
                      initialValues={{
                        bibleVersion,
                        enableBibleReader: page.enableBibleReader,
                        enableDevotionals: page.enableDevotionals,
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <>
                          <div className={classes.switchRow}>
                            <Typography className={classes.switchLabel}>
                              {t('vanco.mobile.home.bible.enable.reader')}
                            </Typography>
                            <div className={classes.switchWrapper}>
                              <IOSSwitch
                                checked={values.enableBibleReader}
                                onChange={(e) => {
                                  setFieldValue(
                                    'enableBibleReader',
                                    e.currentTarget.checked,
                                  );
                                  onUpdateBibleReaderEnabled(e);
                                }}
                              />
                            </div>
                          </div>
                          {values.enableBibleReader && (
                            <div className={classes.versionWrapper}>
                              <Field
                                className={classes.versionDropdown}
                                id="bible-version"
                                data-testid="bible-version-selector"
                                name="bibleVersion"
                                component={CustomDropdown}
                                data={BIBLE_VERSIONS}
                                value={values.bibleVersion}
                                onChange={(e) => {
                                  setFieldValue(e);
                                  setBibleVersion(e);
                                }}
                                textFieldLabel={t(
                                  'vanco.mobile.home.bible.version',
                                )}
                              />
                            </div>
                          )}
                          <div className={classes.switchRow}>
                            <Typography className={classes.switchLabel}>
                              {t('vanco.mobile.home.bible.enable.devotionals')}
                            </Typography>
                            <div className={classes.switchWrapper}>
                              <IOSSwitch
                                checked={values.enableDevotionals}
                                onChange={(e) => {
                                  setFieldValue(
                                    'enableDevotionals',
                                    e.currentTarget.checked,
                                  );
                                  onUpdateDevotionalEnabled(e);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </Formik>
                  )}
                </Grid>
              </Paper>
            </Grid>
          )}
          {/* Community box */}
          <Grid item xs={12}>
            <Paper className={classes.categoryBox}>
              <div className={classes.communityTitleContainer}>
                <div className={classes.categoryTitleContainer}>
                  <span>
                    <QuestionAnswer className={classes.icon} />
                  </span>
                  <Typography className={classes.header} variant="h2">
                    {t('vanco.mobile.home.community.title')}
                  </Typography>
                </div>

                {!page.hasCommunityFeatures && (
                  <>
                    <div
                      id="popup"
                      ref={badgeRef}
                      onMouseEnter={openPopover}
                      onMouseLeave={closePopover}
                    >
                      <Typography className={classes.badge}>
                        <Badge variant="default">
                          <Block />
                          {t('vanco.mobile.home.community.disabled')}
                        </Badge>
                      </Typography>
                    </div>
                    <Popover
                      anchorEl={badgeRef.current}
                      container={badgeRef.current}
                      className={classes.popover}
                      classes={{
                        paper: classes.popoverContent,
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={popoverOpen}
                      disableRestoreFocus
                    >
                      <Typography>
                        {t('vanco.mobile.home.community.disabledMessage')}
                      </Typography>
                    </Popover>
                  </>
                )}
              </div>
              <Typography className={classes.descriptionText}>
                {t('vanco.mobile.home.community.description')}
              </Typography>
              <Divider className={classes.divider} />
              {page.hasCommunityFeatures || readOnlyPermissions ? (
                <Grid
                  container
                  spacing={6}
                  className={classes.settingsSubContainer}
                >
                  <Divider
                    orientation="vertical"
                    className={classes.settingsVerticalDivider}
                  />
                  <Grid item xs={6}>
                    <div className={classes.flex}>
                      <Typography variant="body2">
                        {t('vanco.mobile.home.community.appDirectory.title')}
                      </Typography>
                      {readOnlyPermissions && (
                        <Typography className={classes.readOnly}>
                          <Badge variant="default">{t('app.readOnly')}</Badge>
                        </Typography>
                      )}
                    </div>
                    {renderLink(
                      t('vanco.mobile.home.community.viewMembers'),
                      '/mobile/users',
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.flex}>
                      <Typography variant="body2">
                        {t('vanco.mobile.home.community.events.title')}
                      </Typography>
                      {readOnlyPermissions && (
                        <Typography className={classes.readOnly}>
                          <Badge variant="default">{t('app.readOnly')}</Badge>
                        </Typography>
                      )}
                    </div>
                    {renderLink(
                      t('vanco.mobile.home.events.view'),
                      '/mobile/events',
                    )}
                  </Grid>
                  <Divider className={classes.horizontalDivider2} />
                  <Grid item xs={6}>
                    <div className={classes.flex}>
                      <Typography variant="body2">
                        {t('vanco.mobile.home.community.groups.title')}
                      </Typography>
                      {readOnlyPermissions && (
                        <Typography className={classes.readOnly}>
                          <Badge variant="default">{t('app.readOnly')}</Badge>
                        </Typography>
                      )}
                    </div>
                    {renderLink(
                      t('vanco.mobile.home.groups.view'),
                      '/mobile/groups',
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t('vanco.mobile.home.community.settings.title')}
                    </Typography>
                    {renderLink(
                      t('vanco.mobile.home.community.manageCommunity'),
                      '/mobile/edit-permissions',
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Button
                  variant="outlined"
                  className={classes.setUpButton}
                  onClick={() => history.push('/mobile/edit-permissions')}
                  data-testid="community-features-disabled"
                >
                  {t('vanco.mobile.home.community.setUp')}
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      )}

      {wasInternal && (
        <DisableMobileModal
          onClose={closeEnableModal}
          open={enableModalOpen}
          organizationId={organizationId}
          organizationStreamingDisableDate={undefined}
        />
      )}
      <NewMobilePageModal
        isOpen={newMobilePageModalOpen}
        onClose={closeModal}
      />
      <InviteByCodeModal
        invitationCode={invitationCode}
        open={inviteByCodeModalOpen}
        onClose={closeInviteByCodeModal}
        onCopyCode={onCopyCode}
      />
      <InviteByEmailModal
        open={inviteByEmailModalOpen}
        onClose={closeInviteByEmailModal}
        onSubmit={onEmailFormSubmit}
        isSubmitting={inviteStatus === 'loading'}
      />
      <CreateTileModal
        open={createTileModalOpen}
        onClose={closeCreateTileModalOpen}
        isLoading={createStatus === 'loading'}
        onCreateTile={onCreateTile}
      />
      {page?.locationURI && (
        <QRCodeModal
          siteLink={`${window.env.REACT_APP_MOBILE_URL}/app-link?location=${page.locationURI}&${VO_QR_QUERY_PARAMETER}`}
          open={qrCodeModalOpen}
          onClose={onCloseQRCodeModal}
          onError={onErrorQRCodeModal}
          name={page.pageName}
        />
      )}
    </Page>
  );
}

VancoMobileSettings.propTypes = {
  classes: objectOf(string).isRequired,
  newMobilePageModalOpen: bool.isRequired,
  tilesActions: object.isRequired,
  features: objectOf(bool),
  wasInternal: PropTypes.bool.isRequired,
  organizationId: PropTypes.string.isRequired,
};

VancoMobileSettings.defaultProps = {
  features: {},
};

export default withStyles(styles)(VancoMobileSettings);
