import {
  COMMS_ROUTE,
  DONORS_ROUTES,
  GIVING_ROUTES,
  IMPORT_ROUTES,
  MOBILE_SUBROUTES,
  REPORTING_ROUTES,
  SETTINGS_ROUTES,
  SITES_SUBROUTES,
  VIDEO_ROUTES,
  SECTIONS_TYPE,
} from 'globals/constants';

export const ORGANIZATION_VIEW = 'ORGANIZATION_VIEW';
export const ORGANIZATION_VIEWFUNDS = 'ORGANIZATION_VIEWFUNDS';
export const ORGANIZATION_ADDFUNDS = 'ORGANIZATION_ADDFUNDS';
export const ORGANIZATION_VIEWBANKACCOUNTS = 'ORGANIZATION_VIEWBANKACCOUNTS';
export const LOCATION_VIEW = 'LOCATION_VIEW';
export const LOCATION_EDIT = 'LOCATION_EDIT';
export const LOCATION_RESET = 'LOCATION_RESET';
export const CAMPAIGN_VIEW = 'CAMPAIGN_VIEW';
export const CAMPAIGN_ADD = 'CAMPAIGN_ADD';
export const CAMPAIGN_EDIT = 'CAMPAIGN_EDIT';
export const CAMPAIGN_DELETE = 'CAMPAIGN_DELETE';
export const USER_ALLOWTENANTASSOCIATION = 'USER_ALLOWTENANTASSOCIATION';
export const USER_VIEW = 'USER_VIEW';
export const USER_ADD = 'USER_ADD';
export const USER_DELETE = 'USER_DELETE';
export const USER_CANCELINVITATION = 'USER_CANCELINVITATION';
export const USER_TRANSFEROWNERSHIP = 'USER_TRANSFEROWNERSHIP';

// Section permissions
export const SECTION_PERMISSIONS = {
  GIVING: {
    ROOT: 'GIVING',
    TILES: {
      READ: 'GIVING_TILES_READ',
      WRITE: 'GIVING_TILES_WRITE',
      ENTRY_POINT_ROUTE: GIVING_ROUTES.tiles,
      ROUTES: [GIVING_ROUTES.tile, GIVING_ROUTES.tiles],
    },
    TRANSACTIONS: {
      READ: 'GIVING_TRANSACTIONS_READ',
      WRITE: 'GIVING_TRANSACTIONS_WRITE',
      ENTRY_POINT_ROUTE: GIVING_ROUTES.newTransaction,
      ROUTES: [GIVING_ROUTES.newTransaction],
    },
    SITES: {
      READ: 'GIVING_SITES_READ',
      WRITE: 'GIVING_SITES_WRITE',
      ENTRY_POINT_ROUTE: GIVING_ROUTES.sites,
      ROUTES: Object.values(SITES_SUBROUTES),
    },
    MOBILE: {
      READ: 'GIVING_MOBILE_READ',
      WRITE: 'GIVING_MOBILE_WRITE',
      ENTRY_POINT_ROUTE: GIVING_ROUTES.mobile,
      ROUTES: [MOBILE_SUBROUTES.mobile, MOBILE_SUBROUTES.tilesOrder],
    },
    KIOSK: {
      READ: 'GIVING_KIOSK_READ',
      WRITE: 'GIVING_KIOSK_WRITE',
      ENTRY_POINT_ROUTE: GIVING_ROUTES.kiosk,
      ROUTES: [GIVING_ROUTES.kiosk],
    },
  },
  DONORS: {
    ROOT: 'DONORS',
    DONORS: {
      READ: 'DONORS_DONORS_READ',
      WRITE: 'DONORS_DONORS_WRITE',
      ENTRY_POINT_ROUTE: DONORS_ROUTES.donors,
      ROUTES: [DONORS_ROUTES.donors],
    },
    TAGS: {
      READ: 'DONORS_TAGS_READ',
      WRITE: 'DONORS_TAGS_WRITE',
      ENTRY_POINT_ROUTE: DONORS_ROUTES.tags,
      ROUTES: [DONORS_ROUTES.tags],
    },
  },
  OUTREACH: {
    ROOT: 'OUTREACH',
    EMAILS: {
      READ: 'OUTREACH_EMAILS_READ',
      WRITE: 'OUTREACH_EMAILS_WRITE',
      ENTRY_POINT_ROUTE: COMMS_ROUTE.emails,
      ROUTES: [COMMS_ROUTE.emails],
    },
    SMS: {
      READ: 'OUTREACH_SMS_READ',
      WRITE: 'OUTREACH_SMS_WRITE',
      ENTRY_POINT_ROUTE: COMMS_ROUTE.sms,
      ROUTES: [COMMS_ROUTE.sms],
    },
    WRITING_DESK: {
      READ: 'OUTREACH_WRITING_DESK_READ',
      WRITE: 'OUTREACH_WRITING_DESK_WRITE',
      ENTRY_POINT_ROUTE: COMMS_ROUTE.writingDesk,
      ROUTES: [COMMS_ROUTE.writingDesk],
    },
    CONTENT_MANAGER: {
      READ: 'OUTREACH_CONTENT_MANAGER_READ',
      WRITE: 'OUTREACH_CONTENT_MANAGER_WRITE',
      ENTRY_POINT_ROUTE: COMMS_ROUTE.contentManager,
      ROUTES: [COMMS_ROUTE.contentManager],
    },
    TEMPLATES: {
      READ: 'OUTREACH_TEMPLATES_READ',
      WRITE: 'OUTREACH_TEMPLATES_WRITE',
      ENTRY_POINT_ROUTE: COMMS_ROUTE.templates,
      ROUTES: [COMMS_ROUTE.templates],
    },
  },
  SMART_VIDEO: {
    ROOT: 'SMART_VIDEO',
    LIVE_STREAMING: {
      READ: 'SMART_VIDEO_LIVE_STREAMING_READ',
      WRITE: 'SMART_VIDEO_LIVE_STREAMING_WRITE',
      ENTRY_POINT_ROUTE: VIDEO_ROUTES.liveStreaming,
      ROUTES: [VIDEO_ROUTES.liveStreaming, VIDEO_ROUTES.liveStreamingSetup],
    },
    SMART_LIBRARY: {
      READ: 'SMART_VIDEO_SMART_LIBRARY_READ',
      WRITE: 'SMART_VIDEO_SMART_LIBRARY_WRITE',
      ENTRY_POINT_ROUTE: VIDEO_ROUTES.smartLibrary,
      ROUTES: [VIDEO_ROUTES.smartLibrary, VIDEO_ROUTES.smartLibraryPaywall],
    },
    SMART_CLIPS: {
      READ: 'SMART_VIDEO_SMART_CLIPS_READ',
      WRITE: 'SMART_VIDEO_SMART_CLIPS_WRITE',
      ENTRY_POINT_ROUTE: VIDEO_ROUTES.smartClips,
      ROUTES: [VIDEO_ROUTES.smartClips, VIDEO_ROUTES.smartClipsPaywall],
    },
    SETTINGS: {
      READ: 'SMART_VIDEO_SETTINGS_READ',
      WRITE: 'SMART_VIDEO_SETTINGS_WRITE',
      ENTRY_POINT_ROUTE: VIDEO_ROUTES.videoSettings,
      ROUTES: [VIDEO_ROUTES.videoSettings],
    },
  },
  REPORTING: {
    ROOT: 'REPORTING',
    REPORTS: {
      READ: 'REPORTING_REPORTS_READ',
      WRITE: 'REPORTING_REPORTS_WRITE',
      ENTRY_POINT_ROUTE: REPORTING_ROUTES.reporting,
      ROUTES: [REPORTING_ROUTES.reporting],
    },
    INSIGHTS: {
      READ: 'REPORTING_INSIGHTS_READ',
      WRITE: 'REPORTING_INSIGHTS_WRITE',
      ENTRY_POINT_ROUTE: REPORTING_ROUTES.givingDashboard,
      ROUTES: [
        REPORTING_ROUTES.givingDashboard,
        REPORTING_ROUTES.insightsPaywall,
      ],
    },
  },
  IMPORT: {
    ROOT: 'IMPORT',
    GIVING: {
      READ: 'IMPORT_GIVING_READ',
      WRITE: 'IMPORT_GIVING_WRITE',
      ENTRY_POINT_ROUTE: IMPORT_ROUTES.import,
      ROUTES: [IMPORT_ROUTES.import],
    },
  },
  SETTINGS: {
    ROOT: 'SETTINGS',
    ORGANIZATION: {
      READ: 'SETTINGS_ORGANIZATION_READ',
      WRITE: 'SETTINGS_ORGANIZATION_WRITE',
      ENTRY_POINT_ROUTE: SETTINGS_ROUTES.organization,
      ROUTES: [SETTINGS_ROUTES.organization],
    },
    USERS: {
      READ: 'SETTINGS_USERS_READ',
      WRITE: 'SETTINGS_USERS_WRITE',
      ENTRY_POINT_ROUTE: SETTINGS_ROUTES.users,
      ROUTES: [SETTINGS_ROUTES.users],
    },
    EMAIL_RECEIPTS: {
      READ: 'SETTINGS_EMAIL_RECEIPTS_READ',
      WRITE: 'SETTINGS_EMAIL_RECEIPTS_WRITE',
      ENTRY_POINT_ROUTE: SETTINGS_ROUTES.communications,
      ROUTES: [SETTINGS_ROUTES.communications],
    },
    PAID_FEATURES: {
      READ: 'SETTINGS_PAID_FEATURES_READ',
      WRITE: 'SETTINGS_PAID_FEATURES_WRITE',
      ENTRY_POINT_ROUTE: SETTINGS_ROUTES.paidfeatures,
      ROUTES: [SETTINGS_ROUTES.paidfeatures],
    },
    IDENTITY_PROVIDER: {
      READ: 'SETTINGS_IDENTITY_PROVIDER_READ',
      WRITE: 'SETTINGS_IDENTITY_PROVIDER_WRITE',
      // Identity Provider doesn't have a route inside our app
    },
  },
};

export const PERMISSIONS_CHECKBOXES = [
  {
    sectionId: SECTIONS_TYPE.Giving,
    key: SECTION_PERMISSIONS.GIVING.ROOT,
    label: 'Giving',
    value: false,
  },
  {
    sectionId: SECTIONS_TYPE.Donors,
    key: SECTION_PERMISSIONS.DONORS.ROOT,
    label: 'Donors',
    value: false,
  },
  {
    sectionId: SECTIONS_TYPE.OutReach,
    key: SECTION_PERMISSIONS.OUTREACH.ROOT,
    label: 'Outreach',
    value: false,
  },
  {
    sectionId: SECTIONS_TYPE.Smart_Video,
    key: SECTION_PERMISSIONS.SMART_VIDEO.ROOT,
    label: 'Smart Video',
    value: false,
  },
  {
    sectionId: SECTIONS_TYPE.Reporting,
    key: SECTION_PERMISSIONS.REPORTING.ROOT,
    label: 'Reporting',
    value: false,
  },
];
