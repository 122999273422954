import { bool, func, objectOf, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { Grid } from '@material-ui/core';
import Modal from '../Modal';
import { ReactComponent as DownloadIcon } from '../../../assets/download.svg';
import styles from './styles';
import { downloadBase64File } from '../../../utils/images';

export function QRCodeModal({
  classes,
  open,
  onClose,
  onError,
  siteLink,
  name,
}) {
  const { t } = useTranslation();

  const [src, setSrc] = useState(' ');

  useEffect(() => {
    let isMounted = true;
    if (open && siteLink) {
      const opts = {
        type: 'image/png',
        width: 1000,
        margin: 1,
      };
      try {
        QRCode.toDataURL(siteLink, opts).then((source) => {
          if (isMounted) setSrc(source);
        });
      } catch (e) {
        onError();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [open, siteLink, onError]);

  const onDownload = () => {
    downloadBase64File(src, `${name} - QR Code.png`);
    onClose();
  };

  const close = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={close}>
      <Modal.Title className={classes.modalTitle} />
      <Modal.Content className={classes.modalContent}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5">{t('site.qrcode.title')}</Typography>
            <Typography variant="h5">{name}</Typography>
            <img
              src={src}
              alt="qr code"
              className={classes.qrCode}
              data-testid="qr-code"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={onDownload}
            >
              <DownloadIcon className={classes.icon} />
              <Typography className={classes.buttonText}>
                {t('button.download')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}

QRCodeModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func,
  onError: func,
  siteLink: string.isRequired,
  name: string.isRequired,
};

QRCodeModal.defaultProps = {
  open: false,
  onClose: null,
  onError: null,
};

export default withStyles(styles)(QRCodeModal);
