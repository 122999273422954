import useFeatures from '../useFeatures/useFeatures';
import useUser from '../useUser';

const useVideoDisplayFlags = () => {
  const {
    wasInternal: isInternalUser,
    smartLibraryDisabled: smartLibraryDisabledByDate, // disabled date indicates the user has not paid for the feature - different from feature flag
    smartClipsDisabled: smartClipsDisabledByDate, // disabled date indicates the user has not paid for the feature - different from feature flag
    organizationStreamingDisabled,
  } = useUser();

  const {
    SmartLibrary: ffSmartLibrary,
    SmartClips: ffSmartClips,
    VancoVideo: ffVancoVideo,
  } = useFeatures();

  // Smart Video features are enabled if either SmartLibrary or SmartClips are enabled
  const isFeatureEnabled = ffSmartLibrary || ffSmartClips;

  const shouldRedirect = !ffVancoVideo || !isFeatureEnabled;

  const isSmartLibraryUserVisible =
    !smartLibraryDisabledByDate || isInternalUser;

  const isSmartClipsUserVisible = !smartClipsDisabledByDate || isInternalUser;

  const shouldDisplaySmartLibrary = ffSmartLibrary && isSmartLibraryUserVisible;

  const shouldDisplaySmartClips = ffSmartClips && isSmartClipsUserVisible;

  const shouldDisplayLiveStreamAndSettings =
    isInternalUser || !organizationStreamingDisabled;

  const redirectToErrorFromLibrary = !ffVancoVideo || !ffSmartLibrary;
  const redirectToErrorFromClips = !ffVancoVideo || !ffSmartClips;

  return {
    isInternalUser,
    shouldDisplaySmartLibrary,
    shouldDisplaySmartClips,
    shouldRedirect,
    isFeatureEnabled,
    shouldDisplayLiveStreamAndSettings,
    redirectToErrorFromLibrary,
    redirectToErrorFromClips,
    ffVancoVideo,
    ffSmartClips,
    ffSmartLibrary,
  };
};

export default useVideoDisplayFlags;
