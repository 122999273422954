import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid, FormControl } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

import { PrimaryButton, Modal } from '../../../common';

export function RemoveUserModal({
  classes,
  open,
  user,
  onClose,
  onSave,
  isLoading,
}) {
  const { t } = useTranslation();
  const userNameString =
    user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName} (${user.userName})`
      : user.userName;

  return (
    <Modal open={open} onClose={onClose} data-testid="remove-user-modal">
        <Modal.Title>{t('users.removeUser.title')}</Modal.Title>
        <Modal.Content>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <FormControl fullWidth>
                <Typography>
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  <b>{userNameString}</b> {t('users.removeUser.noLongerAccess')}
                </Typography>
                <Grid
                  container
                  direction="row"
                  className={classes.infoContainer}
                >
                  <Grid item xs={1}>
                    <InfoIcon />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="body2" className={classes.infoNotice}>
                      {t('users.removeUser.wontRemoveFromClassicMyVanco')}
                    </Typography>
                    <Typography variant="body1">
                      {t('users.removeUser.needToRemoveInClassMyVanco')}
                    </Typography>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          {!isLoading && (
            <>
              <Button
                onClick={onClose}
                size="large"
                color="primary"
                variant="outlined"
              >
                {t('button.cancel')}
              </Button>
              <PrimaryButton
                size="large"
                onClick={() => onSave(user)}
                data-testid="remove-user"
              >
                {t('users.removeUser.submit')}
              </PrimaryButton>
            </>
          )}
          {isLoading && (
            <CircularProgress data-testid="remove-user-modal-spinner" />
          )}
        </Modal.Actions>
      </Modal>
  );
}

RemoveUserModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(RemoveUserModal);
