import {
  TILE_PENDING,
  PAGE_PENDING,
  TILE_TYPE_GIFT,
  CHANNEL_TYPE,
} from '../constants';

export const defaultTile = {
  title: '',
  description: '',
  shortDescription: '',
  buttonText: '',
  headerText: '',
  customButtonText: '',
  customHeaderText: '',
  fund: '',
  status: TILE_PENDING,
  image: {
    src: '',
    crop: {},
  },
  type: TILE_TYPE_GIFT,
  paymentFrequencies: [],
  ordinal: 1,
  includeQuestion: true,
  questions: [],
  locations: [],
  textField: '',
  customTextField: '',
  showTextField: false,
  showEndDate: false,
  responseRequired: false,
  isTextFieldResponseType: true,
  memoFieldResponses: [],
};

export const defaultPage = {
  status: PAGE_PENDING,
  pageName: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  email: '',
  phone: '',
  enableProcessingFees: true,
  hasPaymentMethods: false,
  checkPercentage: '1.0',
  creditPercentage: '2.9',
  url: '',
  homePage: '',
  primary: true,
  primaryColor: null,
  channelId: CHANNEL_TYPE.ONLINE,
  locationURI: '',
  allowSocialSharing: true,
  displayPageName: true,
  addressRequired: false,
};

export const defaultOrg = {
  checkPercentage: 1,
  creditPercentage: 2.9,
};

export default { defaultTile, defaultPage, defaultOrg };
