import BaseService from '../BaseService';

class KioskService extends BaseService {
  requestKiosk(orgUri = '') {
    const body = { orgUri };
    return this.http.post('kiosk/request', body);
  }

  enableKiosk(orgUri) {
    const body = { orgUri };
    return this.http.post('kiosk', body);
  }

  updateKiosk(orgUri, kiosk) {
    return this.http.put(`kiosk/${orgUri}`, kiosk);
  }

  getKiosk(orgUri) {
    return this.http.get(`kiosk/${orgUri}`);
  }

  disableKiosk(orgUri, disableDate) {
    const body = { data: { disableDate } };
    return this.http.delete(`kiosk/${orgUri}`, body);
  }
}

export default new KioskService();
