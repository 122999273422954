import {
  GET_PHOTOS_START,
  GET_PHOTOS_FINISH,
  GET_PHOTOS_ERROR,
  CLEAR_PHOTOS,
} from './types';

const INITIAL_STATE = {
  loadingPhotos: false,
  currentPage: 0,
  photosError: null,
  photos: [],
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_PHOTOS_START:
      return {
        ...state,
        photosError: null,
        loadingPhotos: true,
      };

    case GET_PHOTOS_FINISH:
      return {
        ...state,
        photosError: null,
        currentPage: action.page,
        loadingPhotos: false,
        photos: action.data,
      };

    case GET_PHOTOS_ERROR:
      return {
        ...state,
        photosError: action.error,
        loadingPhotos: false,
        photos: [],
      };

    case CLEAR_PHOTOS:
      return {
        ...state,
        photosError: null,
        loadingPhotos: false,
        currentPage: 0,
        photos: [],
      };

    default:
      return state;
  }
};
