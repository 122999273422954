import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import Crop from '../Crop';
import PrimaryButton from '../PrimaryButton';
import styles from './styles';
import ExternalLink from '../ExternalLink';
import { IMAGE_ASPECTS, UNSPLASH_UTM_PARAMS } from '../../../globals/constants';

export function CropModal({
  classes,
  image,
  open,
  onClose,
  onSave,
  crop,
  onCrop,
  loadingUpdateImage,
  aspect,
}) {
  const { t } = useTranslation();
  const matches = useMediaQuery('(min-width:1280px)');

  return (
    <Dialog fullScreen={!matches} open={open} onClose={onClose}>
      <DialogTitle classes={{ root: classes.title }} disableTypography>
        <Typography variant="h2">{t('button.crop')}</Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Crop
          src={image ? image.cropImage : ''}
          crop={crop}
          onCrop={onCrop}
          aspect={aspect}
        />
        {image.userName && (
          <div className={classes.imageBy}>
            {t('imageSelection.imageBy')}

            <ExternalLink
              to={`${image.userUrl}${UNSPLASH_UTM_PARAMS}`}
              className={classes.link}
            >
              {image.userName}
            </ExternalLink>
          </div>
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        {loadingUpdateImage && <CircularProgress color="primary" />}
        {!loadingUpdateImage && (
          <>
            <PrimaryButton size="large" onClick={onSave}>
              {t('button.crop')}
            </PrimaryButton>
            <Button onClick={onClose} size="large" color="primary">
              {t('button.close')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

CropModal.propTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.shape({
    id: PropTypes.string,
    userName: PropTypes.string,
    userUrl: PropTypes.string,
    cropImage: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  crop: PropTypes.object.isRequired,
  onCrop: PropTypes.func.isRequired,
  loadingUpdateImage: PropTypes.bool.isRequired,
  aspect: PropTypes.number,
};

CropModal.defaultProps = {
  image: {},
  aspect: IMAGE_ASPECTS.RECTANGLE,
};

export default withStyles(styles)(CropModal);
