import { withStyles } from '@material-ui/core/styles';
import { objectOf, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import styles from './styles';
import { ButtonMenu, PageTitle } from '../../../common';
import useGetStreamerSettings from '../../../../utils/hooks/useGetStreamerSettings';

export function Title({ classes }) {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: streamerSettings, status } = useGetStreamerSettings();
  const isLoading = status === 'loading';

  const onEditSiteSettings = () => history.push('/stream/settings');

  return (
    <div className={classes.title}>
      <PageTitle isLoading={isLoading}>
        {streamerSettings.title || ''}
      </PageTitle>
      <div className={classes.leftMargin}>
        <ButtonMenu buttonContent={t('site.actions')}>
          <Typography className={classes.actionsTitle}>
            {t('stream.site.settings.actions')}
          </Typography>
          <ButtonMenu.Item onClick={onEditSiteSettings}>
            <EditIcon className={classes.icon} />
            <Typography>{t('stream.site.settings.edit')}</Typography>
          </ButtonMenu.Item>
        </ButtonMenu>
      </div>
    </div>
  );
}

Title.propTypes = {
  classes: objectOf(string).isRequired,
};

export default withStyles(styles)(Title);
