import { node } from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';

import CardListItem from '../CardListItem';
import { useCardContext } from '../context';

function CardList({ children }) {
  const { classes, isLoading } = useCardContext();

  return (
    <ul className={classes.cardList}>
      {isLoading ? (
        <CardListItem disabled>
          <Skeleton variant="rect" height="1.6rem" width={150} />
        </CardListItem>
      ) : (
        children
      )}
    </ul>
  );
}

CardList.propTypes = {
  children: node,
};

CardList.defaultProps = {
  children: null,
};

export default CardList;
