import { func, object, objectOf, shape, string } from 'prop-types';
import { Tooltip, Typography, withStyles } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/EditOutlined';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete-20px.svg';
import OutlinedTextField from '../../OutlinedTextField';

import styles from './styles';

function TextItem({ classes, textData, onEdit, onDelete, onBlur, maxLengths }) {
  const { customText, isEditing, error } = textData;
  const [tempText, setTempText] = useState(customText);
  const { t } = useTranslation();

  const handleBlur = (e) => {
    const updatedData = { ...textData, customText: tempText.trim() };
    onBlur(updatedData, e.target.value);
  };

  const handleChange = (e) => {
    setTempText(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleBlur(e);
    }
  };

  useEffect(() => {
    if (isEditing) {
      setTempText(customText);
    }
  }, [isEditing, customText]);

  return (
    <>
      {!isEditing && (
        <div
          className={`${classes.container} ${isEditing ? classes.inline : ''}`}
        >
          <div className={classes.textContainer}>
            <div className={classes.flex}>
              <DragIndicatorIcon className={classes.drag} />
              <Typography
                className={classes.description}
                data-testid="custom-text"
              >
                {customText}
              </Typography>
            </div>

            <div className={classes.flex}>
              <Tooltip title={t('tile.editDetails')}>
                <EditIcon
                  className={classes.edit}
                  data-testid="edit-text-button"
                  onClick={() => onEdit(textData)}
                />
              </Tooltip>
              <Tooltip title={t('tile.delete')}>
                <DeleteIcon
                  className={classes.remove}
                  data-testid="delete-text-button"
                  onClick={() => onDelete(textData)}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      )}
      {isEditing && (
        <OutlinedTextField
          showCount
          id="customText"
          name="customText"
          data-testid="customText-editor"
          value={isEditing ? tempText : customText}
          inputProps={{
            maxLength: maxLengths.customDropDownText,
            'data-testid': 'custom-dropdown-text',
          }}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          error={!!error}
          errorMessage={error}
          autoFocus
        />
      )}
    </>
  );
}

TextItem.propTypes = {
  classes: objectOf(string).isRequired,
  textData: shape({}).isRequired,
  onEdit: func.isRequired,
  onDelete: func.isRequired,
  onBlur: func.isRequired,
  maxLengths: object.isRequired,
};

export default withStyles(styles)(TextItem);
