import * as Yup from 'yup';

import i18n from '../../../utils/i18n';

export const maxLengths = {};

export const createSchema = () => {
  return Yup.object().shape({
    includeQuestion: Yup.bool(),
    prompt: Yup.string().when('includeQuestion', {
      is: true,
      then: (s) =>
        s.required(
          i18n.t('validation.required', {
            requiredVerb: 'enter a',
            requiredNoun: 'prompt',
          }),
        ),
    }),
    required: Yup.bool(),
  });
};
