import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import useImportFiles from '../hooks/useImportFiles';
import StatusChip from '../StatusChip';
import styles from './styles';
import Table from '../Table';

function ImportFilesTable({ idsPayerTenant, accessToken, onItemSelected }) {
  const [files] = useImportFiles(idsPayerTenant, accessToken);
  const { t } = useTranslation();
  const dateFormatter = ({ children }) => {
    const dateString = children;
    if (!dateString) return t('ministry.platform.import.table.date.pending');
    const dateTime = new Date(dateString);
    return moment(dateTime).format('MMM DD, YYYY - hh:mm A');
  };

  return (
    !!files && (
      <Table
        cols={[
          {
            key: 'fileName',
            displayName: t('ministry.platform.import.table.header.name'),
            width: '60%',
            sortable: true,
          },
          {
            key: 'status',
            displayName: t('ministry.platform.import.table.header.status'),
            wrapper: StatusChip,
            width: '20%',
          },
          {
            key: 'completionDate',
            displayName: t('ministry.platform.import.table.header.datetime'),
            width: '20%',
            sortable: true,
            wrapper: dateFormatter,
          },
        ]}
        data={files}
        onItemSelected={onItemSelected}
      />
    )
  );
}
ImportFilesTable.propTypes = {
  idsPayerTenant: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  onItemSelected: PropTypes.func.isRequired,
};
export default withStyles(styles)(ImportFilesTable);
