export default (theme) => ({
  modalTitle: {
    padding: '16px 24px',
    borderBottom: 'none',
    marginBottom: 0,
  },
  modalContent: {
    padding: '14px 24px 24px 24px',
    textAlign: 'center',
  },
  buttonText: {
    color: 'inherit',
  },
  description: {
    color: '#222222',
  },
  icon: {
    marginRight: theme.spacing(1),
    '& path': {
      fill: 'white',
    },
  },
  qrCode: {
    width: 300,
    margin: theme.spacing(2),
  },
});
