import {
  CREATE_PAGE_START,
  CREATE_PAGE_FINISH,
  CREATE_PAGE_ERROR,
  GET_PAGES_START,
  GET_PAGES_ERROR,
  GET_PAGES_FINISH,
  SAVE_PAGE_START,
  SAVE_PAGE_FINISH,
  SAVE_PAGE_ERROR,
  GET_PAGE_START,
  GET_PAGE_FINISH,
  GET_PAGE_ERROR,
  RESET_NEW_PAGE,
  SET_CURRENT_PAGE,
  GET_CURRENT_PAGE_START,
  GET_CURRENT_PAGE_FINISH,
  GET_CURRENT_PAGE_ERROR,
  UPDATE_PAGE,
  CLEAR_PAGES,
  SET_NEW_MOBILE_PAGE_MODAL_OPEN,
  SET_PAGE_COMMUNITY_FEATURES_CHOOSEN,
  REMOVE_PAGE,
  SET_STREAMER_ID,
  CREATE_STREAMER_SETTINGS_START,
  CREATE_STREAMER_SETTINGS_FINISH,
  CREATE_STREAMER_SETTINGS_ERROR,
  UPDATE_STREAMER_SETTINGS_START,
  UPDATE_STREAMER_SETTINGS_FINISH,
  UPDATE_STREAMER_SETTINGS_ERROR,
  UPDATE_ADDRESS_REQUIRED_START,
  UPDATE_ADDRESS_REQUIRED_FINISH,
  UPDATE_ADDRESS_REQUIRED_ERROR,
  PATCH_LOCATION_FINISH,
} from './types';
import { USER_LOGOUT } from '../user/types';
import { backendToFrontend } from './transform';

const INITIAL_STATE = {
  loadingPages: false,
  hasLoadedPages: false,
  loadingPage: false,
  loadingSave: false,
  loadingCreate: false,
  loadingCurrentPage: false,
  fetchedCurrentPage: false,
  newMobilePageModalOpen: false,
  error: null,
  pages: [],
  newPage: {},
  currentPage: {},
  retries: 0,
  streamer: {},
};

const createOrReplacePage = (state, page) => {
  return [
    backendToFrontend(page),
    ...state.pages.filter((p) => {
      return p.id !== page.id;
    }),
  ];
};

export default (state = INITIAL_STATE, action = {}) => {
  const page = action.data;

  switch (action.type) {
    // GET PAGES
    case GET_PAGES_START:
      return {
        ...state,
        error: null,
        loadingPages: true,
      };

    case GET_PAGES_FINISH: {
      return {
        ...state,
        retries: 0,
        error: null,
        loadingPages: false,
        hasLoadedPages: true,
        pages: action.data.map((p) => backendToFrontend(p)),
      };
    }

    case GET_PAGES_ERROR:
      return {
        ...state,
        error: action.error,
        loadingPages: false,
        hasLoadedPages: true,
        retries: state.retries + 1,
      };

    // GET PAGE
    case GET_PAGE_START:
      return {
        ...state,
        error: null,
        loadingPage: true,
      };

    case GET_PAGE_FINISH: {
      const pages = createOrReplacePage(state, page);
      return {
        ...state,
        error: null,
        loadingPage: false,
        pages,
      };
    }

    case GET_PAGE_ERROR:
      return {
        ...state,
        error: action.error,
        loadingPage: false,
      };

    // SAVE
    case SAVE_PAGE_START:
      return {
        ...state,
        error: null,
        loadingSave: true,
      };

    // Called when we save some info on page-setttings section
    case SAVE_PAGE_FINISH: {
      const pages = createOrReplacePage(state, page);
      const frontEndPage = backendToFrontend(page);
      return {
        ...state,
        error: null,
        loadingSave: false,
        newPage: frontEndPage,
        currentPage: frontEndPage,
        pages,
      };
    }

    case SAVE_PAGE_ERROR:
      return {
        ...state,
        error: action.error,
        loadingSave: false,
      };

    // CREATE

    case CREATE_PAGE_START:
      return {
        ...state,
        error: null,
        loadingCreate: true,
      };

    // Called when wizard steps finish
    case CREATE_PAGE_FINISH: {
      const pages = createOrReplacePage(state, page);

      return {
        ...state,
        error: null,
        loadingCreate: false,
        pages,
        newPage: page,
        currentPage: page,
      };
    }

    case CREATE_PAGE_ERROR:
      return {
        ...state,
        error: action.error,
        loadingCreate: false,
      };

    // RESET
    case RESET_NEW_PAGE:
      return {
        ...state,
        newPage: {},
      };

    // CURRENT
    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: page,
      };
    }

    case GET_CURRENT_PAGE_START:
      return {
        ...state,
        error: null,
        loadingCurrentPage: true,
        fetchedCurrentPage: true,
      };

    // Called when we enter to the router
    case GET_CURRENT_PAGE_FINISH: {
      const pages = createOrReplacePage(state, page);

      return {
        ...state,
        error: null,
        pages,
        loadingCurrentPage: false,
        currentPage: backendToFrontend(page),
      };
    }

    case GET_CURRENT_PAGE_ERROR:
      return {
        ...state,
        error: action.error,
        loadingCurrentPage: false,
      };

    // UPDATE
    case UPDATE_PAGE: {
      const pages = [
        page,
        ...state.pages.filter((p) => {
          return p.id !== page.id;
        }),
      ];

      return {
        ...state,
        pages,
      };
    }

    case USER_LOGOUT: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }

    case CLEAR_PAGES: {
      return {
        ...state,
        pages: [],
      };
    }

    case SET_NEW_MOBILE_PAGE_MODAL_OPEN: {
      return {
        ...state,
        newMobilePageModalOpen: action.isOpen,
      };
    }

    case SET_PAGE_COMMUNITY_FEATURES_CHOOSEN: {
      return {
        ...state,
        communityFeaturesChoosen: action.isChoosen,
      };
    }

    case REMOVE_PAGE: {
      const pages = (state?.pages || []).filter((p) => p.id !== action.page.id);
      const currentPage =
        state?.currentPage?.id === action.page.id
          ? pages.find((p) => p.channelId === action.page.channelId) || {}
          : state.currentPage;
      return {
        ...state,
        pages,
        currentPage,
      };
    }

    case SET_STREAMER_ID: {
      return {
        ...state,
        streamer: { streamerId: action.streamerId },
      };
    }

    case CREATE_STREAMER_SETTINGS_START:
      return {
        ...state,
        error: null,
        loadingCreate: true,
        streamer: {},
      };

    case CREATE_STREAMER_SETTINGS_FINISH: {
      return {
        ...state,
        error: null,
        loadingCreate: false,
        streamer: action.data,
      };
    }

    case CREATE_STREAMER_SETTINGS_ERROR:
      return {
        ...state,
        error: action.error,
        loadingCreate: false,
      };

    case UPDATE_STREAMER_SETTINGS_START:
      return {
        ...state,
        error: null,
        loadingSave: true,
        streamer: { streamerId: state.streamer.streamerId || '' },
      };

    case UPDATE_STREAMER_SETTINGS_FINISH: {
      return {
        ...state,
        error: null,
        loadingSave: false,
        streamer: action.data,
      };
    }

    case UPDATE_STREAMER_SETTINGS_ERROR:
      return {
        ...state,
        error: action.error,
        loadingSave: false,
      };

    case UPDATE_ADDRESS_REQUIRED_START: {
      return {
        ...state,
        error: null,
        loadingSave: true,
      };
    }

    case UPDATE_ADDRESS_REQUIRED_FINISH: {
      const pages = [
        backendToFrontend(page),
        ...state.pages.filter((p) => {
          return p.id !== page.id;
        }),
      ];
      return {
        ...state,
        error: null,
        loadingSave: false,
        pages,
      };
    }

    case UPDATE_ADDRESS_REQUIRED_ERROR: {
      return {
        ...state,
        error: action.error,
        loadingSave: false,
      };
    }
    case PATCH_LOCATION_FINISH: {
      const pages = [
        backendToFrontend(page),
        ...state.pages.filter((p) => {
          return p.id !== page.id;
        }),
      ];
      return {
        ...state,
        error: null,
        loadingSave: false,
        pages,
      };
    }
    default:
      return state;
  }
};
