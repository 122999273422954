import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import styles from './styles';

export function MobileWizardNav({
  classes,
  currentSection,
  totalSections,
  navConfig,
}) {
  const currentSectionIndex = navConfig.findIndex(a => a === currentSection);
  const progress = (currentSectionIndex / totalSections) * 100;

  return (
    <section className={classes.container}>
      <LinearProgress
        variant="determinate"
        value={progress}
        classes={{ root: classes.progressBar }}
      />
      <Typography classes={{ body1: classes.navTitle }} color="inherit">
        {currentSection.navTitle}
      </Typography>
    </section>
  );
}

MobileWizardNav.propTypes = {
  classes: PropTypes.object.isRequired,
  totalSections: PropTypes.number.isRequired,
  currentSection: PropTypes.object.isRequired,
  navConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(MobileWizardNav);
