export default theme => ({
  modalTitle: {
    padding: '16px 24px',
  },
  modalContent: {
    padding: '14px 24px 24px 24px',
  },
  modalActions: {
    padding: 24,
  },
  title: {
    fontWeight: 700,
    color: '#222222',
  },
  description: {
    color: '#222222',
  },
  cancelButton: {
    marginRight: 10,
  },
  cancelButtonText: {
    fontWeight: 700,
    borderRadius: 6,
  },
  copyButtonText: {
    color: 'inherit',
  },
  container: {
    border: '1px solid #CCCCCC',
    borderRadius: 6,
    marginTop: 24,
    height: 144,
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
    },
  },
  code: {
    padding: '0 16px',
  },
  copied: {
    display: 'flex',
    alignItems: 'center',
    background: '#F1F8F5',
    color: '#118411',
    marginTop: 24,
    padding: 18,
    '& span': {
      marginLeft: 18,
      fontSize: 16,
      fontWeight: 'bold',
      fontFamily: theme.typography.fontFamily,
    },
  },
  dimensions: {
    display: 'flex',
  },
  dimensionInput: {
    width: 100,
  },
  dimensionSymbol: {
    fontSize: 21,
    position: 'relative',
    top: 28,
    marginLeft: 15,
    marginRight: 15,
  },
  optionDescription: {
    color: theme.palette.text.subdued,
    marginLeft: 32,
  },
  pre: {
    marginTop: 12,
    whiteSpace: 'pre-wrap',
  },
  subtitle: {
    marginTop: 15,
    marginBottom: 15,
  },
});
