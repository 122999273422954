import { useQuery } from 'react-query';
import recurrenceAPI from '../api/recurrenceAPI';

function useImportFiles(tenantId, accessToken) {
  const { data, isLoading, error } = useQuery('importFiles', () =>
    recurrenceAPI.getImportFiles(tenantId, accessToken),
  );

  return [data?.data?.data, error, isLoading];
}

export default useImportFiles;
