import postRobot from 'post-robot';
import { HandlerType, CancelableType } from 'post-robot/src/types';
import qs from 'qs';

import { url, object } from '@vancoplatform/utils';

export type PopupPosition = {
  width?: number;
  height?: number;
  left?: number;
  top?: number;
};

export type PopupOptions = { url: string; popupOptions?: PopupPosition };

class PopupHandler {
  url: string;
  origin: string;
  popup: Window | null;
  popupOptions: PopupPosition;

  constructor(options: PopupOptions) {
    this.url = options.url;
    this.origin = url.extractOrigin(options.url);
    this.popup = null;

    const popupPosition = this.calculatePosition(options.popupOptions || {});
    this.popupOptions = object.merge(popupPosition).with(options.popupOptions);
  }

  calculatePosition(options: PopupPosition): PopupPosition {
    const width = options.width || 500;
    const height = options.height || 720;

    const screenX =
      typeof window.screenX !== 'undefined'
        ? window.screenX
        : window.screenLeft;
    const screenY =
      typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop;

    const outerWidth =
      typeof window.outerWidth !== 'undefined'
        ? window.outerWidth
        : window.document.body.clientWidth;

    const outerHeight =
      typeof window.outerHeight !== 'undefined'
        ? window.outerHeight
        : window.document.body.clientHeight;

    const left = (outerWidth - width) / 2;
    const top = (outerHeight - height) / 2;

    return { width, height, left: screenX + left, top: screenY + top };
  }

  mount() {
    const windowFeatures = qs.stringify(this.popupOptions, {
      encode: false,
      delimiter: ',',
    });

    this.popup = window.open(this.url, 'ids_login_popup', windowFeatures);
    this.popup.focus();
  }

  on(name: string, handler: HandlerType): CancelableType {
    return postRobot.on(
      name,
      { window: this.popup, domain: this.origin },
      handler
    );
  }

  unmount() {
    this.popup.close();
  }
}

export default PopupHandler;
