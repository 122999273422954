import { SET_ALERT, CLEAR_ALERT } from './types';

const INITIAL_STATE = {
  variant: null,
  message: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        variant: action.variant,
        message: action.message,
      };

    case CLEAR_ALERT:
      return {
        ...state,
        variant: null,
        message: null,
      };

    default:
      return state;
  }
};
