import {
  TOGGLE_LEFT_NAV,
  TOGGLE_PROFILE_NAV,
  TOGGLE_APPS_NAV,
  CLOSE_NAVS,
} from './types';

const INITIAL_STATE = {
  leftNavOpen: false,
  profileNavOpen: false,
  appsNavOpen: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case TOGGLE_LEFT_NAV:
      return {
        ...INITIAL_STATE,
        leftNavOpen: !state.leftNavOpen,
      };

    case TOGGLE_APPS_NAV:
      return {
        ...INITIAL_STATE,
        appsNavOpen: !state.appsNavOpen,
      };

    case TOGGLE_PROFILE_NAV:
      return {
        ...INITIAL_STATE,
        profileNavOpen: !state.profileNavOpen,
      };

    case CLOSE_NAVS:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};
