import { useMemo } from 'react';
import { bool, node, objectOf, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';

import CardContext from './context';

import styles from './styles';

function Card({ classes, isLoading, className, children }) {
  const contextProviderProps = useMemo(() => ({classes, isLoading}), [classes, isLoading]);
  return (
    <Paper
      classes={{
        root: `${classes.root} ${className}`,
      }}
    >
      <CardContext.Provider value={contextProviderProps}>
        {children}
      </CardContext.Provider>
    </Paper>
  );
}

Card.propTypes = {
  classes: objectOf(string).isRequired,
  isLoading: bool,
  children: node,
  className: string,
};

Card.defaultProps = {
  isLoading: false,
  children: null,
  className: '',
};

export default withStyles(styles)(Card);
