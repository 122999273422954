export default function styles(theme) {
  return {
    titleContainer: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'flex-start',
    },
    header: {
      fontSize: theme.spacing(5),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    paper: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        minHeight: 800,
        maxWidth: 850,
      },
    },
    firstline: {
      wordWrap: 'break-word',
      [theme.breakpoints.up('sm')]: {
        minHeight: 300,
        maxWidth: 300,
      },
    },
    threeTopSpace: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    twoTopSpace: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    date: {
      color: '#5E5E5E',
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(2),
      },
    },
    items: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        minHeight: 300,
        maxWidth: 800,
      },
      backgroundColor: '#F4F4F4',
      padding: 20,
      borderTop: '1px solid #DDDDDD',
      borderBottom: '1px solid #DDDDDD',
    },
    footer: {
      marginTop: theme.spacing(3),
      fontSize: 6,
      [theme.breakpoints.up('sm')]: {
        minHeight: 60,
        maxWidth: 800,
      },
      backgroundColor: '#F4F4F4',
      padding: 10,
      height: 30,
    },
    buttonEdit: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    vanco: {
      fontWeight: 'bold',
    },
    vancoEmail: {
      color: 'blue',
      fontSize: 16,
    },
    eightTopSpace: {
      marginTop: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    fontRegular: {
      fontSize: 16,
    },
    link: {
      color: 'blue',
      fontWeight: 'bold',
      fontSize: 16,
    },
    gray: {
      color: '#5E5E5E',
    },
    bodyText: {
      fontSize: 20,
      marginTop: theme.spacing(3),
    },
    skeleton: {
      padding: '16px 24px 0 24px',
      fontSize: '1.6rem',
      fontFamily: theme.typography.fontFamily,
    },
    actionButtons: {
      marginTop: theme.spacing(5),
    },
    subtitle: {
      fontSize: 20,
      fontWeight: 'bold',
      marginTop: theme.spacing(3),
    },
    flex: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
    },
    flexMargin: {
      marginTop: theme.spacing(1),
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    revert: {
      marginRight: theme.spacing(2),
      marginBottom: 5,
      width: 'auto',
    },
  };
}
