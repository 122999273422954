import { connect } from 'react-redux';
import { setCurrentPage } from '../../../store/pages/actions';

import PrivateRouteWithPages from './PrivateRouteWithPages';

export function mapStateToProps(state) {
  return {
    isLoggedIn: state.user.isLoggedIn,
    currentPage: state.pages.currentPage,
    loadingAuth: state.user.loadingAuth,
    grantedActions: state.user.user.grantedActions,
    pages: state.pages.pages,
    loadingPages: state.pages.loadingPages,
    pageError: state.pages.error ?? '',
  };
}

export default connect(mapStateToProps, { setCurrentPage })(
  PrivateRouteWithPages,
);
