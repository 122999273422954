import { useMemo } from 'react';
import { string, objectOf, node, number } from 'prop-types';
import { withStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';

import SwipeableContainerContext from './context';
import Slide from './Slide';
import styles from './styles';

export function SwipeableContainer({
  currentSlideIndex,
  children,
  classes,
}) {
  const contextProviderProps = useMemo(() => ({currentSlideIndex, classes}), [currentSlideIndex, classes]);
  return <SwipeableContainerContext.Provider value={contextProviderProps}>
    <SwipeableViews index={currentSlideIndex}>{children}</SwipeableViews>
  </SwipeableContainerContext.Provider>
}

SwipeableContainer.Slide = Slide;

SwipeableContainer.propTypes = {
  classes: objectOf(string).isRequired,
  children: node.isRequired,
  currentSlideIndex: number.isRequired,
};

export default withStyles(styles)(SwipeableContainer);
