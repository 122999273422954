import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useIsFetching } from 'react-query';

import styles from './styles';

export function AppProgressBar({ isLoading, classes }) {
  const isFetching = useIsFetching();
  if (isLoading || isFetching)
    return (
      <LinearProgress
        className={classes.progressBar}
        data-testid="linear-progress"
      />
    );

  return null;
}

AppProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

AppProgressBar.defaultProps = {
  isLoading: false,
};

export default withStyles(styles)(AppProgressBar);
