import { useRef, useState } from 'react';
import { bool, func, objectOf, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Popover from '@material-ui/core/Popover';
import { useTranslation } from 'react-i18next';

import { TILE_TYPE_GIFT, TILE_TYPE_PAYMENT } from '../../../globals/constants';
import { ReactComponent as GiftIcon } from '../../../assets/gift.svg';
import { ReactComponent as LocalOfferIcon } from '../../../assets/local_offer.svg';
import Modal from '../Modal';

import styles from './styles';

export function CreateTileModal({
  classes,
  open,
  onClose,
  disablePaymentButton,
  disableGiftButton,
  isLoading,
  onCreateTile,
}) {
  const { t } = useTranslation();
  const paymentButtonRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const openPopover = () => setPopoverOpen(true);
  const closePopover = () => setPopoverOpen(false);
  const displayPaymentPopover = popoverOpen && disablePaymentButton;

  const createTile = (type) => () => {
    onCreateTile(type);
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.createtileModal}>
      <Modal.Title className={classes.header} />
      <Modal.Content className={classes.content}>
        <Typography variant="h3" className={classes.title}>
          {t('createTileModal.title')}
        </Typography>
        <Button
          variant="outlined"
          className={classes.tileTypeButton}
          disabled={disableGiftButton || isLoading}
          onClick={createTile(TILE_TYPE_GIFT)}
        >
          <div className={classes.icon}>
            <GiftIcon />
          </div>
          <div className={classes.optionTextContainer}>
            <Typography variant="body1" className={classes.optionTitle}>
              {t('createTileModal.gift')}
            </Typography>
            <Typography variant="body1" className={classes.optionSubtitle}>
              {t('createTileModal.giftDescription')}
            </Typography>
          </div>
          <ArrowForwardIosIcon />
        </Button>
        <div
          className={classes.paymentButtonContainer}
          ref={paymentButtonRef}
          onMouseEnter={openPopover}
          onMouseLeave={closePopover}
        >
          <Button
            variant="outlined"
            className={classes.tileTypeButton}
            disabled={disablePaymentButton || isLoading}
            onClick={createTile(TILE_TYPE_PAYMENT)}
            aria-owns={displayPaymentPopover ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            data-testid="create-payment-tile-button"
          >
            <div className={classes.icon}>
              <LocalOfferIcon />
            </div>
            <div className={classes.optionTextContainer}>
              <Typography variant="body1" className={classes.optionTitle}>
                {t('createTileModal.payment')}
              </Typography>
              <Typography variant="body1" className={classes.optionSubtitle}>
                {t('createTileModal.paymentDescription')}
              </Typography>
            </div>
            <ArrowForwardIosIcon />
          </Button>
        </div>
        <Popover
          anchorEl={paymentButtonRef.current}
          container={paymentButtonRef.current}
          className={classes.popover}
          classes={{
            paper: classes.popoverContent,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          open={displayPaymentPopover}
          disableRestoreFocus
        >
          <Typography>{t('createTileModal.paymentPopover')}</Typography>
        </Popover>
      </Modal.Content>
    </Modal>
  );
}

CreateTileModal.propTypes = {
  classes: objectOf(string).isRequired,
  open: bool,
  onClose: func.isRequired,
  disablePaymentButton: bool,
  disableGiftButton: bool,
  isLoading: bool,
  onCreateTile: func.isRequired,
};

CreateTileModal.defaultProps = {
  open: false,
  disablePaymentButton: false,
  disableGiftButton: false,
  isLoading: false,
};

export default withStyles(styles)(CreateTileModal);
