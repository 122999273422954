import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
  },
  table: {
    width: '100%',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  header: {
    padding: theme.spacing(2),
    borderBottom: 'none',
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    fontWeight: 'bold',
  },
  rowTitle: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: '1.75rem', // 25% smaller font size
    width: '150px', // add a fixed width
  },
  isbold: {
    fontWeight: 'bold',
  },
  select: {
    minWidth: 265,
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: 4,
    padding: '8px 12px',
  },
  permissionCell: {
    width: '4%',
    borderBottom: 'none',
    paddingLeft: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingBottom: theme.spacing(1),
    },
  },
  accessCell: {
    width: '40%',
    borderBottom: 'none',
    paddingLeft: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  headerSelectContainer: {
    paddingRight: `0 ${theme.spacing(2.5)}px`,
  },
  row: {
    '& td': {
      borderBottom: 'none',
    },
    '&:last-child td': {
      paddingBottom: theme.spacing(3.5),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
  },
  collapseCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

export default useStyles;
