import { arrayOf, objectOf, object, string, bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Button from '@material-ui/core/Button';
import { useQueryClient } from 'react-query';
import {
  Page,
  PagePaper,
  PageTitle,
  PageStatusModal,
  EmbedModal,
} from '../../common';
import List from './List';
import {
  getNonArchivedOnlinePages,
  getArchivedOnlinePages,
  isActive,
} from '../../../utils/page';
import {
  useCustomState,
  useDeletePage,
  useQRCodeModal,
} from '../../../utils/hooks';
import DeleteModal from '../../common/DeletePageModal';
import QRCodeModal from '../../common/QRCodeModal/QRCodeModal';

import styles from './styles';
import { VO_QR_QUERY_PARAMETER } from '../../../globals/constants';
import CreateButton from '../../common/CreateButton';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

const initialState = {
  pageToUpdate: null,
  showArchivedPages: false,
  pageStatusModalOpen: false,
  pageToDelete: null,
  deletePageModalOpen: false,
  pageToEmbed: null,
  embedPageModalOpen: false,
};

export function SitesList({
  classes,
  pages,
  loadingSave,
  savePage,
  setSuccess,
  removePage,
  resetNewPage,
}) {
  const { t } = useTranslation();
  usePageTitle(t('sites.document.title'));
  const history = useHistory();
  const queryClient = useQueryClient();
  const [
    {
      pageToUpdate,
      showArchivedPages,
      pageStatusModalOpen,
      pageToDelete,
      deletePageModalOpen,
      pageToEmbed,
      embedPageModalOpen,
      pageToGenerateQRCode,
    },
    setState,
  ] = useCustomState(initialState);
  const {
    qrCodeModalOpen,
    openQRCodeModal,
    onCloseQRCodeModal,
    onErrorQRCodeModal,
  } = useQRCodeModal();
  const { deletePage, isDeletable, isDeleting, isDeletableFetching } =
    useDeletePage(pageToDelete, {
      enabled: deletePageModalOpen,
    });

  const nonArchivedPages = getNonArchivedOnlinePages(pages);
  const archivedPages = getArchivedOnlinePages(pages);

  const onCreateNewSite = () => {
    resetNewPage();
    history.push('/wizard/page-info');
  };

  const onArchivedPages = () => {
    setState({ showArchivedPages: !showArchivedPages });
  };

  const closeModal = () => {
    setState({ pageStatusModalOpen: false });
  };

  const onSavePage = (updatedPage) => {
    setState({
      pageToUpdate: updatedPage,
      pageStatusModalOpen: true,
    });
  };

  const onConfirmSave = async () => {
    const message = isActive(pageToUpdate)
      ? t('site.unarchived.success')
      : t('site.archived.success');
    await savePage(pageToUpdate, true, message);
    closeModal();
    queryClient.removeQueries(['tile']);
  };

  const onSelectPageToDelete = (selectedPage) => {
    setState({
      pageToDelete: selectedPage,
      deletePageModalOpen: true,
    });
  };

  const onDeletePage = () => {
    deletePage(pageToDelete, {
      onSuccess: () => {
        setState({
          deletePageModalOpen: false,
        });
        removePage(pageToDelete);
        setSuccess(t('site.delete.success'));
      },
    });
  };

  const closeDeleteModal = () => {
    setState({
      deletePageModalOpen: false,
    });
  };

  const onSelectPageToEmbed = (selectedPage) => {
    setState({
      pageToEmbed: selectedPage,
      embedPageModalOpen: true,
    });
  };

  const closeEmbedModal = () => {
    setState({
      embedPageModalOpen: false,
    });
  };

  const onGenerateQRCode = (selectedPage) => {
    setState({
      pageToGenerateQRCode: selectedPage,
    });
    openQRCodeModal();
  };

  return (
    <>
      <Grid className={classes.mainContainer}>
        <Grid container>
          <Page>
            <div className={classes.titleContainer}>
              <PageTitle className={classes.title}>
                {t('app.vancoOnline')}
              </PageTitle>
              <CreateButton variant="contained" onClick={onCreateNewSite}>
                {t('site.createNew')}
              </CreateButton>
            </div>
            <PagePaper className={classes.tableContainer}>
              <List
                pages={nonArchivedPages}
                onSavePage={onSavePage}
                onDeletePage={onSelectPageToDelete}
                onEmbedPage={onSelectPageToEmbed}
                onGenerateQRCode={onGenerateQRCode}
                setSuccess={setSuccess}
              />
            </PagePaper>
          </Page>
          <Grid item lg={12}>
            <Button
              className={classes.showArchivedButton}
              onClick={onArchivedPages}
              size="large"
            >
              {t('site.button.archived')}
              {showArchivedPages ? (
                <KeyboardArrowUp className={classes.arrowIcon} />
              ) : (
                <KeyboardArrowDown className={classes.arrowIcon} />
              )}
            </Button>
          </Grid>
          {showArchivedPages ? (
            <Page className={classes.archivedPage}>
              <PagePaper className={classes.tableContainerArchived}>
                <List
                  pages={archivedPages}
                  onSavePage={onSavePage}
                  onDeletePage={onSelectPageToDelete}
                  variant="archived"
                />
              </PagePaper>
            </Page>
          ) : (
            <Grid item lg={12} className={classes.emptySpace} />
          )}
        </Grid>
      </Grid>
      <PageStatusModal
        disabled={loadingSave}
        onClose={closeModal}
        open={pageStatusModalOpen}
        onAccept={onConfirmSave}
        variant={
          pageToUpdate && isActive(pageToUpdate) ? 'unarchive' : 'archive'
        }
      />
      <DeleteModal
        isDeletable={isDeletable}
        isLoading={isDeletableFetching}
        disabled={isDeleting}
        onClose={closeDeleteModal}
        open={deletePageModalOpen}
        onDelete={onDeletePage}
        page={pageToDelete}
        entityType="site"
      />
      <EmbedModal
        domain={window.env.REACT_APP_DON_URL}
        locationURI={pageToEmbed ? pageToEmbed.locationURI : ''}
        onClose={closeEmbedModal}
        open={embedPageModalOpen}
        page={pageToEmbed}
      />
      {pageToGenerateQRCode && (
        <QRCodeModal
          siteLink={`${window.env.REACT_APP_DON_URL}/${pageToGenerateQRCode.locationURI}/home?${VO_QR_QUERY_PARAMETER}`}
          open={qrCodeModalOpen}
          onClose={onCloseQRCodeModal}
          onError={onErrorQRCodeModal}
          name={pageToGenerateQRCode.pageName}
        />
      )}
    </>
  );
}

SitesList.propTypes = {
  classes: objectOf(string).isRequired,
  pages: arrayOf(object).isRequired,
  loadingSave: bool.isRequired,
  savePage: func.isRequired,
  setSuccess: func.isRequired,
  removePage: func.isRequired,
  resetNewPage: func.isRequired,
};

export default withStyles(styles)(SitesList);
