export default (theme) => ({
  menu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  upperContent: {
    justifyContent: 'normal',
  },
  activeIcon: {
    '& path': {
      fill: theme.palette.white,
    },
  },
  submenuIcon: {
    height: '1.6rem',
    width: '1.6rem',
  },
});
