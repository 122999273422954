import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';

import { SESSION_LENGTH_MILLISECONDS } from '../../../globals/constants';

function IdleHandler({ user, getOrRenewAccessToken }) {
  const userRef = useRef(user);
  const userTimeout = user.session.expireIn || SESSION_LENGTH_MILLISECONDS;

  // update session ref for event handler functions
  useEffect(() => {
    userRef.current = user;
  }, [user]);

  /**
   * Handle a user event
   * IMPORTANT: This function gets bound once on initial render, so it only has that closure's props. Any changing data (such as redux state data) needs to be passed in through useRef
   */
  const handleAction = () => {
    if (userRef.current.isLoggedIn) {
      // this will get or renew the access token and if the token expires it will log them out
      getOrRenewAccessToken();
    }
  };

  /**
   * Handle a user becoming idle by either logging them out or clearing their basket
   * IMPORTANT: This function gets bound once on initial render, so it only has that closure's props. Any changing data (such as redux state data) needs to be passed in through useRef
   */
  const handleIdle = () => {
    if (userRef.current.isLoggedIn) {
      // this will get or renew the access token and if the token expires it will log them out
      getOrRenewAccessToken();
    }
  };

  useIdleTimer({
    timeout: userTimeout,
    throttle: 5000,
    onAction: handleAction,
    onIdle: handleIdle,
  });

  return <div />;
}

IdleHandler.propTypes = {
  user: PropTypes.object.isRequired,
  getOrRenewAccessToken: PropTypes.func.isRequired,
};

export default IdleHandler;
