import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import navigation from './navigation/reducer';
import user from './user/reducer';
import internal from './internal/reducer';
import unsplash from './unsplash/reducer';
import tiles from './tiles/reducer';
import pages from './pages/reducer';
import alerts from './alerts/reducer';
import loading from './loading/reducer';
import organizations from './organizations/reducer';
import loadingDialog from './loadingDialog/reducer';
import features from './features/reducer';
import kiosk from './kiosk/reducer';
import transaction from './transaction/reducer';
import modals from './modals/reducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history), // required by the connected-react-router package
    navigation,
    user,
    internal,
    unsplash,
    tiles,
    pages,
    alerts,
    loading,
    organizations,
    loadingDialog,
    features,
    kiosk,
    transaction,
    modals,
  });

export default createRootReducer;
