import { useTranslation } from 'react-i18next';
import { Page, PageTitle } from '../../common';

function Donations() {
  const { t } = useTranslation();

  return (
    <Page>
      <PageTitle>{t('app.online')}</PageTitle>
    </Page>
  );
}

export default Donations;
