import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import withRequiredGrants from 'components/hoc/withRequiredGrants';
import { ExternalLink } from '../../common';
import Submenu from '../Submenu';
import styles from './styles';

export function MenuItem({
  classes,
  history,
  icon,
  label,
  active,
  linkPath,
  submenuItems,
  isExternalLink,
  onClick,
}) {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (active) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [active]);

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  function navigate() {
    history.push(linkPath);
  }

  const WrapperLink = isExternalLink ? ExternalLink : Link;

  const smartVideoLabel = t('app.menu.smartVideo');
  const isSmartVideoLabel = label === smartVideoLabel;
  const isSmartVideoLink = isSmartVideoLabel && !submenuItems.length;
  const isExpanded = isSmartVideoLink ? false : expanded;

  const clickCallback =
    onClick && !linkPath
      ? (e, ...args) => {
          // Prevent the link from performing a navigation because that can
          // sometimes cause components to become unmounted and lose their state.
          e.preventDefault();
          onClick(e, ...args);
        }
      : onClick;

  return (
    <div className={classes.wrap}>
      {!submenuItems.length && !isSmartVideoLabel && (
        <WrapperLink
          className={`
            ${classes.menuLink}
            ${!icon ? classes.noIcon : ''}
            ${active ? classes.active : ''}
          `}
          to={linkPath || '#'}
          onClick={clickCallback}
        >
          {icon && <span>{icon}</span>}
          <Typography
            className={`
            ${classes.label}
            ${active ? classes.active : ''}
          `}
          >
            {label}
          </Typography>
        </WrapperLink>
      )}
      {(Boolean(submenuItems?.length) || isSmartVideoLink) && (
        <Submenu
          icon={icon}
          label={label}
          active={active}
          expanded={isExpanded}
          onToggle={linkPath ? navigate : toggleExpanded}
          items={submenuItems}
        />
      )}
    </div>
  );
}

MenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  icon: PropTypes.node,
  label: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  linkPath: PropTypes.string,
  submenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  isExternalLink: PropTypes.bool,
  onClick: PropTypes.func,
};

MenuItem.defaultProps = {
  icon: null,
  linkPath: null,
  submenuItems: [],
  isExternalLink: false,
  onClick: () => {},
};

export default withStyles(styles)(withRouter(withRequiredGrants(MenuItem)));
