import { string, objectOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Typography, MenuItem, Grid } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Page, PageTitle, OutlinedSelect, PagePaper } from '../../common';
import styles from './styles';
import {
  COMMUNICATIONS_COMPLETED_TEMPLATE,
  COMMUNICATIONS_SCHEDULED_TEMPLATE,
} from '../../../globals/constants';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

function CommunicationSettings({ classes }) {
  const { t } = useTranslation();
  usePageTitle(t('communications.title'));
  const [emailTemplate, setEmailTemplate] = useState('');
  const history = useHistory();

  const handleEmailTemplateChange = (e) => {
    setEmailTemplate(e.target.value);

    history.push(`/communications/receipt/${e.target.value}`);
  };

  return (
    <Page>
      <div className={classes.titleContainer}>
        <PageTitle>{t('communications.title')}</PageTitle>
      </div>
      <PagePaper className={classes.paper}>
        <Typography className={classes.header} variant="h2">
          {t('communications.subtitle')}
        </Typography>
        <Typography className={classes.description} variant="subtitle2">
          <Typography variant="subtitle2">
            {t('communications.description.one')}
          </Typography>
          <Typography variant="subtitle2">
            {t('communications.description.two')}
          </Typography>
        </Typography>

        <Grid container>
          <Grid item xs={12} sm={6} md={5} className={classes.dropdown}>
            <OutlinedSelect
              id="emailTemplate"
              name="emailTemplate"
              data-testid="template-select"
              value={emailTemplate}
              onChange={handleEmailTemplateChange}
              label={
                emailTemplate
                  ? t('communications.selected')
                  : t('communications.select')
              }
            >
              <MenuItem
                className={classes.inlineItems}
                value={COMMUNICATIONS_COMPLETED_TEMPLATE}
                key={COMMUNICATIONS_COMPLETED_TEMPLATE}
              >
                {t('communications.select.completed')}
              </MenuItem>
              <MenuItem
                className={classes.inlineItems}
                value={COMMUNICATIONS_SCHEDULED_TEMPLATE}
                key={COMMUNICATIONS_SCHEDULED_TEMPLATE}
              >
                {t('communications.select.scheduled')}
              </MenuItem>
            </OutlinedSelect>
          </Grid>
        </Grid>
      </PagePaper>
    </Page>
  );
}

CommunicationSettings.propTypes = {
  classes: objectOf(string).isRequired,
};

export default withStyles(styles)(CommunicationSettings);
