import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles';
import {
  generateSignedUrl,
  setupPostMessageListener,
} from '../../../../utils/sigma';
import { getOrganizationId } from '../../../../store/user/actions';

function ReportsIframe({ classes, url, orgId, filter, email }) {
  const dispatch = useDispatch();
  const [hash, setHash] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [clientPK, setClientPK] = useState();

  const getOrgId = async () => {
    const orgData = await dispatch(getOrganizationId(orgId));
    setClientPK(orgData.clientPK);
  };

  if (!clientPK) {
    getOrgId();
  }

  useEffect(() => {
    if (clientPK) {
      // Ensure clientPK has been set
      generateSignedUrl(url, email, clientPK, filter).then(setHash);
    }
  }, [url, email, clientPK, filter]);

  useEffect(() => {
    const removeListener = setupPostMessageListener(setLoaded);
    return removeListener;
  }, []);

  return (
    <>
      {!loaded && (
        <div className={classes.spinnerContainer}>
          <CircularProgress
            color="primary"
            size={50}
            className={classes.spinner}
          />
        </div>
      )}

      {hash && (
        <div className={loaded ? classes.visible : classes.hide}>
          <iframe title="content" src={hash} className={classes.iframeStyle} />
        </div>
      )}
    </>
  );
}

ReportsIframe.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default withStyles(styles)(ReportsIframe);
