import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FormControl } from '@material-ui/core';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import Form from '../../Form';
import ColorSelector from '../../ColorSelector';
import { COLOR_OPTIONS } from '../../../../globals/constants';

import styles from './styles';
import OutlinedTextField from '../../OutlinedTextField';
import FormHelperTextBottom from '../../FormHelperTextBottom';
import { getCountText } from '../../../../utils';
import PageLogo from '../PageLogo';
import PageTitle from '../../PageTitle';
import PageDivider from '../../PageDivider';

export function OffsetForm({
  classes,
  values,
  handleSubmit,
  handleBlur,
  setFieldValue,
  goToPrev,
  loading,
  isFinished,
  handleReset,
  dirty,
  errors,
  touched,
  maxLengths,
  page,
  isWizard,
}) {
  const { t } = useTranslation();
  const { title, primaryColor, logoImage, pageTitleKey } = values;

  const onColorSelect = color =>
    setFieldValue({ target: { name: 'primaryColor', value: color } });

  const setLogoImage = img =>
    setFieldValue({ target: { name: 'logoImage', value: img } });

  return (
    <Form className={classes.form}>
      {pageTitleKey && (
        <>
          <PageTitle variant="small">{t(pageTitleKey)}</PageTitle>
          <Grid item className={classes.divider}>
            <PageDivider variant="wide" />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography variant="h5">
          {t('stream.page.branding.pageName.title')}
        </Typography>
        <Typography variant="body1" className={classes.textMargin}>
          {t('stream.page.branding.pageName.description')}
        </Typography>
        <FormControl fullWidth className={classes.textMargin}>
          <OutlinedTextField
            id="title"
            name="title"
            error={errors.pageName && touched.pageName}
            value={title}
            label={t('stream.page.branding.streamPageName')}
            placeholder={t('stream.page.branding.streamPageName.placeholder')}
            inputProps={{
              maxLength: maxLengths.pageName,
              'data-testid': 'pageName-input',
            }}
            onBlur={handleBlur}
            onChange={setFieldValue}
            autoComplete="off"
            autoFocus={!title.length}
          />
          <FormHelperTextBottom
            left={touched.pageName && errors.pageName}
            right={getCountText(title.length, maxLengths.pageName)}
          />
        </FormControl>
      </Grid>
      <Grid item className={classes.divider}>
        <PageDivider variant={isWizard ? 'default' : 'editor'} />
      </Grid>
      <Grid container className={classes.colorContainer}>
        <Typography variant="h5">{t('wizard.nav.color')}</Typography>
        <Typography variant="body1" className={classes.textMargin}>
          {t('stream.page.branding.color.description')}
        </Typography>
        {COLOR_OPTIONS.map(c => (
          <ColorSelector
            key={c}
            color={c}
            onClick={onColorSelect}
            isSelected={c === primaryColor}
          />
        ))}
      </Grid>
      <Grid item className={classes.divider}>
        <PageDivider variant={isWizard ? 'default' : 'editor'} />
      </Grid>
      <Grid container className={classes.sectionVertGutter}>
        <Grid item xs={12}>
          <PageLogo
            page={page}
            primaryColor={primaryColor}
            logoImage={logoImage}
            setLogoImage={setLogoImage}
          />
        </Grid>
      </Grid>
      {isWizard && (
        <Grid item>
          <PageDivider variant="wide" />
        </Grid>
      )}

      {!loading && goToPrev && (
        <WizardButtons
          isFinished={isFinished}
          dirty={dirty}
          goToNext={handleSubmit}
          goToPrev={goToPrev}
          undo={handleReset}
          newWizard
        />
      )}
      {loading && goToPrev && (
        <div className={classes.spinnerContainer}>
          <CircularProgress
            color="primary"
            data-testid="color-options-save-spinner"
          />
        </div>
      )}
    </Form>
  );
}

OffsetForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  goToPrev: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  maxLengths: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  isWizard: PropTypes.bool.isRequired,
};

OffsetForm.defaultProps = {
  goToPrev: null,
};

export default withStyles(styles)(OffsetForm);
