// Libs
import { useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Hooks
import { useVideoDisplayFlags } from 'utils/hooks';
import { NotFound } from 'components/common/NotFound';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

// Components
import VideoChannelSettings from '../../common/VideoChannelSettings/VideoChannelSettings';
import LiveStreaming from '../LiveStreaming';
import { PageTitle, Tab, Tabs } from '../../common';
import Page, { containerVariants } from '../../common/Page';

// Constants
import {
  UNKNOWN,
  VIDEO_ROUTES,
  VIDEO_SETTINGS_TABS,
} from '../../../globals/constants';

function VideoSettings() {
  const { t } = useTranslation();

  const { isFeatureEnabled, shouldDisplayLiveStreamAndSettings } =
    useVideoDisplayFlags();

  const defaultActiveTab = !isFeatureEnabled
    ? VIDEO_SETTINGS_TABS.liveStreaming
    : VIDEO_SETTINGS_TABS.channel;

  const { activeTab = defaultActiveTab } = useParams();

  const history = useHistory();

  usePageTitle(t('app.menu.settings'));

  const selectSettingsTab = () =>
    history.push(
      `${VIDEO_ROUTES.videoSettings}/${VIDEO_SETTINGS_TABS.channel}`,
    );

  const tabsContent = {
    [VIDEO_SETTINGS_TABS.channel]: <VideoChannelSettings />,
    [VIDEO_SETTINGS_TABS.liveStreaming]: (
      <LiveStreaming onCancel={selectSettingsTab} />
    ),
  };

  const defineActiveTab = (tab) =>
    tab === VIDEO_SETTINGS_TABS.channel && !isFeatureEnabled
      ? VIDEO_SETTINGS_TABS.liveStreaming
      : activeTab;

  const selectedTab = tabsContent[activeTab]
    ? defineActiveTab(activeTab)
    : UNKNOWN;

  const renderContent = () => tabsContent[selectedTab] ?? <NotFound />;

  const onTabsChange = (_, val) => {
    history.push(`${VIDEO_ROUTES.videoSettings}/${val.toLowerCase()}`);
  };

  const redirectToNotFound = useCallback(
    () => history.push('/not-found'),
    [history],
  );

  const shouldRedirectToNotFound =
    (!isFeatureEnabled && activeTab === VIDEO_SETTINGS_TABS.channel) ||
    (!shouldDisplayLiveStreamAndSettings &&
      activeTab === VIDEO_SETTINGS_TABS.liveStreaming);

  useEffect(() => {
    if (shouldRedirectToNotFound) {
      redirectToNotFound();
    }
  }, [shouldRedirectToNotFound, redirectToNotFound]);

  const renderTabs = () => (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      value={selectedTab}
      onChange={onTabsChange}
      variant="scrollable"
    >
      {isFeatureEnabled && (
        <Tab
          value={VIDEO_SETTINGS_TABS.channel}
          label={t('vancoVideo.tabs.channel')}
        />
      )}
      {shouldDisplayLiveStreamAndSettings && (
        <Tab
          value={VIDEO_SETTINGS_TABS.liveStreaming}
          label={t('vancoVideo.tabs.liveStreaming')}
          disabledHintText={t(
            'vancoVideo.tabs.liveStreaming.addYoutubeChannel',
          )}
        />
      )}
    </Tabs>
  );

  return (
    <Grid container>
      <Page
        variant={
          activeTab === VIDEO_SETTINGS_TABS.channel
            ? containerVariants.CENTERED
            : containerVariants.NORMAL
        }
      >
        <PageTitle>{t('app.menu.settings')}</PageTitle>
        {renderTabs()}
        {renderContent()}
      </Page>
    </Grid>
  );
}

export default VideoSettings;
