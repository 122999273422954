import { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as globals from '../../../globals/constants';
import OpenAmount from './transactionItems/OpenAmount';
import PaymentFixedAmount from './transactionItems/PaymentFixedAmount';
import { hasError, getErrorMessage } from '../../../utils/campaign';
import { basketItemsMatch } from '../../../utils/basket';
import AddDonationForm from './form';
import ConfirmationDialog from '../ConfirmDialog/ConfirmationDialog';
import styles from './styles';

export function AddDonation({
  transactionActions,
  setError,
  transactionType,
  items,
}) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    errorModalOpen: false,
    errorModalMessage: null,
  });
  const formRef = useRef(null);

  const setErrorModalOpen = ({ isOpen, errorMessage }) => {
    setState((prevState) => {
      return {
        ...prevState,
        errorModalOpen: isOpen,
        errorModalMessage: isOpen ? errorMessage : null,
      };
    });
  };

  const renderTransactionItem = (formikProps) => {
    switch (transactionType) {
      case globals.TRANSACTION_ITEM_TYPES.OPEN_AMOUNT:
        return <OpenAmount id="OpenAmount" formikProps={formikProps} />;
      case globals.TRANSACTION_ITEM_TYPES.PAYMENT_FIXED_AMOUNT:
        return (
          <PaymentFixedAmount
            transactionDetails={formikProps.values}
            {...formikProps}
          />
        );
      default:
        return <Fragment key={1} />;
    }
  };

  /**
   * Catches the submit event from the form, compiles the data that's been passed up by each child transaction item, and sends it through redux to be added to the basket.
   */
  const addToBasket = (newItem, { resetForm }) => {
    if (hasError(newItem, items)) {
      setErrorModalOpen({
        isOpen: true,
        errorMessage: getErrorMessage(newItem, state.cartData),
      });
      return;
    }

    if (newItem.type !== globals.CAMPAIGN_TYPE.PAYMENT) {
      const matchingItems = items.some((it) => basketItemsMatch(it, newItem));
      if (matchingItems) {
        setErrorModalOpen({
          isOpen: true,
          errorMessage: t('basket.itemAlreadyInBasket'),
        });
        return;
      }
    }

    try {
      transactionActions.addToBasket(newItem);
      resetForm();
    } catch (e) {
      setError(e.message);
    }
  };

  const dismissErrorModal = () => {
    formRef.current.resetForm();
    setErrorModalOpen({ isOpen: false });
  };

  return (
    <>
      <AddDonationForm
        handleSubmit={addToBasket}
        renderTransactionItem={renderTransactionItem}
        transactionType={transactionType}
        isDirty={items.length > 0}
        ref={formRef}
      />
      <ConfirmationDialog
        title={t('basket.errorModal.title')}
        message={state.errorModalMessage}
        submitButtonText={t('button.gotIt')}
        onSubmit={dismissErrorModal}
        onClose={dismissErrorModal}
        isOpen={state.errorModalOpen}
      />
    </>
  );
}

AddDonation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  setError: PropTypes.func.isRequired,
  transactionActions: PropTypes.object.isRequired,
  transactionType: PropTypes.string.isRequired,
  enableDefaultFrequency: PropTypes.bool,
};

AddDonation.defaultProps = {
  enableDefaultFrequency: false,
};

export default withStyles(styles)(AddDonation);
