import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Close, ArrowDropDown } from '@material-ui/icons';
import {
  withStyles,
  useMediaQuery,
  Button,
  Typography,
} from '@material-ui/core';

import { handleRouteChange } from '../../../utils';
import MenuItem from '../MenuItem';
import { CustomDrawer } from '../../common';
import { SIDEBAR_WIDTH } from '../../../globals/dimensions';

import styles from './styles';
import SelectOrganizationModal from '../../common/SelectOrganizationModal';
import useSelectOrganizationModal from '../../../utils/hooks/useSelectOrganizationModal';

export function ProfileNav({
  classes,
  location,
  open,
  navActions,
  history,
  fullName,
  wasInternal,
  isInternal,
  associatedOrganizations,
  organizationName,
  organizationId,
}) {
  const { t } = useTranslation();
  const {
    isOpen: modalIsOpen,
    openModal,
    closeModal,
    onSelectOrganization,
  } = useSelectOrganizationModal();

  const currentPath = location.pathname;
  const staticLogoPages = ['wizard', 'internal', 'profile'];
  const isDesktop = useMediaQuery('(min-width:1280px)');
  const hideAccountSelector =
    staticLogoPages.some((s) => currentPath.includes(s)) ||
    isDesktop ||
    isInternal ||
    wasInternal;

  // close this nav on route change if its open
  useEffect(() => {
    return handleRouteChange(history, navActions);
  }, [history, navActions]);

  return (
    <>
      <CustomDrawer
        anchor="right"
        size={SIDEBAR_WIDTH}
        open={open}
        onClose={navActions.toggleProfileNav}
        blur
        elevated
        id="profile-nav"
        className={classes.menu}
      >
        <div className={classes.upperContent}>
          <Button
            className={classes.closeDesktop}
            onClick={navActions.toggleProfileNav}
            id="profile-nav-close-btn"
          >
            <Close className={classes.closeIcon} />
            <Typography variant="body1" className={classes.closeText}>
              {t('app.close')}
            </Typography>
          </Button>
          {fullName && (
            <Typography className={classes.name} variant="h4">
              {fullName}
            </Typography>
          )}
          {!hideAccountSelector && (
            <MenuItem
              active={false}
              onClick={openModal}
              label={
                /* eslint-disable-next-line react/jsx-wrap-multilines */
                <span className={classes.accountSelector}>
                  <Typography component="span" className={classes.accountName}>
                    {organizationName}
                  </Typography>
                  <ArrowDropDown />
                </span>
              }
            />
          )}
          <MenuItem
            label={t('app.profile')}
            active={currentPath === '/profile'}
            onClick={() => history.push('/profile')}
          />
          {wasInternal && !isInternal && (
            <MenuItem
              label={t('app.logoutOrg')}
              onClick={() => navActions.internalLogout()}
              active={false}
            />
          )}
          {!(wasInternal && !isInternal) && (
            <MenuItem
              label={t('app.logout')}
              active={currentPath === '/logout'}
              onClick={() => navActions.logout()}
            />
          )}
        </div>
        <div className={classes.drawerFooter}>
          <a
            href="https://www.vancopayments.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('profile.nav.privacyShort')}
          </a>
          &#8226;
          <a
            href="https://www.vancopayments.com/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('profile.nav.tosShort')}
          </a>
          &#8226;
          <span className={classes.copyright}>
            {t('profile.nav.vanco.copyright', {
              year: new Date().getFullYear(),
            })}
          </span>
        </div>
      </CustomDrawer>
      <SelectOrganizationModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        organizations={associatedOrganizations}
        selectedOrganizationId={organizationId}
        onSelectOrganization={onSelectOrganization}
      />
    </>
  );
}

ProfileNav.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  navActions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fullName: PropTypes.string,
  wasInternal: PropTypes.bool.isRequired,
  isInternal: PropTypes.bool.isRequired,
  associatedOrganizations: PropTypes.arrayOf(
    PropTypes.shape({
      organizationId: PropTypes.string.isRequired,
      organizationName: PropTypes.string.isRequired,
    }),
  ),
  organizationName: PropTypes.string,
  organizationId: PropTypes.string,
};

ProfileNav.defaultProps = {
  fullName: '',
  associatedOrganizations: [],
  organizationName: '',
  organizationId: '',
};

export default withStyles(styles)(withRouter(ProfileNav));
