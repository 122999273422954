import { useMemo } from 'react';
import { objectOf, string, node } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Page from '../Page';
import PagePaper from '../PagePaper';

import SetupLayoutContext from './context';
import Title from './title';
import Content from './content';
import Button from './button';
import CircularProgress from './circularProgress';

import styles from './styles';

export function SetupLayout({ classes, children }) {
  const contextProviderProps = useMemo(() => ({classes}), [classes]);
  return (
    <section className={classes.main}>
      <section className={classes.contentWrap}>
        <Page className={classes.contentContainer}>
          <PagePaper className={classes.container}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <SetupLayoutContext.Provider value={contextProviderProps}>
                  {children}
                </SetupLayoutContext.Provider>
              </Grid>
            </Grid>
          </PagePaper>
        </Page>
      </section>
    </section>
  );
}

SetupLayout.Title = Title;
SetupLayout.Content = Content;
SetupLayout.Button = Button;
SetupLayout.CircularProgress = CircularProgress;

SetupLayout.propTypes = {
  classes: objectOf(string).isRequired,
  children: node.isRequired,
};

export default withStyles(styles)(SetupLayout);
