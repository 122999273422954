export default function styles(theme) {
  const buttonCommon = {
    height: 40,
    borderRadius: 6,
  };
  return {
    section: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'end',
    },
    button: {
      ...buttonCommon,
    },
    saveButton: {
      marginLeft: theme.spacing(1),
      ...buttonCommon,
    },
  };
}
