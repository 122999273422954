export default theme => ({
  access: {
    width: 180,
    [theme.breakpoints.down('sm')]: {
      width: 110,
    },
  },
  members: {
    width: 180,
    [theme.breakpoints.down('sm')]: {
      width: 110,
    },
  },
  lastActivity: {
    width: 135,
  },
});
