import FeaturesService from '../../api/FeaturesService';
import {
  GET_FEATURES_START,
  GET_FEATURES_FINISH,
  GET_FEATURES_ERROR,
} from './types';
import { handleException, handleResponseError } from '../alerts/actions';
import Cookies from '../../utils/Cookies';

export function getFeatures() {
  return async dispatch => {
    dispatch({ type: GET_FEATURES_START });

    try {
      const res = await FeaturesService.getFeatures();

      if ([201, 200].includes(res.status)) {
        const features = res.data.data;
        Cookies.setFeaturesCookie(features);
        dispatch({
          type: GET_FEATURES_FINISH,
          data: features,
        });
      } else {
        dispatch({
          type: GET_FEATURES_ERROR,
          error: res.error,
        });
        dispatch(handleResponseError(res));
      }
    } catch (e) {
      dispatch({
        type: GET_FEATURES_ERROR,
        error: e.message,
      });
      dispatch(handleException(e));
    }
  };
}

export function loadFeatures() {
  return async dispatch => {
    dispatch({ type: GET_FEATURES_START });
    const features = Cookies.getFeaturesCookie();

    if (features) {
      dispatch({ type: GET_FEATURES_FINISH, data: features });
    } else {
      dispatch({ type: GET_FEATURES_FINISH, data: {} });
    }
  };
}
