import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import FeeDonationInputs from '../../../FeeDonationInputs';
import styles from './styles';

export function FeesForm({
  classes,
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  dirty,
  submitCount,
  isEditor,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Typography className={classes.header} variant="body2">
        {t('organization.fees.enable.title')}
      </Typography>
      <Grid item md={9} sm={12} xs={12}>
        <FeeDonationInputs
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          dirty={dirty}
          submitCount={submitCount}
          isEditor={isEditor}
        />
      </Grid>
    </div>
  );
}

FeesForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  submitCount: PropTypes.number.isRequired,
  isEditor: PropTypes.bool.isRequired,
};

export default withStyles(styles)(FeesForm);
