import { number, node } from 'prop-types';

import { useSwipeableContainerContext } from '../context';

export function Slide({ slideIndex, children }) {
  const { classes, currentSlideIndex } = useSwipeableContainerContext();
  return (
    <div
      className={
        currentSlideIndex === slideIndex ? classes.show : classes.hidden
      }
    >
      {children}
    </div>
  );
}

Slide.propTypes = {
  slideIndex: number.isRequired,
  children: node.isRequired,
};

export default Slide;
