import { shape, func, objectOf, string, arrayOf, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

function PageSelector({
  classes,
  page,
  onMouseEnter,
  onMouseLeave,
  errors,
  locations,
  onChange,
  disabled,
}) {
  return (
    <FormControlLabel
      key={page.id}
      control={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <Checkbox
            color="primary"
            checked={locations.some((l) => l.id === page.id)}
            className={
              errors && errors.locations
                ? classes.errorColor
                : classes.primaryColor
            }
            onChange={onChange}
            name={page.id}
            disabled={disabled}
          />
        </div>
      }
      label={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <div className={classes.descriptionMargin} aria-haspopup="true">
          <Typography>{page.pageName}</Typography>
        </div>
      }
      className={classes.secondaryRadioControl}
    />
  );
}

PageSelector.propTypes = {
  classes: objectOf(string).isRequired,
  page: shape({}).isRequired,
  locations: arrayOf(shape({})).isRequired,
  onMouseEnter: func,
  onMouseLeave: func,
  errors: objectOf(string).isRequired,
  onChange: func.isRequired,
  disabled: bool,
};

PageSelector.defaultProps = {
  onMouseEnter: null,
  onMouseLeave: null,
  disabled: false,
};

export default withStyles(styles)(PageSelector);
