import { Chip, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IMPORT_STATUS } from '../utils/const';
import styles from './styles';

const STATUS_LABELS = {
  [IMPORT_STATUS.COMPLETE]: 'ministry.platform.import.table.status.complete',
  [IMPORT_STATUS.FAILED]: 'ministry.platform.import.table.status.failed',
  [IMPORT_STATUS.IN_PROGRESS]:
    'ministry.platform.import.table.status.inProgress',
  [IMPORT_STATUS.COMPLETE_WITH_ERRORS]:
    'ministry.platform.import.table.status.completeErrors',
};
function StatusChip({ children, classes }) {
  const { t } = useTranslation();
  const label = STATUS_LABELS[children];
  const classesMap = {
    [IMPORT_STATUS.COMPLETE]: classes.complete,
    [IMPORT_STATUS.FAILED]: classes.failed,
    [IMPORT_STATUS.COMPLETE_WITH_ERRORS]: classes.completeWithErrors,
    [IMPORT_STATUS.IN_PROGRESS]: classes.incomplete,
  };
  return <Chip className={`${classesMap[children]}`} label={t(label)} />;
}

StatusChip.propTypes = {
  children: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(StatusChip);
