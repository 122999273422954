import BaseService from '../BaseService';

class CommunicationService extends BaseService {
  // eslint-disable-next-line class-methods-use-this
  getCommunicationsUrl = (template) => {
    return `communication/receipt/${template}`;
  };

  getDynamicTexts(template) {
    return this.http.get(this.getCommunicationsUrl(template));
  }

  saveDynamicTexts(template, values) {
    return this.http.put(this.getCommunicationsUrl(template), { data: values });
  }
}

export default new CommunicationService();
