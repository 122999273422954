import * as Yup from 'yup';
import i18n from '../../../../utils/i18n';

export const createSchema = () => {
  return Yup.object().shape({
    purposeEntry: Yup.string().required(
      i18n.t('modal.aiassist.purpose.required'),
    ),
    mainPointsEntry: Yup.string().required(
      i18n.t('modal.aiassist.mainpoints.required'),
    ),
    toneEntry: Yup.string(), // Optional field, no validation required
    responseLength: Yup.string(), // Optional field, no validation required
  });
};

export default createSchema;
