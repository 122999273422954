import moment from 'moment';
import { PAYMENT_FREQUENCY } from '../../globals/constants';

export const getInitialMonth = (selectedDate, allowedDates) => {
  if (selectedDate) {
    return selectedDate;
  }

  const date = moment();
  let showNextMonth = moment().endOf('month').diff(date, 'days') < 2;
  if (!showNextMonth && allowedDates) {
    showNextMonth = Math.max(...allowedDates) < date.date();
  }

  return showNextMonth ? date.add(1, 'M') : date;
};

export const getLatestAllowedDate = (date) => {
  return moment(date).endOf('day').subtract(1, 'day').add(1, 'year');
};

export const getLatestAllowedEndDate = (date) => {
  return moment(date).startOf('day').add(100, 'year');
};

export const getEarliestAllowedDate = (date, frequency) => {
  return moment(date)
    .startOf('day')
    .add(frequency !== PAYMENT_FREQUENCY.ONE_TIME_NOW ? 2 : 0, 'day');
};

export const formatBasketItemDate = (date) =>
  moment(date).format('MMMM D, YYYY');

export const formatDateToServerRequest = (date = '') =>
  moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : null;

export default {
  getInitialMonth,
  getLatestAllowedDate,
  getLatestAllowedEndDate,
  getEarliestAllowedDate,
  formatBasketItemDate,
  formatDateToServerRequest,
};
