import { SET_APP_LOADING } from './types';

export function setAppLoading(loading) {
  return {
    type: SET_APP_LOADING,
    loading,
  };
}

export default { setAppLoading };
