import { PAGE_ACTIVE } from '../../../globals/constants';
import i18n from '../../../utils/i18n';

// TODO: Remove with ChannelManagement FF cleanup

export const mobilePagePaths = {
  GET_STARTED: '/mobile/:pageId/get-started',
  INFO: '/wizard/mobile/:pageId/app-info',
  BRANDING: '/wizard/mobile/:pageId/branding',
  FEES: '/wizard/mobile/:pageId/processing-fees',
  PERMISSIONS: '/wizard/mobile/:pageId/permissions',
  REVIEW: '/wizard/mobile/:pageId/review',
};

export function createConfig() {
  const paths = mobilePagePaths;
  return [
    {
      path: paths.GET_STARTED,
    },
    {
      navTitle: i18n.t('mobilePageWizard.basicInfo.key'),
      pageTitleKey: 'mobilePageWizard.basicInfo.title',
      path: paths.INFO,
      nextPath: paths.BRANDING,
    },
    {
      navTitle: i18n.t('mobilePageWizard.branding.key'),
      pageTitleKey: 'mobilePageWizard.branding.title',
      path: paths.BRANDING,
      nextPath: paths.FEES,
    },
    {
      navTitle: i18n.t('mobilePageWizard.processingFees.key'),
      pageTitleKey: i18n.t('mobilePageWizard.processingFees.title'),
      path: paths.FEES,
      nextPath: paths.PERMISSIONS,
    },
    {
      navTitle: i18n.t('mobilePageWizard.communityFeatures.key'),
      pageTitleKey: 'mobilePageWizard.communityFeatures.title',
      path: paths.PERMISSIONS,
      nextPath: paths.REVIEW,
    },
    {
      navTitle: i18n.t('mobilePageWizard.review.key'),
      pageTitleKey: 'mobilePageWizard.review.title',
      path: paths.REVIEW,
      nextPath: '/mobile/home',
    },
  ];
}

export function mobilePageNavConfig(page, communityFeaturesChoosen) {
  let nextFound = false;
  const config = createConfig();
  const brandingKey = i18n.t('mobilePageWizard.branding.key');

  return config.map((step) => {
    const newStep = { ...step };
    switch (step.navTitle) {
      case i18n.t('mobilePageWizard.basicInfo.key'): {
        const finished =
          page.pageName && page.address && page.city && page.zip && page.state;
        if (finished) {
          newStep.isFinished = true;
        }
        break;
      }
      case brandingKey: {
        const finished = !!page.primaryColor;
        if (finished) {
          newStep.isFinished = true;
        }
        break;
      }
      case i18n.t('mobilePageWizard.processingFees.key'): {
        if (page.hasPaymentMethods) {
          newStep.isFinished = true;
        }
        break;
      }
      case i18n.t('mobilePageWizard.communityFeatures.key'): {
        if (
          page.hasCommunityFeatures ||
          communityFeaturesChoosen ||
          page?.mobileAppPermissions?.defaultGroupName
        ) {
          newStep.isFinished = true;
        }
        break;
      }
      default:
      // Do nothing
    }

    if (page.status === PAGE_ACTIVE) {
      newStep.isFinished = true;
    } else if (newStep.navTitle && !newStep.isFinished && !nextFound) {
      newStep.next = true;
      nextFound = true;
    }

    return newStep;
  });
}

export default mobilePageNavConfig;
