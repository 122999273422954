import { useCallback, useReducer } from 'react';

export function useCustomState(initalValues) {
  const [state, setState] = useReducer(
    (currentState, newState) => ({ ...currentState, ...newState }),
    initalValues,
  );

  const resetState = useCallback(() => setState(initalValues), [initalValues]);

  return [state, setState, resetState];
}

export default useCustomState;
