import { connect } from 'react-redux';

import PreventLeavePageModal from './PreventLeavePageModal';

export const mapStateToProps = state => ({
  changeAccountIsOpen: state.loadingDialog.open,
});

export default connect(
  mapStateToProps,
  null,
)(PreventLeavePageModal);
