import { PAGE_ACTIVE } from '../../../globals/constants';
import i18n from '../../../utils/i18n';

export const pagePathsDeprecated = {
  GET_STARTED: '/getStarted',
  INFO_WITHOUT_ID: '/wizard/page-info',
  INFO: '/wizard/:pageId/page-info',
  COLORS: '/wizard/:pageId/color-options',
  DONATIONS_OPTIONS: '/wizard/:pageId/donation-options',
  SOCIAL_SHARING: '/wizard/:pageId/social-sharing',
  REVIEW: '/wizard/page/:pageId/review',
};

export const pagePaths = {
  GET_STARTED: '/getStarted',
  INFO_WITHOUT_ID: '/wizard/page-info',
  INFO: '/wizard/:pageId/page-info',
};

export function createConfig(page, features) {
  const paths = features.ChannelManagement ? pagePaths : pagePathsDeprecated;
  const newNavTitlePath = page?.id ? paths.INFO : paths.INFO_WITHOUT_ID;

  if (features.ChannelManagement)
    return [
      {
        path: paths.GET_STARTED,
      },
      {
        navTitle: i18n.t('wizard.nav.basicInfo'),
        path: newNavTitlePath,
        pageTitleKey: 'wizard.createASite',
        next: true,
      },
    ];

  return [
    {
      path: paths.GET_STARTED,
    },
    {
      navTitle: i18n.t('wizard.nav.basicInfo'),
      path: newNavTitlePath,
      pageTitleKey: 'headerManagement.wizardTitle',
      nextPath: paths.COLORS,
    },
    {
      navTitle: i18n.t('wizard.nav.color'),
      path: paths.COLORS,
      nextPath: paths.DONATIONS_OPTIONS,
    },
    {
      navTitle: i18n.t('wizard.nav.processingFees'),
      path: paths.DONATIONS_OPTIONS,
      nextPath: paths.SOCIAL_SHARING,
    },
    {
      navTitle: i18n.t('wizard.nav.socialSharing'),
      path: paths.SOCIAL_SHARING,
      nextPath: paths.REVIEW,
    },
    {
      navTitle: i18n.t('wizard.nav.review'),
      path: paths.REVIEW,
      nextPath: '/tiles',
    },
    {
      path: '/tiles',
    },
  ];
}

export function pageNavConfig(page, features) {
  let nextFound = false;
  const config = createConfig(page, features);

  return config.map((step) => {
    const newStep = { ...step };
    switch (step.navTitle) {
      case i18n.t('wizard.nav.basicInfo'): {
        const finished =
          page.pageName &&
          (features.ChannelManagement ||
            page.phone ||
            page.email ||
            (page.address && page.city && page.zip && page.state));
        if (finished) {
          newStep.isFinished = true;
        }
        break;
      }
      case i18n.t('wizard.nav.color'): {
        if (page.primaryColor) {
          newStep.isFinished = true;
        }
        break;
      }
      // No case for processing fees since we always want it unfinished for now
      case i18n.t('wizard.nav.processingFees'): {
        if (page.hasPaymentMethods) {
          newStep.isFinished = true;
        }
        break;
      }
      case i18n.t('wizard.nav.socialSharing'): {
        if (
          page.allowSocialSharing !== null &&
          page.allowSocialSharing !== undefined
        ) {
          newStep.isFinished = true;
        }
        break;
      }
      default:
      // Do nothing
    }
    if (page.status === PAGE_ACTIVE) {
      newStep.isFinished = true;
    } else if (newStep.navTitle && !newStep.isFinished && !nextFound) {
      newStep.next = true;
      nextFound = true;
    }

    return newStep;
  });
}

export default pageNavConfig;
