export default function styles(theme) {
  return {
    label: {
      // fixes label from being overlapped by textfield border
      backgroundColor: theme.palette.white,
      paddingLeft: 5,
      paddingRight: 5,
    },
    errorOverride: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#FFF4F4',
      },
    },
  };
}
