import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SECTION_PERMISSIONS } from 'globals/grants';
import { IGNORE_PATHS_LIST, NOT_ALLOWED_ROUTE } from './routes.constants';
import useFeatures from '../useFeatures/useFeatures';
import usePermissions from '../usePermissions';
import useUser from '../useUser';

const usePermissionsRouter = () => {
  const userPermissions = usePermissions();
  // TODO: Remove internal validation
  const { wasInternal } = useUser();

  const [redirectRoute, setRedirectRoute] = useState('');
  const [fallbackRoute, setFallbackRoute] = useState('');
  const { pathname } = useLocation();

  const { AdminUserPermissions: FFAdminUserPermissions } = useFeatures();

  /**
   * Looks up for a specific permission subsection according to a given pathname
   */
  const getUserPermissionByPathname = useCallback(
    (currentRoute) => {
      return Object.keys(SECTION_PERMISSIONS).some((section) => {
        return Object.keys(SECTION_PERMISSIONS[section]).some((subsection) => {
          const { ROUTES: permissionRoutes, READ: readPermission } =
            SECTION_PERMISSIONS[section][subsection];
          if (
            Array.isArray(permissionRoutes) &&
            permissionRoutes?.some((route) => currentRoute.includes(route))
          ) {
            return Boolean(userPermissions[readPermission]);
          }
          return false;
        });
      });
    },
    [userPermissions],
  );

  // Iterates across one permissions section and returns first entry point
  const getFallbackRouteBySection = useCallback(
    (section) => {
      const foundPermission = Object.keys(section).find((subsection) => {
        const { READ: readPermission } = section[subsection];
        return userPermissions[readPermission];
      });

      if (!foundPermission) {
        return '';
      }

      const { ENTRY_POINT_ROUTE: permissionEntryPoint } =
        section[foundPermission];
      return permissionEntryPoint;
    },
    [userPermissions],
  );

  const getSectionEntryPoint = (section, defaultRoute = '') => {
    if (!FFAdminUserPermissions) return defaultRoute;

    return getFallbackRouteBySection(section);
  };

  /**
   * Will iterate across all the permissions to find the first avaialable permission
   * following the leftnav position order of the subsections
   */
  const getFallbackRouteByPermission = useCallback(() => {
    let initialEntryPoint = NOT_ALLOWED_ROUTE;
    Object.keys(SECTION_PERMISSIONS).some((section) => {
      const currentSection = SECTION_PERMISSIONS[section];
      const entryPoint = getFallbackRouteBySection(currentSection);

      if (entryPoint) {
        initialEntryPoint = entryPoint;
        return true;
      }
      return false;
    });
    return initialEntryPoint;
  }, [getFallbackRouteBySection]);

  useEffect(() => {
    if (!FFAdminUserPermissions) {
      // Previous implementation was to redirecto to /tiles
      setFallbackRoute(SECTION_PERMISSIONS.GIVING.TILES.ENTRY_POINT_ROUTE);
      return;
    }
    if (userPermissions.permissionsLoading) {
      return;
    }
    const route = getFallbackRouteByPermission();
    setFallbackRoute(route);
  }, [getFallbackRouteByPermission, userPermissions, FFAdminUserPermissions]);

  const getUserRedirectRoute = useCallback(
    (route) => {
      const isAllowed =
        IGNORE_PATHS_LIST.some((path) => pathname.includes(path)) ||
        pathname === '/' ||
        getUserPermissionByPathname(route);
      if (isAllowed) {
        return '';
      }
      return NOT_ALLOWED_ROUTE;
    },
    [getUserPermissionByPathname, pathname],
  );

  useEffect(() => {
    if (!FFAdminUserPermissions || wasInternal) {
      return;
    }
    const redirectUserRoute = getUserRedirectRoute(pathname);
    setRedirectRoute(redirectUserRoute);
  }, [
    pathname,
    getUserRedirectRoute,
    userPermissions,
    FFAdminUserPermissions,
    wasInternal,
  ]);

  return {
    fallbackRoute,
    redirectRoute,
    getSectionEntryPoint,
    isLoading: userPermissions.permissionsLoading,
  };
};

export default usePermissionsRouter;
