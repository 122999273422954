export default async text => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
  } else {
    // fallback for browsers that do not support Clipboard API (in other words, Internet Explorer)
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.value = text;
    input.select();
    input.focus();
    document.execCommand('copy');
    document.body.removeChild(input);
  }
};
