import getOpener from './getOpener';
import getParent from './getParent';

export default function getAncestor(win: Window = window): Window | null {
  const opener = getOpener(win);

  if (opener) {
    return opener;
  }

  const parent = getParent(win);

  if (parent) {
    return parent;
  }
  return null;
}
