import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { NotFound, NoActiveChannel } from '../../common';
import { userIsAllowed } from '../../../utils/permissions';
import withScrollToTop from '../../hoc/withScrollToTop';
import { PAGE_ACTIVE } from '../../../globals/constants';
import RetryLoading from '../../common/RetryLoading';

export function PrivateRouteWithPages({
  isLoggedIn,
  loadingAuth,
  currentPage,
  component: Component,
  grantedActions,
  requiredGrants,
  optionalGrants,
  pages,
  setCurrentPage,
  loadingPages,
  pageError,
  ...rest
}) {
  useEffect(() => {
    // At this moment we don't have the full integration with Vanco Mobile
    // so in order to creating or fetching tiles we need to send a location id it doesn't matter the channel type
    const activePage = pages.find((p) => p.status === PAGE_ACTIVE);
    if (!currentPage.id && activePage) {
      setCurrentPage(activePage);
    }
  }, [currentPage, setCurrentPage, pages]);

  const WrappedComponent = useMemo(
    () => withScrollToTop(Component),
    [Component],
  );

  const renderRoute = (props) => {
    const { location } = props;
    if (loadingAuth || loadingPages) {
      return null;
    }
    if (
      isLoggedIn &&
      !userIsAllowed(grantedActions, requiredGrants, optionalGrants)
    ) {
      return <NotFound />;
    }
    if (isLoggedIn && !currentPage.id && !pageError) {
      return <NoActiveChannel />;
    }

    if (isLoggedIn && !currentPage.id && pageError) {
      return <RetryLoading />;
    }

    if (isLoggedIn) {
      return <WrappedComponent {...rest} {...props} />;
    }
    return (
      <Redirect
        data-testid="redirect"
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  };

  return <Route {...rest} render={renderRoute} />;
}

PrivateRouteWithPages.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  location: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loadingAuth: PropTypes.bool.isRequired,
  currentPage: PropTypes.object.isRequired,
  grantedActions: PropTypes.arrayOf(PropTypes.string).isRequired,
  requiredGrants: PropTypes.arrayOf(PropTypes.string),
  optionalGrants: PropTypes.arrayOf(PropTypes.string),
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  loadingPages: PropTypes.bool.isRequired,
  pageError: PropTypes.string.isRequired,
};

PrivateRouteWithPages.defaultProps = {
  requiredGrants: [],
  optionalGrants: [],
};

export default withRouter(PrivateRouteWithPages);
