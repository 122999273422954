import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import { open, close } from '../../../store/loadingDialog/actions';
import { Page } from '../../common';
import { internalLogin } from '../../../store/internal/actions';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

export default function InternalLogin() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  usePageTitle(t('titles.login'));
  const { orgId } = useParams();
  const grantedActions = useSelector((state) => state.user.user.grantedActions);
  const internalLoginAllowed = grantedActions.includes(
    'USER_TENANTASSOCIATION',
  );
  const userLoginFinished = grantedActions.length > 0;

  useEffect(() => {
    dispatch(open(t('loading.switchingAccounts')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userLoginFinished) {
      if (!internalLoginAllowed) {
        dispatch(close());
      } else {
        dispatch(internalLogin({ id: orgId })).then(() => dispatch(close()));
      }
    }
  }, [dispatch, userLoginFinished, orgId, internalLoginAllowed]);

  return (
    <Page>
      {userLoginFinished && !internalLoginAllowed && (
        <Grid container justify="center">
          <Typography style={{ marginTop: 50 }} variant="h2">
            {t('errors.mustBeInternalUser')}
          </Typography>
        </Grid>
      )}
    </Page>
  );
}

InternalLogin.propTypes = {};
