export default function styles(theme) {
  return {
    hidden: {
      display: 'none',
    },
    heading: {
      marginTop: 16,
      marginBottom: 16,
    },
    subItem: {
      fontSize: '1.4rem',
      color: theme.palette.secondary.medium,
    },
    descriptionTextField: {
      minHeight: 150,
    },
    shortDescriptionTextContainer: {
      marginTop: 8,
    },
    shortDescriptionTextField: {
      minHeight: 100,
    },
    smallFont: {
      fontSize: '1.4rem',
    },
    shortDescriptionContainer: {
      margin: '30px 0',
    },
    divider: {
      margin: '20px 0 20px',
      color: '#EBEBEB',
    },
    buttonLabelDivider: {
      margin: '12px 0 10px 50px',
      color: '#EBEBEB',
    },
    buttonLabelHeader: {
      padding: '14px 0 12px 16px',
      color: '#999999',
      fontSize: '1.3rem',
    },
    inlineItems: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 20,
    },
    doneIcon: {
      marginLeft: -2,
      paddingRight: 8,
      width: 32,
      height: 32,
    },
    unselected: {
      paddingLeft: 30,
    },
  };
}
