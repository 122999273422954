import {
  PAGE_FACEBOOK_VIDEO,
  PAGE_PLATFORM_FACEBOOK,
  PAGE_YOUTUBE_VIDEO,
} from 'globals/constants';
import moment from 'moment';
import UseFacebookEmbedCode from 'utils/hooks/useFacebookEmbedCode';
import * as Yup from 'yup';
import { validations } from '../../../utils';

import i18n from '../../../utils/i18n';
import { maxLengths } from '../../../utils/validations';

export const { pageName: pageNameLength } = maxLengths;

export const createSchema = () => {
  const schema = Yup.object().shape({
    title: Yup.string()
      .max(maxLengths.pageName, i18n.t('validation.long'))
      .required(
        i18n.t('validation.required', {
          requiredNoun: i18n
            .t('stream.page.branding.streamPageName')
            .toLocaleLowerCase(),
        }),
      ),
    tileId: Yup.string(),
    siteId: Yup.string().test('tileId', function testTileId() {
      const { tileId, siteId } = this.parent;
      return tileId || siteId
        ? true
        : this.createError({
            message: 'Select a campaign or a site.', // This error is not displayed, only used to disable Submit button
          });
    }),
    platform: Yup.string().test('platform', function validate(value) {
      return value?.length > 0
        ? true
        : this.createError({
            message: i18n.t(
              'stream.page.videoDetails.videoUrl.platform.validation',
            ),
          });
    }),
    videoType: Yup.string(),
    videoUrl: Yup.string().test('videoUrl', function validate(value) {
      const { videoType, platform } = this.parent;
      if (platform === PAGE_PLATFORM_FACEBOOK) return true;
      if (!value || value.length === 0)
        return this.createError({
          message: i18n.t('stream.page.youtubeUrl.validation.required'),
        });
      if (videoType === PAGE_YOUTUBE_VIDEO) {
        if (validations.youtubeUrlRegex.test(value)) return true;
        return this.createError({
          message: i18n.t('stream.page.youtubeUrl.validation'),
        });
      }
      if (validations.youtubeChannelRegex.test(value)) return true;
      return this.createError({
        message: i18n.t('stream.page.youtubeUrl.channel.validation'),
      });
    }),
    startDate: Yup.date().required(
      i18n.t('validation.pleaseEnterA', {
        requiredNoun: i18n
          .t('stream.page.videoDetails.schedule.startDate')
          .toLocaleLowerCase(),
      }),
    ),
    endDate: Yup.date().when('videoType', {
      is: (videoType) =>
        videoType === PAGE_YOUTUBE_VIDEO || videoType === PAGE_FACEBOOK_VIDEO,
      then: (s) =>
        s
          .required(
            i18n.t('validation.pleaseEnter', {
              requiredNoun: `an ${i18n
                .t('stream.page.videoDetails.schedule.endDate')
                .toLocaleLowerCase()}`,
            }),
          )
          .test('endDate', function validate(value) {
            const { startDate } = this.parent;

            const startDateOnly = moment(startDate).startOf('day');
            const endDateOnly = moment(value).startOf('day');

            const isValid = endDateOnly.isSameOrAfter(startDateOnly);
            return isValid
              ? true
              : this.createError({
                  message: i18n.t(
                    'stream.page.videoDetails.schedule.endDate.validation',
                    {
                      requiredNoun: startDate.toLocaleDateString(),
                    },
                  ),
                });
          }),
    }),

    startTime: Yup.string().matches(
      validations.timeFormat,
      'Specify start time.',
    ),
    endTime: Yup.string()
      .test('endTime', function validate(value) {
        const { startTime, startDate, endDate } = this.parent;

        const referenceEndTime = moment(value, 'h:mm A');
        const referenceStartTime = moment(startTime, 'h:mm A');

        const startDateOnly = moment(startDate).startOf('day');
        const endDateOnly = moment(endDate).startOf('day');

        const areDatesValid = startDateOnly.isBefore(endDateOnly);

        const isValid = startDateOnly.isSame(endDateOnly)
          ? referenceEndTime.isAfter(referenceStartTime)
          : referenceEndTime.isSameOrAfter(referenceStartTime);

        return isValid || areDatesValid
          ? true
          : this.createError({
              message: i18n.t('validation.pleaseEnter', {
                requiredNoun: `a valid ${i18n
                  .t('stream.page.videoDetails.schedule.endTime')
                  .toLocaleLowerCase()}`,
              }),
            });
      })
      .matches(validations.timeFormat, 'Specify end time.')
      .required(
        i18n.t('validation.pleaseEnter', {
          requiredNoun: `an ${i18n
            .t('stream.page.videoDetails.schedule.endTime')
            .toLocaleLowerCase()}`,
        }),
      ),
    embedCode: Yup.string().when('platform', {
      is: PAGE_PLATFORM_FACEBOOK,
      then: (s) =>
        s.test('test-url', function validate(value) {
          const {
            isEmbedCode,
            isTinyUrl,
            isValidTinyUrl,
            isValidFullURL,
            extractUrlFromEmbed,
          } = UseFacebookEmbedCode();

          if (!value) {
            return this.createError({
              message: i18n.t('validation.pleaseEnter', {
                requiredNoun: i18n
                  .t('stream.page.videoDetails.videoUrl.embedCode')
                  .toLocaleLowerCase(),
              }),
            });
          }

          if (isEmbedCode(value)) {
            const extractedUrl = extractUrlFromEmbed(value);
            // extraction process has already validated the structure of the url
            return extractedUrl === ''
              ? this.createError({
                  message: i18n.t('stream.page.facebook.validation'),
                })
              : true;
          }

          if (isTinyUrl(value)) {
            return isValidTinyUrl(value)
              ? true
              : this.createError({
                  message: i18n.t('stream.page.facebookUrl.validation'),
                });
          }

          return isValidFullURL(value)
            ? true
            : this.createError({
                message: i18n.t('stream.page.facebookUrl.validation'),
              });
        }),
    }),
  });

  return schema;
};
