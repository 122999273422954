export default function styles(theme) {
  return {
    subtitle: {
      marginTop: 15,
    },
    divider: {
      marginLeft: '-24px',
      marginRight: '-24px',
      width: '110%',
    },
    sectionTitle: {
      marginTop: theme.spacing(3),
    },
    sectionVertGutter: {
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    radioGroupItem: {
      alignItems: 'baseline',
      marginRight: 0,
    },
    radioGroupLabel: {
      alignSelf: 'flex-start',
      width: '100%',
    },
    radioButton: {
      marginTop: 6,
    },
    hidden: {
      display: 'none',
    },
    show: {
      display: 'auto',
    },
    errorOverride: {
      backgroundColor: '#FFF4F4',
      border: `1px solid ${theme.palette.text.critical}`,
      borderRadius: 4,
    },
    labelOverride: {
      background: 'none',
      color: theme.palette.text.default,
    },
    helperText: {
      margin: 0,
      fontSize: 16,
      color: theme.palette.text.critical,
      display: 'inline',
    },
    content: {
      display: 'flex',
      flex: 1,
      marginTop: 24,
      marginBottom: 24,
    },
    selectSpinner: {
      marginTop: 16,
      marginBottom: 8,
    },
    mainDescription: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: theme.palette.text.subdued,
    },
    descriptionText: {
      color: theme.palette.text.subdued,
    },
    spinnerContainer: {
      marginTop: theme.spacing(2),
    },
  };
}
