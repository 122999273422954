export default function styles(theme) {
  return {
    subtitle: {
      lineHeight: '1.43',
      maxWidth: 640,
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 40,
      '& button': {
        '&:first-of-type': {
          marginRight: 5,
        },
        [theme.breakpoints.down('xs')]: {
          '&:first-of-type': {
            marginRight: 0,
          },
        },
      },
    },
  };
}
