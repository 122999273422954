export default () => ({
  modalContent: {
    padding: 0,
    textAlign: 'center',
  },
  videoPlayer: {
    width: '100%',
    height: 'auto',
  },
  dialogPaper: {
    width: '100%',
    maxWidth: '1000px',
    borderRadius: '8px',
    boxShadow:
      '0px 1px 3px 0px rgba(60,64,67,0.3), 0px 4px 8px 3px rgba(60,64,67,0.15)',
  },
  actions: {
    padding: 10,
  },
});
