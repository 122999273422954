import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { objectOf, string } from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {
  MOBILE_USER_ROLE,
  MOBILE_USER_TAB_ALL,
  CHANNEL_TYPE,
  PAGE_ACTIVE,
} from '../../../globals/constants';
import {
  useGetMobilePageUsers,
  useInviteMobileUsers,
  useGetPage,
} from '../../../utils/hooks';
import { copyText } from '../../../utils';
import { setSuccess } from '../../../store/alerts/actions';
import { ReactComponent as MailIcon } from '../../../assets/mail.svg';
import { ReactComponent as CopyIcon } from '../../../assets/copyBlackIcon.svg';
import Page from '../Page';
import PageTitle from '../PageTitle';
import PagePaper from '../PagePaper';
import ButtonMenu from '../ButtonMenu';
import Tabs from './Tabs';
import UsersList from './UsersList';
import InviteByCodeModal from './InviteByCodeModal';
import InviteByEmailModal from './InviteByEmailModal';

import styles from './styles';
import usePageTitle from '../../../utils/hooks/usePageTitle/usePageTitle';

const tabPaths = {
  [MOBILE_USER_TAB_ALL]: MOBILE_USER_TAB_ALL,
  [MOBILE_USER_ROLE.MEMBER.toLowerCase()]: MOBILE_USER_ROLE.MEMBER,
  [MOBILE_USER_ROLE.MODERATOR.toLowerCase()]: MOBILE_USER_ROLE.MODERATOR,
  [MOBILE_USER_ROLE.STAFF.toLowerCase()]: MOBILE_USER_ROLE.STAFF,
};

export function VancoMobileUsers({ classes }) {
  const { t } = useTranslation();
  usePageTitle(t('vanco.mobile.home.users.viewUsers'));
  const dispatch = useDispatch();
  const { page } = useGetPage(CHANNEL_TYPE.MOBILE, PAGE_ACTIVE);
  const { users, invitationCode, status } = useGetMobilePageUsers();
  const { mutate, status: inviteStatus } = useInviteMobileUsers();
  const { userRole = MOBILE_USER_TAB_ALL } = useParams();
  const [inviteByCodeModalOpen, setInviteByCodeModalOpen] = useState(false);
  const [inviteByEmailModalOpen, setInviteByEmailModalOpen] = useState(false);
  const userTab = tabPaths[userRole] || MOBILE_USER_TAB_ALL;
  const history = useHistory();
  const openInviteByCode = () => setInviteByCodeModalOpen(true);
  const closeInviteByCodeModal = () => setInviteByCodeModalOpen(false);
  const openInviteByEmailModal = () => setInviteByEmailModalOpen(true);
  const closeInviteByEmailModal = () => setInviteByEmailModalOpen(false);

  const goBack = () => {
    history.push('/mobile/home');
  };

  const onChangeTab = (_, val) => {
    history.push(`/mobile/users/${val.toLowerCase()}`);
  };

  const onCopyCode = (code) => {
    copyText(code);
    dispatch(setSuccess('Invite code copied.'));
  };

  const onEmailFormSubmit = ({ emails }, { resetForm }) => {
    mutate(
      { users: emails },
      {
        onSuccess: () => {
          closeInviteByEmailModal();
          resetForm();
        },
      },
    );
  };

  return (
    <Page>
      {status === 'loading' && null}
      {status === 'success' && (
        <>
          <div className={classes.titleContainer}>
            <PageTitle onGoBack={goBack} readOnly={!page.hasCommunityFeatures}>
              {t('vanco.mobile.appUsers.title')}
            </PageTitle>
            <ButtonMenu
              buttonContent={t('vanco.mobile.home.users.invite.button')}
            >
              <ButtonMenu.Item onClick={openInviteByEmailModal}>
                <MailIcon className={classes.icon} />
                <Typography>
                  {t('vanco.mobile.home.users.invite.by.email')}
                </Typography>
              </ButtonMenu.Item>
              {invitationCode && (
                <ButtonMenu.Item onClick={openInviteByCode}>
                  <CopyIcon className={classes.icon} />
                  <Typography>
                    {t('vanco.mobile.home.users.copy.invite.code')}
                  </Typography>
                </ButtonMenu.Item>
              )}
            </ButtonMenu>
          </div>
          <Tabs value={userTab} onChange={onChangeTab} />
          <PagePaper className={classes.pagePaper}>
            <UsersList users={users} userTab={userTab} />
          </PagePaper>
        </>
      )}
      <InviteByCodeModal
        invitationCode={invitationCode}
        open={inviteByCodeModalOpen}
        onClose={closeInviteByCodeModal}
        onCopyCode={onCopyCode}
      />
      <InviteByEmailModal
        open={inviteByEmailModalOpen}
        onClose={closeInviteByEmailModal}
        onSubmit={onEmailFormSubmit}
        isSubmitting={inviteStatus === 'loading'}
      />
    </Page>
  );
}

VancoMobileUsers.propTypes = {
  classes: objectOf(string).isRequired,
};

export default withStyles(styles)(VancoMobileUsers);
