import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../Modal';
import styles from './styles';
import DatePicker from '../DatePicker';
import CustomFormik from '../CustomFormik';
import createSchema from './validation';

function KioskDisableModal({
  classes,
  open,
  hasKiosk,
  onClose,
  disableKiosk,
  enableKiosk,
  setSuccess,
  onChangeSuccess,
}) {
  const { t } = useTranslation();
  const currentDate = moment().startOf('day');

  const onSubmitChange = async (values) => {
    // if value didn't change, dismiss the modal
    if (hasKiosk === values.isKioskEnabled) {
      onClose();
      return;
    }
    if (values.isKioskEnabled) {
      await enableKiosk();
      setSuccess(t('kiosk.setup.settings.manage.enableSuccess'));
    } else {
      await disableKiosk(values.disableDate);
      setSuccess(t('kiosk.setup.settings.manage.disableSuccess'));
    }
    onClose();
    onChangeSuccess();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title className={classes.modalTitle}>
        {t('kiosk.setup.settings.manage.title')}
      </Modal.Title>
      <Modal.Content className={classes.modalContent}>
        <CustomFormik
          initialValues={{
            disableDate: undefined,
            isKioskEnabled: hasKiosk,
          }}
          validationSchema={createSchema()}
          onSubmit={onSubmitChange}
          render={(formikProps) => {
            const {
              values: { isKioskEnabled, disableDate },
              setFieldValue,
              submitCount,
            } = formikProps;

            const dateError = formikProps.errors.disableDate && submitCount > 0;
            const toggleEnabled = () => {
              setFieldValue({
                target: {
                  name: 'isKioskEnabled',
                  value: !isKioskEnabled,
                },
              });
            };
            const handleDateChange = (name, value) => {
              setFieldValue({
                target: {
                  name,
                  value: value ? value.startOf('day') : undefined,
                },
              });
            };
            return (
              <>
                <Typography>
                  {t('kiosk.setup.settings.manage.description')}
                </Typography>
                <RadioGroup name="isKioskEnabled" onChange={toggleEnabled}>
                  <FormControlLabel
                    className={classes.radioButton}
                    value={isKioskEnabled}
                    control={<Radio color="primary" checked={isKioskEnabled} />}
                    label={t('kiosk.setup.settings.manage.option.yes')}
                  />
                  <FormControlLabel
                    className={classes.radioButton}
                    classes={{
                      root: classes.disableRadio,
                      label: classes.disableRadioLabel,
                    }}
                    control={
                      <Radio
                        color="primary"
                        className={classes.radioIcon}
                        checked={!isKioskEnabled}
                      />
                    }
                    labelPlacement="end"
                    label={
                      <>
                        <Typography className={classes.labelHeight}>
                          {t('kiosk.setup.settings.manage.option.no')}
                        </Typography>

                        {!isKioskEnabled && (
                          <>
                            <Typography className={classes.dateDescription}>
                              {t(
                                'kiosk.setup.settings.manage.date.description',
                              )}
                            </Typography>
                            <FormControl
                              fullWidth
                              className={classes.datePicker}
                            >
                              <Field
                                item
                                id="disableDate"
                                name="disableDate"
                                value={disableDate}
                                earliestAllowedDate={currentDate}
                                handleDateChange={handleDateChange}
                                hasError={dateError}
                                component={DatePicker}
                                position="relative"
                              />
                            </FormControl>
                          </>
                        )}
                      </>
                    }
                  />
                </RadioGroup>
                <Divider className={classes.bottomDivider} />
                <span className={classes.actionButtonGroup}>
                  <Button
                    color="primary"
                    onClick={onClose}
                    disabled={formikProps.isSubmitting}
                  >
                    {t('kiosk.setup.cancel')}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={formikProps.isSubmitting}
                    onClick={formikProps.handleSubmit}
                  >
                    {t('kiosk.setup.save')}
                  </Button>
                </span>
              </>
            );
          }}
        />
      </Modal.Content>
    </Modal>
  );
}

KioskDisableModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  hasKiosk: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  disableKiosk: PropTypes.func.isRequired,
  enableKiosk: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  onChangeSuccess: PropTypes.func,
};

KioskDisableModal.defaultProps = {
  onClose: () => {},
  onChangeSuccess: () => {},
};
export default withStyles(styles)(KioskDisableModal);
