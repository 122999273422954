import { connect } from 'react-redux';

import Users from './Users';
import {
  getUsers,
  createUser,
  clearUserError,
  setCreateUserModalOpen,
  setRemoveUserModalOpen,
  setEditUserModalOpen,
  setEditUserPermissionsModalOpen,
  setEmailSettingsModalOpen,
  removeUser,
  transferOwnership,
  patchUser,
} from '../../../store/user/actions';
import { transformUserOrgBackendError } from '../../../store/user/transform';

export function mapStateToProps(state) {
  return {
    currentUser: state.user.session.idTokenPayload,
    isInternalSession: state.user.user.wasInternal,
    users: state.user.orgUsers.sort((a, b) => {
      const aSort = a.firstName?.toLowerCase() || a.username?.toLowerCase();
      const bSort = b.firstName?.toLowerCase() || b.username?.toLowerCase();
      return aSort < bSort ? -1 : 1;
    }),
    orgId: state.organizations.currentOrganization.id,
    loadingUserCreate: state.user.loadingCreate,
    loadingUserRemove: state.user.loadingRemove,
    loadingUserEdit: state.user.loadingEdit,
    loadingEmailSettings: state.user.loadingEmailSettings,
    backendError: state.user.error
      ? transformUserOrgBackendError(state.user.error)
      : null,
    showCreateUserModal: state.user.showCreateUserModal,
    showRemoveUserModal: state.user.showRemoveUserModal,
    showEditUserModal: state.user.showEditUserModal,
    showEmailSettingsModal: state.user.showEmailSettingsModal,
    userToRemove: state.user.userToRemove,
    userToEditRole: state.user.userToEditRole,
    userToEditEmail: state.user.userToEditEmail,
    userToEditPermissions: state.user.userToEditPermissions,
    showEditUserPermissionsModal: state.user.showEditUserPermissionsModal,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  createUser,
  clearUserError,
  setCreateUserModalOpen,
  setRemoveUserModalOpen,
  setEditUserModalOpen,
  setEmailSettingsModalOpen,
  setEditUserPermissionsModalOpen,
  removeUser,
  transferOwnership,
  patchUser,
})(Users);
