/* Frontend fund to a backend representation of a fund */
export const frontendToBackend = fund => {
  return {
    accountCode: fund.accountCode,
    fundRefId: fund.fundRefId,
    bankAccountId: fund.bankAccount,
    displayName: fund.name,
  };
};

/* Backend fund to a frontend representation of a fund */
export const backendToFrontend = fund => {
  return {
    accountCode: fund.accountCode,
    fundRefId: fund.fundRefId,
    bankAccount: fund.bankAccountId,
    name: fund.displayName,
  };
};

export default { frontendToBackend, backendToFrontend };
