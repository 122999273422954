import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';
import tileImage from './tile.png';
import PageTitle from '../PageTitle';
import PageDescription from '../PageDescription';
import PrimaryButton from '../PrimaryButton';
import LinkButton from '../LinkButton';

export function TileCreate({
  classes,
  goToNext,
  newTile,
  createTile,
  loadingCreate,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (goToNext && newTile.id) {
      goToNext();
    }
  }, [newTile, goToNext]);

  const onCreate = () => {
    createTile();
  };

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <PageTitle className={classes.title}>
            {t('wizard.tileCreate.title')}
          </PageTitle>
          <img src={tileImage} alt="tile" className={classes.mobileImage} />
          <PageDescription className={classes.description}>
            {t('wizard.tileCreate.description')}
          </PageDescription>
          {!loadingCreate && (
            <>
              <PrimaryButton size="large" onClick={onCreate}>
                {t('wizard.tileCreate.leftButton')}
              </PrimaryButton>
              <LinkButton size="large" color="primary" to="/tiles">
                {t('wizard.tileCreate.rightButton')}
              </LinkButton>
            </>
          )}
          {loadingCreate && <CircularProgress color="primary" />}
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <img src={tileImage} alt="tile" className={classes.image} />
        </Grid>
      </Grid>
    </div>
  );
}

TileCreate.propTypes = {
  classes: PropTypes.object.isRequired,
  goToNext: PropTypes.func.isRequired,
  newTile: PropTypes.object.isRequired,
  createTile: PropTypes.func.isRequired,
  loadingCreate: PropTypes.bool.isRequired,
};

export default withStyles(styles)(TileCreate);
