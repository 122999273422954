import { shape, func, objectOf, string, arrayOf } from 'prop-types';
import { format } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { Typography, Hidden } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';

import InsertLinkIcon from '@material-ui/icons/InsertLink';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { ReactComponent as EditIcon } from '../../../../assets/edit-20px.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete-20px.svg';
import { ReactComponent as ArchiveIcon } from '../../../../assets/archive-20px.svg';
import TileStatus from '../TileStatus';
import TileChannels from '../TileChannels';
import { PopoverMenu, TableRow, TableCell } from '../../../common';
import { constants } from '../../../../globals';
import tileNavConfig from '../../../navigation/WizardLayout/tile.config';
import { copyText } from '../../../../utils';

import styles from './styles';

export function Tile({
  classes,
  tile,
  pages,
  onClick,
  onArchivedTile,
  onDeleteTile,
  onUnarchiveTile,
  onGenerateQRCode,
  setSuccess,
  primaryLocatioURI,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const tileLink = `${window.env.REACT_APP_DON_URL}/${primaryLocatioURI}/campaign/${tile.id}?access=tile_direct`;

  const onSelect = () => {
    if (onClick) {
      onClick(tile);
    }
  };

  const copyTileLink = () => {
    copyText(tileLink);
    setSuccess(t('siteSettings.linkCopied'));
  };

  const archivedTile = () => {
    if (onArchivedTile) {
      onArchivedTile(tile);
    }
  };

  const deleteTile = () => {
    if (onDeleteTile) {
      onDeleteTile(tile);
    }
  };

  const generateQRCode = () => {
    if (onGenerateQRCode) {
      onGenerateQRCode(tile);
    }
  };

  const unarchiveTile = () => {
    if (onUnarchiveTile) {
      onUnarchiveTile(tile);
      queryClient.removeQueries('tile');
    }
  };

  const onEditTile = () => {
    const editPath =
      tile.status === constants.TILE_PENDING
        ? tileNavConfig(tile)
            .find((step) => step.next)
            .path.replace(':tileId', tile.id)
        : `/tile/${tile.id}`;
    history.push(editPath);
  };

  return (
    <TableRow onClick={onSelect} data-testid={tile.id}>
      <TableCell className={!tile.title ? classes.untitled : ''}>
        <Typography component="span">{tile.title || 'Untitled'}</Typography>
      </TableCell>
      {tile.status !== constants.TILE_ARCHIVED ? (
        [
          <TableCell className={classes.smallColumn} key={1}>
            <TileStatus tile={tile} />
          </TableCell>,
          <Hidden xsDown key={2}>
            <TableCell className={classes.smallColumn}>
              <TileChannels tile={tile} pages={pages} />
            </TableCell>
          </Hidden>,
          <Hidden smDown key={3}>
            <TableCell className={classes.smallColumn}>
              <Typography component="span">
                {format(new Date(tile.lastUpdatedAt), 'MMM d, yyyy')}
              </Typography>
            </TableCell>
          </Hidden>,
        ]
      ) : (
        <TableCell className={classes.smallColumn}>
          <Typography component="span">
            {tile.archivedDate
              ? format(new Date(tile.archivedDate), 'MMM d, yyyy')
              : ''}
          </Typography>
        </TableCell>
      )}
      <TableCell className={classes.moreMenu} menu>
        <PopoverMenu>
          <PopoverMenu.Item onSelect={onEditTile}>
            <EditIcon />
            {t('tile.edit')}
          </PopoverMenu.Item>
          <PopoverMenu.Divider />
          {tile.status === constants.TILE_ACTIVE && [
            <PopoverMenu.Item onSelect={generateQRCode} key="site.qrCode">
              <QrCodeIcon className={classes.icon} sx={{ fontSize: 24 }} />
              {t('site.qrCode')}
            </PopoverMenu.Item>,
            <PopoverMenu.Item onSelect={copyTileLink} key="tile.copyTileLink">
              <InsertLinkIcon className={classes.icon} />
              {t('tile.copyTileLink')}
            </PopoverMenu.Item>,
            <PopoverMenu.Divider key="divider" />,
          ]}
          {tile.status === constants.TILE_ACTIVE && (
            <PopoverMenu.Item onSelect={archivedTile}>
              <ArchiveIcon />
              {t('tile.archiveTile')}
            </PopoverMenu.Item>
          )}
          {tile.status === constants.TILE_ARCHIVED && (
            <PopoverMenu.Item onSelect={unarchiveTile}>
              <ReplayIcon />
              {t('tile.unarchiveTile')}
            </PopoverMenu.Item>
          )}
          <PopoverMenu.Item variant="danger" onSelect={deleteTile}>
            <DeleteIcon />
            {t('tile.deleteTile')}
          </PopoverMenu.Item>
        </PopoverMenu>
      </TableCell>
    </TableRow>
  );
}

Tile.propTypes = {
  classes: objectOf(string).isRequired,
  tile: shape({}).isRequired,
  pages: arrayOf(shape({})).isRequired,
  onClick: func,
  onArchivedTile: func,
  onDeleteTile: func,
  onUnarchiveTile: func,
  onGenerateQRCode: func,
  setSuccess: func,
  primaryLocatioURI: string,
};

Tile.defaultProps = {
  onClick: null,
  onArchivedTile: null,
  onDeleteTile: null,
  onUnarchiveTile: null,
  onGenerateQRCode: null,
  setSuccess: null,
  primaryLocatioURI: null,
};

export default withStyles(styles)(Tile);
