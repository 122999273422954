import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { useScrollToTop } from '../../../utils/hooks';
import LeftNav from '../LeftNav';
import styles from './styles';

export function MainLayout({ classes, children }) {
  const contentRef = useRef();
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  useScrollToTop(contentRef, location);

  return (
    <section className={classes.main} data-testid="main-layout">
      {isLoggedIn && <LeftNav />}
      <section className={classes.contentWrap} ref={contentRef}>
        <Grid container className={classes.content} justifyContent="center">
          {children}
        </Grid>
      </section>
    </section>
  );
}

MainLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

MainLayout.defaultProps = {
  children: null,
};

export default withStyles(styles)(MainLayout);
