import { connect } from 'react-redux';

import VancoMobileEditPermissions from './VancoMobileEditPermissions';
import { savePage } from '../../../store/pages/actions';
import { setSuccess } from '../../../store/alerts/actions';

export function mapStateToProps(state) {
  return {
    loadingPage: state.pages.loadingPage,
    loadingSave: state.pages.loadingSave,
    newPage: state.pages.newPage,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, { savePage, setSuccess })(
  VancoMobileEditPermissions,
);
