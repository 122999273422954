import { forwardRef } from 'react';
import PropTypes, { string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';

import styles from './styles';

export const containerVariants = {
  NORMAL: 'normal',
  CENTERED: 'centered',
  CENTERED_WIDE: 'centeredWide',
  CARD_CONTAINER: 'cardContainer',
};

const Page = forwardRef(
  ({ children, variant, classes, className, ...rest }, ref) => {
    const containerSize = {
      [containerVariants.NORMAL]: {
        md: 12,
      },
      [containerVariants.CENTERED]: {
        xs: 12,
      },
      [containerVariants.CENTERED_WIDE]: {
        md: 10,
        lg: 8,
        xs: 12,
      },
      [containerVariants.CARD_CONTAINER]: {
        md: 6,
        xs: 12,
      },
    };

    const containerClass = clsx({
      [classes.container]: true,
      [className]: !!className,
    });

    return (
      <Grid
        item
        {...(containerSize[variant] || containerSize[containerVariants.NORMAL])}
        {...rest}
        className={containerClass}
        ref={ref}
      >
        {children}
      </Grid>
    );
  },
);

Page.propTypes = {
  classes: PropTypes.object.isRequired,
  className: string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([
    'normal',
    'centered',
    'cardContainer',
    'centeredWide',
  ]),
};

Page.defaultProps = {
  variant: 'normal',
  className: '',
};

export default withStyles(styles)(Page);
