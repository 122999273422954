import palette from '../palette';

export default {
  h1: {
    fontSize: '4.6rem',
    fontWeight: 'bold',
    color: palette.h1,
    letterSpacing: '1.4px',
  },
  h2: {
    fontSize: '2.4rem',
    fontWeight: 800,
    color: palette.h2,
    letterSpacing: '0.66px',
  },
  h3: {
    fontSize: '2.2rem',
    fontWeight: 'bold',
    color: palette.h3,
  },
  h4: {
    fontSize: '2.0rem',
    fontWeight: 'regular',
    color: palette.secondary.main,
    letterSpacing: '0.79px',
  },
  h5: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
  },
  h6: {},
  subtitle1: {
    fontSize: '1.8rem',
  },
  subtitle2: {},
  body1: {
    fontSize: '1.6rem',
    color: palette.secondary.main,
  },
  body2: {
    fontSize: '1.6rem',
    color: palette.secondary.main,
    fontWeight: 'bold',
  },
  button: {
    fontWeight: 'bold',
    fontSize: '1.6rem',
    lineHeight: 1.25,
    textTransform: 'none',
  },
  caption: {
    fontSize: '1.3rem',
  },
  overline: {},
};

// non-material ui typography
export const formInputText = {
  fontSize: '1.6rem',
  lineHeight: 1.63,
  fontWeight: 'normal',
};
