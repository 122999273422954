import { PAYMENT_METHOD } from '../../globals/constants';
import {
  GET_TRANSACTION_DATA_START,
  GET_TRANSACTION_DATA_FINISH,
  GET_TRANSACTION_DATA_ERROR,
  SET_HEXEA_OBJECT,
  SET_PAYMENT_TYPE,
  UPDATE_BASKET,
  UPDATE_TRANSACTION_FEES,
  PROCESS_TRANSACTIONS_FINISH,
  SET_IS_PROCESSING_TRANSACTIONS,
  CLEAN_UP_TRANSACTIONS,
} from './types';

const initialState = {
  tiles: [],
  paymentMethods: {},
  pcct: '',
  createdTransactions: [],
  transactionsInBasket: [],
  loadingData: false,
  hasLoadedData: false,
  isProcessingTransactions: false,
  basket: [],
  selectedPaymentType: PAYMENT_METHOD.CREDIT_CARD,
  selectedFees: {
    applyProcessingFee: false,
    feeValue: 0,
  },
  hexea: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_TRANSACTION_DATA_START:
      return {
        ...state,
        loadingData: true,
        basket: [], // Reset basket on reload.
      };
    case GET_TRANSACTION_DATA_FINISH:
      return {
        ...state,
        tiles: action.data.tiles,
        payments: action.data.payments,
        paymentMethods: action.data.paymentMethods,
        pcct: action.data.pcct,
        loadingData: false,
        hasLoadedData: true,
      };
    case GET_TRANSACTION_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loadingData: false,
      };
    case SET_HEXEA_OBJECT: {
      return { ...state, hexea: action.hexea };
    }
    case SET_PAYMENT_TYPE: {
      return { ...state, selectedPaymentType: action.selectedPaymentType };
    }
    case SET_IS_PROCESSING_TRANSACTIONS:
      return {
        ...state,
        isProcessingTransactions: action.value,
      };
    case PROCESS_TRANSACTIONS_FINISH:
      return {
        ...state,
        isProcessingTransactions: false,
        createdTransactions: [
          ...state.createdTransactions,
          ...action.createdTransactions,
        ],
        basket: [],
        selectedFees: initialState.selectedFees,
      };
    case UPDATE_BASKET:
      return {
        ...state,
        basket: action.data,
      };
    case UPDATE_TRANSACTION_FEES:
      return {
        ...state,
        selectedFees: action.data,
      };
    case CLEAN_UP_TRANSACTIONS:
      return initialState;
    default:
      return state;
  }
};
