import { Grid, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { func, objectOf, shape, string } from 'prop-types';

import InlineErrorMessage from '../../../../InlineErrorMessage';
import CropModal from '../../../../CropModal';
import FileInput from '../../../../FileInput';
import ImageContainer from '../../../../ImageContainer';
import { ReactComponent as UploadIcon } from '../../../../../../assets/logoUploadIcon.svg';
import { getOrganizationInitials } from '../../../../../../utils/page';
import { updateLogoImage } from '../../../../../../store/organizations/actions';

import styles from './styles';
import useImageConfiguration from '../../../../../../utils/hooks/useImageConfiguration/useImageConfiguration';
import { IMAGE_ASPECTS } from '../../../../../../globals/constants';

export function OrganizationLogoSection({
  settings,
  classes,
  primaryColor,
  logoImage,
  setLogoImage,
}) {
  const { t } = useTranslation();
  const {
    fileInputIsLoading,
    loadingUpdateImage,
    uploadImageError,
    uncroppedImage,
    cropOpen,
    setCropOpen,
    crop,
    setCrop,
    cropSave,
    onUserUploadImage,
  } = useImageConfiguration({
    pageId: settings.organizationId,
    setImage: setLogoImage,
    updateImage: updateLogoImage,
    cropAspect: 1,
  });

  const organizationInitials = getOrganizationInitials(settings.dbaName);

  const logoButtonLabel = t(
    logoImage?.url
      ? 'organization.branding.logo.change'
      : 'organization.branding.logo.upload',
  );
  return (
    <>
      <Typography variant="h5" className={classes.logoHeading}>
        {t('organization.branding.logo.title')}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {t('organization.branding.logo.description')}
      </Typography>
      <Grid container alignItems="center">
        <ImageContainer
          imageUrl={logoImage?.url}
          backgroundColor={primaryColor}
          onRemove={() => setLogoImage(null)}
        >
          <Typography variant="body2" className={classes.organizationInitials}>
            {organizationInitials}
          </Typography>
        </ImageContainer>
        <FileInput
          onChange={onUserUploadImage}
          id="image"
          name="image"
          icon={
            !logoImage?.url && <UploadIcon className={classes.uploadIcon} />
          }
          label={logoButtonLabel}
          accept="image/x-png,image/jpeg"
          value=""
          loading={fileInputIsLoading}
          disabled={fileInputIsLoading}
        />
      </Grid>
      {uploadImageError && (
        <Grid container>
          <Grid item xs={12}>
            <InlineErrorMessage errorMessage={uploadImageError} />
          </Grid>
        </Grid>
      )}
      {cropOpen && (
        <CropModal
          crop={crop}
          onCrop={(c, percentCrop) => setCrop(percentCrop)}
          open={cropOpen}
          image={uncroppedImage}
          onClose={() => setCropOpen(false)}
          onSave={cropSave}
          loadingUpdateImage={loadingUpdateImage}
          aspect={IMAGE_ASPECTS.SQUARE}
        />
      )}
    </>
  );
}

OrganizationLogoSection.propTypes = {
  settings: shape({}).isRequired,
  classes: objectOf(string).isRequired,
  primaryColor: string.isRequired,
  logoImage: shape({}),
  setLogoImage: func.isRequired,
};

OrganizationLogoSection.defaultProps = {
  logoImage: null,
};

export default withStyles(styles)(OrganizationLogoSection);
