import { func, node } from 'prop-types';

import PrimaryButton from '../../PrimaryButton';
import { useSetupLayoutContext } from '../context';

export function Button({ children, onClick }) {
  const { classes } = useSetupLayoutContext();

  return (
    <PrimaryButton onClick={onClick} size="large" className={classes.button}>
      {children}
    </PrimaryButton>
  );
}

Button.propTypes = {
  onClick: func,
  children: node.isRequired,
};

Button.defaultProps = {
  onClick: null,
};

export default Button;
