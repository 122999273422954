export const isTokenExpired = (session) => {
  const currentDate = Date.now();
  const { tExpires, accessToken } = session;
  const isValidToken = Boolean(accessToken?.length);
  const hasExpired = currentDate > tExpires;
  return isValidToken && hasExpired;
};

export const createSession = (session) => {
  const currentDate = Date.now();
  /*
   * It will increase the expiration time at the moment
   * of storing the accessToken in 1 hr
   * i.e. Wed Nov 29 2023 12:40:24 -> Wed Nov 29 2023 13:40:24.
   */
  const tExpires = currentDate + session.expiresIn * 1000;
  return {
    ...session,
    tExpires,
  };
};
