import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Modal, PrimaryButton } from '../../../common';

import styles from './styles';

export function EmailSettingsModal({
  classes,
  open,
  user,
  onClose,
  onSave,
  isLoading,
}) {
  const { t } = useTranslation();
  const [receivesEmailNotifications, setReceivesEmailNotifications] = useState(
    user.receivesEmailNotifications,
  );

  const onChange = e => {
    const { value } = e.target;
    setReceivesEmailNotifications(value === 'true');
  };
  useEffect(() => {
    setReceivesEmailNotifications(user.receivesEmailNotifications);
  }, [user]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      data-testid="email-settings-modal"
      className={classes.modal}
    >
      <Modal.Title>{t('users.editEmailNotifications.title')}</Modal.Title>
      <Modal.Content className={classes.content}>
        <Typography>{t('users.editEmailNotifications.description')}</Typography>
        <RadioGroup component={RadioGroup} onChange={onChange}>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Radio
                checked={receivesEmailNotifications}
                name="receiveTransactionEmails"
                color="primary"
                value="true"
              />
            }
            label={
              <Typography>{t('users.editEmailNotifications.yes')}</Typography>
            }
          />
          <Typography className={classes.optionDescription}>
            {t('users.editEmailNotifications.yes.description')}
          </Typography>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Radio
                checked={!receivesEmailNotifications}
                name="receiveTransactionEmails"
                color="primary"
                value="false"
              />
            }
            label={
              <Typography>{t('users.editEmailNotifications.no')}</Typography>
            }
          />
        </RadioGroup>
      </Modal.Content>
      <Modal.Actions>
        {!isLoading && (
          <>
            <Button
              onClick={onClose}
              size="large"
              color="primary"
              variant="outlined"
            >
              {t('button.cancel')}
            </Button>
            <PrimaryButton
              size="large"
              onClick={() => onSave(user, { receivesEmailNotifications })}
              data-testid="set-user-notifications"
            >
              {t('button.save')}
            </PrimaryButton>
          </>
        )}
        {isLoading && (
          <CircularProgress data-testid="email-settings-modal-spinner" />
        )}
      </Modal.Actions>
    </Modal>
  );
}

export default withStyles(styles)(EmailSettingsModal);

EmailSettingsModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
