export default function styles(theme) {
  return {
    titleContainer: {
      height: 60,
      marginBottom: 0,
    },
    content: {
      padding: '0px',
    },
    closeIcon: {
      marginRight: '-12px',
    },
    actions: {
      padding: '24px',
    },
    dialogPaper: {
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc(100% - 240px)',
      },
    },
    list: {
      padding: 0,
    },
    listItem: {
      minHeight: '71px',
      '&:hover': {
        outline: '1px solid rgb(224, 224, 224)',
      },
    },
    listItemIcon: {
      marginLeft: '16px',
      minWidth: '35px',
    },
    tileIcon: {
      height: '40px',
      width: '40px',
      margin: '40px 0px 29px 0px',
      '& path': {
        opacity: 0.48,
      },
    },
    bottomText: {
      marginTop: 4,
      marginBottom: 64,
    },
    underlined: {
      textDecoration: 'underline',
    },
    progressContainer: {
      margin: '20px 0px',
    },
  };
}
