import { connect } from 'react-redux';

import IdleHandler from './IdleHandler';
import { getOrRenewAccessToken } from '../../../store/user/actions';

export function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  { getOrRenewAccessToken },
)(IdleHandler);
