/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
import { CUSTOM_LABEL } from '../../globals/constants';
import { isValid } from './stringValidation';

export * from './stringValidation';

export const maxLengths = {
  pageName: 55,
  address: 30,
  address2: 30,
  city: 25,
  state: 2,
  zip: 5,
  email: 45,
  phone: 10,
  defaultGroupName: 60,
  price: 13,
  customTextField: 96,
  pixels: 4,
  customDropDownText: 50,
  purposeEntry: 1000,
  mainPointsEntry: 1000,
  toneEntry: 1000,
};

export const minLengths = {
  state: 2,
  price: 3,
};

/* eslint-disable  no-control-regex */
export const phone = /^\d{10}$/;
export const nonAscii = /[^\x00-\x7F]/g;
export const nonDecimal = /[^\d.]/g;
export const nonDoubleDot = /(\..*)\./g;
export function decimalRange(min, max) {
  return new RegExp(
    `^((([${min}-${max - 1}]{0,1})?(\\.\\d{0,2})?)|(${max}(\\.(0{0,2})?)?))?$`,
  );
}
export function beforeDecimal(count) {
  return new RegExp(`(^[\\d]{${count}})[\\d]`, 'g');
}
export function afterDecimal(count) {
  return new RegExp(`(\\.[\\d]{${count}}).`, 'g');
}
export const validChars = `!@#$^&*()-_+=[],.?/:;'`;
export const nonAlphanumeric = /[^A-Za-z0-9_|\\.|\-|,|' ]/g;
export const alphanumericPlusSomeSpecialChars = /^[a-zA-Z0-9:\- ]*$/;
export const alphanumeric = /^[a-zA-Z0-9 ]*$/; // alpha and spaces
export const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.(\w*[a-z]\w*)(:[0-9]{1,5})?(\/.*)?$/;
export const youtubeUrlRegex =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
export const youtubeChannelRegex =
  /^(https?:\/\/)?(www\.)?youtube\.com\/(channel\/|@)[\w-]+/;
export const timeFormat = /^((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))$/;

export const emailRegex =
  /^[a-zA-Z0-9!#\\&$%'*+=?^`{}|~_-](\.?[a-zA-Z0-9\\!#$&%'*+=?^`{}|~_-]){0,}@[a-zA-Z0-9]+\.(?!-)([a-zA-Z0-9]?((-?[a-zA-Z0-9]+)+\.(?!-))){0,}[a-zA-Z0-9]{2,8}$/;

const checkMax = 2;
const creditMax = 4;
const checkRegex = decimalRange(0, checkMax);
const creditRegex = decimalRange(0, creditMax);

export const isValidEmail = (email) => {
  return !!(email || '').match(emailRegex);
};

export const hasValidCharsOnly = (currentValue) => {
  if (!isValid(currentValue, true)) {
    return false;
  }

  // ensure special chars are escaped
  const escapedSpecialChars = validChars.replace(
    // eslint-disable-next-line no-useless-escape
    /[-\/\\^$*+?.()|[\]{}]/g,
    '\\$&',
  );
  // the ^ character within the [] will match anything not between the brackets
  // space that follows after the } is required
  const regex = new RegExp(`[^a-zA-Z0-9${escapedSpecialChars}\n ]`);

  const matchFound = currentValue.match(regex);
  return matchFound === null;
};

export const pageNameValidation = ({ i18n, errorMessage, bannedNames }) => {
  let error = i18n.t('validation.required', {
    requiredNoun: i18n.t('headerManagement.siteName').toLocaleLowerCase(),
  });

  if (errorMessage) {
    error = i18n.t(errorMessage);
  }

  return Yup.object().shape({
    pageName: Yup.string()
      .max(maxLengths.pageName, i18n.t('validation.long'))
      .test(
        'duplicatedName',
        i18n.t('headerManagement.duplicatedSiteName'),
        /* eslint-disable-next-line func-names */
        function () {
          const { pageName } = this.parent;
          return !(bannedNames?.length > 0 && bannedNames.includes(pageName));
        },
      )
      .required(error),
  });
};

export const addressValidation = (i18n) =>
  Yup.object().shape({
    address: Yup.string().when('provideAddress', {
      is: true,
      then: (s) =>
        s.required(
          i18n.t('validation.required', {
            requiredNoun: i18n
              .t('headerManagement.address')
              .toLocaleLowerCase(),
          }),
        ),
    }),
    address2: Yup.string().nullable(),
    city: Yup.string()
      .nullable()
      .when('provideAddress', {
        is: true,
        then: (s) =>
          s.required(
            i18n.t('validation.required', {
              requiredNoun: i18n.t('headerManagement.city').toLocaleLowerCase(),
            }),
          ),
      }),
    zip: Yup.number()
      .nullable()
      .when('provideAddress', {
        is: true,
        then: (s) =>
          s
            .nullable()
            .typeError(
              i18n.t('validation.number', {
                requiredNoun: i18n.t('headerManagement.zip'),
              }),
            )
            .required(
              i18n.t('validation.required', {
                requiredNoun: i18n
                  .t('headerManagement.zip')
                  .toLocaleLowerCase(),
              }),
            ),
      }),
    state: Yup.string()
      .nullable()
      .when('provideAddress', {
        is: true,
        then: (s) =>
          s
            .nullable()
            .typeError(
              i18n.t('validation.required', {
                requiredNoun: i18n.t('headerManagement.state'),
              }),
            )
            .required(
              i18n.t('validation.required', {
                requiredNoun: i18n
                  .t('headerManagement.state')
                  .toLocaleLowerCase(),
              }),
            )
            .max(maxLengths.state, i18n.t('validations.long'))
            .min(
              minLengths.state,
              i18n.t('validation.short', { min: minLengths.state }),
            ),
      }),
  });

export const processingFeesValidations = (i18n) =>
  Yup.object().shape({
    enableProcessingFees: Yup.bool().required(i18n.t('validation.required')),
    checkPercentage: Yup.string()
      .notRequired()
      .when('enableProcessingFees', {
        is: true,
        then: (s) =>
          s
            .matches(
              checkRegex,
              i18n.t('validation.outOfRange', {
                type: 'percentage',
                max: checkMax.toString(),
              }),
            )
            .required(
              i18n.t('validation.required', {
                requiredVerb: 'enter a',
                requiredNoun: 'percentage',
              }),
            ),
        otherwise: (s) => s.string().notRequired(),
      }),
    creditPercentage: Yup.string()
      .notRequired()
      .when('enableProcessingFees', {
        is: true,
        then: (s) =>
          s
            .matches(
              creditRegex,
              i18n.t('validation.outOfRange', {
                type: 'percentage',
                max: creditMax.toString(),
              }),
            )
            .required(
              i18n.t('validation.required', {
                requiredVerb: 'enter a',
                requiredNoun: 'percentage',
              }),
            ),
        otherwise: (s) => s.string().notRequired(),
      }),
  });

export const appPermissionValidations = (i18n) =>
  Yup.object().shape({
    hasCommunityFeatures: Yup.boolean(),
    defaultGroupName: Yup.string()
      .notRequired()
      .when('hasCommunityFeatures', {
        is: true,
        then: (s) => s.required(i18n.t('validation.defaultGroupName')),
        otherwise: (s) => s.notRequired(),
      }),
  });

export const contactInfoValidations = (i18n) =>
  Yup.object().shape({
    provideEmail: Yup.boolean().nullable(),
    providePhone: Yup.boolean().nullable(),
    provideContact: Yup.boolean().test(
      'oneOfRequired',
      i18n.t('mobileSetup.provideEmailOrPhone'),
      /* eslint-disable-next-line func-names */
      function () {
        const { providePhone, provideEmail } = this.parent;
        return provideEmail || providePhone;
      },
    ),
    phone: Yup.string()
      .nullable()
      .when('providePhone', {
        is: true,
        then: (s) =>
          s.matches(phone, i18n.t('validation.phone')).required(
            i18n.t('validation.required', {
              requiredNoun: i18n
                .t('headerManagement.phone')
                .toLocaleLowerCase(),
            }),
          ),
      }),
    email: Yup.string()
      .nullable()
      .when('provideEmail', {
        is: true,
        then: (s) =>
          s.email(i18n.t('validation.email')).required(
            i18n.t('validation.required', {
              requiredNoun: i18n
                .t('headerManagement.emailAddress')
                .toLocaleLowerCase(),
            }),
          ),
      }),
  });

export const memoLineValidations = (i18n, features) => {
  return {
    showTextField: Yup.bool(),
    textField: Yup.string().when('showTextField', {
      is: true,
      then: (s) => s.required(i18n.t('tile.memoLine.select.error')),
    }),
    customTextField: Yup.string().when(['showTextField', 'textField'], {
      is: (showTextField, textField) =>
        showTextField && textField === CUSTOM_LABEL,
      then: (s) =>
        s
          .required(
            i18n.t('validation.pleaseEnter', {
              requiredNoun: i18n.t('cta.label2').toLocaleLowerCase(),
            }),
          )
          .max(maxLengths.customTextField, i18n.t('validation.long')),
    }),
    memoFieldResponses: Yup.array().when('showTextField', {
      is: true,
      then: (s) =>
        s.test('memoFieldResponses', function validate(value) {
          const { isTextFieldResponseType } = this.parent;
          if (!features.TileMemoDropdown || isTextFieldResponseType)
            return true;
          if (!value || value.length === 0)
            return this.createError({
              message: i18n.t('tile.memoLine.dropdownfield.empty'),
            });
          if (value.length < 2)
            return this.createError({
              message: i18n.t('tile.memoLine.select.error.min'),
            });

          const errorMessages = value
            .filter((i) => !!i.error)
            .map((i) => i.error);
          if (errorMessages.length > 0) {
            return this.createError({
              message: errorMessages[0],
            });
          }
          return true;
        }),
    }),
  };
};

export const tilePaymentValidations = (i18n, features) =>
  Yup.object().shape({
    fund: Yup.object()
      .nullable()
      .required(
        i18n.t('validation.required', {
          requiredNoun: i18n.t('fund.selectFund').toLocaleLowerCase(),
          requiredVerb: '',
        }),
      ),
    paymentOptions: Yup.object().shape({
      price: Yup.number()
        .required(
          i18n.t('validation.pleaseEnterA', {
            requiredNoun: i18n.t('wizard.tile.price').toLocaleLowerCase(),
            requiredVerb: '',
          }),
        )
        .min(
          minLengths.price,
          i18n.t('validation.shortNumber', {
            type: i18n.t('wizard.tile.price'),
            min: `$${minLengths.price.toFixed(2)}`,
          }),
        )
        .typeError(
          i18n.t('validation.number', {
            requiredNoun: i18n.t('wizard.tile.price'),
            requiredVerb: '',
          }),
        ),
    }),
    ...memoLineValidations(i18n, features),
  });

export const isValidObject = (object) =>
  Boolean(object) && Object.keys(object).length > 0;
