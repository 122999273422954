import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Paper, withStyles, Grid } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { updateUserFeatures } from '../../../store/organizations/actions';
import { setSuccess } from '../../../store/alerts/actions';
import { Page, PageTitle } from '../../common';
import styles from './styles';
import FeatureEnablingForm from '../../common/FeatureEnablingForm';
import DisableStream from '../../common/FeatureEnablingForm/DisableStream/DisableStream';
import { useFeatures } from '../../../utils/hooks';
import useGetOrgFeatures from '../../../utils/hooks/useGetOrgFeatures';
import { FEATURES_TYPE } from '../../../globals/constants/index';
import { disableKiosk, enableKiosk } from '../../../store/kiosk/actions';

function PaywallDashboard({
  classes,
  hasKiosk,
  organizationId,
  organizationStreamingDisableDate,
  smartLibraryDisableDate,
  smartClipsDisableDate,
  kioskDisableDate,
}) {
  const {
    VancoVideo: ffVancoVideo,
    ContentManager: ffContentManager,
    Communications: ffCommunications,
    KioskSetup: ffKiosk,
    Insights: ffInsights,
  } = useFeatures();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const videoTitle = t(`${ffVancoVideo ? 'video' : 'live'}Title`);

  const { features } = useGetOrgFeatures(organizationId);

  let hasCommunications = false;
  let communicationsDisableDate;
  let hasContentManager = false;
  let contentManagerDisableDate;
  let hasInsights = false;
  let insightsDisableDate;

  if (features.length > 0) {
    const commsFeature = features?.filter(
      (feature) => feature.featureId === FEATURES_TYPE.Communications,
    );

    const contentFeature = features?.filter(
      (feature) => feature.featureId === FEATURES_TYPE.ContentManager,
    );

    const insightsFeature = features?.filter(
      (feature) => feature.featureId === FEATURES_TYPE.Insights,
    );

    hasCommunications = commsFeature.length > 0 && commsFeature[0].isEnabled;
    hasContentManager =
      contentFeature.length > 0 && contentFeature[0].isEnabled;
    hasInsights = insightsFeature.length > 0 && insightsFeature[0].isEnabled;

    communicationsDisableDate = commsFeature[0]?.disableDate;
    contentManagerDisableDate = contentFeature[0]?.disableDate;
    insightsDisableDate = insightsFeature[0]?.disableDate;
  }
  const onSubmit = async (values) => {
    const notified = await dispatch(
      updateUserFeatures(organizationId, [values]),
    );

    if (notified) {
      const successMessage = t('site.changes.saved');
      dispatch(setSuccess(successMessage));
    }
  };

  const onSubmitKiosk = async (values) => {
    if (values.disableDate === null) {
      await dispatch(enableKiosk());
      dispatch(setSuccess(t('kiosk.setup.settings.manage.enableSuccess')));
    } else {
      await dispatch(disableKiosk(values.disableDate));
      dispatch(setSuccess(t('kiosk.setup.settings.manage.disableSuccess')));
    }
  };

  return (
    <Page>
      <PageTitle className={classes.pageTitle}>
        {t('app.menu.settings.paidFeatures')}
      </PageTitle>
      <Grid styles={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        {ffKiosk && (
          <Paper style={{ marginBottom: 16, padding: 24 }}>
            <PageTitle>{t('kiosk.info.title')}</PageTitle>
            <FeatureEnablingForm
              id={0}
              enabledText={t('kiosk.setup.settings.manage.option.yes')}
              disabledText={t('kiosk.setup.settings.manage.option.no')}
              dateHelperText={t('kiosk.setup.settings.manage.date.description')}
              enabled={hasKiosk && kioskDisableDate === null}
              date={kioskDisableDate}
              onEnable={onSubmitKiosk}
            />
          </Paper>
        )}
        {ffVancoVideo && (
          <Paper style={{ marginBottom: 16, padding: 24 }}>
            <PageTitle>
              {' '}
              {t('disableStreamModal.title', {
                title: videoTitle,
              })}
            </PageTitle>
            <DisableStream
              organizationId={organizationId}
              streamingDisableDate={organizationStreamingDisableDate}
              smartLibraryDisableDate={smartLibraryDisableDate}
              smartClipsDisableDate={smartClipsDisableDate}
            />
          </Paper>
        )}
        {ffCommunications && (
          <Paper style={{ marginBottom: 16, padding: 24 }}>
            <PageTitle>{t('app.menu.communications')}</PageTitle>
            <FeatureEnablingForm
              id={FEATURES_TYPE.Communications}
              enabledText={t('comms.setup.settings.manage.option.yes')}
              disabledText={t('comms.setup.settings.manage.option.no')}
              dateHelperText={t('comms.setup.settings.manage.date.description')}
              enabled={hasCommunications && communicationsDisableDate === null}
              onEnable={onSubmit}
              date={communicationsDisableDate}
            />
          </Paper>
        )}
        {ffInsights && (
          <Paper style={{ marginBottom: 16, padding: 24 }}>
            <PageTitle data-testid="InsightsTitle">
              {t('titles.insights')}
            </PageTitle>
            <FeatureEnablingForm
              data-testid="InsightsForm"
              id={FEATURES_TYPE.Insights}
              enabledText={t('insights.setup.settings.manage.option.yes')}
              disabledText={t('insights.setup.settings.manage.option.no')}
              dateHelperText={t(
                'insights.setup.settings.manage.date.description',
              )}
              enabled={hasInsights && insightsDisableDate === null}
              onEnable={onSubmit}
              date={insightsDisableDate}
              label={t('insights.setup.settings.manage.label')}
            />
          </Paper>
        )}
        {ffContentManager && (
          <Paper style={{ marginBottom: 16, padding: 24 }}>
            <PageTitle>{t('titles.content.contentManager')}</PageTitle>
            <FeatureEnablingForm
              id={FEATURES_TYPE.ContentManager}
              enabledText={t('content.setup.settings.manage.option.yes')}
              disabledText={t('content.setup.settings.manage.option.no')}
              dateHelperText={t(
                'content.setup.settings.manage.date.description',
              )}
              enabled={hasContentManager && contentManagerDisableDate === null}
              onEnable={onSubmit}
              date={contentManagerDisableDate}
            />
          </Paper>
        )}
      </Grid>
    </Page>
  );
}
PaywallDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  hasKiosk: PropTypes.bool.isRequired,
  kioskDisableDate: PropTypes.object,
  organizationId: PropTypes.string.isRequired,
  organizationStreamingDisableDate: PropTypes.object,
  smartLibraryDisableDate: PropTypes.object,
  smartClipsDisableDate: PropTypes.object,
};

PaywallDashboard.defaultProps = {
  organizationStreamingDisableDate: null,
  smartClipsDisableDate: null,
  smartLibraryDisableDate: null,
  kioskDisableDate: null,
};

export function mapStateToProps(state) {
  return {
    hasKiosk: state.kiosk.hasKiosk,
    kioskDisableDate: state.kiosk.kioskDisableDate,
    organizationId: state.user.user.organizationId,
    organizationStreamingDisableDate: state.user.user
      .organizationStreamingDisableDate
      ? moment(state.user.user.organizationStreamingDisableDate)
      : undefined,
    smartLibraryDisableDate: state.user.user.smartLibraryDisableDate
      ? moment(state.user.user.smartLibraryDisableDate)
      : undefined,
    smartClipsDisableDate: state.user.user.smartClipsDisableDate
      ? moment(state.user.user.smartClipsDisableDate)
      : undefined,
  };
}
export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    updateUserFeatures,
    setSuccess,
  }),
)(PaywallDashboard);
