import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  iframeStyle: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  backdrop: {
    zIndex: 1,
    position: 'absolute',
  },
}));
