import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, matchPath } from 'react-router-dom';
import { handleException } from '../../../store/alerts/actions';

import { backendToFrontend } from '../../../store/pages/transform';
import PageService from '../../../api/PageService';
import {
  getPage,
  getPages as getPagesAction,
} from '../../../store/pages/actions';
import { setAppLoading } from '../../../store/loading/actions';

async function getPages() {
  const response = await PageService.getPages();
  return response.data.data.map(backendToFrontend);
}

async function deletePage(page) {
  const response = await PageService.deletePage(page);
  return response;
}

async function isDeletable({ queryKey }) {
  const [, pageId] = queryKey;
  const { data } = await PageService.isDeletable({ id: pageId });
  return data.data.isDeletable;
}

async function patchLocation({ page, field, data }) {
  const request = [
    {
      op: 'replace',
      path: `/${field}`,
      value: data,
    },
  ];
  return PageService.patchLocation(page, request);
}

export function useGetPages(config = {}) {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ['pages'],
    queryFn: getPages,
    onError: (error) => {
      dispatch(handleException(error));
    },
    ...config,
  });
}

export function useGetPageId(paths = [], isOrganizationPage = false) {
  const location = useLocation();
  const params = useParams();
  if (isOrganizationPage) return undefined;
  const overrideParams = matchPath(location.pathname, {
    path: Object.entries(paths),
  });
  let pageId = params.pageId ? params.pageId : null;
  if (overrideParams?.params?.pageId) {
    pageId = overrideParams.params.pageId
      ? overrideParams.params.pageId
      : pageId;
  }

  return pageId;
}

export function useDeletePage(config = {}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(deletePage, {
    onSuccess: () => {
      queryClient.invalidateQueries('tile');
    },
    onError: (error) => {
      dispatch(handleException(error));
    },
    ...config,
  });
}

export function useIsDeletablePage(page, config) {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['page-is-deletable', page?.id],
    queryFn: isDeletable,
    onError: (error) => {
      dispatch(handleException(error));
    },
    ...config,
  });
}

export function usePatchLocation(page, config = {}) {
  const dispatch = useDispatch();
  return useMutation(patchLocation, {
    onMutate: () => {
      dispatch(setAppLoading(true));
    },
    onSuccess: () => {
      dispatch(getPage(page));
    },
    onSettled: () => {
      dispatch(setAppLoading(false));
    },
    onError: (error) => {
      dispatch(handleException(error));
    },
    ...config,
  });
}

export const useFetchPages = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading.appIsLoading);
  return {
    fetchPages: () => dispatch(getPagesAction()),
    isLoading: Boolean(isLoading),
  };
};

export default {
  useGetPages,
  useGetPageId,
  useDeletePage,
  useIsDeletablePage,
  usePatchLocation,
};
