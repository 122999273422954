import { useMemo, useState } from 'react';
import { COMMUNICATIONS_EMAIL } from '../../../../globals/constants';

const useRevertToDefault = ({ t, template, setFieldValue }) => {
  const [revertBodyToDefault, setRevertBodyToDefault] = useState(false);

  const defaultSubjectText = useMemo(
    () => `${t('communication.transaction.confirmation.static.subject')}!`,
    [t],
  );

  const defaultBodyText = useMemo(
    () =>
      `${t(
        'communication.transaction.confirmation.static.body.one',
      )}%contributor name%, ${t(
        `communication.transaction.confirmation.static.body.two${
          template === COMMUNICATIONS_EMAIL.SCHEDULED_TEMPLATE
            ? ''
            : '.completed'
        }`,
      )}\n`,
    [t, template],
  );

  const onRevertToDefault = (type) => {
    switch (type) {
      case COMMUNICATIONS_EMAIL.BODY_TYPE:
        setRevertBodyToDefault(true);
        break;
      case COMMUNICATIONS_EMAIL.SUBJECT_TYPE:
        setFieldValue({ target: { name: type, value: defaultSubjectText } });
        break;
      default:
        break;
    }
  };

  return {
    onRevertToDefault,
    revertBodyToDefault,
    setRevertBodyToDefault,
    defaultSubjectText,
    defaultBodyText,
  };
};

export default useRevertToDefault;
