import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import withPage from '../../hoc/withPage';
import { savePage, removePage } from '../../../store/pages/actions';
import { setError, setSuccess } from '../../../store/alerts/actions';

import PageBuilder from './PageBuilder';
import { useFeatures } from '../../../utils/hooks';

export function PageBuilderWrapper({ ...props }) {
  const location = useLocation();
  const currentPath = location.pathname;
  const isStreamPage = currentPath.includes('/stream');

  const { t } = useTranslation();

  const { VancoVideo: ffVancoVideo } = useFeatures();

  const Wrapped = useMemo(() => {
    return withPage(
      withRouter(PageBuilder),
      isStreamPage ? 'app.vancoStreaming' : 'titles.vancoOnline',
      ffVancoVideo
        ? { key: 'title', value: t(`${ffVancoVideo ? 'video' : 'live'}Title`) }
        : {},
    );
  }, [isStreamPage, ffVancoVideo, t]);

  return <Wrapped {...props} />;
}

export function mapStateToProps(state) {
  return {
    loadingSave: state.pages.loadingSave,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, {
  savePage,
  setSnackbar: setError,
  setSuccess,
  removePage,
})(PageBuilderWrapper);
