import { useEffect, useState } from 'react';

const getScrollParent = node => {
  if (!node) {
    return null;
  }
  if (node.scrollHeight > node.clientHeight) {
    return node;
  }
  return getScrollParent(node.parentNode);
};

const useScrollOnceToBottom = (isReady, nodeRef, onBeforeScroll) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    if (isReady && !hasScrolled) {
      setHasScrolled(true);
      if (onBeforeScroll) {
        onBeforeScroll();
      }
      setTimeout(() => {
        getScrollParent(nodeRef.current).scrollTo(0, 9999);
      }, 0);
    }
  }, [hasScrolled, isReady, nodeRef, onBeforeScroll]);
};

export default useScrollOnceToBottom;
