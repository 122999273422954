import { useEffect } from 'react';

const scrollToTopOfRef = ref => ref.current.scrollTo(0, 0); // General scroll to element function
const scrollToTopOfParentRef = ref => ref.current.parentNode.scrollTo(0, 0);

export function useScrollTop(ref, location) {
  const { pathname } = location;
  useEffect(() => {
    // parent window needs to also be adjusted
    if (ref && ref.current) {
      if (ref.current.scrollTo) scrollToTopOfRef(ref);
      if (ref.current.parentNode?.scrollTo) scrollToTopOfParentRef(ref);
    }
  }, [pathname, ref]);
}

export default useScrollTop;
