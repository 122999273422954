import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { CustomFormik, Page, PageTitle } from '../../common';
import FeatureSection from './FeatureSection';
import useStyles from './styles';
import {
  getOrganizationGrants,
  saveOrganizationPermissions,
} from '../../../store/organizations/actions';

const FeatureManagement = function FeatureManagement() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { organizationUri, organizationGrants, loadingOrganizationGrants } =
    useSelector((state) => ({
      organizationUri: state.organizations.currentOrganization.id,
      organizationGrants: state.organizations.currentOrganization.grants,
      loadingOrganizationGrants: state.organizations.loadingOrganizationGrants,
    }));

  const initialValuesRef = useRef(null);
  const [dataChanged, setDataChanged] = useState(false);
  const initialLoadRef = useRef(true);
  const [headerValues, setHeaderValues] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const permissionMap = useMemo(
    () => ({
      View: 'Read',
      Edit: 'Write',
      Read: 'View',
      Write: 'Edit',
    }),
    [],
  );

  const mapUIToPermission = useCallback(
    (uiPermission) => permissionMap[uiPermission] || uiPermission,
    [permissionMap],
  );

  const mapPermissionToUI = useCallback(
    (permission) => permissionMap[permission] || permission,
    [permissionMap],
  );

  useEffect(() => {
    dispatch(getOrganizationGrants());
  }, [dispatch]);

  const initialValues = useMemo(() => {
    const values = {};
    organizationGrants.forEach((grant) => {
      if (!values[grant.section]) {
        values[grant.section] = {};
      }
      if (!values[grant.section][grant.subSection]) {
        values[grant.section][grant.subSection] = {};
      }
      values[grant.section][grant.subSection][grant.permission] = grant.enabled;
    });
    initialValuesRef.current = JSON.parse(JSON.stringify(values));
    setIsSaving(false);
    return values;
  }, [organizationGrants]);

  useEffect(() => {
    if (
      !loadingOrganizationGrants &&
      initialLoadRef.current &&
      Object.keys(initialValues).length > 0
    ) {
      initialLoadRef.current = false;
      setDataChanged(false);

      const initialHeaderValues = {};

      Object.keys(initialValues).forEach((sectionName) => {
        const sectionData = initialValues[sectionName];

        const subsectionValues = [];

        Object.keys(sectionData).forEach((subsectionName) => {
          const permissions = Object.entries(sectionData[subsectionName])
            .filter(([, value]) => value)
            .map(([permissionType]) => permissionType);

          if (permissions.length > 0) {
            const highestPermission = permissions.reduce((highest, current) => {
              const currentIndex = Object.keys(
                sectionData[subsectionName],
              ).indexOf(current);
              const highestIndex = Object.keys(
                sectionData[subsectionName],
              ).indexOf(highest);
              return currentIndex > highestIndex ? current : highest;
            });

            subsectionValues.push(highestPermission);
          } else {
            subsectionValues.push(null);
          }
        });

        const uniqueValues = new Set(subsectionValues);
        let headerValue;

        if (uniqueValues.size === 1) {
          headerValue = uniqueValues.has(null)
            ? 'None'
            : Array.from(uniqueValues)[0];
        } else {
          headerValue = 'Custom';
        }

        initialHeaderValues[sectionName] = headerValue;
      });
      const updatedHeaderValues = {};
      Object.keys(initialHeaderValues).forEach((key) => {
        updatedHeaderValues[key] = mapPermissionToUI(initialHeaderValues[key]);
      });

      setHeaderValues(updatedHeaderValues);
    }
  }, [loadingOrganizationGrants, initialValues, mapPermissionToUI]);

  const handleSubmit = async (values) => {
    setIsSaving(true);
    const accountGrants = [];
    Object.entries(values).forEach(([section, subsections]) => {
      Object.entries(subsections).forEach(([subSection, permissions]) => {
        const enabledPermissions = Object.entries(permissions)
          .filter(([, enabled]) => enabled)
          .map(([permission]) => permission);

        if (enabledPermissions.length > 0) {
          const relevantGrants = organizationGrants.filter(
            (g) =>
              g.section === section &&
              g.subSection === subSection &&
              enabledPermissions.includes(g.permission),
          );

          if (relevantGrants.length > 0) {
            const highestIdGrant = relevantGrants.reduce((prev, current) =>
              prev.permissionId > current.permissionId ? prev : current,
            );

            accountGrants.push({
              PermissionId: highestIdGrant.permissionId,
              SectionId: highestIdGrant.sectionId,
              SubSectionId: highestIdGrant.subSectionId,
            });
          }
        }
      });
    });

    const result = await dispatch(
      saveOrganizationPermissions(organizationUri, accountGrants),
    );
    if (result.success) {
      dispatch(getOrganizationGrants());
    }
  };

  const hasDataChanged = (currentValues) => {
    if (!initialValuesRef.current) return false;
    return (
      JSON.stringify(currentValues) !== JSON.stringify(initialValuesRef.current)
    );
  };

  const checkAndLogDataChange = (newValues) => {
    if (initialLoadRef.current) return;

    const changed = hasDataChanged(newValues);
    if (changed !== dataChanged) {
      setDataChanged(changed);
    }
  };

  const handleAccessChange = useCallback(
    (values, setValues, sectionName, subSectionName, newUIPermissionType) => {
      const newPermissionType = mapUIToPermission(newUIPermissionType);
      const newValues = { ...values };
      if (newValues[sectionName] && newValues[sectionName][subSectionName]) {
        Object.keys(newValues[sectionName][subSectionName]).forEach(
          (permissionType) => {
            newValues[sectionName][subSectionName][permissionType] =
              (newPermissionType === 'Read' && permissionType === 'Read') ||
              (newPermissionType === 'Write' &&
                (permissionType === 'Read' || permissionType === 'Write'));
          },
        );
        setValues(newValues);
        checkAndLogDataChange(newValues);
      }
    },
    // eslint-disable-next-line
    [mapUIToPermission],
  );

  const handleHeaderAccessChange = useCallback(
    (values, setValues, sectionName, newUIHeaderValue) => {
      const newHeaderValue = mapUIToPermission(newUIHeaderValue);
      const newValues = JSON.parse(JSON.stringify(values));
      let changed = false;
      Object.keys(newValues[sectionName]).forEach((subSectionName) => {
        if (newValues[sectionName][subSectionName]) {
          Object.keys(newValues[sectionName][subSectionName]).forEach(
            (permissionType) => {
              let newValue;
              if (newHeaderValue === 'Custom') {
                newValue = false;
              } else {
                newValue =
                  (newHeaderValue === 'Read' && permissionType === 'Read') ||
                  (newHeaderValue === 'Write' &&
                    ['Read', 'Write'].includes(permissionType));
              }
              if (
                newValues[sectionName][subSectionName][permissionType] !==
                newValue
              ) {
                newValues[sectionName][subSectionName][permissionType] =
                  newValue;
                changed = true;
              }
            },
          );
        }
      });
      if (changed) {
        setValues(newValues);
        checkAndLogDataChange(newValues);
      }
      setHeaderValues((prev) => ({
        ...prev,
        [sectionName]: newUIHeaderValue,
      }));
    },
    // eslint-disable-next-line
    [mapUIToPermission],
  );

  if (loadingOrganizationGrants) {
    return (
      <div className={classes.spinnerContainer}>
        <CircularProgress color="primary" data-testid="spinner-test" />
      </div>
    );
  }

  return (
    <Page>
      <div className={classes.titleContainer}>
        <PageTitle className={classes.pageTitle}>
          {t('app.menu.settings.featureManagement')}
        </PageTitle>
      </div>
      <CustomFormik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        render={({ values, setValues, submitForm }) => (
          <form onSubmit={submitForm}>
            <Grid
              container
              spacing={2}
              data-testid="feature-sections-container"
            >
              {Object.entries(values).map(([sectionName, subsections]) => (
                <Grid item xs={12} key={sectionName}>
                  <FeatureSection
                    header={sectionName}
                    headerValue={
                      (headerValues && headerValues[sectionName]) || 'None'
                    }
                    subsections={Object.entries(subsections).map(
                      ([subSectionName, permissions]) => ({
                        name: subSectionName,
                        permissions: Object.entries(permissions).map(
                          ([permissionType, enabled]) => ({
                            type: mapPermissionToUI(permissionType),
                            enabled,
                            id: permissionType === 'Write' ? 2 : 1,
                          }),
                        ),
                      }),
                    )}
                    onAccessChange={(subSectionName, newUIPermissionType) =>
                      handleAccessChange(
                        values,
                        setValues,
                        sectionName,
                        subSectionName,
                        newUIPermissionType,
                      )
                    }
                    onHeaderAccessChange={(newUIHeaderValue) =>
                      handleHeaderAccessChange(
                        values,
                        setValues,
                        sectionName,
                        newUIHeaderValue,
                      )
                    }
                  />
                </Grid>
              ))}
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.submitButton}
                color="primary"
                variant="contained"
                onClick={submitForm}
                disabled={!dataChanged || isSaving}
              >
                Save
              </Button>
            </div>
          </form>
        )}
      />
    </Page>
  );
};

export default FeatureManagement;
