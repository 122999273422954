import { connect } from 'react-redux';

import TilePayment from './TilePayment';
import { saveTile } from '../../../store/tiles/actions';
import { setSuccess, handleException } from '../../../store/alerts/actions';

export function mapStateToProps(state) {
  return {
    organizationId: state.organizations.currentOrganization.id,
    loadingTile: state.tiles.loadingTile,
    loadingSave: state.tiles.loadingSave,
    newTile: state.tiles.newTile,
    features: state.features?.features,
  };
}

export default connect(mapStateToProps, {
  saveTile,
  setSuccess,
  handleException,
})(TilePayment);
