import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FAITH_FORWARD_EVENT_TYPE,
  VANCO_VIDEO_TYPE,
  VIDEO_ROUTES,
} from '../../../globals/constants';
import BaseVideoComponent from '../../common/BaseVideoComponent';
import { useVideoDisplayFlags } from '../../../utils/hooks';

const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 'auto',
    overflow: 'hidden',
  },
}));

/**
 * Also know as Clips
 * @returns {JSX.Element}
 */
function Clips() {
  const classes = useStyles();

  const { shouldDisplaySmartClips, redirectToErrorFromClips } =
    useVideoDisplayFlags();

  const history = useHistory();

  const redirectToPaywall = () => {
    history.push(VIDEO_ROUTES.smartClipsPaywall);
  };

  const redirectToError = () => history.push('/*');

  /*
   * Prevent users to use premium features if disabled
   */
  useEffect(() => {
    if (redirectToErrorFromClips) {
      redirectToError();
      return;
    }

    if (!shouldDisplaySmartClips) {
      redirectToPaywall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDisplaySmartClips, redirectToErrorFromClips]);

  const eventsMap = {
    [FAITH_FORWARD_EVENT_TYPE.signupRequired]: redirectToPaywall,
  };

  return (
    <div className={classes.mainContainer}>
      <BaseVideoComponent
        variant={VANCO_VIDEO_TYPE.CLIP}
        customEventsMap={eventsMap}
      />
    </div>
  );
}

export default Clips;
