import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardReturn } from '@material-ui/icons/';
import ExternalIcon from '@material-ui/icons/LaunchOutlined';
import { useMediaQuery } from '@material-ui/core';
import usePaywall from 'utils/hooks/usePaywall';
import usePermissionsRouter from 'utils/hooks/usePermissionsRouter';
import usePaidFeatures from 'utils/hooks/usePaidFeatures';
import {
  DONORS_READ_PERMISSIONS,
  GIVING_READ_PERMISSIONS,
  OUTREACH_READ_PERMISSIONS,
  REPORTING_READ_PERMISSIONS,
  SETTINGS_READ_PERMISSIONS,
  SMART_VIDEO_READ_PERMISSIONS,
} from 'utils/hooks/usePermissionsRouter/routes.constants';
import { handleRouteChange } from '../../../utils';
import MenuItem from '../MenuItem';
import { CustomDrawer, AuthorizationWrapper } from '../../common';
import { ReactComponent as Settings } from '../../../assets/settingsIcon.svg';
import { ReactComponent as GroupIcon } from '../../../assets/groupIconOutline.svg';
import { ReactComponent as DataIcon } from '../../../assets/dataIcon.svg';
import { ReactComponent as MediaIcon } from '../../../assets/mediaIcon.svg';
import { ReactComponent as DonorsIcon } from '../../../assets/donorsIcon.svg';
import { ReactComponent as OutreachIcon } from '../../../assets/outreachIcon.svg';
import { ReactComponent as GivingIcon } from '../../../assets/givingIcon.svg';
import { SIDEBAR_WIDTH } from '../../../globals/dimensions';
import { SECTION_PERMISSIONS, USER_VIEW } from '../../../globals/grants';
import { toggleLeftNav, closeNavs } from '../../../store/navigation/actions';
import useGetOrgFeatures from '../../../utils/hooks/useGetOrgFeatures';
import styles from './styles';
import {
  useACSTBlocking,
  useFeatures,
  useVideoDisplayFlags,
} from '../../../utils/hooks';
import {
  COMMS_ROUTE,
  VIDEO_ROUTES,
  GIVING_ROUTES,
  REPORTING_ROUTES,
  SETTINGS_ROUTES,
  IMPORT_ROUTES,
  DONORS_ROUTES,
} from '../../../globals/constants';

export function LeftNav({
  classes,
  open,
  navActions,
  mobileEnabled,
  idsPayerTenant,
  organizationId,
}) {
  const { t } = useTranslation();
  const { getSectionEntryPoint } = usePermissionsRouter();

  const {
    ImportExternalPayerRecurrence: ffImportExternalPayerRecurrence,
    KioskSetup: ffKioskSetup,
    KeyedTransaction: ffKeyedTransaction,
    VancoVideo: ffVancoVideo,
    Reporting: ffReporting,
    ContentManager: ffContentManager,
    Donors: ffDonors,
    Communications: ffCommunications,
    CommunicationsSMS: ffCommunicationsSms,
    SmartLibrary: ffSmartLibrary,
    SmartClips: ffSmartClips,
    AdminUserPermissions: ffAdminUserPermissions,
  } = useFeatures();

  const {
    isInternalUser,
    shouldDisplaySmartLibrary,
    shouldDisplaySmartClips,
    shouldDisplayLiveStreamAndSettings,
  } = useVideoDisplayFlags();

  const location = useLocation();
  const history = useHistory();
  const { shouldDisplayOutreachEmail } = useGetOrgFeatures(organizationId);
  const isDesktop = useMediaQuery('(min-width:1280px)');

  const { insights: redirectInsights } = usePaywall();

  const currentPath = location.pathname;
  const isWizard = currentPath.includes('wizard');
  const isProfile = currentPath.includes('profile');
  const isOpen = open || (isDesktop && !isWizard && !isProfile);
  const isInline = isDesktop && !isWizard && !isProfile;

  const showPaidFeatures = usePaidFeatures();

  const { hideFeaturesForACST } = useACSTBlocking();
  const canAccessFeatureManagement = isInternalUser && ffAdminUserPermissions;

  const mpImportTabActive = Object.values(IMPORT_ROUTES).some((route) =>
    currentPath.includes(route),
  );

  const settingsTabActive = Object.values(SETTINGS_ROUTES).some((route) =>
    currentPath.includes(route),
  );

  const givingTabActive = Object.values(GIVING_ROUTES).some((route) =>
    currentPath.includes(route),
  );

  const reportingTabActive = Object.values(REPORTING_ROUTES).some((route) =>
    currentPath.includes(route),
  );

  const donorsTabActive = Object.values(DONORS_ROUTES).some((route) =>
    currentPath.includes(route),
  );

  const vancoVideoItemActive = Object.values(VIDEO_ROUTES).some((route) =>
    currentPath.includes(route),
  );

  const outreachItemActive = Object.values(COMMS_ROUTE).some((route) =>
    currentPath.includes(route),
  );

  const showImportLinks = ffImportExternalPayerRecurrence && isInternalUser;
  const showKioskTab = Boolean(ffKioskSetup);
  const showNewTransactionTab = Boolean(ffKeyedTransaction);
  const showReportingTab = Boolean(ffReporting) && !hideFeaturesForACST;
  const showContentManager = Boolean(ffContentManager);
  const showDonorsTab = Boolean(ffDonors);
  const showOutreachTab =
    Boolean(ffCommunications) || Boolean(ffCommunicationsSms);
  const showEmails = Boolean(ffCommunications);
  const showSMS = Boolean(ffCommunicationsSms);
  const identityProviderURL = `${window.env.REACT_APP_IDS_DOMAIN}/developers/${idsPayerTenant}/sso-providers/new`;

  const initialUrlOutreach = () => {
    if (showEmails) {
      if (shouldDisplayOutreachEmail) {
        return '/emails';
      }
      return '/emails-paywall';
    }

    if (showSMS) {
      return '/sms';
    }

    return '/content/desk';
  };

  const getVancoVideoInitialUrl = () => {
    if (shouldDisplayLiveStreamAndSettings) {
      return VIDEO_ROUTES.liveStreaming;
    }
    if (ffSmartLibrary) {
      return VIDEO_ROUTES.smartLibrary;
    }
    if (ffSmartClips) {
      return VIDEO_ROUTES.smartClips;
    }
    return 'not-found';
  };

  // close this nav on route change if its open
  useEffect(() => {
    return handleRouteChange(history, navActions);
  }, [history, navActions]);

  return (
    <CustomDrawer
      anchor="left"
      size={SIDEBAR_WIDTH}
      inline={isInline}
      permanent={isInline}
      open={isOpen}
      blur={open}
      onClose={navActions.toggleLeftNav}
      elevated
      className={classes.menu}
    >
      <div className={classes.upperContent}>
        <MenuItem
          history={history}
          linkPath={getSectionEntryPoint(SECTION_PERMISSIONS.GIVING, '/tiles')}
          icon={
            <GivingIcon
              data-testid="left-nav-vanco-giving-icon"
              className={givingTabActive ? classes.activeIcon : ''}
            />
          }
          label={t('app.menu.giving')}
          active={givingTabActive}
          optionalGrants={GIVING_READ_PERMISSIONS}
          submenuItems={[
            {
              label: t('donations.tiles'),
              path: '/tiles',
              requiredGrants: [SECTION_PERMISSIONS.GIVING.TILES.READ],
            },
            ...(showNewTransactionTab
              ? [
                  {
                    label: t('app.menu.transactions'),
                    path: '/new-transaction',
                    requiredGrants: [
                      SECTION_PERMISSIONS.GIVING.TRANSACTIONS.READ,
                    ],
                  },
                ]
              : []),
            {
              label: t('app.menu.sites'),
              path: '/sites',
              requiredGrants: [SECTION_PERMISSIONS.GIVING.SITES.READ],
            },
            ...(isInternalUser || mobileEnabled
              ? [
                  {
                    label: t('app.menu.mobile'),
                    path: '/mobile/home',
                    requiredGrants: [SECTION_PERMISSIONS.GIVING.MOBILE.READ],
                  },
                ]
              : []),
            ...(showKioskTab
              ? [
                  {
                    label: t('app.menu.kiosk'),
                    path: '/kiosk',
                    requiredGrants: [SECTION_PERMISSIONS.GIVING.KIOSK.READ],
                  },
                ]
              : []),
          ]}
        />
        {showDonorsTab && !hideFeaturesForACST && (
          <MenuItem
            history={history}
            linkPath={getSectionEntryPoint(
              SECTION_PERMISSIONS.DONORS,
              '/donors',
            )}
            icon={
              <DonorsIcon
                data-testid="left-nav-vanco-donors-icon"
                className={donorsTabActive ? classes.activeIcon : ''}
              />
            }
            label={t('app.menu.donors')}
            active={donorsTabActive}
            optionalGrants={DONORS_READ_PERMISSIONS}
            submenuItems={[
              {
                label: t('app.menu.donors'),
                path: '/donors',
                requiredGrants: [SECTION_PERMISSIONS.DONORS.DONORS.READ],
              },
              {
                label: t('app.menu.tags'),
                path: '/donors-settings',
                requiredGrants: [SECTION_PERMISSIONS.DONORS.TAGS.READ],
              },
            ]}
          />
        )}
        {(showOutreachTab || showContentManager) && !hideFeaturesForACST && (
          <MenuItem
            history={history}
            linkPath={getSectionEntryPoint(
              SECTION_PERMISSIONS.OUTREACH,
              initialUrlOutreach(),
            )}
            icon={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <OutreachIcon
                className={outreachItemActive ? classes.activeIcon : ''}
                data-testid="left-nav-outreach-icon"
              />
            }
            label={t('app.menu.outreach')}
            active={outreachItemActive}
            optionalGrants={OUTREACH_READ_PERMISSIONS}
            submenuItems={[
              ...(showEmails
                ? [
                    {
                      label: t('titles.comms.emails'),
                      path: shouldDisplayOutreachEmail
                        ? '/emails'
                        : '/emails-paywall',
                      requiredGrants: [
                        SECTION_PERMISSIONS.OUTREACH.EMAILS.READ,
                      ],
                    },
                  ]
                : []),
              ...(showSMS
                ? [
                    {
                      label: t('titles.comms.sms'),
                      path: '/sms',
                      requiredGrants: [SECTION_PERMISSIONS.OUTREACH.SMS.READ],
                    },
                  ]
                : []),
              ...(showContentManager
                ? [
                    {
                      label: t('app.menu.streaming.writingDesk'),
                      path: '/content/desk',
                      requiredGrants: [
                        SECTION_PERMISSIONS.OUTREACH.WRITING_DESK.READ,
                      ],
                    },
                    {
                      label: t('app.menu.streaming.contentManager'),
                      path: '/content/cms',
                      requiredGrants: [
                        SECTION_PERMISSIONS.OUTREACH.CONTENT_MANAGER.READ,
                      ],
                    },
                  ]
                : []),
              ...(showEmails
                ? [
                    {
                      label: t('titles.comms.templates'),
                      path: shouldDisplayOutreachEmail
                        ? '/templates'
                        : '/templates-paywall',
                      requiredGrants: [
                        SECTION_PERMISSIONS.OUTREACH.TEMPLATES.READ,
                      ],
                    },
                  ]
                : []),
            ]}
          />
        )}
        {ffVancoVideo && !hideFeaturesForACST && (
          <MenuItem
            history={history}
            linkPath={getSectionEntryPoint(
              SECTION_PERMISSIONS.SMART_VIDEO,
              getVancoVideoInitialUrl(),
            )}
            icon={
              <MediaIcon
                className={vancoVideoItemActive ? classes.activeIcon : ''}
                data-testid="left-nav-vanco-media-icon"
                title="vanco-media-icon"
              />
            }
            label={t('app.menu.smartVideo')}
            active={vancoVideoItemActive}
            optionalGrants={SMART_VIDEO_READ_PERMISSIONS}
            submenuItems={[
              ...(shouldDisplayLiveStreamAndSettings
                ? [
                    {
                      label: t('app.menu.streaming.liveStreaming'),
                      path: VIDEO_ROUTES.liveStreaming,
                      requiredGrants: [
                        SECTION_PERMISSIONS.SMART_VIDEO.LIVE_STREAMING.READ,
                      ],
                    },
                  ]
                : []),
              ...(ffSmartLibrary
                ? [
                    {
                      label: t('app.menu.streaming.smartLibrary'),
                      path: shouldDisplaySmartLibrary
                        ? VIDEO_ROUTES.smartLibrary
                        : VIDEO_ROUTES.smartLibraryPaywall,
                      requiredGrants: [
                        SECTION_PERMISSIONS.SMART_VIDEO.SMART_LIBRARY.READ,
                      ],
                    },
                  ]
                : []),
              ...(ffSmartClips
                ? [
                    {
                      label: t('app.menu.streaming.smartClips'),
                      path: shouldDisplaySmartClips
                        ? VIDEO_ROUTES.smartClips
                        : VIDEO_ROUTES.smartClipsPaywall,
                      requiredGrants: [
                        SECTION_PERMISSIONS.SMART_VIDEO.SMART_CLIPS.READ,
                      ],
                    },
                  ]
                : []),
              {
                label: t('app.menu.settings'),
                path: VIDEO_ROUTES.videoSettings,
                'data-testid': 'video-settings-id',
                requiredGrants: [SECTION_PERMISSIONS.SMART_VIDEO.SETTINGS.READ],
              },
            ]}
          />
        )}
        {showImportLinks && (
          <MenuItem
            history={history}
            linkPath={getSectionEntryPoint(
              SECTION_PERMISSIONS.IMPORT,
              '/import',
            )}
            icon={
              <GroupIcon
                className={mpImportTabActive ? classes.activeIcon : ''}
                data-testid="left-nav-import-icon"
              />
            }
            label={t('app.menu.import')}
            active={mpImportTabActive}
            optionalGrants={[SECTION_PERMISSIONS.IMPORT.GIVING.READ]}
            submenuItems={[
              {
                label: t('app.menu.import.giving'),
                path: '/import',
                requiredGrants: [SECTION_PERMISSIONS.IMPORT.GIVING.READ],
              },
            ]}
          />
        )}
        {showReportingTab && (
          <MenuItem
            history={history}
            linkPath={getSectionEntryPoint(
              SECTION_PERMISSIONS.REPORTING,
              '/reporting',
            )}
            icon={
              <DataIcon
                className={reportingTabActive ? classes.activeIcon : ''}
                data-testid="left-nav-vanco-reporting-icon"
                title="vanco-reporting-icon"
              />
            }
            label={t('app.menu.data', {
              title: t('app.menu.data'),
            })}
            active={reportingTabActive}
            optionalGrants={REPORTING_READ_PERMISSIONS}
            submenuItems={[
              ...[
                {
                  label: t('app.menu.reporting'),
                  path: '/reporting',
                  requiredGrants: [SECTION_PERMISSIONS.REPORTING.REPORTS.READ],
                },
                {
                  label: t('app.menu.giving.insights'),
                  path: redirectInsights
                    ? REPORTING_ROUTES.insightsPaywall
                    : REPORTING_ROUTES.givingDashboard,
                  requiredGrants: [SECTION_PERMISSIONS.REPORTING.INSIGHTS.READ],
                },
              ],
            ]}
          />
        )}
        <AuthorizationWrapper optionalGrants={[USER_VIEW]}>
          <MenuItem
            history={history}
            linkPath="/organization"
            icon={
              <Settings
                className={settingsTabActive ? classes.activeIcon : ''}
                data-testid="left-nav-settings-icon"
              />
            }
            label={t('app.menu.settings')}
            active={settingsTabActive}
            optionalGrants={SETTINGS_READ_PERMISSIONS}
            submenuItems={[
              ...[
                {
                  label: t('app.menu.settings.organization'),
                  path: '/organization',
                  requiredGrants: [
                    SECTION_PERMISSIONS.SETTINGS.ORGANIZATION.READ,
                  ],
                },
                ...(canAccessFeatureManagement
                  ? [
                      {
                        label: t('app.menu.settings.featureManagement'),
                        path: '/feature-management',
                      },
                    ]
                  : []),
                {
                  label: t('app.menu.settings.users'),
                  path: '/users',
                  requiredGrants: [SECTION_PERMISSIONS.SETTINGS.USERS.READ],
                },
                {
                  label: t('app.menu.settings.emailReceipts'),
                  path: '/communications',
                  requiredGrants: [
                    SECTION_PERMISSIONS.SETTINGS.EMAIL_RECEIPTS.READ,
                  ],
                },
              ],
              ...(showPaidFeatures
                ? [
                    {
                      label: t('app.menu.settings.paidFeatures'),
                      path: '/paidfeatures',
                      requiredGrants: [
                        SECTION_PERMISSIONS.SETTINGS.PAID_FEATURES.READ,
                      ],
                    },
                  ]
                : []),
              ...(showImportLinks
                ? [
                    {
                      label: t('app.menu.settings.identityProvider'),
                      path: identityProviderURL,
                      isExternalLink: true,
                      rightIcon: (
                        <ExternalIcon
                          className={classes.submenuIcon}
                          data-testid="left-nav-identity-icon"
                        />
                      ),
                      requiredGrants: [
                        SECTION_PERMISSIONS.SETTINGS.IDENTITY_PROVIDER.READ,
                      ],
                    },
                  ]
                : []),
            ]}
          />
        </AuthorizationWrapper>
        <MenuItem
          icon={<KeyboardReturn data-testid="left-nav-return-icon" />}
          label={t('app.serviceCenter')}
          active={false}
          linkPath="https://myvanco.vancopayments.com/secure/vanco_login.html"
          isExternalLink
        />
      </div>
    </CustomDrawer>
  );
}

LeftNav.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  navActions: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  mobileEnabled: PropTypes.bool,
  idsPayerTenant: PropTypes.string,
  organizationId: PropTypes.string,
};

LeftNav.defaultProps = {
  mobileEnabled: false,
  idsPayerTenant: undefined,
  organizationId: '',
};

LeftNav.defaultProps = {
  mobileEnabled: false, // by default the feature is enabled but dont want to show it during loading by accident
};

export function mapStateToProps(state) {
  return {
    open: state.navigation.leftNavOpen,
    mobileEnabled:
      state.user.user.wasInternal ||
      state.organizations.currentOrganization.mobileEnabled,
    idsPayerTenant: state.organizations.currentOrganization.idsPayerTenant,
    role: state.user.user.role,
    wasInternal: state.user.user.wasInternal,
    organizationId: state.user.user.organizationId,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    navActions: bindActionCreators({ toggleLeftNav, closeNavs }, dispatch),
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(LeftNav);
