export default function styles(theme) {
  return {
    subTitle: {
      marginTop: 20,
    },
    subTitleEditor: {
      marginTop: theme.spacing(1),
    },
    container: {
      maxWidth: 600,
    },
    spinnerContainer: {
      marginTop: theme.spacing(4),
      height: 46,
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(5),
    },
    submitButton: {
      marginTop: theme.spacing(4),
    },
    form: {
      marginBottom: 0,
    },
    formEditor: {
      marginBottom: theme.spacing(4),
    },
    defaultGutter: {
      marginTop: theme.spacing(1),
    },
  };
}
