import { node } from 'prop-types';

import PageTitle from '../../PageTitle';
import { useSetupLayoutContext } from '../context';

export function Title({ children }) {
  const { classes } = useSetupLayoutContext();

  return <PageTitle className={classes.title}>{children}</PageTitle>;
}

Title.propTypes = {
  children: node.isRequired,
};

export default Title;
