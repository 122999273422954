import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  getDayName,
  getFormatedDaysRange,
  getFormatedSingleDate,
  isSameDay,
} from '../../../utils/date';
import styles from './styles';
import PageTitle from '../PageTitle';
import WizardButtons from '../../navigation/WizardLayout/WizardButtons';
import {
  MAX_REVIEW_DISPLAY_LENGTH,
  PAGE_ACTIVE,
  PAGE_PLATFORM_FACEBOOK,
  PAGE_PLATFORM_YOUTUBE,
  PAGE_YOUTUBE_VIDEO,
  PAGE_PENDING,
} from '../../../globals/constants';
import { getOrganizationInitials } from '../../../utils/page';
import { useGetTiles } from '../../../utils/hooks';
import { formatStreamInfo } from '../../../utils/stream';
import { ReactComponent as PreviewIcon } from '../../../assets/preview.svg';
import { isValidObject } from '../../../utils/validations';

export function StreamPageReviewWizard({
  history,
  page,
  goToPrev,
  goToNext,
  classes,
  savePage,
  loadingSave,
  pages,
  organizationName,
  features,
}) {
  const { t } = useTranslation();
  const { data: tiles = [], status: getTilesStatus } = useGetTiles();
  const {
    videoType,
    videoUrl,
    startDate,
    startTime,
    endDate,
    endTime,
    timeZone,
    repeatWeekly,
    description,
    chatTurnOnMinutes,
    chatTurnOffMinutes,
    chatEnabled,
    title,
    platform,
    embedCode,
    streamerId,
    streamId,
  } = page.streamData || {};
  const { campaignId, locationId } = page.streamGivingOption || {};
  const { logoImage, primaryColor } = page;

  const hasDescription =
    description &&
    !(description.length === 1 && description[0].insert === '\n');
  const videoTypeDescription = t(
    videoType === PAGE_YOUTUBE_VIDEO
      ? 'stream.page.videoDetails.videoUrl.option1.title'
      : 'stream.page.videoDetails.videoUrl.option2.title',
  );
  // eslint-disable-next-line no-nested-ternary
  const isSingleDay = startDate
    ? endDate
      ? isSameDay(startDate.toDate(), endDate.toDate())
      : true
    : false;
  const displayName = locationId
    ? pages.find((p) => p.id === locationId)?.pageName
    : getTilesStatus === 'success' &&
      tiles.find((c) => c.id === campaignId)?.title;
  const organizationInitials = getOrganizationInitials(organizationName);
  const previewLink = `${
    window.env.REACT_APP_STREAM_URL
  }/api/preview?slug=/watch/${formatStreamInfo(streamerId)}/${formatStreamInfo(
    streamId,
  )}`;
  const isYouTube = !platform || platform === PAGE_PLATFORM_YOUTUBE;
  const isFacebook = !!platform && platform === PAGE_PLATFORM_FACEBOOK;
  const embedInfo = isYouTube ? videoUrl : embedCode;
  const truncatedEmbedInfo =
    embedInfo?.substring(
      0,
      Math.min(embedInfo.length, MAX_REVIEW_DISPLAY_LENGTH),
    ) ?? '';
  const [isEmbedInfoTruncated, setIsEmbedInfoTruncated] = useState(
    embedInfo?.length > truncatedEmbedInfo.length,
  );

  const handleUndo = () => {};

  const processDefaultStreamDates = (streamData) => {
    if (!isValidObject(streamData)) return {};

    const beginningDate = moment().startOf('day');
    const finishDate = moment()
      .startOf('day')
      .add(23, 'hour')
      .add(59, 'minute');

    return {
      ...streamData,
      startDate: beginningDate,
      startTime: beginningDate.format('h:mm A'),
      endDate: finishDate,
      endTime: finishDate.format('h:mm A'),
    };
  };

  const hasContinueStatus = [PAGE_PENDING, PAGE_ACTIVE].includes(page.status);

  const handleSubmit = useCallback(async () => {
    const updatedPage = {
      ...page,
      streamData: hasContinueStatus
        ? page.streamData
        : processDefaultStreamDates(page.streamData),
      status: PAGE_ACTIVE,
    };
    await savePage(updatedPage);
    goToNext(true);
  }, [goToNext, page, savePage, hasContinueStatus]);

  const getScheduleDate = () => {
    let dateDescription = '';
    const startDateObj = startDate.toDate();
    if (repeatWeekly) {
      dateDescription = `${getDayName(startDateObj)}, ${getFormatedSingleDate(
        startDateObj,
      )}`;
    } else if (isSingleDay) {
      dateDescription = getFormatedSingleDate(startDateObj);
    } else {
      dateDescription = getFormatedDaysRange(startDateObj, endDate.toDate());
    }
    return dateDescription;
  };

  const onEdit = (e) =>
    history.push(`/wizard/stream/${page.id}/${e.currentTarget.value}`);

  const onPreview = () => {
    window.open(previewLink, '_blank');
  };

  useEffect(() => {
    const messageHandler = (evt) => {
      if (evt.origin !== window.env.REACT_APP_STREAM_URL) {
        return;
      }
      if (evt.data === 'publish') {
        handleSubmit();
      }
    };
    window.addEventListener('message', messageHandler, false);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [handleSubmit]);

  const renderSectionTitle = (section, editPath = section) => {
    return (
      <>
        <Grid item xs={11}>
          <Typography className={classes.gridTitle}>
            {t(`stream.pageWizard.${section}.title`)}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Button color="primary" value={editPath} onClick={onEdit}>
            {t('wizard.pageReview.edit')}
          </Button>
        </Grid>
      </>
    );
  };

  const renderLeftDescription = (label) => {
    return (
      <Grid item lg={5} md={4} sm={12} xs={12} className={classes.gridRow}>
        <Typography variant="body2">{t(label)}</Typography>
      </Grid>
    );
  };

  const renderRightDescription = (label) => {
    return (
      <Grid item lg={7} md={8} sm={12} xs={12} className={classes.gridRow}>
        <Typography>{label}</Typography>
      </Grid>
    );
  };

  const renderEmbedInfo = useCallback(() => {
    return (
      <Grid
        item
        lg={7}
        md={8}
        sm={12}
        xs={12}
        className={[classes.gridRow, classes.breakAll].join(' ')}
      >
        <Typography display="inline">
          {isEmbedInfoTruncated ? truncatedEmbedInfo : embedInfo}
        </Typography>
        {isEmbedInfoTruncated && (
          <Button
            className={classes.truncationButton}
            color="primary"
            onClick={() => setIsEmbedInfoTruncated(false)}
          >
            {t('stream.page.review.seeMore')}
          </Button>
        )}
        {!isEmbedInfoTruncated &&
          embedInfo?.length > truncatedEmbedInfo.length && (
            <Button
              className={classes.truncationButton}
              color="primary"
              onClick={() => setIsEmbedInfoTruncated(true)}
            >
              {t('stream.page.review.seeLess')}
            </Button>
          )}
      </Grid>
    );
  }, [isEmbedInfoTruncated, embedInfo, truncatedEmbedInfo, classes, t]);

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={7}>
          <PageTitle variant="small">{t('wizard.pageReview.title')}</PageTitle>
        </Grid>
        {!features?.SmartLibrary && !features?.SmartClips && (
          <Grid item xs={5} className={classes.previewButton}>
            <Button
              key="preview"
              aria-label="preview"
              color="primary"
              onClick={onPreview}
              data-testid="preview-button"
            >
              <PreviewIcon className={classes.buttonIcon} />
              {t('stream.page.review.preview')}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item className={classes.divider}>
          <Divider />
        </Grid>
      </Grid>

      {/* Stream details */}
      <Grid container className={classes.grid}>
        {renderSectionTitle('basicInfo', 'page-info')}
        {features.ChannelManagement && (
          <>
            {renderLeftDescription('stream.page.branding.streamPageName')}
            {renderRightDescription(title)}
          </>
        )}
        {renderLeftDescription('stream.page.review.platform')}
        {renderRightDescription(platform)}
        {isYouTube && (
          <>
            {renderLeftDescription('stream.page.review.videoType')}
            {renderRightDescription(videoTypeDescription)}
            {renderLeftDescription('stream.page.review.videoLink')}
            {renderEmbedInfo()}
          </>
        )}
        {isFacebook && (
          <>
            {renderLeftDescription('stream.page.review.embedCode')}
            {renderEmbedInfo()}
          </>
        )}

        <>
          {renderLeftDescription('stream.page.videoDetails.schedule.title')}
          <Grid item lg={7} md={8} sm={12} xs={12} className={classes.gridRow}>
            <Typography>{startDate && getScheduleDate()}</Typography>
            <Typography>{`${startTime} – ${endTime} ${timeZone?.abbreviation}`}</Typography>
            {repeatWeekly && (
              <Typography>{t('stream.page.review.repeats.weekly')}</Typography>
            )}
          </Grid>
        </>
        {renderLeftDescription('stream.page.review.description.added')}
        {renderRightDescription(t(hasDescription ? 'app.yes' : 'app.no'))}
      </Grid>

      {/* Appearance */}
      {!features.ChannelManagement && (
        <Grid container className={classes.grid}>
          {renderSectionTitle('branding')}
          {renderLeftDescription('stream.page.branding.streamPageName')}
          {renderRightDescription(title)}
          {renderLeftDescription('wizard.pageReview.color')}
          {primaryColor ? (
            <Grid
              item
              lg={7}
              md={8}
              sm={12}
              xs={12}
              className={`${classes.gridRow} ${classes.primaryColorContainer}`}
            >
              <div
                className={classes.primaryColor}
                style={{ backgroundColor: primaryColor }}
                data-testid="review-primary-color"
              />
              <Typography>{primaryColor}</Typography>
            </Grid>
          ) : (
            <Typography className={classes.notProvided}>
              {t('wizard.pageReview.notProvided')}
            </Typography>
          )}
          {renderLeftDescription('mobileSetup.review.organizationIcon')}
          <Grid item lg={7} md={8} sm={12} xs={12}>
            {!logoImage?.url && (
              <div
                className={classes.nonIconBackground}
                style={{ backgroundColor: primaryColor }}
              >
                <Typography variant="body2" className={classes.iconInitial}>
                  {organizationInitials}
                </Typography>
              </div>
            )}
            {logoImage?.url && (
              <div
                className={classes.iconBackground}
                style={{ backgroundImage: `url(${logoImage.url})` }}
              />
            )}
          </Grid>
        </Grid>
      )}

      {/* Giving */}
      <Grid container className={classes.grid}>
        {renderSectionTitle('giving')}
        {renderLeftDescription('stream.page.giving.title')}
        {renderRightDescription(
          t(
            page.streamGivingOption?.campaignId
              ? 'stream.page.giving.individual.tile'
              : 'stream.page.giving.online.site',
          ),
        )}
        {renderLeftDescription(
          page.streamGivingOption?.campaignId
            ? 'stream.page.review.tile'
            : 'stream.page.giving.online.site',
        )}
        {getTilesStatus === 'success' ? (
          renderRightDescription(displayName)
        ) : (
          <Skeleton variant="text" width="30%" className={classes.gridRow} />
        )}
      </Grid>

      {/* Chat */}
      <Grid container className={classes.grid}>
        {renderSectionTitle('chat')}
        {renderLeftDescription('stream.page.review.chat.enabled')}
        {renderRightDescription(t(chatEnabled ? 'app.yes' : 'app.no'))}
        {chatEnabled && (
          <>
            {renderLeftDescription(t('stream.page.review.chat.start.time'))}
            {renderRightDescription(
              `${chatTurnOnMinutes} ${t('stream.page.review.minutes.before')}`,
            )}
            {renderLeftDescription(t('stream.page.review.chat.end.time'))}
            {renderRightDescription(
              `${chatTurnOffMinutes} ${t('stream.page.review.minutes.after')}`,
            )}
          </>
        )}
      </Grid>
      <Grid container>
        <Grid item className={classes.lastDivider}>
          <Divider />
        </Grid>
      </Grid>
      {!loadingSave && goToPrev && (
        <WizardButtons
          isFinished={false}
          dirty={false}
          goToNext={handleSubmit}
          goToPrev={goToPrev}
          undo={handleUndo}
          newWizard
        />
      )}
      {loadingSave && (
        <div className={classes.spinnerContainer}>
          <CircularProgress color="primary" />
        </div>
      )}
    </div>
  );
}

StreamPageReviewWizard.propTypes = {
  classes: PropTypes.object.isRequired,
  goToNext: PropTypes.func.isRequired,
  goToPrev: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  savePage: PropTypes.func.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  organizationName: PropTypes.string.isRequired,
  features: PropTypes.objectOf(PropTypes.bool).isRequired,
};

export default withStyles(styles)(withRouter(StreamPageReviewWizard));
