import { bool, func, objectOf, string, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Divider,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import styles from './styles';
import DisclosureMessage from '../../DisclosureMessage';
import PageDivider from '../../PageDivider';

function StreamPageChatForm(props) {
  const {
    classes,
    setFieldValue,
    handleSubmit,
    values,
    goToPrev,
    loading,
    isFinished,
    handleReset,
    dirty,
    handleBlur,
    isWizard,
    hideDividers,
  } = props;

  const { chatEnabled, chatTurnOnMinutes, chatTurnOffMinutes } = values;
  const { t } = useTranslation();
  const minutesOption = Array.from({ length: 13 }, (_, i) => i * 5);

  const handleRadioChange = async (e) => {
    const { name, value } = e.target;
    await setFieldValue({ target: { name, value: value === 'true' } });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    await setFieldValue({ target: { name, value } });
  };

  const renderMinuteSelect = (name, value) => {
    const labelName = name === 'chatTurnOnMinutes' ? 'On' : 'Off';
    return (
      <Grid container direction="row" alignItems="center">
        <Typography component="span">
          {t(`stream.page.chat.turn${labelName}`)}
        </Typography>
        <TextField
          id={`select-${name}`}
          select
          className={classes.selectTextField}
          name={name}
          value={value}
          onChange={handleChange}
          InputLabelProps={{ shrink: false }}
          variant="outlined"
          onBlur={handleBlur}
        >
          {minutesOption.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Typography component="span">
          {t(`stream.page.chat.turn${labelName}.description`)}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.subtitle} variant="h5">
          {t('stream.page.chat.settings.title')}
        </Typography>
        <Typography className={classes.descriptionText}>
          {t('stream.page.chat.settings.description')}
        </Typography>
      </Grid>
      <Grid item>
        <RadioGroup component={RadioGroup} onChange={handleRadioChange}>
          <FormControlLabel
            className={classes.radioButton}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Radio
                checked={chatEnabled}
                name="chatEnabled"
                color="primary"
                value
              />
            }
            label={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Typography className={classes.subtitle2}>
                {t('stream.page.chat.enable')}
              </Typography>
            }
          />
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Radio
                checked={!chatEnabled}
                name="chatEnabled"
                color="primary"
                value={false}
              />
            }
            label={
              /* eslint-disable-next-line react/jsx-wrap-multilines */
              <Typography>{t('stream.page.chat.disable')}</Typography>
            }
          />
        </RadioGroup>
      </Grid>

      <Grid item xs={12}>
        <DisclosureMessage
          className={classes.disclosure}
          message={t('stream.page.chat.message')}
          variant="single"
        />
      </Grid>

      {chatEnabled && (
        <>
          {!hideDividers && <PageDivider variant="wide" />}

          <Grid item xs={12}>
            <Typography className={classes.subtitle} variant="h5">
              {t('stream.page.chat.availability.title')}
            </Typography>
            <Typography className={classes.descriptionText}>
              {t('stream.page.chat.availability.description')}
            </Typography>
          </Grid>
          {renderMinuteSelect('chatTurnOnMinutes', chatTurnOnMinutes)}
          {renderMinuteSelect('chatTurnOffMinutes', chatTurnOffMinutes)}
        </>
      )}

      {isWizard && (
        <Grid
          item
          className={chatEnabled ? classes.lastDivider : classes.divider}
        >
          {!hideDividers && <Divider />}
        </Grid>
      )}

      <Grid item xs={12}>
        {!loading && goToPrev && (
          <WizardButtons
            isFinished={isFinished}
            dirty={dirty}
            goToNext={handleSubmit}
            goToPrev={goToPrev}
            undo={handleReset}
            newWizard
          />
        )}
        {loading && goToPrev && (
          <div className={classes.spinnerContainer}>
            <CircularProgress
              color="primary"
              className={classes.spinner}
              data-testid="chat-spinner"
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
}

StreamPageChatForm.propTypes = {
  classes: objectOf(string).isRequired,
  handleSubmit: func.isRequired,
  goToPrev: func,
  loading: bool.isRequired,
  isFinished: bool.isRequired,
  handleReset: func.isRequired,
  dirty: bool.isRequired,
  setFieldValue: func.isRequired,
  values: object.isRequired,
  handleBlur: func.isRequired,
  isWizard: bool.isRequired,
  hideDividers: bool,
};

StreamPageChatForm.defaultProps = {
  goToPrev: null,
  hideDividers: false,
};

export default withStyles(styles)(StreamPageChatForm);
