import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import PageService from '../../../api/PageService';
import { handleException } from '../../../store/alerts/actions';

async function fetchStreamerSettings() {
  const response = await PageService.getStreamerSettings();
  return response.data.data;
}

export function useGetStreamerSettings(config = {}) {
  const dispatch = useDispatch();
  const { orgId } = useSelector((state) => ({
    orgId: state.organizations.currentOrganization.id,
  }));
  const {
    data = {},
    status,
    isLoading,
    ...rest
  } = useQuery({
    queryKey: ['streamer-settings', orgId],
    queryFn: fetchStreamerSettings,
    enabled: config.enabled && !!orgId,
    onError: (error) => {
      dispatch(handleException(error));
    },
    ...config,
  });

  return {
    ...rest,
    data,
    isLoading,
    status,
  };
}

export default useGetStreamerSettings;
