import { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomFormik from 'components/common/CustomFormik';
import { handleException } from 'store/alerts/actions';
import { useDispatch } from 'react-redux';
import { postAiEmailAssist } from '../../../../store/AIAssist/actions';
import Modal from '../../Modal';
import useStyles from './styles';
import { createSchema } from './validation';
import AiAssistForm from './AiAssistForm';
import AiAssistWarning from './AiAssistWarning';

export function AiAssistModal({
  open,
  onClose,
  showWarning,
  onWarningDismiss,
  organizationId,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      let emailResponseLength = values.responseLength;

      if (emailResponseLength) {
        if (
          emailResponseLength === t('modal.aiassist.responseLength.average')
        ) {
          emailResponseLength = t(
            'modal.aiassist.responseLength.average.value',
          );
        }
        emailResponseLength = emailResponseLength.toLowerCase();
      }
      const aiAssistantResponse = await dispatch(
        postAiEmailAssist({
          ...values,
          responseLength: emailResponseLength,
          organizationId,
        }),
      );

      if (aiAssistantResponse) {
        const iframe = document.querySelector('iframe[src*=".myvanco"]');

        if (iframe) {
          iframe.contentWindow.postMessage(
            {
              action: 'emailAiAssistantResponse',
              inputText: aiAssistantResponse,
            },
            '*',
          );
        }

        onClose();
      }
    } catch (error) {
      dispatch(handleException);
    } finally {
      setIsSubmitting(false);
    }
  };

  const schema = createSchema();

  const dismissWarning = () => {
    onWarningDismiss();
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.createtileModal}>
      {showWarning ? (
        <AiAssistWarning handleClose={onClose} handleSubmit={dismissWarning} />
      ) : (
        <CustomFormik
          initialValues={{
            purposeEntry: '',
            mainPointsEntry: '',
            toneEntry: '',
            responseLength: '',
          }}
          enableReinitialize
          onSubmit={onSubmit}
          render={(formikProps) => (
            <AiAssistForm
              {...formikProps}
              handleClose={onClose}
              hasExistingText={false}
              isSubmitting={isSubmitting}
            />
          )}
          validationSchema={schema}
        />
      )}
    </Modal>
  );
}

AiAssistModal.propTypes = {
  open: bool,
  onClose: func.isRequired,
  showWarning: bool,
  onWarningDismiss: func.isRequired,
  organizationId: string.isRequired,
};

AiAssistModal.defaultProps = {
  open: false,
  showWarning: false,
};

export default AiAssistModal;
