/* eslint-disable no-nested-ternary */
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withPage from '../../hoc/withPage';
import withTile from '../../hoc/withTile';
import withOrg from '../../hoc/withOrg';
import WizardLayout from './WizardLayout';
import { resetNewTile } from '../../../store/tiles/actions';
import { resetNewPage, setCurrentPage } from '../../../store/pages/actions';
import { useGetPageId } from '../../../utils/hooks';
import { pagePaths, pagePathsDeprecated } from './page.config';
import { mobilePagePaths } from './mobile.page.config';
import { streamPagePaths } from './stream.page.config';
import { organizationPagePaths } from './organization.page.config';
import Navbar from './Navbar';
import AppProgressBar from '../AppProgressBar';

export function WizardLayoutWrapper({
  location,
  isLoggedIn,
  newTile,
  features,
  currentPage,
  pages,
  setCurrentPage: setPage,
  communityFeaturesChoosen,
  newWizard,
  ...rest
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  // Allow Organization wizard to pops up the prevent leave modal on exit in all circumstances
  const [forcePreventModal, setForcePreventModal] = useState(false);
  const isTile = location.pathname.includes('tile');
  const isMobilePage = location.pathname.includes('mobile');
  const isStreamPage = location.pathname.includes('stream');
  const isOrganizationPage = location.pathname.includes('organization');
  const paths = isMobilePage
    ? mobilePagePaths
    : isStreamPage
    ? streamPagePaths
    : isOrganizationPage
    ? organizationPagePaths
    : features.ChannelManagement
    ? pagePaths
    : pagePathsDeprecated;
  const pageId = useGetPageId(paths, isOrganizationPage);
  const wrapper = isOrganizationPage ? withOrg : isTile ? withTile : withPage;

  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  const WrappedLayout = useMemo(() => {
    if (isOrganizationPage) {
      return wrapper(WizardLayout, 'wizard.organization.title');
    }

    if (!isTile && !pageId) {
      return WizardLayout;
    }

    return wrapper(
      WizardLayout,
      isTile ? 'titles.createTile' : 'titles.createSite',
    );
  }, [wrapper, isTile, pageId, isOrganizationPage]);

  return (
    <>
      <Navbar
        isLoggedIn={isLoggedIn}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        pages={pages}
        newWizard={newWizard || features.NewWizard} // Leaved this to avoid modifying more files (streaming already using the NewWizard GUI as default)
        features={features}
        setForcePreventModal={setForcePreventModal}
        forcePreventModal={forcePreventModal}
      />
      <AppProgressBar />
      <WrappedLayout
        location={location}
        menuOpen={menuOpen}
        communityFeaturesChoosen={communityFeaturesChoosen}
        newWizard={newWizard || features.NewWizard}
        features={features}
        forcePreventModal={forcePreventModal}
        {...rest}
      />
    </>
  );
}

WizardLayoutWrapper.propTypes = {
  location: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  newTile: PropTypes.object.isRequired,
  features: PropTypes.object,
  currentPage: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  communityFeaturesChoosen: PropTypes.bool,
  newWizard: PropTypes.bool,
};

WizardLayoutWrapper.defaultProps = {
  features: {},
  communityFeaturesChoosen: false,
  newWizard: false,
};

export function mapStateToProps(state) {
  return {
    newTile: state.tiles.newTile,
    newPage: state.pages.newPage,
    features: state.features?.features,
    currentPage: state.pages.currentPage,
    pages: state.pages.pages,
    communityFeaturesChoosen: state.pages.communityFeaturesChoosen,
  };
}

export default connect(mapStateToProps, {
  resetNewTile,
  resetNewPage,
  setCurrentPage,
})(WizardLayoutWrapper);
