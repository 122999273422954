export default () => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inviteButton: {
    borderRadius: 4,
  },
  pagePaper: {
    padding: 20,
  },
  icon: {
    marginRight: 20,
  },
});
