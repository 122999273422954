import { string, oneOf } from 'prop-types';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

import { useCardContext } from '../context';
import { getOrganizationInitials } from '../../../../utils/page';

function CardMediaImage({ src, alt, variant, className }) {
  const { classes, isLoading } = useCardContext();
  const imageClasses = clsx({
    [classes.cardMediaImageDefault]: variant === 'default',
    [classes.cardMediaImageWide]: variant === 'wide',
    [className]: !!className,
  });

  if (isLoading) {
    return (
      <Skeleton
        variant="rect"
        data-testid="loading-image"
        className={imageClasses}
      />
    );
  }

  if (!src) {
    return (
      <div className={`${imageClasses} ${classes.cardMediaImagePlaceholder}`}>
        <Typography className={classes.cardMediaImageInitials}>
          {getOrganizationInitials(alt)}
        </Typography>
      </div>
    );
  }

  return <img className={imageClasses} src={src} alt={alt} />;
}

CardMediaImage.propTypes = {
  src: string,
  alt: string,
  variant: oneOf(['default', 'wide']),
  className: string,
};

CardMediaImage.defaultProps = {
  src: null,
  alt: '',
  variant: 'default',
  className: null,
};

export default CardMediaImage;
