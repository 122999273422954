import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaymentMethod from './PaymentMethod';
import {
  setPaymentType,
  processTransactions,
} from '../../../store/transaction/actions';

export const mapStateToProps = ({
  transaction: {
    hexea,
    selectedPaymentType,
    paymentMethods,
    isProcessingTransactions,
    basket,
  },
}) => ({
  hexea,
  selectedPaymentType,
  paymentMethods,
  isProcessingTransactions,
  hasItemsInBasket: basket?.length > 0,
});

export const mapDispatchToProps = (dispatch) => ({
  paymentMethodActions: bindActionCreators(
    {
      setPaymentType,
      processTransactions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
