import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

import styles from './styles';

function CreateButton({ children, classes, ...props }) {
  const { t } = useTranslation();

  const formatLabel = () => t('button.create', { what: children });

  return (
    <Button color="primary" {...props}>
      <AddIcon className={classes.addIcon} data-testid="add-icon" />
      {formatLabel()}
    </Button>
  );
}

CreateButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
};

export default withStyles(styles)(CreateButton);
