import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import styles from './styles';

function OutlinedSelect(props) {
  const {
    children,
    classes,
    label,
    id,
    customControl,
    className,
    menuProps,
    labelClass,
    placeholderOnly,
    ...selectProps
  } = props;
  const selectId = `select-${id}`;
  const outlineId = `outlined-${id}`;
  return (
    <FormControl className={[classes.formControl, className].join(' ')}>
      <InputLabel
        shrink={placeholderOnly ? false : undefined}
        className={[classes.label, labelClass].join(' ')}
      >
        {label}
      </InputLabel>
      <Select
        id={id}
        input={<OutlinedInput name="" id={outlineId} labelWidth={0} />}
        inputProps={{
          id: selectId,
          name: id,
          'data-testid': selectId,
        }}
        className={classes.select}
        {...selectProps}
        MenuProps={menuProps}
      >
        {children}
      </Select>
    </FormControl>
  );
}

OutlinedSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  label: PropTypes.string,
  id: PropTypes.string,
  menuProps: PropTypes.object,
  customControl: PropTypes.bool,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  placeholderOnly: PropTypes.bool,
};

OutlinedSelect.defaultProps = {
  children: null,
  label: '',
  id: '',
  menuProps: {},
  customControl: false,
  className: '',
  labelClass: '',
  placeholderOnly: false,
};

export default withStyles(styles)(OutlinedSelect);
