import { createContext } from 'react';
import { TILE_TYPE_GIFT } from '../../globals/constants';
import i18n from '../i18n';

export const isCurrentSection = (currentSection, page) =>
  currentSection.path === page.path;
export const isCurrentPage = (page, path) => path === page.path;

export const findCurrentSection = (navConfig, path) => {
  for (let index = 0; index < navConfig.length; index += 1) {
    const section = navConfig[index];
    if (section.path === path) return section;
  }
  /* eslint-disable-next-line no-console */
  console.error('Bad path:', path);
  return {};
};

/* From a starting section index it finds the next section in the navConfig */
export const findNextPath = (navConfig, startSection) => {
  const startIndex = navConfig.findIndex(
    (section) => section.path === startSection.path,
  );
  for (let row = startIndex; row < navConfig.length; row += 1) {
    const section = navConfig[row];

    if (startIndex !== row && section.path) {
      return section.path;
    }
  }

  return null;
};

export const findPrevPath = (navConfig, startSection) => {
  const startIndex = navConfig.findIndex(
    (section) => section.path === startSection.path,
  );
  for (let row = startIndex; row >= 0; row -= 1) {
    const section = navConfig[row];

    if (startIndex !== row && section.path) {
      return section.path;
    }
  }
  return null;
};

export const getWizardNavTitle = (
  { tile },
  isStreamPage,
  isOrganizationPage,
  isStreamSite = false,
  ffVancoVideo = false,
) => {
  if (tile) {
    return i18n.t(
      tile.type === TILE_TYPE_GIFT
        ? 'wizard.createAGift'
        : 'wizard.createAPayment',
    );
  }
  if (isStreamSite)
    return i18n.t('wizard.StreamSite', {
      title: i18n.t(`${ffVancoVideo ? 'video' : 'live'}Title`),
    });
  if (isStreamPage) return i18n.t('wizard.createAStream');
  if (isOrganizationPage) return i18n.t('wizard.organization.title');

  return i18n.t('wizard.createASite');
};

export const WizardContext = createContext({
  dirty: false,
  setDirty: () => {},
  submit: null,
  setSubmit: () => {},
});
