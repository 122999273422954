import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Done from '@material-ui/icons/Done';
import { withStyles } from '@material-ui/core/styles';

import { CUSTOM_TEXT } from '../../../../globals/constants';
import styles from './styles';
import OutlinedSelect from '../../OutlinedSelect';
import OutlinedTextEditor from '../../OutlinedTextEditor';
import FormHelperTextBottom from '../../FormHelperTextBottom';
import OutlinedTextField from '../../OutlinedTextField';
import WizardButtons from '../../../navigation/WizardLayout/WizardButtons';
import Form from '../../Form';
import { getCountText } from '../../../../utils';

function TileDescriptionForm(props) {
  const {
    handleSubmit,
    values,
    errors,
    maxLengths,
    setFieldValue,
    touched,
    handleBlur,
    goToPrev,
    loading,
    isFinished,
    handleReset,
    dirty,
    classes,
    buttonTextOptions,
  } = props;
  const {
    description,
    shortDescription,
    buttonText,
    customButtonText,
  } = values;

  const { t } = useTranslation();
  const customButtonTextRef = useRef(null);
  const [dropDownHasChange, setDropdownHasChange] = useState(false);
  const [enableCustomButtonText, setEnableCustomButtonText] = useState(false);

  const onButtonTextDropdownChange = e => {
    setFieldValue(e);
    setDropdownHasChange(true);
    setEnableCustomButtonText(e.target.value === CUSTOM_TEXT);
  };

  const onEditorChange = value => {
    setFieldValue({ target: { name: 'description', value } });
  };

  // This is going to work just if the user use the dropdown to enable
  // the custom button text field and not in the initial render
  useEffect(() => {
    if (enableCustomButtonText && dropDownHasChange) {
      customButtonTextRef.current.focus();
    }
  }, [enableCustomButtonText, dropDownHasChange]);

  return (
    <Form>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <section className={classes.heading}>
            <Typography variant="body2" className={classes.subtitle}>
              {t('tile.description.heading1')}
            </Typography>
            <Typography>{t('tile.description.helper1')}</Typography>
          </section>
        </Grid>
        <Grid item md={9} sm={12} xs={12}>
          <OutlinedTextEditor
            placeholder={t('tile.description.placeholder')}
            onChange={onEditorChange}
            value={description}
          />
        </Grid>
        <Grid
          item
          md={9}
          sm={12}
          xs={12}
          className={classes.shortDescriptionContainer}
        >
          <section className={classes.heading}>
            <Typography variant="body2" className={classes.subtitle}>
              {t('tile.shortDescription.heading1')}
            </Typography>
            <Typography>{t('tile.shortDescription.helper1')}</Typography>
          </section>
          <FormControl fullWidth>
            <OutlinedTextField
              className={classes.shortDescriptionTextContainer}
              id="shortDescription"
              name="shortDescription"
              value={shortDescription}
              placeholder={t('tile.shortDescription.placeholder')}
              multiline
              maxRows="3"
              inputProps={{
                maxLength: maxLengths.shortDescription,
                className: classes.shortDescriptionTextField,
                'data-testid': 'short-description',
              }}
              onChange={setFieldValue}
              onBlur={handleBlur}
              error={errors.shortDescription && touched.shortDescription}
            />
            <FormHelperTextBottom
              left={touched.shortDescription && errors.shortDescription}
              data-testid="short-description-counter"
              right={getCountText(
                shortDescription.length,
                maxLengths.shortDescription,
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={3} sm={12} xs={12} />
        <Grid item md={9} sm={12} xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item md={9} sm={12} xs={12}>
          <section className={classes.heading}>
            <Typography variant="body2" className={classes.subtitle}>
              {t('tile.setUp.buttonLabel')}
            </Typography>
            <Typography>{t('cta.helper1')}</Typography>
            {/* <ExampleLink image="https://via.placeholder.com/500">
              {t('see.example')}
            </ExampleLink> */}
          </section>
        </Grid>
        <Grid item md={3} sm={12} xs={12} />
        <Grid item md={4} sm={12} xs={12}>
          <OutlinedSelect
            id="buttonText"
            name="buttonText"
            data-testid="buttonText"
            error={errors.buttonText && touched.buttonText}
            value={buttonText}
            onChange={onButtonTextDropdownChange}
            label={t('tile.setUp.buttonLabel')}
            renderValue={value => value}
          >
            <Typography variant="body2" className={classes.buttonLabelHeader}>
              {t('tile.setUp.customButtonheading')}
            </Typography>
            <MenuItem
              className={classes.inlineItems}
              value={CUSTOM_TEXT}
              key={CUSTOM_TEXT}
            >
              {buttonText === CUSTOM_TEXT ? (
                <Done color="primary" className={classes.doneIcon} />
              ) : (
                <span className={classes.unselected} />
              )}
              {t('tile.setUp.customButtonLabel')}
            </MenuItem>
            <Divider className={classes.buttonLabelDivider} />
            <Typography variant="body2" className={classes.buttonLabelHeader}>
              {t('tile.setUp.suggestionsButtonheading')}
            </Typography>
            {buttonTextOptions.map(option => (
              <MenuItem
                className={classes.inlineItems}
                value={option}
                key={option}
              >
                {buttonText === option ? (
                  <Done color="primary" className={classes.doneIcon} />
                ) : (
                  <span className={classes.unselected} />
                )}
                {option}
              </MenuItem>
            ))}
          </OutlinedSelect>
          {errors.buttonText && (
            <FormHelperTextBottom left={errors.buttonText} />
          )}
        </Grid>
        <Grid item md={8} sm={12} xs={12} />
        <Grid
          item
          md={4}
          sm={12}
          xs={12}
          className={buttonText === CUSTOM_TEXT ? '' : classes.hidden}
        >
          <OutlinedTextField
            id="customButtonText"
            name="customButtonText"
            value={customButtonText}
            onChange={setFieldValue}
            label={t('tile.setUp.customButtonLabel')}
            inputProps={{
              maxLength: maxLengths.customButtonText,
              'data-testid': 'custom-button-text',
              ref: customButtonTextRef,
            }}
            error={errors.customButtonText && touched.customButtonText}
            autoComplete="off"
            onBlur={handleBlur}
            placeholder={t('tile.setUp.customButtonPlaceholder')}
          />
          <FormHelperTextBottom
            left={touched.customButtonText && errors.customButtonText}
            right={
              errors.customButtonText && touched.customButtonText
                ? ''
                : getCountText(
                    customButtonText.length,
                    maxLengths.customButtonText,
                  )
            }
          />
        </Grid>
      </Grid>
      <br />
      {!loading && goToPrev && (
        <WizardButtons
          isFinished={isFinished}
          dirty={dirty}
          goToNext={handleSubmit}
          goToPrev={goToPrev}
          undo={handleReset}
        />
      )}
      {loading && goToPrev && <CircularProgress color="primary" />}
    </Form>
  );
}

TileDescriptionForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  maxLengths: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  goToPrev: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  buttonTextOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TileDescriptionForm.defaultProps = {
  goToPrev: null,
};

export default withStyles(styles)(TileDescriptionForm);
