import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useIsDeletableTile, useDeleteTile } from '../tiles';
import { handleException, setSuccess } from '../../../store/alerts/actions';

export function useDeleteTileWithConfirmModal(tile, onDeleteSucess) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const {
    data: isDeletable,
    status: isDeletableStatus,
    isFetching: isDeletableFetching,
  } = useIsDeletableTile(tile, {
    enabled: Boolean(tile?.id && deleteModalOpen),
    refetchOnWindowFocus: false,
    retry: 0,
    onError: (error) => {
      dispatch(handleException(error));
      setDeleteModalOpen(false);
    },
  });
  const { mutate: deleteTile, isLoading } = useDeleteTile({
    onSuccess: () => {
      setDeleteModalOpen(false);
      queryClient.removeQueries(['tile', tile.id]);
      queryClient.invalidateQueries('tile');
      onDeleteSucess();
      dispatch(setSuccess(t('deleteModal.delete.success')));
    },
    onError: (error) => {
      dispatch(handleException(error));
    },
  });

  return {
    deleteModalOpen,
    setDeleteModalOpen,
    isDeletable: !!isDeletable,
    isDeletableStatus,
    isDeletableFetching,
    deleteTile,
    isDeleting: isLoading,
  };
}

export default useDeleteTileWithConfirmModal;
