import { objectOf, string, oneOf, bool, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';

import { COLOR_OPTIONS } from '../../../globals/constants';

import styles from './styles';

export function ColorSelector({ classes, color, onClick, isSelected }) {
  const onCircleClick = value => () => {
    onClick(value);
  };

  return (
    <div className={classes.container}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={isSelected ? classes.selectedCircle : classes.circle}
        style={{ backgroundColor: color }}
        onClick={onCircleClick(color)}
        role="button"
        tabIndex={0}
      >
        {isSelected && <DoneIcon />}
      </div>
    </div>
  );
}

ColorSelector.propTypes = {
  classes: objectOf(string).isRequired,
  color: oneOf(COLOR_OPTIONS.map(c => c)),
  onClick: func.isRequired,
  isSelected: bool,
};

ColorSelector.defaultProps = {
  isSelected: false,
  color: null,
};

export default withStyles(styles)(ColorSelector);
