import { object, url, assert, win } from '@vancoplatform/utils';
import { isSameOrigin, getOrigin, getHostAndPath } from '../helper/window';
import PopupHandler, {
  PopupOptions,
  PopupPosition,
} from '../helper/popup-handler';
import TransactionManager from './transaction-manager';
import WebAuth from '.';
import Authentication, { SignOnOptions } from '../Authentication';
import { AuthorizeOptions, Transaction, WebAuthOptions } from '../types';

class Popup {
  baseOptions: WebAuthOptions;
  client: Authentication;
  webAuth: WebAuth;
  transactionManager: TransactionManager;

  constructor(webAuth: WebAuth, options) {
    this.baseOptions = options;
    this.client = webAuth.client;
    this.webAuth = webAuth;

    this.transactionManager = new TransactionManager(
      this.baseOptions.transaction
    );
  }

  signOn(
    options: {
      popupOptions?: PopupPosition;
      mode?: 'popup' | 'popup_authorize';
    } & AuthorizeOptions
  ) {
    const popOpts: PopupOptions = {
      url: undefined,
    };

    const params: SignOnOptions = object
      .merge(this.baseOptions, [
        'clientId',
        'scope',
        'audience',
        'tenant',
        'responseType',
        'redirectUri',
        'state',
        'nonce',
        'maxAge',
      ])
      .with(object.blacklist(options, ['popupOptions']));

    assert.check(
      params,
      { type: 'object', message: 'options parameter is not valid' },
      {
        responseType: {
          type: 'string',
          message: 'responseType option is required',
        },
      }
    );

    if (options.popupOptions) {
      popOpts.popupOptions = object.pick(options.popupOptions, [
        'width',
        'height',
      ]);
    }

    params.mode = params.mode || 'popup';
    popOpts.url = this.client.buildSignOnUrl(params);

    return this.createSignUpAuthorizePopup(popOpts, params);
  }

  async authorize(options: { popupOptions: PopupPosition }) {
    const popOpts: PopupOptions = {
      url: undefined,
    };

    let params: AuthorizeOptions<unknown> = object
      .merge(this.baseOptions, [
        'clientId',
        'scope',
        'audience',
        'tenant',
        'responseType',
        'redirectUri',
        'state',
        'nonce',
        'usePKCE',
      ])
      .with(object.blacklist(options, ['popupHandler']));

    assert.check(
      params,
      { type: 'object', message: 'options parameter is not valid' },
      {
        responseType: {
          type: 'string',
          message: 'responseType option is required',
        },
      }
    );

    // If no redirectUri is provided, just fall back to using the current location
    params.redirectUri = params.redirectUri || getHostAndPath();

    // Perform a sanity check to verify that the origin of the current window
    // matches the origin of the redirect uri. If this is not the case, we
    // will be unable to receive the authorization response.
    const origin = url.extractOrigin(params.redirectUri);
    if (!isSameOrigin(origin)) {
      return Promise.reject(
        `The provided redirect uri "${
          params.redirectUri
        }" must have the same origin as the current window "${getOrigin()}"`
      );
    }

    if (options.popupOptions) {
      popOpts.popupOptions = object.pick(options.popupOptions, [
        'width',
        'height',
      ]);
    }

    params = await this.transactionManager.process(params);
    params.scope = params.scope || 'openid profile email';
    params.redirectMode = params.redirectMode || 'relay';
    params.responseMode = 'fragment';

    popOpts.url = this.client.buildAuthorizeUrl(params);

    return await this.createSignUpAuthorizePopup(popOpts, params);
  }

  createSignUpAuthorizePopup(popOpts: PopupOptions, params) {
    const handler = new PopupHandler(popOpts);
    handler.mount();

    return new Promise<AuthorizeOptions & { originalRedirectUri?: string }>(
      (resolve, reject) => {
        handler.on('getTransaction', async () => {
          let transaction: Transaction<unknown> & {
            clientId?: string;
            redirectUri?: string;
            mode?: string;
          } = null;

          if (params.mode === 'popup_authorize') {
            const event = await win.sendToParent<Transaction<unknown>>(
              'getTransaction'
            );
            transaction = event.data;
          } else {
            transaction = this.transactionManager.getStoredTransaction(
              params.state
            );
          }

          if (transaction == null) {
            return transaction;
          }

          transaction.clientId = params.clientId;
          transaction.redirectUri = params.redirectUri;
          transaction.mode = params.mode;
          return transaction;
        });

        handler.on('authorize', (event) => {
          resolve(event.data);
          handler.unmount();
        });

        handler.on('signOn', (event) => {
          resolve(event.data);
          handler.unmount();
        });

        handler.on('redirect', (event) => {
          if (params.mode === 'popup_authorize') {
            handler.unmount();
            const { original_redirect_uri, ...data } = event.data;
            win.sendToParent('redirect', data, {
              domain: url.extractOrigin(original_redirect_uri),
            });
          }
        });

        handler.on('error', (event) => {
          reject(event.data);
          handler.unmount();
        });
      }
    );
  }
}

export default Popup;
