import * as Yup from 'yup';

import i18n from '../../../utils/i18n';
import { maxLengths } from '../../../utils/validations';

export const { pageName: pageNameLength } = maxLengths;

export const createSchema = () => {
  return Yup.object().shape({
    title: Yup.string()
      .max(maxLengths.pageName, i18n.t('validation.long'))
      .required(
        i18n.t('validation.required', {
          requiredNoun: i18n
            .t('stream.page.branding.streamPageName')
            .toLocaleLowerCase(),
        }),
      ),
  });
};
