export default function getParent(win: Window = window): Window | null {
  if (!win) {
    return null;
  }

  try {
    if (win.parent && win.parent !== win) {
      return win.parent;
    }
  } catch (err) {
    return null;
  }
  return null;
}
