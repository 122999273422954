export default function styles() {
  return {
    title: {
      padding: '0px 24px',
      '& h6': {
        textAlign: 'left',
        paddingLeft: 0,
      },
    },
    titleContainer: {
      height: 60,
      marginBottom: 0,
    },
    content: {
      padding: '16px 24px 0px 24px',
    },
    closeIcon: {
      marginRight: '-12px',
    },
    actions: {
      padding: '32px 24px 24px 24px',
      border: 0,
    },
  };
}
