export const SEND_KIOSK_REQUEST = 'SEND_KIOSK_REQUEST';
export const SEND_KIOSK_REQUEST_SUCCESS = 'SEND_KIOSK_REQUEST_SUCCESS';
export const SEND_KIOSK_REQUEST_ERROR = 'SEND_KIOSK_REQUEST_ERROR';
export const GET_KIOSK = 'GET_KIOSK';
export const GET_KIOSK_SUCCESS = 'GET_KIOSK_SUCCESS';
export const GET_KIOSK_ERROR = 'GET_KIOSK_ERROR';
export const ENABLE_KIOSK = 'ENABLE_KIOSK';
export const ENABLE_KIOSK_SUCCESS = 'ENABLE_KIOSK_SUCCESS';
export const ENABLE_KIOSK_ERROR = 'ENABLE_KIOSK_ERROR';
export const DISABLE_KIOSK = 'DISABLE_KIOSK';
export const DISABLE_KIOSK_SUCCESS = 'DISABLE_KIOSK_SUCCESS';
export const DISABLE_KIOSK_ERROR = 'DISABLE_KIOSK_ERROR';
export const UPDATE_KIOSK = 'UPDATE_KIOSK';
export const UPDATE_KIOSK_SUCCESS = 'UPDATE_KIOSK_SUCCESS';
export const UPDATE_KIOSK_ERROR = 'UPDATE_KIOSK_ERROR';
