import { NAVBAR_HEIGHT } from '../../../globals/dimensions';

export default theme => ({
  appBar: {
    backgroundColor: theme.palette.white,
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
  },
  toolbar: {
    minHeight: NAVBAR_HEIGHT,
    paddingLeft: 58,
    paddingRight: 58,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  menuButton: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 0,
    padding: 10,
    paddingRight: 14,
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  menuIconButton: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
    },
  },
  profileMenuText: {
    marginLeft: 10,
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
  name: {
    display: 'inline-block',
    fontWeight: 'bold',
    marginLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  profileIcon: {
    fontSize: '2.4rem',
    verticalAlign: 'middle',
    margin: 5,
  },
  mobileProfileIcon: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
    },
  },
  desktopProfile: {
    display: 'inline-block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});
