import { string, node, bool } from 'prop-types';
import Paper from '@material-ui/core/Paper';

import useStyles from './styles';

function PagePaper({ className, children, contained }) {
  const classes = useStyles({ contained });
  return (
    <Paper
      classes={{
        root: `${classes.root} ${className}`,
      }}
    >
      {children}
    </Paper>
  );
}

PagePaper.propTypes = {
  className: string,
  children: node.isRequired,
  contained: bool,
};

PagePaper.defaultProps = {
  className: '',
  contained: false,
};

export default PagePaper;
